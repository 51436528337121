import React from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ProjectActionMenuItem } from '../../../../components/projects/projectsTable/ProjectsTable.styles';
import { UsersAction } from '../../../../models/user/enums';

export const getUsersActionItems = (
) => {
    const actions = Array<ItemType>();
    actions.push({
            key: UsersAction.CSV,
            label: <ProjectActionMenuItem>Export to CSV</ProjectActionMenuItem>,
    });
    return actions;
};
