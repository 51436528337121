import {
    DeclareResults,
    EC3EmbodiedCarbon,
    MasterFormatBackground,
    RegulationText,
} from '../../../../models/product/enums';

export const getColorCircleForResult = (declareResults: DeclareResults) => {
    switch (declareResults) {
        case DeclareResults.Approved:
            return '#d67930';
        case DeclareResults.Free:
            return '#b33533';
        case DeclareResults.Declared:
            return '#970e53';
        case DeclareResults.Bronze:
            return '#c28342';
        case DeclareResults.Platinum:
            return '#8cB5cc';
        case DeclareResults.Gold:
            return '#ceb04e';
        case DeclareResults.Silver:
            return '#c0c0c0';
        default:
            return 'transparent';
    }
};

export const getPercentileColor = (ec3EmbodiedCarbon: EC3EmbodiedCarbon) => {
    switch (ec3EmbodiedCarbon) {
        case EC3EmbodiedCarbon.Twenty:
            return '#58f00d';
        case EC3EmbodiedCarbon.Fourty:
            return '#45c500';
        case EC3EmbodiedCarbon.Sixty:
            return '#369c27';
        case EC3EmbodiedCarbon.SixtyPlus:
            return '#007d00';
        case EC3EmbodiedCarbon.Eighty:
            return '#555c55';
        case EC3EmbodiedCarbon.EightyPlus:
            return '#555c55';
        default:
            return 'transparent';
    }
};

export const getListingHeaderBackgroundColor = (companyLevel?: MasterFormatBackground) => {
    switch (companyLevel) {
        case MasterFormatBackground.FREE_LISTING:
            return '#91BAC3';
        case MasterFormatBackground.STANDARD:
            return '#5A76B4';
        case MasterFormatBackground.FEATURED:
            return '#284757';
        default:
            return '#284757';
    }
};

export const getMasterFormatBackgroundColor = (companyLevel?: MasterFormatBackground) => {
    switch (companyLevel) {
        case MasterFormatBackground.FREE_LISTING:
            return '#aec2c8';
        case MasterFormatBackground.STANDARD:
            return '#8da0bd';
        case MasterFormatBackground.FEATURED:
            return '#8c9ea7';
        default:
            return '#8c9ea7';
    }
};

export const getNameOfListingType = (companyLevel?: MasterFormatBackground) => {
    switch (companyLevel) {
        case MasterFormatBackground.FREE_LISTING:
            return 'FREE — EPD';
        case MasterFormatBackground.STANDARD:
            return 'STANDARD';
        case MasterFormatBackground.FEATURED:
            return 'FEATURED';
        default:
            return 'FEATURED';
    }
};

export const formatRegulationText = (regulationText: RegulationText) => {
//    debugger
    switch (regulationText) {
        case RegulationText.PERKINS_AND_WILL:
            return 'Perkins&Will PL Free';
        default:
            return regulationText;
    }
};
