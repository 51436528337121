import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    grayDark01,
    grayLight05,
    green,
    white,
} from '../../../../styles/colors';
import { BoldText, ErrorMessage, InvisibleButton, Text } from '../../../../styles/common.styles';

const flexibleSectionSmallScreenMaxWidth = 1350;
const pricingSectionSmallScreenMaxWidth = 1450;

export const MFRProductInfoContainer = styled('div')`
    background-color: ${grayLight05};
    display: grid;
    grid-template-columns: 5fr 4fr;
    align-items: start;
    flex-wrap: wrap;
    padding: 16px 20px 16px 12px;
    width: 100%;
    column-gap: 5px;
    row-gap: 12px;

    > :nth-child(3) {
        justify-self: end;
    }

    @media (max-width: ${pricingSectionSmallScreenMaxWidth}px) {
        column-gap: 20px;
    }
`;

export const MFRProductInfoSection = styled('div')`
    &:not(:last-child) {
        margin-right: 16px;
    }
    .ant-select-selector {
        width: 103px !important;
        padding: 0 7px !important;
        .ant-select-selection-item {
            font-weight: 400 !important;
            font-size: 12px !important;
            color: ${blueDark01} !important;
        }
    }
    &.toggle-section {
        margin-right: 42px;
    }
`;

export const MFRProductPricingInfoSection = styled(MFRProductInfoSection)`
    width: auto;
    display: flex;
    @media (max-width: ${pricingSectionSmallScreenMaxWidth}px) {
        flex-direction: column;
    }
`;

export const MFRProductItemLabel = styled(Text)`
    margin-bottom: 4px;
`;

export const MFRPricingSection = styled(MFRProductInfoSection)`
    .ant-input {
        width: 125px;
    }
`;

export const MFRPricingInputContainer = styled('div')`
    position: relative;
`;

export const PricingError = styled(ErrorMessage)`
    left: 0;
    bottom: -15px;
`;

export const MFRProductRadioButtonContainer = styled('div')`
    margin-top: 13px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    gap: 5%;
    .ant-radio-inner::after {
        background-color: ${white};
    }
    .ant-radio-checked .ant-radio-inner {
        background-color: ${blue01};
    }
    .ant-radio-wrapper {
        color: ${grayDark01};
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }

    @media (max-width: ${pricingSectionSmallScreenMaxWidth}px) {
        margin-left: 0px;
    }
`;
export const MFRProductDateSection = styled('div')`
    height: 47px;
    margin-right: 44.5px;
`;

export const MFRDateValue = styled('div')`
    margin-top: 8px;
    margin-bottom: 4px;
    white-space: nowrap;
    font-size: 12px;

    .normal-weight {
        font-family: 'Proxima Nova';
    }
`;

export const MFRDateLabel = styled(Text)``;

export const MFRFavoriteContainer = styled('div')`
    display: flex;
    align-items: center;
    margin-top: 14px;
    @media (max-width: ${flexibleSectionSmallScreenMaxWidth}px) {
        margin-top: 8px;
    }
`;

export const MFRFavoriteItem = styled('div')`
    display: flex;
    gap: 7.5px;
    align-items: center;
    margin-right: 15.5px;
    &.favoriteItem {
        margin-right: 16.5px;
    }
    div {
        white-space: nowrap;
    }
    .ant-checkbox-inner {
        border-radius: 50%;
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-radius: 50%;
            background-color: ${green};
        }
    }
`;

export const ActionItem = styled('div')`
    align-items: center;
    display: flex;
    gap: 8px;
    margin-top: 14px;
    div {
        white-space: nowrap;
    }
    .ant-checkbox-inner {
        border-radius: 50%;
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-radius: 50%;
            background-color: ${green};
        }
    }
`;

export const MFRPricingContainer = styled('div')`
    min-width: 115px;
`;

export const ActionSection = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: start;
    .ant-radio-group {
        display: flex;
    }
`;

export const FlexibleActionSection = styled(ActionSection)`
    width: 100%;
    justify-content: space-between;
    @media (max-width: ${flexibleSectionSmallScreenMaxWidth}px) {
        flex-direction: column;
    }
`;

export const InvitePeopleLink = styled(Text)`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    justify-self: start;
    grid-column: span 2;
    cursor: pointer;
`;

export const FavoriteButton = styled(InvisibleButton)`
    align-items: flex-start;
    margin-top: 0px;
    padding-bottom: 0px;
    &.favoriteButtonText {
        padding-top: 8px;
    }
`;