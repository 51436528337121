import styled from 'styled-components';
import { Form } from 'antd';
import { error } from '../../../styles/colors';

export const CustomSelectFormItem = styled(Form.Item)`
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 10px;
        display: flex;
        align-items: center;
    }
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
            .ant-select-customize-input
        ):not(.ant-pagination-size-changer)
        .ant-select-selector {
        border-color: ${error} !important;
    }
    .ant-form-item-explain-error {
        color: ${error} !important;
    }
`;

export const OptionSpan = styled('span')`
    &.wrapLines {
        white-space: normal;
    }
    .ant-modal-content &.wrapLines {
        white-space: nowrap;
    }
`;
