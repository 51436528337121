import React, { useEffect, useState } from 'react';
import { Form, Col, Image } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useModalState } from '../../../hooks/modalState';
import { createUserAction, getUserReferencesAction } from '../../../redux/actions/userActions';
import { UserTableFields } from '../../../models/user/user';
import { CreateUserRequest } from '../../../redux/actions/userActions/types';

import ModalTitle from '../../common/modalTitle/ModalTitle';
import { SelectData } from '../../../models/common/types';
import { CloseIcon, Text } from '../../../styles/common.styles';
import {
    CreateAccountModal,
    CreateAccountRow,
    CreateAccountSpin,
    PhoneInputContainer,
    ModalInput,
    SelectRoleTipContainer,
} from './CreateOneAccountModal.styles';
import { blueDark01 } from '../../../styles/colors';
import CustomInput from '../../common/inputs/CustomInput';
import CustomSelect from '../../common/select/CustomSelect';
import ModalFooter from '../../common/modalFooter/ModalFooter';
import ErrorModal from '../../common/errorModal/ErrorModal';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'one' }) => void;
    reload: (modal: 'one', user: Array<UserTableFields>) => void;
}

const CreateOneAccountModal: React.FC<Props> = ({ isOpen, closeModal, reload }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const [selectData, setSelectedData] = useState({
        projectGroup: [{ id: '', name: ' ' }],
        allTitleRoles: [],
        allAccountRoles: [],
    });
    const errorModal = useModalState();

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldValue('organizationName', selector.authReducer.user?.organization.name);
        getDataForSelect();
    }, []);

    const getDataForSelect = async () => {
        const { payload } = (await dispatch(getUserReferencesAction())) as any;
        if (payload?.data) {
            setSelectedData({
                projectGroup: selectData.projectGroup.concat(payload.data.allProjectGroups),
                allTitleRoles: payload.data.allTitleRoles,
                allAccountRoles: payload.data.allAccountRoles.map((role: SelectData) => ({
                    ...role,
                    name: role.name.replace('Account', ''),
                })),
            });
        } else {
            errorModal.onOpen();
        }
    };

    const handleCancel = () => {
        closeModal({ key: 'one' });
    };

    const onSubmit = async (data: CreateUserRequest) => {
        Object.keys(data).forEach((key) => {
            if (!data[key]) {
                data[key] = null;
            }
        }, data);
        const { payload } = (await dispatch(
            createUserAction({
                ...data,
                userName: data.email,
                parentAccountId:
                    selector.authReducer.user?.parentAccountId ||
                    selector.authReducer.user?.accountId,
            }),
        )) as any;
        if (payload?.data) {
            reload('one', payload.data);
        }
    };

    return (
        <CreateAccountModal
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
            centered
            closeIcon={<CloseIcon />}
        >
            <CreateAccountSpin size="large" spinning={selector.userReducer.pending}>
                <ModalTitle
                    title="Create one account"
                    icon="ic-modal-title-create-one-account.svg"
                />
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    Create accounts one at a time below. Project Builder & Library will automatically
                    assign a temporary password for each account.
                </Text>
                <Form onFinish={onSubmit} form={form}>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="First name"
                                name="firstName"
                                required
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </Col>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Last name"
                                name="lastName"
                                required
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </Col>
                    </CreateAccountRow>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Email"
                                name="email"
                                required
                                rules={[
                                    { required: true, message: '' },
                                    {
                                        pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                                        message: 'Invalid email',
                                    },
                                ]}
                                block
                            />
                        </Col>
                        <Col>
                            <PhoneInputContainer>
                                <CustomInput
                                    Component={ModalInput}
                                    label="Phone"
                                    name="phoneNumber"
                                    rules={[
                                        {
                                            pattern:
                                                /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                                            message: 'Invalid phone',
                                        },
                                    ]}
                                    block
                                />

                                <CustomInput
                                    Component={ModalInput}
                                    label="Ext."
                                    name="phoneNumberExt"
                                    block
                                    rules={[
                                        {
                                            pattern: /^(\+?\d{1,3}|\d{1,4})$/,
                                            message: 'Invalid phone extension',
                                        },
                                    ]}
                                />
                            </PhoneInputContainer>
                        </Col>
                    </CreateAccountRow>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Organization name*"
                                name="organizationName"
                                props={{ disabled: true }}
                                block
                            />
                        </Col>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Title/role"
                                name="titleRoleID"
                                required
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </Col>
                    </CreateAccountRow>
                    <CreateAccountRow>
                        <Col>
                            <CustomSelect
                                label="Project Builder account type*"
                                name="accountRoleID"
                                options={selectData.allAccountRoles}
                                placeholder="Select account type"
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                            <SelectRoleTipContainer>
                                <Image
                                    width={13}
                                    preview={false}
                                    src="src/assets/images/circle-question.svg"
                                    alt="tooltip"
                                />
                                <div className="tip">
                                    <strong>Assigning account types</strong><br/>
                                    Consider what you would like each person to be able to do.<br/>
                                    <br/>
                                    <strong>Administrators (Admins)</strong> create other accounts and have ownership rights to every Project (even ones the Project's Owner has hidden from the rest of the organization); their <a target="_blank" href="https://transparencycatalog.zendesk.com/hc/en-us/articles/12889864142994-Roles">Project role</a> defaults to Admin. They can delete products from the office Library.<br/>
                                    <br/>
                                    <strong>Members</strong> create and manage Projects and can be assigned different <a target="_blank" href="https://transparencycatalog.zendesk.com/hc/en-us/articles/12889864142994-Roles">Project roles</a>, each with different permissions. Members cannot create or delete other accounts. They can add products to (but not delete products from) the office Library.<br/>
                                    <br/>
                                    <strong>Associates</strong> can only collaborate on Projects. People with Associate accounts do not have a Personal Favorite Products Library or have access to the office Library.<br/><br/>
                                    These are the capabilities they have within the Projects:<br/><br/>
                                    <ul>
                                    <li>View the Project</li>
                                    <li>Comment on products</li>
                                    <li>Upload documents and images to products</li>
                                    <li>Export documents and images</li>
                                    <li>Export Projects to .CSV</li>
                                    </ul>
                                </div>
                            </SelectRoleTipContainer>
                        </Col>
                    </CreateAccountRow>
                    <ModalFooter
                        action="Create account"
                        cancel={handleCancel}
                        submit={() => {}}
                        actionButtonWidth={118}
                    />
                </Form>
            </CreateAccountSpin>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </CreateAccountModal>
    );
};

export default CreateOneAccountModal;
