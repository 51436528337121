import { Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';
import { blueDark01 } from '../../../styles/colors';

export const ChangeRoleGroupModal = styled(Modal)`
    width: 680px !important;
    height: 283px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    .ant-divider-horizontal {
        margin-top: 34px;
    }
`;

export const ChangeRoleSpace = styled(Space)`
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 28px;
    .ant-space-item {
        width: 400px;

        #input-container {
            margin-top: 4px;
        }
    }

    .ant-form-item {
        margin-bottom: 0px;
    }
    .ant-form-item-label > label {
        height: 16px;
        vertical-align: unset;
        margin-bottom: 4px;
        line-height: 16px !important;
        font-size: 13px;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        color: ${blueDark01} !important;

        &::before {
            margin: 0 !important;
        }

        &::after {
            position: unset;
            top: 0;
            margin: 0;
        }
    }
`;

export const LabelContainer = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

export const TipContainer = styled('div')`
    height: 16px;
    line-height: 16px;
    position: relative;
    cursor: pointer;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    .access-tip {
        display: none;
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            left: 20px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 400px;
        }
        .access-tip {
            display: block;
            position: absolute;
            top: 30px;
            right: -90px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 202px;
        }
    }
`;

export const ChangeRoleTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    top: -20px;
    left: 280px;
    width: 13px;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -280px;
            left: -315px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: pre-wrap;
            text-wrap: wrap;
            word-break: break-word;
            -ms-word-break: break-all;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            z-index: 2;
            width: 500px;
        }
    }
`;
