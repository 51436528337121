import { Form } from 'antd';
import React from 'react';
import ModalTitle from '../modalTitle/ModalTitle';
import { Text, CloseIcon } from '../../../styles/common.styles';
import { blueDark01 } from '../../../styles/colors';
import ModalFooter from '../modalFooter/ModalFooter';
import { DeleteProjectModal } from './DeleteModal.styles';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'delete' }) => void;
    reload: (modal: string) => void;
    text: string;
    title: string;
    icon?: string;
}
const DeleteModal: React.FC<Props> = ({ isOpen, closeModal, reload, text, title, icon }) => {
    const handleCancel = () => {
        closeModal({ key: 'delete' });
    };

    const onDelete = () => {
        reload('delete');
    };

    return (
        <DeleteProjectModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            className="delete-modal-container"
            closeIcon={<CloseIcon />}
        >
            <Form onFinish={onDelete}>
                <ModalTitle title={`Delete ${title}`} icon={icon} />
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    Are you sure you want to delete {text}?
                </Text>
                <ModalFooter cancel={handleCancel} submit={() => {}} action="Delete" />
            </Form>
        </DeleteProjectModal>
    );
};

export default DeleteModal;
