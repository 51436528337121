import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectData } from '../../../models/common/types';
import { TableData } from '../../../models/tableData/types';
import { SMAdminUser, SMAdminUserTableFields } from '../../../adminPanel/models/user/smAdminUser';
import { buyMoreUsersBySMAdminAction, updateTrialPeriodBySMAdminAction, getSMAdminUsersAction } from '../../actions/smAdminActions';

export interface SMAdminUsersReducerType {
    users: Array<SMAdminUser>;
    selectedRows: Array<SMAdminUserTableFields>;
    pending: boolean;
    tableData: TableData;
    references?: {
        allProjectGroups: Array<SelectData>;
        allAccountRoles: Array<SelectData>;
        allTitleRoles: Array<SelectData>;
    };
}

const initialState: SMAdminUsersReducerType = {
    users: [],
    selectedRows: [],
    pending: false,
    tableData: {
        curPage: 1,
        pageSize: 10,
        searchString: '',
        sortDirection: 'asc',
        sortExpression: 'lastName',
    },
    references: {
        allProjectGroups: [],
        allAccountRoles: [],
        allTitleRoles: [],
    },
};

export const smAdminUserSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        setUsers(state, action: PayloadAction<SMAdminUser>) {
            state.users = state.users.concat(action.payload);
        },
        setSelectedRows(state, action: PayloadAction<Array<SMAdminUserTableFields>>) {
            state.selectedRows = action.payload;
        },
        clearSelectedRows(state) {
            state.selectedRows = [];
        },
        setSMAdminUsersTableData(state, action: PayloadAction<TableData>) {
            state.tableData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSMAdminUsersAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getSMAdminUsersAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getSMAdminUsersAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(buyMoreUsersBySMAdminAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(buyMoreUsersBySMAdminAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(buyMoreUsersBySMAdminAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(updateTrialPeriodBySMAdminAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(updateTrialPeriodBySMAdminAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(updateTrialPeriodBySMAdminAction.rejected, (state) => {
            state.pending = false;
        });

    },
});

export const { setSelectedRows, clearSelectedRows, setSMAdminUsersTableData } =
    smAdminUserSlice.actions;

export default smAdminUserSlice.reducer;
