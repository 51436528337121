import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getEC3AuthDataAction } from '../../../../redux/actions/ec3Actions';
import { setEC3AuthToken } from '../../../../redux/reducers/ec3Reducer/EC3Slice';
import { EC3LoginSpin, EC3RedirectContainer } from './EC3Login.styles';

const EC3Redirect: React.FC = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { ec3Reducer } = selector;
    const params = new URLSearchParams(location.search);
    useEffect(() => {
        const code = params.get('code');
        getAccessToken(code);
    }, []);

    const getAccessToken = async (code: string | null) => {
        const clientId = process.env.REACT_APP_EC3_CLIENT_ID;
        if (code && clientId) {
            const authResult = await getEC3AuthDataAction({
                code: code,
                grant_type: 'authorization_code',
                client_id: clientId,
            });
            if (authResult?.data) {
                dispatch(
                    setEC3AuthToken({
                        token: authResult.data.access_token,
                        refreshToken: authResult.data.refresh_token,
                    }),
                );
                window.location.href = ec3Reducer.pageFromRequest + `?code=${code}`;
            }
        } else {
            window.location.href = ec3Reducer.pageFromRequest;
        }
    };

    return (
        <EC3LoginSpin spinning={true}>
            <EC3RedirectContainer></EC3RedirectContainer>
        </EC3LoginSpin>
    );
};

export default EC3Redirect;
