import styled from 'styled-components';
import { Layout } from 'antd';
import { blueDark02 } from '../../../styles/colors';

const { Header } = Layout;

export const PageHeaderContainer = styled(Header)`
    background-color: ${blueDark02};
    padding: 0px 100px;
    border-top: 1px solid rgba(0, 39, 59, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1224px;
    height: unset;
    padding-top: 17px;
    padding-bottom: 17px;
    line-height: 28px;
`;

export const PageHeaderTitleContainer = styled('div')`
    display: flex;
    align-items: baseline;
    position: relative;
    button {
        position: absolute;
        top: 0;
    }
`;
