import styled from 'styled-components';
import { blueLink, grayDark05, grayLight01 } from '../../../../../styles/colors';

export const NotificationItemContainer = styled('div')`
    display: flex;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;
`;

export const NotificationTitleContainer = styled('div')`
    display: flex;
    align-items: center;
    &:hover {
        .hover {
            color: ${blueLink};
            cursor: pointer;
        }
        .switch-button {
            background-color: ${blueLink} !important;
            &:after {
                background: white;
            }
            &:before {
                background: white;
            }
        }
    }
`;

export const NotificationItemWrapper = styled('div')`
    border-bottom: 0.5px solid ${grayLight01};
    border-top: 0.5px solid ${grayLight01};
    &.clicked {
        border-bottom: 0.5px solid ${grayDark05};
        border-top: 0.5px solid ${grayDark05};
    }
`;
