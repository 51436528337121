import React, { useEffect, useState } from 'react';
import { Image, Space, Input, Dropdown } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import {
    AccessActionsButton,
    AccessTabLinkContainer,
    AccessTabTitle,
    CollaboratorEmail,
    CollaboratorsCount,
    CopyToClipboardContainer,
    InviteText,
    InviteTipContainer,
    RoleTip,
    ShareTableDataContainer,
    ShareTabTableItem,
} from './ShareProject.styles';
import { MFRProject, ProjectTableFields } from '../../../../models/project/types';
import { SpaceBetween, Text } from '../../../../styles/common.styles';
import { grayDark03 } from '../../../../styles/colors';
import CustomPagination from '../../../common/pagination/CustomPagination';
import { SelectData } from '../../../../models/common/types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { removeProjectAccessAction } from '../../../../redux/actions/projectActions';
import { CaretDownOutlined } from '@ant-design/icons';

import {
    Collaborator,
    GetProjectInformationResponse,
} from '../../../../redux/actions/projectActions/types';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { alertService } from '../../../../services';
import { AlertMessages } from '../../../alert/AlertMessages';
import { getAvailableProjectRoles } from '../../../../helpers/projectAccess';
import { ProjectPermissionTypes } from '../../../../models/permission/enums';
import InitialAvatar from '../../../common/InitialAvatar/InitialAvatar';
interface Props {
    project: ProjectTableFields | GetProjectInformationResponse | MFRProject;
    projectRoles: Array<SelectData> | undefined;
    totalCount: number;
    collaborators: Array<Collaborator>;
    handleCancel: () => void;
    handleSelect: (collaborator: Collaborator, roleId?: string) => void;
    getCollaborators: (page: number, searchString: string) => void;
    reload: (page: number, searchString: string) => Promise<void>;
}

const AccessTab: React.FC<Props> = ({
    project,
    projectRoles,
    totalCount,
    collaborators,
    handleSelect,
    getCollaborators,
    reload,
}) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { authReducer } = selector;
    const [copied, setCopied] = useState(false);
    const [projectRolesMenu, setProjectMenuRoles] = useState<{
        allRoles: Array<ItemType> | undefined;
        ownerDomain: string;
    }>({
        allRoles: undefined,
        ownerDomain: '',
    });

    const [state, setState] = useState({ searchString: '', curPage: 1 });

    useEffect(() => {
        if (!projectRoles) {
            return;
        }
        const currentProjectRoles: Array<SelectData> = JSON.parse(JSON.stringify(projectRoles));
        const allRoles = currentProjectRoles?.map((role) => ({ key: role.id, label: role.name }));
        let ownerDomain = '';
        const ownerDomainSplit = authReducer.user?.email.split('@');
        if (ownerDomainSplit) {
            ownerDomain = ownerDomainSplit[ownerDomainSplit?.length - 1];
        }
        setProjectMenuRoles({
            ...projectRolesMenu,
            allRoles: allRoles || [],
            ownerDomain: ownerDomain,
        });
    }, [projectRoles]);

    useEffect(() => {
        if (totalCount < 10) {
            setState({ ...state, curPage: 1 });
        }
    }, [totalCount]);

    const handleCopyUrl = () => {
        setCopied(true);
        alertService.success(AlertMessages.COPY_LINK);
        window.navigator.clipboard.writeText(projectUrl);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const changePage = (page: number) => {
        setState({ ...state, curPage: page });
        getCollaborators(page, state.searchString);
    };

    const handleCurrentPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentCount = event.currentTarget.value;
        if (/^-?\d*\.?\d*$/.test(currentCount) || currentCount === '') {
            setState({ ...state, curPage: +event.currentTarget.value });
        }
        if (+currentCount > Math.ceil(totalCount / 10)) {
            setTimeout(() => {
                setState({
                    ...state,
                    curPage: Math.ceil(totalCount / 10),
                });
            }, 1000);
        }
        if (
            +event.currentTarget.value > 0 &&
            +event.currentTarget.value <= Math.ceil(totalCount / 10)
        ) {
            getCollaborators(+event.currentTarget.value, state.searchString);
        }
        if (+currentCount === 0) {
            getCollaborators(1, state.searchString);
            setTimeout(() => {
                setState({
                    ...state,
                    curPage: 1,
                });
            }, 1000);
        }
    };

    const handleSelectMenu = async (collaborator: Collaborator, roleId: string) => {
        if (!roleId) {
            return;
        }
        if (roleId === 'remove') {
            const { payload } = await dispatch(
                removeProjectAccessAction({ projectId: project.id, email: collaborator.email }),
            );
            if (payload) {
                reload(1, '');
                alertService.success(AlertMessages.REMOVE_ACCESS);
            }
            return;
        }

        handleSelect(collaborator, roleId);
        const currentCollaborators = [...collaborators];
        const index = currentCollaborators.findIndex(
            (item: Collaborator) => item.email === collaborator.email,
        );
        if (currentCollaborators[index]) {
            currentCollaborators[index].temporaryRoleId = +roleId;
        }
    };

    const handleFilterPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        getCollaborators(state.curPage, event.target.value);
        setState({ ...state, searchString: event.target.value });
    };

    const isInvitedOrganizationMember = (collaborator: Collaborator): boolean => {
        const isInvited = collaborator.roleId !== null && collaborator.roleId !== undefined;
        return isInvited && (collaborator.isAccountAdmin || collaborator.isAccountMember || collaborator.isAccountAssociate);
    }

    const projectUrl = `${process.env.REACT_APP_URL}/project-info/${project?.id}`;
    return (
        <>
            <ShareTableDataContainer>
                <Space>
                    <AccessTabTitle>Collaborators</AccessTabTitle>
                    <CollaboratorsCount>({totalCount})</CollaboratorsCount>
                    <InviteTipContainer>
                        <Image src="src/assets/images/circle-question.svg" preview={false} />
                        <RoleTip className="access-tip">
                            Members of your organization who <br />
                            have Project Builder accounts are <br /> listed here in{' '}
                            <strong>boldface</strong>, but appear <br /> in light blue until you
                            invite them to <br /> the project.
                        </RoleTip>
                    </InviteTipContainer>
                </Space>
                <Space>
                    <Text weight={400} size={12} height={12} color={grayDark03}>
                        Find
                    </Text>
                    <Input onChange={handleFilterPage} />
                </Space>
                <div></div>
            </ShareTableDataContainer>
            <InviteText>Invite collaborators by selecting their role.</InviteText>
            <>
                {projectRolesMenu.allRoles && (
                    <>
                        {collaborators
                            .filter((c) => c.isCollaborator)
                            .map((collaborator) => {
                                let allRoles = Array<ItemType>();
                                const roleId = collaborator.roleId || collaborator.temporaryRoleId;
                                let actualRole = (projectRolesMenu.allRoles as any)?.find(
                                    (role: any) => role?.key === roleId,
                                )?.label;
                                const collaboratorDomainSplit = collaborator.email.split('@');
                                const collaboratorDomain =
                                    collaboratorDomainSplit[collaboratorDomainSplit.length - 1];
                                const additionalOptions: Array<ItemType> = [
                                    { type: 'divider' },
                                    {
                                        key: 'remove',
                                        label: 'Remove',
                                    },
                                ];
                                const availableRoles = getAvailableProjectRoles(
                                    projectRolesMenu.ownerDomain,
                                    collaboratorDomain,
                                    collaborator,
                                );
                                if (projectRolesMenu.allRoles) {
                                    allRoles = (projectRolesMenu.allRoles as any).filter(
                                        (role: any) =>
                                            availableRoles.some(
                                                (availableRole) => role.label === availableRole,
                                            ),
                                    );
                                    if (collaborator.roleId) {
                                        allRoles = allRoles.concat(additionalOptions);
                                    } else {
                                        actualRole = collaborator.isAccountAdmin
                                            ? ProjectPermissionTypes.PROJECT_ADMIN
                                            : ProjectPermissionTypes.COMMENTER;
                                    }
                                }

                                return (
                                    <ShareTabTableItem key={collaborator.email}>
                                        <CollaboratorEmail
                                            onClick={() => handleSelect(collaborator, `${collaborator.roleId}`)}
                                        >
                                            <InitialAvatar initial={collaborator.username?.[0] || collaborator.email[0]} outlined={!(collaborator.isAccountAdmin || collaborator.isAccountMember || collaborator.isAccountAssociate)} />
                                            <span
                                                id={isInvitedOrganizationMember(collaborator) ? 'bold' : ''}
                                                className={`collaborator-email`}
                                            >
                                                {collaborator.email}
                                            </span>
                                        </CollaboratorEmail>
                                        <div>
                                            <Dropdown
                                                menu={{
                                                    items:
                                                        allRoles?.map((role: any) => ({
                                                            ...role,
                                                            label: role.label?.replace(
                                                                'Project',
                                                                '',
                                                            ),
                                                        })) || [],
                                                    onClick: (data: { key: string }) => {
                                                        handleSelectMenu(collaborator, data.key)
                                                    },
                                                }}
                                                placement="bottom"
                                                trigger={['click']}
                                            >
                                                <AccessActionsButton>
                                                    {actualRole?.replace('Project', '') ||
                                                        'Invite as...'}{' '}
                                                    <CaretDownOutlined />
                                                </AccessActionsButton>
                                            </Dropdown>
                                        </div>
                                    </ShareTabTableItem>
                                );
                            })}
                    </>
                )}
            </>
            <SpaceBetween>
                <AccessTabLinkContainer>
                    {!copied ? (
                        <CopyToClipboardContainer onClick={handleCopyUrl}>
                            <Image src="src/assets/images/link.svg" preview={false} />
                            <AccessTabTitle>Copy project link</AccessTabTitle>
                        </CopyToClipboardContainer>
                    ) : (
                        <CopyToClipboardContainer onClick={handleCopyUrl}>
                            <CheckOutlined style={{ color: 'green' }} />
                            <AccessTabTitle>Copy project link</AccessTabTitle>
                        </CopyToClipboardContainer>
                    )}
                </AccessTabLinkContainer>
                <CustomPagination
                    page={state.curPage}
                    total={totalCount}
                    pageSize={10}
                    changePageNumber={changePage}
                    handleCurrentPage={handleCurrentPage}
                />
            </SpaceBetween>
        </>
    );
};

export default AccessTab;
