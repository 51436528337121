import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Image, Space, Table } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { addPages, removePage } from '../../redux/reducers/navigationReducer/NavigationSlice';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import { BoldText, PageContainer, SpaceBetween, Text } from '../../styles/common.styles';
import {
    DashboardContainer,
    DashboardFavoritesContainer,
    DashboardHeaderText,
    DashboardLeft,
    DashboardPageTitle,
    DashboardProductsTableContainer,
    DashboardRecentlySpin,
    DashboardRight,
    DashboardTablesContainer,
    DashboardTitleContainer,
    FavoriteTypeSelector,
    TextBold,
    TextContact,
    EmptyTextContainer
} from './Dashboard.styles';
import { blueLink, grayDark03, grayLight01, teal01 } from '../../styles/colors';
import { columns, productColumns } from './Dashboard.columns';
import { RecentlyProjectsTableFields } from '../../models/project/types';
import { SelectData } from '../../models/common/types';
import { usePermissions } from '../../hooks/permissions';
import { SubscriptionTypes } from '../../models/subscription/enums';
import { AccountPermissionTypes, UserRoleTypes } from '../../models/permission/enums';
import { getCustomerPortalLinkAction } from '../../redux/actions/checkoutActions';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { ProductFavorite } from '../../models/productFavorite/types';
import { ActionResponse } from '../../models/actionResponse/types';
import { GetRecentlyFavoritesResponse } from '../../redux/actions/favoriteProductsActions/types';
import { ProductFavoriteType } from '../../models/productFavorite/enums';

import CustomSelect from '../../components/common/select/CustomSelect';
import { setSubscriptionUpdating } from '../../redux/reducers/subscriptionReducer/SubscriptionSlice';
import { ExternalProduct } from '../../models/product/types';
import { getExternalProductsAction } from '../../redux/actions/transparencyCatalogActions';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../redux/actions/transparencyCatalogActions/types';
import { attachProductToProject } from './Dashboard.helper';
import BannerDisplayContainer from '../../components/bannerDisplay/BannerDisplayContainer';
import { useModalState } from '../../hooks/modalState';
import ContactAccountAdmins from '../../components/contactAccountAdmins/ContactAccountAdmins';
import { uploadDocumentByName } from '../../helpers/DOM';
import { getDashboardData } from '../../redux/actions/dashboard';
import { DashboardDataResponse } from '../../redux/actions/dashboard/types';
import EmptyData from '../../components/common/emptyData/EmptyData';

const DashboardPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const selector = useAppSelector((combinedState) => combinedState);
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const { authReducer, projectReducer } = selector;
    const { getUserRole, getAccountSubscription, getAccountRole } = usePermissions();
    const { onToggle, isOpen } = useModalState();

    const [state, setState] = useState({
        pending: true,
        projects: Array<RecentlyProjectsTableFields>(),
        [ProductFavoriteType.MyFavorite]: Array<ProductFavorite>(),
        [ProductFavoriteType.OfficeFavorite]: Array<ProductFavorite>(),
        companies: Array<SelectData>(),
        currentFavorites:
            getAccountSubscription() === SubscriptionTypes.OFFICE ||
                getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE
                ? ProductFavoriteType.OfficeFavorite
                : ProductFavoriteType.MyFavorite,
    });

    useEffect(() => {
        dispatch(
            addPages([
                {
                    key: NavigationKey.DASHBOARD,
                    value: `${authReducer.user?.firstName}’s Dashboard`,
                },
            ]),
        );
        const file = params.get('file');
        if (file) {
            uploadDocumentByName(file);
            window.history.replaceState(null, '', `/`);
            history.push('/');
        }
        if (authReducer.user) {
            getRecentlyProject();
        }

        return () => {
            dispatch(removePage());
        };
    }, [projectReducer.addedNewProject]);

    let defaultProjectText = 'Start a Project to see changes appear here.';
    let defaultProjectText2 = '';
    let defaultFavoriteText = 'Upgrade to a paid subscription to start saving favorite products';
    const defaultFavoriteText2 = '';
    if (getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE) {
       defaultProjectText = "Updates to Projects you`ve been invited to will appear here as they happen.";
       defaultProjectText2 = "Upgrade to a Member account to start Projects of your own.";
       defaultFavoriteText = "Upgrade to a Member account to view and save favorite products.";
    }

    const getRecentlyProject = async () => {
        const { payload } = (await dispatch(
            getDashboardData(),
        )) as ActionResponse<DashboardDataResponse>;
        if (payload.data) {
            getRecentlyFavorites(payload.data);
        }
    };

    const getRecentlyFavorites = async (data: DashboardDataResponse) => {
        const productIds = data.lastActivities
            .filter((p) => p.projectId)
            .map((p) => p.projectProduct)
            .map((pp) => pp?.productId)
            .filter(Boolean);
        if (data.lastAddedFavorites) {
            const { myFavorites, officeFavorites, products } = await getProductDetails(
                data.lastAddedFavorites,
                productIds,
            );
            setState({
                ...state,
                pending: false,
                projects: attachProductToProject(data.lastActivities, products),
                [ProductFavoriteType.MyFavorite]: myFavorites,
                [ProductFavoriteType.OfficeFavorite]: officeFavorites,
                companies:
                    getAccountSubscription() === SubscriptionTypes.OFFICE ||
                        getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE
                        ? [
                            {
                                id: ProductFavoriteType.OfficeFavorite,
                                name: `${authReducer.user?.organization?.name}’s favorite products`,
                            },
                            {
                                id: ProductFavoriteType.MyFavorite,
                                name: `My favorite products`,
                            },
                        ]
                        : [
                            {
                                id: ProductFavoriteType.MyFavorite,
                                name: `My favorite products`,
                            },
                        ],
            });
        } else {
            setState({ ...state, pending: false });
        }
    };

    const getProductDetails = async (
        favoriteProducts: GetRecentlyFavoritesResponse,
        recentlyProjectProductIds: Array<number | undefined>,
    ): Promise<GetRecentlyFavoritesResponse> => {
        const myFavoriteProductIds = favoriteProducts.myFavorites.map(
            (favorite) => favorite.product.productId,
        );
        const officeFavoriteProductIds = favoriteProducts.officeFavorites.map(
            (favorite) => favorite.product.productId,
        );
        if (
            !myFavoriteProductIds.length &&
            !officeFavoriteProductIds.length &&
            !recentlyProjectProductIds
        ) {
            return { myFavorites: [], officeFavorites: [], products: [] };
        }
        const productIds = myFavoriteProductIds
            .concat(officeFavoriteProductIds)
            .concat(recentlyProjectProductIds as any);
        if (!productIds?.length) {
            return { myFavorites: [], officeFavorites: [], products: [] };
        }
        const { payload } = (await dispatch(
            getExternalProductsAction({
                productIds,
            }),
        )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;
        if (payload.data) {
            const products = Object.values(payload.data.mfSection).reduce(
                (agg, item: any) => agg.concat(item),
                Array<ExternalProduct>(),
            );
            return {
                myFavorites: favoriteProducts.myFavorites.map((p) => ({
                    ...p,
                    name: products.find((item) => item.id === p.product.productId)?.productName,
                    product: { ...p.product, isMyFavorite: true },
                })),
                officeFavorites: favoriteProducts.officeFavorites.map((p) => ({
                    ...p,
                    name: products.find((item) => item.id === p.product.productId)?.productName,
                    product: { ...p.product, isOfficeFavorite: true },
                })),
                products: recentlyProjectProductIds.map((productId) => ({
                    productId: productId,
                    name: products.find((item) => item.id === productId)?.productName,
                })),
            };
        }
        return { myFavorites: [], officeFavorites: [], products: [] };
    };

    const goToSubscriptions = async () => {
        if (
            getAccountSubscription() === SubscriptionTypes.FREE ||
            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_INDIVIDUAL ||
            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE
        ) {
            history.push('/plan');
            return;
        }
        if (authReducer.user?.accountId) {
            const { payload } = (await dispatch(getCustomerPortalLinkAction())) as any;
            if (payload) {
                dispatch(setSubscriptionUpdating(true));
                window.location.href = payload.data;
            }
        }
    };

    const handleRecentlyFavorites = (key: ProductFavoriteType) => {
        setState({ ...state, currentFavorites: key });
    };

    const getFavoriteLink = () => {
        let favoriteLink = '/my-favorites';
        if (
            getAccountSubscription() === SubscriptionTypes.OFFICE ||
            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE
        ) {
            favoriteLink =
                state.currentFavorites === ProductFavoriteType.MyFavorite
                    ? 'my-favorites'
                    : '/favorites';
        }
        return favoriteLink;
    };

    return (
        <>
            <PageHeader
                title={`${authReducer.user?.firstName}’s Dashboard`}
                children={
                    <DashboardHeaderText onClick={onToggle}>
                        {authReducer.user?.parentAccountId && (
                            <>
                                <BoldText weight={400} size={13} height={16} color={grayLight01}>
                                    Need help?
                                </BoldText>
                                <Text weight={400} size={13} height={16} color={grayLight01}>
                                    {`Contact the `}
                                </Text>
                                <Text weight={400} size={13} height={16} color={teal01}>
                                    {`admin(s)`}
                                    <Text weight={400} size={13} height={16} color={grayLight01}>
                                        .
                                    </Text>
                                </Text>
                            </>
                        )}
                    </DashboardHeaderText>
                }
            />
            <PageContainer>
                <DashboardContainer>
                    <DashboardLeft>
                        <DashboardTitleContainer>
                            <Image
                                src="src/assets/images/project-menu.svg"
                                preview={false}
                                alt=""
                            />
                            <DashboardPageTitle>Recently active Projects</DashboardPageTitle>
                        </DashboardTitleContainer>
                        <SpaceBetween>
                            <h3>
                                <Text weight={400} size={13} height={16} color={grayDark03}>
                                    Activity for Projects you own or contribute to
                                </Text>
                            </h3>
                            <NavLink to="/project-library">
                                <TextBold weight={400} size={13} height={16} color={blueLink}>
                                    Visit your list of Projects
                                </TextBold>
                            </NavLink>
                        </SpaceBetween>
                        <DashboardTablesContainer
                            className={`antd-table ${state.projects.length ? '' : 'empty'}`}
                            id="project-dashboard"
                        >
                            <DashboardRecentlySpin spinning={state.pending}>
                                <Table
                                    columns={columns()}
                                    dataSource={state.projects}
                                    rowKey="id"
                                    locale={{
                                        emptyText: (
                                          <>
                                            <EmptyData
                                                text={defaultProjectText}
                                                text2={defaultProjectText2}
                                                pending={state.pending}
                                            />
                                            {getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE &&
                                                <TextContact onClick={onToggle}>
                                                    {`Contact your account admin to request a Member account `}
                                                </TextContact>
                                            }
                                          </>
                                        ),
                                    }}
                                />
                            </DashboardRecentlySpin>
                        </DashboardTablesContainer>
                        <DashboardFavoritesContainer>
                            <Image src="src/assets/images/star.svg" preview={false} alt="" />
                            <DashboardPageTitle>
                                Recently added favorite products
                            </DashboardPageTitle>
                        </DashboardFavoritesContainer>
                        {getAccountSubscription() !== SubscriptionTypes.FREE && getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE ? <>
                            <SpaceBetween>
                                <Space>
                                    <Text weight={400} size={13} height={16} color={grayDark03}>
                                        Show
                                    </Text>
                                    <FavoriteTypeSelector>
                                        <CustomSelect
                                            options={state.companies}
                                            props={{ onChange: handleRecentlyFavorites }}
                                            value={state.currentFavorites}
                                        />
                                    </FavoriteTypeSelector>
                                </Space>
                                <NavLink to={getFavoriteLink()}>
                                    <TextBold weight={400} size={13} height={16} color={blueLink}>
                                        {state.currentFavorites === ProductFavoriteType.MyFavorite
                                            ? 'Visit my Favorite Products Library'
                                            : `Visit ${authReducer.user?.organization.name} Favorite Products Library`}
                                    </TextBold>
                                </NavLink>
                            </SpaceBetween>
                            <DashboardProductsTableContainer
                                className={`antd-table ${state[state.currentFavorites]?.length ? '' : 'empty'
                                    }`}
                            >
                                <DashboardRecentlySpin spinning={state.pending}>
                                    <Table
                                        columns={productColumns()}
                                        dataSource={state[state.currentFavorites]}
                                        rowKey="id"
                                        locale={{
                                            emptyText: (
                                                <EmptyData
                                                    text="Save favorite products to see changes appear here."
                                                    pending={state.pending}
                                                />
                                            ),
                                        }}
                                    />
                                </DashboardRecentlySpin>
                            </DashboardProductsTableContainer>
                        </> : <EmptyTextContainer><EmptyData
                            text={defaultFavoriteText}
                            text2={defaultFavoriteText2}
                            pending={false}
                        />
                                {getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE &&
                                    <TextContact onClick={onToggle}>
                                        {`Contact your account admin to request a Member account `}
                                    </TextContact>
                                }
                        </EmptyTextContainer>}
                    </DashboardLeft>

                    <DashboardRight>
                        <BannerDisplayContainer />
                    </DashboardRight>
                </DashboardContainer>
                {isOpen && <ContactAccountAdmins closeModal={onToggle} isOpen={isOpen} />}
            </PageContainer>
        </>
    );
};

export default DashboardPage;
