import { AccountPermissionTypes, ProjectPermissionTypes } from '../models/permission/enums';
import { FavoriteType } from '../models/product/enums';
import { SubscriptionTypes } from '../models/subscription/enums';
import { useAppSelector } from './redux';

export const usePermissions = () => {
    const user = useAppSelector((state) => state.authReducer.user);

    const getUserEmail = (): string | undefined => {
        return user?.email;
    };

    const checkPermission = (action: string): boolean => {
        if (user?.permissions) {
            const actualPermission = user?.permissions.find((item) => item.action === action);
            return actualPermission?.isAllowed || false;
        }
        return false;
    };

    const getUserRole = (): string | undefined => {
        return user?.appRole;
    };

    const getAccountSubscription = (): SubscriptionTypes | undefined => {
        return user?.subscriptionPlanName;
    };

    const getAccountRole = (): AccountPermissionTypes | undefined => {
        return user?.accountRole;
    };

    const getProjectPermission = (
        availableRoles: Array<ProjectPermissionTypes>,
        actualRole?: ProjectPermissionTypes,
    ): boolean => {
        if (!actualRole) {
            return false;
        }
        return !!availableRoles.filter((role) => role === actualRole).length;
    };

    const checkProductActionPermission = (
        favoriteType?: FavoriteType,
        userRole?: ProjectPermissionTypes,
    ) => {
        if (favoriteType !== undefined) {
            if (favoriteType === FavoriteType.OfficeFavorite) {
                return getAccountRole() !== AccountPermissionTypes.ACCOUNT_ADMIN;
            }
            return false;
        }
        return !(
            userRole === ProjectPermissionTypes.PROJECT_ADMIN ||
            userRole === ProjectPermissionTypes.OWNER
        );
    };

    return {
        getUserEmail,
        checkPermission,
        getUserRole,
        getAccountSubscription,
        getAccountRole,
        getProjectPermission,
        checkProductActionPermission,
    };
};
