import React from 'react';
import Switcher from '../../../components/common/switcher/Switcher';
import { blueDark01 } from '../../../styles/colors';
import {
    MFRDivisionItemWrapper,
    MFRDivisionItemContainer,
    MFRDivisionTitleContainer,
    MFRDivisionTitle,
} from './MFRDivision..styles';
import { ProductSwitcherContent, Text } from '../../../styles/common.styles';
import { Division } from '../../../models/productDivision/types';
import { Product } from '../../../models/product/types';
import MFRSectionItem from '../mfrSection/MFRSection';
import { ProductReferences } from '../../../redux/actions/productActions/types';
import { MFRProject } from '../../../models/project/types';

interface Props {
    division: Division<Product>;
    projectData: MFRProject;
    handleClicked: (divisionId: string) => void;
    handleSectionOpen: (divisionId: string, sectionId: string) => void;
    references: ProductReferences | null;
    editable: boolean;
    commentId?: string;
    selectedProductId?: string;
    openingMode?: string;
    showHelp: boolean;
}

const MFRDivisionItem: React.FC<Props> = ({
    division,
    projectData,
    handleClicked,
    handleSectionOpen,
    references,
    editable,
    commentId,
    selectedProductId,
    openingMode,
    showHelp,
}) => {
    const handleSectionClicked = (sectionId: string) => {
        handleSectionOpen(division.id, sectionId);
    };

    return (
        <MFRDivisionItemWrapper>
            <MFRDivisionItemContainer>
                <MFRDivisionTitleContainer
                    onClick={() => {
                        handleClicked(division.id);
                    }}
                >
                    <Switcher
                        id={division.id}
                        clicked={division.clicked}
                        handleSwitch={handleClicked}
                        width={24}
                        height={24}
                    />
                    <MFRDivisionTitle className="division-id hover">
                        {division.id[0] + division.id[1]}
                    </MFRDivisionTitle>
                    <MFRDivisionTitle className="hover">{division.name}</MFRDivisionTitle>
                    <Text weight={400} size={20} height={24} color={blueDark01} className="hover">
                        (
                        {division.sections.reduce((agg, item) => {
                            agg += item.products.length;
                            return agg;
                        }, 0)}
                        )
                    </Text>
                </MFRDivisionTitleContainer>
            </MFRDivisionItemContainer>
            <ProductSwitcherContent className={division.clicked ? 'clicked' : ''}>
                {division.sections.map((section) => (
                    <MFRSectionItem
                        section={section}
                        key={section.id}
                        projectData={projectData}
                        handleClicked={handleSectionClicked}
                        references={references}
                        editable={editable}
                        commentId={commentId}
                        selectedProductId={selectedProductId}
                        openingMode={openingMode}
                        showHelp={showHelp}
                    />
                ))}
            </ProductSwitcherContent>
        </MFRDivisionItemWrapper>
    );
};

export default MFRDivisionItem;
