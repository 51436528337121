import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import CreateOneAccountModal from '../../components/users/createOneAccountModal/CreateOneAccountModal';
import UsersTable from '../../components/users/usersTable/UsersTable';
import CreateBulkAccountModal from '../../components/users/createBulkAccountModal/CreateBulkAccountModal';
import { actionMenu, createAccountMenu } from './UsersPage.mockData';
import {
    deleteBulkUsersAction,
    getAccountSettingsAction,
    getUsersAction,
} from '../../redux/actions/userActions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { TableData } from '../../models/tableData/types';
import { TableUserData, UserTableFields } from '../../models/user/user';
import { extractFields } from '../../helpers/extract';
import ChangeRoleModal from '../../components/users/changeRoleGroupModal/changeRoleGroupModal';
import { clearSelectedRows, setUsersTableData } from '../../redux/reducers/usersReducer/UserSlice';
import DeleteModal from '../../components/common/deleteModal/DeleteModal';

import {
    CreateAccountButton,
    CreateAccountButtonContainer,
    CreateAccountMenu,
    UsersPageActionsButton,
    UsersTableActions,
} from './UsersPage.styles';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import { PageContainer } from '../../styles/common.styles';
import { UsersPageSearch } from './UsersPageSearch';
import { alertService } from '../../services';
import { GetAccountSettingsResponse } from '../../redux/actions/userActions/types';
interface UsersParams {
    from: string;
}

const UsersPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { userReducer, authReducer } = selector;
    const params = useParams<UsersParams>();

    const [openCreateMenu, setOpenCreateMenu] = useState(false);
    const [openActionsMenu, setOpenActionsMenu] = useState(false);
    const [disableAddingUsers, setDisableAddingUsers] = useState(false);

    const [tableData, setTableData] = useState<TableData>({
        curPage: 1,
        pageSize: 10,
        searchString: '',
        sortDirection: 'asc',
        sortExpression: '',
    });

    const [state, setState] = useState({
        users: Array<UserTableFields>(),
        total: 0,
    });

    const [openModals, setOpenModals] = useState({
        one: false,
        bulk: false,
        group: false,
        role: false,
        delete: false,
    });

    useEffect(() => {
        getData();
        dispatch(clearSelectedRows());
        history.replaceState(null, '', '/users');
    }, []);

    const getData = async () => {
        const dataTable = params.from ? selector.userReducer.tableData : tableData;
        await getUsers(dataTable);
        await getAccountSettings();
    };

    const getAccountSettings = async () => {
        const { payload } = (await dispatch(
            getAccountSettingsAction(),
        )) as GetAccountSettingsResponse;
        if (payload.data.subscription) {
            setDisableAddingUsers(
                payload.data.subscription?.userInUse >= payload.data.subscription?.maxUserCount,
            );
        }
    };

    const getUsers = async (currentTableData: TableData, newUsers?: Array<UserTableFields>) => {
        const additionalUsers = Array.isArray(newUsers) ? newUsers : [];
        const { payload } = (await dispatch(getUsersAction(currentTableData))) as any;
        const result = extractFields<UserTableFields>(
            Object.keys(new TableUserData()),
            payload.data.concat(additionalUsers),
        );
        const users = [
            ...new Map(result.map((item: UserTableFields) => [item.key, item])).values(),
        ] as Array<UserTableFields>;
        dispatch(setUsersTableData(currentTableData));

        setTableData(currentTableData);
        setState({
            users: users,
            total: payload.usersOnAllPagesCount,
        });
    };

    const handleCreateMenu = () => {
        setOpenCreateMenu(!openCreateMenu);
    };

    const handleMenuSelect = (data: { key: string }) => {
        if (data.key === 'delete') {
            const selectedUsersIds = userReducer.selectedRows.map((x) => String(x.key));
            const currentUsersId = String(authReducer.user?.id);
            if (selectedUsersIds.includes(currentUsersId)) {
                alertService.error("You can't delete your own account with this command");
                return;
            }
        }

        if (data.key === 'bulk') {
          reloadAfterCloseModal(tableData);
        }

        setOpenCreateMenu(false);
        setOpenActionsMenu(false);
        setOpenModals({ ...openModals, [data.key]: !openModals[data.key] });
    };

    const handleActionsMenu = () => {
        setOpenActionsMenu(!openActionsMenu);
    };

    const handleSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.value && tableData.searchString) {
                getUsers({ ...tableData, searchString: '' });
            }
            setTableData({ ...tableData, searchString: event.target.value });
        },
        [tableData.searchString],
    );

    const onSearch = () => {
        getUsers({ ...tableData, curPage: 1 });
    };

    const clearSearchInput = () => {
        getUsers({ ...tableData, searchString: '' });
    };

    const reloadAfterCloseModal = async (modal: string, users?: Array<UserTableFields>) => {
        setOpenModals({ ...openModals, [modal]: false });
        await getUsers(tableData, users);
        await getAccountSettings();
        dispatch(clearSelectedRows());
    };

    const deleteUsers = async () => {
        const { payload } = await dispatch(
            deleteBulkUsersAction(userReducer.selectedRows.map((user) => user.key)) as any,
        );
        if (payload?.message) {
            setOpenModals({ ...openModals, delete: false });
            dispatch(clearSelectedRows());
            await getUsers(tableData);
            await getAccountSettings();
        }
    };

    const createButton = (
        <div>
            <Dropdown
                menu={{
                    items: createAccountMenu,
                    onClick: handleMenuSelect,
                    style: { top: -36 },
                }}
                placement="bottom"
                trigger={['click']}
                onOpenChange={handleCreateMenu}
            >
                <CreateAccountButtonContainer>
                    <CreateAccountButton id="create-user-button">
                        Create account(s)
                    </CreateAccountButton>
                    <CreateAccountMenu id="create-user-menu">
                        <CaretDownOutlined style={{ color: 'white' }} />
                    </CreateAccountMenu>
                </CreateAccountButtonContainer>
            </Dropdown>
        </div>
    );

    return (
        <>
            <PageHeader title="Users" children={createButton} />
            <PageContainer>
                <div>
                    <UsersTableActions>
                        <Dropdown
                            menu={{
                                items: actionMenu,
                                onClick: handleMenuSelect,
                                style: { top: 0 },
                            }}
                            disabled={!selector.userReducer.selectedRows?.length}
                            placement="bottom"
                            trigger={['click']}
                            onOpenChange={handleActionsMenu}
                        >
                            <UsersPageActionsButton>
                                Actions{' '}
                                {openActionsMenu ? <CaretUpOutlined /> : <CaretDownOutlined />}
                            </UsersPageActionsButton>
                        </Dropdown>
                    </UsersTableActions>
                    <UsersTable
                        getUsers={getUsers}
                        tableData={tableData}
                        users={state.users}
                        totalCount={state.total}
                        isPending={userReducer.pending}
                        searchComponent={
                            <UsersPageSearch
                                tableData={tableData}
                                totalCount={state.total}
                                onSearch={onSearch}
                                handleSearch={handleSearch}
                                clearSearchInput={clearSearchInput}
                            />
                        }
                        setTableData={setTableData}
                    />
                </div>
                {openModals.one && (
                    <CreateOneAccountModal
                        isOpen={openModals.one}
                        closeModal={handleMenuSelect}
                        reload={reloadAfterCloseModal}
                    />
                )}
                {openModals.bulk && (
                    <CreateBulkAccountModal
                        isOpen={openModals.bulk}
                        closeModal={handleMenuSelect}
                        reload={reloadAfterCloseModal}
                    />
                )}
                {openModals.group && (
                    <ChangeRoleModal
                        isOpen={openModals.group}
                        closeModal={handleMenuSelect}
                        reload={reloadAfterCloseModal}
                        field="group"
                        usersCount={userReducer.selectedRows.length}
                    />
                )}
                {openModals.role && (
                    <ChangeRoleModal
                        isOpen={openModals.role}
                        closeModal={handleMenuSelect}
                        reload={reloadAfterCloseModal}
                        field="role"
                        usersCount={userReducer.selectedRows.length}
                    />
                )}
                {openModals.delete && (
                    <DeleteModal
                        isOpen={openModals.delete}
                        closeModal={handleMenuSelect}
                        reload={deleteUsers}
                        title="users"
                        text={userReducer.selectedRows
                            .map((item) => `${item.firstName} ${item.lastName}`)
                            .join(', ')}
                        icon="trash-can-list.svg"
                    />
                )}
            </PageContainer>
        </>
    );
};

export default UsersPage;
