import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../../helpers/request';
import { ActionResponse } from '../../../models/actionResponse/types';
import {
    BuyMoreUsersRequest,
    ConfirmPaymentRequest,
    CreateCheckoutLinkRequest,
    CreateFreeSubscriptionRequest,
    CreateTrialSubscriptionRequest,
} from './types';

const baseUrl = `/checkout`;

export const getCheckoutLinkAction = createAsyncThunk<any | undefined, CreateCheckoutLinkRequest>(
    'checkout/getCheckoutLink',
    async (data: CreateCheckoutLinkRequest) => {
        const result = await http<any, CreateCheckoutLinkRequest>(
            {
                path: `${baseUrl}/createLink`,
                method: 'post',
                body: data,
            },
            false,
        );
        console.log('GetCheckoutLink:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const getCheckoutUpdateLinkAction = createAsyncThunk<
    any | undefined,
    CreateCheckoutLinkRequest
>('checkout/getCheckoutLink', async (data: CreateCheckoutLinkRequest) => {
    const result = await http<any, CreateCheckoutLinkRequest>(
        {
            path: `${baseUrl}/CreateUpdateLink`,
            method: 'post',
            body: data,
        },
        false,
    );
    console.log('CreateUpdateLink:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const createTrialSubscriptionAction = createAsyncThunk<
    any | undefined,
    CreateTrialSubscriptionRequest
>('checkout/createTrialSubscription', async (data: CreateTrialSubscriptionRequest) => {
    const result = await http<any, CreateTrialSubscriptionRequest>(
        {
            path: `${baseUrl}/trial`,
            method: 'post',
            body: data,
        },
        false,
    );
    console.log('CreateTrialSubscription:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const createFreeSubscriptionAction = createAsyncThunk<
    any | undefined,
    CreateFreeSubscriptionRequest
>('checkout/createFreeSubscription', async (data: CreateFreeSubscriptionRequest) => {
    const result = await http<any, CreateFreeSubscriptionRequest>(
        {
            path: `${baseUrl}/freeSubscription`,
            method: 'post',
            body: data,
        },
        false,
    );
    console.log('CreateFreeSubscription:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const confirmPaymentAction = createAsyncThunk<any | undefined, ConfirmPaymentRequest>(
    'checkout/confirmPayment',
    async (data: ConfirmPaymentRequest) => {
        const result = await http<any, ConfirmPaymentRequest>(
            {
                path: `${baseUrl}/confirmPayment`,
                method: 'post',
                body: data,
            },
            false,
        );
        console.log('ConfirmPayment:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const confirmUpdateSubscriptionPaymentAction = createAsyncThunk<
    any | undefined,
    ConfirmPaymentRequest
>('checkout/confirmUpdateSubscriptionPayment', async (data: ConfirmPaymentRequest) => {
    const result = await http<any, ConfirmPaymentRequest>(
        {
            path: `${baseUrl}/confirmUpdatePayment`,
            method: 'post',
            body: data,
        },
        false,
    );
    console.log('ConfirmUpdateSubscriptionPayment:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getCustomerPortalLinkAction = createAsyncThunk<any | undefined, undefined>(
    'checkout/getCustomerPortalLink',
    async () => {
        const result = await http<any, undefined>(
            {
                path: `${baseUrl}/create-customer-portal-session`,
                method: 'post',
            },
            true,
        );
        console.log('GetCustomerPortalLink:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const buyMoreUsersAction = createAsyncThunk<
    ActionResponse<any> | undefined,
    BuyMoreUsersRequest
>('checkout/buyMoreUsers', async (data: BuyMoreUsersRequest) => {
    const result = await http<ActionResponse<any>, BuyMoreUsersRequest>({
        path: `${baseUrl}/update-subscription-quantity`,
        method: 'post',
        body: data,
    });

    console.log('BuyMoreUsers:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const cancelSubscriptionAction = createAsyncThunk<
    ActionResponse<any> | undefined,
    BuyMoreUsersRequest
>('checkout/buyMoreUsers', async (data: BuyMoreUsersRequest) => {
    const result = await http<ActionResponse<any>, BuyMoreUsersRequest>({
        path: `${baseUrl}/update-subscription-quantity`,
        method: 'post',
        body: data,
    });

    console.log('BuyMoreUsers:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
