import React, { useEffect, useState } from 'react';
import { Form, Select, Image } from 'antd';
import { blue01 } from '../../../../styles/colors';
import { StartNewProjectTitle } from '../StartProject.styles';
import { ExistingProjectContainer, StartProjectField } from './ChooseExistingProject.styles';
import { Text } from '../../../../styles/common.styles';
import { useAppDispatch } from '../../../../hooks/redux';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { AvailableProject } from '../../../../models/project/types';
import { getAvailableProjectsAction } from '../../../../redux/actions/projectActions';

interface Props {
    setSelectedProject: React.Dispatch<React.SetStateAction<AvailableProject | null>>;
}

const ChooseExistingProject: React.FC<Props> = ({ setSelectedProject }) => {
    const dispatch = useAppDispatch();

    const [availableProjects, setAvailableProjects] = useState({
        current: Array<AvailableProject>(),
        all: Array<AvailableProject>(),
    });

    useEffect(() => {
        getAvailableProjects();
    }, []);

    const getAvailableProjects = async () => {
        const { payload } = (await dispatch(getAvailableProjectsAction())) as ActionResponse<
            Array<AvailableProject>
        >;
        if (payload.data) {
            const projects = payload.data.map((project) => ({
                ...project,
                value: project.id.toString(),
                label: project.name,
            }));
            projects.unshift({ label: 'None', value: 0, id: 0 } as any);
            setAvailableProjects({
                ...availableProjects,
                current: projects,
                all: projects,
            });
        }
    };

    const handleProjectSelectSelect = (value: string) => {
        let selectedProject = null;
        if (value) {
            selectedProject = availableProjects.all.find(
                (project) => project.id.toString() === value.toString(),
            );
        }
        setSelectedProject(selectedProject || null);
    };

    return (
        <>
            <ExistingProjectContainer>
                <Text weight={500} height={16} size={13} color={blue01}>
                    ADD PRODUCTS TO THIS PROJECT
                </Text>
                <StartProjectField>
                    <div>
                        <Form.Item name={'project'} label={'Existing project'}>
                            <Select
                                aria-required
                                placeholder={'Select project'}
                                onChange={handleProjectSelectSelect}
                                allowClear={true}
                                showSearch={true}
                                filterOption={(input, option) =>
                                    (option?.label.toLowerCase() ?? '').includes(
                                        input.toLowerCase(),
                                    )
                                }
                                options={[
                                    {
                                        label: 'Projects',
                                        options: availableProjects.current.filter(
                                            (project) => project.isTemplate !== true,
                                        ),
                                    },
                                    {
                                        label: 'Templates',
                                        options: availableProjects.current.filter(
                                            (project) => project.isTemplate === true,
                                        ),
                                    },
                                ]}
                                suffixIcon={
                                    <Image
                                        src="src/assets/images/ic-dropdown-arrow.svg"
                                        preview={false}
                                    />
                                }
                            />
                        </Form.Item>
                    </div>
                </StartProjectField>
            </ExistingProjectContainer>
            <StartNewProjectTitle>
                <Text weight={500} height={16} size={13} color={blue01}>
                    OR START A NEW PROJECT
                </Text>
            </StartNewProjectTitle>
        </>
    );
};

export default ChooseExistingProject;
