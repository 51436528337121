import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigationKey } from '../../../components/appNavigationBar/AppNavigation.types';

interface NavigationItem {
    key: NavigationKey | string;
    value: string;
}
export interface AppNavigation {
    pages: Array<NavigationItem>;
}
const initialState: AppNavigation = {
    pages: [{ key: NavigationKey.HOME, value: 'SM Transparency Catalog' }],
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: initialState,
    reducers: {
        addPage(state, action: PayloadAction<NavigationItem>) {
            state.pages.push(action.payload);
            state.pages = [...new Map(state.pages.map((item) => [item.key, item])).values()];
        },
        removePage(state) {
            if (state.pages.length > 1) {
                state.pages.pop();
                state.pages = state.pages;
            }
        },
        addPages(state, action: PayloadAction<Array<NavigationItem>>) {
            state.pages = action.payload;
        },
        removePages(state) {
            state.pages = initialState.pages;
        },
    },
});

export const { addPage, removePage, addPages, removePages } = navigationSlice.actions;

export default navigationSlice.reducer;
