import React, { useEffect, useState, useRef } from 'react';
import { Image } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { InvisibleButton, SpaceEnd } from '../../../../styles/common.styles';

import {
    DivisionsContainer,
    EC3Link,
    NoProductsTitleContainer,
    NoProductsTitleContainerFiltered,
    ProductSpin,
    ProductsTabContent,
    ProductsTabHeader,
    ProductsTabPageContainer,
    OpenCloseAllButton,
    ViewProjectInEC3Button,
    FoundResultsCount,
} from './ProductsTab.styles';
import ProductsSearch from '../../../../components/products/productsTable/ProductsSearch';
import { GetProjectInformationResponse } from '../../../../redux/actions/projectActions/types';
import { Text } from '../../../../styles/common.styles';
import { blueLink } from '../../../../styles/colors';
import ProductsAction from '../../../../components/common/productsAction/ProductsAction';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
    getProjectProductsAction,
    getProjectProductsWithSearchAction,
    getProductReferencesAction,
    getProductIdsByPackageIdAction,
    addProductsToProjectAction,
    getProjectProductsWithCopyTokensOptionAction,
} from '../../../../redux/actions/productActions';
import { ActionResponse } from '../../../../models/actionResponse/types';
import {
    AddProductsToProjectResponse,
    GetProductIdsByPackageIdResponse,
    ProductReferences,
} from '../../../../redux/actions/productActions/types';
import DivisionItem from './divisionItem/DivisionItem';
import { Division, Section } from '../../../../models/productDivision/types';
import {
    BrandCompany,
    ExternalProduct,
    InternalProduct,
    Product,
} from '../../../../models/product/types';
import { usePermissions } from '../../../../hooks/permissions';
import { ProjectPermissionTypes } from '../../../../models/permission/enums';
import { ProjectReferences } from '../../../../models/project/types';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../../../redux/actions/transparencyCatalogActions/types';
import ExistingProductsModal from '../../../../components/existingProductsModal/ExistingProductsModal';
import { EC3ExportStatus } from '../../../../models/ec3/enums';
import {
    getCompaniesOfProducts,
    getExternalProductsAction,
} from '../../../../redux/actions/transparencyCatalogActions';

interface Props {
    projectInfo: GetProjectInformationResponse | null;
    projectReferences: ProjectReferences | null;
    allDivisions: Array<Division<Product>>;
    installedDivisions: Array<Division<Product>>;
    isFilterMode?: boolean;
    isFilteringInProgress?: boolean;
    filteredDivisions: Array<Division<Product>>;
    installedTab?: boolean;
    packageId?: string;
    setDivisions: (divisions: Array<Division<Product>>) => void;
    setAllDivisions: (divisions: Array<Division<Product>>) => Array<Division<Product>>;
    setInstalledDivisions: (divisions: Array<Division<Product>>) => Array<Division<Product>>;
    setFilteredDivisions: (divisions: Array<Division<Product>>) => Array<Division<Product>>;
    setFilteredMode: (filterMode: boolean) => boolean;
    setFilteringInProgress: (filterInProgress: boolean) => boolean;
    searchText: string;
    setSearch: (searchText: string) => string;
}

interface State {
    products: Array<Product>;
    pending: boolean;
    searchString: string;
}

const ProductsTab: React.FC<Props> = ({
    projectInfo,
    projectReferences,
    installedTab,
    allDivisions,
    installedDivisions,
    isFilterMode,
    isFilteringInProgress,
    filteredDivisions,
    packageId,
    setDivisions,
    setAllDivisions,
    setInstalledDivisions,
    setFilteredDivisions,
    setFilteredMode,
    setFilteringInProgress,
    searchText,
    setSearch,
}) => {
    const existingProductIds = useRef(Array<number>());
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { ec3Reducer } = selector;
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [state, setState] = useState<State>({
        products: Array<Product>(),
        pending: true,
        searchString: null,
    });

    const [existingProducts, setExistingProducts] = useState({
        products: Array<{ id: number; name: string }>(),
        productIds: Array<number>(),
        isModalOpen: false,
    });

    const [references, setReferences] = useState<ProductReferences | null>(null);

    const [brandsInformation, setBrandsInformation] = useState(Array<BrandCompany>());

    const { getProjectPermission } = usePermissions();

    useEffect(() => {
        if (projectInfo) {
            window.scroll(0, 0);
            if (!state.searchString) {
                getData();
            }
        }
    }, [projectInfo]);

    const getData = async () => {
        await copyTokensFromCookiesIfAuthFails();
        await getProductIdsByPackageId();
        await getProductReferences();
        await getProductIds();
    };

    const changeSearch = (searchString: string) => {
        setState({
            ...state,
            searchString: searchString,
        });
        setSearch(searchString);
    };

    const submitSearch = () => {
        setFilteringInProgress(true);
        setFilteredMode(state.searchString);
        if (!state.searchString) {
            setFilteredDivisions([]);
            setFilteringInProgress(false);
            return;
        }
        getData();
    };

    const cleanFilter = () => {
        setFilteringInProgress(true);
        changeSearch('');
        setFilteredMode(false);
        setFilteredDivisions([]);
        setFilteringInProgress(false);
    };

    const getBrandsInformation = async (products: Array<Product>) => {
        const companies = await getCompaniesOfProducts(products, dispatch);
        setBrandsInformation(companies);
    };

    const getProductIdsByPackageId = async () => {
        if (projectInfo && packageId && packageId.length > 1) {
            const { payload } = (await dispatch(
                getProductIdsByPackageIdAction({
                    productPackageID: packageId,
                }),
            )) as GetProductIdsByPackageIdResponse;
            if (payload.result) {
                await addProductsToProject(
                    +projectInfo.id,
                    payload.result.map((r) => r.productSelectedOnTcId),
                );
                window.history.replaceState(null, '', `/project-info/${projectInfo?.id}/0/2`);
                history.push(`/project-info/${projectInfo?.id}/0/2`);
            }
        }
    };

    const addProductsToProject = async (projectId: number, productIds: Array<number>) => {
        const { payload } = (await dispatch(
            addProductsToProjectAction({ products: productIds, projectId: projectId }),
        )) as ActionResponse<AddProductsToProjectResponse>;
        if (payload.data.existingProducts.length) {
            existingProductIds.current = payload.data.existingProducts;
        }
    };

    const copyTokensFromCookiesIfAuthFails = async () => {
        if (projectInfo) {
            console.log('copyTokensFromCookiesIfAuthFails in ProductsTab');
            await dispatch(getProjectProductsWithCopyTokensOptionAction(projectInfo.id));
        }
    }

    const getProductIds = async () => {
        if (projectInfo && state.searchString) {
            const { payload } = (await dispatch(
                getProjectProductsWithSearchAction({projectId: projectInfo.id, searchString: state.searchString ?? ''}),
            )) as ActionResponse<Array<InternalProduct>>;
            if (payload.data) {
               getProducts(payload.data);
            }
        } else if (projectInfo) {
            const { payload } = (await dispatch(
                getProjectProductsAction(projectInfo.id),
            )) as ActionResponse<Array<InternalProduct>>;
            if (payload.data) {
               getProducts(payload.data);
            }
        }
    };

    const getProductReferences = async () => {
        const { payload } = (await dispatch(
            getProductReferencesAction(),
        )) as ActionResponse<ProductReferences>;
        setReferences(payload.data);
    };

    const getProducts = async (internalProducts: Array<InternalProduct>) => {
        const productIds = internalProducts.map((p) => p.productId);
        if (!productIds.length) {
            setState({
                ...state,
                products: [],
                pending: false,
            });
            setDivisions([]);
            return;
        }
        const { payload } = (await dispatch(
            getExternalProductsAction({ productIds }),
        )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;

        if (payload.data) {
            const availableProducts = Object.values(payload.data.mfSection).reduce(
                (agg, item: any) => agg.concat(item),
                Array<ExternalProduct>(),
            );
/*
            const currentProducts0 = availableProducts.reduce((agg, value) => {
                const internalProduct = internalProducts.find(
                    (product) => product.productId === value.id,
                );
                if (internalProduct) {
                    agg.push({
                        ...value,
                        ...internalProduct,
                        dateAdded: internalProduct.dateAdded,
                        lastUpdated: internalProduct.lastUpdated,
                        product: internalProduct,
                        lowestLevelNumber:
                            value?.mfSectionLevel3?.number ||
                            value?.mfSectionLevel2?.number ||
                            value?.mfSectionLevel1?.number ||
                            value.mfSection.number,
                        lowestLevelLabel:
                            value.mfSectionLevel3?.label ||
                            value.mfSectionLevel2?.label ||
                            value.mfSectionLevel1?.label ||
                            value.mfSection.label,
                    });
                }
                return agg;
            }, Array<Product>());
*/
            const currentProducts = internalProducts.reduce((agg, value) => {
                const availableProduct = availableProducts.find(
                    (product) => product.id === value.productId,
                );
                if (availableProduct) {
                    agg.push({
                        ...availableProduct,
                        ...value,
                        dateAdded: value.dateAdded,
                        lastUpdated: value.lastUpdated,
                        product: value,
                        projectProductId: value.id,
                        lowestLevelNumber:
                            availableProduct?.mfSectionLevel3?.number ||
                            availableProduct?.mfSectionLevel2?.number ||
                            availableProduct?.mfSectionLevel1?.number ||
                            availableProduct.mfSection.number,
                        lowestLevelLabel:
                            availableProduct.mfSectionLevel3?.label ||
                            availableProduct.mfSectionLevel2?.label ||
                            availableProduct.mfSectionLevel1?.label ||
                            availableProduct.mfSection.label,
                    });
                }
                return agg;
            }, Array<Product>());

            await getBrandsInformation(currentProducts);
            const divisions = new Map();
            currentProducts.forEach((product) => {
                const key = parseInt(product.mfDivision.number[0] + product.mfDivision.number[1]);
                const highestSection = product.mfSectionLevel1 || product.mfSection;
                if (divisions.has(key)) {
                    if (divisions.get(key).sections.has(highestSection.number.slice(0, 5))) {
                        divisions
                            .get(key)
                            .sections.get(highestSection.number.slice(0, 5))
                            .products.push(product);
                    } else {
                        divisions.get(key).sections.set(highestSection.number.slice(0, 5), {
                            id: highestSection.number.slice(0, 5),
                            key: parseInt(highestSection.number[3] + highestSection.number[4]),
                            name: highestSection.label,
                            clicked: false,
                            products: new Array(product),
                        });
                    }
                } else {
                    divisions.set(key, {
                        id: product.mfDivision.number,
                        name: product.mfDivision.label,
                        key: key,
                        sections: new Map([
                            [
                                highestSection.number.slice(0, 5),
                                {
                                    id: highestSection.number.slice(0, 5),
                                    key: parseInt(
                                        highestSection.number[3] + highestSection.number[4],
                                    ),
                                    name: highestSection.label,
                                    clicked: false,
                                    products: new Array(product),
                                },
                            ],
                        ]),
                        clicked: false,
                    });
                }
            });
            for (const division of divisions.values()) {
                division.sections = [...division.sections.values()].sort(
                    (a: Section<Product>, b: Section<Product>) => a.key - b.key,
                );
            }
            const uniqueDivisions = [...divisions.values()];
            let uniqueInstalledDivisions = Array<Division<Product>>();
            if (!allDivisions.length) {
                uniqueInstalledDivisions = setAllDivisions([
                    ...uniqueDivisions.sort((a, b) => a.key - b.key),
                ]);
            } else {
                setInstalledDivisions([...uniqueDivisions.sort((a, b) => a.key - b.key)]);
            }
            if (existingProductIds.current && existingProductIds.current.length) {
                setExistingProducts({
                    ...existingProducts,
                    products: currentProducts
                        .filter((cp) =>
                            existingProductIds.current.some((id) => id === cp.product.productId),
                        )
                        .map((product) => ({ name: product.productName, id: product.id })),
                    isModalOpen: true,
                });
            }
            const productId = queryParams.get('productId');
            if (productId) {
                const selectedProduct = availableProducts.find(
                    (product) => product.id.toString() === productId.toString(),
                );
                if (selectedProduct) {
                    showSelectedProduct(selectedProduct, uniqueDivisions, uniqueInstalledDivisions);
                }
            }
            setState({
                ...state,
                products: currentProducts,
                pending: false,
            });
            if (state.searchString) {
                const uniqueFilteredDivisions = !installedTab ? filterProducts(allDivisions) : filterProducts(installedDivisions);
                setFilteredDivisions([...uniqueFilteredDivisions.sort((a, b) => a.key - b.key)]);
                setFilteredMode(true);
            }
            setFilteringInProgress(false);
        }
    };

    const filterProducts = (
        divs: Array<Division<Product>>
    ) => {
        const filteredDs = new Array<Division<Product>>();
        const strSearch = state.searchString ? state.searchString.toLowerCase() : state.searchString;
        for (const div of divs.values()) {
            const filteredSections = [];
            for (const sect of div.sections.values()) {
               const filteredProducts = sect.products.filter(
                   (product) => (product.productName && product.productName.toLowerCase().indexOf(strSearch) > -1) || 
                                (product.company && product.company.toLowerCase().indexOf(strSearch) > -1) );
               if (filteredProducts && filteredProducts.length > 0) {
                   const filteredSection =  { ...sect };
                   filteredSection.clicked = true;
                   filteredSection.products = filteredProducts;
                   filteredSections.push(filteredSection);
               }
            }
               if (filteredSections && filteredSections.length > 0) {
                   const filteredD = { ...div };
                   filteredD.clicked = true;
                   filteredD.sections = filteredSections;
                   filteredDs.push(filteredD);
               }
        }
        return filteredDs;
    };

    const showSelectedProduct = (
        product: ExternalProduct,
        divisions: Array<Division<Product>>,
        installedDivisions: Array<Division<Product>>,
    ) => {
        const currentDivisions = installedTab ? installedDivisions : divisions;
        const divisionIndex = currentDivisions.findIndex((d) => d.id === product.mfDivision.number);
        const highestSection = product.mfSectionLevel1 || product.mfSection;
        if (divisionIndex !== -1) {
            currentDivisions[divisionIndex].clicked = true;
            const sectionIndex = currentDivisions[divisionIndex].sections.findIndex(
                (s) => s.id === highestSection.number.slice(0, 5),
            );
            if (sectionIndex !== -1) {
                currentDivisions[divisionIndex].sections[sectionIndex].clicked = true;
                setTimeout(() => {
                    document.getElementById(`${product.id}`)?.scrollIntoView({
                        behavior: 'smooth',
                    });
                }, 500);
            }
        }
    };

    const handleDivisionOpen = (id: string) => {
        const index = allDivisions.findIndex((division) => division.id === id);
        if (index !== -1) {
            const currentDivisions = allDivisions;
            currentDivisions[index].clicked = !currentDivisions[index].clicked;
            setDivisions(currentDivisions);
        }
    };

    const handleSectionOpen = (divisionId: string, sectionId: string) => {
        const divisionIndex = allDivisions.findIndex((division) => division.id === divisionId);
        const sectionIndex = allDivisions[divisionIndex].sections.findIndex(
            (section) => section.id === sectionId,
        );
        if (divisionIndex !== -1 && sectionIndex !== -1) {
            const currentDivisions = allDivisions;
            currentDivisions[divisionIndex].sections[sectionIndex].clicked =
                !currentDivisions[divisionIndex].sections[sectionIndex].clicked;
            setDivisions(currentDivisions);
        }
    };

    const handleInstalledDivisionOpen = (id: string) => {
        const index = installedDivisions.findIndex((division) => division.id === id);
        if (index !== -1) {
            const currentDivisions = installedDivisions;
            currentDivisions[index].clicked = !currentDivisions[index].clicked;
            setInstalledDivisions(currentDivisions);
        }
    };

    const handleFilteredDivisionOpen = (id: string) => {
        const index = filteredDivisions.findIndex((division) => division.id === id);
        if (index !== -1) {
            const currentDivisions = filteredDivisions;
            currentDivisions[index].clicked = !currentDivisions[index].clicked;
            setFilteredDivisions(currentDivisions);
        }
    };

    const handleInstalledSectionOpen = (divisionId: string, sectionId: string) => {
        const divisionIndex = installedDivisions.findIndex(
            (division) => division.id === divisionId,
        );
        const sectionIndex = installedDivisions[divisionIndex].sections.findIndex(
            (section) => section.id === sectionId,
        );
        if (divisionIndex !== -1 && sectionIndex !== -1) {
            const currentDivisions = installedDivisions;
            currentDivisions[divisionIndex].sections[sectionIndex].clicked =
                !currentDivisions[divisionIndex].sections[sectionIndex].clicked;
            setInstalledDivisions(currentDivisions);
        }
    };

    const handleFilteredSectionOpen = (divisionId: string, sectionId: string) => {
        const divisionIndex = filteredDivisions.findIndex(
            (division) => division.id === divisionId,
        );
        const sectionIndex = filteredDivisions[divisionIndex].sections.findIndex(
            (section) => section.id === sectionId,
        );
        if (divisionIndex !== -1 && sectionIndex !== -1) {
            const currentDivisions = filteredDivisions;
            currentDivisions[divisionIndex].sections[sectionIndex].clicked =
                !currentDivisions[divisionIndex].sections[sectionIndex].clicked;
            setFilteredDivisions(currentDivisions);
        }
    };


    const updateDivisions = (product: Product, isInstalled?: boolean) => {
        const highestSection = product.mfSectionLevel1 || product.mfSection;
        const currentDivisions = allDivisions;
        const currentInstalledDivisions = installedDivisions;
        const divisionIndex = allDivisions.findIndex(
            (division) => division.id === product.mfDivision.number,
        );
        const sectionIndex = allDivisions[divisionIndex].sections.findIndex(
            (section) => section.id === highestSection.number.slice(0, 5),
        );

        const productIndex = allDivisions[divisionIndex].sections[sectionIndex].products.findIndex(
            (item) => item.id === product.id,
        );
        if (divisionIndex !== -1 && sectionIndex !== -1 && productIndex !== -1) {
            currentDivisions[divisionIndex].sections[sectionIndex].products[productIndex] = product;
        }
        setDivisions(currentDivisions);

        if (isInstalled) {
            handleInstalledProduct(currentInstalledDivisions, product);
            setInstalledDivisions(currentInstalledDivisions);
            return;
        }
        const installedDivisionIndex = installedDivisions.findIndex(
            (division) => division.id === product.mfDivision.number,
        );
        if (installedDivisionIndex !== -1) {
            const installedSectionIndex = installedDivisions[installedDivisionIndex].sections.findIndex(
                (section) => section.id === highestSection.number.slice(0, 5),
            );
            if (installedSectionIndex !== -1) {
                const installedProductIndex = installedDivisions[installedDivisionIndex].sections[
                    installedSectionIndex
                ].products.findIndex((item) => item.id === product.id);
                if (installedProductIndex !== -1) {
                    currentInstalledDivisions[installedDivisionIndex].sections[
                        installedSectionIndex
                    ].products[installedProductIndex] = product;
                }
                setInstalledDivisions(currentInstalledDivisions);
            }
        }

        const currentFilteredDivisions = filteredDivisions;
        if (isFilterMode) {
            const filteredDivisionIndex = filteredDivisions.findIndex(
                (division) => division.id === product.mfDivision.number,
            );
            if (filteredDivisionIndex !== -1) {
                const filteredSectionIndex = filteredDivisions[filteredDivisionIndex].sections.findIndex(
                    (section) => section.id === highestSection.number.slice(0, 5),
                );
                if (filteredSectionIndex !== -1) {
                    const filteredProductIndex = filteredDivisions[filteredDivisionIndex].sections[
                        filteredSectionIndex
                    ].products.findIndex((item) => item.id === product.id);
                    if (filteredProductIndex !== -1) {
                        currentFilteredDivisions[filteredDivisionIndex].sections[
                            filteredSectionIndex
                        ].products[filteredProductIndex] = product;
                    }
                    setFilteredDivisions(currentFilteredDivisions);
                }
            }
        }
    };

    const handleDeleteProduct = (product: Product) => {
        setAllDivisions(handleDeleteProductInSpecificDivisionList(allDivisions, product));
        if (isFilterMode) {
            setFilteredDivisions(handleDeleteProductInSpecificDivisionList(filteredDivisions, product));
            if (installedTab) {
                setInstalledDivisions(handleDeleteProductInSpecificDivisionList(installedDivisions, product));
            }
        }
    };

    const handleDeleteProductInSpecificDivisionList = (
        divisions: Array<Division<Product>>,
        product: Product,
    ) => {
        const highestSection = product.mfSectionLevel1 || product.mfSection;
        const divisionIndex = divisions.findIndex(
            (division) => division.id === product.mfDivision.number,
        );
        const sectionIndex = divisions[divisionIndex].sections.findIndex(
            (section) => section.id === highestSection.number.slice(0, 5),
        );

        const productIndex = divisions[divisionIndex].sections[sectionIndex].products.findIndex(
            (item) => item.id === product.id,
        );
        const currentDivisions = divisions;
        currentDivisions[divisionIndex].sections[sectionIndex].products.splice(productIndex, 1);
        if (!currentDivisions[divisionIndex].sections[sectionIndex].products.length) {
            currentDivisions[divisionIndex].sections.splice(sectionIndex, 1);
            if (!currentDivisions[divisionIndex].sections.length) {
                currentDivisions.splice(divisionIndex, 1);
            }
        }
        return currentDivisions;
    };

    const handleInstalledProduct = (
        installedDivisions: Array<Division<Product>>,
        product: Product,
    ) => {
        const highestSection = product.mfSectionLevel1 || product.mfSection;
        if (product.isInstalled) {
            const currentDivisionIndex = installedDivisions.findIndex(
                (division) => division.id === product.mfDivision.number,
            );
            if (currentDivisionIndex !== -1) {
                const currentSectionIndex = installedDivisions[
                    currentDivisionIndex
                ].sections.findIndex((section) => section.id === highestSection.number.slice(0, 5));
                if (currentSectionIndex !== -1) {
                    installedDivisions[currentDivisionIndex].sections[
                        currentSectionIndex
                    ].products.push(product);
                } else {
                    installedDivisions[currentDivisionIndex].sections.push({
                        id: highestSection.number.slice(0, 5),
                        key: parseInt(highestSection.number[3] + highestSection.number[4]),
                        name: highestSection.label,
                        clicked: false,
                        products: [product],
                    });
                }
            } else {
                installedDivisions.push({
                    id: product.mfDivision.number,
                    name: product.mfDivision.label,
                    key: parseInt(product.mfDivision.number[0] + product.mfDivision.number[1]),
                    clicked: false,
                    sections: [
                        {
                            id: highestSection.number.slice(0, 5),
                            key: parseInt(highestSection.number[3] + highestSection.number[4]),
                            name: highestSection.label,
                            clicked: false,
                            products: [product],
                        },
                    ],
                });
                installedDivisions = installedDivisions.sort((a, b) => a.key - b.key);
            }
        } else {
            const installedDivisionIndex = installedDivisions.findIndex(
                (division) => division.id === product.mfDivision.number,
            );
            const installedSectionIndex = installedDivisions[
                installedDivisionIndex
            ].sections.findIndex((section) => section.id === highestSection.number.slice(0, 5));

            const installedProductIndex = installedDivisions[installedDivisionIndex].sections[
                installedSectionIndex
            ].products.findIndex((item) => item.id === product.id);
            installedDivisions[installedDivisionIndex].sections[
                installedSectionIndex
            ].products.splice(installedProductIndex, 1);
            if (
                !installedDivisions[installedDivisionIndex].sections[installedSectionIndex].products
                    .length
            ) {
                installedDivisions[installedDivisionIndex].sections.splice(
                    installedSectionIndex,
                    1,
                );
                if (!installedDivisions[installedDivisionIndex].sections.length) {
                    installedDivisions.splice(installedDivisionIndex, 1);
                }
            }
        }
    };

    const closeWarnModal = () => {
        setExistingProducts({
            productIds: Array<number>(),
            products: Array<{ id: number; name: string }>(),
            isModalOpen: false,
        });
        existingProductIds.current = Array<number>();
        history.push(`/project-info/${projectInfo?.id}/0/2`);
    };

    const openCloseAll = async () => {
        let action;

        if (installedTab && !isFilterMode) {
            action = checkWhatActionNeeded(installedDivisions);
            openCloseAllProcess(installedDivisions, action);
        } else if (!installedTab && !isFilterMode) {
            action = checkWhatActionNeeded(allDivisions);
            openCloseAllProcess(allDivisions, action);
        } else if (isFilterMode) {
            action = checkWhatActionNeeded(filteredDivisions);
            openCloseAllProcess(filteredDivisions, action);
        }
        history.push(`/project-info/${projectInfo?.id}/0/2`);
    };

    const openCloseAllProcess = async (divisions: Array<Division<Product>>, action: boolean) => {
        if (!divisions || !divisions.length) {
            return;
        }
        for (let i = 0; i < divisions.length; i++) {
            const division = divisions[i];
            division.clicked = action;
                if (!division.sections || !division.sections.length) {
                    return;
                }
            for (let j = 0; j < division.sections.length; j++) {
                const section = division.sections[j];
                section.clicked = action;
            }
        }
    }

    const checkWhatActionNeeded = (divisions: Array<Division<Product>>) => {
        for (let i = 0; i < divisions.length; i++) {
            const division = divisions[i];
            if (!division.clicked) {
                return true;
            }
            for (let j = 0; j < division.sections.length; j++) {
                const section = division.sections[j];
                if (!section.clicked) {
                    return true;
                }
            }
        }
        return false;
    }

    const getProductCount = (divisions: Array<Division<Product>>) => {
        let coun = 0;
        for (let i = 0; i < divisions.length; i++) {
            const division = divisions[i];
            for (let j = 0; j < division.sections.length; j++) {
                coun += division.sections[j].products.length;
            }
        }
        return coun;       
    }

    return (
        <ProductsTabPageContainer>
            <ProductsTabHeader>
                    <ProductsSearch
                         searchString={searchText}
                         changeSearch={changeSearch}
                         cleanFilter={cleanFilter}
                         handleSearchSubmit={submitSearch}
                    />

                    <SpaceEnd>
                        <ViewProjectInEC3Button>
                            {(projectInfo?.ec3ProjectStatus?.export?.status === EC3ExportStatus.Done ||
                                projectInfo?.id === ec3Reducer.ec3Project?.projectId) && (
                                <EC3Link>
                                    <Image
                                        src="src/assets/images/arrow-up-right-from-square.svg"
                                        preview={false}
                                    />
                                    <a
                                        href={
                                            projectInfo?.ec3ProjectStatus?.eC3Project?.link ||
                                            ec3Reducer.ec3Project?.link
                                        }
                                        target="_blank"
                                    >
                                        <Text weight={400} size={14} height={18} color={blueLink}>
                                            View project in EC3 tool
                                        </Text>
                                    </a>
                                </EC3Link>
                            )}
                        </ViewProjectInEC3Button>
                        <ProductsAction projectId={projectInfo?.id} projectRole={projectInfo?.userRole} />
                    </SpaceEnd>
            </ProductsTabHeader>

            {isFilterMode && !isFilteringInProgress && (
                <FoundResultsCount weight={400} size={14} height={18} color={blueLink}>
                    {!filteredDivisions.length || !getProductCount(filteredDivisions) === 0 ? 'No results found' : getProductCount(filteredDivisions) + ' result' + (getProductCount(filteredDivisions) > 1 ? 's' : '') + ' found' } 
                </FoundResultsCount>
            )}

            {(!isFilterMode && allDivisions.length) || (isFilterMode && filteredDivisions.length) ? (
                <InvisibleButton>
                        <OpenCloseAllButton onClick={openCloseAll} className="closeOpenAll">
                            <Text weight={400} size={12} height={14} color={blueLink}>
                                Open / close all
                            </Text>
                        </OpenCloseAllButton>
                </InvisibleButton>
                   ) : (''
            )}

            {(!state.pending || ec3Reducer.pending) && !isFilteringInProgress ? (
                <>
                    {installedTab && !isFilterMode && (
                        <ProductsTabContent>
                            {installedDivisions.length ? (
                                <DivisionsContainer
                                    className={installedDivisions[0].clicked ? 'clicked' : ''}
                                >
                                    {installedDivisions.map((division) => (
                                        <DivisionItem
                                            division={division}
                                            key={division.key}
                                            references={references}
                                            projectInfo={projectInfo}
                                            projectReferences={projectReferences}
                                            installedTab
                                            handleClicked={handleInstalledDivisionOpen}
                                            handleSectionOpen={handleInstalledSectionOpen}
                                            handleDeleteProduct={handleDeleteProduct}
                                            updateDivisions={updateDivisions}
                                            editable={getProjectPermission(
                                                [
                                                    ProjectPermissionTypes.EDITOR,
                                                    ProjectPermissionTypes.OWNER,
                                                    ProjectPermissionTypes.PROJECT_ADMIN,
                                                ],
                                                projectInfo?.userRole,
                                            )}
                                            brandsInfo={brandsInformation}
                                        />
                                    ))}
                                </DivisionsContainer>
                            ) : (
                                <NoProductsTitleContainer>
                                    <h1>
                                        In the Products tab, click the "Installed" icon next to
                                        products that were installed to create the list here.
                                    </h1>
                                </NoProductsTitleContainer>
                            )}
                        </ProductsTabContent>
                    )}

                    {installedTab && isFilterMode && (
                        <ProductSpin size="large" spinning={ec3Reducer.pending}>
                            <ProductsTabContent>
                                {filteredDivisions.length  ? (
                                    <DivisionsContainer
                                        className={filteredDivisions[0].clicked ? 'clicked' : ''}
                                    >
                                        {filteredDivisions.map((division) => (
                                            <DivisionItem
                                                division={division}
                                                key={division.key}
                                                references={references}
                                                projectReferences={projectReferences}
                                                projectInfo={projectInfo}
                                                handleClicked={handleFilteredDivisionOpen}
                                                handleSectionOpen={handleFilteredSectionOpen}
                                                handleDeleteProduct={handleDeleteProduct}
                                                updateDivisions={updateDivisions}
                                                editable={getProjectPermission(
                                                    [
                                                        ProjectPermissionTypes.EDITOR,
                                                        ProjectPermissionTypes.OWNER,
                                                        ProjectPermissionTypes.PROJECT_ADMIN,
                                                    ],
                                                    projectInfo?.userRole,
                                                )}
                                                brandsInfo={brandsInformation}
                                            />
                                        ))}
                                    </DivisionsContainer>
                                ) : (
                                    <NoProductsTitleContainerFiltered>
                                        <center>
                                            <Image
                                                src="src/assets/images/empty-graphic 1.svg"
                                                preview={false}
                                                alt="no results found"
                                            />
                                            <h1><center>Clear search field to<br/>display products and try<br/>your search again.</center></h1>
                                        </center>
                                    </NoProductsTitleContainerFiltered>
                                )}
                            </ProductsTabContent>
                        </ProductSpin>
                    )}

                    {!installedTab && !isFilterMode && (
                        <ProductSpin size="large" spinning={ec3Reducer.pending}>
                            <ProductsTabContent>
                                {allDivisions.length ? (
                                    <DivisionsContainer
                                        className={allDivisions[0].clicked ? 'clicked' : ''}
                                    >
                                        {allDivisions.map((division) => (
                                            <DivisionItem
                                                division={division}
                                                key={division.key}
                                                references={references}
                                                projectReferences={projectReferences}
                                                projectInfo={projectInfo}
                                                handleClicked={handleDivisionOpen}
                                                handleSectionOpen={handleSectionOpen}
                                                handleDeleteProduct={handleDeleteProduct}
                                                updateDivisions={updateDivisions}
                                                editable={getProjectPermission(
                                                    [
                                                        ProjectPermissionTypes.EDITOR,
                                                        ProjectPermissionTypes.OWNER,
                                                        ProjectPermissionTypes.PROJECT_ADMIN,
                                                    ],
                                                    projectInfo?.userRole,
                                                )}
                                                brandsInfo={brandsInformation}
                                            />
                                        ))}
                                    </DivisionsContainer>
                                ) : (
                                    <NoProductsTitleContainer>
                                        <h1>Add products using the "Add products" button above.</h1>
                                    </NoProductsTitleContainer>
                                )}
                            </ProductsTabContent>
                        </ProductSpin>
                    )}

                    {!installedTab && isFilterMode && (
                        <ProductSpin size="large" spinning={ec3Reducer.pending}>
                            <ProductsTabContent>
                                {filteredDivisions.length  ? (
                                    <DivisionsContainer
                                        className={filteredDivisions[0].clicked ? 'clicked' : ''}
                                    >
                                        {filteredDivisions.map((division) => (
                                            <DivisionItem
                                                division={division}
                                                key={division.key}
                                                references={references}
                                                projectReferences={projectReferences}
                                                projectInfo={projectInfo}
                                                handleClicked={handleFilteredDivisionOpen}
                                                handleSectionOpen={handleFilteredSectionOpen}
                                                handleDeleteProduct={handleDeleteProduct}
                                                updateDivisions={updateDivisions}
                                                editable={getProjectPermission(
                                                    [
                                                        ProjectPermissionTypes.EDITOR,
                                                        ProjectPermissionTypes.OWNER,
                                                        ProjectPermissionTypes.PROJECT_ADMIN,
                                                    ],
                                                    projectInfo?.userRole,
                                                )}
                                                brandsInfo={brandsInformation}
                                            />
                                        ))}
                                    </DivisionsContainer>
                                ) : (
                                    <NoProductsTitleContainerFiltered>
                                        <center>
                                            <Image
                                                src="src/assets/images/empty-graphic 1.svg"
                                                preview={false}
                                                alt="no results found"
                                            />
                                            <h1><center>Clear search field to<br/>display products and try<br/>your search again.</center></h1>
                                        </center>
                                    </NoProductsTitleContainerFiltered>
                                )}
                            </ProductsTabContent>
                        </ProductSpin>
                    )}

                </>
            ) : (
                <ProductSpin size="large" spinning={state.pending || isFilteringInProgress}>
                    <ProductsTabContent></ProductsTabContent>
                </ProductSpin>
            )}
            {existingProducts.isModalOpen && (
                <ExistingProductsModal
                    isOpen={existingProducts.isModalOpen}
                    closeModal={closeWarnModal}
                    existingProducts={existingProducts.products}
                    addedFrom={'Transparency Catalog'}
                    addedTo={'project'}
                    currentPage={`/project-info/${projectInfo?.id}/0/2`}
                />
            )}
        </ProductsTabPageContainer>
    );
};

export default ProductsTab;
