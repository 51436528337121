import styled from 'styled-components';
import Dragger from 'antd/lib/upload/Dragger';
import {
    teal05,
    blue,
    purpleLight,
    blueDark02,
    blueLink,
    blueDark01,
    grayDark06,
    grayDark05,
    blue01,
    white,
} from '../../../styles/colors';
import { InvisibleButton, PrimaryButton, SpaceBetween } from '../../../styles/common.styles';

export const CreateAccountDragger = styled(Dragger)`
    height: 112px !important;
`;

export const ReDragger = styled(Dragger)`
    background-color: ${white} !important;
    border: none !important;
    margin-bottom: 0px !important;
    width: 90% !important;

    &.ant-upload.ant-upload-drag .ant-upload-btn {
        padding: 0px;
    }
`;

export const FileInputContainer = styled('div')`
    .ant-upload.ant-upload-drag {
        border-radius: 3px;
        margin-top: 4px;
        margin-bottom: 8px;
        background-color: ${teal05};

        &:hover {
            border-color: ${blue};
            background-color: ${purpleLight};
        }
    }

    .ant-upload-drag-container > .ant-image {
        margin-bottom: 4px;
    }

    .ant-btn {
        span {
            font-weight: 500;
            line-height: 18px;
        }

        width: 100px;
        background-color: white;
        border-color: ${blueDark02};
        border-radius: 3px;

        &:disabled {
            pointer-events: none;
        }

        &:hover:not(:disabled) {
            border-color: ${blueLink};
            color: ${blueLink};
        }

        &:focus:not(:disabled) {
            border-color: ${blueDark01};
            color: ${blueDark01};
        }
    }

    .ant-space.ant-space-horizontal.ant-space-align-center {
        display: flex;
    }
`;

export const FileInputSelected = styled('input')`
    margin-top: 8px;
    border: 1px solid ${grayDark06};
    border-radius: 3px;
    width: 100%;
    height: 36px;
    padding: 9px 10px;
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark01};

    &:hover {
        border-color: ${grayDark05};
    }

    &:focus {
        border-color: ${blue01};
        box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
        outline: none;
    }
`;

export const FileInputFlexRow = styled('div')`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: -6px;

    div:after {
        content: ' ';
        white-space: pre;
    }

    div:last-child:after {
        content: '';
    }
`;

export const CustomButton = styled(InvisibleButton)`
    float: right;
    display: block;
    margin-right: -36px;
`;

export const FileInputAction = styled(SpaceBetween)`
    margin-bottom: 20px;
`;

export const SaveFileButton = styled(PrimaryButton)`
    color: ${blueDark02};
    background-color: ${blueDark02};
    font-weight: 500;
`;
