import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertMessages } from '../../../../components/alert/AlertMessages';
import CustomInput from '../../../../components/common/inputs/CustomInput';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useModalState } from '../../../../hooks/modalState';
import { changePasswordAction, updateUserAction } from '../../../../redux/actions/userActions';
import {
    ChangePasswordRequest,
    CreateUserRequest,
    UserByIdResponse,
} from '../../../../redux/actions/userActions/types';
import { clearState } from '../../../../redux/persistedState';
import { riseStoredUser, setStoredUser } from '../../../../redux/reducers/authReducer/AuthSlice';
import { alertService } from '../../../../services';
import {
    InvisibleButton,
    Margin,
    SecondaryButton,
    SpaceBetween,
    Text,
} from '../../../../styles/common.styles';
import {
    BasicInfoColumn,
    BasicInfoContainer,
    BasicInfoDivider,
    BasicInfoForm,
    BasicInfoPasswordContainer,
    BasicInfoPhoneInputContainer,
    BasicInfoResetPassword,
    BasicInfoSaveButton,
    BasicInfoSection,
} from './BasicInformation.styles';
import ErrorModal from '../../../../components/common/errorModal/ErrorModal';
import { blue01, blueLink } from '../../../../styles/colors';
import { resetSMAdminUserPasswordAction } from '../../../../redux/actions/smAdminActions';

interface BasicInfoParams {
    user: UserByIdResponse | undefined;
}

export const BasicInformation: React.FC<BasicInfoParams> = ({ user }) => {
    const selector = useAppSelector((state) => state);
    const { authReducer } = selector;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const errorModal = useModalState();

    const isOwner = user?.id === authReducer.user?.id;

    useEffect(() => {
        form.setFieldsValue(user);
    }, [user]);

    const onSubmit = async (data: CreateUserRequest) => {
        Object.keys(data).forEach((key) => {
            if (!data[key]) {
                data[key] = null;
            }
        }, data);
        if (user?.id) {
            const { payload } = (await dispatch(
                updateUserAction({
                    user: {
                        ...data,
                        userName: data.email,
                        accountId: user?.parentAccountId || user?.accountId,
                    },
                    id: user.id.toString(),
                }),
            )) as any;
            if (payload?.data) {
                if (payload?.data[0].userName !== user?.userName) {
                    clearState();
                    history.push('/login');
                    dispatch(riseStoredUser());
                    alertService.info('Please login with a new email', {
                        autoClose: false,
                    });
                    return;
                }
                if (payload.data[0].id === authReducer.user?.id) {
                    dispatch(setStoredUser(payload?.data[0]));
                }
                window.scroll(0, 0);
                alertService.success(AlertMessages.UPDATE_USER);
            } else {
                errorModal.onOpen();
            }
        }
    };

    const submitChangePasswordButton = () => {
        return (
            <SpaceBetween>
                <SecondaryButton htmlType="submit">Change password</SecondaryButton>
            </SpaceBetween>
        );
    };

    const submitPassword = async (data: ChangePasswordRequest) => {
        const { payload } = await dispatch(changePasswordAction(data));
        if (payload.message) {
            window.scroll(0, 0);
            alertService.success(AlertMessages.CHANGE_PASSWORD);
        }
    };

    const resetPassword = async () => {
        const { payload } = (await dispatch(
            resetSMAdminUserPasswordAction(user!.id.toString()),
        )) as any;
        if (payload.message) {
            window.scroll(0, 0);
            alertService.success(AlertMessages.RESET_PASSWORD);
        }
    };

    return (
        <BasicInfoContainer>
            <BasicInfoForm onFinish={onSubmit} form={form}>
                <BasicInfoColumn>
                    <BasicInfoSection>
                        {isOwner ? (
                            <Text
                                weight={500}
                                size={20}
                                height={24}
                                width={154}
                                color={blue01}
                                font={'DINNextLTPro'}
                            >
                                About you
                            </Text>
                        ) : (
                            <Text
                                weight={500}
                                size={20}
                                height={24}
                                width={154}
                                color={blue01}
                                font={'DINNextLTPro'}
                            >
                                Basic information
                            </Text>
                        )}
                        <Margin value="0 0 28px 0" />
                        <CustomInput
                            Component={Input}
                            label={'First name'}
                            name={'firstName'}
                            required
                            block
                        />
                        <CustomInput
                            Component={Input}
                            label={'Last name'}
                            name={'lastName'}
                            required
                            block
                        />
                        <CustomInput
                            Component={Input}
                            label={'Email'}
                            name={'email'}
                            required
                            block
                            rules={[
                                {
                                    required: true,
                                    message: 'Please add your email',
                                },
                                {
                                    pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                                    message: 'Invalid email',
                                },
                            ]}
                        />
                        <BasicInfoPhoneInputContainer>
                            <CustomInput
                                Component={Input}
                                label={'Phone'}
                                name={'phoneNumber'}
                                required
                            />
                            <CustomInput Component={Input} label={'Ext.'} name={'phoneNumberExt'} />
                        </BasicInfoPhoneInputContainer>
                    </BasicInfoSection>
                </BasicInfoColumn>
            </BasicInfoForm>
            <BasicInfoForm onFinish={submitPassword}>
                <BasicInfoColumn>
                    <BasicInfoSection>
                        <Text
                            weight={500}
                            size={20}
                            height={24}
                            width={87}
                            color={blue01}
                            font={'DINNextLTPro'}
                        >
                            Password
                        </Text>
                        {isOwner ? (
                            <BasicInfoPasswordContainer>
                                <Margin value="0 0 28px 0" />
                                <CustomInput
                                    Component={Input.Password}
                                    label={'Old password'}
                                    name={'oldPassword'}
                                    required
                                    block
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                />
                                <CustomInput
                                    Component={Input.Password}
                                    label={'New password'}
                                    name={'password'}
                                    required
                                    block
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                        {
                                            pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$/,
                                            message:
                                                'Should be at least 6 characters, numbers and letters only',
                                        },
                                    ]}
                                />
                                <CustomInput
                                    Component={Input.Password}
                                    label={'Confirm password'}
                                    name={'confirmPassword'}
                                    required
                                    block
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }: any) => ({
                                            validator(_: any, value: any) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(`Passwords don't match`),
                                                );
                                            },
                                        }),
                                    ]}
                                />
                                <CustomInput
                                    Component={submitChangePasswordButton}
                                    label=" "
                                    name={''}
                                />
                            </BasicInfoPasswordContainer>
                        ) : (
                            <BasicInfoResetPassword>
                                <Margin value="0 0 14px 0" />
                                <InvisibleButton onClick={resetPassword}>
                                    <Text
                                        weight={400}
                                        size={13}
                                        height={16}
                                        color={blueLink}
                                        font={'Proxima Nova'}
                                    >
                                        Reset password
                                    </Text>
                                </InvisibleButton>
                            </BasicInfoResetPassword>
                        )}
                    </BasicInfoSection>
                </BasicInfoColumn>
            </BasicInfoForm>
            <BasicInfoDivider />
            <BasicInfoSaveButton htmlType="submit" width={58} onClick={() => form.submit()}>
                Save
            </BasicInfoSaveButton>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </BasicInfoContainer>
    );
};
