import styled from 'styled-components';
import { Divider, Form, Spin } from 'antd';
import { blueDark01 } from '../../../styles/colors';
import { PrimaryButton } from '../../../styles/common.styles';

export const SMAdminDetailsPageSpin = styled(Spin)`
    top: 0 !important;
`;

export const DetailsPageSpin = styled(Spin)`
    top: 0 !important;
`;

export const DetailsForm = styled(Form)`
    margin: 37px 100px 46px 100px;
    display: flex;
    flex-direction: column;
`;

export const DetailsContainer = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 59px;
`;

export const DetailsColumn = styled('div')`
    display: flex;
    flex-direction: column;
    button {
        height: 16px;
    }
`;

export const DetailsSection = styled('div')`
    display: flex;
    flex-direction: column;

    .input-block,
    & > div > div {
        & > div:first-child {
            margin-bottom: 16px;
        }
        .ant-form-item-row {
            display: flex !important;
            gap: 16px;

            .ant-form-item-label {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                label {
                    font-weight: normal;
                    font-family: 'Proxima N W15 Bold';
                    font-size: 13px;
                    line-height: 16px !important;
                    height: auto;
                    color: ${blueDark01} !important;
                    &::after {
                        margin-right: 0;
                    }
                }
            }
        }
    }
`;

export const DetailsPhoneInputContainer = styled('div')`
    display: flex;
    flex-direction: row;

    & > div {
        :nth-child(1) {
            .ant-form-item-row {
                .ant-form-item-control {
                    input {
                        width: 203px;
                    }
                }
            }
        }
        :nth-child(2) {
            .ant-form-item-row {
                .ant-form-item-label {
                    width: 48px;
                }
                .ant-form-item-control {
                    input {
                        width: 87px;
                    }
                }
            }
        }
    }
`;

export const DetailsDivider = styled(Divider)`
    margin: 40px 0px;
`;

export const DetailsPrimaryButton = styled(PrimaryButton)`
    align-self: end;
`;
