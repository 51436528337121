import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomTable from '../../common/table/CustomTable';
import { TableData } from '../../../models/tableData/types';
import { UserTableFields } from '../../../models/user/user';
import { columns } from './UsersTableColumns';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setSelectedRows } from '../../../redux/reducers/usersReducer/UserSlice';
import { UsersTableContainer, UsersTableSpinner } from './UsersTable.styles';
import { SortOrder } from 'antd/lib/table/interface';
import EmptyData from '../../common/emptyData/EmptyData';

interface Props {
    tableData: TableData;
    getUsers: (data: TableData) => void;
    users: Array<UserTableFields>;
    totalCount: number;
    isPending: boolean;
    setTableData: (data: any) => void;
    searchComponent: React.ReactNode;
}

const UsersTable: React.FC<Props> = ({
    tableData,
    getUsers,
    users,
    totalCount,
    isPending,
    setTableData,
    searchComponent,
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const history = useHistory();
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { userReducer, authReducer } = selector;

    useEffect(() => {
        setSelectedRowKeys(userReducer.selectedRows.map((user) => user.key));
    }, [userReducer.selectedRows]);

    const onSelectChange = (newSelectedUser: UserTableFields) => {
        const selectedUsers = userReducer.selectedRows.slice();
        const index = selectedUsers.findIndex(
            (selectedUser: UserTableFields) => selectedUser.key === newSelectedUser.key,
        );
        if (index === -1) {
            selectedUsers.push(newSelectedUser);
        } else {
            selectedUsers.splice(index, 1);
        }
        setSelectedRowKeys(selectedUsers.map((user) => user.key));
        dispatch(setSelectedRows(selectedUsers));
    };

    const onSelectAll = (isAll: boolean, data: any) => {
        let selectedUsers: Array<UserTableFields> = [];
        if (isAll) {
            selectedUsers = data;
        }
        setSelectedRowKeys(selectedUsers.map((user) => user.key));
        dispatch(setSelectedRows(selectedUsers));
    };

    const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectChange,
        onSelectAll: onSelectAll,
        getCheckboxProps: (record: UserTableFields) => {
            return {
                style:
                    !record.isMemberOfOtherAccount || authReducer.user?.id === record.id
                        ? { display: 'none' }
                        : {},
                disabled: !record.isMemberOfOtherAccount || authReducer.user?.id === record.id,
            };
        },
    };

    const changePageSize = (pageSize: number) => {
        let curPage = tableData.curPage;
        if (Math.ceil(totalCount / pageSize) < tableData.curPage) {
            curPage = Math.ceil(totalCount / pageSize);
        }
        getUsers({ ...tableData, pageSize: pageSize, curPage: curPage });
    };

    const handleCurrentPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentCount = event.currentTarget.value;
        if (/^-?\d*\.?\d*$/.test(currentCount) || currentCount === '') {
            setTableData({ ...tableData, curPage: +event.currentTarget.value });
            if (+currentCount > Math.ceil(totalCount / tableData.pageSize)) {
                setTimeout(() => {
                    setTableData({
                        ...tableData,
                        curPage: Math.ceil(totalCount / tableData.pageSize),
                    });
                    changePageNumber(Math.ceil(totalCount / tableData.pageSize));
                }, 1000);
            }
        }
        if (
            +event.currentTarget.value > 0 &&
            +event.currentTarget.value <= Math.ceil(totalCount / tableData.pageSize)
        ) {
            changePageNumber(+event.currentTarget.value);
        }
    };

    const changePageNumber = (pageNumber: number) => {
        getUsers({ ...tableData, curPage: pageNumber });
    };

    const changeSort = (field: string, direction?: SortOrder) => {
        getUsers({
            ...tableData,
            sortDirection: direction === 'descend' ? 'desc' : 'asc',
            sortExpression: direction ? field : '',
        });
    };

    const clickRow = (data: UserTableFields) => {
        history.push(`/users/edit/${data.key}`);
    };

    return (
        <UsersTableContainer>
            <UsersTableSpinner size="large" spinning={isPending}>
                <CustomTable
                    changeSort={changeSort}
                    tableData={{
                        ...tableData,
                        pageSize: tableData.pageSize,
                    }}
                    data={users}
                    rowSelection={rowSelection}
                    totalCount={totalCount}
                    showTotalCount
                    columns={columns}
                    tableName="Users"
                    changePageSize={changePageSize}
                    handleCurrentPage={handleCurrentPage}
                    changePageNumber={changePageNumber}
                    clickRow={clickRow}
                    searchComponent={searchComponent}
                    locale={{
                        emptyText: <EmptyData text={`No results found`} pending={isPending} />,
                    }}
                />
            </UsersTableSpinner>
        </UsersTableContainer>
    );
};

export default UsersTable;
