import React, { useEffect, useState } from 'react';
import { Image, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { addPages, removePages } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { EmptyPendingPage, Text, MiddleSpin } from '../../styles/common.styles';
import {
    MFRPageContainer,
    MFRPageContent,
    ProductsCount,
    ReturnToProductTabContainer,
    ReturnToProductTabLink,
} from './MFRPage.styles';
import MFRPageHeader from './mfrPageHeader/MFRPageHeader';
import { blue01, blueLink } from '../../styles/colors';
import {
    BrandCompany,
    ExternalProduct,
    InternalProduct,
    Product,
} from '../../models/product/types';
import { ActionResponse } from '../../models/actionResponse/types';
import { Division, Section } from '../../models/productDivision/types';
import MFRDivisionItem from './mfrDivision/MFRDivision';
import {
    getProductReferencesAction,
    getProjectProductsAction,
} from '../../redux/actions/productActions';
import { ProductReferences } from '../../redux/actions/productActions/types';
import { usePermissions } from '../../hooks/permissions';
import { ProjectPermissionTypes } from '../../models/permission/enums';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../redux/actions/transparencyCatalogActions/types';
import { MFRProject, ProjectReferences } from '../../models/project/types';
import {
    getProjectForMFRPageAction,
    getProjectReferencesAction,
} from '../../redux/actions/projectActions';
import {
    getBrandByIdAction,
    getCompaniesOfProducts,
    getExternalProductsAction,
} from '../../redux/actions/transparencyCatalogActions';
import { useModalState } from '../../hooks/modalState';
import HelpModal from '../../components/helpModal/HelpModal';
import ProjectInfoActions from '../projectInfo/projectInfoActions/ProjectInfoActions';
import MFRPageCompanyHeader from './MFRPageCompanyHeader';

interface Params {
    projectId: string;
    productId: string;
    projectProductId?: string;
    mode?: string;
    commentId?: string;
}

const MFRPage: React.FC = () => {
    const selector = useAppSelector((combinedState) => combinedState);
    const dispatch = useAppDispatch();
    const params = useParams<Params>();
    const history = useHistory();
    const { isOpen, onToggle } = useModalState();

    const { authReducer } = selector;

    const [brandInformation, setBrandInformation] = useState<BrandCompany | undefined>(undefined);
    const [projectData, setProjectData] = useState<MFRProject | null>(null);
    const [references, setReferences] = useState<ProductReferences | null>(null);
    const [projectReferences, setProjectReferences] = useState<ProjectReferences | null>(null);

    const { getProjectPermission } = usePermissions();

    const [state, setState] = useState({
        divisions: Array<Division<Product>>(),
        productsCount: 0,
        pending: true,
    });
    if (!params?.productId) {
        history.push(`/project-info/${params.projectId}}`);
    }

    const setNavigationBar = (projectDataForNavbar: MFRProject | undefined, companyName: string) => {
        dispatch(
            addPages([
                {
                    key: NavigationKey.DASHBOARD,
                    value: `${authReducer.user?.firstName}’s Dashboard`,
                },
                {
                    key: NavigationKey.PROJECT_LIBRARY,
                    value: `${authReducer.user?.organization.name} Projects`,
                },
                {
                    key: `/project-info/${params.projectId}`,
                    value: `${projectDataForNavbar?.name}`,
                },
                {
                    key: `/product-info/${params.projectId}/${companyName}`,
                    value: `${companyName} product list`,
                },
            ]),
        );
    };
    useEffect(() => {
        return () => {
            dispatch(removePages());
        };
    }, []);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await getProductReferences();
        await getProjectReferences();
        await getProductIds();
    };

    const getProjectReferences = async () => {
        const { payload } = (await dispatch(
            getProjectReferencesAction(),
        )) as ActionResponse<ProjectReferences>;
        setProjectReferences(payload.data);
    };

    const getCompanyData = async (product: ExternalProduct) => {
        const companies = await getCompaniesOfProducts([product], dispatch);
        if (companies.length && companies[0]) {
            setBrandInformation(companies[0]);
            console.log('BrandInformation:');
            console.log(companies[0]);
            return companies[0];
        }
        return undefined;
    };

    const getProductReferences = async () => {
        const { payload } = (await dispatch(
            getProductReferencesAction(),
        )) as ActionResponse<ProductReferences>;
        setReferences(payload.data);
    };

    const getProductIds = async () => {
        if (params.projectId) {
            const { payload } = (await dispatch(
                getProjectProductsAction(+params.projectId),
            )) as ActionResponse<Array<InternalProduct>>;
            if (payload && payload.data) {
                getProducts(payload.data);
            }
        }
    };

    const getProjectData = async () => {
        let project;
        const { payload } = (await dispatch(
            getProjectForMFRPageAction(+params.projectId),
        )) as ActionResponse<MFRProject>;
        if (payload.data) {
            project = payload.data;
            setProjectData(payload.data);
        }
        return project;
    };

    const getProducts = async (internalProducts: Array<InternalProduct>) => {
        const productIds = internalProducts.map((p) => p.productId);
        const { payload } = (await dispatch(
            getExternalProductsAction({ productIds }),
        )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;
        if (payload.data) {
            const availableProducts = Object.values(payload.data.mfSection).reduce(
                (agg, item: any) => agg.concat(item),
                Array<ExternalProduct>(),
            );

            const companyIds = availableProducts.map((product) => product.companyID);
            const uniqueCompanyIds = [...new Set(companyIds)];
            const companiesPayloadData = (await dispatch(getBrandByIdAction(uniqueCompanyIds))) as ActionResponse<
                Array<BrandCompany>
            >;
            const companiesAll = companiesPayloadData?.payload?.data;
            for (let i = 0; i < availableProducts.length; i++) {
                const p = availableProducts[i];
                const mfSection = p.mfSection?.number;

                if (companiesAll && mfSection) {
                    const companyOfProduct = companiesAll.find((c) => c.id === p.companyID);
                    if (companyOfProduct) {
                        const section = companyOfProduct.specs?.find((c) => c.mfSectionNumber === mfSection)
                        if (section) {
                            p.specUrl = section.specUrl;
                        }
                    }
                }
            }
            const selectedProduct = getSelectedProduct(availableProducts);
            if (selectedProduct) {
                const company = await getCompanyData(selectedProduct);
                const productsByCompany = availableProducts.filter(
                    (ap) => ap.companyID === selectedProduct?.companyID,
                );
                const internalProductsByCompany = internalProducts.filter((ip) =>
                    productsByCompany.some((ep) => ep.id === ip.productId),
                );
                const projectDataFromRequest = await getProjectData();
                setNavigationBar(projectDataFromRequest, selectedProduct.company);
                if (company) {
                    productsByCompany.forEach((p) => ({
                        ...p,
                        companyLevel: company.level,
                        listingUrl: company.listingUrl,
                    }));
                }
                setDivisions(internalProductsByCompany, productsByCompany);
            } else {
                setState({
                    ...state,
                    divisions: [],
                    pending: false,
                });
            }
        }
    };

    const getSelectedProduct = (availableProducts: ExternalProduct[]) => {
        let selectedProduct = availableProducts.find(
            (ep) => ep.id.toString() === params.productId.toString(),
        );
        if (!selectedProduct) {
            selectedProduct = availableProducts[0];
        }
        return selectedProduct;
    };

    const setDivisions = (
        internalProducts: Array<InternalProduct>,
        externalProducts: Array<ExternalProduct>,
    ) => {

/*
        const products0 = externalProducts.reduce((agg, value) => {
            const internalProduct = internalProducts.find(
                (product) => product.productId === value.id,
            );
            if (internalProduct) {
                agg.push({
                    ...value,
                    ...internalProduct,
                    dateAdded: internalProduct.dateAdded,
                    lastUpdated: internalProduct.lastUpdated,
                    product: internalProduct,
                    lowestLevelNumber:
                        value?.mfSectionLevel3?.number ||
                        value?.mfSectionLevel2?.number ||
                        value?.mfSectionLevel1?.number ||
                        value.mfSection.number,
                    lowestLevelLabel:
                        value?.mfSectionLevel3?.label ||
                        value?.mfSectionLevel2?.label ||
                        value?.mfSectionLevel1?.label ||
                        value.mfSection.number,
                });
            }
            return agg;
        }, Array<Product>());
*/

        const products = internalProducts.reduce((agg, value) => {
                const availableProduct = externalProducts.find(
                    (product) => product.id === value.productId,
                );
                if (availableProduct) {
                    agg.push({
                        ...availableProduct,
                        ...value,
                        dateAdded: value.dateAdded,
                        lastUpdated: value.lastUpdated,
                        product: value,
                        projectProductId: value.id,
                        lowestLevelNumber:
                            availableProduct?.mfSectionLevel3?.number ||
                            availableProduct?.mfSectionLevel2?.number ||
                            availableProduct?.mfSectionLevel1?.number ||
                            availableProduct.mfSection.number,
                        lowestLevelLabel:
                            availableProduct.mfSectionLevel3?.label ||
                            availableProduct.mfSectionLevel2?.label ||
                            availableProduct.mfSectionLevel1?.label ||
                            availableProduct.mfSection.label,
                    });
                }
                return agg;
            }, Array<Product>());



        if (products) {
            const divisions = new Map();
            products.forEach((product) => {
                const key = parseInt(product.mfDivision.number[0] + product.mfDivision.number[1]);
                const highestSection = product.mfSectionLevel1 || product.mfSection;
                if (divisions.has(key)) {
                    if (divisions.get(key).sections.has(highestSection.number.slice(0, 5))) {
                        divisions
                            .get(key)
                            .sections.get(highestSection.number.slice(0, 5))
                            .products.push(product);
                    } else {
                        divisions.get(key).sections.set(highestSection.number.slice(0, 5), {
                            id: highestSection.number.slice(0, 5),
                            key: parseInt(highestSection.number[3] + highestSection.number[4]),
                            name: highestSection.label,
                            clicked: false,
                            products: new Array(product),
                        });
                    }
                } else {
                    divisions.set(key, {
                        id: product.mfDivision.number,
                        name: product.mfDivision.label,
                        key: key,
                        sections: new Map([
                            [
                                highestSection.number.slice(0, 5),
                                {
                                    id: highestSection.number.slice(0, 5),
                                    key: parseInt(
                                        highestSection.number[3] + highestSection.number[4],
                                    ),
                                    name: highestSection.label,
                                    clicked: false,
                                    products: new Array(product),
                                },
                            ],
                        ]),
                        clicked: false,
                    });
                }
            });
            for (const division of divisions.values()) {
                division.sections = [...division.sections.values()].sort(
                    (a: Section<Product>, b: Section<Product>) => a.key - b.key,
                );
            }
            const uniqueDivisions = [...divisions.values()];

            const selectedProduct = externalProducts.find(
                (product) => product.id.toString() === params.productId.toString(),
            );
            if (selectedProduct) {
                showSelectedProduct(selectedProduct, uniqueDivisions);
            }

            setState({
                ...state,
                divisions: uniqueDivisions,
                pending: false,
                productsCount: products.length,
            });
        }
    };

    const showSelectedProduct = (product: ExternalProduct, divisions: Array<Division<Product>>) => {
        const divisionIndex = divisions.findIndex((d) => d.id === product.mfDivision.number);
        const highestSection = product.mfSectionLevel1 || product.mfSection;
        if (divisionIndex !== -1) {
            divisions[divisionIndex].clicked = true;
            const sectionIndex = divisions[divisionIndex].sections.findIndex(
                (s) => s.id === highestSection.number.slice(0, 5),
            );
            if (sectionIndex !== -1) {
                divisions[divisionIndex].sections[sectionIndex].clicked = true;
                setTimeout(() => {
                    document.getElementById(`${product.id}`)?.scrollIntoView({
                        behavior: 'smooth',
                    });
                }, 500);
            }
        }
    };

    const handleDivisionOpen = (id: string) => {
        const index = state.divisions.findIndex((division) => division.id === id);
        if (index !== -1) {
            const currentDivisions = state.divisions;
            currentDivisions[index].clicked = !currentDivisions[index].clicked;
            setState({ ...state, divisions: currentDivisions });
        }
    };

    const handleSectionOpen = (divisionId: string, sectionId: string) => {
        const divisionIndex = state.divisions.findIndex((division) => division.id === divisionId);
        const sectionIndex = state.divisions[divisionIndex].sections.findIndex(
            (section) => section.id === sectionId,
        );
        if (divisionIndex !== -1 && sectionIndex !== -1) {
            const currentDivisions = state.divisions;
            currentDivisions[divisionIndex].sections[sectionIndex].clicked =
                !currentDivisions[divisionIndex].sections[sectionIndex].clicked;
            setState({ ...state, divisions: currentDivisions });
        }
    };
    if (!brandInformation) {
        return (
            <MiddleSpin spinning={!brandInformation} size="large">
                <EmptyPendingPage />
            </MiddleSpin>
        );
    }
    return (
        <>
            <PageHeader
                title={`${
                    brandInformation?.company ? brandInformation?.company + ' product list' : ''
                } `}
                children={
                    <ProjectInfoActions
                        project={projectData}
                        projectReferences={projectReferences}
                    />
                }
            />
            <MFRPageContainer>
                <MFRPageHeader project={projectData} />
                <ReturnToProductTabContainer>
                    <ReturnToProductTabLink
                        to={`/project-info/${projectData?.id}/0/2?productId=${params.productId}`}
                    >
                        <Image src="src/assets/images/return-arrow.svg" preview={false} />
                        <Text weight={400} height={18} size={14} color={blueLink}>
                            Return to Products tab
                        </Text>
                    </ReturnToProductTabLink>
                </ReturnToProductTabContainer>
                <MFRPageCompanyHeader company={brandInformation} onToggle={onToggle} user={authReducer.user} />
                <ProductsCount color={blue01} weight={600} height={24} size={20}>
                    Products saved to this project ({state.productsCount})
                </ProductsCount>
                {!state.pending && projectData ? (
                    <MFRPageContent>
                        {state.divisions.map((division) => (
                            <MFRDivisionItem
                                key={division.id}
                                division={division}
                                handleClicked={handleDivisionOpen}
                                handleSectionOpen={handleSectionOpen}
                                references={references}
                                projectData={projectData}
                                commentId={params.commentId}
                                selectedProductId={params.productId}
                                openingMode={params.mode}
                                editable={getProjectPermission(
                                    [
                                        ProjectPermissionTypes.EDITOR,
                                        ProjectPermissionTypes.OWNER,
                                        ProjectPermissionTypes.PROJECT_ADMIN,
                                    ],
                                    projectData?.userRole,
                                )}
                                showHelp={
                                    brandInformation?.contactEmail !== null &&
                                    brandInformation?.contactInfo !== null
                                }
                            />
                        ))}
                    </MFRPageContent>
                ) : (
                    <Spin spinning={true}>
                        <MFRPageContent></MFRPageContent>
                    </Spin>
                )}
            </MFRPageContainer>
            {isOpen && (
                <HelpModal
                    isOpen={isOpen}
                    handleCancel={onToggle}
                    companyId={brandInformation?.id || 0}
                    project={projectData}
                />
            )}
        </>
    );
};

export default MFRPage;
