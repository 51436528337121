import { Dropdown, Image, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import {
    GetProjectInformationResponse,
    TeamMember,
} from '../../../../redux/actions/projectActions/types';
import { blueDark01 } from '../../../../styles/colors';
import {
    PageContainer, PrimaryButton,
} from '../../../../styles/common.styles';
import {
    ProjectTeamMembersList,
    ProjectTeamMembersListItem,
    ProjectTeamMembersListItemDropdownButton,
    ProjectTeamMembersListItemRole,
    ProjectTeamMembersListItemUsername,
    ProjectTeamMembersUpdateButtonWrapper,
    ProjectTeamTabTitle,
    ProjectTeamText,
} from './ProjectTeam.styled';
import { InviteTipContainer, RoleTip } from '../../../mfrPage/mfrProduct/mfrProductInfo/Components/ShareProduct.styles';
import InitialAvatar from '../../../../components/common/InitialAvatar/InitialAvatar';
import { ProjectRole, SelectData } from '../../../../models/common/types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { CaretDownOutlined } from '@ant-design/icons';
import { alertService } from '../../../../services';
import { AlertMessages } from '../../../../components/alert/AlertMessages';
import { removeProjectAccessAction, updateTeamAction } from '../../../../redux/actions/projectActions';
import { getAvailableProjectRoles } from '../../../../helpers/projectAccess';
import { ProjectPermissionTypes } from '../../../../models/permission/enums';

interface Props {
    projectInfo: GetProjectInformationResponse | null;
    members: TeamMember[],
    projectRoles: ProjectRole[] | undefined
    reload: () => Promise<void>
}

const ProjectTeamTab: React.FC<Props> = ({ projectRoles, members, projectInfo, reload }) => {
    const dispatch = useAppDispatch()
    const [projectRolesMenu, setProjectMenuRoles] = useState<{
        allRoles: Array<ItemType> | undefined;
        ownerDomain: string;
    }>({
        allRoles: undefined,
        ownerDomain: '',
    });

    const [changedUsersRole, setChangedUsersRole] = useState<{ projectId: number, roleId: number, email: string, userId: number }[]>([])

    const getUserRole = (roleId: number | undefined) => {
        if (!roleId) {
            return ProjectPermissionTypes.OWNER
        }
        return projectRoles?.find((projectRole) => projectRole.id === roleId)?.name
    }
    const getUserRole2 = (roleId: number | undefined) => {
        return getUserRole(roleId).replace('Project','')
    }

    const { authReducer } = useAppSelector(store => store)

    const currentUser = authReducer.user

    useEffect(() => {
        if (!projectRoles) {
            return;
        }
        const currentProjectRoles: Array<SelectData> = JSON.parse(JSON.stringify(projectRoles));
        const allRoles = currentProjectRoles?.map((role) => ({ key: role.id, label: role.name }));
        let ownerDomain = '';
        const ownerDomainSplit = authReducer.user?.email.split('@');
        if (ownerDomainSplit) {
            ownerDomain = ownerDomainSplit[ownerDomainSplit?.length - 1];
        }
        setProjectMenuRoles({
            ...projectRolesMenu,
            allRoles: allRoles || [],
            ownerDomain: ownerDomain,
        });
    }, [projectRoles]);

    const handleSelectMenu = async (member: TeamMember, roleId: string) => {
        if (!roleId) {
            return;
        }

        if (roleId === "remove") {
            const { payload } = await dispatch(
                removeProjectAccessAction({ projectId: projectInfo!.id, email: member.email }),
            );
            if (payload) {
                reload();
                alertService.success(AlertMessages.REMOVE_ACCESS);
            }
            return;
        }

        setChangedUsersRole(oldState => {
            const newState = [...oldState]
            const changedUserIndex = oldState.findIndex(user => user.userId === member.userId)
            if (changedUserIndex !== -1) {
                if (+roleId === member.roleId) {
                    newState.splice(changedUserIndex, 1)
                } else {
                    newState[changedUserIndex].roleId = +roleId
                }
            } else if (+roleId !== member.roleId) {
                newState.push({ projectId: projectInfo!.id, email: member.email, roleId: +roleId, userId: member.userId })
            }

            return newState
        })
    };

    async function handleSubmitChangedRoles () {
        const { payload } = await dispatch(
            updateTeamAction(changedUsersRole),
        );
        if (payload) {
            reload();
            alertService.success(AlertMessages.UPDATE_MEMBER_ROLE);
        }
    }

    return (
        <PageContainer>
            <Spin
                size="large"
                spinning={!members.length}
            >
                <ProjectTeamTabTitle>Project team</ProjectTeamTabTitle>
                <ProjectTeamText weight={400} size={14} height={18} color={blueDark01}>Team includes the Project owner(s) and other people who have been invited to work on the project. <InviteTipContainer>
                    <Image preview={false} src="src/assets/images/circle-question.svg" />
                    <RoleTip className="tip">
                        <div>
                            <strong>Types of collaborator</strong>
                        </div>
                        <div>
                            Not every role below will be available for every person you invite to <br />{' '}
                            the project.
                        </div>
                        <div>
                            <strong>Commenters</strong> can view or comment on a Project. Anyone can have this role. This is the
                            only role available to people outside your organization.
                        </div>
                        <div>
                            <strong>Editors</strong> can view, edit, comment on, upload to, export and copy a Project. Editors must
                            be members of your organization with paid accounts.
                        </div>
                        <div>
                            <strong>Owners</strong> can do everything Editors do, but also control Project access, including being
                            able to invite others to the Project, delete it or assign its ownership to another person.
                            Owners must be members of your organization with paid accounts.
                        </div>
                        <div>
                            <strong>Admins</strong> are administrators of your Office subscription. They can do everything Owners
                            can do, so invite them to a Project when you have work for them to do.
                        </div>
                    </RoleTip>
                </InviteTipContainer>
                </ProjectTeamText>
                <ProjectTeamMembersList>
                    {members.map(
                        member => {
                            let allRoles = Array<ItemType>();
                            const roleId = member.roleId;
                            const collaboratorDomainSplit = member.email.split('@');
                            const collaboratorDomain =
                                collaboratorDomainSplit[collaboratorDomainSplit.length - 1];
                            const additionalOptions: Array<ItemType> = [
                                { type: 'divider' },
                                {
                                    key: 'remove',
                                    label: 'Remove',
                                },
                            ];
                            const availableRoles = getAvailableProjectRoles(
                                projectRolesMenu.ownerDomain,
                                collaboratorDomain,
                                member,
                            );
                            if (projectRolesMenu.allRoles) {
                                allRoles = (projectRolesMenu.allRoles as any).filter(
                                    (role: any) =>
                                        availableRoles.some(
                                            (availableRole) => role.label === availableRole,
                                        ),
                                );
                                if (member.roleId) {
                                    allRoles = allRoles.concat(additionalOptions);
                                }
                            }

                            return <ProjectTeamMembersListItem key={member.userId}>
                                <InitialAvatar initial={member.username?.[0] || member.email[0]} outlined={!(member.isAccountAdmin || member.isAccountMember || member.isAccountAssociate)} />
                                <ProjectTeamMembersListItemUsername href={`mailto:` + member.email}>{member.username || member.email}</ProjectTeamMembersListItemUsername>
                                <ProjectTeamMembersListItemRole>
                                    {
                                        getUserRole(members.find(collaborator => currentUser?.id === collaborator.userId)?.roleId) === ProjectPermissionTypes.OWNER && !member.isCreator ?
                                            <Dropdown
                                                menu={{
                                                    items:
                                                        allRoles?.map((role: any) => ({
                                                            ...role,
                                                            label: role.label?.replace(
                                                                'Project',
                                                                '',
                                                            ),
                                                        })) || [],
                                                    onClick: ({ key: roleId }: { key: string }) => {
                                                        handleSelectMenu(member, roleId)
                                                    },
                                                }}
                                                placement="bottom"
                                                trigger={['click']}
                                            >
                                                <ProjectTeamMembersListItemDropdownButton block>
                                                    {projectRoles?.find(role => role.id === (changedUsersRole.find((user) => user.userId === member.userId)?.roleId || member.roleId))?.name.replace('Project', '') ||
                                                        'Invite as...'}{' '}
                                                    <CaretDownOutlined />
                                                </ProjectTeamMembersListItemDropdownButton>
                                            </Dropdown>
                                            : getUserRole2(member.roleId)}</ProjectTeamMembersListItemRole>
                            </ProjectTeamMembersListItem>
                        }
                    )}
                </ProjectTeamMembersList>
                <ProjectTeamMembersUpdateButtonWrapper>
                    {currentUser?.id === projectInfo?.createdById && <PrimaryButton onClick={handleSubmitChangedRoles} disabled={!changedUsersRole.length} htmlType="submit">
                        Save
                    </PrimaryButton>}
                </ProjectTeamMembersUpdateButtonWrapper>
            </Spin>
        </PageContainer>
    );
};

export default ProjectTeamTab;
