import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import CustomInput from '../../components/common/inputs/CustomInput';
import { SubscriptionTypes } from '../../models/subscription/enums';
import { ChangePasswordRequest, CreateUserRequest } from '../../redux/actions/userActions/types';
import { blueLink } from '../../styles/colors';
import {
    InvisibleButton,
    Margin,
    SecondaryButton,
    SpaceBetween,
    Text,
} from '../../styles/common.styles';
import {
    BasicInfoColumn,
    BasicInfoContainer,
    BasicInfoDivider,
    BasicInfoForm,
    BasicInfoPasswordContainer,
    BasicInfoPhoneInputContainer,
    BasicInfoResetPassword,
    BasicInformationTitle,
    BasicInfoRow,
    BasicInfoSection,
    BasicPasswordTitle,
} from './BasicInformation.styles';

interface Props {
    user: CreateUserRequest | undefined;
    userSubscriptionType: string;
    onSubmit: (data: CreateUserRequest) => void;
    submitContainer: (callback: () => void) => JSX.Element;
    resetPassword?: () => void;
    isOwner: boolean;
    submitPassword: (data: ChangePasswordRequest) => void;
    informationCards?: () => JSX.Element;
}

const BasicInformationTab: React.FC<Props> = ({
    user,
    userSubscriptionType,
    isOwner,
    onSubmit,
    submitContainer,
    resetPassword,
    submitPassword,
    informationCards,
}) => {
    const [form] = Form.useForm();
    const isPhoneRequired =
        userSubscriptionType === SubscriptionTypes.OFFICE ||
        userSubscriptionType === SubscriptionTypes.INDIVIDUAL;

    useEffect(() => {
        if (user) {
            form.setFieldsValue(user);
        }
    }, [user]);

    const submitChangePasswordButton = () => {
        return (
            <SpaceBetween>
                <SecondaryButton htmlType="submit">Change password</SecondaryButton>
            </SpaceBetween>
        );
    };

    return (
        <BasicInfoContainer>
            <BasicInfoRow>
                <BasicInfoColumn>
                    <BasicInfoForm onFinish={onSubmit} form={form}>
                        <BasicInfoColumn>
                            <BasicInfoSection>
                                <BasicInformationTitle>
                                    {isOwner ? `About you` : 'Basic information'}
                                </BasicInformationTitle>

                                <Margin value="0 0 28px 0" />
                                <CustomInput
                                    Component={Input}
                                    label={'First name'}
                                    name={'firstName'}
                                    required
                                    block
                                />
                                <CustomInput
                                    Component={Input}
                                    label={'Last name'}
                                    name={'lastName'}
                                    required
                                    block
                                />
                                <CustomInput
                                    Component={Input}
                                    label="Title/role"
                                    name="titleRoleID"
                                    required
                                    block
                                    rules={[{ required: true, message: '' }]}
                                />
                                <CustomInput
                                    Component={Input}
                                    label={'Email'}
                                    name={'email'}
                                    required
                                    block
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please add your email',
                                        },
                                        {
                                            pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                                            message: 'Invalid email',
                                        },
                                    ]}
                                />
                                <BasicInfoPhoneInputContainer>
                                    <CustomInput
                                        Component={Input}
                                        label={'Phone'}
                                        name={'phoneNumber'}
                                        required={isPhoneRequired}
                                    />
                                    <CustomInput
                                        Component={Input}
                                        label={'Ext.'}
                                        name={'phoneNumberExt'}
                                    />
                                </BasicInfoPhoneInputContainer>
                            </BasicInfoSection>
                        </BasicInfoColumn>
                    </BasicInfoForm>
                    <BasicInfoForm onFinish={submitPassword}>
                        <BasicInfoColumn>
                            <BasicInfoSection>
                                <BasicPasswordTitle>Password</BasicPasswordTitle>
                                {isOwner ? (
                                    <BasicInfoPasswordContainer>
                                        <Margin value="0 0 28px 0" />
                                        <CustomInput
                                            Component={Input.Password}
                                            label={'Old password'}
                                            name={'oldPassword'}
                                            required
                                            block
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        />
                                        <CustomInput
                                            Component={Input.Password}
                                            label={'New password'}
                                            name={'password'}
                                            required
                                            block
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                                {
                                                    pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$/,
                                                    message:
                                                        'Should be at least 6 characters, numbers and letters only',
                                                },
                                            ]}
                                        />
                                        <CustomInput
                                            Component={Input.Password}
                                            label={'Confirm password'}
                                            name={'confirmPassword'}
                                            required
                                            block
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password!',
                                                },
                                                ({ getFieldValue }: any) => ({
                                                    validator(_: any, value: any) {
                                                        if (
                                                            !value ||
                                                            getFieldValue('password') === value
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(`Passwords don't match`),
                                                        );
                                                    },
                                                }),
                                            ]}
                                        />
                                        <CustomInput
                                            Component={submitChangePasswordButton}
                                            label=" "
                                            name={''}
                                        />
                                    </BasicInfoPasswordContainer>
                                ) : (
                                    <BasicInfoResetPassword>
                                        <Margin value="0 0 14px 0" />
                                        <InvisibleButton onClick={resetPassword}>
                                            <Text
                                                weight={400}
                                                size={13}
                                                height={16}
                                                color={blueLink}
                                                font={'Proxima Nova'}
                                            >
                                                Reset password
                                            </Text>
                                        </InvisibleButton>
                                    </BasicInfoResetPassword>
                                )}
                            </BasicInfoSection>
                        </BasicInfoColumn>
                    </BasicInfoForm>
                </BasicInfoColumn>
                {informationCards ? informationCards() : <></>}
            </BasicInfoRow>
            <BasicInfoDivider />
            {submitContainer(() => form.submit())}
        </BasicInfoContainer>
    );
};

export default BasicInformationTab;
