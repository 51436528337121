import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import CustomInput from '../../components/common/inputs/CustomInput';
import { useAppDispatch } from '../../hooks/redux';
import { resetPasswordAction } from '../../redux/actions/authActions';

import {
    ResetPasswordContainer,
    ResetPasswordTitle,
    ResetPasswordTitleForm,
    ResetPasswordWrapper,
    SubmitButtonContainer,
} from './ResetPassword.styles';
import { PrimaryButton, SpaceCenter, TypographyText } from '../../styles/common.styles';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';

const ResetPassword: React.FC = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const history = useHistory();
    const dispatch = useAppDispatch();

    const [state, setState] = useState({
        isPasswordReset: false,
    });

    useEffect(() => {
        dispatch(addPages([{ key: NavigationKey.HOME, value: 'SM Transparency Catalog' }]));
    }, []);

    const onSubmit = async (data: { password: string; confirmPassword: string }) => {
        const userId = params.get('id') || '';
        const key = params.get('key') || '';
        const { payload } = (await dispatch(
            resetPasswordAction({
                password: data.password,
                key,
                userId,
                confirmPassword: data.confirmPassword,
            }),
        )) as any;
        if (payload.message) {
            setState({ ...state, isPasswordReset: true });
        }
    };

    const goToLogin = () => {
        history.push('/login');
    };

    const submitButton = () => {
        return <PrimaryButton htmlType="submit">Set new password</PrimaryButton>;
    };

    return (
        <ResetPasswordWrapper>
            {!state.isPasswordReset ? (
                <ResetPasswordContainer>
                    <ResetPasswordTitle level={2}>Reset password</ResetPasswordTitle>
                    <ResetPasswordTitleForm>
                        <TypographyText>
                            Set a password of at least 6 characters with letters and numbers. Both
                            passwords must match.
                        </TypographyText>
                    </ResetPasswordTitleForm>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        initialValues={{ remember: false }}
                        onFinish={onSubmit}
                        autoComplete="off"
                    >
                        <div>
                            <CustomInput
                                Component={Input.Password}
                                label="Password"
                                name="password"
                                required
                                rules={[
                                    { required: true, message: '' },
                                    {
                                        pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$/,
                                        message: 'Invalid password',
                                    },
                                ]}
                            />
                        </div>
                        <div>
                            <div id="confirm-container" className={'required'}>
                                <Form.Item
                                    name="confirmPassword"
                                    label="Confirm Password*"
                                    dependencies={['password']}
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(`Passwords don't match`),
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="new-password" />
                                </Form.Item>
                            </div>
                        </div>

                        <SubmitButtonContainer>
                            <CustomInput Component={submitButton} name="submit" label="submit" />
                        </SubmitButtonContainer>
                    </Form>
                </ResetPasswordContainer>
            ) : (
                <ResetPasswordContainer>
                    <Form.Item>
                        <SpaceCenter>
                            <TypographyText>Your password was changed successfully</TypographyText>
                        </SpaceCenter>
                    </Form.Item>
                    <SpaceCenter>
                        <PrimaryButton onClick={goToLogin}>Back to login</PrimaryButton>
                    </SpaceCenter>
                </ResetPasswordContainer>
            )}
        </ResetPasswordWrapper>
    );
};

export default ResetPassword;
