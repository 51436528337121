import styled from 'styled-components';
import {
    grayDark05,
    grayDark06,
    grayLight02,
    grayLight04,
    grayLight05,
    teal05,
    blueDark02,
    blueLink,
    white,
    grayDark01,
} from '../../../../styles/colors';
import { PrimaryButton } from '../../../../styles/common.styles';

export const UploadsTableContainer = styled('div')`
    width: 75%;
    min-width: 800px;
    @media screen and (max-width: 760px) {
        width: 100%;
    }
`;

export const DownloadButtonsContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
`;

export const UploadTableHead = styled('div')`
    display: flex;
    margin-right: 15px;
`;

export const TableHeadName = styled('div')`
    padding-left: 32px;
    width: 40%;
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    min-width: 260px;
    .ant-image-img {
        width: 7px;
        height: 10px;
    }
    .rotate {
        transform: rotate(180deg);
        margin-bottom: 5px;
    }
    &.pictureTable {
        min-width: 230px;
        width: 25%;
    }
`;

export const TableHeadGeneral = styled('div')`
    display: flex;
    gap: 4px;
    width: 15%;
    align-items: center;
    cursor: pointer;
    &.action {
        width: 10%;
    }
    &.expires {
        width: 20%;
    }
    &.pictureTableExpires {
        width: 20%;
    }
    &.pictureTable {
        width: 30%;
    }
    &.pictureTableSize {
        width: 10%;
    }
    &.pictureTableDate {
        width: 15%;
    }
    .ant-image-img {
        width: 7px;
        height: 10px;
    }
    .rotate {
        transform: rotate(180deg);
        margin-bottom: 5px;
    }
`;

export const TableHeadGeneral12 = styled(TableHeadGeneral)`
    gap: 12px;
`;

export const TableHeadExpires = styled('div')`
    width: 132px;
`;

export const DocumentsContainer = styled('div')`
    background: #ffffff;
    height: 500px;
    border: 1px solid ${grayDark06};
    border-radius: 3px;
`;

export const AltTextTooltip = styled('div')`
    position: absolute;
    bottom: 0%;
    left: 0%;
    margin: 4px;
    font-family: 'Proxima Nova';
    font-size: 9px;
    line-height: 11px;
    padding: 4px;
    background-color: ${grayDark01};
    color: white;
    display: flex;
    align-items: center;
`;

export const UploadTableBody = styled(DocumentsContainer)`
    scroll-behavior: smooth;
    .row {
        background-color: ${grayLight04};
    }
    .container {
        &:hover {
            background-color: ${teal05};
        }
        border-bottom: 1px solid ${grayLight02};
    }
    overflow: hidden scroll;
    &::-webkit-scrollbar {
        width: 14px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px 6px ${grayLight04};
        border: solid 4px transparent;
        border-radius: 14px;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 14px 14px ${grayDark05};
        border: solid 4px transparent;
        border-radius: 14px;
    }
    &::-webkit-scrollbar-button {
        display: none;
    }
`;

export const DownloadAllButton = styled(PrimaryButton)`
    width: 170px;
    height: 32px;
    border: 1px solid ${blueDark02};
    border-radius: 3px;
    background-color: inherit;
    color: ${blueDark02};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${grayLight05};
        border: 1px solid ${blueDark02};
        color: ${blueDark02};
    }
    &:disabled {
        background-color: inherit;
        border: 1px solid ${blueDark02};
        color: ${blueDark02};
        &:hover {
            background-color: inherit;
            border: 1px solid ${blueDark02};
            color: ${blueDark02};
        }
    }
`;

export const PaginationContainer = styled('div')`
    margin-right: 22px;
    height: 28px;
    .ant-space {
        height: 28px;
    }
`;

export const DocumentTypeTextBlock = styled('div')`
    margin: 0 0 8px 0;
    // max-width: 616px;
    width: 100%;
    font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    b {
        font-family: Proxima N W15 Bold;
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
        text-align: left;
    }
    a {
        font-family: Proxima Nova;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: #134FBD;
    }
`;

export const DocumentTypeButtonLinkBlock = styled('div')`
    margin: 0 0 25px 0;
    display: inline-flex;
`;

export const DocumentTypeButtonBlock = styled('div')`

`;

export const DocumentTypeLinkBlock = styled('div')`
    margin-left: 8px;
    a {
        font-family: Proxima Nova;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: #134FBD;
    }
`;


