import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { exportToEC3Action } from '../../actions/ec3Actions';

export interface EC3Type {
    token: string;
    refreshToken: string;
    pending: boolean;
    exportedProject?: number;
    ec3Project: { link: string; projectId: number } | null;
    exportBegan: boolean;
    pageFromRequest: string;
}

const initialState: EC3Type = {
    token: '',
    refreshToken: '',
    pending: false,
    exportedProject: 0,
    ec3Project: null,
    exportBegan: false,
    pageFromRequest: '',
};
export const ec3Slice = createSlice({
    name: 'EC3',
    initialState: initialState,
    reducers: {
        setEC3AuthToken(
            state,
            action: PayloadAction<{ token: string; refreshToken: string; projectId?: number }>,
        ) {
            state.token = action.payload.token;
            state.exportedProject = action.payload.projectId;
            state.refreshToken = action.payload.refreshToken;
        },
        setExportedProject(state, action: PayloadAction<number | undefined>) {
            state.exportedProject = action.payload;
        },
        setEc3Project(state, action: PayloadAction<{ link: string; projectId: number } | null>) {
            state.ec3Project = action.payload;
        },
        setEc3ProjectBegan(state) {
            state.exportBegan = !state.exportBegan;
        },
        setPageFromRequest(state, action: PayloadAction<{ page: string }>) {
            state.pageFromRequest = action.payload.page;
        },
        clearEC3Slice(state) {
            state.token = '';
            state.refreshToken = '';
            state.pageFromRequest = '';
            state.pending = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exportToEC3Action.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(exportToEC3Action.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(exportToEC3Action.rejected, (state) => {
            state.pending = false;
        });
    },
});

export const {
    setEC3AuthToken,
    setEc3Project,
    setEc3ProjectBegan,
    setPageFromRequest,
    setExportedProject,
    clearEC3Slice,
} = ec3Slice.actions;

export default ec3Slice.reducer;
