import { Image } from 'antd';
import React from 'react';
import { EPD, EPDSection, MaterialDisclosures } from './ProductEPDSection.styles';
import { BrandCompany, FavoriteProduct, Product } from '../../../../../../models/product/types';

interface Props {
    product?: Product;
    epdProgram?: string;
    hpd?: string;
}

const ProductEPDSection: React.FC<Props> = ({ product, epdProgram, hpd }) => {
    const epdChecked = !!epdProgram && !!product.epdProgram && epdProgram !== 'IA null';
    const materialDisclosureChecked = (product.hpd !== null) || (product.declare !== null) || (product.c2cCertified !== null) || 
              (product.c2cMHC !== null) || (product.level !== null) || (product.lpc !== null) || (product.mfrInv !== null);
    return (
        <EPDSection>
            <EPD className={epdChecked ? 'check' : ''}>
                {epdChecked && <Image src="src/assets/images/circle-check.svg" preview={false} />}
                <span>EPD</span>
            </EPD>
            <MaterialDisclosures className={materialDisclosureChecked ? 'check' : ''}>
                {materialDisclosureChecked && <Image src="src/assets/images/circle-check-aqua.svg" preview={false} />}
                <span>Material disclosures</span>
            </MaterialDisclosures>
        </EPDSection>
    );
};

export default ProductEPDSection;
