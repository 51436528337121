import { Radio, RadioChangeEvent, Tooltip } from 'antd';
import React, { useState } from 'react';
import { toShortLocalDate } from '../../../../helpers/dateHelper';
import { usePermissions } from '../../../../hooks/permissions';
import { useAppDispatch } from '../../../../hooks/redux';
import { FavoriteType } from '../../../../models/product/enums';
import {
    PricingError,
    PricingInputContainer,
} from '../../../../pages/projectInfo/tabs/productsTab/productItem/ProductItem.styles';
import {
    changeFavoriteNotesAction,
    changeFavoriteDescriptionAction,
    changeFavoritePricingAction,
    changeFavoritePricingTypeAction,
} from '../../../../redux/actions/favoriteProductsActions';
import { ProductReferences } from '../../../../redux/actions/productActions/types';
import { alertService } from '../../../../services/alert.service';
import { grayDark05 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common.styles';
import { MergedFavoriteProduct } from '../../FavoriteProducts.types';
import {
    FavoriteDate,
    FavoriteDateAdded,
    FavoriteNote,
    FavoriteNoteInput,
    FavoriteNoteLabel,
    FavoriteNoteSectionContainer,
    FavoriteDescription,
    FavoriteDescriptionInput,
    FavoriteDescriptionLabel,
    FavoriteDescriptionSectionContainer,
    FavoritePrice,
    FavoritePriceInput,
    FavoriteProductRadioButtonContainer,
} from './FavoriteNoteSection.styles';

interface Props {
    references: ProductReferences | null;
    product: MergedFavoriteProduct;
    favoriteType: FavoriteType;
}

const FavoriteNoteSection: React.FC<Props> = ({ references, product, favoriteType }) => {
    const dispatch = useAppDispatch();

    const { checkProductActionPermission } = usePermissions();

    const [state, setState] = useState({ current: product });
    const [errors, setErrors] = useState({
        pricing: false,
        notes: false,
    });

    const handlePricingType = async (event: RadioChangeEvent) => {
        const pricingTypeId = event.target.value || ' ';
        if (pricingTypeId) {
            const currentProduct = state.current;
            currentProduct.product.pricingTypeId = pricingTypeId;
            setState({ ...state, current: currentProduct });
            await dispatch(
                changeFavoritePricingTypeAction({
                    pricingTypeId,
                    favoriteType: favoriteType,
                    productFavoriteId: product.id,
                }),
            );
        } else {
            alertService.error('Something went wrong');
        }
    };

    const handlePricingChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length > 75) {
            setErrors({ ...errors, pricing: true });
            setTimeout(() => {
                setErrors({ ...errors, pricing: false });
            }, 5000);
            return;
        }
        const currentProduct = state.current;
        currentProduct.product.pricing = value;
        setState({ ...state, current: currentProduct });
    };

    const savePricing = async () => {
        const currentProduct = state.current;
        await dispatch(
            changeFavoritePricingAction({
                favoriteType: favoriteType,
                productFavoriteId: product.id,
                pricing: currentProduct?.product?.pricing?.trim() || '',
            }),
        );
    };

    const handleNotesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length > 500) {
            setErrors({ ...errors, notes: true });
            setTimeout(() => {
                setErrors({ ...errors, notes: false });
            }, 5000);
            return;
        }
        const currentProduct = state.current;
        currentProduct.notes = value;
        setState({ ...state, current: currentProduct });
    };

    const handleDescriptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length > 500) {
            setErrors({ ...errors, notes: true });
            setTimeout(() => {
                setErrors({ ...errors, notes: false });
            }, 5000);
            return;
        }
        const currentProduct = state.current;
        currentProduct.product.description = value;
        setState({ ...state, current: currentProduct });
    };

    const handleTextInputBlur = (event: any) => {
        event.target.scrollLeft = 0;
    }

    const handleNotesBlur = (event: any) => {
        handleTextInputBlur(event);
        saveNotes();
    }

    const handleDescriptionBlur = (event: any) => {
        handleTextInputBlur(event);
        saveDescription();
    }

    const saveNotes = async () => {
        const currentProduct = state.current;
        await dispatch(
            changeFavoriteNotesAction({
                favoriteType: favoriteType,
                productFavoriteId: product.id,
                notes: currentProduct.notes?.trim() || '',
            }),
        );
    };

    const saveDescription = async () => {
        const currentProduct = state.current;
        await dispatch(
            changeFavoriteDescriptionAction({
                favoriteType: favoriteType,
                productFavoriteId: product.id,
                description: currentProduct.product?.description?.trim() || '',
            }),
        );
    };

    return (
        <FavoriteNoteSectionContainer>
            <FavoriteNote>
                <FavoriteNoteLabel weight={400} size={9} height={11} color={grayDark05}>
                    NOTES
                </FavoriteNoteLabel>
                <PricingInputContainer>
                    <Tooltip title={state.current.notes}>
                        <FavoriteNoteInput
                            onChange={handleNotesChange}
                            value={state.current.notes}
                            disabled={checkProductActionPermission(favoriteType)}
                            onBlur={handleNotesBlur}
                            placeholder='Reason for adding to Library'
                        />
                    </Tooltip>
                    {errors.notes && <PricingError>max size 500 characters</PricingError>}
                </PricingInputContainer>
            </FavoriteNote>
            <FavoriteDescription>
                <FavoriteDescriptionLabel weight={400} size={9} height={11} color={grayDark05}>
                    DESCRIPTION
                </FavoriteDescriptionLabel>
                <PricingInputContainer>
                    <Tooltip title={state.current.product.description}>
                        <FavoriteDescriptionInput
                            onChange={handleDescriptionChange}
                            value={state.current.product.description}
                            disabled={checkProductActionPermission(favoriteType)}
                            onBlur={handleDescriptionBlur}
                            placeholder='Ex: SKU, color, use, etc.'
                        />
                    </Tooltip>
                    {errors.notes && <PricingError>max size 500 characters</PricingError>}
                </PricingInputContainer>
            </FavoriteDescription>
            <FavoritePrice>
                <FavoriteNoteLabel weight={400} size={9} height={11} color={grayDark05}>
                    PRICING
                </FavoriteNoteLabel>
                <PricingInputContainer>
                    <FavoritePriceInput
                        onChange={handlePricingChange}
                        value={state.current.product.pricing}
                        disabled={checkProductActionPermission(favoriteType)}
                        onBlur={savePricing}
                    />
                    {errors.pricing && <PricingError>max size 75 characters</PricingError>}
                </PricingInputContainer>
            </FavoritePrice>
            <FavoriteProductRadioButtonContainer>
                <Radio.Group
                    value={state?.current?.product.pricingTypeId}
                    onChange={handlePricingType}
                    disabled={checkProductActionPermission(favoriteType)}
                >
                    {references?.allPricingType.map((pricingType) => (
                        <Radio value={pricingType.id} key={pricingType.id}>
                            {pricingType.name}
                        </Radio>
                    ))}
                </Radio.Group>
            </FavoriteProductRadioButtonContainer>
            <FavoriteDateAdded>
                <FavoriteNoteLabel weight={400} size={9} height={11} color={grayDark05}>
                    DATE ADDED
                </FavoriteNoteLabel>
                <FavoriteDate>
                    <span>{toShortLocalDate(state.current.product.dateAdded)}</span>{' '}
                    {state.current.createdBy && <span>({state.current.createdBy})</span>}
                </FavoriteDate>
                <Text
                    weight={400}
                    size={10}
                    height={14}
                    color={grayDark05}
                >{`Last updated: ${toShortLocalDate(state.current.product.lastUpdated)}`}</Text>
            </FavoriteDateAdded>
        </FavoriteNoteSectionContainer>
    );
};

export default FavoriteNoteSection;
