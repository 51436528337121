import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Space } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { addPages, removePage } from '../../redux/reducers/navigationReducer/NavigationSlice';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import { SettingPageSpin, SettingsPageContainer } from './SettingsPage.styles';
import OrganizationInfo from './organizationInfo/OrganizationInfo';
import { SubscriptionInfo } from './subscriptionInfo/SubscriptionInfo';
import { SelectData } from '../../models/common/types';
import { getRegisterReferencesAction } from '../../redux/actions/authActions';
import {
    getAccountSettingsAction,
    updateAccountSettingsAction,
} from '../../redux/actions/userActions';
import { GetAccountSettingsResponse } from '../../redux/actions/userActions/types';
import { alertService } from '../../services';
import { OrganizationData } from '../../models/organization/types';
import { SettingReferences } from './SettingPage.types';
import { AlertMessages } from '../../components/alert/AlertMessages';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { SubscriptionData } from '../../models/subscription/types';

const SettingsPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);

    const { userReducer, authReducer } = selector;

    const [services, setServices] = useState({ current: Array<{ id: number; text?: string }>() });

    const [error, setError] = useState(false);

    const [organizationData, setOrganizationData] = useState<{
        organization: OrganizationData | undefined;
        subscription: SubscriptionData | undefined;
    }>({
        organization: undefined,
        subscription: undefined,
    });

    const [references, setReferences] = useState<SettingReferences>({
        allOrganizationSizes: Array<SelectData>(),
        allServices: Array<SelectData>(),
        allCountriesAndStates: Array<any>(),
        allStates: Array<any>(),
    });

    useEffect(() => {
        dispatch(
            addPages([
                {
                    key: NavigationKey.DASHBOARD,
                    value: `${authReducer.user?.firstName}’s Dashboard`,
                },
                {
                    key: NavigationKey.SETTINGS,
                    value: `Subscription settings`,
                },
            ]),
        );
        return () => {
            dispatch(removePage());
        };
    }, []);
    useEffect(() => {
        getReferences();
        getAccountSettings();
    }, []);

    const [form] = Form.useForm();
    const watch = Form.useWatch('officeCountryId', form);

    useEffect(() => {
        if (watch) {
            const currentCountry = references.allCountriesAndStates.find(
                (country) => country.id === watch,
            );
            setReferences({ ...references, allStates: currentCountry?.states || [] });
            if (currentCountry?.states.length! > 0) {
                form.setFieldValue(
                    'officeStateId',
                    organizationData.organization?.officeStateId !== 0 &&
                        organizationData.organization?.officeCountryId === watch
                        ? organizationData.organization?.officeStateId
                        : currentCountry?.states[0].id,
                );
            } else {
                const country = references.allCountriesAndStates.find(
                    (countryInArray) =>
                        countryInArray.id.toString() ===
                        organizationData.organization?.officeCountryId?.toString(),
                );
                form.setFieldValue(
                    'officeStateName',
                    country?.states.length! < 1
                        ? organizationData.organization?.officeStateName
                        : '',
                );
            }
        }
    }, [watch]);

    useEffect(() => {
        if (organizationData && organizationData.organization) {
            form.setFieldsValue({
                organizationName: organizationData?.organization.organizationName,
                organizationSizeId: organizationData?.organization?.organizationSizeId,
                officeCity: organizationData?.organization?.city,
                officeStateId: organizationData.organization.officeStateId,
                officeCountryId: organizationData.organization.officeCountryId,
                officeStateName: organizationData.organization.officeStateName,
                text: organizationData.organization.serviceText,
            });
        }
    }, [organizationData.organization]);

    const getReferences = async () => {
        const { payload } = (await dispatch(getRegisterReferencesAction())) as any;
        if (payload.data) {
            setReferences({
                allOrganizationSizes: payload?.data?.allOrganizationSizes || [],
                allServices: payload?.data?.allServices || [],
                allCountriesAndStates: payload?.data?.allCountriesAndStates || [],
                allStates: payload?.data?.allCountriesAndStates[0].states || [],
            });
        }
    };

    const getAccountSettings = async () => {
        const { payload } = (await dispatch(
            getAccountSettingsAction(),
        )) as GetAccountSettingsResponse;
        if (payload.data) {
            const serviceText = payload.data.services.find((service) => service.text);
            setServices({ current: payload.data.services });
            setOrganizationData({
                organization: {
                    organizationName: payload?.data?.organization?.name,
                    organizationSizeId: payload?.data?.organization?.sizeId,
                    city: payload?.data?.office?.city,
                    officeCountryId: payload.data?.office?.country?.id,
                    officeStateId: payload.data?.office?.state?.id,
                    officeStateName: payload.data?.office?.state?.name,
                    serviceText: serviceText?.text,
                },
                subscription: {
                    paymentMethod: payload?.data?.subscription?.paymentMethod,
                    ownerEmail: payload?.data?.email,
                    contactName: payload?.data?.contactName,
                    nextRenewOn: payload?.data?.subscription?.nextRenewOn,
                    maxUserCount: payload?.data?.subscription?.maxUserCount,
                    maxAssociateUserCount: payload?.data?.subscription?.maxAssociateUserCount,
                    userInUse: payload?.data?.subscription?.userInUse,
                    paidUserInUse: payload?.data?.subscription?.paidUserInUse,
                    freeUserInUse: payload?.data?.subscription?.freeUserInUse,
                    phone: `${payload?.data?.phone || ''}${
                        payload?.data?.phoneExt ? '-' + payload?.data?.phoneExt : ''
                    }`,
                    status: payload?.data?.subscription?.status,
                    cancelAtPeriodEnd: payload?.data?.subscription?.cancelAtPeriodEnd,
                },
            });
        }
    };

    const onSubmit = async (data: {
        organizationName: string;
        organizationSizeId: number;
        officeStateId: number;
        officeCity: string;
        text: string;
    }) => {
        if (!services.current.length) {
            setError(true);
            return;
        }
        const organizationSizeName = references.allOrganizationSizes.find(
            (size) => size.id === data.organizationSizeId.toString(),
        )?.name;
        const currentServices = services.current;
        const serviceWithText = references.allServices.find((service: any) => service.hasText);
        if (serviceWithText) {
            const serviceWithTextIndex = currentServices.findIndex(
                (service) => service.id.toString() === serviceWithText.id.toString(),
            );
            if (serviceWithTextIndex !== -1) {
                currentServices[serviceWithTextIndex].text = data.text;
            }
        }
        const { payload } = await dispatch(
            updateAccountSettingsAction({
                ...data,
                services: currentServices,
                organizationSizeName,
                officeStateId: data.officeStateId,
                officeCity: data.officeCity,
            }) as any,
        );
        if (payload) {
            window.scroll(0, 0);
            alertService.success(AlertMessages.ORGANIZATION_UPDATED);
        }
    };
    const onFailed = () => {
        if (!services.current.length) {
            setError(true);
            return;
        }
    };

    const handleServicesSelect = (event: any) => {
        const service = +event.target.name;
        const currentServices = services.current;
        const index = currentServices.findIndex((item) => item.id === service);
        if (index === -1) {
            currentServices.push({ id: service });
        } else {
            currentServices.splice(index, 1);
        }
        if (currentServices.length && error) {
            setError(false);
        }
        setServices({ ...services, current: currentServices });
    };

    return (
        <>
            <PageHeader title="Subscription settings" />
            <SettingsPageContainer>
                <SettingPageSpin size="large" spinning={userReducer.pending}>
                    <Row>
                        <Col span={24}>
                            <Space direction="vertical" className="setting-group-container">
                                <SubscriptionInfo
                                    subscriptionData={organizationData.subscription}
                                />
                                <OrganizationInfo
                                    organizationData={organizationData.organization}
                                    references={references}
                                    services={services.current}
                                    error={error}
                                    form={form}
                                    handleServicesSelect={handleServicesSelect}
                                    onSubmit={onSubmit}
                                    onFailed={onFailed}
                                />
                            </Space>
                        </Col>
                    </Row>
                </SettingPageSpin>
            </SettingsPageContainer>
        </>
    );
};

export default SettingsPage;
