export enum NavigationKey {
    DASHBOARD = '/dashboard',
    ACCOUNT = '/account',
    HOME = '/',
    PROJECT_LIBRARY = '/project-library',
    SETTINGS = '/settings',
    USERS = '/users',
    ADMINS = '/admins',
    FAVORITES = '/favorites',
    PLAN = '/plan',
    MY_FAVORITES = '/my-favorites',
}
