import { Input } from 'antd';
import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    grayDark01,
    grayDark06,
    grayLight05,
    white,
} from '../../../../styles/colors';
import { Text } from '../../../../styles/common.styles';

export const FavoriteNoteSectionContainer = styled('div')`
    width: 100%;
    background-color: ${grayLight05};
    display: flex;
    padding: 16px 25px 16px 12px;
`;

export const FavoriteNote = styled('div')`
    width: 215px;
`;
export const FavoriteNoteLabel = styled(Text)`
    margin-bottom: 4px;
`;
export const FavoriteNoteInput = styled(Input)`
    border: 1px solid ${grayDark06};
    border-radius: 3px;
    width: 100%;
`;

export const FavoriteDescriptionSectionContainer = styled('div')`
    width: 100%;
    background-color: ${grayLight05};
    display: flex;
    padding: 16px 25px 16px 12px;
`;

export const FavoriteDescription = styled('div')`
    width: 215px;
    margin-left: 20px;
`;
export const FavoriteDescriptionLabel = styled(Text)`
    margin-bottom: 4px;
`;
export const FavoriteDescriptionInput = styled(Input)`
    border: 1px solid ${grayDark06};
    border-radius: 3px;
    width: 100%;
`;

export const FavoritePrice = styled('div')`
    width: 124px;
    margin-left: 20px;
`;
export const FavoritePriceInput = styled(Input)`
    border: 1px solid ${grayDark06};
    border-radius: 3px;
    width: 100%;
`;

export const FavoriteProductRadioButtonContainer = styled('div')`
    margin-top: 13px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    .ant-radio-inner::after {
        background-color: ${white};
    }
    .ant-radio-checked .ant-radio-inner {
        background-color: ${blue01};
    }
    .ant-radio-wrapper {
        color: ${grayDark01};
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }
    .ant-radio-group {
        white-space: nowrap;
    }
`;

export const FavoriteDateAdded = styled('div')`
    margin-left: 8px;
`;

export const FavoriteDate = styled('div')`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${blueDark01};
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 7px;
    span {
        white-space: nowrap;
    }
`;
