import { ProjectPermissionTypes } from '../models/permission/enums';
import { Collaborator } from '../redux/actions/projectActions/types';

/**
 * @description Get available project roles
 * @param ownerDomain
 * @param collaboratorDomain
 * @param currentCollaborator
 * @returns Array<ProjectPermissionTypes>
 */
export const getAvailableProjectRoles = (
    ownerDomain: string,
    collaboratorDomain: string,
    currentCollaborator?: Collaborator,
): Array<ProjectPermissionTypes> => {
    if (currentCollaborator) {
        if (currentCollaborator.isAccountAdmin) {
            return [ProjectPermissionTypes.PROJECT_ADMIN];
        }
        if (currentCollaborator.isAccountMember) {
            return [
                ProjectPermissionTypes.COMMENTER,
                ProjectPermissionTypes.EDITOR,
                ProjectPermissionTypes.OWNER,
            ];
        }
        if (currentCollaborator.isAccountAssociate) {
            return [
                ProjectPermissionTypes.COMMENTER
            ];
        }
    }
    if (ownerDomain === collaboratorDomain) {
        return [ProjectPermissionTypes.COMMENTER];
    } else {
        return [ProjectPermissionTypes.COMMENTER, ProjectPermissionTypes.MANUFACTURER];
    }
};
