import { createAsyncThunk } from '@reduxjs/toolkit';
import { UploadsTableFields } from '../../../components/productInfo/uploads/Uploads.types';
import { createMultipartData } from '../../../helpers/multipartData';
import { http, httpMultipart } from '../../../helpers/request';
import { ActionResponse, CommonResponse } from '../../../models/actionResponse/types';
import { Comment } from '../../../models/comment/types';
import { FavoriteProduct, InternalProduct } from '../../../models/product/types';
import {
    AddFavoriteCommentRequest,
    UpdateFavoritePricingInfoRequest,
    AddToFavoriteRequest,
    ChangeFavoriteNotesRequest,
    ChangeFavoriteDescriptionRequest,
    ChangeFavoritePricingRequest,
    ChangeFavoritePricingTypeRequest,
    GetRecentlyFavoritesResponse,
    RemoveFromFavoriteRequest,
    UploadFavoriteFileRequest,
    UpdateFavoriteFileAction,
    GetFavoriteUploadsRequest,
    DeleteUploadDocumentFavoriteRequest,
    ChangeFavorite,
    removeFavoriteInBulkRequest,
    AddFromFavoriteRequest,
    AddToFavoriteResponse,
    AddFavoriteInBulkRequest,
    AddFavoriteInBulkResponse,
    GetUploadedFavoriteImagesCountRequest,
} from './types';

const baseUrl = `/productFavorites`;

export const addToFavoriteAction = createAsyncThunk<
    ActionResponse<FavoriteProduct> | undefined,
    AddToFavoriteRequest
>('favoriteProducts/addToFavorite', async (data: AddToFavoriteRequest) => {
    const result = await http<ActionResponse<FavoriteProduct>, AddToFavoriteRequest>({
        path: `${baseUrl}/add-favorite`,
        method: 'post',
        body: data,
    });

    console.log('AddToFavorite:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const removeFromFavoriteAction = createAsyncThunk<
    CommonResponse | undefined,
    RemoveFromFavoriteRequest
>('favoriteProducts/removeFromFavorite', async (data: RemoveFromFavoriteRequest) => {
    const result = await http<CommonResponse, RemoveFromFavoriteRequest>({
        path: `${baseUrl}/remove-favorite`,
        method: 'post',
        body: data,
    });

    console.log('RemoveFromFavorite:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getRecentlyFavoritesAction = createAsyncThunk<
    ActionResponse<GetRecentlyFavoritesResponse> | undefined,
    undefined
>('favoriteProducts/getRecentlyFavorites', async () => {
    const result = await http<ActionResponse<GetRecentlyFavoritesResponse>, undefined>({
        path: `${baseUrl}/recently-added`,
        method: 'get',
    });

    console.log('GetRecentlyAddedFavorites:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getOfficeFavoritesAction = createAsyncThunk<
    ActionResponse<Array<FavoriteProduct>> | undefined,
    undefined
>('favoriteProducts/getOfficeFavorites', async () => {
    const result = await http<ActionResponse<Array<FavoriteProduct>>, undefined>({
        path: `${baseUrl}/office-favorites`,
        method: 'get',
    });

    console.log('GetOfficeFavorites:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getMyFavoritesAction = createAsyncThunk<
    ActionResponse<Array<FavoriteProduct>> | undefined,
    undefined
>('favoriteProducts/getMyFavorites', async () => {
    const result = await http<ActionResponse<Array<FavoriteProduct>>, undefined>({
        path: `${baseUrl}/my-favorites`,
        method: 'get',
    });

    console.log('GetMyFavorites:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeFavoritePricingTypeAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ChangeFavoritePricingTypeRequest
>('favoriteProducts/changePricingType', async (data: ChangeFavoritePricingTypeRequest) => {
    const result = await http<ActionResponse<InternalProduct>, ChangeFavoritePricingTypeRequest>(
        {
            path: `${baseUrl}/pricing-type`,
            method: 'put',
            body: data,
        },
        true,
        false,
    );
    console.log('ChangeFavoritePricingType:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeFavoritePricingAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ChangeFavoritePricingRequest
>('favoriteProducts/changeFavoritePricing', async (data: ChangeFavoritePricingRequest) => {
    const result = await http<ActionResponse<InternalProduct>, ChangeFavoritePricingRequest>(
        {
            path: `${baseUrl}/pricing`,
            method: 'put',
            body: data,
        },
        true,
        false,
    );
    console.log('ChangeFavoritePricing:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeFavoriteNotesAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ChangeFavoriteNotesRequest
>('favoriteProducts/changeFavoriteNotes', async (data: ChangeFavoriteNotesRequest) => {
    const result = await http<ActionResponse<InternalProduct>, ChangeFavoriteNotesRequest>(
        {
            path: `${baseUrl}/notes`,
            method: 'put',
            body: data,
        },
        true,
        false,
    );
    console.log('UpdateFavoritePricingNotes:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeFavoriteDescriptionAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ChangeFavoriteDescriptionRequest
>('favoriteProducts/changeFavoriteDescription', async (data: ChangeFavoriteDescriptionRequest) => {
    console.log('ChangeFavoriteDescriptionRequest:');
    console.log(data);
    const result = await http<ActionResponse<InternalProduct>, ChangeFavoriteDescriptionRequest>(
        {
            path: `${baseUrl}/description`,
            method: 'put',
            body: data,
        },
        true,
        false,
    );
    console.log('UpdateFavoriteDescription:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const addFavoriteCommentAction = createAsyncThunk<
    ActionResponse<Comment> | undefined,
    AddFavoriteCommentRequest
>('favoriteProducts/addFavoriteComment', async (data: AddFavoriteCommentRequest) => {
    const result = await http<ActionResponse<Comment>, AddFavoriteCommentRequest>(
        {
            path: `${baseUrl}/add-comment`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('AddFavoriteComment:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateFavoritePricingInfoAction = createAsyncThunk<
    ActionResponse<Comment> | undefined,
    UpdateFavoritePricingInfoRequest
>('favoriteProducts/addPricingInfo', async (data: UpdateFavoritePricingInfoRequest) => {
    const result = await http<ActionResponse<Comment>, UpdateFavoritePricingInfoRequest>(
        {
            path: `${baseUrl}/pricing-information`,
            method: 'put',
            body: data,
        },
        true,
        false,
    );
    console.log('UpdateFavoritePricingInformation:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getFavoriteFilesAction = createAsyncThunk<
    ActionResponse<UploadsTableFields> | undefined,
    GetFavoriteUploadsRequest
>('favoriteProducts/getFavoriteFiles', async (data: GetFavoriteUploadsRequest) => {
    const result = await http<ActionResponse<UploadsTableFields>, GetFavoriteUploadsRequest>(
        {
            path: `${baseUrl}/get-uploads`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('GetFavoriteFiles:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getFavoriteUploadedImagesCountAction = createAsyncThunk<
    ActionResponse<number> | undefined,
    GetUploadedFavoriteImagesCountRequest
>('favoriteProducts/getFavoriteUploadedImagesCount', async (data: GetUploadedFavoriteImagesCountRequest) => {
    const result = await http<ActionResponse<number>, GetUploadedFavoriteImagesCountRequest>(
        {
            path: `${baseUrl}/upload-image-count`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('GetFavoriteUploadedImagesCount:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const uploadFavoriteFileAction = createAsyncThunk<
    any | undefined,
    UploadFavoriteFileRequest
>('favoriteProducts/uploadFavoriteFile', async (uploadFileData: UploadFavoriteFileRequest) => {
    const data = createMultipartData(uploadFileData);
    const result = await httpMultipart({
        path: `${baseUrl}/upload-file`,
        method: 'post',
        body: data,
    });
    console.log('UploadFileToFavorite:');
    console.log(result.data);
    return result.data;
});

export const updateFavoriteFileAction = createAsyncThunk<
    ActionResponse<FavoriteProduct> | undefined,
    UpdateFavoriteFileAction
>('favoriteProducts/updateFavoriteFile', async (data: UpdateFavoriteFileAction) => {
    const result = await http<ActionResponse<FavoriteProduct>, UpdateFavoriteFileAction>(
        {
            path: `${baseUrl}/upload-file`,
            method: 'put',
            body: data,
        },
        true,
        false,
    );
    console.log('UpdateFavoriteFiles:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const deleteFavoriteFileAction = createAsyncThunk<
    CommonResponse | undefined,
    DeleteUploadDocumentFavoriteRequest
>('favoriteProducts/deleteFavoriteFile', async (data: DeleteUploadDocumentFavoriteRequest) => {
    const result = await http<CommonResponse, DeleteUploadDocumentFavoriteRequest>(
        {
            path: `${baseUrl}/delete-upload-file`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('DeleteUploadDocumentFavorite:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const deleteAllFavoriteCommentsAction = createAsyncThunk<
    CommonResponse | undefined,
    ChangeFavorite
>('favoriteProducts/deleteAllFavoriteComments', async (data: ChangeFavorite) => {
    const result = await http<CommonResponse, ChangeFavorite>(
        {
            path: `${baseUrl}/delete-all-comments`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('DeleteAllFavoriteComments:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const removeFavoriteInBulkAction = createAsyncThunk<
    CommonResponse | undefined,
    removeFavoriteInBulkRequest
>('favoriteProducts/removeFavoriteInBulk', async (data: removeFavoriteInBulkRequest) => {
    const result = await http<CommonResponse, removeFavoriteInBulkRequest>(
        {
            path: `${baseUrl}/remove-favorite-bulk`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('RemoveFavoriteInBulk:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getFavoriteCommentsAction = createAsyncThunk<
    ActionResponse<Array<Comment>> | undefined,
    ChangeFavorite
>('products/getFavoriteComments', async (data: ChangeFavorite) => {
    const result = await http<ActionResponse<Array<Comment>>, ChangeFavorite>(
        {
            path: `${baseUrl}/get-comments`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('GetFavoriteCommennts:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const addProductsFromFavoriteAction = createAsyncThunk<
    ActionResponse<AddToFavoriteResponse> | undefined,
    AddFromFavoriteRequest
>('products/getFavoriteComments', async (data: AddFromFavoriteRequest) => {
    const result = await http<ActionResponse<AddToFavoriteResponse>, AddFromFavoriteRequest>(
        {
            path: `${baseUrl}/transfer-favorites`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('GetFavoriteComments:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const addFavoriteInBulkAction = createAsyncThunk<
    ActionResponse<AddFavoriteInBulkResponse> | undefined,
    AddFavoriteInBulkRequest
>('products/addFavoriteInBulk', async (data: AddFavoriteInBulkRequest) => {
    const result = await http<ActionResponse<AddFavoriteInBulkResponse>, AddFavoriteInBulkRequest>(
        {
            path: `${baseUrl}/add-favorite-bulk`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('AddFavoriteInBulk:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
