import { Divider, Spin } from 'antd';
import styled from 'styled-components';
import { blueLink } from '../../styles/colors';
import { InvisibleButton } from '../../styles/common.styles';

export const PlanContainer = styled('div')`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
`;

export const PlanCard = styled('div')`
    width: 20rem;
`;

export const PlanCardHeader = styled('div')`
    background-color: #8ebac5;
    padding: 20px;
    align-items: left;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    font-family: 'DINNextLTPro';
`;

export const PlanContent = styled('div')`
    border: 1px solid #c7c7c6;
    font-family: 'DINNextLTPro';
    padding: 20px;
    position: relative;
`;

export const PlanPrice = styled('div')`
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    color: #0099ff;
`;

export const PlanDivider = styled(Divider)`
    border-top: 1px solid #e6e6e6;
`;

export const PlanSavePrice = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 15px;
    line-height: 20px;
    color: #0099ff;
`;

export const PlanDescription = styled('div')`
    margin: 20px 0;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 13.5px;
    line-height: 19px;
    color: #4d4d4d;
`;

export const BuyButton = styled(InvisibleButton)`
    color: ${blueLink};
    height: 15px;
`;

export const SubscriptionPlanSpin = styled(Spin)`
    top: 0 !important;
    span {
        left: 10% !important;
    }
`;
