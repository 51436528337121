import React, { useEffect, useState } from 'react';
import { scrollToTarget } from '../../../helpers/DOM';
import { useModalState } from '../../../hooks/modalState';
import { usePermissions } from '../../../hooks/permissions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { ActionResponse, CommonResponse } from '../../../models/actionResponse/types';
import { Comment } from '../../../models/comment/types';
import { AccountPermissionTypes } from '../../../models/permission/enums';
import { FavoriteType } from '../../../models/product/enums';
import { Product } from '../../../models/product/types';
import { MFRProject } from '../../../models/project/types';
import {
    addFavoriteCommentAction,
    deleteAllFavoriteCommentsAction,
} from '../../../redux/actions/favoriteProductsActions';
import { addCommentAction, deleteAllCommentsAction } from '../../../redux/actions/productActions';
import { alertService } from '../../../services/alert.service';
import { blueDark01, blueLink } from '../../../styles/colors';
import { Text } from '../../../styles/common.styles';
import { InvisibleButton } from '../../../styles/common.styles';
import DeleteModal from '../../common/deleteModal/DeleteModal';
import Switcher from '../../common/switcher/Switcher';
import { MergedFavoriteProduct } from '../../favoritProducts/FavoriteProducts.types';
import {
    AddComment,
    CommentInput,
    CommentsContainer,
    CommentsSwitcherTitle,
    DeleteComments,
    ProductCommentsContainer,
    ProductCommentsSwitcherContainer,
    SaveCommentButton,
    ViewComments,
} from './ProductComments.styles';
import ProductCommentsSection from './ProductCommentSection';

interface State {
    clicked: boolean;
    comment: string;
    newComment: null | Comment;
    deleteAllComments: number;
    commentsCount: number;
}
interface Props {
    product: MergedFavoriteProduct | Product;
    selectedProductId?: string;
    favoriteType?: FavoriteType;
    projectData?: MFRProject;
    commentId?: string;
    openByDefault?: boolean;
}
const ProductComments: React.FC<Props> = ({
    favoriteType,
    product,
    projectData,
    commentId,
    openByDefault,
    selectedProductId,
}) => {
    if (commentId == '-1') {
        commentId = undefined;
    }
    const dispatch = useAppDispatch();
    const { getAccountRole } = usePermissions();
    const user = useAppSelector((state) => state.authReducer.user);

    const { onOpen, onClose, isOpen } = useModalState();

    const [state, setState] = useState<State>({
        clicked: false,
        comment: '',
        newComment: null,
        deleteAllComments: 0,
        commentsCount: product.product.commentsCount,
    });

    const handleClicked = () => {
        setState({ ...state, clicked: !state.clicked });
    };

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setState({ ...state, comment: event.target.value });
    };

    useEffect(() => {
        if (
            selectedProductId?.toString() === product.product.productId.toString() &&
            commentId &&
            +commentId
        ) {
            setState({ ...state, clicked: true });
        }
    }, []);

    useEffect(() => {
        if (commentId === "share") {
            setState({ ...state, clicked: true });
        }
    }, [commentId]);

    useEffect(() => {
        if (openByDefault) {
            setState({ ...state, clicked: true });
        }
    }, [openByDefault]);

    const submitComment = async () => {
        let result;
        if (!state.comment.trim()) {
            return;
        }

        const comment = `${state.comment}`;
        setState({
            ...state,
            comment: '',
        });

        if (favoriteType !== undefined) {
            const { payload } = (await dispatch(
                addFavoriteCommentAction({
                    comment: comment,
                    favoriteType: favoriteType,
                    productFavoriteId: product.id,
                }),
            )) as ActionResponse<Comment>;
            result = payload;
        } else {
            if (projectData?.id) {
                const { payload } = (await dispatch(
                    addCommentAction({
                        comment: comment,
                        projectProductId: (product as Product).id,
                        productId: (product as Product).productId,
                        projectId: projectData.id,
                    }),
                )) as ActionResponse<Comment>;
                result = payload;
            }
        }

        if (result) {
            fixComment(result.data);

            setState({
                ...state,
                comment: '',
                newComment: result.data,
                commentsCount: state.commentsCount + 1,
            });
            
            alertService.success('New comment was added', { fixed: true });
            const scrollableDiv = document.getElementById('comments-list');
            const targetElement = document.getElementById(result.data.id.toString());
            scrollToTarget(targetElement, scrollableDiv);
        }
    };

    const fixComment = (comment: Comment) => {
        if (comment.isAccountAdmin === null) {
            comment.isAccountAdmin = user?.accountRole === AccountPermissionTypes.ACCOUNT_ADMIN;
        }

        if (comment.isAccountMember === null) {
            comment.isAccountMember = user?.accountRole === AccountPermissionTypes.ACCOUNT_USER;
        }

        if (comment.isAccountAssociate === null) {
            comment.isAccountAssociate = user?.accountRole === AccountPermissionTypes.ACCOUNT_ASSOCIATE;
        }
    }

    const deleteAllComments = async () => {
        let result;
        if (favoriteType !== undefined) {
            const { payload } = (await dispatch(
                deleteAllFavoriteCommentsAction({
                    favoriteType: favoriteType,
                    productFavoriteId: product.id,
                }),
            )) as CommonResponse;
            result = payload;
        } else {
            if (projectData?.id) {
                const { payload } = (await dispatch(
                    deleteAllCommentsAction({
                        productId: (product as Product).productId,
                        projectId: projectData.id,
                    }),
                )) as CommonResponse;
                result = payload;
            }
        }
        if (result?.message) {
            setState({
                ...state,
                deleteAllComments: state.deleteAllComments + 1,
                commentsCount: 0,
            });
            onClose();
        }
    };

    const changeCommentsCount = () => {
        setState({ ...state, commentsCount: state.commentsCount - 1 });
    };

    return (
        <>
            <ProductCommentsContainer>
                <ProductCommentsSwitcherContainer>
                    <Switcher
                        id={''}
                        clicked={state.clicked}
                        handleSwitch={handleClicked}
                        width={20}
                        height={20}
                    />
                    <CommentsSwitcherTitle weight={400} height={18} size={14} color={blueDark01}>
                        Comments ({state.commentsCount})
                    </CommentsSwitcherTitle>
                </ProductCommentsSwitcherContainer>
            </ProductCommentsContainer>
            <CommentsContainer className={state.clicked ? 'clicked' : ''}>
                <AddComment>
                    <CommentInput
                        value={state.comment}
                        onChange={handleInput}
                        placeholder="Add comment"
                    />
                    <SaveCommentButton onClick={submitComment}>Save</SaveCommentButton>
                </AddComment>
                <ViewComments>
                    <DeleteComments>
                        {favoriteType === FavoriteType.MyFavorite ||
                        getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN ? (
                            <InvisibleButton onClick={onOpen}>
                                <Text weight={400} size={12} height={14} color={blueLink}>
                                    Delete all comments
                                </Text>
                            </InvisibleButton>
                        ) : (
                            <InvisibleButton>
                                <Text weight={400} size={12} height={14} color={blueLink}></Text>
                            </InvisibleButton>
                        )}
                    </DeleteComments>
                    {state.clicked && (
                        <ProductCommentsSection
                            product={product}
                            favoriteType={favoriteType}
                            newComment={state.newComment}
                            deleteAllComments={state.deleteAllComments}
                            commentId={commentId}
                            changeCommentsCount={changeCommentsCount}
                            projectData={projectData}
                        />
                    )}
                </ViewComments>
            </CommentsContainer>
            <DeleteModal
                isOpen={isOpen}
                closeModal={onClose}
                reload={deleteAllComments}
                title="comments"
                text={'all comments'}
                icon=""
            />
        </>
    );
};

export default ProductComments;
