import React, { useState } from 'react';
import { Dropdown, Space } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import {
    AddProductButton,
    AddProductButtonContainer,
    AddProductMenu,
    ProductsActionImage,
} from './ProductsAction.styles';
import { useModalState } from '../../../hooks/modalState';
import { useAppSelector } from '../../../hooks/redux';
import { useHistory } from 'react-router-dom';
import { AddProductAction } from '../../../models/product/enums';
import AddFromAnotherSite from './AddFromAnotherSiteModal/AddFromAnotherSiteModal';
import ProductsModal from '../../productsModal/ProductsModal';
import { ProjectPermissionTypes } from '../../../models/permission/enums';
import { usePermissions } from '../../../hooks/permissions';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { tryRefreshSession } from '../../../helpers/request';
import { getAccessToken, getRefreshToken, setCookies } from '../../../redux/persistedState';

interface Props {
    showActions?: boolean;
    projectId?: number;
    projectRole?: ProjectPermissionTypes;
}

const ProductsAction: React.FC<Props> = ({ showActions, projectId, projectRole }) => {
    const selector = useAppSelector((state) => state);
    const history = useHistory();
    const productsModal = useModalState();
    const menuModal = useModalState();
    const { authReducer } = selector;
    const { getProjectPermission, getAccountSubscription } = usePermissions();
    const [openModals, setOpenModals] = useState({
        addFromAnotherSite: false,
    });

    const getSearchNotInTcUrlParams = () => {
        const userId = authReducer.user?.id;
        const organizationId = authReducer.user?.organization?.id;
        return `project=${projectId}&userId=${userId}&organizationId=${organizationId}&return=${window.location.href}`;
    }

    const handleMenuSelect = async (data: { key: string }) => {
        switch (data.key) {
            case AddProductAction.Search:
                await tryRefreshSession();
                setCookies(getAccessToken(), getRefreshToken());
                window.location.href = `${process.env.REACT_APP_TC_URL}/search?project=${projectId}`;
                break;
            case AddProductAction.YourFavorites:
                history.push(`/my-favorites/${projectId}`);
                break;
            case AddProductAction.OfficeFavorite:
                history.push(`/favorites/${projectId}`);
                break;
            case AddProductAction.ProjectLibraryImport:
                history.push(`/project-library/0/${projectId}`);
                break;
            case AddProductAction.SearchNotInTc:
                window.location.href = `${process.env.REACT_APP_TC_URL}/project-builder/add-product?${getSearchNotInTcUrlParams()}`;
                break;
            default:
                break;
        }
    };

    const handleCloseModal = (data: { key: string }) => {
        setOpenModals({ ...openModals, [data.key]: !openModals[data.key] });
    };

    const actionMenu = (organizationName: string): Array<ItemType> => {
        const menu: Array<ItemType> = [
            {
                key: AddProductAction.Search,
                label: 'Add from Transparency Catalog',
            },
            {
                key: AddProductAction.YourFavorites,
                label: 'Add from my Product Library',
            },
            { type: 'divider' },
            {
                key: AddProductAction.ProjectLibraryImport,
                label: 'Import a Project',
            },
            { type: 'divider' },
            {
                key: AddProductAction.SearchNotInTc,
                label: 'Add product not in Transparency Catalog',
            },
        ];
        if (getAccountSubscription() === SubscriptionTypes.OFFICE) {
            menu.splice(1, 0, {
                key: AddProductAction.OfficeFavorite,
                label: `Add from ${organizationName} Product Library`,
            });
        }
        return menu;
    };

    return (
        <Space>
            {showActions && (
                <ProductsActionImage src="src/assets/images/file-export.svg" preview={false} />
            )}
            {showActions && (
                <ProductsActionImage src="src/assets/images/copy-white.svg" preview={false} />
            )}
            {getProjectPermission(
                [ProjectPermissionTypes.PROJECT_ADMIN, ProjectPermissionTypes.OWNER, ProjectPermissionTypes.EDITOR],
                projectRole,
            ) && (
                <Dropdown
                    menu={{
                        items: actionMenu(authReducer.user?.organization.name || ''),
                        onClick: handleMenuSelect,
                        style: { top: -36 },
                    }}
                    placement="bottomLeft"
                    trigger={['click']}
                    onOpenChange={menuModal.onToggle}
                >
                    <AddProductButtonContainer>
                        <AddProductButton id="add-products-button">Add products</AddProductButton>
                        <AddProductMenu id="add-products-menu">
                            {menuModal.isOpen ? (
                                <CaretUpOutlined style={{ color: 'white' }} />
                            ) : (
                                <CaretDownOutlined style={{ color: 'white' }} />
                            )}
                        </AddProductMenu>
                    </AddProductButtonContainer>
                </Dropdown>
            )}
            {openModals.addFromAnotherSite && (
                <AddFromAnotherSite
                    isOpen={openModals.addFromAnotherSite}
                    closeModal={handleCloseModal}
                />
            )}
            {productsModal.isOpen && (
                <ProductsModal
                    projectId={projectId}
                    isOpen={productsModal.isOpen}
                    closeModal={productsModal.onClose}
                    setProductsPackageId={() => {}}
                ></ProductsModal>
            )}
        </Space>
    );
};

export default ProductsAction;
