import React from 'react';
import { Image } from 'antd';
import { usePermissions } from '../../../hooks/permissions';
import { AccountPermissionTypes } from '../../../models/permission/enums';
import { OfficeFavoriteTipContainer, FavoriteButton} from './ActiveOfficeFavoriteIcon.styles';
import { blueLink, grayDark03 } from '../../../styles/colors';
import { Text } from '../../../styles/common.styles';

interface Props {
    handleOfficeFavorite: () => Promise<void>;
}

const ActiveOfficeFavoriteIcon: React.FC<Props> = ({ handleOfficeFavorite }) => {
    const { getAccountRole } = usePermissions();
    return (
        <>
            {getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN ? (
                <>
                    <Image
                        src="src/assets/images/star.svg"
                        preview={false}
                        width={16}
                        style={{ cursor: 'pointer' }}
                        onClick={handleOfficeFavorite}
                    />
                    <FavoriteButton onClick={handleOfficeFavorite} className="favoriteButtonText">
                      <Text weight={400} size={12} height={14} color={blueLink}>
                          Office Library
                      </Text>
                    </FavoriteButton>
                </>
            ) : (
                <>
                    <OfficeFavoriteTipContainer>
                        <Image src="src/assets/images/star.svg" preview={false} width={16} />
                        <span className="tip">
                            Only admins can remove this product from your office's Favorite Products
                            Library.
                        </span>
                    </OfficeFavoriteTipContainer>
                      <Text weight={400} size={12} height={14} color={grayDark03}>
                          Office Library
                      </Text>

                </>
            )}
        </>
    );
};

export default ActiveOfficeFavoriteIcon;
