import { Form, Input, Image, Checkbox, UploadProps, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import CustomInput from '../../../../components/common/inputs/CustomInput';
import CustomSelect from '../../../../components/common/select/CustomSelect';
import WhoCanSeeThisProject from '../../../../components/tips/WhoCanSeeThisProject';
import {
    ConfidentialContainer,
    ConfidentialText,
} from '../../../../components/projects/startProject/StartProject.styles';
import {
    GetProjectInformationResponse,
    UpdateProjectData,
} from '../../../../redux/actions/projectActions/types';
import { blueDark01, blueLink, grayDark03 } from '../../../../styles/colors';
import {
    BoldText,
    InvisibleContainer,
    PageContainer,
    SpaceBetween,
    Text,
} from '../../../../styles/common.styles';
import {
    BaseInformationContainer,
    BaseInformationForm,
    ProjectInfoPageTitle,
    ProjectInformationDragger,
    ProjectInformationImageDragger,
    ProjectInformationPageDivider,
    ProjectInformationSpace,
    DraggerText,
    SaveProjectInfo,
    SaveProjectInfoTop,
    GrossBuildingInput,
    DocumentNameInput,
    PreviewImage,
    HiddenInputWrapper,
    CustomButton,
    ProjectLibraryVisibilityInput,
    LinkToManufacturerDashboard,
//    ALinkToManufacturerDashboard,
    ConfidentialTipContainer,
    DraggerTextAction,
    ProjectFileInputFlexRow,
    EC3ExportButton,
    ChooseFileText,
    EC3LinkContainer,
    ConstructionTypeInput,
    RightLabelOwnersContainer,
    ProjectBuildingTypeInputContainer,
    TemplateContainer,
    ProjectVisibilityTextContainer,
} from './ProjectInformation.styles';
import {
    getAvailableProjectsAction,
    getProjectReferencesAction,
    updateProjectInformationAction,
} from '../../../../redux/actions/projectActions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { alertService } from '../../../../services';
import { ProjectPermissionTypes } from '../../../../models/permission/enums';
import { AccountPermissionTypes } from '../../../../models/permission/enums';
import { AlertMessages } from '../../../../components/alert/AlertMessages';
import MapContainerWrapper from '../../../../components/mapContainer/MapContainerWrapper';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { AvailableProject, ProjectReferences } from '../../../../models/project/types';
import { useModalState } from '../../../../hooks/modalState';
import DeleteModal from '../../../../components/common/deleteModal/DeleteModal';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { EC3ExportStatus } from '../../../../models/ec3/enums';
import EC3Login from '../../../../components/projects/projectActions/ec3Login/EC3Login';
import { ExportText } from '../ec3ExportTab/exportHeader/ExportHeader.styles';
import EC3SuccessExport from '../../../../components/projects/projectActions/ec3Success/EC3SuccessExport';
import { usePermissions } from '../../../../hooks/permissions';
import { SubscriptionTypes } from '../../../../models/subscription/enums';
import { InternalProduct } from '../../../../models/product/types';
import { getProjectProductsAction } from '../../../../redux/actions/productActions';

interface Props {
    projectInfo: GetProjectInformationResponse | null;
    reload: () => Promise<void>;
    setPersistActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ProjectInformation: React.FC<Props> = ({ projectInfo, reload, setPersistActiveTab }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { projectReducer, productReducer, authReducer, ec3Reducer } = selector;
    const [form] = Form.useForm();

    const [references, setReferences] = useState<ProjectReferences | null>(null);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [image, setImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState('');
    const [state, setState] = useState({
        isConfidential: false,
    });
    const [productIds, setProductIds] = useState<Array<InternalProduct>>([]);
    const [isInputDisplayed, setIsInputDisplayed] = useState(false);
    const [parentTemplateName, setParentTemplateName] = useState(<></>);
    const { onOpen, onClose, isOpen } = useModalState();
    const ec3ModalState = useModalState();
    const ec3SuccessModal = useModalState();

    const { getAccountSubscription } = usePermissions();
    const { getAccountRole, getProjectPermission } = usePermissions();

    useEffect(() => {
        if (projectInfo) {
            getProductIds();
            getParentTemplateName();
            projectInfo.ec3Url = projectInfo.ec3ProjectStatus?.eC3Project?.link;
            getReferences();
            form.setFieldsValue(projectInfo);
            setState({ ...state, isConfidential: projectInfo.isConfidential || false });
            if (projectInfo.imageFilePath) {
                setImageUrl(`${process.env.REACT_APP_API_URL}/static/${projectInfo.imageFilePath}`);
            }
            if (projectInfo.budgetFilePath && projectInfo.documentName) {
                setFileName(projectInfo.documentName);
            }
        }
    }, [projectInfo]);

    const getAvailableProjects = async () => {
        const { payload } = (await dispatch(
            getAvailableProjectsAction()
        )) as ActionResponse<Array<AvailableProject>>;

        return payload?.data;
    }

    const parentTemplateExists = async () => {
        const availableProjects = await getAvailableProjects();
        if (!availableProjects?.length || !projectInfo) {
            return false;
        }

        return availableProjects.some((availableProject: AvailableProject) => availableProject.id === projectInfo.templateId);
    }

    const getParentTemplateName = async () => {
        if (!projectInfo) {
            return;
        }

        if (await parentTemplateExists()) {
            setParentTemplateName(
                <a style={{ color: '#134fbd' }} href={'project-info/' + projectInfo.templateId || ''} target="_blank">
                    {projectInfo.templateName}
                </a>
            );
        } else {
            setParentTemplateName(<>{projectInfo.templateName}</>);
        }
    }

    const getProductIds = async () => {
        if (!projectInfo) {
            return;
        }

        const { payload } = (await dispatch(
            getProjectProductsAction(projectInfo.id),
        )) as ActionResponse<Array<InternalProduct>>;

        if (payload?.data) {
            setProductIds(payload.data);
        }
    };

    const onEC3ExportButtonClicked = () => {
        if (!productIds?.length) {
            console.log('There are no products to export');
            alertService.warn('There are no products to export');
            return;
        }

        ec3ModalState.onToggle();
    }

    const getReferences = async () => {
        const { payload } = (await dispatch(
            getProjectReferencesAction(),
        )) as ActionResponse<ProjectReferences>;
        setReferences(payload.data);

        if (projectInfo?.buildingTypeId) {
            const currentBuildingType = payload.data?.allBuildingTypes.find(
                (x) => x?.id?.toString() === projectInfo?.buildingTypeId?.toString(),
            );

            if (currentBuildingType?.name === 'Other') {
                setIsInputDisplayed(true);
            }
        }
    };

    const budgetFile = () => {
        const props: UploadProps = {
            name: 'file',
            multiple: false,
            beforeUpload: () => false,
            onChange (info: any) {
                if (!info?.file) {
                    alertService.error('Something went wrong');
                    return;
                }
                if (info.file.size < 1) {
                    alertService.error('0 bytes size file cannot be attached', { fixed: true });
                    return;
                }
                if (info.file.size > 5242880) {
                    window.scroll(0, 0);
                    alertService.error('5 mb max size for budget file');
                    return;
                }

                setFileName(info.file.name);
                form.setFieldValue('documentName', info.file.name);
                setFile(info.file);
            },
        };
        return (
            <>
                {' '}
                <ProjectInformationDragger
                    multiple={false}
                    showUploadList={false}
                    fileList={undefined}
                    disabled={getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE}
                    {...props}
                >
                    <Image src="src/assets/images/file-lines.svg" preview={false} />
                    {!fileName ? (
                        <>
                            <ChooseFileText weight={400} size={14} height={18} color={blueLink}>
                                Choose a file
                            </ChooseFileText>
                            <DraggerText
                                weight={400}
                                size={14}
                                height={18}
                                color={blueDark01}
                                className="up"
                            >
                                or drag it here
                            </DraggerText>
                        </>
                    ) : (
                        <DraggerText weight={400} size={14} height={18} color={blueLink}>
                            {fileName}
                        </DraggerText>
                    )}
                </ProjectInformationDragger>
                {fileName && (
                    <DraggerTextAction
                        multiple={false}
                        showUploadList={false}
                        fileList={undefined}
                        {...props}
                    >
                        <ProjectFileInputFlexRow>
                            <BoldText weight={400} size={14} height={18} color={blueLink}>
                                Choose a file
                            </BoldText>
                            <Text weight={400} size={14} height={18} color={blueDark01}>
                                or drag it here
                            </Text>
                        </ProjectFileInputFlexRow>
                    </DraggerTextAction>
                )}
                <DraggerText weight={400} size={12} height={14} color={grayDark03}>
                    File types (5Mb maximum):
                </DraggerText>
            </>
        );
    };

    const imageFile = () => {
        const props: UploadProps = {
            name: 'file',
            multiple: false,
            beforeUpload: () => false,
            onChange (info: any) {
                if (!info?.file) {
                    alertService.error('Something went wrong');
                    return;
                }
                if (info.file.size < 1) {
                    alertService.error('0 bytes size file cannot be attached', { fixed: true });
                    return;
                }
                if (info.file.size > 5242880) {
                    window.scroll(0, 0);
                    alertService.error('5 mb max size for budget file');
                    return;
                }
                setImageUrl(URL.createObjectURL(info.file));
                setImage(info.file);
            },
        };
        return (
            <>
                <ProjectInformationImageDragger
                    multiple={false}
                    showUploadList={false}
                    fileList={undefined}
                    accept=".png,.jpeg,.gif"
                    {...props}
                    className={imageUrl ? 'preview' : ''}
                    disabled={getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE}
                >
                    <>
                        {!imageUrl ? (
                            <>
                                <Image src="src/assets/images/blue-image.svg" preview={false} />
                                <ChooseFileText weight={400} size={14} height={18} color={blueLink}>
                                    Choose a file
                                </ChooseFileText>
                                <DraggerText
                                    weight={400}
                                    size={14}
                                    height={18}
                                    color={blueDark01}
                                    className="up"
                                >
                                    or drag it here
                                </DraggerText>
                            </>
                        ) : (
                            <PreviewImage src={imageUrl} preview={false} className="previewImage" />
                        )}
                    </>
                </ProjectInformationImageDragger>
                {imageUrl && (
                    <DraggerTextAction
                        multiple={false}
                        showUploadList={false}
                        fileList={undefined}
                        accept=".png,.jpeg,.gif"
                        {...props}
                    >
                        <ProjectFileInputFlexRow>
                            <BoldText weight={400} size={14} height={18} color={blueLink}>
                                Choose a file
                            </BoldText>
                            <Text weight={400} size={14} height={18} color={blueDark01}>
                                or drag it here
                            </Text>
                        </ProjectFileInputFlexRow>
                    </DraggerTextAction>
                )}
                <DraggerText weight={400} size={12} height={14} color={grayDark03}>
                    File types (5Mb maximum): JPEG, GIF, or PNG
                </DraggerText>
            </>
        );
    };

    const handleCheckbox = () => {
        setState({ ...state, isConfidential: !state.isConfidential });
    };
    const confidentialityCheckbox = ({ disabled }: { disabled: boolean }) => {
        return (
            <ConfidentialContainer>
                <Checkbox
                    name="isConfidential"
                    onChange={handleCheckbox}
                    checked={state.isConfidential}
                    disabled={disabled}
                />
                <ConfidentialText>
                    This is a confidential client project.{' '}
                    <ConfidentialTipContainer>
                        <Image
                            width={13}
                            preview={false}
                            src="src/assets/images/circle-question.svg"
                        />
                        <div className="tip">
                            A <strong>Confidential</strong> Project excludes Project name<br/> and location from manufacturer analytics.
                        </div>
                    </ConfidentialTipContainer>
                </ConfidentialText>
            </ConfidentialContainer>
        );
    };
    const onSubmit = async (data: UpdateProjectData) => {
        const resultData = Object.entries(data).reduce((agg, item) => {
            if (item[1] === null) {
                agg[item[0]] = undefined;
            } else {
                agg[item[0]] = item[1];
            }
            return agg;
        }, {} as UpdateProjectData);
        const updatedProject = {
            ...resultData,
            isConfidential: state.isConfidential,
            locationX: data?.locationX,
            locationY: data?.locationY,
            budgetFile: data.documentName ? file : null,
            imageFile: image,
            isImageDeleted: imageUrl ? false : true,
            budgetFilePath: data.documentName ? projectInfo?.budgetFilePath : undefined,
        };
        if (projectInfo?.id) {
            const { payload } = await dispatch(
                updateProjectInformationAction({
                    data: updatedProject,
                    projectId: projectInfo.id,
                }) as any,
            );
            if (payload) {
                window.scroll(0, 0);
                reload();
                setFile(null);
                setImage(null);
                alertService.success(AlertMessages.PROJECT_UPDATED);
            }
        } else {
            alertService.error('Something went wrong, please try later');
        }
    };

    const changeDocumentName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (fileName) {
            setFileName(event.target.value);
        }
    };

    const handleBuildingTypeSelect = (value: string) => {
        const otherVariant = references?.allBuildingTypes.find((x) => x.name === 'Other');

        if (value === otherVariant?.id) {
            setIsInputDisplayed(true);
        } else {
            setIsInputDisplayed(false);
        }
    };

    const deleteImage = async () => {
        setImageUrl('');
        onClose();
    };

    const onFailed = (errorsInfo: ValidateErrorEntity<UpdateProjectData>) => {
        if (errorsInfo?.errorFields.length && errorsInfo.errorFields[0].name) {
            document
                .getElementById(`basic_${errorsInfo.errorFields[0].name[0].toString()}`)
                ?.scrollIntoView();
        }
    };

    const disableSave = () => {
        if (projectInfo?.isFromManufacturer) {
            const isOwner = projectInfo.createdById === authReducer.user?.id;
            return isOwner ? false : true;
        } else {
            return projectInfo?.userRole === ProjectPermissionTypes.COMMENTER;
        }
    };

    const startEC3Export = () => {
        ec3ModalState.onClose();
        ec3SuccessModal.onToggle();
    };

    const ownersContainer = () => {

        if (!projectInfo || !projectInfo.creatorOwner) {
            return (<div></div>);
        }

        return (
            <dl>
                {projectInfo.creatorOwner.map(function (n, index) {
                    return (
                        <React.Fragment key={index}>
                            <a style={{ color: '#134fbd' }} href={`mailto:` + n.email}>{n.firstName} {n.lastName}</a>{index < projectInfo.creatorOwner.length - 1 ? ', ' : ''}
                        </React.Fragment>
                    )
                })}
            </dl>
        );
    };

    const templateContainer = () => {
        if (!projectInfo || !projectInfo.templateId) {
            return (<div style={{height: 0+'px'}}>123</div>);
        }

        return (
           <span>Created from {parentTemplateName}</span>
        );
    };

    return (
        <PageContainer>
            <Spin
                size="large"
                spinning={projectReducer.pending || productReducer.pending || ec3Reducer.pending}
            >
                <BaseInformationForm
                    name="basic"
                    autoComplete="off"
                    labelCol={{ span: 20 }}
                    onFinish={onSubmit}
                    onFinishFailed={onFailed}
                    form={form}
                >

                    <SpaceBetween>
                        <div>
                        </div>
                        <SaveProjectInfoTop disabled={disableSave()} htmlType="submit">
                            Save
                        </SaveProjectInfoTop>
                    </SpaceBetween>

                    <ProjectInformationSpace>
                        <div>
                            <ProjectInfoPageTitle>Basic information</ProjectInfoPageTitle>
                            <BaseInformationContainer>
                                <CustomInput
                                    Component={Input}
                                    label="Project name"
                                    name="name"
                                    required
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            max: 200,
                                            message: 'Value should be less than 200 characters',
                                        },
                                    ]}
                                    props={{
                                        disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                    }}
                                />

                                {!projectInfo || !projectInfo.templateId ? (
                                    <></>
                                ) : (
                                    <>
                                        <div id="template-container">
                                            <TemplateContainer
                                                Component={templateContainer}
                                                label=""
                                            />
                                        </div>
                                    </>
                                )}

                                <div id="right-label-container">
                                    <RightLabelOwnersContainer
                                        Component={ownersContainer}
                                        label="Project creator/ owners"
                                    />
                                </div>
                                <CustomSelect
                                    label="Project status*"
                                    name="projectStatusId"
                                    options={references?.allProjectStatuses || []}
                                    placeholder="Select project status"
                                    rules={[{ required: true, message: '' }]}
                                    props={{
                                        disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                    }}
                                />
                                <CustomInput
                                    Component={Input}
                                    label="Client"
                                    name="clientOwner"
                                    rules={[
                                        {
                                            max: 100,
                                            message: 'Value should be less than 100 characters',
                                        },
                                    ]}
                                    props={{
                                        disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                    }}
                                />
                                {projectInfo?.isTemplate ? (
                                    <></>
                                ) : (
                                    <>
                                        <div id="right-label-container">
                                            <ProjectVisibilityTextContainer>
                                                <ProjectLibraryVisibilityInput
                                                    label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Who can see this Project*"
                                                    name="projectLibraryVisibilityId"
                                                    options={references?.allProjectVisibilities || []}
                                                    placeholder="Select project status"
                                                    rules={[{ required: true, message: '' }]}
                                                    props={{ disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE }}
                                                />
                                                <br/><WhoCanSeeThisProject />
                                             </ProjectVisibilityTextContainer>
                                        </div>

                                        <CustomInput
                                            label="Confidentiality"
                                            name="isConfidential"
                                            Component={confidentialityCheckbox}
                                            props={{
                                                disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                            }}
                                        />
                                    </>
                                )}
                                <CustomInput
                                    Component={Input}
                                    label="Project ID"
                                    name="externalProjectId"
                                    rules={[
                                        {
                                            max: 250,
                                            message: 'Value should be less than 250 characters',
                                        },
                                    ]}
                                    props={{
                                        disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                    }}
                                />
                                <ProjectInformationPageDivider />
                                <ProjectBuildingTypeInputContainer>
                                    <CustomSelect
                                        label="Project / building type"
                                        name="buildingTypeId"
                                        options={references?.allBuildingTypes || []}
                                        placeholder="Select"
                                        required
                                        rules={[{ required: true, message: '' }]}
                                        props={{
                                            onChange: handleBuildingTypeSelect,
                                            disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                        }}
                                    />
                                </ProjectBuildingTypeInputContainer>
                                <HiddenInputWrapper displayed={isInputDisplayed}>
                                    <CustomInput
                                        rules={[{ required: isInputDisplayed, message: '' }]}
                                        Component={Input}
                                        label={''}
                                        name="otherBuildingType"
                                        props={{
                                            disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                        }}
                                    />
                                </HiddenInputWrapper>
                                <GrossBuildingInput>
                                    <CustomSelect
                                        label="Gross building area"
                                        name="grossBuildingAreaId"
                                        options={references?.allGrossBuildingAreas || []}
                                        placeholder="Select"
                                        props={{
                                            disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                        }}
                                    />
                                </GrossBuildingInput>
                                <GrossBuildingInput>
                                    {((projectInfo?.ec3ProjectStatus?.export?.status ===
                                        EC3ExportStatus.Done ||
                                        projectInfo?.id === ec3Reducer.ec3Project?.projectId) && (
                                            <CustomInput
                                                Component={Text}
                                                label="EC3 tool project URL"
                                                name="ec3Url"
                                                props={{
                                                    children: (
                                                        <EC3LinkContainer>
                                                            <Image
                                                                src="src/assets/images/arrow-up-right-from-square.svg"
                                                                preview={false}
                                                            />
                                                            <a
                                                                href={projectInfo?.ec3Url || ''}
                                                                target="_blank"
                                                            >
                                                                <Text
                                                                    weight={400}
                                                                    size={14}
                                                                    height={18}
                                                                    color={blueLink}
                                                                >
                                                                    View project in EC3 tool
                                                                </Text>
                                                            </a>
                                                        </EC3LinkContainer>
                                                    ),
                                                    disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                                }}
                                            />
                                        )) || (
                                            <CustomInput
                                                Component={Text}
                                                label="EC3 tool project URL"
                                                name="ec3Url"
                                                props={{
                                                    children: (
                                                        <>
                                                            <EC3ExportButton
                                                                onClick={onEC3ExportButtonClicked}
                                                            >
                                                                <ExportText
                                                                    weight={400}
                                                                    size={14}
                                                                    height={18}
                                                                    color={blueLink}
                                                                >
                                                                    {`Export this project to the EC3 tool`}
                                                                </ExportText>
                                                            </EC3ExportButton>
                                                            {ec3ModalState.isOpen && productIds?.length && (
                                                                <EC3Login
                                                                    closeModal={ec3ModalState.onToggle}
                                                                    isOpen={isOpen}
                                                                    project={projectInfo}
                                                                    productIds={productIds}
                                                                    reload={startEC3Export}
                                                                    activeTab={'1'}
                                                                />
                                                            )}
                                                        </>
                                                    ),
                                                    disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                                }}
                                            />
                                        )}
                                </GrossBuildingInput>
                                <ProjectInfoPageTitle>
                                    Construction and contract
                                </ProjectInfoPageTitle>
                                <div id="right-label-container">
                                    <CustomSelect
                                        label="Green building rating system"
                                        name="greenBuildingRatingSystemId"
                                        options={references?.allGreenBuildingRatingSystems || []}
                                        placeholder="Select"
                                        props={{
                                            disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                        }}
                                    />
                                </div>
                                <div id="right-label-container">
                                    <ConstructionTypeInput>
                                        <CustomSelect
                                            label="Construction type"
                                            name="constructionTypeId"
                                            options={references?.allConstructionTypes || []}
                                            placeholder="Select"
                                            props={{
                                                disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                            }}
                                        />
                                    </ConstructionTypeInput>
                                </div>
                                <CustomSelect
                                    label="Contract type"
                                    name="contractTypeId"
                                    options={references?.allContractTypes || []}
                                    placeholder="Select"
                                    props={{
                                        disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                    }}
                                />
                                <CustomSelect
                                    label="Budget"
                                    name="budgetId"
                                    options={references?.allBudgets || []}
                                    placeholder="Select"
                                    props={{
                                        disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                    }}
                                />
                                <CustomInput
                                    label="Budget file"
                                    name="isConfidential"
                                    Component={budgetFile}
                                    props={{
                                        disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                    }}
                                />
                                <InvisibleContainer>
                                    <CustomInput
                                        label="locationX"
                                        name="locationX"
                                        Component={Input}
                                        props={{
                                            disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                        }}
                                    />
                                    <CustomInput
                                        label="locationY"
                                        name="locationY"
                                        Component={Input}
                                        props={{
                                            disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                        }}
                                    />
                                </InvisibleContainer>
                                <DocumentNameInput>
                                    <CustomInput
                                        Component={Input}
                                        label="Document name"
                                        name="documentName"
                                        props={{
                                            onChange: changeDocumentName,
                                            disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                        }}
                                        rules={[
                                            {
                                                pattern: /^[-\w^&'@{}[\],$=!#().%+~ ]+$/,
                                                message: 'Invalid file name',
                                            },
                                        ]}
                                    />
                                </DocumentNameInput>
                            </BaseInformationContainer>
                        </div>
                        <div>
                            <ProjectInfoPageTitle>Description and location</ProjectInfoPageTitle>
                            <CustomInput
                                label="Image"
                                name="isConfidential"
                                Component={imageFile}
                            />
                            {imageUrl ? (
                                <CustomButton onClick={onOpen} disabled={getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE}>
                                    <Image
                                        src={`src/assets/images/trash-grey.svg`}
                                        preview={false}
                                    />
                                </CustomButton>
                            ) : (
                                <></>
                            )}
                            <DeleteModal
                                isOpen={isOpen}
                                closeModal={onClose}
                                reload={deleteImage}
                                title="image"
                                text={'image'}
                                icon=""
                            />
                            <ProjectInformationPageDivider className="image" />
                            <CustomInput
                                Component={TextArea}
                                label="Description"
                                name="description"
                                props={{
                                    placeholder:
                                        'Can include project background info, summarized design brief and efficiency strategies to be explored. Limited to 3000 characters.',
                                    rows: 10,
                                    disabled: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE
                                }}
                                rules={[
                                    {
                                        max: 3000,
                                        message: 'Value should be less than 3000 characters',
                                    },
                                ]}
                            />
                            <ProjectInformationPageDivider />
                            <MapContainerWrapper projectInfo={projectInfo} form={form} disabled={getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE} />
                        </div>
                    </ProjectInformationSpace>
                    <ProjectInformationPageDivider />
                    <SpaceBetween>
                        <div>
                            <Text height={18} weight={400} size={14} color={grayDark03}>
                                Note: project data is aggregated and shared with manufacturers.
                            </Text>
                            <Text height={18} weight={400} size={14} color={grayDark03}>
                                No user information is shared.{' '}
                                <a style={{ color: '#134fbd' }} target='_blank' rel='noopener noreferrer' href="https://transparencycatalog.zendesk.com/hc/en-us/articles/12197032591634-Coming-Q1-2024-Project-Builder-Library-analytics-for-manufacturers">
                                    {' '}
                                    Learn about our Manufacturer Analytics Dashboard.
                                </a>
                            </Text>
                        </div>
                        <SaveProjectInfo disabled={disableSave()} htmlType="submit">
                            Save
                        </SaveProjectInfo>
                    </SpaceBetween>
                </BaseInformationForm>
            </Spin>
            {ec3SuccessModal.isOpen && projectInfo && (
                <EC3SuccessExport
                    isOpen={ec3SuccessModal.isOpen}
                    closeModal={ec3SuccessModal.onClose}
                    projectId={projectInfo.id}
                    setPersistActiveTab={setPersistActiveTab}
                />
            )}
        </PageContainer>
    );
};

export default ProjectInformation;
