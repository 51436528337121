import React from 'react';
import { InvisibleButton } from '../../../styles/common.styles';
import { SwitcherContainer } from './Switcher.styles';

interface Props {
    clicked: boolean;
    id: string;
    handleSwitch: (id: string) => void;
    width: number;
    height: number;
    small?: boolean;
}
const Switcher: React.FC<Props> = ({ clicked, id, handleSwitch, width, height, small }) => {
    return (
        <SwitcherContainer className="switch-mesh" width={width} height={height}>
            <div className={`switch-container ${small && 'small'}`}>
                <InvisibleButton
                    onClick={(event) => {
                        event.stopPropagation();
                        handleSwitch(id);
                    }}
                >
                    <div className={`switch-button th-head ${clicked ? 'clicked' : ''}`}></div>
                </InvisibleButton>
            </div>
        </SwitcherContainer>
    );
};

export default Switcher;
