import { GeoData } from '../../models/common/types';

export const states: Array<GeoData> = [
    {
        name: '--',
        id: 'NO',
        box: [-125.0011, 24.9493, -66.9326, 49.5904],
    },
    {
        name: 'Alabama',
        id: 'AL',
        box: [-88.473227, 30.223334, -84.88908, 35.008028],
    },
    {
        name: 'Alaska',
        id: 'AK',
        box: [-179.148909, 51.214183, 179.77847, 71.365162],
    },
    {
        name: 'American Samoa',
        id: 'AS',
        box: [-171.089874, -14.548699, -168.1433, -11.046934],
    },
    {
        name: 'Arizona',
        id: 'AZ',
        box: [-114.81651, 31.332177, -109.045223, 37.00426],
    },
    {
        name: 'Arkansas',
        id: 'AR',
        box: [-94.617919, 33.004106, -89.644395, 36.4996],
    },
    {
        name: 'California',
        id: 'CA',
        box: [-124.409591, 32.534156, -114.131211, 42.009518],
    },
    {
        name: 'Colorado',
        id: 'CO',
        box: [-109.060253, 36.992426, -102.041524, 41.003444],
    },
    {
        name: 'Connecticut',
        id: 'CT',
        box: [-73.727775, 40.980144, -71.786994, 42.050587],
    },
    {
        name: 'Delaware',
        id: 'DE',
        box: [-75.788658, 38.451013, -75.048939, 39.839007],
    },
    {
        name: 'District Of Columbia',
        id: 'DC',
        box: [-77.119759, 38.791645, -76.909395, 38.99511],
    },
    {
        name: 'Federated States Of Micronesia',
        id: 'FM',
        box: [137.4811, 1.103, 163.035591, 9.9667],
    },
    {
        name: 'Florida',
        id: 'FL',
        box: [-87.634938, 24.523096, -80.031362, 31.000888],
    },
    {
        name: 'Georgia',
        id: 'GA',
        box: [-85.605165, 30.357851, -80.839729, 35.000659],
    },
    {
        name: 'Guam',
        id: 'GU',
        box: [144.618068, 13.234189, 144.956712, 13.654383],
    },
    {
        name: 'Hawaii',
        id: 'HI',
        box: [-178.334698, 18.910361, -154.806773, 28.402123],
    },
    {
        name: 'Idaho',
        id: 'ID',
        box: [-117.243027, 41.988057, -111.043564, 49.001146],
    },
    {
        name: 'Illinois',
        id: 'IL',
        box: [-91.513079, 36.970298, -87.494756, 42.508481],
    },
    {
        name: 'Indiana',
        id: 'IN',
        box: [-88.09776, 37.771742, -84.784579, 41.760592],
    },
    {
        name: 'Iowa',
        id: 'IA',
        box: [-96.639704, 40.375501, -90.140061, 43.501196],
    },
    {
        name: 'Kansas',
        id: 'KS',
        box: [-102.051744, 36.993016, -94.588413, 40.003162],
    },
    {
        name: 'Kentucky',
        id: 'KY',
        box: [-89.571509, 36.497129, -81.964971, 39.147458],
    },
    {
        name: 'Louisiana',
        id: 'LA',
        box: [-94.043147, 28.928609, -88.817017, 33.019457],
    },
    {
        name: 'Maine',
        id: 'ME',
        box: [-71.083924, 42.977764, -66.949895, 47.459686],
    },
    {
        name: 'Marshall Islands',
        id: 'MH',
        box: [163.4985095, -0.5481258, 178.4985095, 14.4518742],
    },
    {
        name: 'Maryland',
        id: 'MD',
        box: [-79.487651, 37.911717, -75.048939, 39.723043],
    },
    {
        name: 'Massachusetts',
        id: 'MA',
        box: [-73.508142, 41.237964, -69.928393, 42.886589],
    },
    {
        name: 'Michigan',
        id: 'MI',
        box: [-90.418136, 41.696118, -82.413474, 48.2388],
    },
    {
        name: 'Minnesota',
        id: 'MN',
        box: [-97.239209, 43.499356, -89.491739, 49.384358],
    },
    {
        name: 'Mississippi',
        id: 'MS',
        box: [-91.655009, 30.173943, -88.097888, 34.996052],
    },
    {
        name: 'Missouri',
        id: 'MO',
        box: [-95.774704, 35.995683, -89.098843, 40.61364],
    },
    {
        name: 'Montana',
        id: 'MT',
        box: [-116.050003, 44.358221, -104.039138, 49.00139],
    },
    {
        name: 'Nebraska',
        id: 'NE',
        box: [-104.053514, 39.999998, -95.30829, 43.001708],
    },
    {
        name: 'Nevada',
        id: 'NV',
        box: [-120.005746, 35.001857, -114.039648, 42.002207],
    },
    {
        name: 'New Hampshire',
        id: 'NH',
        box: [-72.557247, 42.69699, -70.610621, 45.305476],
    },
    {
        name: 'New Jersey',
        id: 'NJ',
        box: [-75.559614, 38.928519, -73.893979, 41.357423],
    },
    {
        name: 'New Mexico',
        id: 'NM',
        box: [-109.050173, 31.332301, -103.001964, 37.000232],
    },
    {
        name: 'New York',
        id: 'NY',
        box: [-79.762152, 40.496103, -71.856214, 45.01585],
    },
    {
        name: 'North Carolina',
        id: 'NC',
        box: [-84.321869, 33.842316, -75.460621, 36.588117],
    },
    {
        name: 'North Dakota',
        id: 'ND',
        box: [-104.0489, 45.935054, -96.554507, 49.000574],
    },
    {
        name: 'Northern Mariana Islands',
        id: 'MP',
        box: [144.886331, 14.110472, 146.064818, 20.553802],
    },
    {
        name: 'Ohio',
        id: 'OH',
        box: [-84.820159, 38.403202, -80.518693, 41.977523],
    },
    {
        name: 'Oklahoma',
        id: 'OK',
        box: [-103.002565, 33.615833, -94.430662, 37.002206],
    },
    {
        name: 'Oregon',
        id: 'OR',
        box: [-124.566244, 41.991794, -116.463504, 46.292035],
    },
    {
        name: 'Palau',
        id: 'PW',
        box: [2.9351868, 42.5481153, 2.9840518, 42.5844284],
    },
    {
        name: 'Pennsylvania',
        id: 'PA',
        box: [-80.519891, 39.7198, -74.689516, 42.26986],
    },
    {
        name: 'Puerto Rico',
        id: 'PR',
        box: [-67.271492, 17.9268695, -65.5897525, 18.5159789],
    },
    {
        name: 'Rhode Island',
        id: 'RI',
        box: [-71.862772, 41.146339, -71.12057, 42.018798],
    },
    {
        name: 'South Carolina',
        id: 'SC',
        box: [-83.35391, 32.0346, -78.54203, 35.215402],
    },
    {
        name: 'South Dakota',
        id: 'SD',
        box: [-104.057698, 42.479635, -96.436589, 45.94545],
    },
    {
        name: 'Tennessee',
        id: 'TN',
        box: [-90.310298, 34.982972, -81.6469, 36.678118],
    },
    {
        name: 'Texas',
        id: 'TX',
        box: [-106.645646, 25.837377, -93.508292, 36.500704],
    },
    {
        name: 'Utah',
        id: 'UT',
        box: [-114.052962, 36.997968, -109.041058, 42.001567],
    },
    {
        name: 'Vermont',
        id: 'VT',
        box: [-73.43774, 42.726853, -71.464555, 45.016659],
    },
    {
        name: 'Virgin Islands',
        id: 'VI',
        box: [-65.085452, 17.673976, -64.564907, 18.412655],
    },
    {
        name: 'Virginia',
        id: 'VA',
        box: [-83.675395, 36.540738, -75.242266, 39.466012],
    },
    {
        name: 'Washington',
        id: 'WA',
        box: [-124.763068, 45.543541, -116.915989, 49.002494],
    },
    {
        name: 'West Virginia',
        id: 'WV',
        box: [-82.644739, 37.201483, -77.719519, 40.638801],
    },
    {
        name: 'Wisconsin',
        id: 'WI',
        box: [-92.888114, 42.491983, -86.805415, 47.080621],
    },
    {
        name: 'Wyoming',
        id: 'WY',
        box: [-111.056888, 40.994746, -104.05216, 45.005904],
    },
];
