import styled from "styled-components";
import { SaveButton } from "./ImageModal.styles";

export const TextContainer = styled('div')`
    width: 100%;
    margin-top: 20px;
`;

export const LabelContainer = styled('div')`
    margin-bottom: 4px;
    font-family: 'Proxima N W15 Bold';
`;

export const DownloadButton = styled(SaveButton)``;