import styled from 'styled-components';
import { blueLink } from '../../../styles/colors';
import { PrimaryButton } from '../../../styles/common.styles';
import { BannerStyleProps } from '../bannerTypes/BannerStyleProps';

export const DashboardButton = styled(PrimaryButton)`
    font-family: 'DINNextLTPro';
    margin-top: 16px;
    padding-top: 7px !important;
    width: fit-content;
`;

export const TitleText = styled('div')`
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    font-family: 'DINNextLTPro-Bold';
    width: auto;
    text-decoration-line: 'inherit';
`;

export const BannerWrapper = styled('div')`
    margin-bottom: 40px;
    .banner-content-wrapper {
        a {
            color: #fff;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        p:last-child {
            margin: 0;
        }
    }

    &.withoutPadding {
        .banner-content-wrapper {
            a {
                color: ${blueLink};
                text-decoration: none;
            }
        }
    }
    &.green {
        padding: 20px;
    }
`;

export const ContentWrapper = styled('div')`
    img {
        object-fit: cover;
        width: 100% !important;
    }
    p {
        word-break: break-word;
    }
`;

export const PromoWrapper = styled('div')`
    .banner-wrapper {
        background: ${(props: BannerStyleProps) => props.background};
    }

    .banner-title {
        a {
            color: ${(props: BannerStyleProps) => props.titleColor};
        }
    }

    .banner-content-wrapper {
        background: ${(props: BannerStyleProps) => props.background};
        color: ${(props: BannerStyleProps) => props.contentColor};

        span {
            color: ${(props: BannerStyleProps) => props.contentColor} !important;
            background-color: transparent !important;
        }
    }
`;
