import React from 'react';
import { ActivitiesName } from '../../../../../models/projectActivity/enums';
import { Activity } from '../../../../../redux/actions/projectActions/types';
import { blueLink } from '../../../../../styles/colors';
import { ActivityEventItem } from '../ProjectNotifications.styles';
import {
    ActivityEmailText,
    ActivityText,
    PricingInformationText,
    ProductLink,
    ProductNameText,
} from './ProjectActivityItem.styles';

interface Props {
    data: Array<Activity>;
}

const GenerateActivityText: React.FC<Props> = ({ data }) => {
    const activity = data[0];
    if (!activity) {
        return <ActivityText>did something</ActivityText>;
    }
    const projectProductLink = activity.projectProduct?.isDeleted ? (
        <ProductNameText>{activity.projectProduct?.productName}</ProductNameText>
    ) : (
        <ProductLink
            to={`/product-info/${activity.projectId}/${activity.projectProduct?.productId}` + (activity.projectProduct?.id ? `${activity.projectProduct?.id}` : `-1`)}
        >
            {activity.projectProduct?.productName}
        </ProductLink>
    );
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProductAdded) {
        return <ActivityText>added product {projectProductLink}</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProductDeleted) {
        return <ActivityText>deleted product {projectProductLink}</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProductUpdated) {
        return <ActivityText>updated product {projectProductLink}</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProductMarkedAsInstalled) {
        return <ActivityText>marked {projectProductLink} as installed</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProductMarkedAsUninstalled) {
        return <ActivityText>marked {projectProductLink} as uninstalled</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.CommentAdded) {
        if (activity.projectProduct?.isDeleted) {
            return (
                <ActivityText>
                    {`left ${data.length > 1 ? `${data.length} comments` : 'comment'} about `}
                    <ProductNameText>{activity.projectProduct?.productName}</ProductNameText>
                </ActivityText>
            );
        } else {
            return (
                <ActivityText>
                    {`left ${data.length > 1 ? `${data.length} comments` : 'comment'} about `}
                    <ProductLink
                        to={`/product-info/${activity.projectId}/${activity.projectProduct?.productId}/${activity.projectProductCommentId}`}
                    >
                        {activity.projectProduct?.productName}
                    </ProductLink>
                </ActivityText>
            );
        }
    }

    if (ActivitiesName[activity.activityName] === ActivitiesName.CommentDeleted) {
        if (activity.projectProduct?.isDeleted) {
            return (
                <ActivityText>
                    {`deleted ${data.length > 1 ? `${data.length} comments` : 'comment'} from `}
                    <ProductNameText>{activity.projectProduct?.productName}</ProductNameText>
                </ActivityText>
            );
        } else {
            return (
                <ActivityText>
                    {`deleted ${data.length > 1 ? `${data.length} comments` : 'comment'} from `}
                    <ProductLink
                        to={`/product-info/${activity.projectId}/${activity.projectProduct?.productId}/${activity.projectProductCommentId}`}
                    >
                        {activity.projectProduct?.productName}
                    </ProductLink>{' '}
                </ActivityText>
            );
        }
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.UploadedFile) {
        return (
            <ActivityText>
                {`uploaded ${data.length > 1 ? `${data.length} documents` : 'document'} about `}
                {projectProductLink}
            </ActivityText>
        );
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.DeletedFile) {
        return (
            <ActivityText>
                {`deleted ${data.length > 1 ? `${data.length} documents` : 'document'} from `}
                {projectProductLink}
            </ActivityText>
        );
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.UpdatedFile) {
        return (
            <ActivityText>
                updated file info {`in `} {projectProductLink}
            </ActivityText>
        );
    }

    if (ActivitiesName[activity.activityName] === ActivitiesName.UploadedImage) {
        return (
            <ActivityText>
                {`uploaded ${data.length > 1 ? `${data.length} images` : 'image'} about `}
                {projectProductLink}
            </ActivityText>
        );
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.DeletedImage) {
        return (
            <ActivityText>
                {`deleted ${data.length > 1 ? `${data.length} images` : 'image'} from `}
                {projectProductLink}
            </ActivityText>
        );
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.UpdatedImage) {
        return (
            <ActivityText>
                updated image info {`in `} {projectProductLink}
            </ActivityText>
        );
    }


    if (ActivitiesName[activity.activityName] === ActivitiesName.PricingUpdated) {
        if (activity.projectProduct?.isDeleted) {
            return (
                <ActivityText>
                    {`set pricing for `}
                    <ProductNameText>{activity.projectProduct?.productName}</ProductNameText>
                    <PricingInformationText>{` as ${activity.newValue}`}</PricingInformationText>
                </ActivityText>
            );
        } else {
            return (
                <PricingInformationText>
                    {`set pricing for `}
                    <ProductLink
                        to={`/product-info/${activity.projectId}/${activity.projectProduct?.productId}/0`}
                    >
                        {activity.projectProduct?.productName}
                    </ProductLink>{' '}
                    {` as ${activity.newValue}`}
                </PricingInformationText>
            );
        }
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.NewProjectAddedToProjectLibrary) {
        return <ActivityText>created project</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProjectShared) {
        if (data.length === 1) {
            return (
                <ActivityEmailText>
                    {`shared project to `}
                    <a href={`mailto:${activity.emailList}`}>
                        <ActivityEventItem weight={400} size={14} height={18} color={blueLink}>
                            {activity.emailList}
                        </ActivityEventItem>
                    </a>
                </ActivityEmailText>
            );
        } else {
            return <ActivityEmailText>{`shared project to `}</ActivityEmailText>;
        }
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProjectShareRemoved) {
        return (
            <ActivityEmailText>
                {`access to project for `}
                <a href={`mailto:${activity.emailList}`}>
                    <ActivityEventItem weight={400} size={13} height={16} color={blueLink}>
                        {activity.emailList}
                    </ActivityEventItem>
                </a>
                {` was removed`}
            </ActivityEmailText>
        );
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProjectDeleted) {
        return <ActivityText>{`project deleted`}</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProjectPropertiesUpdated) {
        return <ActivityText>{`updated project`}</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.ProjectCompleted) {
        return <ActivityText>{`project was completed`}</ActivityText>;
    }
    if (ActivitiesName[activity.activityName] === ActivitiesName.InvitationAccepted) {
        return <ActivityText>{`accepted invitation to this project. `}</ActivityText>;
    }
    return <></>;
};

export default GenerateActivityText;
