import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectData } from '../../../models/common/types';
import { TableData } from '../../../models/tableData/types';
import { User, UserTableFields } from '../../../models/user/user';
import {
    createUserAction,
    getAccountSettingsAction,
    getUserByIdAction,
    getUserReferencesAction,
    getUsersAction,
} from '../../actions/userActions';
import { resetSMAdminUserPasswordAction } from '../../actions/smAdminActions';

export interface UsersReducerType {
    users: Array<User>;
    selectedRows: Array<UserTableFields>;
    pending: boolean;
    tableData: TableData;
    references?: {
        allProjectGroups: Array<SelectData>;
        allAccountRoles: Array<SelectData>;
        allTitleRoles: Array<SelectData>;
    };
}

const initialState: UsersReducerType = {
    users: [],
    selectedRows: [],
    pending: false,
    tableData: {
        curPage: 1,
        pageSize: 10,
        searchString: '',
        sortDirection: 'asc',
        sortExpression: 'lastName',
    },
    references: {
        allProjectGroups: [],
        allAccountRoles: [],
        allTitleRoles: [],
    },
};

export const userSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        setUsers(state, action: PayloadAction<User>) {
            state.users = state.users.concat(action.payload);
        },
        setSelectedRows(state, action: PayloadAction<Array<UserTableFields>>) {
            state.selectedRows = action.payload;
        },
        clearSelectedRows(state) {
            state.selectedRows = [];
        },
        setUsersTableData(state, action: PayloadAction<TableData>) {
            state.tableData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getUsersAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getUsersAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(createUserAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(createUserAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(createUserAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getUserByIdAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getUserByIdAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getUserByIdAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getUserReferencesAction.fulfilled, (state, action) => {
            state.references = action.payload?.data;
            state.pending = false;
        });
        builder.addCase(getUserReferencesAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getUserReferencesAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getAccountSettingsAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getAccountSettingsAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getAccountSettingsAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(resetSMAdminUserPasswordAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(resetSMAdminUserPasswordAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(resetSMAdminUserPasswordAction.rejected, (state) => {
            state.pending = false;
        });
    },
});

export const { setSelectedRows, clearSelectedRows, setUsersTableData } = userSlice.actions;

export default userSlice.reducer;
