import { Modal, Row, Spin, Input } from 'antd';
import styled from 'styled-components';
import { error, blue01, blueDark01, grayDark05, grayDark06 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common.styles';

export const CreateAccountModal = styled(Modal)`
    width: 680px !important;
    height: 566px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    .ant-col {
        text-align: left;
    }

    .input-block .ant-form-item-row {
        line-height: 16px;
    }

    .ant-input {
        width: 280px;
        border-radius: 3px;
        border-color: ${grayDark06};
        padding: 6px 10px 6px 10px;
        font-size: 13px;
        line-height: 16px;
        height: 32px;
    }

    .ant-form-item {
        margin-bottom: 0;

        .ant-select-selector {
            width: 280px;
            border-color: ${grayDark06};
        }

        .ant-select-selector:hover {
            border-color: ${grayDark05};
            border-radius: 3px;
        }

        .ant-select-selector:focus,
        .ant-select-selector-focused {
            border-color: ${blue01};
            border-radius: 3px;
            box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
        }

        .ant-select-selector-status-error:not(.ant-select-selector-disabled):not(
                .ant-select-selector-borderless
            ).ant-select-selector,
        .ant-select-selector-status-error:not(.ant-select-selector-disabled):not(
                .ant-select-selector-borderless
            ).ant-select-selector:hover {
            border-color: ${error};
        }
    }

    .ant-form-horizontal .ant-form-item-label {
        line-height: 14px;
    }

    .ant-form-item-label > label {
        height: 16px;
        vertical-align: unset;
        margin-bottom: 4px;
        line-height: 16px !important;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        color: ${blueDark01} !important;

        &::before {
            display: none !important;
        }

        &::after {
            position: unset;
            top: 0;
            margin: 0;
        }
    }

    .ant-input:hover {
        border-color: ${grayDark05};
        border-radius: 3px;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: ${blue01};
        border-radius: 3px;
        box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        border-color: ${error};
    }

    .text-area-container {
        .ant-space-item {
            width: 100%;
            .ant-form-item-control {
                min-width: 100%;
                margin-right: 20px;
            }
        }
        label {
            font-weight: normal;
            font-family: 'Proxima N W15 Bold';
            font-size: 13px !important;
            line-height: 16px !important;
            color: ${blueDark01} !important;
            white-space: nowrap;
        }
    }
    form {
        margin-top: 1px;
    }

    .ant-divider-horizontal {
        margin-top: 40px;
        margin-bottom: 20px;
    }
`;

export const SectionLabel = styled(Text)`
    margin-top: ${(props: { marginTop?: number }) =>
        props.marginTop ? `${props.marginTop}px` : ''};
    margin-bottom: 20px;
`;

export const PhoneInputContainer = styled('div')`
    display: flex;
    gap: 20px;

    #input-container {
        &:nth-child(1) {
            input {
                width: 167px;
            }
        }
        &:nth-child(2) {
            input {
                width: 93px;
            }
        }
    }
`;

export const CheckboxInputContainer = styled('div')`
    & > div:first-child {
        margin-bottom: 4px;
    }
`;

export const CheckboxContainer = styled('div')`
    display: flex;
    gap: 8px;
`;

export const CreateAccountRow = styled(Row)`
    .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin: 0;
    }
    width: 100%;
    gap: 40px;
    margin-bottom: 22px;

    label {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px !important;
        line-height: 16px !important;
        color: ${blueDark01} !important;
        white-space: nowrap;
    }
`;

export const CreateAccountSpin = styled(Spin)`
    top: 0 !important;
`;

export const ModalInput = styled(Input)`
    font-size: 14px !important;
    color: ${blueDark01} !important;
    line-height: 18px !important;
`;
