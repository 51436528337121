import React, { useEffect, useState } from 'react';
import DeleteModal from '../../../../components/common/deleteModal/DeleteModal';
import { useAppDispatch } from '../../../../hooks/redux';
import {
    deleteSMAdminUserAction,
    updateSMAdminUserAction,
} from '../../../../redux/actions/smAdminActions';
import {
    ChangeRoleSMAdminUserRequest,
    SetPublisherSMAdminUserRequest,
} from '../../../../redux/actions/smAdminActions/types';
import { SMAdminTableFields, SMAdminUserTableFields } from '../../../models/user/smAdminUser';
import { UserType } from '../../../pages/users/SMAdminUsersPage';
import ChangeRoleForAdmin from '../changeRoleGroupModal/ChangeRoleForAdmin';

interface Props {
    action: string | null;
    user: SMAdminUserTableFields | SMAdminTableFields;
    riseAction: () => void;
    refresh: () => void;
    userType: UserType;
}

const SMAdminUsersTableActions: React.FC<Props> = ({
    action,
    user,
    riseAction,
    refresh,
    userType,
}) => {
    const dispatch = useAppDispatch();
    const [openModals, setOpenModals] = useState({
        changeRole: false,
        delete: false,
    });

    useEffect(() => {
        if (action) {
            switch (action) {
                case 'makePublisher':
                    setPublisher(true);
                    break;
                case 'removePublisher':
                    setPublisher(false);
                    break;
                default:
                    setOpenModals({ ...openModals, [action]: true });
                    break;
            }
        }
    }, [action]);

    const closeModal = (data: { key: string }) => {
        setOpenModals({ ...openModals, [data.key]: false });
        riseAction();
    };
    const reload = (key: string, data?: any) => {
        switch (key) {
            case 'role':
                changeRole(data);
                break;
            case 'delete':
                deleteUser();
                break;
            default:
                break;
        }
    };

    const changeRole = async (data: { accountRoleID: number }) => {
        const { payload } = await dispatch(
            updateSMAdminUserAction({
                id: user.id,
                roleId: data.accountRoleID,
            } as ChangeRoleSMAdminUserRequest) as any,
        );
        if (payload.data) {
            setOpenModals({ ...openModals, changeRole: false });
            refresh();
            riseAction();
        }
    };

    const setPublisher = async (isPublisher: boolean) => {
        const { payload } = await dispatch(
            updateSMAdminUserAction({
                id: user.id,
                isManufacturer: isPublisher,
            } as SetPublisherSMAdminUserRequest) as any,
        );
        if (payload.data) {
            refresh();
            riseAction();
        }
    };

    const deleteUser = async () => {
        let res = undefined;
        if (userType === 'user') {
            res = await dispatch(deleteSMAdminUserAction(user.key) as any);
        } else {
            //res = await dispatch(deleteSMAdminAction(user.key) as any);
        }
        if (res.payload?.message) {
            setOpenModals({ ...openModals, delete: false });
            refresh();
            riseAction();
        }
    };

    return (
        <>
            {openModals.changeRole && (
                <ChangeRoleForAdmin
                    isOpen={openModals.changeRole}
                    closeModal={closeModal}
                    field={'role'}
                    reload={reload}
                    sendActionInReload
                />
            )}
            {openModals.delete && (
                <DeleteModal
                    isOpen={openModals.delete}
                    closeModal={closeModal}
                    reload={reload}
                    text={`${user.firstName} ${user.lastName}`}
                    title="account"
                    icon="trash-can-list.svg"
                />
            )}
        </>
    );
};

export default SMAdminUsersTableActions;
