import React from 'react';
import { Col, Row } from 'antd';

import { SettingsGroupProps } from './SettingsGroupBox.types';
import { BlueTitle } from '../../../styles/common.styles';

const SettingsGroupBox: React.FC<SettingsGroupProps> = ({ title, children }) => (
    <div>
        <Row>
            <Col span={24}>
                <BlueTitle>{title}</BlueTitle>
            </Col>
        </Row>
        <Row>
            <Col span={24}>{children}</Col>
        </Row>
    </div>
);

export default SettingsGroupBox;
