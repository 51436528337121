import styled from 'styled-components';
import { CustomInitialAvatarProps } from './InitialAvatar.types';

export const CustomInitialAvatar = styled.div<CustomInitialAvatarProps>`
    width: 20px;
    height: 20px;
    line-height: 21px;
    font-family: "Proxima Nova";
    font-size: 13px;
    color: ${({ outlined }) => outlined ? "#1a9ebf" : "#fff"};
    background-color:  ${({ outlined }) => outlined ? "#fff" : "#1a9ebf"};
    border: 1px solid #1a9ebf;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`