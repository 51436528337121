import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../../helpers/request';
import { ActionResponse } from '../../../models/actionResponse/types';
import { DashboardDataResponse } from './types';

const baseUrl = `/dashboard`;

export const getDashboardData = createAsyncThunk<
    ActionResponse<DashboardDataResponse> | undefined,
    undefined
>('projects/getRecentlyProjects', async () => {
    const result = await http<ActionResponse<DashboardDataResponse>, undefined>({
        path: `${baseUrl}`,
        method: 'get',
    });
    console.log('GetRecentlyProjects:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
