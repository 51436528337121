import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { extractStoredUser } from '../../../helpers/extract';
import { UserRoleTypes } from '../../../models/permission/enums';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { StoredUser } from '../../../models/user/user';
import {
    getRegisterReferencesAction,
    loginAction,
    registerAction,
} from '../../actions/authActions';
import { getUserByIdAction } from '../../actions/userActions';
import { UserByIdResponse } from '../../actions/userActions/types';

export interface AuthReducerType {
    user: StoredUser | undefined;
    pending: boolean;
    showBanner: boolean;
}

const initialState: AuthReducerType = {
    user: undefined,
    pending: false,
    showBanner: false,
};

export const userSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        resetSpin(state) {
            state.pending = false;
        },
        setStoredUser(state, action: PayloadAction<UserByIdResponse>) {
            state.user = extractStoredUser(action.payload);
        },
        riseStoredUser(state) {
            state.user = undefined;
            state.showBanner = false;
        },
        closeBanner(state) {
            state.showBanner = false;
        },
        changeSubscription(
            state,
            action: PayloadAction<{
                subscriptionPlanName: SubscriptionTypes;
                subscriptionPlanId: number;
            }>,
        ) {
            if (state.user) {
                state.user.subscriptionPlanId = action.payload.subscriptionPlanId;
                state.user.subscriptionPlanName = action.payload.subscriptionPlanName;
                state.showBanner = false;
            }
        },
        clearAuthSlice(state) {
            state = initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAction.fulfilled, (state, action) => {
                state.user = extractStoredUser(action.payload?.user);
                state.pending = false;
                if (
                    state.user?.subscriptionPlanName?.toString().includes('Free') &&
                    state.user.appRole !== UserRoleTypes.SM_ADMIN
                ) {
                    state.showBanner = true;
                } else {
                    state.showBanner = false;
                }
            })
            .addCase(loginAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(loginAction.rejected, (state) => {
                state.pending = false;
            })
            .addCase(registerAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(registerAction.rejected, (state) => {
                state.pending = false;
            })
            .addCase(registerAction.fulfilled, (state) => {
                state.pending = false;
            })
            .addCase(getRegisterReferencesAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(getRegisterReferencesAction.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getRegisterReferencesAction.fulfilled, (state) => {
                state.pending = false;
            })
            .addCase(getUserByIdAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(getUserByIdAction.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getUserByIdAction.fulfilled, (state) => {
                state.pending = false;
            });
    },
});

export const {
    setStoredUser,
    riseStoredUser,
    resetSpin,
    changeSubscription,
    closeBanner,
    clearAuthSlice,
} = userSlice.actions;

export default userSlice.reducer;
