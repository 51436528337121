import React from 'react';
import {
    CaptchaBlock,
    CaptchaText,
    Link,
    ModalButtonContainer,
    OuterContainer,
} from './HelpModalFooter.styles';
import { PrimaryButton, SecondaryButton } from '../../../styles/common.styles';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
    submit: (data?: any) => void;
    changeCAPTCHA?: (data?: any) => void;
    cancel: () => void;
    action: string;
    primaryProps?: any;
}

const HelpModalFooter: React.FC<Props> = ({
    submit,
    cancel,
    action,
    primaryProps,
    changeCAPTCHA,
}) => {
    return (
        <OuterContainer>
            <CaptchaBlock>
                <ReCAPTCHA
                    sitekey="6LdMeR4UAAAAAFJKApbhMTWFGUTBXoLhwN6AmTUn"
                    onChange={changeCAPTCHA}
                />
                <CaptchaText>
                    By sharing your email, you agree to our <Link href="https://transparencycatalog.com/privacy-policy" target="_blank">Privacy policy</Link>.
                </CaptchaText>
            </CaptchaBlock>
            <ModalButtonContainer>
                <SecondaryButton onClick={cancel} width={100}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton htmlType="submit" onClick={submit} {...primaryProps} width={100}>
                    {action}
                </PrimaryButton>
            </ModalButtonContainer>
        </OuterContainer>
    );
};

export default HelpModalFooter;
