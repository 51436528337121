import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import {
    DetailsColumn1,
    DetailsColumn2,
    DetailsContainer,
    DetailsSubscriptionLink,
    DetailsPhoneInputContainer,
    DetailsSection,
    DetailsDivider,
    DetailsForm,
    DetailsPrimaryButton,
    DetailsCheckboxContainer,
    SMUserDetailSpin,
    AccessTabLinkContainer,
    AccessTabTitle,
    CopyToClipboardContainer,
} from './SMAdminUserDetails.styles';
import { BoldText, InvisibleButton, Margin, Text } from '../../../styles/common.styles';
import { black, blue01, blueDark01, blueLink } from '../../../styles/colors';
import CustomInput from '../../../components/common/inputs/CustomInput';
import { Input, Image, Checkbox, Form } from 'antd';
import CustomSelect from '../../../components/common/select/CustomSelect';
import { Link } from '../../../components/common/modalFooter/ModalFooter.styles';
import PageHeader from '../../../components/common/pageHeader/PageHeader';
import ErrorModal from '../../../components/common/errorModal/ErrorModal';
import { addPage, removePage } from '../../../redux/reducers/navigationReducer/NavigationSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useModalState } from '../../../hooks/modalState';
import {
    getSMAdminUserByIdAction,
    resetSMAdminUserPasswordAction,
    updateSMAdminUserAction,
} from '../../../redux/actions/smAdminActions';
import { useHistory, useParams } from 'react-router-dom';
import {
    SMAdminUserByIdResponse,
    UpdateSMAdminUserRequest,
} from '../../../redux/actions/smAdminActions/types';
import { SelectData } from '../../../models/common/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { getUserReferencesAction } from '../../../redux/actions/userActions';
import { AlertMessages } from '../../../components/alert/AlertMessages';
import { alertService } from '../../../services';
import { formatTimeEST } from '../../../helpers/dateHelper';
import { ActionResponse, CommonResponse } from '../../../models/actionResponse/types';
import { getSubscriptionAction } from '../../../redux/actions/subscriptionActions';
import { SubscriptionPlan } from '../../../redux/actions/userActions/types';
import { formatSubscription } from '../../../helpers/textFormatter';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import SubscriptionDetailsSection from './subscriptionDetailsSection/SubscriptionDetailsSection';

interface EditParams {
    id: string;
}

const SMAdminUsersDetails: React.FC = () => {
    const { id } = useParams<EditParams>();
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { userReducer, smAdminReducer } = selector;
    const history = useHistory();
    const [references, setReferences] = useState({
        allAccountRoles: Array<SelectData>(),
        allOrganizationSizes: Array<SelectData>(),
        allTitleRoles: Array<SelectData>(),
    });
    const [subscriptionPlans, setSubscriptionPlans] = useState<Array<SubscriptionPlan>>([]);
    const errorModal = useModalState();

    const [user, setUser] = useState<SMAdminUserByIdResponse | undefined>(undefined);
    const [userSubscriptionName, setUserSubscriptionName] = useState('');
    const [isPhoneRequired, setIsPhoneRequired] = useState(true);
    const [isManufacturerCheckboxState, setIsManufacturerCheckboxState] = useState(false);

    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);

    const [form] = Form.useForm();

    const proximaNovaFont = 'Proxima Nova';
    const dinNextLTProFont = 'DINNextLTPro';

    useEffect(() => {
        getData();
        return () => {
            dispatch(removePage());
        };
    }, []);

    const getData = async () => {
        await getSubscriptions();
        await getReferences();
    };

    const getSubscriptions = async () => {
        const { payload } = (await dispatch(getSubscriptionAction())) as ActionResponse<
            Array<SubscriptionPlan>
        >;
        if (payload.data) {
            setSubscriptionPlans(payload.data);
        }
    };

    useEffect(() => {
        if (references.allTitleRoles.length) {
            getUser();
        }
    }, [references]);

    useEffect(() => {
        if (user) {
            form.setFieldsValue(user);
        }
    }, [user]);

    const getUser = async () => {
        const { payload } = (await dispatch(
            getSMAdminUserByIdAction(id),
        )) as ActionResponse<SMAdminUserByIdResponse>;
        if (payload?.data) {
            const userFromPayload = payload?.data;
            userFromPayload.titleRoleId = references.allTitleRoles.find(
                (item) => +item.id === userFromPayload.titleRoleId,
            )!.name;
            setIsManufacturerCheckboxState(userFromPayload.isManufacturer);
            setUser(userFromPayload);
            const subcriptionName = formatSubscription(
                subscriptionPlans.find(
                    (s) => s.id.toString() === userFromPayload?.subscriptionPlanId?.toString(),
                )?.name,
            );
            setUserSubscriptionName(subcriptionName);
            setIsPhoneRequired(
                subcriptionName === SubscriptionTypes.OFFICE ||
                subcriptionName === SubscriptionTypes.INDIVIDUAL,
            );
            dispatch(
                addPage({
                    key: `/users/edit/${payload.data?.id}`,
                    value: `${payload.data?.firstName} ${payload.data?.lastName}`,
                }),
            );
        } else {
            history.push('/users');
        }
    };

    const getReferences = async () => {
        const { payload } = (await dispatch(getUserReferencesAction())) as any;
        if (payload.data) {
            setReferences({
                allOrganizationSizes: payload?.data?.allOrganizationSizes || [],
                allTitleRoles: payload.data.allTitleRoles || [],
                allAccountRoles:
                    payload.data.allAccountRoles.map((role: SelectData) => ({
                        ...role,
                        name: role.name.replace('Account', ''),
                    })) || [],
            });
        }
    };

    const handleIsManufacturerCheckbox = (event: CheckboxChangeEvent) => {
        setIsManufacturerCheckboxState(event.target.checked);
    };

    const resetPassword = async () => {
        const { payload } = (await dispatch(resetSMAdminUserPasswordAction(id))) as CommonResponse;
        if (payload.message) {
            window.scroll(0, 0);
            alertService.success(AlertMessages.RESET_PASSWORD, { fixed: true });
        }
    };

    const copyConfirmationLink = async (event: any) => {
        setCopied1(true);
        const link = user?.confirmRegistrationLink;
        alertService.success(AlertMessages.COPY_LINK);
        window.navigator.clipboard.writeText(link);
        setTimeout(() => {
            setCopied1(false);
        }, 2000);
    };

    const copyResetPasswordLink = async (event: any) => {
        setCopied2(true);
        const link = user?.restorePasswordLink;
        alertService.success(AlertMessages.COPY_LINK);
        window.navigator.clipboard.writeText(link);
        setTimeout(() => {
            setCopied2(false);
        }, 2000);
    };

    const saveChanges = async (data: UpdateSMAdminUserRequest) => {
        data.id = parseInt(id);
        data.isManufacturer = isManufacturerCheckboxState;
        const { payload } = (await dispatch(updateSMAdminUserAction(data))) as any;
        if (payload.data) {
            alertService.success(AlertMessages.UPDATE_USER, { fixed: true });
        }
    };

    return (
        <SMUserDetailSpin spinning={userReducer.pending || smAdminReducer.pending} size="large">
            <PageHeader title={`${user?.firstName} ${user?.lastName}`} showMenu={false} />
            <DetailsForm onFinish={saveChanges} form={form}>
                <DetailsContainer>
                    <DetailsColumn1>
                        <DetailsSection>
                            <Text
                                weight={500}
                                size={20}
                                height={24}
                                width={154}
                                color={blue01}
                                font={dinNextLTProFont}
                            >
                                Basic information
                            </Text>
                            <Margin value="0 0 28px 0" />
                            <CustomInput
                                Component={Input}
                                label={'First name'}
                                name={'firstName'}
                                required
                                block
                            />
                            <CustomInput
                                Component={Input}
                                label={'Last name'}
                                name={'lastName'}
                                required
                                block
                            />
                            <CustomInput
                                Component={Input}
                                label={'Title/role'}
                                name={'titleRoleId'}
                                required
                                block
                            />
                            <CustomInput
                                Component={Input}
                                label={'Email'}
                                name={'email'}
                                required
                                block
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add your email',
                                    },
                                    {
                                        pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                                        message: 'Invalid email',
                                    },
                                ]}
                            />
                            <DetailsPhoneInputContainer>
                                <CustomInput
                                    Component={Input}
                                    label={'Phone'}
                                    name={'phoneNumber'}
                                    required={isPhoneRequired}
                                />
                                <CustomInput
                                    Component={Input}
                                    label={'Ext.'}
                                    name={'phoneNumberExt'}
                                />
                            </DetailsPhoneInputContainer>
                            <CustomSelect
                                label={'Project Builder account type*'}
                                name={'roleId'}
                                block
                                required
                                options={references.allAccountRoles}
                            />
                            <DetailsCheckboxContainer>
                                <BoldText
                                    weight={400}
                                    size={13}
                                    height={16}
                                    width={100}
                                    color={blueDark01}
                                >
                                    Publisher
                                </BoldText>
                                <Checkbox
                                    checked={isManufacturerCheckboxState}
                                    name="isManufacturer"
                                    onChange={handleIsManufacturerCheckbox}
                                />
                                <Text
                                    weight={400}
                                    size={14}
                                    height={18}
                                    color={blueDark01}
                                    font={proximaNovaFont}
                                >
                                    User may upload/manage manufacturer templates.
                                </Text>
                            </DetailsCheckboxContainer>
                        </DetailsSection>
                        <DetailsSection>
                            <Margin value="0 0 40px 0" />
                            <Text
                                weight={500}
                                size={20}
                                height={24}
                                width={210}
                                color={blue01}
                                font={dinNextLTProFont}
                            >
                                About your organization
                            </Text>
                            <Margin value="0 0 14px 0" />
                            <CustomInput
                                Component={Input}
                                label={'Organization name*'}
                                name={'organizationName'}
                                required
                                block
                            />
                            <CustomSelect
                                label={'Organization size*'}
                                name={'organizationSizeId'}
                                block
                                required
                                options={references.allOrganizationSizes}
                            />
                            <Margin value="0 0 12px 0" />
                        </DetailsSection>
                        <DetailsSection>
                            <Text
                                weight={500}
                                size={20}
                                height={24}
                                width={210}
                                color={blueDark01}
                                font={dinNextLTProFont}
                            >
                                About your office
                            </Text>
                            <Margin value="0 0 14px 0" />
                            <CustomInput
                                Component={Input}
                                label={'Office'}
                                name={'officeName'}
                                block
                            />
                        </DetailsSection>
                        <DetailsSection>
                            <Text
                                weight={500}
                                size={20}
                                height={24}
                                width={87}
                                color={blue01}
                                font={dinNextLTProFont}
                            >
                                Password
                            </Text>
                            <Margin value="0 0 14px 0" />
                            <InvisibleButton onClick={resetPassword}>
                                <Text
                                    weight={400}
                                    size={13}
                                    height={16}
                                    color={blueLink}
                                    font={proximaNovaFont}
                                >
                                    Reset password
                                </Text>
                            </InvisibleButton>
                        </DetailsSection>
                    </DetailsColumn1>
                    <DetailsColumn2>
                        {user?.lastLoginTime ? (
                            <>
                                <DetailsSection>
                                    <Text
                                        weight={500}
                                        size={20}
                                        height={24}
                                        width={86}
                                        color={blue01}
                                        font={dinNextLTProFont}
                                    >
                                        Last login
                                    </Text>
                                    <Margin value="0 0 34px 0" />
                                    <Text
                                        weight={400}
                                        size={16}
                                        height={20}
                                        color={black}
                                        font={proximaNovaFont}
                                    >
                                        {formatTimeEST(user?.lastLoginTime)}
                                    </Text>
                                </DetailsSection>
                                <Margin value="0 0 40px 0" />
                            </>
                        ) : (
                            <></>
                        )}
                        <DetailsSection>
                            <Text
                                weight={500}
                                size={20}
                                height={24}
                                width={111}
                                color={blue01}
                                font={dinNextLTProFont}
                            >
                                Subscription
                            </Text>
                            <SubscriptionDetailsSection
                                user={user}
                                userSubscriptionName={userSubscriptionName}
                            />
                            {user?.stripeDashboardCustomerLink && (
                                <DetailsSubscriptionLink>
                                    <Link href={user?.stripeDashboardCustomerLink}>
                                        View subscription on Stripe
                                    </Link>
                                    <Image
                                        preview={false}
                                        src="src/assets/images/arrow-up-right-from-square.svg"
                                        height={16}
                                    />
                                </DetailsSubscriptionLink>
                            )}

                            {(user?.confirmRegistrationLink || user?.restorePasswordLink) && (
                              <DetailsSection>
                                <Margin value="14px 0 28px 0" />
                                <Text
                                    weight={500}
                                    size={20}
                                    height={24}
                                    width={150}
                                    color={blue01}
                                    font={dinNextLTProFont}
                                >
                                    Copy links
                                </Text>
                              </DetailsSection>
                            )}
                            {user?.confirmRegistrationLink && (
                                <AccessTabLinkContainer style={{ width: '212px' }}>
                                    {!copied1 ? (
                                        <CopyToClipboardContainer onClick={copyConfirmationLink}>
                                            <Image src="src/assets/images/link.svg" preview={false} />
                                            <AccessTabTitle>Copy account activation link</AccessTabTitle>
                                        </CopyToClipboardContainer>
                                      ) : (
                                        <CopyToClipboardContainer onClick={copyConfirmationLink}>
                                            <CheckOutlined style={{ color: 'green' }} />
                                            <AccessTabTitle>Copy account activation link</AccessTabTitle>
                                        </CopyToClipboardContainer>
                                    )}
                                </AccessTabLinkContainer>
                            )}
                            {user?.restorePasswordLink && (
                                <AccessTabLinkContainer>
                                    {!copied2 ? (
                                        <CopyToClipboardContainer onClick={copyResetPasswordLink}>
                                            <Image src="src/assets/images/link.svg" preview={false} />
                                            <AccessTabTitle>Copy set password link</AccessTabTitle>
                                        </CopyToClipboardContainer>
                                      ) : (
                                        <CopyToClipboardContainer onClick={copyResetPasswordLink}>
                                            <CheckOutlined style={{ color: 'green' }} />
                                            <AccessTabTitle>Copy set password link</AccessTabTitle>
                                        </CopyToClipboardContainer>
                                    )}
                                    <Margin value="0 0 14px 0" />
                                </AccessTabLinkContainer>
                            )}
                        </DetailsSection>
                    </DetailsColumn2>
                </DetailsContainer>
                <DetailsDivider />
                <DetailsPrimaryButton htmlType="submit" width={58}>
                    Save
                </DetailsPrimaryButton>
            </DetailsForm>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </SMUserDetailSpin>
    );
};

export default SMAdminUsersDetails;
