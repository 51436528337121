import React from 'react';
import { Form, Col } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useModalState } from '../../../../hooks/modalState';
import ErrorModal from '../../../../components/common/errorModal/ErrorModal';
import ModalTitle from '../../../../components/common/modalTitle/ModalTitle';
import CustomInput from '../../../../components/common/inputs/CustomInput';
import ModalFooter from '../../../../components/common/modalFooter/ModalFooter';
import { CloseIcon } from '../../../../styles/common.styles';
import {
    CreateAccountModal,
    CreateAccountRow,
    CreateAccountSpin,
    PhoneInputContainer,
    ModalInput,
} from './CreateOneSMAdminModal.styles';
import { CreateSMAdminRequest } from '../../../../redux/actions/smAdminActions/types';
import { createSMAdminAction } from '../../../../redux/actions/smAdminActions';
import { SMAdminTableFields } from '../../../models/user/smAdminUser';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'admin' }) => void;
    reload: (modal: 'admin', user: Array<SMAdminTableFields>) => void;
}

const CreateOneSMAdminModal: React.FC<Props> = ({ isOpen, closeModal, reload }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const errorModal = useModalState();

    const [form] = Form.useForm();

    const handleCancel = () => {
        closeModal({ key: 'admin' });
    };

    const onSubmit = async (data: CreateSMAdminRequest) => {
        const { payload } = (await dispatch(createSMAdminAction(data))) as any;
        if (payload?.data) {
            reload('admin', payload.data);
        } else {
            errorModal.onOpen();
        }
    };

    return (
        <CreateAccountModal
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
            centered
            closeIcon={<CloseIcon />}
        >
            <CreateAccountSpin size="large" spinning={selector.userReducer.pending}>
                <ModalTitle
                    title="Create SM admin"
                    icon="ic-modal-title-create-accounts-bulk.svg"
                />
                <Form onFinish={onSubmit} form={form}>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="First name"
                                name="firstName"
                                required
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </Col>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Last name"
                                name="lastName"
                                required
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </Col>
                    </CreateAccountRow>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Email"
                                name="email"
                                required
                                rules={[
                                    { required: true, message: '' },
                                    {
                                        pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                                        message: 'Invalid email',
                                    },
                                ]}
                                block
                            />
                        </Col>
                        <Col>
                            <PhoneInputContainer>
                                <CustomInput
                                    Component={ModalInput}
                                    label="Phone"
                                    name="phoneNumber"
                                    rules={[
                                        {
                                            pattern:
                                                /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                                            message: 'Invalid phone',
                                        },
                                    ]}
                                    block
                                />

                                <CustomInput
                                    Component={ModalInput}
                                    label="Ext."
                                    name="phoneNumberExt"
                                    block
                                    rules={[
                                        {
                                            pattern: /^(\+?\d{1,3}|\d{1,4})$/,
                                            message: 'Invalid phone extension',
                                        },
                                    ]}
                                />
                            </PhoneInputContainer>
                        </Col>
                    </CreateAccountRow>
                    <ModalFooter
                        action="Create SM admin"
                        cancel={handleCancel}
                        submit={() => {}}
                        actionButtonWidth={131}
                        cancelButtonWidth={69}
                    />
                </Form>
            </CreateAccountSpin>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </CreateAccountModal>
    );
};

export default CreateOneSMAdminModal;
