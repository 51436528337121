import React from 'react';
import * as MaterialProgramTip from '../MaterialProgramTip';
import { TooltipStyles } from '../../Tooltip.styles';

const DeclareTip: React.FC = () => {
    return (
        <MaterialProgramTip.Container>
            <TooltipStyles className="shadow">
                <div className="tooltip tooltip-declare">
                    <h1>Declare</h1>
                    <h2>Program</h2>
                    <p>
                        The Declare product database and label are used to select products that meet
                        the Living Building Challenge's and Living Product Challenge's stringent
                        materials requirements, streamlining the materials specification and
                        certification process. Labels are self-declared, verification is optional.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <img src="src/assets/images/verified.png" alt="" />{' '}
                        <b>Declare label has been 3rd party verified.</b>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <b>Declare 3rd party verifiers:</b> A Greener Space, Green Start,
                        GreenCircle, SCS, Sustainability Made Simple, Vertima, WAP Sustainability.{' '}
                    </p>
                    <h2>Results</h2>
                    <div className="tooltip-content">
                        <div className="tooltip-row">
                            <div className="circle red"></div>
                            <p>
                                <strong>LBC Red List Free</strong> (formerly "Red List Free"): This
                                product is 100% disclosed at 100ppm and does not contain any
                                ingredients on the Red List.
                            </p>
                        </div>
                        <div className="tooltip-row">
                            <div className="circle orange"></div>
                            <p>
                                <strong>LBC Red List Approved</strong> (formerly "LBC Compliant"):
                                This product is 99-100% disclosed at 100ppm and may contain Red List
                                ingredient(s) if covered by an existing exception.
                            </p>
                        </div>
                        <div className="tooltip-row">
                            <div className="circle yellow"></div>
                            <p>
                                <strong>Declared:</strong> This product is 100% disclosed at 100ppm
                                and contains Red List ingredient(s).
                            </p>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <a href="https://declare.living-future.org/" target="_blank">
                            Visit the Declare website to learn more.
                        </a>
                    </p>
                </div>
            </TooltipStyles>
        </MaterialProgramTip.Container>
    );
};

export default DeclareTip;
