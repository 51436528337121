import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useAppDispatch } from '../../hooks/redux';
import { changeNotificationsAction } from '../../redux/actions/userActions';
import { NotificationItem } from '../../models/user/user';
import { AccountPermissionTypes } from '../../models/permission/enums';
import { usePermissions } from '../../hooks/permissions';

import {
    ACCEPTS_SHARING,
    ADDS_PRODUCTS,
    defaultState,
    LEAVES_COMMENT,
    SEND_COPY,
    UPLOAD_DOCUMENT,
} from './NotificationsEmail.mockData';
import {
    CheckBoxContainer,
    NotificationsEmailTable,
    NotificationsEmailText,
    NotificationsTableTitle,
    NotificationTip,
} from './NotificationsEmail.styles';
import { PageContainer } from '../../styles/common.styles';

interface Props {
    userNotifications: Array<NotificationItem>;
    id: number | undefined;
}

interface State {
    [key: string]: NotificationItem;
}

const NotificationsEmail: React.FC<Props> = ({ userNotifications, id }) => {
    const dispatch = useAppDispatch();
    const { getAccountRole } = usePermissions();

    const [notifications, setNotifications] = useState<State>(defaultState);
    useEffect(() => {
        const currentNotifications = userNotifications.reduce(
            (agg, v) => ({ ...agg, [v.name]: v }),
            notifications,
        );
        setNotifications(currentNotifications);
    }, [userNotifications]);

    const handleEachTimeChange = (data: CheckboxChangeEvent) => {
        const value = notifications[data.target.name as any];
        value.sendEmailMeEachTime = data.target.checked;
        submitChange(data.target.name, value);
    };

    const handleDigestChange = (data: CheckboxChangeEvent) => {
        const value = notifications[data.target.name as any];
        value.sendEmailDigest = data.target.checked;
        submitChange(data.target.name, value);
    };

    const submitChange = (name: string = '', value: NotificationItem) => {
        setNotifications({ ...notifications, [name]: value });
        dispatch(
            changeNotificationsAction(
                Object.values({ ...notifications, [name]: value }).map((item) => ({
                    ...item,
                    userId: id,
                })),
            ),
        );
    };

    return (
        <PageContainer>
            <NotificationsEmailText style={{ paddingLeft: '0' }}>
                A record of project notifications is always available from each project’s
                Notifications tab.
            </NotificationsEmailText>
            <NotificationsEmailTable>
                <thead>
                    <tr>
                        <td>
                            <NotificationsTableTitle style={{ paddingLeft: '0' }}>
                                Notify when
                            </NotificationsTableTitle>
                        </td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <NotificationsTableTitle>
                                <div>Email me each time</div>
                            </NotificationsTableTitle>
                        </td>
                        <td style={{ width: '120px', textAlign: 'center' }}>
                            <NotificationsTableTitle
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: '0',
                                }}
                            >
                                <NotificationTip>Sent every 24 hours</NotificationTip>
                                <div>Email digest</div>
                            </NotificationsTableTitle>
                        </td>
                    </tr>
                </thead>
                <tbody>

                    {getAccountRole() !== AccountPermissionTypes.ACCOUNT_FREE && (
                    <tr>
                        <td>{notifications[ACCEPTS_SHARING]?.name.toString()}</td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[ACCEPTS_SHARING]?.name.toString()}
                                    checked={notifications[ACCEPTS_SHARING]?.sendEmailMeEachTime}
                                    onChange={handleEachTimeChange}
                                />
                            </CheckBoxContainer>
                        </td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[ACCEPTS_SHARING]?.name}
                                    checked={notifications[ACCEPTS_SHARING]?.sendEmailDigest}
                                    onChange={handleDigestChange}
                                />
                            </CheckBoxContainer>
                        </td>
                    </tr>
                    )}

                    <tr>
                        <td>Someone adds, edits, or deletes a product in my projects</td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[ADDS_PRODUCTS]?.name.toString()}
                                    checked={notifications[ADDS_PRODUCTS]?.sendEmailMeEachTime}
                                    onChange={handleEachTimeChange}
                                />
                            </CheckBoxContainer>
                        </td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[ADDS_PRODUCTS]?.name.toString()}
                                    checked={notifications[ADDS_PRODUCTS]?.sendEmailDigest}
                                    onChange={handleDigestChange}
                                />
                            </CheckBoxContainer>
                        </td>
                    </tr>
                    <tr>
                        <td>Someone leaves a comment on one of my projects</td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[LEAVES_COMMENT]?.name.toString()}
                                    checked={notifications[LEAVES_COMMENT]?.sendEmailMeEachTime}
                                    onChange={handleEachTimeChange}
                                />
                            </CheckBoxContainer>
                        </td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[LEAVES_COMMENT]?.name.toString()}
                                    checked={notifications[LEAVES_COMMENT]?.sendEmailDigest}
                                    onChange={handleDigestChange}
                                />
                            </CheckBoxContainer>
                        </td>
                    </tr>
                    <tr>
                        <td>Someone uploads a new document for a product</td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[UPLOAD_DOCUMENT]?.name.toString()}
                                    checked={notifications[UPLOAD_DOCUMENT]?.sendEmailMeEachTime}
                                    onChange={handleEachTimeChange}
                                />
                            </CheckBoxContainer>
                        </td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[UPLOAD_DOCUMENT]?.name.toString()}
                                    checked={notifications[UPLOAD_DOCUMENT]?.sendEmailDigest}
                                    onChange={handleDigestChange}
                                />
                            </CheckBoxContainer>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            I contact a manufacturer for spec & quote help: send me a copy of my
                            message
                        </td>
                        <td>
                            <CheckBoxContainer>
                                <Checkbox
                                    name={notifications[SEND_COPY]?.name.toString()}
                                    checked={notifications[SEND_COPY]?.sendEmailMeEachTime}
                                    onChange={handleEachTimeChange}
                                />
                            </CheckBoxContainer>
                        </td>
                    </tr>
                </tbody>
            </NotificationsEmailTable>
        </PageContainer>
    );
};

export default NotificationsEmail;
