import React from 'react';
import { useParams } from 'react-router-dom';
import ProductComments from '../../../components/productInfo/comments/ProductComments';
import ProductPricing from '../../../components/productInfo/pricing/PricingInfo';
import { Product } from '../../../models/product/types';
import { ProductReferences } from '../../../redux/actions/productActions/types';
import { MFRPProductItemContainer } from './MFRProduct.styles';
import MFRProductInfo from './mfrProductInfo/MFRProductInfo';
import { MFRProject } from '../../../models/project/types';
import ProductItemHeader from '../../../components/favoritProducts/favoriteProductItem/productItemHeader/ProductItemHeader';
import ProductUploads, { UploadType } from '../../../components/productInfo/uploads/UploadsInfo';
import { isFunctionalityEnabled } from '../../../../config/functionalityAvailability';
import { UPLOAD_PHOTOS } from '../../../../config/paramsConfig';

interface Params {
    projectId: string;
    productId: string;
    projectProductId?: string;
    mode?: string;
    commentId?: string;
}

interface Props {
    references: ProductReferences | null;
    product: Product;
    productsCount: number;
    projectData: MFRProject;
    editable: boolean;
    commentId?: string;
    selectedProductId?: string;
    openingMode?: string;
    showHelp: boolean;
    showProductItemHeader: boolean;
}

const MFRPProductItem: React.FC<Props> = ({
    references,
    product,
    productsCount,
    editable,
    projectData,
    commentId,
    selectedProductId,
    openingMode,
    showHelp,
    showProductItemHeader,
}) => {

    const params = useParams<Params>();

    const allowBeOpened = (params === null || params.projectProductId === null || Number(params.projectProductId) === Number(product.id));

    return (
        <MFRPProductItemContainer id={product.productId.toString()}>
            <ProductItemHeader
                product={product}
                productsCount={productsCount}
                showCheckbox={false}
                projectInfo={projectData}
                showHelp={showHelp}
                showHeader={showProductItemHeader}
            />
            <MFRProductInfo references={references} project={projectData} product={product} editable={editable} />
            <ProductComments
                product={product}
                projectData={projectData}
                openByDefault={openingMode == 'comments' && allowBeOpened ? 1 : 0}
                commentId={commentId}
                selectedProductId={selectedProductId}
            />
            <ProductPricing
                product={product}
                userRole={projectData.userRole}
                openPricing={commentId}
            />
            <ProductUploads
                allDocumentTypes={references?.allDocumentTypes}
                product={product}
                project={projectData}
                openByDefault={openingMode == 'documents' && allowBeOpened ? 1 : 0}
                commentId={commentId}
                uploadType={UploadType.DOCUMENT}
            />
            {
                isFunctionalityEnabled(UPLOAD_PHOTOS) &&
                <ProductUploads
                    allDocumentTypes={references?.allDocumentTypes}
                    product={product}
                    project={projectData}
                    openByDefault={openingMode == 'images' && allowBeOpened ? 1 : 0}
                    commentId={commentId}
                    uploadType={UploadType.PICTURE}
                />
            }
        </MFRPProductItemContainer>
    );
};

export default MFRPProductItem;
