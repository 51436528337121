import React, { useEffect, useState, useRef } from 'react';
import { Col } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import ProjectsTable from '../../components/projects/projectsTable/ProjectsTable';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getProjectLibraryAction } from '../../redux/actions/projectActions';
import { addPages, removePage } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { Project } from '../../models/project/types';
import { initialTableData } from './ProjectLibrary.mockData';
import { TableData } from '../../models/tableData/types';
import {
    setActivatedProject,
    setAddNewProjectMark,
    setCompletedProject,
    setDeletedProject,
    setTemplateProject,
} from '../../redux/reducers/projectsReducer/ProjectSlice';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { LibraryType, ProjectLibraryCountType } from '../../models/project/enums';
import { useParams } from 'react-router-dom';
import { useModalState } from '../../hooks/modalState';
import StartProject from '../../components/projects/startProject/StartProject';
import {
    addProductsToProjectAction,
    getProductIdsByPackageIdAction,
} from '../../redux/actions/productActions';
import {
    AddProductsToProjectResponse,
    GetProductIdsByPackageIdResponse,
} from '../../redux/actions/productActions/types';
import ExistingProductsModal from '../../components/existingProductsModal/ExistingProductsModal';
import { ActionResponse } from '../../models/actionResponse/types';
import { getExternalProductsAction } from '../../redux/actions/transparencyCatalogActions';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../redux/actions/transparencyCatalogActions/types';
import { ExternalProduct } from '../../models/product/types';
import { columns } from '../../components/projects/projectsTable/ProjectsTableColumns';
import { manufacturerTemplateColumns } from '../../components/projects/projectsTable/MFRTemplateColumns';
import SuggestUpgradeAccountModal from '../../components/suggestUpgradeAccountModal/SuggestUpgradeAccountModal';
import { SubscriptionTypes } from '../../models/subscription/enums';
import { ProjectLibraryContainer } from './ProjectLibrary.styles';
import CustomTabs from '../../components/common/tabs/CustomTabs';

interface Params {
    projectId: string;
    packageId?: string;
}
const ProjectLibrary: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const historyState = useRef<Array<string | undefined | null>>(
        Array<string | undefined | null>(),
    );
    const queryParams = new URLSearchParams(location.search);
    const selector = useAppSelector((combinedState) => combinedState);
    const { projectReducer, authReducer } = selector;
    const params = useParams<Params>();
    const { isOpen, onClose, onOpen } = useModalState();
    const { onOpen: openWarning, onClose: closeWarning, isOpen: isWaringOpen } = useModalState();
    const {
        onOpen: openUpgradeAccount,
        onClose: closeUpgradeAccount,
        isOpen: isUpgradeAccountOpen,
    } = useModalState();
    const [existingProducts, setExistingProducts] = useState({
        current: Array<{ id: number; name: string }>(),
        projectId: 0,
    });

    const [state, setState] = useState({
        [LibraryType.YourProjects]: {
            projects: Array<Project>(),
            totalCount: 0,
            tableData: initialTableData,
        },
        [LibraryType.AllProjects]: {
            projects: Array<Project>(),
            totalCount: 0,
            tableData: initialTableData,
        },
        [LibraryType.Completed]: {
            projects: Array<Project>(),
            totalCount: 0,
            tableData: initialTableData,
        },
        [LibraryType.Template]: {
            projects: Array<Project>(),
            totalCount: 0,
            tableData: initialTableData,
        },
        [LibraryType.ManufacturerTemplate]: {
            projects: Array<Project>(),
            totalCount: 0,
            tableData: initialTableData,
        },
    });

    const [totalCount, setTotalCount] = useState({
        yourProjectsTotalCount: 0,
        allProjectsTotalCount: 0,
        completedTotalCount: 0,
        templateTotalCount: 0,
        manufacturerTemplateTotalCount: 0,
    });

    const [currentLibrary, setCurrentLibrary] = useState(LibraryType.YourProjects);
    useEffect(() => {
        dispatch(
            addPages([
                {
                    key: NavigationKey.DASHBOARD,
                    value: `${authReducer.user?.firstName}’s Dashboard`,
                },
                {
                    key: NavigationKey.PROJECT_LIBRARY,
                    value: `${authReducer.user?.organization.name} Projects`,
                },
            ]),
        );
        dispatch(setAddNewProjectMark(false));
        return () => {
            dispatch(removePage());
        };
    }, [projectReducer.addedNewProject]);

    const [locationKeys, setLocationKeys] = useState<string | undefined>(undefined);

    useEffect(() => {
        const activeTab = queryParams.get('tab') || '1';
        setLocationKeys(activeTab);
    }, [queryParams.get('tab')]);

    useEffect(() => {
        if (projectReducer.completedProjectId) {
            setTimeout(async () => {
                const completedCount = await getProjectsAfterSwitchStatus(LibraryType.Completed);
                if (currentLibrary === LibraryType.YourProjects) {
                    const allCount = await getProjectsAfterSwitchStatus(LibraryType.AllProjects);
                    setTotalCount({
                        ...totalCount,
                        completedTotalCount: completedCount,
                        allProjectsTotalCount: allCount,
                        yourProjectsTotalCount: totalCount.yourProjectsTotalCount - 1,
                    });
                } else {
                    const yourCount = await getProjectsAfterSwitchStatus(LibraryType.YourProjects);
                    setTotalCount({
                        ...totalCount,
                        completedTotalCount: completedCount,
                        yourProjectsTotalCount: yourCount,
                        allProjectsTotalCount: totalCount.allProjectsTotalCount - 1,
                    });
                }
            }, 500);
            dispatch(setCompletedProject(null));
        }
        if (projectReducer.activatedProjectId) {
            setTimeout(async () => {
                const yourCount = await getProjectsAfterSwitchStatus(LibraryType.YourProjects);
                const allCount = await getProjectsAfterSwitchStatus(LibraryType.AllProjects);
                setTotalCount({
                    ...totalCount,
                    yourProjectsTotalCount: yourCount,
                    allProjectsTotalCount: allCount,
                    completedTotalCount: totalCount.completedTotalCount - 1,
                });
            }, 500);
            dispatch(setActivatedProject(null));
        }
        if (projectReducer.deletedProjectId) {
            setTimeout(async () => {
                if (currentLibrary === LibraryType.YourProjects) {
                    const allCount = await getProjectsAfterSwitchStatus(LibraryType.AllProjects);
                    setTotalCount({
                        ...totalCount,
                        allProjectsTotalCount: allCount,
                        yourProjectsTotalCount: totalCount.yourProjectsTotalCount - 1,
                    });
                } else {
                    const yourCount = await getProjectsAfterSwitchStatus(LibraryType.YourProjects);
                    setTotalCount({
                        ...totalCount,
                        yourProjectsTotalCount: yourCount,
                        allProjectsTotalCount: totalCount.allProjectsTotalCount - 1,
                    });
                }
                dispatch(setDeletedProject(null));
            }, 500);
            dispatch(setActivatedProject(null));
        }
        if (projectReducer.templateProjectId) {
            setTimeout(async () => {
                await getProjectsAfterSwitchStatus(LibraryType.Template);
                setTotalCount({
                    ...totalCount,
                    templateTotalCount: totalCount.templateTotalCount + 1,
                });
            }, 500);
            dispatch(setTemplateProject(null));
        }
    }, [
        projectReducer.completedProjectId,
        projectReducer.activatedProjectId,
        projectReducer.deletedProjectId,
        projectReducer.templateProjectId,
    ]);

    useEffect(() => {
        if (params.packageId && params.packageId.length > 1) {
            window.history.replaceState(null, '', `/project-library`);
            if (authReducer.user?.subscriptionPlanName === SubscriptionTypes.FREE) {
                openUpgradeAccount();
            } else {
                onOpen();
            }
        }
    }, []);

    const getProjectsAfterSwitchStatus = async (projectType: LibraryType) => {
        const { payload } = (await dispatch(
            getProjectLibraryAction({
                ...state[projectType].tableData,
                status: LibraryType[projectType],
            }),
        )) as any;
        if (payload.projects) {
            const project = state[projectType];
            project.projects = payload.projects;
            project.tableData = state[projectType].tableData;
            setState({ ...state, [projectType]: project });
        }
        return payload.meta.currentCount;
    };

    const getProjects = async (
        projectType: LibraryType,
        tableData: TableData,
        isInitial: boolean,
        countType?: ProjectLibraryCountType,
    ) => {
        const { payload } = (await dispatch(
            getProjectLibraryAction({ ...tableData, status: LibraryType[projectType] }),
        )) as any;
        if (payload.projects) {
            const project = state[projectType];
            project.projects = payload.projects;
            project.tableData = tableData;
            setState({ ...state, [projectType]: project });
        }
        if (payload.meta) {
            if (countType && !isInitial) {
                setTotalCount({ ...totalCount, [countType]: payload.meta.currentCount });
            } else {
                setTotalCount({
                    yourProjectsTotalCount: payload.meta.yourProjectsTotalCount,
                    allProjectsTotalCount: payload.meta.allProjectsTotalCount,
                    completedTotalCount: payload.meta.completedTotalCount,
                    templateTotalCount: payload.meta.templateTotalCount,
                    manufacturerTemplateTotalCount: payload.meta.manufacturerTemplateTotalCount,
                });
            }
        }
    };

    const yourTableProps = {
        library: {
            ...state[LibraryType.YourProjects],
            totalCount: totalCount.yourProjectsTotalCount,
        },
        getProjects: getProjects,
        additionalItems: [],
        libraryType: LibraryType.YourProjects,
        pending: projectReducer.pending,
        countType: ProjectLibraryCountType.YourProjects,
        projectId: params.projectId,
        columns: columns,
    };

    const allTableProps = {
        library: {
            ...state[LibraryType.AllProjects],
            totalCount: totalCount.allProjectsTotalCount,
        },
        getProjects: getProjects,
        additionalItems: [],
        libraryType: LibraryType.AllProjects,
        pending: projectReducer.pending,
        countType: ProjectLibraryCountType.AllProjects,
        projectId: params.projectId,
        columns: columns,
    };

    const completedTableProps = {
        library: { ...state[LibraryType.Completed], totalCount: totalCount.completedTotalCount },
        getProjects: getProjects,
        additionalItems: [],
        libraryType: LibraryType.Completed,
        pending: projectReducer.pending,
        countType: ProjectLibraryCountType.Completed,
        projectId: params.projectId,
        columns: columns,
    };

    const templateTableProps = {
        library: { ...state[LibraryType.Template], totalCount: totalCount.templateTotalCount },
        getProjects: getProjects,
        additionalItems: [],
        libraryType: LibraryType.Template,
        pending: projectReducer.pending,
        countType: ProjectLibraryCountType.Template,
        projectId: params.projectId,
        columns: columns,
    };

    const mfrTemplateTableProps = {
        library: {
            ...state[LibraryType.ManufacturerTemplate],
            totalCount: totalCount.manufacturerTemplateTotalCount,
        },
        getProjects: getProjects,
        additionalItems: [],
        libraryType: LibraryType.ManufacturerTemplate,
        pending: projectReducer.pending,
        countType: ProjectLibraryCountType.MFRTemplates,
        projectId: params.projectId,
        columns: manufacturerTemplateColumns,
    };

    const tabItems = [
        {
            name: `Your projects (${totalCount.yourProjectsTotalCount})`,
            key: LibraryType.YourProjects.toString(),
            tab: <ProjectsTable {...yourTableProps} />,
        },
        {
            name: `All projects (${totalCount.allProjectsTotalCount})`,
            key: LibraryType.AllProjects.toString(),
            tab: <ProjectsTable {...allTableProps} />,
        },
        {
            name: `Completed (${totalCount.completedTotalCount})`,
            key: LibraryType.Completed.toString(),
            tab: <ProjectsTable {...completedTableProps} />,
        },
        {
            name: `Template (${totalCount.templateTotalCount})`,
            key: LibraryType.Template.toString(),
            tab: <ProjectsTable {...templateTableProps} />,
        },
        {
            name: `Manufacturer templates (${totalCount.manufacturerTemplateTotalCount})`,
            key: LibraryType.ManufacturerTemplate.toString(),
            tab: <ProjectsTable {...mfrTemplateTableProps} />,
        },
    ];

    const handleTabsChange = (key: string) => {
        setCurrentLibrary(+key);
        setLocationKeys(key);
        historyState.current = [...historyState.current, key];
        history.push(`${window.location.pathname}?tab=${key}`);
    };

    const getProductIdsForProject = async (projectId: number) => {
        if (params.packageId) {
            const { payload } = (await dispatch(
                getProductIdsByPackageIdAction({
                    productPackageID: params.packageId,
                }),
            )) as GetProductIdsByPackageIdResponse;
            if (payload.result) {
                addProductsToProject(
                    projectId,
                    payload.result.map((r) => r.productSelectedOnTcId),
                );
            }
        }
    };

    const addProductsToProject = async (projectId: number, productIds: Array<number>) => {
        const { payload } = (await dispatch(
            addProductsToProjectAction({ products: productIds, projectId: projectId }),
        )) as ActionResponse<AddProductsToProjectResponse>;
        if (payload.data) {
            if (payload.data.existingProducts && payload.data.existingProducts.length) {
                getExistingProductsDetails(projectId, payload.data.existingProducts);
            } else {
                history.push(`/project-info/${projectId}/0/2`);
            }
        }
    };

    const getExistingProductsDetails = async (projectId: number, productIds: Array<number>) => {
        const { payload } = (await dispatch(
            getExternalProductsAction({
                productIds,
            }),
        )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;
        if (payload.data) {
            const products = Object.values(payload.data.mfSection).reduce(
                (agg, item: any) => agg.concat(item),
                Array<ExternalProduct>(),
            );
            setExistingProducts({
                ...existingProducts,
                current: products.map((product) => ({ name: product.productName, id: product.id })),
                projectId,
            });
            openWarning();
        }
    };

    const goToProducts = () => {
        closeWarning();
        history.push(`/project-info/${existingProducts.projectId}/0/2`);
    };

    return (
        <>
            <PageHeader title={`${authReducer.user?.organization.name} Projects`} />
            <ProjectLibraryContainer>
                <Col span={24}>
                    <CustomTabs
                        onTabChange={handleTabsChange}
                        tabs={tabItems}
                        defaultKey={queryParams.get('tab') || '0'}
                        activeKey={locationKeys}
                    />
                </Col>
            </ProjectLibraryContainer>
            {isOpen && (
                <StartProject
                    isOpen={isOpen}
                    handleCancel={onClose}
                    showProjects
                    fromFavorite
                    addProductsToProject={getProductIdsForProject}
                />
            )}
            {isWaringOpen && (
                <ExistingProductsModal
                    isOpen={isWaringOpen}
                    closeModal={goToProducts}
                    existingProducts={existingProducts.current}
                    addedFrom={'Transparency Catalog'}
                    addedTo={'project'}
                    currentPage="/project-library"
                />
            )}
            {isUpgradeAccountOpen && (
                <SuggestUpgradeAccountModal
                    isOpen={isUpgradeAccountOpen}
                    closeModal={closeUpgradeAccount}
                />
            )}
        </>
    );
};

export default ProjectLibrary;
