import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { getSubscriptionAction } from '../../actions/subscriptionActions';

export interface SubscriptionReducerType {
    selectedSubscriptionId: number | undefined;
    selectedSubscriptionPlanName: SubscriptionTypes | undefined;
    pending: boolean;
    isTrial: boolean;
    usersCount: number;
    isSubscriptionUpdating: boolean;
}
const initialState: SubscriptionReducerType = {
    selectedSubscriptionId: undefined,
    pending: false,
    selectedSubscriptionPlanName: undefined,
    isTrial: false,
    usersCount: 0,
    isSubscriptionUpdating: false,
};

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: initialState,
    reducers: {
        chooseSubscription(
            state,
            action: PayloadAction<{
                planId: number;
                planName: SubscriptionTypes;
                usersCount: number;
                isTrial: boolean;
            }>,
        ) {
            state.selectedSubscriptionId = action.payload.planId;
            state.selectedSubscriptionPlanName = action.payload.planName;
            state.usersCount = action.payload.usersCount;
            state.isTrial = action.payload.isTrial;
        },
        clearSubscription(state) {
            state.selectedSubscriptionId = undefined;
        },
        setNewSubscription(
            state,
            action: PayloadAction<{ planName: SubscriptionTypes; planId?: number }>,
        ) {
            state.selectedSubscriptionPlanName = action.payload.planName;
            state.selectedSubscriptionId = action.payload.planId;
        },
        setSubscriptionUpdating(state, action: PayloadAction<boolean>) {
            state.isSubscriptionUpdating = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionAction.fulfilled, (state) => {
                state.pending = false;
            })
            .addCase(getSubscriptionAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(getSubscriptionAction.rejected, (state) => {
                state.pending = false;
            });
    },
});

export const { clearSubscription, setNewSubscription, setSubscriptionUpdating } =
    subscriptionSlice.actions;

export default subscriptionSlice.reducer;
