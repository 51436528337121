import styled from 'styled-components';
import { blueLink } from '../../../../styles/colors';
import { Text } from '../../../../styles/common.styles';

export const SuccessExportModalText = styled(Text)`
    margin-bottom: 28px;
    a {
        color: ${blueLink};
    }
`;
