import React from 'react';
import { MaterialProgramTipContainer, MaterialProgramTipTitle } from './MaterialProgramTip.styles';
import * as Tooltip from '../Tooltip';

export const Container: React.FC<{ children: React.ReactNode, spanset: boolean }> = ({ children, spanset }) => {
    return (
        <Tooltip.Container spanset={spanset}>
            <MaterialProgramTipContainer className="program-tip">
                <div>{children}</div>
            </MaterialProgramTipContainer>
        </Tooltip.Container>
    );
};

export const Title: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <MaterialProgramTipTitle>{children}</MaterialProgramTipTitle>;
};
