import React, { useEffect, useState } from 'react';
import { DetailsPageSpin } from './SMAdminDetails.styles';
import PageHeader from '../../../components/common/pageHeader/PageHeader';
import { addPage, removePage } from '../../../redux/reducers/navigationReducer/NavigationSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useHistory, useParams } from 'react-router-dom';
import { ActionResponse } from '../../../models/actionResponse/types';
import { getUserByIdAction } from '../../../redux/actions/userActions';
import { UserByIdResponse } from '../../../redux/actions/userActions/types';
import CustomTabs from '../../../components/common/tabs/CustomTabs';
import { BasicInformation } from '../../components/common/basicInformation/BasicInformation';

interface EditParams {
    id: string;
}

const SMAdminDetails: React.FC = () => {
    const { id } = useParams<EditParams>();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const selector = useAppSelector((state) => state);
    const { authReducer } = selector;

    const [user, setUser] = useState<UserByIdResponse | undefined>(undefined);

    useEffect(() => {
        getUser();
        return () => {
            dispatch(removePage());
        };
    }, []);

    const getUser = async () => {
        const { payload } = (await dispatch(
            getUserByIdAction(id),
        )) as ActionResponse<UserByIdResponse>;
        if (payload?.data[0]) {
            const userFromPayload = payload?.data[0];
            setUser(userFromPayload);
            dispatch(
                addPage({
                    key: `/admins/edit/${userFromPayload.id}`,
                    value: `${userFromPayload.firstName} ${userFromPayload.lastName}`,
                }),
            );
        } else {
            history.push('/admins');
        }
    };

    const tabs = [
        {
            name: 'Basic information',
            tab: <BasicInformation user={user} />,
            key: '1',
        },
    ];

    return (
        <>
            {authReducer.user?.id !== user?.id ? (
                <PageHeader title={`${user?.firstName} ${user?.lastName}`} showMenu={false} />
            ) : (
                <PageHeader
                    title={`${authReducer.user?.firstName} ${authReducer.user?.lastName}’s account`}
                />
            )}
            <DetailsPageSpin size="large" spinning={authReducer.pending}>
                <div>
                    <CustomTabs tabs={tabs} defaultKey={'1'} />
                </div>
            </DetailsPageSpin>
        </>
    );
};

export default SMAdminDetails;
