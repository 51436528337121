import React from 'react';
import { Dropdown, Image, Space } from 'antd';
import { CustomColumnType } from '../../common/table/CustomTable.types';
import { ProjectTableFields } from '../../../models/project/types';
import { toShortLocalDate } from '../../../helpers/dateHelper';
import {
    DotsAction,
    OpenMenuButton,
    ProjectLibraryTableCellContainer,
    ProjectNameContainer,
    ProjectTableClickableRow,
    TableImageContainer,
    ConfidentialTipContainer,
} from './ProjectsTable.styles';
import { NavLink } from 'react-router-dom';
import { LibraryType } from '../../../models/project/enums';
import { getAddProductsFromProject, getProjectActions } from './ProjectTable.helper';

interface Props {
    handleActions: (action: string, project: ProjectTableFields, projectId?: string) => void;
    project: ProjectTableFields;
    libraryType: LibraryType;
    projectId?: string;
    disabled: boolean
}
export const ProjectRowActions: React.FC<Props> = ({ handleActions, project, projectId, disabled }) => {
    const [open, setOpen] = React.useState(false);

    const submitAction = (data: { key: string }) => {
        handleActions(data.key, project);
    };

    const handleMenuOpen = () => {
        setOpen(!open);
    };

    return (
        <Dropdown
            menu={{
                items: projectId ? getAddProductsFromProject() : getProjectActions(project),
                onClick: submitAction,
            }}
            overlayClassName="projects-table-columns-dropdown"
            placement="bottom"
            trigger={['click']}
            onOpenChange={handleMenuOpen}
            disabled={disabled}
        >
            <div>
                <OpenMenuButton>
                    <DotsAction></DotsAction>
                </OpenMenuButton>
            </div>
        </Dropdown>
    );
};

export const columns = (
    handleActions: (action: string, project: ProjectTableFields) => void,
    libraryType: LibraryType,
    projectId?: string,
    showActions?: boolean
): CustomColumnType<ProjectTableFields>[] => showActions ? [
    {
        title: 'Project',
        sorter: true,
        clickable: false,
        showSorterTooltip: false,
        dataIndex: 'name',
        className: 'project-name-wrapper',
        render: (value, data: ProjectTableFields) => (
            <div className="project-name">
                <TableImageContainer className={data.imageFilePath ? '' : 'default-image'}>
                    {!data.imageFilePath ? (
                        <>
                            <Image
                                src="src/assets/images/image.svg"
                                width={24}
                                height={21}
                                preview={false}
                            />
                            <Image
                                src="src/assets/images/table-image.png"
                                width={96}
                                height={54}
                                preview={false}
                            />
                        </>
                    ) : (
                        <Image
                            src={`${process.env.REACT_APP_API_URL}/static/${data.imageFilePath}`}
                            width={96}
                            height={54}
                            preview={false}
                        />
                    )}
                </TableImageContainer>
                <ProjectNameContainer>
                    <div>
                        <NavLink to={`/project-info/${data.id}`}>
                            <ProjectTableClickableRow className="project-name">
                                {value}
                            </ProjectTableClickableRow>
                        </NavLink>
                    </div>
                    <Space>
                        {data.projectLibraryVisibilityId === 2 && !data.isTemplate && (
                                <ConfidentialTipContainer>
                                    <Image
                                        src="src/assets/images/eye-slash.svg"
                                        width={13}
                                        height={12}
                                        preview={false}
                                    />
                                    <div className="tip" style={{width:270+'px'}}>
                                        Private Project: visible only to you and admins.
                                    </div>
                                </ConfidentialTipContainer>
                        )}
                        {data.isConfidential && !data.isTemplate && (
                                <ConfidentialTipContainer>
                                    <Image
                                        src="src/assets/images/lock-keyhole.svg"
                                        width={11}
                                        height={12}
                                        preview={false}
                                    />
                                    <div className="tip" style={{width:460+'px'}}>
                                        Confidential Project: name, client and location hidden from manufacturer analytics.
                                    </div>
                                </ConfidentialTipContainer>
                        )}
                    </Space>
                </ProjectNameContainer>
            </div>
        ),
    },
    {
        title: 'Project ID',
        dataIndex: 'externalProjectId',
        sorter: true,
        clickable: true,
        render: (value) => (
            <ProjectLibraryTableCellContainer>
                <div>{value}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'Project / building type',
        dataIndex: 'buildingTypeName',
        sorter: true,
        clickable: true,
        render: (value) => (
            <ProjectLibraryTableCellContainer>
                <div>{value}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'Status',
        dataIndex: 'projectStatusName',
        sorter: true,
        clickable: true,
        render: (value) => (
            <ProjectLibraryTableCellContainer>
                <div>{value}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'Last modified',
        dataIndex: 'modifiedOn',
        sorter: true,
        clickable: true,
        render: (value) => (
            <ProjectLibraryTableCellContainer>
                <div>{toShortLocalDate(value)}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'Created',
        dataIndex: 'createdOn',
        sorter: true,
        clickable: true,
        render: (value) => (
            <ProjectLibraryTableCellContainer>
                <div>{toShortLocalDate(value)}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'Client/ Owner',
        dataIndex: 'clientOwner',
        sorter: true,
        clickable: true,
        render: (value) => (
            <ProjectLibraryTableCellContainer>
                <div>{value}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'Office',
        dataIndex: 'officeName',
        sorter: true,
        clickable: true,
        render: (value) => (
            <ProjectLibraryTableCellContainer>
                <div>{value}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'ACTIONS',
        sorter: false,
        render: (data: ProjectTableFields) =>
            projectId?.toString() !== data.id.toString() ? (
                <ProjectRowActions
                    handleActions={handleActions}
                    project={data}
                    libraryType={libraryType}
                    projectId={projectId}
                    disabled={!showActions}
                />
            ) : (
                <></>
            ),
        width: 20,
    }
] : [
        {
            title: 'Project',
            sorter: true,
            clickable: false,
            showSorterTooltip: false,
            dataIndex: 'name',
            className: 'project-name-wrapper',
            render: (value, data: ProjectTableFields) => (
                <div className="project-name">
                    <TableImageContainer className={data.imageFilePath ? '' : 'default-image'}>
                        {!data.imageFilePath ? (
                            <>
                                <Image
                                    src="src/assets/images/image.svg"
                                    width={24}
                                    height={21}
                                    preview={false}
                                />
                                <Image
                                    src="src/assets/images/table-image.png"
                                    width={96}
                                    height={54}
                                    preview={false}
                                />
                            </>
                        ) : (
                            <Image
                                src={`${process.env.REACT_APP_API_URL}/static/${data.imageFilePath}`}
                                width={96}
                                height={54}
                                preview={false}
                            />
                        )}
                    </TableImageContainer>
                    <ProjectNameContainer>
                        <div>
                            <NavLink to={`/project-info/${data.id}`}>
                                <ProjectTableClickableRow className="project-name">
                                    {value}
                                </ProjectTableClickableRow>
                            </NavLink>
                        </div>
                        <Space>
                            {data.projectLibraryVisibilityId === 2 && !data.isTemplate && (
                                <Image
                                    src="src/assets/images/eye-slash.svg"
                                    width={13}
                                    height={12}
                                    preview={false}
                                />
                            )}
                            {data.isConfidential && !data.isTemplate && (
                                <Image
                                    src="src/assets/images/lock-keyhole.svg"
                                    width={11}
                                    height={12}
                                    preview={false}
                                />
                            )}
                        </Space>
                    </ProjectNameContainer>
                </div>
            ),
        },
        {
            title: 'Project / building type',
            dataIndex: 'buildingTypeName',
            sorter: true,
            clickable: true,
            render: (value) => (
                <ProjectLibraryTableCellContainer>
                    <div>{value}</div>
                </ProjectLibraryTableCellContainer>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'projectStatusName',
            sorter: true,
            clickable: true,
            render: (value) => (
                <ProjectLibraryTableCellContainer>
                    <div>{value}</div>
                </ProjectLibraryTableCellContainer>
            ),
        },
        {
            title: 'Last modified',
            dataIndex: 'modifiedOn',
            sorter: true,
            clickable: true,
            render: (value) => (
                <ProjectLibraryTableCellContainer>
                    <div>{toShortLocalDate(value)}</div>
                </ProjectLibraryTableCellContainer>
            ),
        },
        {
            title: 'Created',
            dataIndex: 'createdOn',
            sorter: true,
            clickable: true,
            render: (value) => (
                <ProjectLibraryTableCellContainer>
                    <div>{toShortLocalDate(value)}</div>
                </ProjectLibraryTableCellContainer>
            ),
        },
        {
            title: 'Client/ Owner',
            dataIndex: 'clientOwner',
            sorter: true,
            clickable: true,
            render: (value) => (
                <ProjectLibraryTableCellContainer>
                    <div>{value}</div>
                </ProjectLibraryTableCellContainer>
            ),
        },
        {
            title: 'Office',
            dataIndex: 'officeName',
            sorter: true,
            clickable: true,
            render: (value) => (
                <ProjectLibraryTableCellContainer>
                    <div>{value}</div>
                </ProjectLibraryTableCellContainer>
            ),
        }
    ]
