import { Modal, Space } from 'antd';
import styled from 'styled-components';
import { blue01, blueDark01, grayDark05, grayDark06 } from '../../../../styles/colors';

export const CopyProjectModal = styled(Modal)`
    width: 680px !important;
    height: 324px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    .ant-input:hover {
        border-color: ${grayDark05};
        border-radius: 3px;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: ${blue01};
        border-radius: 3px;
        box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
    }

    .ant-checkbox-wrapper:hover > .ant-checkbox > .ant-checkbox-inner,
    .ant-checkbox:hover > .ant-checkbox-inner {
        border-color: ${grayDark05};
    }

    .ant-checkbox > .ant-checkbox-inner {
        border: 1px solid ${grayDark06};
        border-radius: 3px;
    }

    .ant-checkbox-checked > .ant-checkbox-inner {
        background-color: ${blue01};
        border-color: ${blue01};
        border-radius: 3px;
    }

    .ant-checkbox:hover::after,
    .ant-checkbox-wrapper:hover .ant-checkbox::after {
        border: none;
    }

    .ant-divider-horizontal {
        margin-top: 29px;
        margin-bottom: 20px;
    }
`;

export const CopyProjectSpace = styled(Space)`
    margin-top: 28px;
    align-items: flex-end;

    width: 100%;
    gap: 20px !important;

    .ant-input {
        padding: 7px 10px 7px 10px;
        border-radius: 3px;
        font-size: 13px;
        line-height: 16px;
        width: 405px;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form-item-label > label {
        height: 16px;
        vertical-align: unset;
        margin-bottom: 4px;
        line-height: 16px !important;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        color: ${blueDark01} !important;

        &::before {
            margin: 0 !important;
        }

        &::after {
            position: unset;
            top: 0;
            margin: 0;
        }
    }
`;

export const CopyProjectCol = styled('div')`
    display: flex;
    gap: 20px;

    .ant-space-item:first-child {
        height: 32px;
        margin: 0;
        line-height: unset;

        .ant-checkbox {
            top: 7px;
        }
    }
`;

export const CopyCheckboxSpace = styled(Space)`
    margin-top: 0px;
    height: 32px;
`;
