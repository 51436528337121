import { Button } from 'antd';
import styled from 'styled-components';
import { grayLight05, blue01, error, blueLink, white, grayDark01 } from '../../../../styles/colors';

export const PromoBlockContainer = styled('div')`
    width: 80%;
    display: flex;
    flex-direction: column;

    .input-block .ant-form-item-row,
    .ant-row {
        display: flex !important;
        justify-content: end;
    }

    .ant-form-horizontal .ant-form-item-control {
        max-width: 350px !important;
    }

    .ant-form-item {
        margin-bottom: 10px;
    }

    .rdw-inline-wrapper {
        flex: 1;
    }

    .rdw-image-wrapper {
        flex: 0;
    }

    .rdw-link-wrapper {
        flex: 2;
    }

    .rdw-option-wrapper {
        margin: 0px 8px;
        cursor: pointer;
    }
    .button-container {
        label {
            display: none;
        }
    }
`;

export const CustomButton = styled(Button)`
    padding: 4px 8px;
    border-radius: 5px;
    background-color: ${grayLight05};
    color: ${blue01};
`;

export const ReactQuillContainer = styled('div')`
    margin-bottom: 10px;
    .ql-container {
        height: 252px;
    }
    .ql-container.ql-snow {
        border: 1px solid #d9d9d9;
    }
    .ql-toolbar.ql-snow {
        border: 1px solid #d9d9d9;
        padding: 0;
    }
    .ql-editor {
        height: 250px;
        a {
            color: ${blueLink};
            text-decoration: none;
        }
    }
    .ql-snow .ql-tooltip {
        z-index: 1;
    }
    &.error {
        .ql-container.ql-snow {
            border: 1px solid ${error};
            border-top: 1px solid ${error};
        }
        .ql-toolbar.ql-snow {
            border-bottom: 1px solid ${error};
        }
    }
    .ql-formats {
        padding: 5px 0;
    }
    .ql-formats:not(:last-child) {
        border-right: 1px solid #d9d9d9;
    }
    .ql-toolbar.ql-snow .ql-formats {
        margin-right: 5px;
    }
`;

export const LinkTargetContainer = styled('div')`
    margin-top: 13px;
    display: flex;
    align-items: center;
    gap: 16px;
    .ant-radio-inner::after {
        background-color: ${white};
    }
    .ant-radio-checked .ant-radio-inner {
        background-color: ${blue01};
    }
    .ant-radio-wrapper {
        color: ${grayDark01};
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }
`;
