import { Space, Spin } from 'antd';
import styled from 'styled-components';
import { blue01, grayDark05, grayLight01, white, black } from '../../../../styles/colors';
import { SpaceBetween } from '../../../../styles/common.styles';
import { Text } from '../../../../styles/common.styles';

import { InvisibleButton } from '../../../../styles/common.styles';

export const ProductsTabHeader = styled(SpaceBetween)`
    padding: 40px 0;
    position: sticky;
    background-color: ${white};
    z-index: 1;
    top: 0;
`;
export const EC3Link = styled(Space)``;
export const ProductsTabContent = styled('div')`
    min-height: calc(100vh - 12rem);
`;

export const ProductSpin = styled(Spin)`
    top: 0 !important;
`;

export const NoProductsTitleContainer = styled('div')`
    height: calc(50vh - 16rem);
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
        font-size: 19px;
        line-height: 27px;
        color: ${blue01};
        font-family: 'DIN Next W01 Medium';
    }
`;

export const NoProductsTitleContainerFiltered = styled(NoProductsTitleContainer)`
    height: calc(50vh - 10rem);
`;

export const DivisionsContainer = styled('div')`
    border-top: 1px solid ${grayLight01};
    border-bottom: 0.5px solid ${grayLight01};
    &.clicked {
        border-top: 1px solid ${grayDark05};
    }
`;

export const ProductsTabPageContainer = styled('div')`
    max-width: 2000px;
    padding: 0px 100px 60px 100px;
    min-width: 1224px;
`;

export const OpenCloseAllButton = styled(InvisibleButton)`
    align-items: flex-start;
    margin-top: 2px;
    &.closeOpenAll {
        margin-top: 16px;
    }
`;

export const ViewProjectInEC3Button = styled(InvisibleButton)`
    &:empty {
        display: block;
    }
`;

export const FoundResultsCount = styled(Text)`
    font-family: 'DINNextLTPro';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: ${black};
    padding-bottom: 30px; 
   
`;

