import * as React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import AlertDialog from './components/alert/Alert';
import Header from './components/header/Header';
import LoginPage from './pages/login/LoginPage';
import AppNavigation from './components/appNavigationBar/AppNavigationBar';
import SubscriptionPage from './pages/subscription/Subscription';
import NotFoundPage from './pages/notFound/NotFoundPage';
import ConfirmationEmail from './pages/confirmationEmail/ConfirmationEmail';
import ResetPassword from './pages/resetPassword/ResetPassword';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import Success from './components/paymentForm/Success';
import ManualConfirmEmail from './components/Development/ManualConfirmEmail';
import Footer from './components/footer/Footer';
import ConfirmInvitation from './pages/confirmInvitation/ConfirmInvitation';
import Version from './components/Development/Version';
import SignUpPage from './pages/signUp/SignUp';
import ProductsPage from './pages/products/Products';
import { usePermissions } from './hooks/permissions';
import CustomerRoutes from './routes/CustomerRoutes';
import { UserRoleTypes } from './models/permission/enums';
import SMAdminRoutes from './routes/SMAdminRoutes';
import CreatePassword from './pages/createPassword/CreatePassword';
import { loadSpace } from '@usersnap/browser';
import { RouteName } from './routes/Routes.types';
let usersnapInit = false;

const App: React.FC = () => {
    const { pathname } = useLocation();
    const { getUserRole } = usePermissions();
    const { getUserEmail } = usePermissions();
    const pagesWithoutHeader = ['/products', '/frame'];
    const pagesWithoutFooter = ['/products', '/promo', '/frame'];
    const hideHeader = !!pagesWithoutHeader.find((p) => pathname.includes(p));
    const hideFooter = !!pagesWithoutFooter.find((p) => pathname.includes(p));

    if (getUserRole() === UserRoleTypes.SM_USER) {
        const initParams = {
            user: {
                email: getUserEmail(),
            },
        };
        const spaceApiKey = 'ae57748f-6385-4c3b-a2d6-42ae22a90c57';
        loadSpace(spaceApiKey).then((api) => {
            if (!usersnapInit) {
                usersnapInit = true;
                api.init(initParams);
            }
        });
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        console.log = () => {};
    }
    return (
        <>
            <div className="app-container">
                {!hideHeader && <Header />}
                {!hideHeader && <AppNavigation />}
                <AlertDialog />
                <Switch>
                    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                    <Route path={RouteName.SUBSCRIPTION} component={SubscriptionPage} />
                    <Route path={RouteName.CONFIRM_EMAIL} component={ConfirmationEmail} />
                    <Route path={RouteName.CONFIRM_INVITATION} component={ConfirmInvitation} />
                    <Route path={RouteName.FORGOT_PASSWORD} component={ForgotPassword} />
                    <Route path={RouteName.RESTORE_PASSWORD} component={ResetPassword} />
                    <Route path={RouteName.LOGIN} component={LoginPage} />
                    <Route path={RouteName.NOT_FOUND_PAGE} component={NotFoundPage} />
                    <Route path={RouteName.SUCCESS_PAYMENT} component={Success} />
                    <Route path={RouteName.SIGN_UP} component={SignUpPage} />
                    <Route path={RouteName.VERSION} component={Version} />
                    <Route exact path={RouteName.SEARCH_PRODUCT} component={ProductsPage} />
                    <Route exact path={RouteName.CREATE_NEW_PASSWORD} component={CreatePassword} />
                    {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                        <Route path="/confirm" component={ManualConfirmEmail} />
                    )}
                    {(getUserRole() === UserRoleTypes.SM_USER || !getUserRole()) && (
                        <CustomerRoutes />
                    )}
                    {getUserRole() === UserRoleTypes.SM_ADMIN && <SMAdminRoutes />}
                </Switch>
                {!hideFooter && <Footer />}
            </div>
        </>
    );
};

export default App;
