import {
    blue,
    blue01,
    blue02,
    blueDark01,
    blueDark02,
    blueDark03,
    blueLink,
    error,
    teal01,
    white,
} from './colors';
import styled from 'styled-components';
import { Button, Space, Tabs, Typography, Spin } from 'antd';
import { TextProps, ButtonProps } from '../models/styles/types';

export const PageTabs = styled(Tabs)`
    .ant-tabs-tab-btn {
        transition: unset;
    }
    .ant-tabs-nav-wrap {
        background-color: ${blueDark02};
        padding-left: 100px;
        .ant-tabs-tab {
            background-color: ${blueDark02};
            border-radius: 4px 4px 0 0 !important;
            border: none !important;
            color: ${teal01};
            margin: 0 10px 0 0 !important;
            padding: 7px 14px !important;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            height: 32px;
            &:hover {
                background-color: ${blueDark03};
                color: ${white};
            }
            &:active {
                background-color: #fff;
                color: ${blueDark01};
                font-weight: normal;
                font-family: 'Proxima N W15 Bold';
                font-size: 14px;
                line-height: 18px;
                @-moz-document url-prefix() {
                    letter-spacing: -0.41px;
                }
            }
        }
        .ant-tabs-tab-btn:focus,
        .ant-tabs-tab-remove:focus {
            color: ${teal01};
        }
        .ant-tabs-tab-active {
            background-color: #fff !important;
            .ant-tabs-tab-btn {
                color: unset;
                color: ${blueDark01};
                font-weight: normal;
                font-family: 'Proxima N W15 Bold';
                font-size: 14px;
                line-height: 18px;
                text-shadow: unset;
                @-moz-document url-prefix() {
                    letter-spacing: -0.41px;
                }
            }
            .ant-tabs-tab-btn:focus,
            .ant-tabs-tab-remove:focus {
                color: ${blueDark01};
            }
        }
        .ant-tabs-tab-btn:active,
        .ant-tabs-tab-remove:active {
            color: unset;
            color: ${blueDark01};
            font-weight: normal;
            font-family: 'Proxima N W15 Bold';
            font-size: 14px;
            line-height: 18px;
            @-moz-document url-prefix() {
                letter-spacing: -0.41px;
            }
        }
    }
`;

export const PrimaryButton = styled(Button)`
    cursor: pointer;
    border-radius: 3px;
    background-color: ${blue01};
    color: #ffffff;
    border: none;
    font-family: 'DINNextLTPro';
    padding: 7px 14px;
    width: ${(props: ButtonProps) => props.width}px;
    height: ${(props: ButtonProps) => props.height}px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: ${blue02};
        color: #ffff;
    }
    &:focus {
        background-color: ${blue01};
        color: #ffffff;
    }
    &:disabled {
        opacity: 0.3;
        background-color: ${blue01};
        color: #ffffff;
        &:hover {
            background-color: ${blue01};
            color: #ffffff;
        }
    }
    &:active {
        background-color: ${blue};
    }
    span {
        margin-top: 2px;
    }
    &[ant-click-animating-without-extra-node='true']::after {
        display: none;
    }
`;

export const SecondaryButton = styled(Button)`
    border-radius: 3px;
    background-color: ${blueDark02};
    color: #ffffff;
    cursor: pointer;
    border: none;
    font-family: 'DINNextLTPro';
    padding: 7px 14px;
    width: ${(props: ButtonProps) => props.width}px;
    height: ${(props: ButtonProps) => props.height}px;
    line-height: 18px;
    align-items: center;
    display: flex;
    justify-content: center;
    &:hover {
        background-color: ${blueDark03};
        color: #ffff;
    }
    &:focus {
        background-color: ${blueDark01};
        color: #ffff;
    }
    &:active {
        background-color: ${blueDark01};
    }
    span {
        margin-top: 2px;
    }
    &[ant-click-animating-without-extra-node='true']::after {
        display: none;
    }
`;

export const ModalSubText = styled(Typography.Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark01};
    white-space: nowrap;
`;

export const InputLabel = styled(Typography.Text)`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 13px;
    line-height: 16px;
    color: ${blueDark01};
`;

export const ClickableRow = styled('span')`
    font-style: normal;
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 13px;
    line-height: 16px;
    color: ${blueLink};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const PageContainer = styled('div')`
    max-width: 2000px;
    padding: 40px 100px 60px 100px;
    @media screen and (max-width: 760px) {
        padding: 40px 30px 60px 30px;
    }
`;

export const TypographyText = styled('h3')`
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: ${blueDark01};
`;

export const SpaceCenter = styled(Space)`
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const SpaceEnd = styled(Space)`
    width: 100%;
    justify-content: flex-end;
`;
export const SpaceStart = styled(Space)`
    width: 100%;
    justify-content: start;
`;

export const SpaceBetween = styled(Space)`
    width: 100%;
    justify-content: space-between;
`;

export const BlueTitle = styled('h2')`
    color: ${blue01};
    font-style: normal;
    font-family: 'DINNextLTPro';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
`;

export const Text = styled('div')`
    font-weight: ${(props: TextProps) => props.weight};
    font-size: ${(props: TextProps) => props.size}px;
    line-height: ${(props: TextProps) => props.height}px;
    color: ${(props: TextProps) => props.color};
    font-family: ${(props: TextProps) => props.font};
    width: ${(props: TextProps) => (props.width ? `${props.width}px` : 'auto')};
    text-decoration-line: ${(props: TextProps) => (props.underline ? 'underline' : 'inherit')};
`;

export const BoldText = styled('div')`
    font-size: ${(props: TextProps) => props.size}px;
    line-height: ${(props: TextProps) => props.height}px;
    color: ${(props: TextProps) => props.color};
    width: ${(props: TextProps) => (props.width ? `${props.width}px` : 'auto')};
    text-decoration-line: ${(props: TextProps) => (props.underline ? 'underline' : 'inherit')};
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
`;

export const Margin = styled('div')`
    margin: ${(props: { value: string }) => props.value};
`;

export const InvisibleButton = styled(Button)`
    color: #ffff;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;
    &:hover {
        background-color: transparent;
        color: inherit;
    }
    &:focus {
        background-color: transparent;
        color: inherit;
    }
    &[ant-click-animating-without-extra-node='true']::after {
        display: none;
    }
`;

export const CloseIcon = styled('div')`
    display: block;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(src/assets/images/ic-modal-close-default.svg);
    &:hover {
        background-image: url(src/assets/images/ic-modal-close-hover.svg);
    }
    &:active {
        background-image: url(src/assets/images/ic-modal-close-pressed.svg);
    }
`;

export const ProductSwitcherContent = styled('div')`
    max-height: 0;
    display: none;
    opacity: 0;
    transition: max-height 600ms ease, opacity 1400ms ease;
    &.clicked {
        display: unset;
        max-height: 20000px;
        opacity: 1;
        transition: max-height 600ms ease, opacity 600ms ease;
    }
`;

export const ErrorMessage = styled('span')`
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${error};
    white-space: nowrap;
`;

export const TopSpin = styled(Spin)`
    top: 0% !important;
`;

export const MiddleSpin = styled(Spin)`
    top: 50% !important;
`;

export const InvisibleContainer = styled('div')`
    display: none;
`;

export const EmptyPendingPage = styled('div')`
    height: 200px;
`;

export const BaseLink = styled('a')`
    background-color: inherit;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    height: fit-content;
`;
