import { Select, Space, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
    blue01,
    blueDark02,
    blueLink,
    grayDark03,
    grayDark05,
    grayDark06,
    grayLight03,
} from '../../styles/colors';
import { ClickableRow, PrimaryButton, Text } from '../../styles/common.styles';

export const DashboardContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    gap: 60px;
    @media screen and (max-width: 860px) {
        display: block;
    }
`;

export const DashboardHeaderText = styled('div')`
    display: flex;
    line-height: unset;
    cursor: pointer;
    div {
        display: flex;
    }

    div:not(:last-child)::after {
        content: ' ';
        white-space: pre;
    }
`;

export const DashboardCaretDownContainer = styled('div')`
    .ant-image-img {
        width: 7px;
        height: 10px;
        margin-left: 4px;
    }
`;

export const DashboardLeft = styled('div')`
    width: 75%;
    @media screen and (max-width: 860px) {
        width: 100%;
    }

    .ant-space-align-center {
        display: flex;
    }

    .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-control-input {
            min-height: 28px;
        }

        .ant-select-selector {
            width: 192px;
            height: 28px;
            padding: 5px 4px 5px 8px;
            border-color: ${grayDark06};
            border-radius: 3px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            font-size: 13px;
            line-height: 16px;
        }

        .ant-select-arrow {
            height: 16px;
            right: 4px;
            vertical-align: unset;
            margin: 0;
            top: 6px;
            line-height: 16px;
        }

        .ant-select-selector:hover {
            border-color: ${grayDark05};
            border-radius: 3px;
        }

        .ant-select-selector:focus,
        .ant-select-selector-focused {
            border-color: ${blue01};
            border-radius: 3px;
            box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
        }
    }
`;

export const DashboardRight = styled('div')`
    width: 20%;
    @media screen and (max-width: 860px) {
        width: 100%;
    }
    .promoWrapper:first-child {
        .banner-wrapper {
            margin-top: 0;
        }
        .withoutPadding {
            padding-top: 0;
        }
    }
`;

export const DashboardTablesContainer = styled(Space)`
    width: 100%;
    margin-top: 18px;
    .ant-space-item {
        width: 100%;
    }
    .ant-table-cell {
        padding: 8px 8px 8px 0;
        &::before {
            content: unset !important;
        }
    }
    thead {
        .ant-table-cell {
            background: unset;
            border-bottom: 1px solid ${grayDark06};
            padding: 0px 0px 8px 0px;
        }
    }
    tbody {
        .ant-table-cell {
            min-width: 100px;
            border: none;
            cursor: pointer;
            border-bottom: 1px solid ${grayLight03};
        }
    }
    .ant-table-column-title {
        font-style: normal;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 10px;
        line-height: 12px;
        color: ${blueDark02};
    }
    .ant-table-cell {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: ${blueDark02};
        font-style: normal;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
    }
    .project-name {
        display: flex;
        gap: 12px;
        align-items: center;
    }
    &#project-dashboard {
        tbody {
            .ant-table-cell {
                padding: 2px 12px 2px 0;
            }
        }
    }
`;

export const DashboardProductsTableContainer = styled(Space)`
    width: 100%;
    margin-top: 20px;
    .ant-space-item {
        width: 100%;
    }
    .ant-table-cell {
        &::before {
            content: unset !important;
        }
    }
    thead {
        .ant-table-cell {
            padding: 0px 0px 9px 0px;
            background: unset;
            font-size: 10px;
            line-height: 12px;
            color: ${blueDark02};
            border-bottom: 1px solid ${grayDark06};
        }
    }
    tbody {
        .ant-table-cell {
            padding: 13px 0;
            border: none;
            cursor: pointer;
            &:first-child {
                padding-left: 12px;
            }
        }
    }

    .ant-table-tbody > tr:not(:last-child) > td {
        border-bottom: 1px solid ${grayLight03};
    }

    .ant-table-column-title {
        font-style: normal;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 10px;
        line-height: 12px;
        color: ${blueDark02};
    }
    .ant-table-cell {
        font-style: normal;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 12px;
        line-height: 16px;
        color: ${grayDark03};
    }
    .project-name {
        display: flex;
        gap: 8px;
        align-items: center;
    }
    &#project-dashboard {
        tbody {
            .ant-table-cell {
                padding: 2px 8px 2px 0;
            }
        }
    }
`;

export const DashboardPromoBlock = styled(Space)`
    width: 15%;
`;

export const DashboardPageTitle = styled('h2')`
    font-family: 'DINNextLTPro';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: ${blue01};
    margin-top: 2px;
    margin: 0;
`;

export const DashboardTitleContainer = styled('div')`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const DashboardFavoritesContainer = styled(DashboardTitleContainer)`
    margin-top: 61px;
    align-items: flex-start;
`;

export const ProjectNameColumn = styled(Space)`
    display: flex;
`;

export const NotificationLink = styled(NavLink)`
    white-space: nowrap;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${blueLink};
    font-family: 'Proxima Nova';
    font-weight: 400;
`;

export const DashboardImageContainer = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 54px;
    .ant-image:first-child {
        position: absolute;
        z-index: 1;
    }
    .ant-image-img {
        object-fit: cover;
    }
`;

export const DashboardButton = styled(PrimaryButton)`
    font-family: 'DINNextLTPro';
    margin-top: 16px;
`;

export const DashboardSelect = styled(Select)`
    width: 250px;
`;

export const DashboardClickableRow = styled(ClickableRow)`
    font-size: 12px;
    text-decoration: none;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
`;

export const NowrapText = styled('span')`
    line-height: 16px;
    font-weight: normal;
    font-family: 'Proxima Nova';
    font-size: 12px;
    margin-left: 5px;
    color: ${blueLink};
    @media screen and (max-width: 860px) {
        white-space: normal;
    }
`;

export const ProductNameText = styled(NowrapText)`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const DashboardRecentlySpin = styled(Spin)`
    top: 0 !important;
`;

export const DashboardTableTextContainer = styled('div')`
    font-family: 'Proxima Nova';
    font-weight: 400;
    &.bold {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
    }
`;

export const DashboardProjectName = styled(DashboardClickableRow)`
    font-family: 'Proxima Nova';
    font-weight: 400;
    &.bold {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
    }
`;

export const RecentlyFavoritesAdded = styled(Text)`
    font-family: 'Proxima Nova';
    font-weight: 400;
`;

export const TextBold = styled(Text)`
    font-family: 'Proxima N W15 Bold';
    font-weight: 400;
`;

export const FavoriteTypeSelector = styled('div')`
    .ant-select-selector {
        width: 260px !important;
    }
`;

export const TextContact = styled(Text)`
    font-weight: 400;
    line-height: 16px;
    font-family: 'Proxima Nova';
    font-size: 13px;
    color: ${blueLink};
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin-bottom: 30px;
`;

export const EmptyTextContainer = styled('div')`
    align-items: center;
    justify-content: center;
`;

