import React, { useEffect, useState } from 'react';
import { Form, Image } from 'antd';
import CustomSelect from '../../common/select/CustomSelect';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useModalState } from '../../../hooks/modalState';
import {
    changeProjectGroupAction,
    changeUserAccountRoleAction,
    getUserReferencesAction,
} from '../../../redux/actions/userActions';
import ModalTitle from '../../common/modalTitle/ModalTitle';
import { SelectData } from '../../../models/common/types';
import ModalFooter from '../../common/modalFooter/ModalFooter';
import ErrorModal from '../../common/errorModal/ErrorModal';
import { BoldText, CloseIcon } from '../../../styles/common.styles';
import {
    ChangeRoleGroupModal,
    ChangeRoleSpace,
    LabelContainer,
    ChangeRoleTipContainer,
} from './changeRoleGroupModal.styles';
import { blueDark01 } from '../../../styles/colors';
import { ActionResponse, CommonResponse } from '../../../models/actionResponse/types';
import { GetUserReferencesResponse } from '../../../redux/actions/userActions/types';

type Field = 'role' | 'group';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: string }) => void;
    field: Field;
    reload: (modal: string, data?: any) => void;
    usersCount: number;
    sendActionInReload?: boolean;
}

const ChangeRoleModal: React.FC<Props> = ({
    isOpen,
    closeModal,
    field,
    reload,
    usersCount,
    sendActionInReload,
}) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state.userReducer);
    const roleField = 'role';
    const accountTypeField = 'account type';
    field = field === roleField ? accountTypeField : field;

    const [selectData, setSelectedData] = useState({
        allTitleRoles: Array<SelectData>(),
        accountRoles: Array<SelectData>(),
    });
    const errorModal = useModalState();

    const handleCancel = () => {
        const fieldClose = field === accountTypeField ? roleField : field;
        closeModal({ key: fieldClose });
    };

    useEffect(() => {
        getDataForSelect();
    }, []);

    const getDataForSelect = async () => {
        const { payload } = (await dispatch(
            getUserReferencesAction(),
        )) as ActionResponse<GetUserReferencesResponse>;
        if (payload?.data) {
            setSelectedData({
                allTitleRoles: payload.data.allTitleRoles || [],
                accountRoles: payload.data.allAccountRoles || [],
            });
        } else {
            errorModal.onOpen();
        }
    };

    const onSubmit = async (data: any) => {
        const fieldClose = field === accountTypeField ? roleField : field;
        if (sendActionInReload) {
            reload(fieldClose, data);
        } else {
            const userIds = selector.selectedRows.map((user) => user.key);
            const { payload } =
                field === accountTypeField
                    ? ((await dispatch(
                          changeUserAccountRoleAction({
                              userIds,
                              accountRoleID: data['accountRoleID'],
                          }),
                      )) as CommonResponse)
                    : ((await dispatch(
                          changeProjectGroupAction({
                              userIds,
                              projectGroupID: data['projectGroupID'],
                          }),
                      )) as any);
            if (payload?.message) {
                reload(fieldClose);
            }
        }
    };

    return (
        <ChangeRoleGroupModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            className="role-modal-container"
            closeIcon={<CloseIcon />}
        >
            <Form onFinish={onSubmit}>
                <ModalTitle title={`Change ${field}`} icon="ic-modal-title-change-role.svg" />
                <Form.Item>
                    <ChangeRoleSpace>
                        <div>
                            <LabelContainer>
                                <BoldText weight={400} size={13} height={16} color={blueDark01}>
                                    Change {usersCount} user
                                    {usersCount > 1 ? 's' : ''} to the following {field}:
                                </BoldText>
                            </LabelContainer>
                            <ChangeRoleTipContainer>
                                <Image
                                    width={13}
                                    preview={false}
                                    src="src/assets/images/circle-question.svg"
                                    alt="tooltip"
                                />
                                <div className="tip">
                                    <strong>Assigning account types</strong><br/>
                                    Consider what you would like each person to be able to do.<br/>
                                    <br/>
                                    <strong>Administrators (Admins)</strong> create other accounts and have ownership rights to every Project (even ones the Project's Owner has hidden from the rest of the organization); their <a target="_blank" href="https://transparencycatalog.zendesk.com/hc/en-us/articles/12889864142994-Roles">Project role</a> defaults to Admin. They can delete products from the office Library.<br/>
                                    <br/>
                                    <strong>Members</strong> create and manage Projects and can be assigned different <a target="_blank" href="https://transparencycatalog.zendesk.com/hc/en-us/articles/12889864142994-Roles">Project roles</a>, each with different permissions. Members cannot create or delete other accounts. They can add products to (but not delete products from) the office Library.<br/>
                                    <br/>
                                    <strong>Associates</strong> can only collaborate on Projects. People with Associate accounts do not have a Personal Favorite Products Library or have access to the office Library.<br/><br/>
                                    These are the capabilities they have within the Projects:<br/><br/>
                                    <ul>
                                    <li>View the Project</li>
                                    <li>Comment on products</li>
                                    <li>Upload documents and images to products</li>
                                    <li>Export documents and images</li>
                                    <li>Export Projects to .CSV</li>
                                    </ul>
                                </div>
                            </ChangeRoleTipContainer>
                            <CustomSelect style={{display: 'inline'}}
                                label={''}
                                name={'accountRoleID'}
                                options={selectData.accountRoles.map((role: SelectData) => ({
                                    ...role,
                                    name: role.name.replace('Account', ''),
                                }))}
                                placeholder={`Select ${field}`}
                                rules={[{ required: true, message: '' }]}
                            />
                        </div>
                    </ChangeRoleSpace>
                </Form.Item>
                <ModalFooter submit={() => {}} cancel={handleCancel} action={`Change ${field}`} />
            </Form>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </ChangeRoleGroupModal>
    );
};

export default ChangeRoleModal;
