export enum LibraryType {
    YourProjects = 1,
    AllProjects = 2,
    Completed = 3,
    Template = 4,
    ManufacturerTemplate = 5,
}

export enum ProjectLibraryCountType {
    YourProjects = 'yourProjectsTotalCount',
    AllProjects = 'allProjectsTotalCount',
    Completed = 'completedTotalCount',
    Template = 'templateTotalCount',
    MFRTemplates = 'manufacturerTemplateTotalCount',
}

export enum ProjectFieldKey {
    Name,
    BuildingTypeName,
    ProjectStatusName,
    ModifiedOn,
    CreatedOn,
    ClientOwner,
    OfficeName,
    ProjectGroupName,
}

export enum ExportProjectType {
    ALL_DIVISIONS_ALL_PRODUCTS,
    ALL_DIVISIONS_BASIS_DESIGN,
    INSTALLED_PRODUCTS_ALL_DIVISIONS_ALL_PRODUCTS,
    INSTALLED_PRODUCTS_ALL_DIVISIONS_BASIS_DESIGN,
    DIVISION_ALL_PRODUCTS,
    DIVISION_BASIS_DESIGN,
    INSTALLED_PRODUCTS_DIVISION_ALL_PRODUCTS,
    INSTALLED_PRODUCTS_DIVISION_BASIS_DESIGN,
}

export enum ProjectAction {
    DELETE = 'delete',
    COPY = 'copy',
    TEMPLATE = 'template',
    EC3 = 'ec3',
    CSV = 'csv',
    COMPLETED = 'completed',
    EXPORT = 'export',
    IMPORT = 'import',
    SHARE = 'share',
    CREATE_FROM_TEMPLATE = 'createFromTemplate',
}
