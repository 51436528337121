import React, { useEffect, useState } from 'react';
import { getBannersAction, getPromoTypesAction } from '../../redux/actions/bannerActions';
import { useAppDispatch } from '../../hooks/redux';
import { useModalState } from '../../hooks/modalState';

import ErrorModal from '../common/errorModal/ErrorModal';
import BannerBlock from './banner/BannerBlock';
import { Banner, SelectData } from '../../models/common/types';
import { getCorrectPromoType } from '../../helpers/bannerHelper';

const BannerDisplayContainer: React.FC = () => {
    const dispatch = useAppDispatch();

    const [banners, setBanners] = useState<Array<Banner>>([]);
    const [promoTypes, setPromoTypes] = useState<Array<SelectData>>([]);
    const errorModal = useModalState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setPromoTypes(await getPromoTypes());
        setBanners(await getBanners());
    };

    const getBanners = async () => {
        const { payload } = (await dispatch(getBannersAction())) as any;
        if (payload?.data) {
            return payload?.data;
        }
        return [];
    };

    const getPromoTypes = async () => {
        const { payload } = (await dispatch(getPromoTypesAction())) as any;
        if (payload?.data) {
            return payload?.data;
        }
        return [];
    };

    return (
        <div>
            {banners.map((banner) => {
                return (
                    <BannerBlock
                        banner={banner}
                        promoType={getCorrectPromoType(
                            banner?.promoTypeId?.toString() ?? '',
                            promoTypes,
                        )}
                        key={banner.id}
                    />
                );
            })}
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </div>
    );
};

export default BannerDisplayContainer;
