import React, { FC, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/redux';
import { getAccessToken } from '../../redux/persistedState';
import { setFilePath, setPackageId } from '../../redux/reducers/productReducer/ProductSlice';
import {
    setAcceptedInvitation,
    setProjectPackageId,
} from '../../redux/reducers/projectsReducer/ProjectSlice';

export const AuthorizedPage: FC<{ children: React.ReactNode }> = ({ children }) => {
    const authToken = getAccessToken();
    const dispatch = useAppDispatch();

    const params = new URLSearchParams(window.location.search);

    const projectInfoString = 'project-info';

    const checkUrl = () => {
        if (
            window.location.pathname.includes(projectInfoString) &&
            window.location.pathname.includes('share')
        ) {
            const urlDataArray = window.location.pathname?.split(projectInfoString)[1]?.split('/');
            const projectId = urlDataArray.filter((data) => data);
            dispatch(setAcceptedInvitation(+projectId[0]));
        }
        if (window.location.pathname.includes('project-library')) {
            const urlDataArray = window.location.pathname?.split('project-library')[1]?.split('/');
            const packageId = urlDataArray.filter((data) => data);
            if (packageId && packageId[0] !== 'undefined') {
                dispatch(setPackageId(packageId[0]));
            }
        }
        if (window.location.pathname.includes(projectInfoString)) {
            const urlDataArray = window.location.pathname?.split(projectInfoString)[1]?.split('/');
            const projectUrl = urlDataArray.filter((data) => data);
            const projectId = projectUrl[0];
            const packageId = projectUrl[1];
            if (packageId && projectId && packageId.length > 1) {
                dispatch(setProjectPackageId({ packageId, projectId }));
            }
        }
        const file = params.get('file');
        if (file) {
            dispatch(setFilePath(file));
        }
        return `/login?return=${params.get("return") ?? window.location.pathname}`;
    };

    if (window.location.pathname.includes(projectInfoString)) {
        const urlDataArray = window.location.pathname?.split(projectInfoString)[1]?.split('/');
        const projectUrl = urlDataArray.filter((data) => data);
        const projectId = projectUrl[0];
        const packageId = projectUrl[1];
        if (packageId && projectId && packageId.length > 1) {
            if (projectId === 'my-favorites' || projectId === 'favorites') {
                window.location.href = `${process.env.REACT_APP_URL}/${projectId}?packageId=${packageId}`;
                return <></>;
            }
        }
    }

    return authToken ? <Fragment>{children}</Fragment> : <Redirect to={checkUrl()} />;
};
