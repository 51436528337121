import React from 'react';
import { PageTitleContainer, PageTitleText } from './PageTitle.styles';

interface Props {
    title: string;
    children?: React.ReactNode;
    showMenu?: boolean;
}

const PageTitle: React.FC<Props> = ({ title, children, showMenu = true }) => {
    return (
        <PageTitleContainer>
            {title ? (
                <PageTitleText showMenu={showMenu}>{title}</PageTitleText>
            ) : (
                <PageTitleText showMenu={showMenu} className="invisible-title">
                    title
                </PageTitleText>
            )}
            {children}
        </PageTitleContainer>
    );
};

export default PageTitle;
