import React, { useEffect, useState } from 'react';
import { MFRProject, ProjectTableFields } from '../../../../models/project/types';
import ModalTitle from '../../../common/modalTitle/ModalTitle';
import InviteTab from './InviteTab';
import AccessTab from './AccessTab';

import { ShareModalDivider, ShareProjectModal } from './ShareProject.styles';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
    getCollaboratorsAction,
    getProjectRolesAction,
} from '../../../../redux/actions/projectActions';
import { SelectData } from '../../../../models/common/types';
import {
    Collaborator,
    GetCollaboratorsResponse,
    GetProjectInformationResponse,
} from '../../../../redux/actions/projectActions/types';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { Spin } from 'antd';
import { CloseIcon } from '../../../../styles/common.styles';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'share' }) => void;
    reload: (modal: string) => void;
    project: ProjectTableFields | GetProjectInformationResponse | MFRProject;
}

const ShareProject: React.FC<Props> = ({ isOpen, closeModal, project }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { projectReducer } = selector;

    const [projectRoles, setProjectRoles] = useState<Array<SelectData> | undefined>(undefined);

    const [selectedUser, setSelectedUser] = useState<Collaborator | null>(null);

    const [state, setState] = useState({
        curPage: 1,
        searchString: '',
        collaborators: Array<Collaborator>(),
        currentCollaborators: Array<Collaborator>(),
        total: 0,
        changeRole: Array<{ id: number; roleId: number }>(),
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await getProjectRoles();
        await getCollaborators(1, '');
    };

    const handleCancel = () => {
        closeModal({ key: 'share' });
    };

    const getCollaborators = async (page: number, searchString: string) => {
        const { payload } = (await dispatch(
            getCollaboratorsAction({
                curPage: page,
                searchString,
                projectId: project.id,
            }),
        )) as ActionResponse<GetCollaboratorsResponse>;
        if (payload.data) {
            setState({
                ...state,
                curPage: page,
                searchString,
                collaborators: payload.data.collaborators,
                currentCollaborators: payload.data.collaborators.slice(page - 1, page - 1 + 10),
                total: payload.data.collaborators.filter((c) => c.isCollaborator).length,
            });
        }
    };

    const getCurrentCollaborators = (page: number, searchString: string) => {
        let currentCollaborators = state.collaborators;
        if (searchString) {
            currentCollaborators = currentCollaborators
                .filter((collaborator) =>
                    collaborator.email.toLowerCase().includes(searchString.toLowerCase()),
                )
                .filter((c) => c.isCollaborator);
        }
        let start = page === 1 ? 0 : (page - 1) * 10;
        let totalCount = state.collaborators.length;
        if (searchString) {
            totalCount = state.collaborators
                .filter((collaborator) =>
                    collaborator.email.toLowerCase().includes(searchString.toLowerCase()),
                )
                .filter((c) => c.isCollaborator).length;
            if (totalCount < 10) {
                start = 0;
            }
        }
        setState({
            ...state,
            currentCollaborators: currentCollaborators.slice(start, start + 10),
            total: totalCount,
        });
    };

    const getProjectRoles = async () => {
        const { payload } = (await dispatch(getProjectRolesAction())) as ActionResponse<
            Array<SelectData>
        >;
        if (payload.data) {
            setProjectRoles(payload.data);
        }
    };

    const handleSelect = (collaborator: Collaborator, roleId: string) => {
        const modalElement = document.getElementsByClassName('ant-modal-wrap');
        modalElement[0]?.scrollTo({ behavior: 'smooth', top: 0 });
        setSelectedUser({ ...collaborator, roleId: +roleId });
    };

    return (
           <ShareProjectModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <Spin spinning={projectReducer.pending}>
                <ModalTitle title="Invite people to the project" icon="share-from-square.svg" />
                <InviteTab
                    handleCancel={handleCancel}
                    projectRoles={projectRoles}
                    project={project}
                    selectedUser={selectedUser}
                    collaborators={state.collaborators}
                />
                <ShareModalDivider />

                <AccessTab
                    project={project}
                    projectRoles={projectRoles}
                    totalCount={state.total}
                    collaborators={state.currentCollaborators}
                    handleCancel={handleCancel}
                    handleSelect={handleSelect}
                    getCollaborators={getCurrentCollaborators}
                    reload={getCollaborators}
                />
            </Spin>
        </ShareProjectModal>
    );
};

export default ShareProject;
