import React, { useEffect } from 'react';
import { Image } from 'antd';
import {
    CreateProjectButton,
    NavigationBarContainer,
    NavigationBarPath,
    NavigationBarSpace,
    NavigationItem,
    NavigationPathContainer,
} from './AppNavigationBar.styles';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import StartProject from '../projects/startProject/StartProject';
import { useModalState } from '../../hooks/modalState';
import { removePage } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { usePermissions } from '../../hooks/permissions';
import { SubscriptionTypes } from '../../models/subscription/enums';
import { NavLink } from 'react-router-dom';
import { AccountPermissionTypes, UserRoleTypes } from '../../models/permission/enums';
import { getAccessToken } from '../../redux/persistedState';

const AppNavigation: React.FC = () => {
    const selector = useAppSelector((state) => state);
    const { navigationReducer, projectReducer } = selector;
    const dispatch = useAppDispatch();
    const { onOpen, onClose, isOpen } = useModalState();
    const { getAccountSubscription, getUserRole, getAccountRole } = usePermissions();

    useEffect(() => {
        window.addEventListener('beforeunload', handleBreadcrumbs);
        return () => {
            window.removeEventListener('beforeunload', handleBreadcrumbs);
        };
    }, []);

    const handleBreadcrumbs = () => {
        dispatch(removePage());
    };

    const showStartProjectButton = () => {
        const authToken = getAccessToken();
        if (
            authToken &&
            getAccountSubscription() !== SubscriptionTypes.FREE &&
            getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE &&
            getUserRole() !== UserRoleTypes.SM_ADMIN
        ) {
            return true;
        }
        return false;
    };

    const getStartProjectButtonName = () => {
        if (
            window.location.pathname.includes('project-info') &&
            projectReducer.viewingProject?.isTemplate
        ) {
            return 'Start a project from this template';
        } else {
            return 'Start a Project';
        }
    };

    return (
        <NavigationBarContainer>
            <NavigationBarSpace direction="horizontal">
                <NavigationBarPath>
                    <NavigationPathContainer
                        split={<Image src="src/assets/images/ic-play.svg" preview={false} />}
                    >
                        {navigationReducer.pages.map((page) => {
                            return (
                                <NavLink to={page.key} key={page.key}>
                                    <NavigationItem>{page.value}</NavigationItem>
                                </NavLink>
                            );
                        })}
                    </NavigationPathContainer>
                </NavigationBarPath>
                {showStartProjectButton() && (
                    <div>
                        <CreateProjectButton onClick={onOpen}>
                            {getStartProjectButtonName()}
                        </CreateProjectButton>
                    </div>
                )}
            </NavigationBarSpace>
            {isOpen && <StartProject isOpen={isOpen} handleCancel={onClose} />}
        </NavigationBarContainer>
    );
};

export default AppNavigation;
