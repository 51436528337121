export enum ExportProjectKeys {
    MasterFormat = 'masterFormat',
    Company = 'company',
    ListingUrl = 'listingUrl',
    TransparencyCatalogUrl = 'transparencyCatalogUrl',
    WebsiteUrl = 'websiteUrl',
    ProductName = 'productName',
    ProductNameCorrected = 'productNameCorrected',
    Description = 'description',
    FinishScheduleID = 'finishScheduleId',
    descriptionCorrected = 'descriptionCorrected',
    ProductUrl = 'productUrl',
    BasisOfDesign = 'basisOfDesign',
    Substitutions = 'substitutions',
    Pricing = 'pricing',
    PricingType = 'pricingType',
    EpdProgram = 'epdProgram',
    EpdId = 'epdID',
    EpdType = 'epdType',
    ScopeRegion = 'scopeRegion',
    Ec3EmbodiedCarbon = 'ec3EmbodiedCarbon',
    EpdExpiresDate = 'epdExpiresDate',
    HPD = 'hpd',
    HpdScopeResults = 'hpdScopeResults',
    HpdScopeResultsCorrected = 'hpdScopeResultsCorrected',
    HpdExpiresDate = 'hpdExpiresDate',
    hpdVerified = 'hpdVerified',
    c2cRestrictedListFree = 'c2cRestrictedListFree',
    livingBuildingChallengeRedListFree = 'livingBuildingChallengeRedListFree',
    perkinsWillPrecautionaryListFree = 'perkinsWillPrecautionaryListFree',
    Declare = 'declare',
    DeclareResults = 'declareResults',
    DeclareExpiresDate = 'declareExpiresDate',
    DeclareVerified = 'declareVerified',
    C2cCertified = 'c2cCertified',
    C2cResults = 'c2cResults',
    C2cExpiresDate = 'c2cExpiresDate',
    C2cMHC = 'c2cMHC',
    C2cMHCResults = 'c2cMHCResults',
    C2cMHCExpiresDate = 'c2cMHCExpiresDate',
    Level = 'level',
    LPC = 'lpc',
    LpcResults = 'lpcResults',
    LpcExpiresDate = 'lpcExpiresDate',
    MfrInv = 'mfrInv',
    MfrInvResults = 'mfrInvResults',
}

export const CSVHeader = [
    { label: 'MasterFormat', key: ExportProjectKeys.MasterFormat },
    { label: 'Company', key: ExportProjectKeys.Company },
    { label: 'Transparency Catalog URL', key: ExportProjectKeys.TransparencyCatalogUrl },
    { label: 'Product', key: ExportProjectKeys.ProductNameCorrected },
    { label: 'Description', key: ExportProjectKeys.descriptionCorrected },
    { label: 'Finish schedule ID', key: ExportProjectKeys.FinishScheduleID },
    { label: 'Product URL', key: ExportProjectKeys.ProductUrl },
    { label: 'Basis of design', key: ExportProjectKeys.BasisOfDesign },
    { label: 'Substitutions', key: ExportProjectKeys.Substitutions },
    { label: 'Pricing', key: ExportProjectKeys.Pricing },
    { label: 'Pricing type', key: ExportProjectKeys.PricingType },
    { label: 'EPD program', key: ExportProjectKeys.EpdProgram },
    { label: 'EPD ID', key: ExportProjectKeys.EpdId },
    { label: 'EPD type', key: ExportProjectKeys.EpdType },
    { label: 'Scope | Region', key: ExportProjectKeys.ScopeRegion },
    { label: 'EC3 embodied carbon', key: ExportProjectKeys.Ec3EmbodiedCarbon },
    { label: 'EPD expires', key: ExportProjectKeys.EpdExpiresDate },
    { label: 'Health Product Declaration (HPD)', key: ExportProjectKeys.HPD },
    { label: 'HPD Scope | Results', key: ExportProjectKeys.HpdScopeResultsCorrected },
    { label: 'HPD expires', key: ExportProjectKeys.HpdExpiresDate },
    { label: 'HPD verified', key: ExportProjectKeys.hpdVerified },
    { label: 'C2C restricted list free', key: ExportProjectKeys.c2cRestrictedListFree },
    { label: 'Living Building Challenge red list free', key: ExportProjectKeys.livingBuildingChallengeRedListFree },
    { label: 'Perkins&Will precautionary list free', key: ExportProjectKeys.perkinsWillPrecautionaryListFree },
    { label: 'Declare', key: ExportProjectKeys.Declare },
    { label: 'Declare results', key: ExportProjectKeys.DeclareResults },
    { label: 'Declare expires', key: ExportProjectKeys.DeclareExpiresDate },
    { label: 'Declare verified', key: ExportProjectKeys.DeclareVerified },
    { label: 'C2C certified', key: ExportProjectKeys.C2cCertified },
    { label: 'C2C results', key: ExportProjectKeys.C2cMHCResults },
    { label: 'C2C expires', key: ExportProjectKeys.C2cExpiresDate },
    { label: 'C2C Material Health Certificate (MHC)', key: ExportProjectKeys.C2cMHC },
    { label: 'C2C MHC results', key: ExportProjectKeys.C2cMHCResults },
    { label: 'C2C MHC expires', key: ExportProjectKeys.C2cMHCExpiresDate },
    { label: 'Level', key: ExportProjectKeys.Level },
    { label: 'Living Product Challenge (LPC)', key: ExportProjectKeys.LPC },
    { label: 'LPC results', key: ExportProjectKeys.LpcResults },
    { label: 'LPC expires', key: ExportProjectKeys.LpcExpiresDate },
    { label: 'Manufacturer Inventory (Mfr Inv)', key: ExportProjectKeys.MfrInv },
    { label: 'Mfr Inv | Scope', key: ExportProjectKeys.MfrInvResults },
];

export const FavoriteCSVHeader = [
    { label: 'MasterFormat', key: ExportProjectKeys.MasterFormat },
    { label: 'Company', key: ExportProjectKeys.Company },
    { label: 'Transparency Catalog URL', key: ExportProjectKeys.TransparencyCatalogUrl },
    { label: 'Product', key: ExportProjectKeys.ProductName },
    { label: 'Finish schedule ID', key: ExportProjectKeys.FinishScheduleID },
    { label: 'Product URL', key: ExportProjectKeys.ProductUrl },
    { label: 'EPD program', key: ExportProjectKeys.EpdProgram },
    { label: 'EPD ID', key: ExportProjectKeys.EpdId },
    { label: 'EPD type', key: ExportProjectKeys.EpdType },
    { label: 'Scope | Region', key: ExportProjectKeys.ScopeRegion },
    { label: 'EC3 embodied carbon', key: ExportProjectKeys.Ec3EmbodiedCarbon },
    { label: 'EPD expires', key: ExportProjectKeys.EpdExpiresDate },
    { label: 'Health Product Declaration (HPD)', key: ExportProjectKeys.HPD },
    { label: 'HPD Scope | Results', key: ExportProjectKeys.HpdScopeResults },
    { label: 'HPD expires', key: ExportProjectKeys.HpdExpiresDate },
    { label: 'HPD verified', key: ExportProjectKeys.hpdVerified },
    { label: 'C2C restricted list free', key: ExportProjectKeys.c2cRestrictedListFree },
    { label: 'Living Building Challenge red list free', key: ExportProjectKeys.livingBuildingChallengeRedListFree },
    { label: 'Perkins&Will precautionary list free', key: ExportProjectKeys.perkinsWillPrecautionaryListFree },
    { label: 'Declare', key: ExportProjectKeys.Declare },
    { label: 'Declare results', key: ExportProjectKeys.DeclareResults },
    { label: 'Declare expires', key: ExportProjectKeys.DeclareExpiresDate },
    { label: 'Declare verified', key: ExportProjectKeys.DeclareVerified },
    { label: 'C2C certified', key: ExportProjectKeys.C2cCertified },
    { label: 'C2C results', key: ExportProjectKeys.C2cMHCResults },
    { label: 'C2C expires', key: ExportProjectKeys.C2cExpiresDate },
    { label: 'C2C Material Health Certificate (MHC)', key: ExportProjectKeys.C2cMHC },
    { label: 'C2C MHC results', key: ExportProjectKeys.C2cMHCResults },
    { label: 'C2C MHC expires', key: ExportProjectKeys.C2cMHCExpiresDate },
    { label: 'Level', key: ExportProjectKeys.Level },
    { label: 'Living Product Challenge (LPC)', key: ExportProjectKeys.LPC },
    { label: 'LPC results', key: ExportProjectKeys.LpcResults },
    { label: 'LPC expires', key: ExportProjectKeys.LpcExpiresDate },
    { label: 'Manufacturer Inventory (Mfr Inv)', key: ExportProjectKeys.MfrInv },
    { label: 'Mfr Inv | Scope', key: ExportProjectKeys.MfrInvResults },
];
