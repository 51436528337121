import { Image } from 'antd';
import React from 'react';
import {
    UsersPageActions,
    UsersFindInput,
    UsersTableCount,
    SearchUsersSpace,
} from './SMAdminUsersPageSearch.styles';
import { TableData } from '../../../../models/tableData/types';

interface Props {
    tableData: TableData;
    totalCount: number;
    onSearch: () => void;
    handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clearSearchInput: () => void;
}

export const UsersPageSearch: React.FC<Props> = ({
    tableData,
    totalCount,
    onSearch,
    handleSearch,
    clearSearchInput,
}) => {
    return (
        <UsersPageActions direction="horizontal">
            <SearchUsersSpace>
                <UsersFindInput
                    placeholder="Find a user by email or name"
                    onChange={handleSearch}
                    onPressEnter={onSearch}
                    value={tableData.searchString}
                    prefix={
                        <>
                            {!tableData.searchString && (
                                <Image
                                    src="src/assets/images/magnifying-glass.svg"
                                    preview={false}
                                    alt="search"
                                />
                            )}
                        </>
                    }
                    suffix={
                        <>
                            {tableData.searchString && (
                                <Image
                                    preview={false}
                                    src="src/assets/images/close-circle-icon.svg"
                                    onClick={clearSearchInput}
                                    alt="reset"
                                />
                            )}
                        </>
                    }
                ></UsersFindInput>
                <UsersTableCount>{`${totalCount} Users`}</UsersTableCount>
            </SearchUsersSpace>
        </UsersPageActions>
    );
};
