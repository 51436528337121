import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-dom';
import App from './app';
import { Provider } from 'react-redux';
import { setupStore } from './redux/store';
import './index.scss';
import './components/images';

render(
    <BrowserRouter basename="/">
        <Provider store={setupStore()}>
            <App />
        </Provider>
    </BrowserRouter>,
    document.getElementById('app'),
);
