import { RootState } from './store';
import Cookies from 'js-cookie';

const transparencyCatalogDomain = 'transparencycatalog.com';

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (!serializedState) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        return undefined;
    }
};

export const saveState = (state: RootState) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (error) {}
};

export const clearState = () => {
    localStorage.clear();
    Cookies.remove('SmSession', {
        domain: transparencyCatalogDomain,
    });
    Cookies.remove('SmRefreshSession', {
        domain: transparencyCatalogDomain,
    });
};

export const setCookies = (accessToken: string, refreshToken: string) => {
    Cookies.set('SmSession', accessToken, {
        domain: transparencyCatalogDomain,
    });
    Cookies.set('SmRefreshSession', refreshToken, {
        domain: transparencyCatalogDomain,
    });
};

export const checkCookies = () => {
    const smSession = Cookies.get('SmSession');

    if (!smSession) {
        setCookies(getAccessToken(), getRefreshToken());
    }
};

export const getAccessToken = (): string => {
    const accessToken = localStorage.getItem('SmSession');
    return accessToken || '';
};

export const getRefreshToken = (): string => {
    const refreshToken = localStorage.getItem('SmRefreshSession');
    return refreshToken || '';
};

export const copyTokensFromCookiesToLocalStorage = () => {
    const accessToken = Cookies.get('SmSession');
    if (accessToken) {
        localStorage.setItem('SmSession', accessToken);
    }

    const refreshToken = Cookies.get('SmRefreshSession');
    if (refreshToken) {
        localStorage.setItem('SmRefreshSession', refreshToken);
    }
}
