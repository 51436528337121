export enum EC3ExportStatus {
    Done = 'Done',
    Cancelled = 'Cancelled',
    InProgress = 'EPDsReceived',
    NotExported = 'NotExported',
    CollectionsCteated = 'CollectionsCteated',
    ProjectCreated = 'ProjectCreated',
    CreateProjectView = 'CreateProjectView',
}

export enum EC3ErrorStatus {
    TokenExpired = 'tokenExpired',
}
