import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMAdminTableFields } from '../../../adminPanel/models/user/smAdminUser';
import { http } from '../../../helpers/request';
import { ActionResponse, CommonResponse } from '../../../models/actionResponse/types';
import { TableData } from '../../../models/tableData/types';
import {
    ChangeRoleSMAdminUserRequest,
    CreateSMAdminRequest,
    GetSMAdminUsersResponse,
    SMAdminUserByIdResponse,
    SetPublisherSMAdminUserRequest,
    UpdateSMAdminUserRequest,
    GetSMAdminsResponse,
    SMCustomPrice,
    CreateSMAdminUserRequest,
    GetSMAdminReferencesResponse,
    UpdateSMAdminRequest,
    BuyMoreUsersBySMAdmin,
    UpdateTrialPeriodBySMAdmin,
} from './types';

const baseUrl = `/smAdmin`;
const referencesUrl = `/references`;

export const getSMAdminReferencesAction = createAsyncThunk<
    ActionResponse<GetSMAdminReferencesResponse> | undefined,
    undefined
>('getSMAdminReferences/users', async () => {
    const result = await http<ActionResponse<GetSMAdminReferencesResponse> | undefined, undefined>({
        path: `${referencesUrl}/sm-admin`,
        method: 'get',
    });
    console.log('SMAdmin/References:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getSMAdminUsersAction = createAsyncThunk<
    GetSMAdminUsersResponse | undefined,
    TableData
>('getUsers/users', async (data: TableData) => {
    const result = await http<GetSMAdminUsersResponse, TableData>({
        path: `${baseUrl}/users`,
        method: 'post',
        body: data,
    });
    console.log('GetSMAdminUsers:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getSMAdminsAction = createAsyncThunk<GetSMAdminsResponse | undefined, TableData>(
    'getSMAdmins/users',
    async (data: TableData) => {
        const result = await http<GetSMAdminsResponse, TableData>({
            path: `${baseUrl}/admins`,
            method: 'post',
            body: data,
        });
        console.log('GetSMAdmins:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const getCustomPricingAction = createAsyncThunk<
    ActionResponse<Array<SMCustomPrice>> | undefined,
    undefined
>('getCustomPricing/users', async () => {
    const result = await http<ActionResponse<Array<SMCustomPrice>>, undefined>({
        path: `${baseUrl}/custom-pricing`,
        method: 'get',
    });
    console.log('getCustomPricing:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getSMAdminUserByIdAction = createAsyncThunk<
    ActionResponse<SMAdminUserByIdResponse> | undefined,
    string
>('getUserById/users', async (id: string) => {
    const result = await http<ActionResponse<SMAdminUserByIdResponse>, string>({
        path: `${baseUrl}/users/${id}`,
        method: 'get',
    });
    console.log('GetSMAdminUserById:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const createSMAdminUserAction = createAsyncThunk<
    SMAdminUserByIdResponse | undefined,
    CreateSMAdminUserRequest
>('createSMUser/users', async (data: CreateSMAdminUserRequest) => {
    const result = await http<SMAdminUserByIdResponse, CreateSMAdminUserRequest>({
        path: `${baseUrl}/create-sm-user`,
        body: data,
        method: 'post',
    });
    console.log('CreateSMAdminUser:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateSMAdminUserAction = createAsyncThunk<
    SMAdminUserByIdResponse | undefined,
    UpdateSMAdminUserRequest | ChangeRoleSMAdminUserRequest | SetPublisherSMAdminUserRequest
>('updateUser/users', async (data: UpdateSMAdminUserRequest) => {
    const result = await http<
        SMAdminUserByIdResponse,
        UpdateSMAdminUserRequest | ChangeRoleSMAdminUserRequest | SetPublisherSMAdminUserRequest
    >({
        path: `${baseUrl}/users/`,
        body: data,
        method: 'put',
    });
    console.log('UpdateSMAdminUser:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateSMAdminAction = createAsyncThunk<
    SMAdminTableFields | undefined,
    UpdateSMAdminRequest
>('updateAdmin/users', async (data: UpdateSMAdminUserRequest) => {
    const result = await http<SMAdminTableFields, UpdateSMAdminRequest>({
        path: `${baseUrl}/admins/`,
        body: data,
        method: 'put',
    });
    console.log('UpdateSMAdmin:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const resetSMAdminUserPasswordAction = createAsyncThunk<CommonResponse | undefined, string>(
    'resetUserPassword/users',
    async (id: string) => {
        const result = await http<CommonResponse, { userId: string }>({
            path: `${baseUrl}/reset-password`,
            method: 'post',
            body: { userId: `${id}` },
        });
        console.log('Reset SMAdminUser:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const deleteSMAdminUserAction = createAsyncThunk<CommonResponse | undefined, string>(
    'deleteUser/users',
    async (id: string) => {
        const result = await http<CommonResponse, { id: string }>({
            path: `${baseUrl}/delete-user`,
            method: 'post',
            body: { id: `${id}` },
        });
        console.log('Delete SMAdminUser:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const deleteSMAdminUsersAction = createAsyncThunk<CommonResponse | undefined, string>(
    'deleteUser/users',
    async (UserIds: string[]) => {
        const result = await http<CommonResponse, { UserIds: string[] }>({
            path: `${baseUrl}/delete-users-bulk`,
            method: 'post',
            body: { UserIds: UserIds },
        });
        return result.parsedBody;
    },
);


export const createSMAdminAction = createAsyncThunk<
    SMAdminUserByIdResponse | undefined,
    CreateSMAdminRequest
>('createSMAdmin/users', async (data: CreateSMAdminRequest) => {
    const result = await http<SMAdminUserByIdResponse, CreateSMAdminRequest>({
        path: `${baseUrl}/create-sm-admin`,
        method: 'post',
        body: data,
    });
    console.log('CreateSMAdmin:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const buyMoreUsersBySMAdminAction = createAsyncThunk<
    ActionResponse<any> | undefined,
    BuyMoreUsersBySMAdmin
>('checkout/buyMoreUsers', async (data: BuyMoreUsersBySMAdmin) => {
    const result = await http<ActionResponse<any>, BuyMoreUsersBySMAdmin>({
        path: `${baseUrl}/update-subscription-quantity`,
        method: 'post',
        body: data,
    });

    console.log('BuyMoreUsers:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const buyMoreAssociateUsersBySMAdminAction = createAsyncThunk<
    ActionResponse<any> | undefined,
    BuyMoreUsersBySMAdmin
>('checkout/buyMoreAssociateUsers', async (data: BuyMoreUsersBySMAdmin) => {
    const result = await http<ActionResponse<any>, BuyMoreUsersBySMAdmin>({
        path: `${baseUrl}/update-subscription-associate-quantity`,
        method: 'post',
        body: data,
    });
    return result.parsedBody;
});

export const updateTrialPeriodBySMAdminAction = createAsyncThunk<
    ActionResponse<any> | undefined,
    UpdateTrialPeriodBySMAdmin
>('checkout/updateTrialPeriod', async (data: UpdateTrialPeriodBySMAdmin) => {
    const result = await http<ActionResponse<any>, UpdateTrialPeriodBySMAdmin>({
        path: `${baseUrl}/update-trial-period`,
        method: 'post',
        body: data,
    });

    console.log('UpdateTrialPeriod');
    console.log(result.parsedBody);
    return result.parsedBody;
});

