import styled from 'styled-components';

import { Modal } from 'antd';
import { blueDark01 } from '../../../styles/colors';

export const CreateAccountModal = styled(Modal)`
    width: 680px !important;
    height: 335px !important;
    .ant-modal-body {
        padding-bottom: 40px;
        label {
            font-weight: 700 !important;
            line-height: 16px !important;
            color: ${blueDark01} !important;
            font-family: 'Proxima Nova' !important;
        }
    }
`;

export const PromptModalText = styled('div')`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark01};
    margin: 0px 0 28px 0;
`;
