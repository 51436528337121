import styled from "styled-components";
import { blueDark01, blueLink } from "../../styles/colors";
import { BaseLink } from "../../styles/common.styles";

export const CompanyNameContainer = styled('div')`
    display: flex;
    align-items: center;
    height: 18px;
    margin-top: 30px;
    font-family: 'DINNextLTPro-Bold';
    font-size: 20px;
    line-height: 24px;
    color: ${blueDark01};
`;

export const ContactInfoContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 472px;
    height: 72px;
    margin-top: 40px;
    .bold {
        font-family: 'Proxima N W15 Bold';
    }
`;

export const NotInCatalogMessageContainer = styled('div')`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
`;

export const ContactUsContainer = styled('div')`
    line-height: 18px;
    font-family: 'Proxima N W15 Bold';
`;

export const EmailLink = styled(BaseLink)`
    color: ${blueLink};
    &:hover {
        color: ${blueLink};
    }
`;