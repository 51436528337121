import { Col, Form, Input } from 'antd';
import React from 'react';
import { blueDark01 } from '../../../../styles/colors';
import { CloseIcon, Text } from '../../../../styles/common.styles';
import CustomInput from '../../inputs/CustomInput';
import ModalFooter from '../../modalFooter/ModalFooter';
import ModalTitle from '../../modalTitle/ModalTitle';
import CustomSelect from '../../select/CustomSelect';
import { AddFromAnotherSiteModal, AddFromAnotherSiteRow } from './AddFromAnotherSiteModal.styles';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'addFromAnotherSite' }) => void;
}

const AddFromAnotherSite: React.FC<Props> = ({ isOpen, closeModal }) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        closeModal({ key: 'addFromAnotherSite' });
    };

    const onSubmit = () => {};
    return (
        <AddFromAnotherSiteModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <ModalTitle
                title="Add product from another site"
                icon="ic-modal-add-product-from-external-site.svg"
            />
            <div>
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    Add a product that is not in the Transparency Catalog by entering its link and
                    CSI MasterFormat® information (required) below.
                </Text>
            </div>
            <Form onFinish={onSubmit} form={form}>
                <AddFromAnotherSiteRow>
                    <Col>
                        <CustomInput
                            Component={Input}
                            label="Product name"
                            name="productName"
                            required
                            rules={[{ required: true, message: '' }]}
                            block
                        />
                    </Col>
                    <Col>
                        <CustomInput
                            Component={Input}
                            label="Product link"
                            name="productLink"
                            required
                            rules={[{ required: true, message: '' }]}
                            block
                        />
                    </Col>
                </AddFromAnotherSiteRow>
                <AddFromAnotherSiteRow>
                    <Col>
                        <CustomSelect
                            label="CSI MasterFormat® division*"
                            name="division"
                            options={[]}
                            placeholder="Select one"
                            rules={[{ required: true, message: '' }]}
                            block
                        />
                    </Col>
                    <Col>
                        <CustomSelect
                            label="Section"
                            name="section"
                            options={[]}
                            placeholder="Select one"
                            rules={[{ required: true, message: '' }]}
                            block
                            props={{ disabled: true }}
                        />
                    </Col>
                </AddFromAnotherSiteRow>
                <ModalFooter
                    submit={() => {}}
                    cancel={handleCancel}
                    action="Save product"
                    actionButtonWidth={111}
                />
            </Form>
        </AddFromAnotherSiteModal>
    );
};

export default AddFromAnotherSite;
