import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Account from '../adminPanel/pages/account/Account';
import PromoBlockConstructor from '../adminPanel/pages/promoBlockConstructor/PromoBlockConstructor';
import { AuthorizedPage } from '../components/authorizedPage/AuthorizedPage';
import NotFoundPage from '../pages/notFound/NotFoundPage';
import SMAdmins from './SMAdminAdminsRoute';
import SMAdminUsersRoute from './SMAdminUsersRoute';
import { RouteName } from './Routes.types';

const SMAdminRoutes: React.FC = () => {
    return (
        <AuthorizedPage>
            <Switch>
                <Route exact path={RouteName.EMPTY_PATH} component={SMAdminUsersRoute} />
                <Route path={RouteName.USERS} component={SMAdminUsersRoute} />
                <Route path={RouteName.ADMINS} component={SMAdmins} />
                <Route exact path={RouteName.PROMO_BLOCKS} component={PromoBlockConstructor} />
                <Route exact path={RouteName.ACCOUNT} component={Account} />
                <Route path={RouteName.ANY_PATH} component={NotFoundPage} />
            </Switch>
        </AuthorizedPage>
    );
};

export default SMAdminRoutes;
