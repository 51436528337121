import React from 'react';

import '../../../styles/overlapStyles/customInput.scss';
import { CustomInputFormItem } from './CustomInputUploadFileForm.styles';
import { Input } from 'antd';

interface Props {
    Component: any;
    label: string;
    name: string;
    required?: boolean;
    props?: any;
    rules?: any;
    id?: string;
    block?: boolean;
    removeExtraSpaces?: boolean;
    forbidWhitespace?: boolean;
    allowTags?: boolean;
    suffix?: string;
    labelSpan?: number;
}
const CustomInputUploadFileForm: React.FC<Props> = ({
    id = 'input-container',
    Component,
    label,
    name,
    required,
    rules,
    props,
    block,
    removeExtraSpaces,
    forbidWhitespace,
    allowTags,
    suffix,
    labelSpan
}) => {
    const removeExtraSpacesValidator = ({ setFieldValue }) => ({
        validateTrigger: 'onBlur',
        validator(_, value) {
            let newValue = value?.replace(/\s\s+/g, ' ');
            newValue = newValue.trim();
            setFieldValue(name, newValue);
            return Promise.resolve();
        },
        message: '',
    });

    const forbidWhitespaceValidator = ({ setFieldValue }) => ({
        validator(_, value) {
            setFieldValue(name, value?.replace(/\s/, ''));
            return Promise.resolve();
        },
        message: '',
    });

    const htmlTagValidator = () => ({
        validator(_, value) {
            if (allowTags) {
                return Promise.resolve();
            }
            if (/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g.test(value)) {
                return Promise.reject(new Error('Invalid value'));
            }
            return Promise.resolve();
        },
        message: 'Invalid value',
    });

    const decorateRules = (rulesToDecorate: any) => {
        if (!rulesToDecorate) {
            rulesToDecorate = [htmlTagValidator];
        }
        rulesToDecorate.push(htmlTagValidator);
        if (Component !== Input) {
            return rulesToDecorate;
        }
        if (removeExtraSpaces === true) {
            rulesToDecorate.push(removeExtraSpacesValidator);
        }
        if (forbidWhitespace === true) {
            rulesToDecorate.push(forbidWhitespaceValidator);
        }
        return rulesToDecorate;
    };

    return (
        <div id={id} className={`${required ? 'required' : ''} ${block ? 'input-block' : ''}`}>
            <CustomInputFormItem
                name={name}
                label={
                <span className='customInputFormItemLabel'>
                    {label} {suffix && <span className="normal-weight-text">{suffix}</span>}
                </span>
                }
                rules={decorateRules(rules)}
                style={{ display: 'block' }}
                validateTrigger="onBlur"
                {...{ labelCol: labelSpan ? { span: labelSpan } : undefined }}
            >
                <Component {...props} />
            </CustomInputFormItem>
        </div>
    );
};

export default CustomInputUploadFileForm;
