import React from 'react';
import { Image } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import {
    CompanyDescription,
    CompanyDescriptionContainer,
    CompanyLinkContainer,
    ContactToCompany,
    ContactUsButton,
} from './CompanyInfo.styles';
import { BrandCompany } from '../../../../models/product/types';
import { StoredUser } from '../../../../models/user/user';

import { BoldText, Text } from '../../../../styles/common.styles';
import { blueDark01, white, blue } from '../../../../styles/colors';
import { FreeListingContact } from '../freeListing/FreeListing.styles';
import {
    BrandImage,
} from '../../MFRPage.styles';


interface Props {
    brandInformation: BrandCompany | undefined;
    onToggle: () => void;
    user: StoredUser | undefined;
}

const CompanyInfo: React.FC<Props> = ({ brandInformation, onToggle, user }) => {
    return (
        <CompanyDescriptionContainer>
            {brandInformation?.featuredProductImage && (
                <Image
                    height={160}
                    src={brandInformation?.featuredProductImage}
                    preview={false}
                />
            )}
            {brandInformation?.logo?.url && brandInformation?.logo?.url !== null && !brandInformation?.featuredProductImage && (
                        <BrandImage src={brandInformation?.logo.url} height={brandInformation?.logo.height} width={brandInformation?.logo.width} preview={false} />
            )}
            <CompanyDescription>
                {brandInformation?.description && (
                    <Text
                        weight={400}
                        height={18}
                        size={14}
                        color={blueDark01}
                        className="description"
                    >
                        {ReactHtmlParser(brandInformation?.description || '')}
                    </Text>
                )}
                {brandInformation?.listingUrl && (
                    <CompanyLinkContainer weight={400} height={18} size={14} color={blueDark01}>
                        <a href={brandInformation?.listingUrl}>
                            Visit our Transparency Catalog listing
                        </a>
                        {` to find all our high-performance products.`}
                    </CompanyLinkContainer>
                )}
            </CompanyDescription>
            {brandInformation?.contactEmail && brandInformation.contactInfo ? (
                <ContactToCompany>
                    <BoldText weight={400} size={16} height={20} color={blue} className={'text'}>
                        Get sample, specification, quote & submittal help
                    </BoldText>
                    <ContactUsButton onClick={onToggle}>Contact us</ContactUsButton>
                </ContactToCompany>
            ) : (
                <FreeListingContact>
                    <p>
                        <strong className="title">MFR, add your products.</strong>
                    </p>{' '}
                    <p>
                        <strong className="text">MFR:</strong>{' '}
                        <a href="mailto:sales@sustainableminds.com?subject=Tell%20me%20more%20about%20the%20SM%20Transparency%20Catalog&body=I%20like%20what%20I%E2%80%99m%20seeing.%20Please%20contact%20me%20to%20discuss%20how%20we%20can%20make%20our%20products%20with%20transparency%20information%20findable%20and%20the%20transparency%20information%20understandable.%0D%0A%0D%0A%5BYour%20Name%2C%20title%2C%20contact%20info%5D%0D%0A%0D%0AThank%20you.%0D%0A%0D%0Awww.transparencycatalog.com%0D%0AAll%20brands%20making%20credibly%20greener%20%26%20healthier%20products%20for%20the%20built%20environment%0D%0A">
                            Contact us
                        </a>{' '}
                        <br />
                        <strong className="text">AECOs:</strong>{' '}
                        <a href="mailto:[enter Manufacturer’s email address here]?cc=sales@sustainableminds.com&subject=Do%20you%20know%20about%20the%20SM%20Transparency%20Catalog%3F&body=Hi%20%5Bmanufacturer%20name%5D%2C%0A%20%0AThis%20is%20%5Byour%20name%2C%20title%2C%20contact%20info%5D.%0A%0AI%E2%80%99m%20sending%20you%20this%20email%20from%20your%20free%20listing%20in%20the%20SM%20Transparency%20Catalog.%20Check%20it%20out%3A%20www.transparencycatalog.com%0A%0AIt%E2%80%99s%20the%20leading%20product%20transparency%20solution%20that%20makes%20it%20easy%20for%20building%20professionals%20to%20find%20and%20select%20products%20with%20transparency%20disclosures%20for%20LEED%20v4%20and%20other%20green%20building%20projects.%20%0A%0AWe%20think%20it%E2%80%99s%20a%20great%20tool%20and%20are%20recommending%20manufacturers%20contact%20Sustainable%20Minds%20to%20learn%20how%20they%20can%20make%20your%20products%20with%20transparency%20information%20findable%20and%20the%20transparency%20information%20understandable.%0A%0AThank%20you.%0A%0Awww.transparencycatalog.com%0AAll%20brands%20making%20credibly%20greener%20%26%20healthier%20products%20for%20the%20built%20environment">
                            Contact the MFR
                        </a>
                    </p>
                </FreeListingContact>
            )}
        </CompanyDescriptionContainer>
    );
};

export default CompanyInfo;
