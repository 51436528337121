import styled from 'styled-components';
import { DatePicker, Image, Input } from 'antd';
import { PrimaryButton, BoldText } from '../../../../styles/common.styles';
import { blueDark01, blueDark02, blueLink, grayDark06, white, black } from '../../../../styles/colors';

export const SubscriptionDetailsSectionWrapper = styled('div')`
    margin: 14px 0 14px 0;
`;

export const CustomNormalText = styled('BoldText')`
    font-weight: 400;
    font-family: 'Proxima Nova';
    font-size: 13px;
    line-height: 16px;
`;

export const CustomBoldText = styled('BoldText')`
    font-size: 13px;
    font-weight: 700;
    font-family: 'Proxima Nova';
    line-height: 16px;
`;

export const CustomNormalTextBig = styled('BoldText')`
    font-size: 16px;
    font-weight: 400;
    font-family: 'Proxima Nova';
    line-height: 20px;
`;

export const CustomBoldTextBig = styled('BoldText')`
    font-size: 16px;
    font-weight: 700;
    font-family: 'Proxima Nova';
    line-height: 20px;
`;

export const TrialEndText = styled('BoldText')`
    font-weight: 700;
    font-size: 13px;
    font-family: 'Proxima Nova';
    margin-left: 45px;
    margin-top: 5px;
    width: 55px;
`;

export const OfficeSubscriptionDetails = styled('div')``;

export const AddMoreUsersContainer = styled('div')`
    margin-left: -7px;
    display: flex;
    align-items: center;
    gap: 9px;
    label {
        text-align: left !important;
    }
    #input-container {
        width: 400px;
        div:first-child {
            margin-bottom: 0 !important;
        }
        div.ant-col {
            width: 110px;
        }
        :nth-child(1) {
             .ant-form-item-row {
                  .ant-form-item-control {
                        input {
                            width: 274px !important;
                        }
                  }
              }
        }
    }

    & > div:nth-last-child(2) {
        label {
//            text-align: left !important;

            &::after {
                display: none;
            }
        }
    }
`;

export const UpdateTrialPeriodContainer = styled('div')`
    display: flex;
    align-items: center;
    gap: 9px;
    label {
        text-align: left !important;
    }
    #input-container {
        div:first-child {
            margin-bottom: 0 !important;
        }
        :nth-child(1) {
             .ant-form-item-row {
                  .ant-form-item-control {
                        input {
                            width: 100px !important;
                        }
                  }
              }
        }
    }

    & > div:nth-last-child(2) {
        label {
            text-align: left !important;

            &::after {
                display: none;
            }
        }
    }
`;

export const UpdateTrialDateContainer = styled('div')`
    display: flex;
    align-items: center;
    gap: 9px;
    label {
        text-align: left !important;
    }

    #input-container {
        div:first-child {
            margin-bottom: 0 !important;
        }
        :nth-child(1) {
             .ant-form-item-row {
                  .ant-form-item-control {
                        input {
                            width: 354px !important;
                        }
                  }
              }
        }
    }

    & > div:nth-last-child(2) {
        label {
            //text-align: left !important;

            &::after {
                display: none;
            }
        }
    }
`;

export const UploadExpiresContainer = styled('div')`
    align-items: center;
    margin-left: 0px;
    width: 354px;
`;

export const UploadsDatePickerContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    &.edit {
        border-radius: 3px;
        padding: 9px 0px 0px 0px;
        border: 1px solid ${grayDark06};
        margin-top: -9px;
        width: 90%;
        background-color: ${white};
    }
    &:hover {

    }
`;

export const EditExpirationDateInput = styled(Input)`
    margin-top: 3px;
    height: 32px;
    border: 1px solid ${grayDark06};
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: ${blueDark01};
    font-weight: normal;
    font-family: 'Proxima Nova';
    font-size: 12px;
    line-height: 14px;
    padding: 0px 0px 0px 8px;
    &.edit {
        background-color: ${white};
        color: ${black};
        border: 1px solid ${grayDark06};
    }
    &:hover {
        background-color: ${white};
        color: ${black};
        border: 1px solid ${grayDark06};
    }
    &:focus {
        background-color: ${white};
        color: ${black};
        border: 1px solid ${white};
    }
    &.error {
        border-color: red;
    }
`;

export const CalendarActionContainer = styled('div')`
    margin-top: 7px;
    display: flex;
`;

export const EditDatePicker = styled(DatePicker)`
    height: 28px;
    margin-top: -2px;
    margin-left: -35px;
    z-index: 100;
    background-color: ${white};
    &.ant-picker {
        border: none;
        background-color: ${white};
        padding: 0px 11px 4px 0;
        cursor: pointer;
    }
    input {
        display: none;
    }
`;

export const AddUsersButton = styled(PrimaryButton)`
    background-color: ${white};
    color: ${blueDark02};
    border: 1px solid ${blueDark02} !important;
    width: 72px;
    height: 36px;
    padding: 17px 14px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Proxima Nova';
    line-height: 18px;
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink} !important;
    }
    &:focus {
        background-color: ${white};
        color: ${blueDark01};
        border: 1px solid ${blueDark01} !important;
    }
`;

export const UpdateTrialButton = styled(AddUsersButton)`
    border: 1px solid ${blueDark02} !important;
    height: 32px;
    margin-top: 2px;
    padding: 13px 14px;
`;

