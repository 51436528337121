import { UploadsTableFields } from '../components/productInfo/uploads/Uploads.types';
import { retrieveUploadFileAction } from '../redux/actions/productActions';

export const scrollToTarget = function (
    target: HTMLElement | null,
    containerEl: HTMLElement | null,
) {
    const isElement = target && target.nodeType === 1;

    if (isElement && containerEl) {
        const topPos = containerEl.offsetTop;
        containerEl.scrollTop = target.offsetTop - topPos;
    }
};

export const uploadDocument = async (file: UploadsTableFields | undefined) => {
    if (file) {
        const blob = await retrieveUploadFileAction({ fileName: file.filePath });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${file.documentName}`;
        link.click();
    }
};

export const uploadDocumentByName = async (filePath: string) => {
    if (filePath) {
        const filePathArray = filePath.split('\\');
        const fileNameWithHash = filePathArray[filePathArray.length - 1];
        const fileNameArray = fileNameWithHash.split('_');
        fileNameArray.shift();
        const fileName = fileNameArray.join('');
        const blob = await retrieveUploadFileAction({ fileName: filePath });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}`;
        link.click();
    }
};
