import styled from 'styled-components';
import { Button, Input, Space } from 'antd';
import {
    blue,
    blue01,
    blue02,
    blueDark01,
    blueDark02,
    blueDark03,
    blueLink,
    blueDisabled01,
    blueDisabled02,
    grayLight01,
    white,
} from '../../styles/colors';
import { PrimaryButton, SpaceEnd, Text } from '../../styles/common.styles';

export const UsersPageActions = styled(Space)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const UsersPageActionsButton = styled(PrimaryButton)`
    border: 1px solid ${blueDark02};
    border-radius: 3px 0 0 3px;
`;

export const UsersFindInput = styled(Input)`
    max-width: 233px;
    height: 32px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    width: 18rem;
    padding: 1px 5px;
`;

export const UsersFindButton = styled(Button)`
    cursor: pointer;
    border-radius: 3px;
    background-color: ${blue01};
    color: ${grayLight01};
    border: none;
    &:hover {
        background-color: ${blue02};
        color: ${grayLight01};
    }
    &:focus {
        background-color: ${blue01};
    }
    padding: 0px 10px;
    height: 27px;
`;

export const CreateAccountButton = styled(Button)`
    border-radius: 3px;
    background-color: ${blueDark02};
    color: ${grayLight01};
    cursor: pointer;
    border-color: ${grayLight01};
    border-radius: 3px 0 0 3px;
    font-family: 'DINNextLTPro';
    border-right: 0px solid ${blueDark02};
    &:focus {
        background-color: ${blueDark03};
        color: ${grayLight01};
        border-color: ${grayLight01};
    }
`;

export const CreateAccountMenu = styled(Button)`
    border-radius: 0px 3px 3px 0;
    padding: 0 10px;
    background-color: ${blueDark02};
    font-family: 'DINNextLTPro';
    &:focus {
        background-color: ${blueDark01};
        color: #ffff;
        border-color: #ffff;
    }
`;

export const CreateAccountButtonContainer = styled('div')`
    &:hover {
        button:first-child {
            background-color: ${blueDark03};
            border-color: #ffff;
            color: #ffff;
            border-right: 0px solid ${blueDark03};
        }
        button:last-child {
            background-color: ${blueDark03};
            border-color: #ffff;
            color: #ffff;
        }
    }
    &.disable {
        #create-user-button {
            border-color: ${grayLight01};
            color: ${grayLight01};
        }
        #create-user-menu {
            border-color: ${grayLight01};
            color: ${grayLight01};
        }
        &:hover {
            button:first-child {
                border-color: ${grayLight01};
                color: ${grayLight01};
                background-color: ${blueDark02};
            }
            button:last-child {
                border-color: ${grayLight01};
                color: ${grayLight01};
                background-color: ${blueDark02};
            }
        }
    }
    .ant-btn[disabled] {
        background-color: inherit;
    }
`;

export const UsersTableActions = styled(SpaceEnd)`
    .ant-btn {
        margin-left: 0px;
        background-color: ${blue01};
        color: #ffffff;
        cursor: pointer;
        border-color: ${blue};
        border-radius: 3px 3px 3px 3px;
        .anticon {
            margin-top: 0px;
            margin-left: 15px;
            padding-left: 10px;
            margin-right: -3px;
            height: 30px;
            padding-top: 8px;
            padding-bottom: 12px;
            border-left: 0.5px solid;
            &:hover {
                border-left: 0.5px solid ${white};
            }
        }
        &:focus {
            background-color: ${blue01};
            color: #ffff;
        }
        &:hover {
            background-color: ${blue02};
            color: #ffff;
        }
        &:disabled {
            background-color: ${blueDisabled01};
            color: ${white};
            border: 1px solid ${blueDisabled02};
            opacity: 1;
            &:hover {
                background-color: ${blueDisabled01};
                color: ${white};
                border: 1px solid ${blueDisabled02};
            }
        }
    }
`;

export const UsersTableCount = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 1px;
`;

export const SearchUsersSpace = styled(Space)`
    gap: 20px !important;
`;

export const UnselectedText = styled(Text)`
    margin-top: 2px;
`;

