import { AccountPermissionTypes } from '../models/permission/enums';
import { SubscriptionTypes } from '../models/subscription/enums';
import { StoredUser } from '../models/user/user';
import { CreateUserRequest, UserByIdResponse } from '../redux/actions/userActions/types';

export const extractFields = <T extends { id: number }>(
    fields: Array<string>,
    properties: Array<T>,
) => {
    const extractedFields = properties.reduce((agg, value) => {
        const result: any = {};
        for (const property of fields) {
            result[property] = value[property];
        }
        result.key = value.id;
        agg.push(result);
        return agg;
    }, Array<T>());
    return extractedFields;
};

export const extractGetByIdUser = (user: UserByIdResponse): CreateUserRequest => {
    const result: CreateUserRequest = {
        firstName: user.firstName,
        lastName: user.lastName,
        userName: user.userName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        phoneNumberExt: user.phoneNumberExt,
        titleRoleID: getAccountRole(user.accountUser, user.parentAccountId).titleRole,
        projectGroupID: user.projectGroupID || null,
        accountRoleID:
            user.accountUser.length > 1
                ? user.accountUser.find((account) => account.accountId === user.parentAccountId)
                      ?.accountRole.id
                : user.accountUser[0].accountRole.id,
        projectBuilderRoleID: user.projectBuilderRoleID,
        notificationSettings: user.notificationSettings,
        accountId: user.accountId,
        parentAccountId: user.parentAccountId,
        organizationName: user.organization.name,
        organizationSizeId: +user.organization.size.id,
        maxUsersCount: getMaxUsersCount(user.accountUser),
    };
    return result;
};

export const extractStoredUser = (user: UserByIdResponse | undefined): StoredUser | undefined => {
    if (!user) {
        return undefined;
    }
    const result: StoredUser = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        appRole: user.projectBuilderRoleName || user?.userRoles[0].role?.name,
        accountRole: getAccountRole(user.accountUser, user.parentAccountId).accountRole,
        accountId: user.accountId,
        accountUser: user.accountUser && user.accountUser.length> 0 ? user.accountUser[0] : null,
        subscriptionPlanId: user.subscriptionPlanId,
        permissions: user.permission,
        subscriptionPlanName:
            (user.subscription.planName as SubscriptionTypes) ||
            getSubscriptionName(user.accountUser),
        parentAccountId: user.parentAccountId,
        subscriptionExpired: user.subscription.expiredOn,
        organization: user.organization,
        office: user.office,
        phoneNumber: user.phoneNumber,
        usedTrialSubscription: user.usedTrialSubscription,
    };
    return result;
};

const getAccountRole = (
    accountUser: Array<{
        accountId: number;
        accountUserId: number;
        accountRole: { id: number; name: string };
        titleRoleName: string;
    }>,
    parentAccountId: number,
): { accountRole: AccountPermissionTypes; titleRole: string } => {
    if (accountUser.length === 1) {
        return {
            accountRole: accountUser[0]?.accountRole?.name as AccountPermissionTypes,
            titleRole: accountUser[0].titleRoleName,
        };
    }
    const currentAccountUser = accountUser.find((account) => account.accountId === parentAccountId);
    return {
        accountRole: currentAccountUser?.accountRole.name as AccountPermissionTypes,
        titleRole: currentAccountUser?.titleRoleName || '',
    };
};

const getSubscriptionName = (
    accountUser: Array<{
        accountId: number;
        accountUserId: number;
        accountRole: { id: number; name: string };
        subscription: { id: number; planName: string; maxUserCount?: number; maxAssociateUserCount?: number };
    }>,
) => {
    const parentUser = accountUser.find((item) => item.subscription.planName);
    return parentUser?.subscription.planName as SubscriptionTypes;
};

const getMaxUsersCount = (
    accountUser: Array<{
        accountId: number;
        accountUserId: number;
        accountRole: { id: number; name: string };
        subscription: { id: number; planName: string; maxUserCount?: number; maxAssociateUserCount?: number };
    }>,
): number | undefined => {
    const parentUser = accountUser.find((item) => item.subscription.planName);
    return parentUser?.subscription.maxUserCount;
};

const getMaxAssociateUsersCount = (
    accountUser: Array<{
        accountId: number;
        accountUserId: number;
        accountRole: { id: number; name: string };
        subscription: { id: number; planName: string; maxUserCount?: number; maxAssociateUserCount?: number };
    }>,
): number | undefined => {
    const parentUser = accountUser.find((item) => item.subscription.planName);
    return parentUser?.subscription.maxAssociateUserCount;
};
