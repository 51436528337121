import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/redux';
import { ProjectReferences, ProjectTableFields } from '../../../models/project/types';
import {
    copyProjectAction,
    deleteProjectAction,
    switchProjectStatusAction,
} from '../../../redux/actions/projectActions';
import DeleteModal from '../../common/deleteModal/DeleteModal';
import CopyProject from '../projectActions/copyProject/CopyProject';
import { CopyProjectData } from '../projectActions/ProjectActions.types';
import SaveTemplate from '../projectActions/saveTemplates/SaveTemplate';
import ShareProject from '../projectActions/shareProject/ShareProject';
import ExportProject from '../projectActions/exportProject/ExportProject';
import { alertService } from '../../../services';
import { AlertMessages } from '../../alert/AlertMessages';
import {
    setActivatedProject,
    setCompletedProject,
    setDeletedProject,
    setTemplateProject,
} from '../../../redux/reducers/projectsReducer/ProjectSlice';
import { ActionResponse } from '../../../models/actionResponse/types';
import { useHistory } from 'react-router-dom';
import ExistingProductsModal from '../../existingProductsModal/ExistingProductsModal';
import { ExternalProduct } from '../../../models/product/types';
import { getExternalProductsAction } from '../../../redux/actions/transparencyCatalogActions';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../../redux/actions/transparencyCatalogActions/types';
import { importProductsFromProjectLibraryAction } from '../../../redux/actions/productActions';
import { ImportProductsFromProjectLibraryResponse } from '../../../redux/actions/productActions/types';
import { ExportProjectType, ProjectAction } from '../../../models/project/enums';
import CreateFromTemplate from '../projectActions/createFromTemolate/CreateFromTemplate';
import { GetProjectInformationResponse } from '../../../redux/actions/projectActions/types';

interface Props {
    action: ProjectAction | null;
    project: ProjectTableFields | null;
    projectReferences: ProjectReferences | null;
    projectId?: string;
    riseAction: () => void;
    refresh: () => void;
}

const ProjectTableActions: React.FC<Props> = ({
    action,
    project,
    projectId,
    projectReferences,
    riseAction,
    refresh,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [openModals, setOpenModals] = useState({
        share: false,
        completed: false,
        copy: false,
        delete: false,
        template: false,
        csv: false,
        warning: false,
        createFromTemplate: false,
        existingProducts: Array<{ id: number; name: string }>(),
    });

    useEffect(() => {
        if (action) {
            setOpenModals({ ...openModals, [action]: true });
            if (action === ProjectAction.COMPLETED) {
                switchProjectStatus();
            }
            if (action === ProjectAction.IMPORT) {
                copyProductsFromProject();
            }
        }
    }, [action, project?.key]);

    const closeModal = (data: { key: string }) => {
        setOpenModals({ ...openModals, [data.key]: false });
        riseAction();
    };
    const reload = (key: string, data?: any) => {
        switch (key) {
            case ProjectAction.DELETE:
                deleteProject();
                break;
            case ProjectAction.COMPLETED:
                switchProjectStatus();
                break;
            case ProjectAction.COPY:
                createCopy(data);
                break;
            case ProjectAction.TEMPLATE:
                createTemplate(data);
                break;
            case ProjectAction.IMPORT:
                copyProductsFromProject();
                break;
            case ProjectAction.CREATE_FROM_TEMPLATE:
                createProjectFromTemplate(data);
                break;
            default:
                break;
        }
    };

    const deleteProject = async () => {
        if (project) {
            const { payload } = await dispatch(deleteProjectAction(+project.key) as any);
            if (payload.message) {
                setOpenModals({ ...openModals, delete: false });
                refresh();
                riseAction();
                if (!project.isCompleted && !project.isTemplate) {
                    dispatch(setDeletedProject(project.id));
                }
            }
        }
    };

    const switchProjectStatus = async () => {
        if (project) {
            const { payload } = (await dispatch(switchProjectStatusAction(+project.key))) as any;
            if (payload.message) {
                setOpenModals({ ...openModals, completed: false });
                riseAction();
                refresh();
                if (project.isCompleted) {
                    dispatch(setActivatedProject(project.id));
                } else {
                    dispatch(setCompletedProject(project.id));
                }
            }
        }
    };

    const createCopy = async (data: CopyProjectData | undefined) => {
        if (data && project) {
            const { payload } = await dispatch(
                copyProjectAction({ ...data, projectId: +project?.key }),
            );
            if (payload) {
                setOpenModals({ ...openModals, copy: false });
                refresh();
                alertService.success(
                    data.isTemplate ? AlertMessages.TEMPLATE_CREATED : AlertMessages.PROJECT_COPIED,
                );
                riseAction();
            }
        }
    };

    const createTemplate = async (data: CopyProjectData | undefined) => {
        if (project && data) {
            const { payload } = await dispatch(
                copyProjectAction({ ...data, projectId: +project?.key, isTemplate: true }),
            );
            if (payload) {
                dispatch(setTemplateProject(+project?.key));
                setOpenModals({ ...openModals, copy: false });
                alertService.success(AlertMessages.TEMPLATE_CREATED);
                refresh();
                riseAction();
            }
        }
    };

    const copyProductsFromProject = async () => {
        if (projectId && project?.id) {
            const { payload } = (await dispatch(
                importProductsFromProjectLibraryAction({
                    toProjectId: +projectId,
                    fromProjectId: project?.id,
                }),
            )) as ActionResponse<ImportProductsFromProjectLibraryResponse>;
            if (payload.data) {
                if (payload.data.existingProducts.length) {
                    getProducts(payload.data.existingProducts);
                } else {
                    history.push(`/project-info/${projectId}/0/2`);
                }
            }
        }
    };

    const getProducts = async (productIds: Array<number>) => {
        const { payload } = (await dispatch(
            getExternalProductsAction({
                productIds,
            }),
        )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;
        if (payload.data) {
            const products = Object.values(payload.data.mfSection)
                .reduce((agg, item: any) => agg.concat(item), Array<ExternalProduct>())
                .map((p) => ({ id: p.id, name: p.productName }));
            setOpenModals({ ...openModals, warning: true, existingProducts: products });
        } else {
            goToProducts();
        }
    };

    const createProjectFromTemplate = async (data: CopyProjectData | undefined) => {
        if (data && project) {
            const { payload } = (await dispatch(
                copyProjectAction({ ...data, projectId: +project?.key }),
            )) as ActionResponse<GetProjectInformationResponse>;
            if (payload.data) {
                setOpenModals({ ...openModals, createFromTemplate: false });
                riseAction();
                history.push(`/project-info/${payload.data.id}`);
            }
        }
    };

    const goToProducts = () => {
        setOpenModals({ ...openModals, warning: false });
        history.push(`/project-info/${projectId}/0/2`);
    };

    return (
        <>
            {project && openModals.delete && (
                <DeleteModal
                    isOpen={openModals.delete}
                    title={project.isTemplate ? 'template' : 'project'}
                    text={project.name}
                    closeModal={closeModal}
                    reload={reload}
                    icon="ic-modal-title-delete-project.svg"
                />
            )}
            {project && openModals.copy && (
                <CopyProject
                    isOpen={openModals.copy}
                    reload={reload}
                    closeModal={closeModal}
                    project={project}
                />
            )}
            {project && openModals.share && (
                <ShareProject
                    isOpen={openModals.share}
                    reload={reload}
                    closeModal={closeModal}
                    project={project}
                />
            )}
            {project && openModals.template && (
                <SaveTemplate
                    isOpen={openModals.template}
                    reload={reload}
                    closeModal={closeModal}
                    project={project}
                />
            )}
            {project && openModals.csv && (
                <ExportProject
                    isOpen={openModals.csv}
                    reload={reload}
                    closeModal={closeModal}
                    project={project}
                    projectReferences={projectReferences}
                    options={[
                        {
                            id: ExportProjectType.ALL_DIVISIONS_ALL_PRODUCTS.toString(),
                            name: 'All divisions, all products',
                        },
                        {
                            id: ExportProjectType.ALL_DIVISIONS_BASIS_DESIGN.toString(),
                            name: 'All divisions, basis of design only',
                        },
                        {
                            id: ExportProjectType.INSTALLED_PRODUCTS_ALL_DIVISIONS_ALL_PRODUCTS.toString(),
                            name: 'Installed products - all divisions, all products',
                        },
                        {
                            id: ExportProjectType.INSTALLED_PRODUCTS_ALL_DIVISIONS_BASIS_DESIGN.toString(),
                            name: 'Installed products - all divisions, basis of design only',
                        },
                    ]}
                />
            )}
            {project && openModals.warning && (
                <ExistingProductsModal
                    isOpen={openModals.warning}
                    closeModal={goToProducts}
                    existingProducts={openModals.existingProducts}
                    addedFrom={'Transparency Catalog'}
                    addedTo="project"
                    currentPage="/project-library"
                />
            )}
            {project && openModals.createFromTemplate && (
                <CreateFromTemplate
                    isOpen={openModals.createFromTemplate}
                    reload={reload}
                    closeModal={closeModal}
                    project={project}
                />
            )}
        </>
    );
};

export default ProjectTableActions;
