import React, { useEffect, useState } from 'react';
import { usePermissions } from '../../../hooks/permissions';
import { useAppDispatch } from '../../../hooks/redux';
import { ActionResponse } from '../../../models/actionResponse/types';
import { ProjectPermissionTypes } from '../../../models/permission/enums';
import { FavoriteType } from '../../../models/product/enums';
import { FavoriteProduct, Product } from '../../../models/product/types';
import { updateFavoritePricingInfoAction } from '../../../redux/actions/favoriteProductsActions';
import { updatePricingInfoAction } from '../../../redux/actions/productActions';
import { alertService } from '../../../services';
import { blueDark01 } from '../../../styles/colors';
import Switcher from '../../common/switcher/Switcher';
import { MergedFavoriteProduct } from '../../favoritProducts/FavoriteProducts.types';
import {
    PricingContainer,
    PricingContent,
    PricingInfoInput,
    PricingSaveButton,
    PricingSwitcherTitle,
    ProductPricingContainer,
    ProductPricingSwitcherContainer,
} from './PricingInfo.styles';

interface Props {
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    userRole?: ProjectPermissionTypes;
    openPricing?: string;
}
const ProductPricing: React.FC<Props> = ({ favoriteType, product, userRole, openPricing }) => {
    const dispatch = useAppDispatch();

    const { checkProductActionPermission } = usePermissions();

    const [state, setState] = useState({
        clicked: false,
        pricingInfo: product?.product?.pricingInformation,
    });

    const handleClicked = () => {
        setState({ ...state, clicked: !state.clicked });
    };

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setState({ ...state, pricingInfo: event.target.value });
    };

    useEffect(() => {
        if (openPricing && !+openPricing) {
            setState({ ...state, clicked: true });
        }
    }, [openPricing]);

    const submitPricingInfo = async () => {
        if (!product?.product?.pricingInformation && !state.pricingInfo) {
            return;
        }
        let result;
        if (favoriteType !== undefined) {
            const { payload } = (await dispatch(
                updateFavoritePricingInfoAction({
                    favoriteType: favoriteType,
                    productFavoriteId: product.id,
                    pricing: state.pricingInfo,
                }),
            )) as ActionResponse<FavoriteProduct>;
            result = payload;
        } else {
            const { payload } = (await dispatch(
                updatePricingInfoAction({
                    productId: product.product.productId,
                    projectId: product.product.projectId,
                    projectProductId: product.product.id,
                    pricing: state.pricingInfo,
                }),
            )) as ActionResponse<FavoriteProduct>;
            result = payload;
        }
        if (result?.data) {
            alertService.success('Pricing information was updated', {
                fixed: true,
            });
        }
    };

    return (
        <ProductPricingContainer>
            <ProductPricingSwitcherContainer>
                <Switcher
                    id={''}
                    clicked={state.clicked}
                    handleSwitch={handleClicked}
                    width={20}
                    height={20}
                />
                <PricingSwitcherTitle weight={400} height={18} size={14} color={blueDark01}>
                    Pricing
                </PricingSwitcherTitle>
            </ProductPricingSwitcherContainer>
            <PricingContent className={state.clicked ? 'clicked' : ''}>
                <PricingContainer>
                    <PricingInfoInput
                        placeholder="Add pricing information"
                        onChange={handleInput}
                        value={state.pricingInfo}
                        disabled={checkProductActionPermission(favoriteType, userRole)}
                    />
                    <PricingSaveButton
                        onClick={submitPricingInfo}
                        disabled={checkProductActionPermission(favoriteType, userRole)}
                    >
                        Save
                    </PricingSaveButton>
                </PricingContainer>
                <div></div>
            </PricingContent>
        </ProductPricingContainer>
    );
};

export default ProductPricing;
