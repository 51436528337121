import { Modal, Row } from 'antd';
import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    error,
    grayDark05,
    grayDark06,
    grayLight02,
} from '../../../../styles/colors';

export const AddFromAnotherSiteModal = styled(Modal)`
    width: 680px !important;
    height: 409px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    form {
        margin-top: 28px;
    }

    .input-block .ant-form-item-row {
        line-height: 16px;
    }

    .ant-form-item {
        margin-bottom: 0;

        .ant-select-selector {
            width: 280px;
            border-color: ${grayDark06};
        }

        .ant-select-selector:hover {
            border-color: ${grayDark05};
            border-radius: 3px;
        }

        .ant-select-selector:focus,
        .ant-select-selector-focused {
            border-color: ${blue01};
            border-radius: 3px;
            box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
        }

        .ant-select-selector-status-error:not(.ant-select-selector-disabled):not(
                .ant-select-selector-borderless
            ).ant-select-selector,
        .ant-select-selector-status-error:not(.ant-select-selector-disabled):not(
                .ant-select-selector-borderless
            ).ant-select-selector:hover {
            border-color: ${error};
        }
    }

    .ant-form-item-label > label {
        height: 16px;
        vertical-align: unset;
        margin-bottom: 4px;
        line-height: 16px !important;
        font-weight: normal;
        color: ${blueDark01} !important;

        &::before {
            display: none !important;
        }

        &::after {
            position: unset;
            top: 0;
            margin: 0;
        }
    }

    .ant-form-horizontal .ant-form-item-label {
        line-height: 14px;
    }

    .ant-input {
        width: 280px;
        border-radius: 3px;
        border-color: ${grayDark06};
        padding: 7px 10px 7px 10px;
        font-size: 13px;
        line-height: 16px;
    }

    .ant-input:hover {
        border-color: ${grayDark05};
        border-radius: 3px;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: ${blue01};
        border-radius: 3px;
        box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        border-color: ${error};
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${grayLight02};
    }

    #input-container.required label:after,
    .required#text-align-right label:after {
        content: '';
    }
`;

export const AddFromAnotherSiteRow = styled(Row)`
    .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin: 0;
    }
    width: 100%;
    gap: 40px;
    margin-bottom: 22px;

    label {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px !important;
        line-height: 16px !important;
        color: ${blueDark01} !important;
        white-space: nowrap;
    }
`;
