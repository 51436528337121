import styled from 'styled-components';
import { blueContact, blueDark01 } from '../../styles/colors';

export const NotFoundPageText = styled('div')`
    margin: 16px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark01};
`;

export const NotFoundPageList = styled('ul')`
    padding-left: 10px;
    li {
        a {
            color: ${blueContact};
        }
        &::marker {
            color: ${blueContact};
        }
    }
`;
