import { AccountPermissionTypes } from '../../../models/permission/enums';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { BaseUserTableFields } from '../../../models/user/user';

export interface SMAdminUserTableFields extends BaseUserTableFields {
    createdOn: string;
    email: string;
    firstName: string;
    id: number;
    isMainUser: boolean;
    isManufacturer: boolean;
    lastLoginTime: string | null;
    lastName: string;
    modifiedOn: string | null;
    officeName: string;
    organizationName: string;
    organizationSizeId: number;
    organizationSizeShortName: string;
    phoneNumber: string;
    phoneNumberExt: string;
    roleId: number;
    roleName: string | undefined;
    stripeCustomerId: string;
    stripeDashboardCustomerLink: string;
    stripeDashboardSubscriptionLink: string;
    stripeSubscriptionId: string;
    titleRoleId: number;
    titleRoleName: string;
    key: string;
    subscriptionPlanId: number;
    subscriptionStatus: string;
    services: string;
}

export class TableSMAdminUserData implements SMAdminUserTableFields {
    createdOn = '';
    email = '';
    firstName = '';
    id = 0;
    isMainUser = false;
    isManufacturer = false;
    lastLoginTime = null;
    lastName = '';
    modifiedOn = null;
    officeName = '';
    organizationName = '';
    organizationSizeId = 0;
    organizationSizeShortName = '';
    phoneNumber = '';
    phoneNumberExt = '';
    roleId = 0;
    roleName = '';
    stripeCustomerId = '';
    stripeDashboardCustomerLink = '';
    stripeDashboardSubscriptionLink = '';
    stripeSubscriptionId = '';
    titleRoleId = 0;
    titleRoleName = '';
    key = '';
    subscriptionPlanId = 0;
    subscriptionStatus = '';
    services= '';
}

export interface SMAdminTableFields extends BaseUserTableFields {
    createdOn: string;
    email: string;
    firstName: string;
    userName: string;
    id: number;
    lastLoginTime: string | null;
    lastName: string;
    modifiedOn: string | null;
    phoneNumber: string;
    phoneNumberExt: string;
    key: string;
}

export class TableSMAdminData implements SMAdminTableFields {
    createdOn = '';
    email = '';
    firstName = '';
    userName = '';
    id = 0;
    lastLoginTime = null;
    lastName = '';
    modifiedOn = null;
    phoneNumber = '';
    phoneNumberExt = '';
    key = '';
}

export interface SMAdminUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    userRoles: Array<UserRole>;
    permission: Array<PermissionItem>;
    accountId: number;
    subscriptionPlanName: SubscriptionTypes;
    subscriptionPlanId: number;
    accountUser: Array<AccountUser>;
    isMemberOfOtherAccount: boolean;
}

export interface PermissionItem {
    object: string;
    action: string;
    isAllowed: boolean;
}

interface UserRole {
    role: Role;
}

interface Role {
    name: string;
}

interface AccountUser {
    accountId: number;
    accountUserId: number;
    role: AccountRole;
    subscription: SubscriptionItem;
}

interface AccountRole {
    id: number;
    name: AccountPermissionTypes;
}

interface SubscriptionItem {
    id: number;
    name: SubscriptionTypes;
}
