import { Spin } from 'antd';
import styled from 'styled-components';
import { PageContainer } from '../../styles/common.styles';
import { InvisibleButton } from '../../styles/common.styles';
import { ProductsTabHeader } from '../../pages/projectInfo/tabs/productsTab/ProductsTab.styles';
import { black, blue01 } from '../../styles/colors';
import { Text } from '../../styles/common.styles';

export const FavoriteProductsHeader = styled(ProductsTabHeader)`
    justify-content: end;
`;

export const FavoritePageContainer = styled(PageContainer)`
    min-width: 1224px;
    padding: 0px 100px 60px 100px;
    margin-top: 16px;
`;

export const FavoriteTabContent = styled('div')`
    min-height: calc(100vh - 16rem);
`;

export const FavoriteSpin = styled(Spin)`
    top: 0 !important;
`;

export const NoProductsTitleContainer = styled('div')`
    height: calc(80vh - 16rem);
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
        font-size: 19px;
        line-height: 27px;
        color: ${blue01};
        font-family: 'DIN Next W01 Medium';
    }
`;

export const NoProductsTitleContainerFiltered = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
        font-size: 19px;
        line-height: 27px;
        color: ${blue01};
        font-family: 'DIN Next W01 Medium';
    }
`;

export const OpenCloseAllButton = styled(InvisibleButton)`
    align-items: flex-start;
    margin-top: 2px;
    &.closeOpenAll {
        margin-top: 16px;
    }
`;

export const FoundResultsCount = styled(Text)`
    font-family: 'DINNextLTPro';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: ${black};
    padding-bottom: 30px; 
   
`;