import React from "react";
import { Image } from 'antd';
import { BrandCompany, Product } from "../../../../../models/product/types";
import { StoredUser } from "../../../../../models/user/user";
import { BrandSectionInnerData, HelpButton, QuestionTipContainer, OneLineContainer } from "./ProductItem.styles";
import { blueDark01, blueLink } from "../../../../../styles/colors";
import { BoldText, Text } from "../../../../../styles/common.styles";
import { MasterFormatBackground } from "../../../../../models/product/enums";
import { EmailLink } from "../../../../mfrPage/MFRPageCompanyHeader.styles";
import { EmailContainer } from "./BrandInnerData.styles";

interface Props {
    user: StoredUser;
    product: Product;
    productStorage: string;
    brandInfo?: BrandCompany;
    sameBrand: boolean;
    openHelp: () => void;
}

const BrandInnerData: React.FC<Props> = ({
    user,
    product,
    productStorage,
    brandInfo,
    sameBrand,
    openHelp
}) => {

    const getStringInURLEncoding = (str: string): string => {
        return str.replaceAll(' ', '%20').replaceAll('\n', '%0D%0A');
    }

    const getOutsideTCEmailBody = (): string => {
        let body = 'Hi Sustainable Minds,\n\n';
        body += `Please contact ${brandInfo ? brandInfo.company : ''} on our behalf to request they create `;  
        body += `transparency documentation so they can be added to the Transparency Catalog. `; 
        body += `We have manually added them to our Project and/or Library for now.\n\n`;
        body += `Thank you,\n\n`;
        body += `[Your name, title]\n\n`;
        return body;
    }

    const getOutsideTCEmailHref = (): string => {
        const mailTo = 'sales@sustainableminds.com';
        const subject = getStringInURLEncoding(`Contact ${brandInfo ? brandInfo.company : ''} to create EPDs / material disclosures`);
        const body = getStringInURLEncoding(getOutsideTCEmailBody());
        return `mailto:${mailTo}?subject=${subject}&body=${body}`;
    }

    const getFreeListingEmailBody = (): string => {
        let body = 'Hi Sustainable Minds,\n\n';
        body += `Please contact ${brandInfo ? brandInfo.company : ''} on our behalf to request they activate `;
        body += 'their listing and add their products. It is OK to use our company name in the request.\n\n';
        body += 'Thank you,\n\n';
        body += `[Your name, title]\n\n`;
        return body;
    }

    const getFreeListingEmailHref = (): string => {
        const mailTo = 'sales@sustainableminds.com';
        const subject = getStringInURLEncoding(`Contact ${brandInfo ? brandInfo.company : ''} to add their products`);
        const body = getStringInURLEncoding(getFreeListingEmailBody());

        return `mailto:${mailTo}?subject=${subject}&body=${body}`;
    }

    if (productStorage !== 'TC') {
        return (
              <BrandSectionInnerData>
                <BoldText weight={400} size={14} height={18} color={blueDark01}>
                    {product.company}
                </BoldText>
                <OneLineContainer height={12}>
                    <Text weight={400} size={10} height={12} color={blueDark01}>
                        MFR MANUALLY ADDED	
                    </Text>
                    <QuestionTipContainer style={{marginLeft:'5'+'px', marginTop:'-6'+'px'}}>
                        <Image
                            src="src/assets/images/mfr-question.svg"
                            width={11}
                            height={12}
                            preview={false}
                        />
                        <div className="tip" style={{marginLeft:'-20'+'px', width:490+'px'}}>
                          <b>This manufacturer is not in the Transparency Catalog</b> because they haven't yet invested in EPDs or material disclosures for their products.
                          <br/><br/>
                          <a href={getOutsideTCEmailHref()} style={{color:'#134FBD'}}>Contact us</a> so we can invite them to get started.
                        </div>
                    </QuestionTipContainer>
                </OneLineContainer>
                <EmailContainer>
                    <EmailLink href={getOutsideTCEmailHref()}>
                        Request the MFR create EPDs and/or material disclosures
                    </EmailLink>
                </EmailContainer>
              </BrandSectionInnerData>
        );
    }

    if (!brandInfo) {
        return <></>;
    }

    const getProductInfoSectionTC = () => {
        if (!brandInfo) {
            return <></>;
        }
        if (brandInfo.level === MasterFormatBackground.FREE_LISTING) {
            return (
              <BrandSectionInnerData>                
                  <OneLineContainer height={12}>
                      <Text weight={400} size={10} height={12} color={blueDark01}>
                          FREE LISTING
                      </Text>
                      <QuestionTipContainer style={{marginLeft:'5'+'px', marginTop:'-6'+'px'}}>
                          <Image
                              src="src/assets/images/mfr-question.svg"
                              width={11}
                              height={12}
                              preview={false}
                          />
                         <div className="tip" style={{marginLeft:'-20'+'px', width:490+'px'}}>
                             <b>This manufacturer has invested in EPDs and/or material ingredient disclosures.</b>
                             <br/><br/>
                             <a href={getFreeListingEmailHref()} style={{color:'#134FBD'}}>Contact us</a> to recommend they activate their listing and add their products.
                         </div>
                      </QuestionTipContainer>
                  </OneLineContainer>
                <EmailContainer>
                    <EmailLink href={getFreeListingEmailHref()}>
                        Request MFR add their products
                    </EmailLink>
                </EmailContainer>
              </BrandSectionInnerData>
            );
        }

        if (brandInfo.contactEmail && brandInfo.contactInfo) {
            return (
                <HelpButton onClick={sameBrand ? undefined : openHelp}>
                    <Text weight={400} size={10} height={12} color={blueLink}>
                        Get spec & quote help
                    </Text>
                </HelpButton>
            );
        }

        return <></>;
    }

    return (
        <BrandSectionInnerData>
            <a href={brandInfo.listingUrl} target="_blank">
                <BoldText weight={400} size={14} height={18} color={blueLink}>
                    {product.company}
                </BoldText>
            </a>
            {getProductInfoSectionTC()}
        </BrandSectionInnerData>
    );
}

export default BrandInnerData;