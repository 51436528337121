import React from 'react';
import { ProgressBarContainer, ProgressBarLine, ProgressBarWrapper } from './ProgressBar.styles';

interface Props {
    total: number;
    completed: number;
    percent: number;
}
const ProgressBar: React.FC<Props> = ({ total, completed }) => {
    const progress = (completed / total) * 100;
    return (
        <ProgressBarContainer>
            <ProgressBarWrapper>
                <ProgressBarLine style={{ width: `${progress}%` }} />
            </ProgressBarWrapper>
        </ProgressBarContainer>
    );
};

export default ProgressBar;
