import styled from 'styled-components';
import { blue01, blueLink, grayLight01 } from '../../../../../styles/colors';
import { PrimaryButton, Text } from '../../../../../styles/common.styles';
import { Divider } from 'antd';

export const ExportTitle = styled('h2')`
    font-family: 'DINNextLTPro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${blue01};
    margin-bottom: 28px;
`;

export const ExportText = styled(Text)`
    button {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        color: ${blueLink};
        display: inline;
        &:hover {
            color: ${blueLink};
        }
        &:focus {
            background-color: transparent;
            color: inherit;
        }
    }
`;

export const ExportEC3Divider = styled(Divider)`
    border-top: 1px solid ${grayLight01};
    margin: 28px 0;
`;

export const ExportButton = styled(PrimaryButton)`
    margin-top: 20px;
    font-weight: 400 !important;
`;
