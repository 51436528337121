import React from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { GetProjectInformationResponse } from '../../../redux/actions/projectActions/types';
import { ProjectPermissionTypes } from '../../../models/permission/enums';
import { ProjectActionMenuItem } from '../../../components/projects/projectsTable/ProjectsTable.styles';
import { ProjectAction } from '../../../models/project/enums';
import { MFRProject } from '../../../models/project/types';

export const getProjectActionItems = (
    project: GetProjectInformationResponse | MFRProject,
    getProjectPermission: (
        availableRoles: ProjectPermissionTypes[],
        actualRole?: ProjectPermissionTypes | undefined,
    ) => boolean,
) => {
    let actions = Array<ItemType>();
    if (project.isTemplate) {
        actions = [
            {
                key: ProjectAction.DELETE,
                disabled: !getProjectPermission(
                    [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: <ProjectActionMenuItem>Delete</ProjectActionMenuItem>,
            },
        ];
        actions.push({
            key: ProjectAction.CSV,
            label: <ProjectActionMenuItem>Export to CSV</ProjectActionMenuItem>,
        });
    } else {
        if (
            getProjectPermission(
                [
                    ProjectPermissionTypes.OWNER,
                    ProjectPermissionTypes.EDITOR,
                    ProjectPermissionTypes.PROJECT_ADMIN,
                ],
                project.userRole,
            )
        ) {
            actions.push({
                key: ProjectAction.COPY,
                label: <ProjectActionMenuItem>Copy Project</ProjectActionMenuItem>,
            });
        }
        actions.push({
            key: ProjectAction.CSV,
            label: <ProjectActionMenuItem>Export to CSV</ProjectActionMenuItem>,
        });
        actions.push({
            key: ProjectAction.EC3,
            label: <ProjectActionMenuItem>Export to EC3</ProjectActionMenuItem>,
        });
        if (
            getProjectPermission(
                [
                    ProjectPermissionTypes.OWNER,
                    ProjectPermissionTypes.EDITOR,
                    ProjectPermissionTypes.PROJECT_ADMIN,
                ],
                project.userRole,
            )
        ) {
            actions.push({
                key: ProjectAction.TEMPLATE,
                label: <ProjectActionMenuItem>Save as Template</ProjectActionMenuItem>,
            });
            actions = actions.concat([
                { type: 'divider' },
                {
                    key: ProjectAction.COMPLETED,
                    label: (
                        <ProjectActionMenuItem>
                            {project.isCompleted ? 'Active' : 'Completed'}
                        </ProjectActionMenuItem>
                    ),
                },
            ]);
        }
        if (
            getProjectPermission(
                [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                project.userRole,
            )
        ) {
            actions.push({
                key: ProjectAction.DELETE,
                label: <ProjectActionMenuItem>Delete</ProjectActionMenuItem>,
            });
        }
    }
    return actions;
};
