export interface AvailabilityConfig {
    production: boolean;
    staging: boolean;
    development: boolean;
}

export interface FunctionalityParams {
    functionalityName: string,
    availabilityConfig: AvailabilityConfig
}

export const UPLOAD_PHOTOS = 'upload_photos';

export const FUNCTIONALITIES_PARAMS: FunctionalityParams[] = [
    {
        functionalityName: UPLOAD_PHOTOS,
        availabilityConfig: {
            production: true,
            staging: true,
            development: true,
        }
    },
];
