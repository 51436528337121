import React, { useEffect, useRef } from 'react';

export const Container: React.FC<{ children: React.ReactNode, spanset: boolean }> = ({ children, spanset }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const tipRoot = containerRef.current as any;
        if (tipRoot) {
            const tipChild = tipRoot.children[0];
            const tipParent = tipRoot.parentElement;

            tipParent.addEventListener('mouseenter', (e: any) => {
                handleDropdownPosition(e.pageY, e.offsetY, tipChild);
            });
        }
    });

    const handleDropdownPosition = (posYabs: number, posYrel: number, target: Element) => {
        const tipHeight: number = target.offsetHeight + 50;
        const pageHeight: number = document.body.scrollHeight;

        const top = posYabs - posYrel;
        const bottom = top + tipHeight;
        const offset = pageHeight - bottom;

        target.style.transform = 'translate(0, ' + (offset < 0 ? offset : 0) + 'px)';
    };

    if (spanset === true) {
        return <span ref={containerRef}>{children}</span>;
    } else {
        return <div ref={containerRef}>{children}</div>;
    }
};
