import React from 'react';
import { Image, Space } from 'antd';
import { CustomColumnType } from '../../components/common/table/CustomTable.types';
import { toShortLocalDate } from '../../helpers/dateHelper';
import { RecentlyProjectsTableFields } from '../../models/project/types';
import { BoldText, Text } from '../../styles/common.styles';
import {
    DashboardCaretDownContainer,
    DashboardClickableRow,
    DashboardImageContainer,
    DashboardProjectName,
    DashboardTableTextContainer,
    NotificationLink,
    RecentlyFavoritesAdded,
} from './Dashboard.styles';
import { grayDark03 } from '../../styles/colors';
import { NavLink, useHistory } from 'react-router-dom';
import { ProductFavorite } from '../../models/productFavorite/types';
import GenerateProjectNotificationLink from '../../components/generateProjectNotificationLink/GenerateProjectNotificationLink';

export const columns = (): CustomColumnType<RecentlyProjectsTableFields>[] => {
    const history = useHistory();
    const clickRow = (record: RecentlyProjectsTableFields) => {
        if (!record.projectIsDeleted) {
            history.push(`/project-info/${record.projectId}`);
        }
    };

    return [
        {
            title: 'PROJECT',
            clickable: true,
            onCell: (record) => ({
                onClick: () => clickRow(record),
            }),
            render: (record: RecentlyProjectsTableFields) => (
                <div className="project-name">
                    <DashboardImageContainer>
                        {!record.projectImageFilePath || record.projectIsDeleted ? (
                            <>
                                <Image
                                    src="src/assets/images/image.svg"
                                    width={24}
                                    height={21}
                                    preview={false}
                                    alt="project image"
                                />
                                <Image
                                    src="src/assets/images/table-image.png"
                                    width={96}
                                    height={54}
                                    preview={false}
                                    alt=""
                                />
                            </>
                        ) : (
                            <Image
                                src={`${process.env.REACT_APP_API_URL}/static/${record.projectImageFilePath}`}
                                width={96}
                                height={54}
                                preview={false}
                                alt="project image"
                            />
                        )}
                    </DashboardImageContainer>
                    <NavLink
                        to={record.projectIsDeleted ? '' : `/project-info/${record.projectId}`}
                    >
                        <DashboardProjectName className="bold">
                            {record.projectName}
                        </DashboardProjectName>
                    </NavLink>
                </div>
            ),
        },
        {
            title: <DashboardCaretDownContainer>LAST MODIFIED</DashboardCaretDownContainer>,

            dataIndex: 'lastActivityOn',
            clickable: true,
            onCell: (record) => ({
                onClick: () => clickRow(record),
            }),
            render: (value) => (
                <DashboardTableTextContainer>
                    <Text weight={400} size={12} height={16} color={grayDark03}>
                        {toShortLocalDate(value)}
                    </Text>
                </DashboardTableTextContainer>
            ),
        },
        {
            title: 'ACTIVITY NOTIFICATIONS',
            render: (data: RecentlyProjectsTableFields) => (
                <DashboardTableTextContainer>
                    <Space>
                        <BoldText height={16} weight={400} size={12} color={grayDark03}>
                            {data.authorName}
                            <GenerateProjectNotificationLink data={data} />
                        </BoldText>
                    </Space>
                </DashboardTableTextContainer>
            ),
        },
        {
            render: (data: RecentlyProjectsTableFields) => (
                <Space>
                    {!data.projectIsDeleted && (
                        <>
                            <NotificationLink to={`/project-info/${data.projectId}/0/4`}>
                                See all
                            </NotificationLink>
                            <Image
                                src="src/assets/images/bell.svg"
                                width={16}
                                preview={false}
                                alt="see all"
                            />
                        </>
                    )}
                </Space>
            ),
            width: 20,
        },
    ];
};

export const productColumns = (): CustomColumnType<ProductFavorite>[] => {
    const history = useHistory();
    const goToFavoriteProduct = (data: ProductFavorite) => {
        if (data.product.isMyFavorite) {
            history.push(`/my-favorites/0/${data.product.productId}`);
        } else {
            history.push(`/favorites/0/${data.product.productId}`);
        }
    };
    return [
        {
            title: 'PRODUCT',
            dataIndex: 'name',
            clickable: true,
            render: (value, data: ProductFavorite) => (
                <Space>
                    <DashboardClickableRow onClick={() => goToFavoriteProduct(data)}>
                        {value}
                    </DashboardClickableRow>
                </Space>
            ),
        },
        {
            title: <DashboardCaretDownContainer>ADDED</DashboardCaretDownContainer>,
            dataIndex: 'modifiedOn',
            render: (value) => (
                <RecentlyFavoritesAdded
                    weight={400}
                    size={12}
                    height={16}
                    color={grayDark03}
                    font-family="Proxima Nova"
                >
                    {toShortLocalDate(value)}
                </RecentlyFavoritesAdded>
            ),
        },
        {
            title: 'ADDED BY',
            dataIndex: 'createdBy',
            render: (value) => <span>{value}</span>,
        },
    ];
};
