import React, { useState } from 'react';
import { Input } from 'antd';
import ModalTitle from '../../../common/modalTitle/ModalTitle';
import { MFRProject, ProjectTableFields } from '../../../../models/project/types';
import { CopyProjectData } from '../ProjectActions.types';
import ModalFooter from '../../../common/modalFooter/ModalFooter';
import { alertService } from '../../../../services';
import { SaveTemplateModal } from './SaveTemplate.styles';
import { CloseIcon } from '../../../../styles/common.styles';
import CustomInput from '../../../common/inputs/CustomInput';
import { GetProjectInformationResponse } from '../../../../redux/actions/projectActions/types';
import { ProjectAction } from '../../../../models/project/enums';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: ProjectAction.TEMPLATE }) => void;
    reload: (modal: string, data: CopyProjectData) => void;
    project: ProjectTableFields | GetProjectInformationResponse | MFRProject;
}

const SaveTemplate: React.FC<Props> = ({ isOpen, closeModal, reload, project }) => {
    const [state, setState] = useState<CopyProjectData>({
        name: project.name,
    });

    const handleCancel = () => {
        closeModal({ key: ProjectAction.TEMPLATE });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.id]: event.target.value });
    };

    const onSubmit = () => {
        if (!state.name.trim()) {
            alertService.warn('project name cannot contain only spaces');
        }
        if (state.name.trim()) {
            reload(ProjectAction.TEMPLATE, state);
        }
    };
    return (
        <SaveTemplateModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <ModalTitle title="Save project as template" />
            <CustomInput
                Component={Input}
                label="Name"
                name="name"
                block
                required
                rules={[{ required: true, message: '' }]}
                props={{
                    defaultValue: state.name,
                    onChange: handleChange,
                }}
            />
            <ModalFooter
                cancel={handleCancel}
                submit={onSubmit}
                action="Save template"
                actionButtonWidth={120}
            />
        </SaveTemplateModal>
    );
};

export default SaveTemplate;
