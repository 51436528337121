import styled from "styled-components";
import { DocumentsContainer } from "./UploadTable.styles";
import { blueLink, grayDark07 } from "../../../../styles/colors";

export const ImagesTabContainer = styled('div')``;

export const FilterContainer = styled('div')`
    width: 275px;
    height: 28px;
    margin-bottom: 28px;
    .ant-form-item-control-input {
        min-height: 28px;
    }
    .ant-input, .ant-col {
        height: 28px;
    }
    .ant-form-item {
        margin-bottom: 0;
    }
    .customInputFormItem {
        font-family: "Proxima Nova";
    }
    .ant-form-item-row {
        gap: 10px;
    }
`;

export const ImagesContainer = styled(DocumentsContainer)`
    height: fit-content;
    min-height: 529px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 28px;
    column-gap: 14px;
    padding: 14px 14px 18px 14px;
    &::after {
        display: none;
    }
`;

export const ImageItemContainer = styled('div')`
    display: -webkit-flex;
    flex-direction: column;
    line-height: initial;
    height: fit-content;
    cursor: pointer;
    max-width: 170px;
    img {
        border: 1px solid ${grayDark07};
        width: 100%;
        height: 100%;
        object-fit: contain; // use cover or fill option if you want an image to fully cover the div, or contain
        max-height: 170px;
    }
    &:hover img {
        border: 1px solid ${blueLink};
    }
`;

export const ImageNameContainer = styled('span')`
    font-family: 'Proxima N W15 Bold';
    text-overflow: ellipsis;
    color: ${blueLink};
    margin-top: 7px;
`;

export const ImageContainer = styled('div')`
    aspect-ratio: 1;
    position: relative;
`;