import styled from 'styled-components';
import { Modal } from 'antd';

export const IFRAME = styled('iframe')`
    width: 100%;
    height: 100%;
`;

export const TransparencyCatalogSearchModal = styled(Modal)`
    .ant-modal-body {
        height: 1000px;
        width: 1000px;
    }
`;
