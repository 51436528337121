import { MenuItem } from '../../models/common/types';

export const actionMenu: Array<MenuItem> = [
    {
        key: 'role',
        label: 'Change account type',
    },
    {
        key: 'delete',
        label: 'Delete',
    },
];

export const createAccountMenu: Array<MenuItem> = [
    {
        key: 'one',
        label: 'Create one account',
    },
    {
        key: 'bulk',
        label: 'Create accounts in bulk',
    },
];
