import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React from 'react';
import { ProjectTableFields } from '../../../models/project/types';
import { ProjectActionMenuItem } from './ProjectsTable.styles';
import { usePermissions } from '../../../hooks/permissions';
import { ProjectPermissionTypes } from '../../../models/permission/enums';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { useAppSelector } from '../../../hooks/redux';
import { ProjectAction } from '../../../models/project/enums';
export const getProjectActions = (project: ProjectTableFields) => {
    const { getProjectPermission } = usePermissions();
    let actions = Array<ItemType>();
    if (project.isTemplate) {
        actions = [
            {
                key: ProjectAction.DELETE,
                disabled: !getProjectPermission(
                    [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: <ProjectActionMenuItem>Delete</ProjectActionMenuItem>,
            },
            {
                key: ProjectAction.CSV,
                disabled: !getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                        ProjectPermissionTypes.COMMENTER,
                    ],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                        ProjectPermissionTypes.COMMENTER,
                    ],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: <ProjectActionMenuItem>Export to CSV</ProjectActionMenuItem>,
            }
        ];
    } else {
        actions = [
            {
                key: ProjectAction.SHARE,
                disabled: !getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                        ProjectPermissionTypes.MANUFACTURER,
                    ],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.MANUFACTURER,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                    ],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: <ProjectActionMenuItem>Share</ProjectActionMenuItem>,
            },
            {
                key: ProjectAction.COPY,
                disabled: !getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                    ],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                    ],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: <ProjectActionMenuItem>Copy Project</ProjectActionMenuItem>,
            },
            {
                key: ProjectAction.CSV,
                disabled: !getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                        ProjectPermissionTypes.COMMENTER,
                    ],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                        ProjectPermissionTypes.COMMENTER,
                    ],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: <ProjectActionMenuItem>Export to CSV</ProjectActionMenuItem>,
            },
            {
                key: ProjectAction.TEMPLATE,
                disabled: !getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                    ],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                    ],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: <ProjectActionMenuItem>Save as Template</ProjectActionMenuItem>,
            },
            { type: 'divider' },
            {
                key: ProjectAction.COMPLETED,
                disabled: !getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                    ],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [
                        ProjectPermissionTypes.OWNER,
                        ProjectPermissionTypes.EDITOR,
                        ProjectPermissionTypes.PROJECT_ADMIN,
                    ],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: (
                    <ProjectActionMenuItem>
                        {project.isCompleted ? 'Active' : 'Completed'}
                    </ProjectActionMenuItem>
                ),
            },
            {
                key: ProjectAction.DELETE,
                disabled: !getProjectPermission(
                    [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                    project.userRole,
                ),
                className: getProjectPermission(
                    [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                    project.userRole,
                )
                    ? ''
                    : 'disabled',
                label: <ProjectActionMenuItem>Delete</ProjectActionMenuItem>,
            },
        ];
    }

    return actions;
};

export const getAddProductsFromProject = () => {
    return [
        {
            key: ProjectAction.IMPORT,
            label: (
                <ProjectActionMenuItem>Add all products from this project</ProjectActionMenuItem>
            ),
        },
    ];
};

export const getMFRTemplateActions = (project: ProjectTableFields, projectId?: string) => {
    const { getAccountSubscription } = usePermissions();
    const authReducer = useAppSelector((state) => state.authReducer);
    if (projectId) {
        return [
            {
                key: ProjectAction.IMPORT,
                label: (
                    <ProjectActionMenuItem>
                        Add all products from this project
                    </ProjectActionMenuItem>
                ),
            },
        ];
    }

    if (authReducer.user?.id === project.createdById) {
        return [
            {
                key: ProjectAction.DELETE,
                label: <ProjectActionMenuItem>Delete</ProjectActionMenuItem>,
            },
        ];
    }
    const result = [
        {
            key: ProjectAction.CSV,
            label: <ProjectActionMenuItem>Export to CSV</ProjectActionMenuItem>,
        },
    ];
    if (getAccountSubscription() !== SubscriptionTypes.FREE) {
        result.unshift({
            key: ProjectAction.CREATE_FROM_TEMPLATE,
            label: <ProjectActionMenuItem>New Project from Template</ProjectActionMenuItem>,
        });
    }
    return result;
};
