import React from 'react';
import { SMAdminUserTableFields } from '../../../models/user/smAdminUser';

import { toShortLocalDate, toShortLocalTime } from '../../../../helpers/dateHelper';
import { CustomColumnType } from '../../../../components/common/table/CustomTable.types';
import { ClickableRow } from '../../../../styles/common.styles';
import { NavLink } from 'react-router-dom';
import { Dropdown, Image } from 'antd';
import {
    UsersTableEmail,
    UsersTableGraySpan,
    UsersTableLastNameContainer,
} from '../../../../components/users/usersTable/UsersTable.styles';
import { SMUsersTableFlexRow, OpenMenuButton } from './SMAdminUsersTable.styles';
import { getSMAdminTableActions, getSMAdminUsersTableActions } from './SMAdminUsersTable.helper';
import { UserType } from '../../../pages/users/SMAdminUsersPage';
import { SubscriptionPlan } from '../../../../redux/actions/userActions/types';
import { formatSubscription } from '../../../../helpers/textFormatter';

interface ActionsProps {
    user: SMAdminUserTableFields;
    handleActions: (action: string, user: SMAdminUserTableFields) => void;
    userType: UserType;
}

const TableRowActions: React.FC<ActionsProps> = ({ user, handleActions, userType }) => {
    const submitAction = (data: { key: string }) => {
        handleActions(data.key, user);
    };

    return (
        <Dropdown
            menu={{
                onClick: submitAction,
                items:
                    userType === 'user'
                        ? getSMAdminUsersTableActions(user)
                        : getSMAdminTableActions(),
            }}
            placement="bottom"
            trigger={['click']}
        >
            <div>
                <OpenMenuButton>
                    <Image
                        src="src/assets/images/ellipsis-vertical.svg"
                        height={16}
                        preview={false}
                    />
                </OpenMenuButton>
            </div>
        </Dropdown>
    );
};

export const columns = (
    handleActions: (action: string, user: SMAdminUserTableFields) => void,
    userType: UserType,
    subscriptions: Array<SubscriptionPlan>,
): Array<CustomColumnType<SMAdminUserTableFields>> =>
    (
        [
            {
                title: 'Last name',
                sorter: true,
                dataIndex: 'lastName',
                clickable: true,
                render: (value, record) => (
                    <NavLink to={`/${userType}s/edit/${record.key}`}>
                        <UsersTableLastNameContainer>
                            <ClickableRow>{value}</ClickableRow>
                            {record.isManufacturer && (
                                <Image
                                    src="src/assets/images/publisher-icon.svg"
                                    height={12}
                                    preview={false}
                                />
                            )}
                        </UsersTableLastNameContainer>
                    </NavLink>
                ),
            },
            {
                title: 'First name',
                sorter: true,
                dataIndex: 'firstName',
                clickable: true,
                render: (value, record) => (
                    <NavLink to={`/${userType}s/edit/${record.key}`}>
                        <ClickableRow>{value}</ClickableRow>
                    </NavLink>
                ),
            },
            {
                title: 'Email',
                sorter: true,
                dataIndex: 'email',
                clickable: true,
                render: (value, record) => (
                    <NavLink to={`/${userType}s/edit/${record.key}`}>
                        <UsersTableEmail>{value}</UsersTableEmail>
                    </NavLink>
                ),
            },
            userType === 'user'
                ? {
                    title: 'Title',
                    sorter: true,
                    dataIndex: 'titleRoleName',
                    clickable: true,
                    render: (value) => <UsersTableGraySpan>{value}</UsersTableGraySpan>,
                  }
                : {},
            userType === 'user'
                ? {
                      title: 'SUBSCR',
                      sorter: true,
                      dataIndex: 'subscriptionPlan',
                      render: (_value, record: SMAdminUserTableFields) => (
                          <UsersTableGraySpan>
                              {formatSubscription(
                                  subscriptions.find((s) => s.id === record.subscriptionPlanId)
                                      ?.name,
                              )}
                          </UsersTableGraySpan>
                      ),
                  }
                : {},
            userType === 'user'
                ? {
                      title: 'STATUS',
                      sorter: true,
                      dataIndex: 'subscriptionStatus',
                      render: (value) => <UsersTableGraySpan>{value}</UsersTableGraySpan>,
                  }
                : {},
            userType === 'user'
                ? {
                      title: 'ORG',
                      sorter: true,
                      dataIndex: 'organizationName',
                      render: (value) => <UsersTableGraySpan>{value}</UsersTableGraySpan>,
                  }
                : {},
            userType === 'user'
                ? {
                      title: 'SIZE',
                      sorter: true,
                      dataIndex: 'organizationSizeShortName',
                      render: (value) => <UsersTableGraySpan>{value}</UsersTableGraySpan>,
                  }
                : {},
            userType === 'user'
                ? {
                      title: 'SERVICES',
                      sorter: false,
                      dataIndex: 'services',
                      render: (value) => <UsersTableGraySpan>{value}</UsersTableGraySpan>,
                  }
                : {},
            userType === 'user'
                ? {
                      title: 'ACCOUNT',
                      sorter: true,
                      dataIndex: 'roleName',
                      render: (value) => (
                          <UsersTableGraySpan>
                              {new String(value).replace('Account', '')}
                          </UsersTableGraySpan>
                      ),
                  }
                : {},
            userType === 'admin'
                ? {
                      title: 'Phone Number',
                      dataIndex: 'phoneNumber',
                      render: (value) => <UsersTableGraySpan>{value}</UsersTableGraySpan>,
                  }
                : {},
            {
                title: 'CREATED',
                sorter: true,
                dataIndex: 'createdOn',
                render: (value, record) => (
                    <UsersTableGraySpan>
                        <SMUsersTableFlexRow>
                            <div>{toShortLocalDate(value)}</div>
                        </SMUsersTableFlexRow>
                    </UsersTableGraySpan>
                ),
            },
            userType === 'user'
                ? {
                    title: 'LAST LOGIN',
                    sorter: true,
                    dataIndex: 'lastLoginTime',
                    render: (value, record) => (
                      <UsersTableGraySpan>
                          <SMUsersTableFlexRow>
                              <div>{toShortLocalDate(value)} {toShortLocalTime(value)}</div>
                              <TableRowActions
                                  user={record}
                                  handleActions={handleActions}
                                  userType={userType}
                              />
                          </SMUsersTableFlexRow>
                      </UsersTableGraySpan>
                    ),
                   }
                : {},
        ] as Array<CustomColumnType<SMAdminUserTableFields>>
    ).filter((value) => {
        return Object.keys(value).length > 0;
    });
