import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { blueContact, blueDark01, grayDark07 } from '../../../../styles/colors';
import { PaginationContainer } from '../../../common/table/CustomTable.styles';
import { PrimaryButton, SecondaryButton } from '../../../../styles/common.styles';

export const ImageModalWindow = styled(Modal)`
    .ant-modal-content {
        margin: 56px 0px 49px 0px;
    }
    .ant-modal-body {
        padding: 56px 40px 28px 40px;
    }
    &.readOnly .ant-modal-body {
        padding-bottom: 48px;
    }
`;

export const ImageModalContainer = styled('div')`
    display: flex;
    flex-direction: column;
    .customInputFormItemLabel {
        font-family: 'Proxima N W15 Bold';
        color: ${blueDark01};
        line-height: 16px;
    }
`;

export const ImageContainer = styled('div')`
    display: flex;
    justify-content: center;
    img {
        width: 100%;
        max-width: 770px;
        border: 1px solid ${grayDark07};
    }
`;

export const ImagesPaginationContainer = styled(PaginationContainer)`
    display: flex;
    justify-content: end;
    margin-top: 16px;
    user-select: none;
`;

export const DoubleTextInputsContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    gap: 28px;
    margin-top: 24px;
`;

export const DoubleInputContainer = styled('div')`
    width: 50%;
    height: auto;
    .altTextContainer {
        height: 0px;
    }
    .altTextTipContainer {
        top: -58px;
        left: 155px;
    }
    .ant-form-item {
        margin-bottom: 0px;
    }
    .ant-input {
        line-height: 18px;
    }
`;

export const DescriptionContainer = styled('div')`
    width: 100%;
    margin-top: 16px;
    #description {
        height: 64px;
        line-height: 18px;
    }
    .ant-form-item {
        margin-bottom: 0px;
    }
`;

export const ButtonsRowContainer = styled('div')`
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 36px;
    border-top: 1px solid #E3E3E3;
    padding-top: 20px;
`;

export const ButtonsContainer = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const SaveButton = styled(PrimaryButton)`
    width: 92px;
    height: 32px;
`;

export const DownloadButton = styled(SecondaryButton)`
    width: 100px;
    height: 32px;
    margin-right: 12px;
`;

export const DeleteButton = styled(Button)`
    margin-right: 24px;
    padding-right: 0px;
    color: ${blueContact};
    line-height: 18px;
    &:hover, &:focus, &:active, &:focus-visible {
        color: ${blueContact};
    }
`;