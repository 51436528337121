import React, { useEffect, useState } from 'react';
import CustomTable from '../../common/table/CustomTable';
import { extractFields } from '../../../helpers/extract';
import {
    Project,
    ProjectLibrary,
    ProjectReferences,
    ProjectTableFields,
    ProjectUserData,
} from '../../../models/project/types';
import { TableData } from '../../../models/tableData/types';
import ProjectTableActions from './ProjectTableActions';
import { useHistory } from 'react-router-dom';
import { ProjectLibraryContainer, ProjectLibraryTable } from './ProjectsTable.styles';
import { Spin } from 'antd';
import ProjectLibrarySearch from './ProjectTableSearch';
import { SortOrder } from 'antd/lib/table/interface';
import {
    LibraryType,
    ProjectAction,
    ProjectFieldKey,
    ProjectLibraryCountType,
} from '../../../models/project/enums';
import { useAppDispatch } from '../../../hooks/redux';
import { getProjectReferencesAction } from '../../../redux/actions/projectActions';
import { ActionResponse } from '../../../models/actionResponse/types';
import EmptyData from '../../common/emptyData/EmptyData';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { AccountPermissionTypes, UserRoleTypes } from '../../../models/permission/enums';
import { usePermissions } from '../../../hooks/permissions';

interface Props {
    library: ProjectLibrary;
    getProjects: (
        libraryType: LibraryType,
        tableData: TableData,
        isInitial: boolean,
        countType?: ProjectLibraryCountType,
    ) => void;
    additionalItems?: Array<Project>;
    libraryType: LibraryType;
    countType: ProjectLibraryCountType;
    pending: boolean;
    projectId?: string;
    columns: any;
}

const ProjectsTable: React.FC<Props> = ({
    library,
    getProjects,
    libraryType,
    countType,
    additionalItems = [],
    pending,
    projectId,
    columns,
}) => {
    const { projects, totalCount } = library;
    const projectWithKeys = extractFields(Object.keys(new ProjectUserData()), projects);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [tableData, setTableData] = useState<TableData>({
        curPage: 1,
        pageSize: 10,
        searchString: '',
        sortDirection: 'asc',
        sortExpression: null,
    });

    const [projectReferences, setProjectReferences] = useState<ProjectReferences | null>(null);

    const [actionData, setActionData] = useState<{
        project: ProjectTableFields | null;
        key: ProjectAction | null;
        projectId?: string;
    }>({
        key: null,
        project: null,
        projectId: undefined,
    });

    const { getUserRole, getAccountRole, getAccountSubscription } = usePermissions()

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await getReferences();
        getProjects(libraryType, tableData, false);
    };

    const getReferences = async () => {
        const { payload } = (await dispatch(
            getProjectReferencesAction(),
        )) as ActionResponse<ProjectReferences>;
        setProjectReferences(payload.data);
    };

    const changeSearch = (searchString: string) => {
        if (!searchString && tableData.searchString) {
            getProjects(libraryType, { ...tableData, searchString: '' }, false, countType);
        }
        setTableData({ ...tableData, searchString });
    };

    const submitSearch = () => {
        getProjects(libraryType, tableData, false, countType);
    };

    const changePageSize = (pageSize: number) => {
        if (Math.ceil(totalCount / pageSize) < tableData.curPage) {
            tableData.curPage = Math.ceil(totalCount / pageSize);
        }
        setTableData({ ...tableData, pageSize: pageSize });
        getProjects(libraryType, { ...tableData, pageSize: pageSize }, false, countType);
    };

    const handleCurrentPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentCount = event.currentTarget.value;
        if (/^-?\d*\.?\d*$/.test(currentCount) || currentCount === '') {
            setTableData({ ...tableData, curPage: +event.currentTarget.value });
            if (+currentCount > Math.ceil(totalCount / tableData.pageSize)) {
                setTimeout(() => {
                    setTableData({
                        ...tableData,
                        curPage: Math.ceil(totalCount / tableData.pageSize),
                    });
                    changePageNumber(Math.ceil(totalCount / tableData.pageSize));
                }, 1000);
            }
        }
        if (
            +event.currentTarget.value > 0 &&
            +event.currentTarget.value <= Math.ceil(totalCount / tableData.pageSize)
        ) {
            changePageNumber(+event.currentTarget.value);
        }
    };

    const changePageNumber = (pageNumber: number) => {
        setTableData({ ...tableData, curPage: pageNumber });
        getProjects(libraryType, { ...tableData, curPage: pageNumber }, false, countType);
    };

    const changeSort = (field: string, direction?: SortOrder) => {
        const newTableData = {
            ...tableData,
            sortDirection: direction === 'descend' ? 'desc' : 'asc',
            sortExpression: direction
                ? ProjectFieldKey[field?.charAt(0).toUpperCase() + field?.slice(1)] || 'name'
                : null,
        };
        setTableData(newTableData);
        getProjects(libraryType, newTableData, false, countType);
    };

    const clickRow = (data: ProjectTableFields) => {
        history.push(`/project-info/${data.key}`);
    };

    const handleActions = (action: ProjectAction, project: ProjectTableFields) => {
        setActionData({ key: action, project, projectId });
    };
    const riseAction = () => {
        setActionData({ key: null, project: null });
    };

    const reloadPage = () => {
        getProjects(libraryType, tableData, false, countType);
    };

    const getMessageForEmptySpace = () => {
        switch (libraryType) {
            case LibraryType.Completed:
                return `Projects you mark as "Completed" will display here.`;
            default:
                if (getAccountSubscription() !== SubscriptionTypes.FREE && getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE) {
                    return `Welcome! Start a Project by clicking the "Start a project" button at the top of your screen.`;
                }
                else if (getAccountSubscription() === SubscriptionTypes.FREE) {
                    return "Upgrade to a subscription to start Projects.";
                }
                else if (getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE) {
                    return "Upgrade to a Member account to start Projects.";
                }
                return "";
        }
    };

    return (
        <ProjectLibraryContainer>
            <ProjectLibraryTable>
                <Spin size="large" spinning={pending}>
                    <CustomTable
                        changeSort={changeSort}
                        tableData={{
                            ...tableData,
                            pageSize:
                                totalCount < tableData.pageSize
                                    ? tableData.pageSize
                                    : additionalItems.length + tableData.pageSize,
                        }}
                        data={projectWithKeys}
                        showSearch
                        searchPlaceholder="product, building type, etc."
                        changeSearch={changeSearch}
                        submitSearch={submitSearch}
                        columns={columns(handleActions, libraryType, projectId, getAccountSubscription() !== SubscriptionTypes.FREE)}
                        tableName="Projects"
                        changePageSize={changePageSize}
                        changePageNumber={changePageNumber}
                        clickRow={clickRow}
                        totalCount={totalCount}
                        handleCurrentPage={handleCurrentPage}
                        locale={{
                            emptyText: (
                                <EmptyData
                                    text={
                                        tableData.searchString
                                            ? 'No results found'
                                            : getMessageForEmptySpace()
                                    }
                                    pending={pending}
                                />
                            ),
                        }}
                        searchComponent={
                            <ProjectLibrarySearch
                                tableData={tableData}
                                changeSearch={changeSearch}
                                handleSearchSubmit={submitSearch}
                            />
                        }
                    />
                    {actionData.key && (
                        <ProjectTableActions
                            action={actionData.key}
                            project={actionData.project}
                            projectReferences={projectReferences}
                            riseAction={riseAction}
                            refresh={reloadPage}
                            projectId={projectId}
                        />
                    )}
                </Spin>
            </ProjectLibraryTable>
        </ProjectLibraryContainer>
    );
};

export default ProjectsTable;
