import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { http } from '../../../helpers/request';
import { EC3ErrorStatus } from '../../../models/ec3/enums';
import { EC3Error } from '../../../models/ec3/types';
import { alertService } from '../../../services';
import {
    ExportToEC3Request,
    ExportToEC3Response,
    GetEC3AuthDataRequest,
    GetEC3AuthDataResponse,
    GetEC3ExportDataRequest,
    RefreshEC3AuthTokensRequest,
} from './types';

const baseUrl = '/ec3';

export const exportToEC3Action = createAsyncThunk<
    ExportToEC3Response | EC3ErrorStatus | undefined,
    ExportToEC3Request
>('ec3/exportToEC3', async (data: ExportToEC3Request) => {
    try {
        const result = await http<ExportToEC3Response, ExportToEC3Request>(
            {
                path: `${baseUrl}/export-project`,
                method: 'post',
                body: data,
            },
            true,
            false,
            true,
        );

        console.log('ExportProjectToEC3:');
        console.log(result.parsedBody);
        return result.parsedBody;
    } catch (error) {
        if (error.status === 401) {
            return EC3ErrorStatus.TokenExpired;
        }

        const ec3Error = JSON.parse(error) as EC3Error;
        alertService.error(
            ec3Error.validation_errors?.non_field_errors[0] || ec3Error?.detail || '',
        );
        return undefined;
    }
});

export const getExportDataAction = createAsyncThunk<
    ExportToEC3Response | undefined,
    GetEC3ExportDataRequest
>('ec3/getExportData', async (data: GetEC3ExportDataRequest) => {
    const result = await http<ExportToEC3Response, GetEC3ExportDataRequest>(
        {
            path: `${baseUrl}/get-completeness`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );

    console.log('GetEC3Completeness:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getEC3AuthDataAction = async (
    data: GetEC3AuthDataRequest,
): Promise<GetEC3AuthDataResponse | undefined> => {
    let result: AxiosResponse<any, any>;
    try {
        result = await axios.post(
            'https://buildingtransparency.org/api/oauth2/token',
            new URLSearchParams({
                grant_type: data.grant_type,
                code: data.code,
                client_id: data.client_id,
                redirect_uri: `${process.env.REACT_APP_URL}/ec3`,
            }),
        );
        console.log(result);
        console.log('GetEC3AuthData:');
        console.log(result.data);
        return result;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

export const refreshEC3AuthTokens = async (
    data: RefreshEC3AuthTokensRequest,
): Promise<GetEC3AuthDataResponse | undefined> => {
    let result: any;
    try {
        result = await axios.post(
            'https://buildingtransparency.org/api/oauth2/token',
            new URLSearchParams({
                grant_type: data.grant_type,
                refresh_token: data.refresh_token,
                client_id: data.client_id,
                redirect_uri: `${process.env.REACT_APP_URL}/ec3`,
            }),
        );
        console.log(result);

        console.log('RefreshEc3AuthTokens:');
        console.log(result.data);
        return result;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};
