import styled from 'styled-components';
import { white } from '../../../../../../styles/colors';

export const EC3TipContainer = styled('div')`
    font-size: 11px;
    font-weight: 400;
    font-family: 'DINNextLTPro';
    line-height: 13px;
    strong {
        font-weight: 600;
    }
    .red {
        color: red;
        margin-top: 20px;
    }
    .right {
        img {
            margin-bottom: 10px;
        }
    }
    .labels {
        span {
            color: ${white};
            width: 30px;
            height: 15px;
            padding: 0px 2px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .twenty {
            background-color: #58f00d;
        }
        .fourty {
            background-color: #45c500;
        }
        .sixty {
            background-color: #369c27;
        }
        .sixtyPlus {
            background-color: #007d00;
        }
        .eighty {
            background-color: #555c55;
        }
        .hide {
            opacity: 0;
        }
    }
`;

export const EC3TipContent = styled('div')`
    display: flex;
    gap: 15px;
    margin-left: 20px;
`;

export const Rollover = styled('div')`
    span {
        font-family: Arial, Helvetica, sans-serif;
    }
    display: flex;
    justify-content: flex-start;
    gap: 2px;
`;
