import { Form, Input, Space, Image, FormInstance } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { CollaborationSpace, InviteTipContainer, RoleTip } from './ShareProduct.styles';
import { SelectData } from '../../../../../models/common/types';
import { Collaborator } from '../../../../../redux/actions/projectActions/types';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { getAvailableProjectRoles } from '../../../../../helpers/projectAccess';
import { ProjectPermissionTypes } from '../../../../../models/permission/enums';
import { alertService } from '../../../../../services';
import CustomInput from '../../../../../components/common/inputs/CustomInput';
import CustomSelect from '../../../../../components/common/select/CustomSelect';
import { PrimaryButton, SpaceEnd } from '../../../../../styles/common.styles';
import { sendProductInvitationAction } from '../../../../../redux/actions/productActions';

interface Props {
    handleCancel: () => void;
    projectRoles: Array<SelectData> | undefined;
    projectId: number;
    productId: number;
    selectedUser: Collaborator | null;
    collaborators: Array<Collaborator>;
    form: FormInstance
}

const ShareProductForm: React.FC<Props> = ({
    handleCancel,
    projectRoles,
    projectId,
    productId,
    selectedUser,
    collaborators,
    form
}) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { authReducer } = selector;
    const [state, setState] = useState({
        currentRoles: Array<SelectData>(),
        selectedRole: 0,
    });
    useEffect(() => {
        if (selectedUser) {
            form.setFieldsValue({ email: selectedUser.email, projectRoleID: selectedUser.roleId });
            setAvailableRoles(selectedUser.email, selectedUser.roleId);
        }
    }, [selectedUser]);

    useEffect(() => {
        if (projectRoles?.length) {
            const currentRoles = projectRoles;
            setState({
                ...state,
                currentRoles: currentRoles,
            });
        }
    }, [projectRoles]);

    const setAvailableRoles = (email: string, role?: number) => {
        const collaboratorDomainSplit = email.split('@');
        const ownerDomainSplit = authReducer.user?.email.split('@');
        const collaboratorDomain = collaboratorDomainSplit[collaboratorDomainSplit.length - 1];
        if (ownerDomainSplit && projectRoles) {
            const ownerDomain = ownerDomainSplit[ownerDomainSplit?.length - 1];
            const currentCollaborator = collaborators.find(
                (collaborator) => collaborator.email === email,
            );
            const availableRoles = getAvailableProjectRoles(
                ownerDomain,
                collaboratorDomain,
                currentCollaborator,
            );
            const currentRoles = projectRoles.filter((projectRole) =>
                availableRoles.some((availableRole) => projectRole.name === availableRole),
            );
            const selectedRole = role || state.selectedRole;
            if (!currentRoles.find((currentRole) => currentRole.id.toString() === selectedRole.toString())) {
                form.setFieldValue('projectRoleID', undefined);
            }
            if (
                currentRoles.length === 1 &&
                currentRoles[0].name === ProjectPermissionTypes.PROJECT_ADMIN
            ) {
                form.setFieldValue('projectRoleID', currentRoles[0].id);
            }
            setState({
                ...state,
                currentRoles: currentRoles || [],
            });
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (selectedUser) {
            form.setFieldValue('projectRoleID', undefined);
        }
        setAvailableRoles(event.target.value);
    };
    const handleSelectChange = (roleId: number) => {
        setState({ ...state, selectedRole: roleId });
    };

    const onSubmit = async (data: { email: string; projectRoleID: string; note: string }) => {
        if (!data.email || !data.projectRoleID) {
            return;
        }
        const { payload } = await dispatch(
            sendProductInvitationAction({
                email: data.email,
                roleId: +data.projectRoleID,
                note: data.note,
                projectId,
                productId
            }),
        );
        if (payload) {
            alertService.success('Invitation was sent successfully');
            form.resetFields()
            handleCancel();
        }
    };

    return (
        <Form
            name="basic"
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            onFinish={onSubmit}
            className="invite-form"
            form={form}
        >
            <CollaborationSpace>
                <CustomInput
                    Component={Input}
                    label="Collaborator email"
                    name="email"
                    required
                    block
                    props={{ onChange: handleInputChange }}
                    rules={[
                        { required: true, message: '' },
                        {
                            pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                            message: 'Invalid email',
                        },
                    ]}
                />
                <div id="select-role">
                    <CustomSelect
                        label="Role"
                        name="projectRoleID"
                        options={
                            state.currentRoles?.map((role) => ({
                                ...role,
                                name: role.name.replace('Project', ''),
                            })) || []
                        }
                        placeholder="Select role"
                        props={{ onChange: handleSelectChange }}
                        rules={[{ required: true, message: '' }]}
                        block
                    />
                </div>
                <InviteTipContainer>
                    <Image preview={false} src="src/assets/images/circle-question.svg" />
                    <RoleTip className="tip">
                        <div>
                            <strong>Types of collaborator</strong>
                        </div>
                        <div>
                            Not every role below will be available for every person you invite to <br />{' '}
                            the project.
                        </div>
                        <div>
                            <strong>Commenters</strong> can view or comment on a Project. Anyone can have this role. This is the
                            only role available to people outside your organization.
                        </div>
                        <div>
                            <strong>Editors</strong> can view, edit, comment on, upload to, export and copy a Project. Editors must
                            be members of your organization with paid accounts.
                        </div>
                        <div>
                            <strong>Owners</strong> can do everything Editors do, but also control Project access, including being
                            able to invite others to the Project, delete it or assign its ownership to another person.
                            Owners must be members of your organization with paid accounts.
                        </div>
                        <div>
                            <strong>Admins</strong> are administrators of your Office subscription. They can do everything Owners
                            can do, so invite them to a Project when you have work for them to do.
                        </div>
                    </RoleTip>
                </InviteTipContainer>
            </CollaborationSpace>
            <Space style={{ width: '100%' }} className="text-area-container">
                <CustomInput
                    Component={TextArea}
                    label="Add a note "
                    name="note"
                    block
                    props={{
                        placeholder: '',
                        rows: 3,
                    }}
                    suffix="(A link to this
                        product will be included in the invitation.)"
                    labelSpan={24}
                />
            </Space>
            <SpaceEnd>
                <PrimaryButton
                    htmlType="submit"
                    width={127}
                    height={32}
                    disabled={!state.currentRoles.length}
                >
                    Send invitation
                </PrimaryButton>
            </SpaceEnd>
        </Form>
    );
};

export default ShareProductForm;
