import styled from 'styled-components';
import { Modal } from 'antd';
import { blueLink } from '../../../styles/colors';
import { Text } from '../../../styles/common.styles';

export const CookiesModalWrapper = styled(Modal)`
    width: 680px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    .ant-divider-horizontal {
        margin-top: 10px;
        margin-bottom: 21px;
    }
`;

export const CookiesModalContent = styled('div')`
    a {
        color: ${blueLink};
    }
`;

export const CookiesModalContainer = styled('div')`
    img {
        width: 32px;
        height: 36px;
    }
    .title-container {
        gap: 20px !important;
    }
`;

export const CookiesModalText = styled(Text)`
    margin-bottom: 16px;
`;
