import { SalesforceLead } from '../models/user/user';

export const createSalesforceLead = (data: SalesforceLead) => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === 'production') {
        const requestOptions: any = {
            method: 'POST',
            mode: 'no-cors',
        };
        fetch(
            `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=00D80000000aQPm&retURL=none&first_name=${data.firstName}&last_name=${data.lastName}&company=${data.organizationName}&email=${data.email}`,
            requestOptions,
        )
            .then((response) => {
                response.text();
            })
            .then((result) => {
                console.log(result);
            })
            .catch((error) => console.log('error', error));
    }
};
