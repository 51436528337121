import React from 'react';

import { Tab } from './CustomTabs.types';
import { PageTabs } from '../../../styles/common.styles';
export interface Props {
    tabs: Tab[];
    defaultKey?: string;
    activeKey?: string;
    props?: any;
    onTabChange?: (tab: string) => void;
}

const CustomTabs: React.FC<Props> = ({ tabs = [], props, defaultKey, activeKey, onTabChange }) => {
    return (
        <PageTabs
            type="card"
            {...props}
            defaultActiveKey={defaultKey}
            activeKey={activeKey}
            onChange={onTabChange}
            items={tabs.map((tab) => {
                return {
                    label: tab.name,
                    key: tab.key,
                    children: tab.tab,
                };
            })}
        />
    );
};

export default CustomTabs;
