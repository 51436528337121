import React from 'react';
import * as MaterialProgramTip from '../materialPrograms/MaterialProgramTip';
import * as Tooltip from '../Tooltip';
import { TooltipStyles } from '../Tooltip.styles';

const EpProgramTip: React.FC = ( productsTab: boolean ) => {
    return (
        <MaterialProgramTip.Container>
            <Tooltip.Container>
                <TooltipStyles className={`${productsTab.productsTab ? 'tooltip-epdpt products-tab' : 'program shadow'}`}>
                    <div className={`${productsTab.productsTab ? 'tooltip tooltip-epd' : 'tooltip tooltip-epd'} `}>


	<h1>ISO 14025 Type III environmental declarations</h1>

	<h2>Program</h2>
	<p>ISO 14025 is the international standard for creating Type III environmental declarations. 
           Organizations who conduct Type III environmental programs are called program operators (PO). 
           The introduction to the standard states POs &ldquo;may refer to declarations by any name, such as eco-profile, 
           environmental product declaration (EPD), environmental profile and Eco-Leaf.&rdquo;</p>
	<p>&nbsp;</p>
	<p><b>Sustainable Minds' brand of Type III environmental declaration is the <a href='http://www.sustainableminds.com/transparency-products/transparency-reports' target='_blank'>SM Transparency 
          Report&trade;</a>.</b> Delivered in the cloud, in three easy to understand pages, it provides all the functional, 
          environmental and material ingredient information to make a purchase decision.</p>

	<h2>Industry Average and optimized EPDs</h2>
	<p>All EPDs in the catalog are product-specific unless indicated to be industry average <strong>(IA)</strong>. 
           EPDs eligible to earn optimization credits are indicated as <strong>EPD+Opt</strong>.</p>
	<p>&nbsp;</p>
	<p><i>Learn more about <a href='https://www.iso.org/obp/ui/#iso:std:iso:14025:ed-1:v1:en' target='_blank'>ISO 14025 Type III 
            environmental declarations</a>.</i></p>

	<h2>Scope</h2>
	<p>Scope of the LCA is defined by the life cycle stages included. Cradle to gate is the minimum required for compliance 
          with green building rating systems.</p>
	<p>&nbsp;</p>
	<p class='rollover_wrap'><span class='rollover_label_wide'><b>C2Gate</b></span><span class='rollover_text_short'>Cradle 
          to gate – from extraction of raw materials to the factory gate</span></p>
	<p class='rollover_wrap'><span class='rollover_label_wide'><b>C2 Gate w/options</b></span><span class='rollover_text_short'>Cradle 
          to gate with options – additional life cycle stages specified either in the PCR w/options or voluntartily reported</span></p>
	<p class='rollover_wrap'><span class='rollover_label_wide'><b>C2Grave</b></span><span class='rollover_text_short'>Cradle to grave – 
           from extraction of raw materials through use and end of life</span></p>

	<h2>Region</h2>
	<p>The Type III environmental declaration is done in accordance with the reporting requirements for that region: <b>
           N. America, Europe, Asia or Global</b></p>

	<h2>CO2e</h2>
	<p>CO2e, or carbon dioxide equivalent, is a standard unit for measuring carbon footprints. The idea is to express the 
           impact of each different greenhouse gas in terms of the amount of CO2 that would create the same amount of warming.</p>

                    </div>
                </TooltipStyles>
            </Tooltip.Container>
        </MaterialProgramTip.Container>
    );
};

export default EpProgramTip;
