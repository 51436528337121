import styled from 'styled-components';
import { Space } from 'antd';

export const PageTitleContainer = styled(Space)`
    align-items: baseline;
`;

interface CustomProps {
    showMenu: boolean;
}

export const PageTitleText = styled('h1')`
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    margin-left: ${(props: CustomProps) => (props.showMenu ? '38px' : '0px')};
    max-width: 570px;
    word-break: break-word;
    &.invisible-title {
        opacity: 0;
    }
`;
