import styled from 'styled-components';
import { Divider, Form, Spin, Typography } from 'antd';
import { blueDark01, blue01 } from '../../../styles/colors';
import { PrimaryButton } from '../../../styles/common.styles';

export const DetailsForm = styled(Form)`
    margin: 37px 100px 46px 100px;
`;

export const DetailsContainer = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 59px;
`;

const DetailsColumn = styled('div')`
    display: flex;
    flex-direction: column;
`;

export const DetailsColumn1 = styled(DetailsColumn)`
    button {
        height: 16px;
    }
`;

export const DetailsColumn2 = styled(DetailsColumn)``;

export const DetailsSection = styled('div')`
    display: flex;
    flex-direction: column;

    .input-block,
    & > div > div {
        & > div:first-child {
            margin-bottom: 16px;
        }
        .ant-form-item-row {
            display: flex !important;
            gap: 16px;

            .ant-form-item-label {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                label {
                    font-weight: normal;
                    font-family: 'Proxima N W15 Bold';
                    font-size: 13px;
                    line-height: 16px !important;
                    height: auto;
                    color: ${blueDark01} !important;
                    &::after {
                        margin-right: 0;
                    }
                }
            }

            .ant-form-item-control {
                input,
                .ant-select-selector {
                    width: 354px;
                    height: 36px;
                }
            }
        }
    }

    & > div:nth-last-child(2) {
        label {
            text-align: right !important;

            &::after {
                display: none;
            }
        }
    }

    &:nth-child(2) {
        .ant-form-item-row {
            .ant-form-item-label {
                label {
                    text-align: right !important;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
`;

export const DetailsPhoneInputContainer = styled('div')`
    display: flex;
    flex-direction: row;

    & > div {
        :nth-child(1) {
            .ant-form-item-row {
                .ant-form-item-control {
                    input {
                        width: 203px;
                    }
                }
            }
        }
        :nth-child(2) {
            .ant-form-item-row {
                .ant-form-item-label {
                    width: 48px;
                }
                .ant-form-item-control {
                    input {
                        width: 87px;
                    }
                }
            }
        }
    }
`;

export const SMUserDetailSpin = styled(Spin)`
    top: 30% !important;
`;

export const DetailsCheckboxContainer = styled('div')`
    display: flex;
    align-items: center;
    height: 36px;

    & > div:first-child {
        text-align: right;
    }

    & > .ant-checkbox-wrapper {
        margin: 0px 8px 0px 16px;
    }
`;

export const DetailsSubscriptionLink = styled('div')`
    display: flex;
    flex-direction: row;
    font-size: 13px;
    line-height: 16px;

    & > div:last-child::before {
        content: ' ';
        white-space: pre;
    }
`;

export const DetailsDivider = styled(Divider)`
    margin: 40px 0px;
`;

export const DetailsPrimaryButton = styled(PrimaryButton)`
    float: right;
`;

export const AccessTabTitle = styled(Typography.Text)`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 14px;
    line-height: 18px;
    color: ${blue01};
    margin-left: 12px;
    cursor: pointer;
`;

export const AccessTabLinkContainer = styled('div')`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    width: 190px;
    margin-top: 12px;
`;

export const CopyToClipboardContainer = styled('div')`
    cursor: pointer;
    height: 14px;
`;
