import React, { useEffect, useState } from "react";
import { FilterContainer, ImagesContainer, ImagesTabContainer } from "./ImagesTable.styles";
import ImageItem from "./ImageItem";
import { UploadedImageData } from "../UploadsInfo";
import ImageModal from "../ImageModal/ImageModal";
import { Input } from "antd";
import CustomInput from "../../../common/inputs/CustomInput";
import { usePermissions } from "../../../../hooks/permissions";
import { ProjectPermissionTypes } from "../../../../models/permission/enums";
import { FavoriteType } from "../../../../models/product/enums";
import { Product } from "../../../../models/product/types";
import { MergedFavoriteProduct } from "../../../favoritProducts/FavoriteProducts.types";
import { UploadFileData } from "../Uploads.types";
import DeleteModal from "../../../common/deleteModal/DeleteModal";
import { deleteFile } from "./uploadsRow/UploadsRow";
import { CommonResponse } from "../../../../models/actionResponse/types";
import { useAppDispatch } from "../../../../hooks/redux";

interface Props {
    images: UploadedImageData[];
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    userRole?: ProjectPermissionTypes;
    onFilterInputChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    updateImage: (image: UploadedImageData) => void;
    removeImage: (imageId: number) => void;
};

const ImagesTable: React.FC<Props> = ({ 
    images,
    favoriteType,
    product,
    userRole,
    onFilterInputChanged,
    updateImage,
    removeImage,
}) => {
    const dispatch = useAppDispatch();
    const { checkProductActionPermission } = usePermissions();
    
    const [imageIndex, setImageIndex] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [editableImages, setEditableImages] = useState<UploadedImageData[]>([]);

    useEffect(() => {
        setEditableImages(images);
    }, [images]);

    const updateImageByForm = (data: UploadFileData) => {
        const updatedImage: UploadedImageData = {
            ...editableImages[imageIndex],
            name: data.documentName,
            altText: data.alternativeText ?? '',
            description: data.description ?? ''
        }
        updateImage(updatedImage);
    }

    const onDeleteClicked = async () => {
        const image = editableImages[imageIndex];
        if (!image) {
            return;
        }
        
        const { payload } = (await deleteFile(favoriteType, image.file, product, dispatch)) as CommonResponse;
        if (payload?.message) {
            removeImage(image.id);
            closeDeleteModal();
        }
    }

    const openImageModal = () => {
        setIsImageModalOpen(true);
    }

    const closeImageModal = () => {
        setIsImageModalOpen(false);
    }

    const openDeleteModal = () => {
        closeImageModal();
        setIsDeleteModalOpen(true);
    }

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    }

    const onDeleteModalClosed = () => {
        closeDeleteModal();
        openImageModal();
    }

    const onImageItemClick = (index: number) => {
        setImageIndex(index);
        setPageNumber(index + 1);
        openImageModal();
    }

    const changePageNumber = (pageNumber: number) => {
        setImageIndex(pageNumber - 1);
        setPageNumber(pageNumber);
    }

    const handleCurrentPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentPage = Math.ceil(+event.currentTarget.value);
        if (currentPage === 0) {
            setPageNumber(currentPage);
        } else if (currentPage > 0 && currentPage <= editableImages.length) {
            changePageNumber(currentPage);
        }
    };

    const isImageModalReadOnly = () => {
        return checkProductActionPermission(favoriteType, userRole);
    }
    
    return (
        <ImagesTabContainer>
            <FilterContainer>
                <CustomInput
                    Component={Input}
                    label='Filter'
                    name='filter'
                    props={{
                        placeholder: 'Image name',
                        onChange: onFilterInputChanged,
                    }}
                />
            </FilterContainer>
            <ImagesContainer>
                {
                    editableImages.map((image, index) => (
                        <ImageItem key={index} image={image} imageIndex={index} onClick={onImageItemClick}/>
                    ))
                }
            </ImagesContainer>
            <ImageModal
                image={editableImages[imageIndex]}
                pageNumber={pageNumber}
                favoriteType={favoriteType}
                product={product}
                imagesTotalCount={editableImages.length}
                isImageModalOpen={isImageModalOpen}
                isReadOnly={isImageModalReadOnly()}
                openDeleteModal={openDeleteModal}
                closeImageModal={closeImageModal}
                updateImage={updateImageByForm}
                changePageNumber={changePageNumber}
                handleCurrentPage={handleCurrentPage}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                closeModal={onDeleteModalClosed}
                reload={onDeleteClicked}
                title="image"
                text={editableImages[imageIndex]?.name ?? ''}
            />
        </ImagesTabContainer>
    );
};

export default ImagesTable;