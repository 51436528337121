import React from 'react';
import { SubscriptionTypes } from '../../models/subscription/enums';
export const getInformationCardContent = (subscriptionType: SubscriptionTypes | undefined) => {
    switch (subscriptionType) {
        case SubscriptionTypes.FREE:
            return (
                <div>
                    Comment on Projects shared with you by users with Project Builder & Library
                    subscriptions.
                    <br />
                    <br />
                    Would you like to create Projects, have your own Favorite Product Libraries, and
                    collaborate with people in your organization?
                </div>
            );
        case SubscriptionTypes.INDIVIDUAL:
            return (
                <div>
                    Supports one person collaborating with any number of people outside your
                    organization.
                    <br />
                    <br /> Are there more people in your organization you’d like be able to
                    collaborate with?
                </div>
            );
        case SubscriptionTypes.FREE_TRIAL_INDIVIDUAL:
            return (
                <div>
                    Supports one person collaborating with any number of people outside your
                    organization.
                    <br />
                    <br /> Are there more people in your organization you’d like be able to
                    collaborate with?
                </div>
            );
        case SubscriptionTypes.OFFICE:
            return (
                <div>
                    Supports collaboration between any number of people in your organization and any
                    number outside your organization.
                </div>
            );
        case SubscriptionTypes.FREE_TRIAL_OFFICE:
            return (
                <div>
                    Supports collaboration between any number of people in your organization and any
                    number outside your organization.
                </div>
            );
        default:
            return '';
    }
};

export const getInformationCardButtonText = (subscriptionType: SubscriptionTypes | undefined) => {
    switch (subscriptionType) {
        case SubscriptionTypes.FREE:
            return 'See our subscription plans';
        case SubscriptionTypes.INDIVIDUAL:
            return 'Upgrade to an Office subscription';
        case SubscriptionTypes.OFFICE:
            return '';
        default:
            return 'See our subscription plans';
    }
};
