import React, { useEffect } from 'react';
import { blueDark01 } from '../../styles/colors';
import { Text, CloseIcon } from '../../styles/common.styles';
import ModalFooter from '../common/modalFooter/ModalFooter';
import ModalTitle from '../common/modalTitle/ModalTitle';
import {
    ExistingModalContent,
    ExistingModalListContainer,
    ExistingProductsModalContent,
} from './ExistingProductsModal.styles';

interface Props {
    existingProducts: Array<{ id: number; name: string }>;
    isOpen: boolean;
    addedFrom: string;
    addedTo: string;
    currentPage: string;
    closeModal: () => void;
}

const ExistingProductsModal: React.FC<Props> = ({
    existingProducts,
    isOpen,
    addedTo,
    currentPage,
    closeModal,
}) => {
    useEffect(() => {
        window.history.replaceState(null, '', currentPage);
    }, []);
    return (
        <ExistingProductsModalContent
            open={isOpen}
            onCancel={closeModal}
            centered
            footer={null}
            className="delete-modal-container"
            closeIcon={<CloseIcon />}
        >
            <ModalTitle title={`Warning`} icon={'warning.svg'} />
            <ExistingModalContent>
                <Text weight={400} height={18} size={14} color={blueDark01}>
                    The following product(s) already exist in your {addedTo}:
                </Text>
                <ExistingModalListContainer>
                    {existingProducts.map((product) => (
                        <li key={product.id}>{product.name}</li>
                    ))}
                </ExistingModalListContainer>
            </ExistingModalContent>
            <ModalFooter
                submit={closeModal}
                action={'Close'}
                cancel={closeModal}
                cancelProps={{
                    style: { display: 'none' },
                }}
            />
        </ExistingProductsModalContent>
    );
};

export default ExistingProductsModal;
