import { createAsyncThunk } from '@reduxjs/toolkit';
import { http, httpTest } from '../../helpers/request';

export const testAction = createAsyncThunk<any | undefined, any>('test/test', async (data: any) => {
    const result = await httpTest<any, any>(
        {
            path: `${data.url}`,
            method: 'post',
            body: data.data,
        },
        data.accessToken,
    );

    console.log('Test:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
