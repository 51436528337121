import React from 'react';
import { Image } from 'antd';
import * as MaterialProgramTip from '../materialPrograms/MaterialProgramTip';
import * as Tooltip from '../Tooltip';
import { TooltipStyles } from '../Tooltip.styles';
import { DivFlexWithWrap, DivImpactArea, DivImpactAreaImage, DivImpactAreaBuckets, DivImpactAreaNameAndBuckets } from './CmfImpactAreasTip.styles';

const CmfImpactAreasTip: React.FC = ( data: any, cmfImpactAreas: Array<any> ) => {
    const impactAreas = cmfImpactAreas.length && cmfImpactAreas.length > 0 ? cmfImpactAreas : data.cmfImpactAreas;

    const getInspectAreaImage = (impactAreaName : string) => {
       switch(impactAreaName) {
          case 'HUMAN HEALTH':
              return 'src/assets/images/health_red.png';
          case 'CLIMATE HEALTH':
              return 'src/assets/images/climate_blue.png';
          case 'CIRCULARITY':
              return 'src/assets/images/circularity_blue.png';
          case 'SOCIAL HEALTH & EQUITY':
              return 'src/assets/images/social_purple.png';
          case 'ECOSYSTEM HEALTH':
              return 'src/assets/images/ecosystem_green.png';
       }
    };

    return (
        <MaterialProgramTip.Container>
            <Tooltip.Container>
                <TooltipStyles className='program-cmf shadow'>
                    <div className='tooltip tooltip-cmf'>


	<p><span class="name">This document is identified in the following <a href="https://www.mindfulmaterials.com/a-common-language" target="_blank">Common Materials Framework (CMF)</a> impact areas:
        </span></p>

        <DivFlexWithWrap>
           {impactAreas?.map((impactArea, i) => (
             <>
             {impactArea.name && (
               <DivImpactArea>
                 <DivImpactAreaImage>
                            <Image
                                preview={false}
                                src={getInspectAreaImage(impactArea.name?.toUpperCase())}
                                style={{ cursor: 'none' }}
                                width={60} height={60}
                            />
                 </DivImpactAreaImage>
                 <DivImpactAreaNameAndBuckets>
                   {impactArea.name}
                   <DivImpactAreaBuckets>
                      {impactArea.buckets?.map((bucket, j) => (
                        <div>{bucket.name}</div>
                      ))}
                   </DivImpactAreaBuckets>
                 </DivImpactAreaNameAndBuckets>
              </DivImpactArea>
             )}
             </>
            ))}
        </DivFlexWithWrap>

                    </div>
                </TooltipStyles>
            </Tooltip.Container>
        </MaterialProgramTip.Container>
    );
};

export default CmfImpactAreasTip;
