import { Button, Image } from 'antd';
import styled from 'styled-components';
import { blue01, blue02, blue, blueDark03, blueDark01 } from '../../../styles/colors';

export const AddProductButton = styled(Button)`
    margin-left: 5px;
    border-radius: 3px;
    background-color: ${blue01};
    color: #ffffff;
    cursor: pointer;
    border-color: ${blue01};
    border-radius: 3px 0 0 3px;
    &:focus {
        background-color: ${blue01};
        color: #ffff;
    }
`;

export const AddProductMenu = styled(Button)`
    border-radius: 0px 3px 3px 0;
    padding: 0 10px;
    background-color: ${blue01};
    border-color: ${blue01} ${blue01} ${blue01} rgba(255, 255, 255, 0.3);
    &:focus {
        background-color: ${blue};
        color: #ffff;
        border-color: #ffff;
    }
`;

export const AddProductButtonContainer = styled('div')`
    &:hover {
        button:first-child {
            background-color: ${blue02};
            color: #ffff;
        }
        button:last-child {
            background-color: ${blue02};
            color: #ffff;
        }
    }
`;

export const ProductsActionImage = styled(Image)`
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
    &:hover {
        background-color: ${blueDark03};
    }
    &:focus {
        background-color: ${blueDark01};
    }
    &.disabled {
        opacity: 0.3;
        &:hover {
            background-color: transparent;
        }
    }
`;
