import { Modal } from 'antd';
import styled from 'styled-components';

export const ContactAdminModal = styled(Modal)`
    width: 680px !important;
    .ant-modal-content {
        margin-bottom: 20px;
    }
`;

export const AdminsContainer = styled('div')`
    display: flex;
    margin-top: 28px;
    flex-wrap: wrap;
    gap: 20px;
`;

export const AdminItem = styled('div')`
    width: 45%;
    margin-bottom: 8px;
`;
