import { Spin } from 'antd';
import styled from 'styled-components';
import { PageContainer } from '../../../../styles/common.styles';

export const EC3LoginSpin = styled(Spin)`
    top: 0 !important;
`;

export const EC3RedirectContainer = styled(PageContainer)`
    min-height: 200px;
`;
