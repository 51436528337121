import { Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { PageContainer, PrimaryButton } from '../../styles/common.styles';
import { mockProducts } from './Products.mockData';
import { Text } from '../../styles/common.styles';
import { blueDark01 } from '../../styles/colors';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ExternalProduct } from '../../models/product/types';
import { testAction } from '../../redux/actions/testActions';

const ProductsPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const { projectReducer } = selector;
    const [selectedProducts, setSelectedProducts] = useState({
        current: Array<ExternalProduct>(),
    });

    const [state, setState] = useState({
        authToken: '',
    });

    useEffect(() => {
        getToken();
    }, []);

    const getToken = async () => {
        const { payload } = await dispatch(
            testAction({
                url: `${process.env.REACT_APP_API_URL}/users/login`,
                data: { username: 'tc', password: '123456a' },
            }),
        );
        if (payload.tokens) {
            setState({ ...state, authToken: payload.tokens.accessToken });
        }
    };

    const handleSelectProduct = (event: any) => {
        const productId = +event.target.name;
        const currentServices = selectedProducts.current;
        const index = currentServices.findIndex((item) => item.id === productId);
        if (index === -1) {
            const currentProduct = mockProducts.find((product) => product?.id === productId);
            if (currentProduct) {
                currentServices.push(currentProduct);
            }
        } else {
            currentServices.splice(index, 1);
        }
        setSelectedProducts({ ...selectedProducts, current: currentServices });
    };

    const submitProducts = async () => {
        const projectId = params.get('project');
        //const productIds = [201548, 13956, 30439, 31050, 62969, 9378, 9194, 13954, 9102, 7432, 16078, 43443, 9376, 45434, 25630, 9209, 34517, 16036, 34102, 38202, 37154]; //selectedProducts.current.map((product) => product.id);
        //const productIds = [22919];
        const productIds = selectedProducts.current.map((product) => product.id);
        console.log('Products.submitProducts');
        console.log(`${process.env.REACT_APP_API_URL}/products/add-to-project`);
        console.log(productIds);
        const { payload } = await dispatch(
            testAction({
                url: `${process.env.REACT_APP_API_URL}/products/add-to-project`,
                data: {
                    ProductSelectedOnTcIDs: productIds,
                },
                accessToken: state.authToken,
            }),
        );
        if (projectId) {
            history.push(`/project-info/${projectId}/${payload.result.productPackageID}/2`);
        } else {
            history.push(`/project-library/${payload.result.productPackageID}`);
        }
        // if (project) {
        //     const { payload } = await dispatch(
        //         addProductsToProjectAction({ products: productIds, projectId: project.id }),
        //     );
        //     if (payload) {
        //         history.push(`/project-info/${project.id}/2`);
        //     }
        // } else {
        //     alertService.error('You have not selected a project');
        // }
    };
    return (
        <PageContainer>
            <PrimaryButton
                id="exportBtn"
                disabled={!selectedProducts.current.length}
                onClick={submitProducts}
            >
                Export
            </PrimaryButton>
            Select products
            {mockProducts.map((product) => {
                return (
                    <div key={product.id}>
                        <Checkbox
                            name={product.id.toString()}
                            onChange={handleSelectProduct}
                            checked={
                                selectedProducts.current.findIndex(
                                    (item) => item.id === product.id,
                                ) !== -1
                            }
                        />
                        <Text weight={400} size={14} height={18} color={blueDark01}>
                            {product.productName}
                        </Text>
                    </div>
                );
            })}
            <PrimaryButton
                disabled={!selectedProducts.current.length}
                onClick={submitProducts}
                id="exportBtn"
            >
                Export
            </PrimaryButton>
        </PageContainer>
    );
};

export default ProductsPage;
