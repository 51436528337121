import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import ErrorModal from '../../../../components/common/errorModal/ErrorModal';
import ModalFooter from '../../../../components/common/modalFooter/ModalFooter';
import ModalTitle from '../../../../components/common/modalTitle/ModalTitle';
import CustomSelect from '../../../../components/common/select/CustomSelect';
import {
    ChangeRoleGroupModal,
    ChangeRoleSpace,
    LabelContainer,
} from '../../../../components/users/changeRoleGroupModal/changeRoleGroupModal.styles';
import { useModalState } from '../../../../hooks/modalState';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ActionResponse, CommonResponse } from '../../../../models/actionResponse/types';
import { SelectData } from '../../../../models/common/types';
import {
    getUserReferencesAction,
    changeUserAccountRoleAction,
    changeProjectGroupAction,
} from '../../../../redux/actions/userActions';
import { GetUserReferencesResponse } from '../../../../redux/actions/userActions/types';
import { blueDark01 } from '../../../../styles/colors';
import { BoldText, CloseIcon } from '../../../../styles/common.styles';
import { Text } from '../../../../styles/common.styles';

type Field = 'role' | 'group';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: string }) => void;
    field: Field;
    reload: (modal: string, data?: any) => void;
    sendActionInReload?: boolean;
}

const ChangeRoleForAdmin: React.FC<Props> = ({
    isOpen,
    closeModal,
    field,
    reload,
    sendActionInReload,
}) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state.userReducer);

    const [selectData, setSelectedData] = useState({
        allTitleRoles: Array<SelectData>(),
        accountRoles: Array<SelectData>(),
    });
    const errorModal = useModalState();

    const handleCancel = () => {
        closeModal({ key: field });
    };

    useEffect(() => {
        getDataForSelect();
    }, []);

    const getDataForSelect = async () => {
        const { payload } = (await dispatch(
            getUserReferencesAction(),
        )) as ActionResponse<GetUserReferencesResponse>;
        if (payload?.data) {
            setSelectedData({
                allTitleRoles: payload.data.allTitleRoles || [],
                accountRoles: payload.data.allAccountRoles || [],
            });
        } else {
            errorModal.onOpen();
        }
    };

    const onSubmit = async (data: any) => {
        if (sendActionInReload) {
            reload(field, data);
        } else {
            const userIds = selector.selectedRows.map((user) => user.key);
            const { payload } =
                field === 'role'
                    ? ((await dispatch(
                          changeUserAccountRoleAction({
                              userIds,
                              accountRoleID: data['accountRoleID'],
                          }),
                      )) as CommonResponse)
                    : ((await dispatch(
                          changeProjectGroupAction({
                              userIds,
                              projectGroupID: data['projectGroupID'],
                          }),
                      )) as any);
            if (payload?.message) {
                reload(field);
            }
        }
    };

    return (
        <ChangeRoleGroupModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            className="role-modal-container"
            closeIcon={<CloseIcon />}
        >
            <ModalTitle title={`Change account type`} icon="ic-modal-title-change-role.svg" />
            <div>
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    Change user to the following account type:
                </Text>
            </div>
            <Form onFinish={onSubmit}>
                <Form.Item>
                    <ChangeRoleSpace>
                        <div>
                            <LabelContainer>
                                <BoldText
                                    weight={400}
                                    size={13}
                                    height={16}
                                    color={blueDark01}
                                ></BoldText>
                            </LabelContainer>
                            <CustomSelect
                                label={'Account type'}
                                name={'accountRoleID'}
                                options={selectData.accountRoles.map((role: SelectData) => ({
                                    ...role,
                                    name: role.name.replace('Account', ''),
                                }))}
                                placeholder={`Select account type`}
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </div>
                    </ChangeRoleSpace>
                </Form.Item>
                <ModalFooter
                    submit={() => {}}
                    cancel={handleCancel}
                    action={`Change account type`}
                />
            </Form>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </ChangeRoleGroupModal>
    );
};

export default ChangeRoleForAdmin;
