import { Button } from 'antd';
import styled from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';
import { blueDark01, blueLink, grayLight01 } from '../../../styles/colors';
import { CloseIcon } from '../../../styles/common.styles';

export const NavigationMenuContainer = styled('div')`
    button {
        border: none;
    }

    .navigation-card {
        display: none;
        z-index: 100;
        position: absolute;
        background: #eff9fc;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
        border-radius: 3px;
        padding: 24px;
        top: 0;

        .links-container {
            .ant-space-item-split {
                height: 100%;
            }

            .ant-divider {
                height: 100%;
                top: 0;
                margin-left: 0;
                margin-right: 4px;
            }

            .group {
                min-width: 165px;

                .ant-space-item {
                    line-height: 16px;
                    min-width: 165px;

                    span {
                        display: block;
                    }
                }
            }

            .group-title {
                font-style: normal;
                font-weight: normal;
                font-family: 'Proxima N W15 Bold';
                font-size: 16px;
                line-height: 20px;
                color: ${blueDark01};
            }
        }
    }

    a {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        font-style: normal;
        color: ${blueLink} !important;
        &:hover {
            text-decoration-line: underline;
        }
    }

    .show-card {
        display: block;
    }
`;

export const NavigationMenuClose = styled(CloseIcon)`
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
`;

export const NavigationMenuButton = styled(Button)`
    color: #ffff;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    &:hover {
        background-color: transparent;
        color: #ffff;
    }
    &:focus {
        background-color: transparent;
        color: #ffff;
    }
`;

export const MenuOutlinedButton = styled(MenuOutlined)`
    font-size: 22px;
    svg {
        color: ${grayLight01};
    }
    &:hover {
        svg {
            color: #ffffff;
        }
    }
`;
