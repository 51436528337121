import React from 'react';
import { Image } from 'antd';

import {
    WarningBannerContainer,
    WarningBannerIcon,
    WarningBannerText,
    WarningBannerBoldText,
} from './WarningBanner.styles';

interface Props {
    Text: string;
    Prefix?: string;
    FullWidth?: boolean;
    Clicked?: boolean;
}

const WarningBanner: React.FC<Props> = ({
    Text,
    Prefix = null,
    FullWidth = false,
    Clicked = false,
}) => {
    return (
        <WarningBannerContainer FullWidth={FullWidth} Clicked={Clicked}>
            <WarningBannerIcon>
                {
                    <Image
                        src="src/assets/images/warning.svg"
                        preview={false}
                        width={'16px'}
                        height={'16px'}
                    />
                }
            </WarningBannerIcon>
            <WarningBannerText>
                {!!Prefix && <WarningBannerBoldText>{Prefix}</WarningBannerBoldText>}
                {Text}
            </WarningBannerText>
        </WarningBannerContainer>
    );
};

export default WarningBanner;
