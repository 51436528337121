import styled from 'styled-components';

export const MaterialProgramTipContainer = styled('div')`
    font-weight: 400;
    font-size: 13px;
    .circle {
        border-radius: 4.5px;
        height: 9px;
        margin-top: 2px;
        margin-right: 3px;
        padding: 0;
        width: 9px;
        flex: none;

        &.platinum {
            background-color: #8bc;
        }
        &.gold {
            background-color: #da3;
        }
        &.silver {
            background-color: #ccc;
        }
        &.bronze {
            background-color: #c73;
        }
        &.red {
            background-color: #c22;
        }
        &.orange {
            background-color: #e70;
        }
        &.yellow {
            background-color: #fc6;
        }
    }
`;

export const MaterialProgramTipTitle = styled('div')`
    font-size: 12px;
    font-weight: 600;
`;

export const ScopeList = styled('ul')`
    padding-inline-start: 15px;
`;
