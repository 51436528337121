import { MenuItem } from '../../../models/common/types';

export const actionMenu: Array<MenuItem> = [
    {
        key: 'role',
        label: 'Change account type',
    },
    {
        key: 'publisher',
        label: 'Make Publisher',
    },
    {
        key: 'delete',
        label: 'Delete',
        divider: true,
    },
];

export const createAccountMenu: Array<MenuItem> = [
    {
        key: 'one',
        label: 'Create subscriber',
    },
    {
        key: 'admin',
        label: 'Create SM admin',
    },
];

export const actionMenuTop: Array<MenuItem> = [
    {
        key: 'csv',
        label: 'Export to CSV',
    },
    {
        key: 'deletebulk',
        label: 'Delete user(s)',
    },
];
