import React from 'react';
import { Rollover } from './EC3Tip.styles';
import * as MaterialProgramTip from '../materialPrograms/MaterialProgramTip';
import { TooltipStyles } from '../Tooltip.styles';

const EC3Tip: React.FC<{ productsTab?: boolean }> = ({ productsTab }) => {
    return (
        <MaterialProgramTip.Container>
            <TooltipStyles className={`tooltip-ec3 ${productsTab ? 'products-tab' : ''}`}>
                <div className="tooltip">
                    <img
                        src="src/assets/images/ec3-tooltip-v2.png"
                        width="117"
                        alt="EC3"
                        className="logo"
                    />
                    <p className="red-label" style={{ color: 'red' }}>
                        <strong>
                            EC3 is a cloud-based tool with constant data updates. EC3 results in the
                            Transparency Catalog are updated monthly and can change.
                        </strong>
                    </p>
                    <hr />
                    <div style={{ display: 'flex' }}>
                        <div className="left">
                            <p>
                                <strong>
                                    The Embodied Carbon in Construction Calculator (EC3) tool – from
                                    Building Transparency (incubated by the Carbon Leadership Forum
                                    and industry partners –
                                </strong>{' '}
                                is designed to enable the building industry to transparently
                                measure, compare, and reduce embodied carbon emissions from
                                construction materials.
                            </p>
                            <p>
                                <strong>How the EC3 methodology uses EPD data:</strong> Using the
                                global warming potential (embodied carbon) results from the
                                manufacturing life cycle stage, five attributes of the data are
                                evaluated to determine its variability. Is it specific to the: MFR
                                plant, product, supply chain, batch? Applying a ‘burden of the
                                doubt’ calculation (opposite of ‘benefit of the doubt’ when the best
                                outcome is given without proof), a +/- uncertainty value (penalty)
                                is added based on yes/no. When the data is not in the EPD, an
                                uncertainty value is applied.
                            </p>
                            <p>
                                <strong>
                                    NOTE: Results are not precise, they are directionally correct
                                    and should be used with in that in mind.
                                </strong>{' '}
                                Results may not always identify low embodied carbon products due to
                                non-specific data in the EPD.
                            </p>
                            <div className="labels">
                                <Rollover>
                                    <span className="l l5">80th+</span>
                                    <p>
                                        Cannot be identified to be among the <strong>80%</strong> of
                                        related products with the lowest EC and may be in the 20%
                                        highest due to high reported EC results plus some high
                                        uncertainty.
                                    </p>
                                </Rollover>
                                <Rollover>
                                    <span className="l l4">60th+</span>
                                    <p>
                                        Not known to be among the <strong>60%</strong> of related
                                        products with the lowest EC, but likely not in the 20%
                                        highest. Uncertainty may be high due to mostly non-specific
                                        data in the EPD. Industry-wide EPDs are assumed to be in
                                        this range.
                                    </p>
                                </Rollover>
                                <Rollover>
                                    <span className="l l3">60th</span>
                                    <p>
                                        Among the <strong>60%</strong> of related products with the
                                        lowest EC
                                    </p>
                                </Rollover>
                                <Rollover>
                                    <span className="l l2">40th</span>{' '}
                                    <p>
                                        {' '}
                                        Among the <strong>40%</strong> of related products with the
                                        lowest EC
                                    </p>
                                </Rollover>
                                <Rollover>
                                    <span className="l l1">20th</span>{' '}
                                    <p>
                                        Among the <strong>20%</strong> of related products with the
                                        lowest EC
                                    </p>
                                </Rollover>
                                <Rollover>
                                    <span className="l"></span>
                                    <p>
                                        <strong>When there is no EC3 result for an EPD:</strong>{' '}
                                        Data is shown only for EPDs that have been successfully
                                        digitized by EC3 and for which sufficient category data is
                                        available.{' '}
                                        <a
                                            href="https://drive.google.com/file/d/1EysSAwUYApcAAcMCjXBYWa3FKEV4oq2d/view"
                                            target="_blank"
                                        >
                                            Read the EC3 methodology
                                        </a>
                                        .<i />
                                    </p>
                                </Rollover>
                            </div>
                        </div>
                        <div className="right">
                            <img
                                src="src/assets/images/ec3-tooltip-graph-v3.png"
                                alt="Graph"
                                width="320"
                            />
                            <p>
                                <strong>
                                    AECOs: To compare materials by performance attributes and
                                    location, log into the EC3 tool at{' '}
                                    <a href="http://BuildingTransparency.org" target="_blank">
                                        BuildingTransparency.org
                                    </a>
                                    .
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    MFRs: To improve your EC3 results, or if there’s no result
                                    displayed for your EPD, or you need to create an EPD(s), <br />
                                    <a
                                        href="http://www.sustainableminds.com/contact-us"
                                        target="blank"
                                    >
                                        contact Sustainable Minds
                                    </a>{' '}
                                    about our Data & EPD services.
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </TooltipStyles>
        </MaterialProgramTip.Container>
    );
};

export default EC3Tip;
