import styled from 'styled-components';
import { blue01 } from '../../../styles/colors';
import { Space } from 'antd';

export const TrialContainer = styled('div')`
    background-color: ${blue01};
`;

export const TrialSpace = styled(Space)`
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    color: #ffffff;
`;

export const CloseTrialSection = styled('button')`
    cursor: pointer;
    border: none;
    background-color: transparent;
`;
