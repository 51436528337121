import styled from 'styled-components';
import { Image } from 'antd';
import { blueDark03, blueDark01, green, blue, grayLight04, white } from '../../styles/colors';

export const ProductsActionImage = styled(Image)`
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
    &:hover {
        background-color: ${blueDark03};
    }
    &:focus {
        background-color: ${blueDark01};
    }
`;

export const ProjectStatusContainer = styled('span')`
    padding: 3px 6px;
    margin-left: 8px;
    background-color: ${blueDark01};
    color: ${white};
    &.Active {
        color: ${green};
    }
    &.Completed {
        color: ${blue};
    }
    &.Template {
        color: ${grayLight04};
    }
`;
export const ConfidentialIcon = styled(Image)`
    margin-left: -2px;
`;

export const ProjectInfoTabContainer = styled('div')`
    min-width: 1224px;
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    }
`;
