/**
 * @description Create multipart data from object
 * @returns FormData
 */
export const createMultipartData = (data: {}): FormData => {
    const multipartData = new FormData();
    Object.entries(data).forEach((item: any) => {
        const value = item[1] === undefined ? null : item[1];
        if (value) {
            multipartData.append(item[0], value);
        }
    });
    return multipartData;
};
