import React from 'react'
import { CustomInitialAvatar } from './InitialAvatar.styles'
import { InitialAvatartProps } from './InitialAvatar.types'

const InitialAvatar: React.FC<InitialAvatartProps> = ({ initial, outlined, style }) => {
    return (
        <CustomInitialAvatar outlined={outlined} style={style}>{initial}</CustomInitialAvatar>
    )
}

export default InitialAvatar