import { createAsyncThunk } from '@reduxjs/toolkit';
import { UploadsTableFields } from '../../../components/productInfo/uploads/Uploads.types';
import { createMultipartData } from '../../../helpers/multipartData';
import { http, httpBuffer, httpMultipart } from '../../../helpers/request';
import { ActionResponse, CommonResponse } from '../../../models/actionResponse/types';
import { Comment } from '../../../models/comment/types';
import { InternalProduct } from '../../../models/product/types';
import {
    AddCommentRequest,
    AddProductsFromFavoriteRequest,
    AddProductsFromFavoriteResponse,
    ChangeBasisOfDesignRequest,
    ChangePricingTypeRequest,
    ChangeSubstitutionRequest,
    GetProjectProductsByIdsRequest,
    GetProjectProductsByIdsWithSearchRequest,
    ProductProjectAction,
    ProductReferences,
    RetrieveUploadFileRequest,
    UpdateProductFileAction,
    UpdatePricingInfoRequest,
    UpdatePricingRequest,
    UploadProductFileRequest,
    DeleteUploadDocumentRequest,
    ImportProductsFromProjectLibraryRequest,
    ImportProductsFromProjectLibraryResponse,
    GetUploadFilesRequest,
    GetProductIdsByPackageIdRequest,
    GetProductIdsByPackageIdResponse,
    SendProductInviteRequest,
    UpdateDescriptionRequest,
    GetUploadedImagesCountRequest,
    GetDocumentTypesAction,
} from './types';

const baseUrl = `/projectProducts`;
const referencesUrl = `/references`;
const getDocumentTypesUrl = `products/getDocumentTypes`;

const getProjectProductAPI = `products/getProjectProducts`;

const getProjectProducts = async (projectId: number, copyTokensFromCookiesIfAuthFails: boolean = false) => {
    console.log('TC API URL:');
    console.log(`${baseUrl}/${projectId}`);

    const result = await http<ActionResponse<Array<InternalProduct>>, number>({
        path: `${baseUrl}/${projectId}`,
        method: 'get',
    }, true, true, false, copyTokensFromCookiesIfAuthFails);

    console.log('GetProjectProducts:');
    console.log(result.parsedBody);
    return result.parsedBody;
}

const getProjectProductsWithSearch = async (data: GetProjectProductsByIdsWithSearchRequest, copyTokensFromCookiesIfAuthFails: boolean = false) => {
    const result = await http<ActionResponse<Array<InternalProduct>>, GetProjectProductsByIdsWithSearchRequest>({
        path: `${baseUrl}/search`,
        method: 'post',
        body: data,
    }, true, true, false, copyTokensFromCookiesIfAuthFails);

    console.log('GetProjectProductsWithSearch:');
    console.log(result.parsedBody);
    return result.parsedBody;
}

export const getProjectProductsWithCopyTokensOptionAction = createAsyncThunk<
    ActionResponse<Array<InternalProduct>> | undefined,
    number
>(getProjectProductAPI, async (projectId: number) => {
    const result = await getProjectProducts(projectId, true);
    return result;
});

export const getProjectProductsAction = createAsyncThunk<
    ActionResponse<Array<InternalProduct>> | undefined,
    number
>(getProjectProductAPI, async (projectId: number) => {
    const result = await getProjectProducts(projectId);
    return result;
});

export const getProjectProductsWithSearchAction = createAsyncThunk<
    ActionResponse<Array<InternalProduct>> | undefined,
    GetProjectProductsByIdsWithSearchRequest 
>('products/getProjectProductsWithSearch', async (data: GetProjectProductsByIdsWithSearchRequest) => {
    const result = await getProjectProductsWithSearch(data);
    return result;
});

export const getProjectProductsByIdsAction = createAsyncThunk<
    ActionResponse<Array<InternalProduct>> | undefined,
    GetProjectProductsByIdsRequest
>(getProjectProductAPI, async (data: GetProjectProductsByIdsRequest) => {
    const result = await http<
        ActionResponse<Array<InternalProduct>>,
        GetProjectProductsByIdsRequest
    >({
        path: `${baseUrl}/company`,
        method: 'post',
        body: data,
    });

    console.log('GetProjectProducts:');
    console.log(result.parsedBody);
    return result.parsedBody;
});


export const sendProductInvitationAction = createAsyncThunk<
    any | undefined,
    SendProductInviteRequest
>('projects/sendProjectInvitation', async (data: SendProductInviteRequest) => {
    const result = await http<any, SendProductInviteRequest>({
        path: `/products/share`,
        method: 'post',
        body: data,
    });
    console.log('SendProductInvitation:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getProductReferencesAction = createAsyncThunk<
    ActionResponse<ProductReferences> | undefined,
    undefined
>('products/getProductReferences', async () => {
    const result = await http<ActionResponse<ProductReferences>, undefined>({
        path: `${referencesUrl}/product`,
        method: 'get',
    });

    console.log('GetProductReferences:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const addProductsToProjectAction = createAsyncThunk<
    ActionResponse<ProductReferences> | undefined,
    { projectId: number; products: Array<number> }
>('products/addProductsToProject', async (data: { projectId: number; products: Array<number> }) => {
console.log('productActions.index.addProductsToProjectAction');
    const result = await http<
        ActionResponse<ProductReferences>,
        { projectId: number; products: Array<number> }
    >({
        path: `${baseUrl}/add-to-project`,
        method: 'post',
        body: data,
    });

    console.log('AddProductsToProject:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeBasisOfDesignAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ChangeBasisOfDesignRequest
>('products/changeBasisOfDesign', async (data: ChangeBasisOfDesignRequest) => {
    const result = await http<ActionResponse<InternalProduct>, ChangeBasisOfDesignRequest>({
        path: `${baseUrl}/basis-of-design`,
        method: 'put',
        body: data,
    });

    console.log('ChangeBasisOfDesign:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeFinishScheduleIdAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ChangeFinishScheduleIdRequest
>('products/changeFinishScheduleId', async (data: ChangeFinishScheduleIdRequest) => {
    const result = await http<ActionResponse<InternalProduct>, ChangeFinishScheduleIdRequest>({
        path: `${baseUrl}/finish-schedule-id`,
        method: 'put',
        body: data,
    });

    console.log('ChangeFinishScheduleId:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeSubstitutionAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ChangeSubstitutionRequest
>('products/changeSubstitution', async (data: ChangeSubstitutionRequest) => {
    const result = await http<ActionResponse<InternalProduct>, ChangeSubstitutionRequest>({
        path: `${baseUrl}/substitutions`,
        method: 'put',
        body: data,
    });

    console.log('ChangeProductSubstitution:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const switchProductInstalledAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ProductProjectAction
>('products/switchProductInstalled', async (data: ProductProjectAction) => {
    const result = await http<ActionResponse<InternalProduct>, ProductProjectAction>({
        path: `${baseUrl}/switch-install`,
        method: 'put',
        body: data,
    });

    console.log('SwitchProductInstalled:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changePricingTypeAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ChangePricingTypeRequest
>('products/changePricingType', async (data: ChangePricingTypeRequest) => {
    const result = await http<ActionResponse<InternalProduct>, ChangePricingTypeRequest>({
        path: `${baseUrl}/pricing-type`,
        method: 'put',
        body: data,
    });

    console.log('ChangePricingType:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updatePricingAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    UpdatePricingRequest
>('products/changePricingType', async (data: UpdatePricingRequest) => {
    const result = await http<ActionResponse<InternalProduct>, UpdatePricingRequest>({
        path: `${baseUrl}/pricing`,
        method: 'put',
        body: data,
    });

    console.log('UpdatePricing:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateDescriptionAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    UpdateDescriptionRequest
>('products/changeDescriptionType', async (data: UpdateDescriptionRequest) => {
    const result = await http<ActionResponse<InternalProduct>, UpdateDescriptionRequest>({
        path: `${baseUrl}/description`,
        method: 'put',
        body: data,
    });

    console.log('UpdateDescription:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const deleteProductAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    ProductProjectAction
>('products/deleteProduct', async (data: ProductProjectAction) => {
    const result = await http<ActionResponse<InternalProduct>, ProductProjectAction>({
        path: `${baseUrl}/delete`,
        method: 'post',
        body: data,
    });

    console.log('DeleteProduct:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const deleteCommentAction = createAsyncThunk<CommonResponse | undefined, number>(
    'products/deleteComment',
    async (data: number) => {
        const result = await http<CommonResponse, number>(
            {
                path: `${baseUrl}/delete-comment/${data}`,
                method: 'delete',
            },
            true,
            false,
        );
        console.log('DeleteComment:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const addProductsFromFavorite = createAsyncThunk<
    ActionResponse<AddProductsFromFavoriteResponse> | undefined,
    AddProductsFromFavoriteRequest
>('products/addProductsFrom', async (data: AddProductsFromFavoriteRequest) => {
    const result = await http<
        ActionResponse<AddProductsFromFavoriteResponse>,
        AddProductsFromFavoriteRequest
    >(
        {
            path: `${baseUrl}/add-from-favorite`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('AddProductsFromFavorite:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const retrieveUploadFileAction = async (data: RetrieveUploadFileRequest) => {
    const result = await httpBuffer(`${baseUrl}/file-stream`, 'arraybuffer', 'post', data);
    const blob = new Blob([result], {
        type: 'application/octet-stream',
    });
    console.log('GetUploadFile:');
    console.log(blob);
    return blob;
};

export const addCommentAction = createAsyncThunk<
    ActionResponse<Comment> | undefined,
    AddCommentRequest
>('products/addComment', async (data: AddCommentRequest) => {
    const result = await http<ActionResponse<Comment>, AddCommentRequest>(
        {
            path: `${baseUrl}/add-comment`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('AddComment:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const deleteAllCommentsAction = createAsyncThunk<
    CommonResponse | undefined,
    ProductProjectAction
>('products/deleteAllComments', async (data: ProductProjectAction) => {
    const result = await http<CommonResponse, ProductProjectAction>(
        {
            path: `${baseUrl}/delete-all-comments`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('DeleteAllComments:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updatePricingInfoAction = createAsyncThunk<
    ActionResponse<Comment> | undefined,
    UpdatePricingInfoRequest
>('products/addPricingInfo', async (data: UpdatePricingInfoRequest) => {
    const result = await http<ActionResponse<Comment>, UpdatePricingInfoRequest>(
        {
            path: `${baseUrl}/pricing-information`,
            method: 'put',
            body: data,
        },
        true,
        false,
    );
    console.log('UpdatePricingInformation:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getProductFilesAction = createAsyncThunk<
    ActionResponse<UploadsTableFields> | undefined,
    GetUploadFilesRequest
>('products/getProductFiles', async (data: GetUploadFilesRequest) => {
    const result = await http<ActionResponse<UploadsTableFields>, GetUploadFilesRequest>(
        {
            path: `${baseUrl}/get-uploads`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('GetProductFiles:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getUploadedImagesCountAction = createAsyncThunk<
    ActionResponse<number> | undefined,
    GetUploadedImagesCountRequest
>('products/getUploadedImagesCount', async (data: GetUploadedImagesCountRequest) => {
    const result = await http<ActionResponse<number>, GetUploadedImagesCountRequest>(
        {
            path: `${baseUrl}/upload-image-count`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('GetUploadedImagesCount:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const uploadProductFileAction = createAsyncThunk<any | undefined, UploadProductFileRequest>(
    'products/uploadProductFile',
    async (uploadFileData: UploadProductFileRequest) => {
        const data = createMultipartData(uploadFileData);
        const result = await httpMultipart({
            path: `${baseUrl}/upload-file`,
            method: 'post',
            body: data,
        });
        console.log('UploadFileToProduct:');
        console.log(result.data);
        return result.data;
    },
);

export const updateProductFileAction = createAsyncThunk<
    ActionResponse<InternalProduct> | undefined,
    UpdateProductFileAction
>('products/updateProductFile', async (data: UpdateProductFileAction) => {
    const result = await http<ActionResponse<InternalProduct>, UpdateProductFileAction>(
        {
            path: `${baseUrl}/upload-file`,
            method: 'put',
            body: data,
        },
        true,
        false,
    );
    console.log('UpdateProductFiles:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const deleteFileAction = createAsyncThunk<
    CommonResponse | undefined,
    DeleteUploadDocumentRequest
>('products/deleteFile', async (data: DeleteUploadDocumentRequest) => {
    const result = await http<CommonResponse, DeleteUploadDocumentRequest>(
        {
            path: `${baseUrl}/delete-upload-document`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('DeleteUploadDocument:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getProductCommentsAction = createAsyncThunk<
    ActionResponse<Array<Comment>> | undefined,
    ProductProjectAction
>('products/getProductComments', async (data: ProductProjectAction) => {
    const result = await http<ActionResponse<Array<Comment>>, ProductProjectAction>(
        {
            path: `${baseUrl}/get-comments`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('GetProductComments:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const importProductsFromProjectLibraryAction = createAsyncThunk<
    ActionResponse<ImportProductsFromProjectLibraryResponse> | undefined,
    ImportProductsFromProjectLibraryRequest
>(
    'products/importProductsFromProjectLibrary',
    async (data: ImportProductsFromProjectLibraryRequest) => {
        const result = await http<
            ActionResponse<ImportProductsFromProjectLibraryResponse>,
            ImportProductsFromProjectLibraryRequest
        >({
            path: `${baseUrl}/import-products-from-project`,
            method: 'post',
            body: data,
        });
        console.log('ImportProductsFromProjectLibrary:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const getProductIdsByPackageIdAction = createAsyncThunk<
    GetProductIdsByPackageIdResponse | undefined,
    GetProductIdsByPackageIdRequest
>('products/getProductIdsByPackageId', async (data: GetProductIdsByPackageIdRequest) => {
    const result = await http<GetProductIdsByPackageIdResponse, GetProductIdsByPackageIdRequest>({
        path: `/products/get-from-buffer`,
        method: 'post',
        body: data,
    });
    console.log('GetBufferProducts:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getDocumentTypesAction = createAsyncThunk<
    ActionResponse<GetDocumentTypesResponse> | undefined,
    GetDocumentTypesAction
>(getDocumentTypesUrl, async (data: GetDocumentTypesAction) => {
    const result = await http<ActionResponse<GetDocumentTypesResponse>, GetDocumentTypesAction>({
        path: `${baseUrl}/documentTypes`,
        method: 'post',
        body: data,
    });
    return result.parsedBody;
});

export const getSustainabilityDocumentTypesAction = createAsyncThunk<
    ActionResponse<GetDocumentTypesResponse> | undefined,
    GetDocumentTypesAction
>(getDocumentTypesUrl, async (data: GetDocumentTypesAction) => {
    const result = await http<ActionResponse<GetDocumentTypesResponse>, GetDocumentTypesAction>({
        path: `${baseUrl}/documentTypes`,
        method: 'post',
        body: data,
    });
    if (result?.parsedBody?.data?.documentTypes) {
        result.parsedBody.data.documentTypes = result.parsedBody.data.documentTypes.filter((dt) => (dt.isDeleted === null || !dt.isDeleted) && dt.groupName==='Sustainability')
    }
    return result.parsedBody;
});

export const getOtherDocumentTypesAction = createAsyncThunk<
    ActionResponse<GetDocumentTypesResponse> | undefined,
    GetDocumentTypesAction
>(getDocumentTypesUrl, async (data: GetDocumentTypesAction) => {
    const result = await http<ActionResponse<GetDocumentTypesResponse>, GetDocumentTypesAction>({
        path: `${baseUrl}/documentTypes`,
        method: 'post',
        body: data,
    });
    if (result?.parsedBody?.data?.documentTypes) {
        result.parsedBody.data.documentTypes = result.parsedBody.data.documentTypes.filter((dt) => (dt.isDeleted === null || !dt.isDeleted) && dt.groupName==='Other')
    }
    return result.parsedBody;
});
