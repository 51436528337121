import React, { useEffect, useState } from 'react';
import { scrollToTarget } from '../../../helpers/DOM';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { ActionResponse, CommonResponse } from '../../../models/actionResponse/types';
import { Comment } from '../../../models/comment/types';
import { FavoriteType } from '../../../models/product/enums';
import { Product } from '../../../models/product/types';
import { MFRProject } from '../../../models/project/types';
import { getFavoriteCommentsAction } from '../../../redux/actions/favoriteProductsActions';
import {
    deleteCommentAction,
    getProductCommentsAction,
} from '../../../redux/actions/productActions';
import { MergedFavoriteProduct } from '../../favoritProducts/FavoriteProducts.types';
import CommentItem from './commentItem/CommentItem';
import { CommentsSection } from './ProductComments.styles';

interface Props {
    product: MergedFavoriteProduct | Product;
    projectData?: MFRProject;
    favoriteType: FavoriteType | undefined;
    newComment: Comment | null;
    deleteAllComments: number;
    commentId?: string;
    changeCommentsCount: () => void;
}

const ProductCommentsSection: React.FC<Props> = ({
    favoriteType,
    product,
    newComment,
    deleteAllComments,
    commentId,
    projectData,
    changeCommentsCount,
}) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);

    const [state, setState] = useState({
        pending: true,
        comments: Array<Comment>(),
    });

    const { productReducer } = selector;

    useEffect(() => {
        getComments();
    }, []);

    useEffect(() => {
        if (newComment) {
            const currentComments = state.comments;
            currentComments.unshift(newComment);
            setState({ ...state, comments: currentComments });
        }
    }, [newComment]);

    useEffect(() => {
        if (deleteAllComments) {
            setState({ ...state, comments: Array<Comment>() });
        }
    }, [deleteAllComments]);

    const getComments = async () => {
        let comments = Array<Comment>();
        if (favoriteType !== undefined) {
            const { payload } = (await dispatch(
                getFavoriteCommentsAction({
                    favoriteType,
                    productFavoriteId: product.id,
                }),
            )) as ActionResponse<Array<Comment>>;
            if (payload.data) {
                comments = payload.data;
            }
        } else {
            const { payload } = (await dispatch(
                getProductCommentsAction({
                    productId: product.product.productId,
                    projectId: product.product.projectId,
                    projectProductId: product.product.id,
                }),
            )) as ActionResponse<Array<Comment>>;
            if (payload.data) {
                comments = payload.data;
            }
        }
        comments?.map((comment, i) => (
            comment.createdOn = new Date(comment.createdOn + 'Z')
        ))

        setState({ ...state, comments, pending: false });
        if (commentId && comments.find((c) => c.id.toString() === commentId.toString())) {
            setTimeout(() => {
                const scrollableDiv = document.getElementById('comments-list');
                const targetElement = document.getElementById(commentId.toString());
                scrollToTarget(targetElement, scrollableDiv);
            }, 1000);
        }
    };

    const deleteComment = async (commentToDeleteId: number) => {
        if (productReducer.pending) {
            return;
        }
        const { payload } = (await dispatch(deleteCommentAction(commentToDeleteId))) as CommonResponse;
        if (payload.message && state.comments) {
            const commentIndex = state.comments.findIndex((comment) => comment.id === commentToDeleteId);
            const currentComments = state.comments;
            currentComments.splice(commentIndex, 1);
            setState({ ...state, comments: currentComments });
            changeCommentsCount();
        }
    };

    return (
        <CommentsSection id="comments-list">
            {state.comments && (
                <>
                    {state.comments?.map((comment, i) => (
                        <CommentItem
                            comment={comment}
                            key={comment.id}
                            deleteComment={deleteComment}
                            favoriteType={favoriteType}
                            projectData={projectData}
                            lastItem={state.comments.length - 1 === i}
                        />
                    ))}
                </>
            )}
        </CommentsSection>
    );
};

export default ProductCommentsSection;
