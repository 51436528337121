import styled from 'styled-components';
import { grayLight01, grayLight04 } from '../../../styles/colors';
import { BoldText, Text } from '../../../styles/common.styles';

export const MFRHeaderSpace = styled('table')`
    width: 100%;
    background-color: ${grayLight04};
`;

export const MFRHeaderRow = styled('tr')`
    th {
        width: 25%;
    }
    .th {
        border-right: 1px solid ${grayLight01};
    }
`;

export const MFRHeaderItem = styled('th')``;

export const MFRTH = styled('div')`
    padding: 0 20px;
    margin: 16px 0;
    display: flex;
`;

export const MFRHeaderTitle = styled(Text)`
    text-align: start;
    text-transform: uppercase;
`;

export const MFRHeaderValue = styled(BoldText)`
    text-align: start;
`;
