/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { CloseIcon } from '../../../../styles/common.styles';
import ModalFooter from '../../../common/modalFooter/ModalFooter';
import ModalTitle from '../../../common/modalTitle/ModalTitle';
import { blueDark01 } from '../../../../styles/colors';
import { SuccessExportModalText } from './EC3SuccessExport.styles';
import { useAppDispatch } from '../../../../hooks/redux';
import { setEc3ProjectBegan } from '../../../../redux/reducers/ec3Reducer/EC3Slice';
interface Props {
    isOpen: boolean;
    projectId: number;
    closeModal: () => void;
    setPersistActiveTab?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const EC3SuccessExport: React.FC<Props> = ({
    isOpen,
    projectId,
    closeModal,
    setPersistActiveTab,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(setEc3ProjectBegan());
    }, []);
    const goToEC3Tab = () => {
        closeModal();
        if (setPersistActiveTab) {
            window.location.href = `/project-info/${projectId}/0/5?projectId=${projectId}`;
        } else {
            history.push(`/project-info/${projectId}/0/5`);
        }
    };
    return (
        <Modal open={isOpen} onCancel={closeModal} closeIcon={<CloseIcon />} centered footer={null}>
            <ModalTitle title="Export to EC3" icon="ic-modal-title-export.svg" />
            <SuccessExportModalText weight={400} height={20} size={15} color={blueDark01}>
                Your export to EC3 has begun. This can take a little while, so to keep an eye on
                your export’s progress,{' '}
                <span onClick={goToEC3Tab}> visit this project’s EC3 exports tab.</span>
            </SuccessExportModalText>
            <ModalFooter
                action="View export status"
                cancel={closeModal}
                submit={goToEC3Tab}
                actionButtonWidth={140}
            />
        </Modal>
    );
};

export default EC3SuccessExport;
