import React from "react";
import { AlternativeTextContainer, AlternativeTextTipContainer } from "./UploadFileForm.styles";
import CustomInputUploadFileForm from "../../../common/inputs/CustomInputUploadFileForm";
import { Input, Image } from "antd";

interface Props {
    label: string;
    name: string;
    placeholder: string;
    suffix: string;
}

const AlternativeTextInput: React.FC<Props> = ({
    label,
    name,
    placeholder,
    suffix,
}) => {
    return (
        <AlternativeTextContainer className='altTextContainer'>
            <CustomInputUploadFileForm
                Component={Input}
                label={label}
                name={name}
                suffix={suffix}
                props={{
                    placeholder: placeholder,
                    rows: 1,
                }}
                block
                rules={[
                    {
                        max: 1000,
                        message: 'Value should be less than 1000 characters',
                    },
                ]}
            />
            <AlternativeTextTipContainer className='altTextTipContainer'>
                <Image
                    width={13}
                    preview={false}
                    src="src/assets/images/circle-question.svg"
                />
                <div className="tip">
                    Provide alternative text to help people using screen readers understand what the image looks like.{' '}
                    <a href="https://www.w3.org/WAI/tutorials/images/tips/" target="_blank">Learn more about writing good alt text.</a>
                </div>
            </AlternativeTextTipContainer>
        </AlternativeTextContainer>
    );
}

export default AlternativeTextInput;