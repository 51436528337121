import React from "react";
import { AltTextTooltip } from "./UploadTable.styles";
import { UploadedImageData } from "../UploadsInfo";
import { Tooltip } from "antd";
import { ImageContainer, ImageItemContainer, ImageNameContainer } from "./ImagesTable.styles";
import { grayDark01 } from "../../../../styles/colors";
import '../../../../styles/overlapStyles/tooltip.scss';

interface Props {
    image: UploadedImageData;
    imageIndex: number;
    onClick: (imageIndex: number) => void;
};

const ImageItem: React.FC<Props> = ({ image, imageIndex, onClick }) => {
    const getAltTextTooltip = () => {
        if (!image.altText?.length) {
            return <></>;
        }

        return (
            <Tooltip title={image.altText} color={grayDark01} overlayClassName='altText'>
                <AltTextTooltip>ALT</AltTextTooltip>
            </Tooltip>
        );
    }

    return (
        <ImageItemContainer onClick={() => onClick(imageIndex)}>
            <ImageContainer>
                <img src={image.url}/>
                {getAltTextTooltip()}
            </ImageContainer>
            <Tooltip title={image.description} color={grayDark01}>
                <ImageNameContainer>{image.name}</ImageNameContainer>
            </Tooltip>
        </ImageItemContainer>
    );
};

export default ImageItem;