import React from 'react';
import { UserTableFields } from '../../../models/user/user';

import { toShortLocalDate, toLocalShortDateTime } from '../../../helpers/dateHelper';
import { CustomColumnType } from '../../common/table/CustomTable.types';
import { ClickableRow } from '../../../styles/common.styles';
import { NavLink } from 'react-router-dom';
import { UsersTableEmail, UsersTableGraySpan } from './UsersTable.styles';

export const columns: CustomColumnType<UserTableFields>[] = [
    {
        title: 'Last name',
        dataIndex: 'lastName',
        sorter: true,
        clickable: true,
        render: (value, record) => (
            <NavLink to={`/users/edit/${record.key}`}>
                <ClickableRow>{value}</ClickableRow>
            </NavLink>
        ),
    },
    {
        title: 'First name',
        dataIndex: 'firstName',
        sorter: true,
        clickable: true,
        render: (value, record) => (
            <NavLink to={`/users/edit/${record.key}`}>
                <ClickableRow>{value}</ClickableRow>
            </NavLink>
        ),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
        clickable: true,
        render: (value, record) => (
            <NavLink to={`/users/edit/${record.key}`}>
                <UsersTableEmail>{value}</UsersTableEmail>
            </NavLink>
        ),
    },
    {
        title: 'Account type',
        sorter: true,
        dataIndex: 'accountRole',
        render: (value) => <span>{value?.replace('Account', '') || 'Admin'}</span>,
    },
    {
        title: 'Account created',
        sorter: true,
        dataIndex: 'accountCreated',
        render: (value) => <UsersTableGraySpan>{toShortLocalDate(value)}</UsersTableGraySpan>,
    },
    {
        title: 'Last Login',
        sorter: true,
        dataIndex: 'lastLoginTime',
        render: (value) => <UsersTableGraySpan>{toLocalShortDateTime(value)}</UsersTableGraySpan>,
    },
];
