import React from 'react';
import { EmptyDataContainer } from './EmptyData.styles';

interface Props {
    text: string;
    text2: string;
    pending?: boolean;
}

const EmptyData: React.FC<Props> = ({ text, text2, pending = true }) => {
    if (text2) {
        return <EmptyDataContainer>{!pending && <>{text}<br/>{text2}</>}</EmptyDataContainer>;
    }
    return <EmptyDataContainer>{!pending && <>{text}</>}</EmptyDataContainer>;
};

export default EmptyData;
