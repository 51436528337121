import styled from 'styled-components';
import {
    blue,
    blue01,
    blue02,
    blueDark01,
    blueDark03,
    blueLink,
    grayLight02,
    grayLight04,
    grayLight05,
    white,
} from '../../../../styles/colors';
import { PrimaryButton, Text } from '../../../../styles/common.styles';

export const CompanyDescriptionContainer = styled('div')`
    img {
        min-width: 80px;
    }
    .ant-image-img {
        width: auto;
        max-width: 225px;
        height: auto;
        vertical-align: middle;
     }
    display: flex;
    justify-content: space-between;
`;

export const CompanyDescription = styled('div')`
    margin: 0 40px 0 95px;
    a {
        color: ${blueLink};
    }
`;

export const CompanyLinkContainer = styled(Text)`
    margin-top: 25px;
    a {
        color: ${blueLink};
        font-weight: 600;
    }
`;

export const ContactToCompany = styled('div')`
    padding: 20px;
    min-width: 235px;
    max-width: 235px;
    max-height: 140px;
    background-color: ${white};
    box-shadow: 3px 3px 5px rgba(0,0,0,.16);
    .text {
        font-family: 'DIN Next W01 Medium';
        font-weight: 500;
        font-size: 17px;
        line-height: 132%;
    }
`;

export const ContactUsButton = styled(PrimaryButton)`
    margin-top: 20px;
    background-color: ${blue};
    color: ${white};
    font-family: 'DINNextLTPro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    border-radius: 3px;
    &:hover {
        background-color: ${blue02};
        color: ${grayLight05};
    }
    &:focus {
        color: ${grayLight02};
        background-color: ${blueDark03};
    }
`;
