import { Button } from 'antd';
import styled from 'styled-components';
import {
    blueDark01,
    blueDark02,
    blueLink,
    grayDark06,
    grayLight01,
    white,
} from '../../../styles/colors';
import { ProductSwitcherContent } from '../../../styles/common.styles';

export const MFRSectionItemContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4px;
`;

export const MFRSectionItemDivider = styled('div')`
    border-top: 1px solid ${grayLight01};
    margin: 0 0 0 40px !important;
`;

export const MFRSectionItemTitleContainer = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        .hover {
            color: ${blueLink};
        }
        .switch-button {
            background-color: ${blueLink} !important;
            &:after {
                background: white;
            }
            &:before {
                background: white;
            }
        }
    }
`;

export const MFRSectionItemWrapper = styled('div')`
    padding: 16.5px 0;
`;

export const MFRSectionTitle = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 16px;
    line-height: 20px;
    color: ${blueDark01};
    margin-right: 5px;
    &.section-id {
        margin-left: -13.5px;
    }
`;

export const MFRProductsContainer = styled(ProductSwitcherContent)`
    .product-item-container:first-child {
        border-top: 1px solid ${grayLight01};
    }
`;

export const MFRProductActionsButtonContainer = styled('div')`
    color: ${blueDark01};
    &:hover {
        button:first-child {
            background-color: ${white};
            color: ${blueDark01};
            border-color: #40a9ff;
            border-right: 0.5px solid #40a9ff;
        }
        button:last-child {
            background-color: ${white};
            color: #ffff;
            border-color: #40a9ff;
            border-left: 0.5px solid #40a9ff;
        }
    }
`;

export const MFRProductActionsButton = styled(Button)`
    margin-left: 5px;
    border-radius: 3px;
    background-color: ${white};
    color: ${blueDark01};
    cursor: pointer;
    border-color: ${grayDark06};
    border-radius: 3px 0 0 3px;
    &:focus {
        background-color: ${white};
        color: ${blueDark01};
        border-color: ${grayDark06};
    }
`;

export const MFRProductActionsMenu = styled(Button)`
    border-radius: 0px 3px 3px 0;
    padding: 0 10px;
    background-color: ${white};
    border-color: ${grayDark06} ${grayDark06} ${grayDark06} rgba(255, 255, 255, 0.3);
    color: ${blueDark02};
    &:focus {
        color: #ffff;
        border-color: #ffff;
    }
`;
