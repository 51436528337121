import { Form } from 'antd';
import React from 'react';
import { blueDark01 } from '../../../styles/colors';
import ModalFooter from '../modalFooter/ModalFooter';
import ModalTitle from '../modalTitle/ModalTitle';
import { CloseIcon, Text } from '../../../styles/common.styles';
import { ResetPassModal } from './ResetModal.styles';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'reset' }) => void;
    reload: (modal: string) => void;
    text: string;
}

const ResetPasswordModal: React.FC<Props> = ({ isOpen, closeModal, text, reload }) => {
    const handleCancel = () => {
        closeModal({ key: 'reset' });
    };

    const onDelete = () => {
        reload('reset');
    };
    return (
        <ResetPassModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            className="reset-modal-container"
            closeIcon={<CloseIcon />}
        >
            <Form onFinish={onDelete}>
                <ModalTitle title={`Reset password`} />
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    Are you sure you want to reset password for {text} ?
                </Text>
                <ModalFooter cancel={handleCancel} submit={() => {}} action="Reset" />
            </Form>
        </ResetPassModal>
    );
};

export default ResetPasswordModal;
