import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Spin } from 'antd';
import CustomInput from '../../common/inputs/CustomInput';
import { loginAction } from '../../../redux/actions/authActions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { resetSpin } from '../../../redux/reducers/authReducer/AuthSlice';
import { PrimaryButton } from '../../../styles/common.styles';
import { getProjectInformationAction } from '../../../redux/actions/projectActions';
import {
    setAcceptedInvitation,
    setProjectPackageId,
} from '../../../redux/reducers/projectsReducer/ProjectSlice';
import { setFilePath, setPackageId } from '../../../redux/reducers/productReducer/ProductSlice';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import {
    ButtonEmptySpace,
    ButtonFormItem,
    ForgotPasswordLink,
    LoginButtonContainer,
    LoginFormStyle,
    LoginSubTitle,
    LoginTitle,
} from './LoginForm.styles';
import { LoginActionResponse } from '../../../redux/actions/authActions/types';
import { uploadDocumentByName } from '../../../helpers/DOM';
import { setCookies } from '../../../redux/persistedState';

const LoginForm: React.FC = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { authReducer, projectReducer, productReducer } = selector;

    const history = useHistory();

    useEffect(() => {
        dispatch(resetSpin());
    }, []);

    const onSubmit = async (data: { userName: string; password: string }) => {
        if (!data.userName.trim()) {
            return;
        }
        const { payload } = (await dispatch(
            loginAction({ ...data, userName: data.userName.trim() }),
        )) as LoginActionResponse;
        if (payload?.user) {
            setCookies(payload.tokens.accessToken, payload.tokens.refreshToken);
            const userRole = payload.user?.userRoles[0].role?.name;
            const returnUrl = params.get('return');
            if (returnUrl) {
                window.location.href = returnUrl;
            }
            if (payload.user.subscription.planName !== SubscriptionTypes.FREE) {
                if (productReducer.packageId) {
                    history.push(`/project-library/${productReducer.packageId}`);
                    dispatch(setPackageId(undefined));
                    return;
                }
                if (projectReducer.packageIdData && userRole !== 'SMAdmin') {
                    history.push(
                        `/project-info/${projectReducer.packageIdData?.projectId}/${projectReducer.packageIdData?.packageId}/2`,
                    );
                    dispatch(setProjectPackageId(undefined));
                    return;
                }
                if (productReducer.filePath) {
                    uploadDocumentByName(productReducer.filePath);
                    dispatch(setFilePath(undefined));
                }
            }
            if (projectReducer.acceptedInvitation) {
                await dispatch(
                    getProjectInformationAction(projectReducer.acceptedInvitation.toString()),
                );
                dispatch(setAcceptedInvitation(0));
            }
            if (userRole !== 'SMAdmin') {
                history.push('/dashboard');
            } else {
                history.push('/users');
            }
        }
    };

    return (
        <>
            <LoginFormStyle
                name="basic"
                labelCol={{ span: 6 }}
                initialValues={{ remember: false }}
                onFinish={onSubmit}
                className="login-form-container"
                autoComplete="off"
            >
                <LoginTitle>Welcome! Log in.</LoginTitle>
                <LoginSubTitle>
                    We’re thrilled to have your organization evaluate Transparency 
                    Catalog Project Builder & Library. Log in with the credentials we sent you.
                </LoginSubTitle>
                <CustomInput
                    Component={Input}
                    label="Email"
                    name="userName"
                    rules={[{ required: true, message: '' }]}
                    forbidWhitespace={true}
                />

                <CustomInput
                    Component={Input.Password}
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: '' }]}
                />

                <ButtonFormItem>
                    <ButtonEmptySpace></ButtonEmptySpace>
                    <LoginButtonContainer>
                        <PrimaryButton htmlType="submit">
                            {!authReducer.pending ? <>Log in</> : <Spin size="small" />}
                        </PrimaryButton>
                        <ForgotPasswordLink to="/forgot-password">
                            Forgot password?
                        </ForgotPasswordLink>
                    </LoginButtonContainer>
                </ButtonFormItem>
            </LoginFormStyle>
        </>
    );
};

export default LoginForm;
