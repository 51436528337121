import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomTable from '../../../../components/common/table/CustomTable';
import { TableData } from '../../../../models/tableData/types';
import { SMAdminTableFields, SMAdminUserTableFields } from '../../../models/user/smAdminUser';
import { columns } from './SMAdminUsersTableColumns';
import { UsersTableContainer, UsersTableSpinner } from './SMAdminUsersTable.styles';
import { SortOrder } from 'antd/lib/table/interface';
import SMAdminUsersTableActions from './SMAdminUsersTableActions';
import { getAdminSortExpressionIdByString } from './SMAdminUsersTable.helper';
import { UserType } from '../../../pages/users/SMAdminUsersPage';
import { SubscriptionPlan } from '../../../../redux/actions/userActions/types';
import EmptyData from '../../../../components/common/emptyData/EmptyData';
import { setSelectedRows } from '../../../../redux/reducers/usersReducer/UserSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

interface Props {
    tableData: TableData;
    users: Array<SMAdminUserTableFields | SMAdminTableFields>;
    totalCount: number;
    isPending: boolean;
    addiTionalItems: Array<SMAdminUserTableFields | SMAdminTableFields>;
    searchComponent: React.ReactNode;
    userType: UserType;
    subscriptions: Array<SubscriptionPlan>;
    setTableData: (data: any) => void;
    getUsers: (data: TableData) => void;
}

const SMUsersTable: React.FC<Props> = ({
    tableData,
    users,
    totalCount,
    isPending,
    addiTionalItems,
    searchComponent,
    userType,
    subscriptions,
    setTableData,
    getUsers,
}) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const history = useHistory();
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { userReducer, authReducer } = selector;

    useEffect(() => {
        setSelectedRowKeys(userReducer.selectedRows.map((user) => user.key));
    }, [userReducer.selectedRows]);

    const onSelectChange = (newSelectedUser: SMAdminUserTableFields) => {
        const selectedUsers = userReducer.selectedRows.slice();
        const index = selectedUsers.findIndex(
            (selectedUser: SMAdminUserTableFields) => selectedUser.key === newSelectedUser.key,
        );
        if (index === -1) {
            selectedUsers.push(newSelectedUser);
        } else {
            selectedUsers.splice(index, 1);
        }
        setSelectedRowKeys(selectedUsers.map((user) => user.key));
        dispatch(setSelectedRows(selectedUsers));
    };

    const onSelectAll = (isAll: boolean, data: any) => {
        let selectedUsers: Array<SMAdminUserTableFields> = [];
        if (isAll) {
            selectedUsers = data.filter(u => u !== undefined);
        }
        setSelectedRowKeys(selectedUsers.map((user) => user.key));
        dispatch(setSelectedRows(selectedUsers));
    };

    const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectChange,
        onSelectAll: onSelectAll,
        getCheckboxProps: (record: SMAdminUserTableFields) => {
            return {
                style:
                    authReducer.user?.id === record.id
                        ? { display: 'none' }
                        : {},
                disabled: authReducer.user?.id === record.id,
            };
        },
    };

    const [actionData, setActionData] = useState<{
        action: string | null;
        user: SMAdminUserTableFields | SMAdminTableFields | null;
    }>({
        action: null,
        user: null,
    });

    const changePageSize = (pageSize: number) => {
        let curPage = tableData.curPage;
        if (Math.ceil(totalCount / pageSize) < tableData.curPage) {
            curPage = Math.ceil(totalCount / pageSize);
        }
        getUsers({ ...tableData, pageSize: pageSize, curPage: curPage });
    };

    const handleCurrentPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentCount = event.currentTarget.value;
        if (/^-?\d*\.?\d*$/.test(currentCount) || currentCount === '') {
            setTableData({ ...tableData, curPage: +event.currentTarget.value });
            if (+currentCount > Math.ceil(totalCount / tableData.pageSize)) {
                setTimeout(() => {
                    setTableData({
                        ...tableData,
                        curPage: Math.ceil(totalCount / tableData.pageSize),
                    });
                    changePageNumber(Math.ceil(totalCount / tableData.pageSize));
                }, 1000);
            }
        }
        if (
            +event.currentTarget.value > 0 &&
            +event.currentTarget.value <= Math.ceil(totalCount / tableData.pageSize)
        ) {
            changePageNumber(+event.currentTarget.value);
        }
    };

    const changePageNumber = (pageNumber: number) => {
        getUsers({ ...tableData, curPage: pageNumber });
    };

    const changeSort = (field: string, direction?: SortOrder) => {
        const sortingField = getAdminSortExpressionIdByString(field);
        getUsers({
            ...tableData,
            sortDirection: direction === 'descend' ? 'desc' : 'asc',
            sortExpression: direction ? sortingField : null,
        });
    };

    const clickRow = (data: SMAdminUserTableFields | SMAdminTableFields) => {
        if (userType === 'user') {
            history.push(`/users/edit/${data.key}`);
        } else {
            history.push(`/admins/edit/${data.key}`);
        }
    };

    const handleActions = (action: string, user: SMAdminUserTableFields | SMAdminTableFields) => {
        setActionData({ action: action, user: user });
    };

    const riseAction = () => {
        setActionData({ action: null, user: null });
    };

    const reloadPage = () => {
        getUsers(tableData);
    };

    return (
        <UsersTableContainer>
            <UsersTableSpinner size="large" spinning={isPending}>
                <CustomTable
                    changeSort={changeSort}
                    tableData={{
                        ...tableData,
                        pageSize:
                            totalCount < tableData.pageSize
                                ? tableData.pageSize
                                : addiTionalItems.length + tableData.pageSize,
                    }}
                    data={users}
                    rowSelection={rowSelection}
                    totalCount={totalCount}
                    showTotalCount
                    columns={columns(handleActions, userType, subscriptions)}
                    tableName="Users"
                    changePageSize={changePageSize}
                    handleCurrentPage={handleCurrentPage}
                    changePageNumber={changePageNumber}
                    clickRow={clickRow}
                    searchComponent={searchComponent}
                    locale={{
                        emptyText: <EmptyData text={`No results found`} pending={isPending} />,
                    }}
                />
                {actionData.action && (
                    <SMAdminUsersTableActions
                        action={actionData.action}
                        user={actionData.user!}
                        riseAction={riseAction}
                        refresh={reloadPage}
                        userType={userType}
                    />
                )}
            </UsersTableSpinner>
        </UsersTableContainer>
    );
};

export default SMUsersTable;
