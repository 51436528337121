import { Modal, Space } from 'antd';
import styled from 'styled-components';

export const ShareProductModal = styled(Modal)`
    .ant-spin-nested-loading > div > .ant-spin {
        top: 0;
    }
    .ant-modal-content {
        width: 600px;
        border-radius: 6px;
    }
    .invite-form {
        #select-role {
            position: relative;
            display: flex;
            #input-container {
                width: 100%;
            }
            img {
                position: absolute;
                top: -10px;
                left: -100px;
            }
        }
        .text-area-container {
            .ant-space-item {
                width: 100%;
                .ant-form-item-control {
                    min-width: 100%;
                    margin-right: 20px;
                }
            }
            .ant-form-item-label {
                margin-left: 5px;
            }
        }
    }
`;

export const RoleTip = styled('div')``;

export const InviteTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    .access-tip {
        display: none;
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -70px;
            right: 40px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            z-index: 2;
            width: 400px;
        }
        .access-tip {
            display: block;
            position: absolute;
            top: 30px;
            right: -90px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 202px;
        }
    }
`;

export const CollaborationSpace = styled(Space)`
    margin-top: 10px;
    width: 100%;
    align-items: center;
    .ant-space-item:first-child {
        width: 60%;
        .ant-form-item-control {
            max-width: 100%;
            margin-right: 20px;
        }
    }
    .ant-space-item:nth-last-of-type(2) {
        width: 40%;
    }
    .ant-form-item-control {
        max-width: 100%;
    }
`;