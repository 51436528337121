import React from 'react';
import { PageHeaderContainer, PageHeaderTitleContainer } from './PageHeader.styles';
import NavigationMenu from '../navigationMenu/NavigationMenu';
import { NavigationGroup, NavigationGroupItem } from '../navigationMenu/NavigationMenu.types';
import { useAppSelector } from '../../../hooks/redux';
import PageTitle from '../pageTitle/PageTitle';

import { usePermissions } from '../../../hooks/permissions';
import { AccountPermissionTypes, UserRoleTypes } from '../../../models/permission/enums';
import { SubscriptionTypes } from '../../../models/subscription/enums';

interface Props {
    title: string;
    showMenu?: boolean;
    children?: React.ReactNode;
    titleChildren?: React.ReactNode;
}

const PageHeader: React.FC<Props> = ({ title, children, titleChildren, showMenu = true }) => {
    const selector = useAppSelector((state) => state.authReducer);
    const { getUserRole, getAccountRole, getAccountSubscription } = usePermissions();
    const navigationGroups = Array<NavigationGroup>();
    if (getUserRole() === UserRoleTypes.SM_USER) {
        navigationGroups.push({
            title: 'Projects',
            icon: 'project-menu.svg',
            items: [
                {
                    name: `${selector.user?.organization?.name} Projects`,
                    link: '/project-library',
                },
            ],
        });

        if (getAccountSubscription() !== SubscriptionTypes.FREE && (getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN
            || getAccountRole() === AccountPermissionTypes.ACCOUNT_USER)) {
            const favoriteItems = Array<NavigationGroupItem>();
            if (
                getAccountSubscription() === SubscriptionTypes.OFFICE ||
                getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE
            ) {
                favoriteItems.push({
                    name: `${selector.user?.organization?.name} Library`,
                    link: '/favorites',
                });
            }
            favoriteItems.push({ name: 'My Library', link: `/my-favorites` });
            navigationGroups.push({
                title: 'Favorite Products Libraries',
                icon: 'star.svg',
                items: favoriteItems,
            });
        }

        if (
            getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN &&
            (getAccountSubscription() === SubscriptionTypes.OFFICE ||
                getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE)
        ) {
            navigationGroups.push({
                title: 'Account admin',
                icon: 'users-gear.svg',
                items: [
                    { name: 'Users', link: '/users' },
                    { name: `Subscription settings`, link: '/settings' },
                ],
            });
        }
    } else {
        navigationGroups.push(
            ...[
                {
                    title: 'Account admin',
                    icon: 'users-gear.svg',
                    items: [
                        { name: 'Users', link: '/users' },
                        { name: 'SM admins', link: '/admins' },
                    ],
                },
                {
                    title: 'Promotion',
                    icon: 'stars.svg',
                    items: [{ name: 'Promo blocks', link: '/promo' }],
                },
            ],
        );
    }

    return (
        <PageHeaderContainer>
            <PageHeaderTitleContainer>
                {showMenu && <NavigationMenu navigationGroups={navigationGroups} />}
                <PageTitle title={title} children={titleChildren} showMenu={showMenu} />
            </PageHeaderTitleContainer>
            {children}
        </PageHeaderContainer>
    );
};

export default PageHeader;
