import React, { useState } from 'react';
import classNames from 'classnames';
import { Col, Divider, Form, Image, Row, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { NavigationMenuProps } from './NavigationMenu.types';

import {
    MenuOutlinedButton,
    NavigationMenuButton,
    NavigationMenuClose,
    NavigationMenuContainer,
} from './NavigationMenu.styles';
import { useAppSelector } from '../../../hooks/redux';

const NavigationMenu: React.FC<NavigationMenuProps> = ({ navigationGroups }) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const selector = useAppSelector((state) => state);
    const { authReducer } = selector;

    const switchOpen = () => {
        setOpen(!open);
    };

    const goTo = (link: string) => {
        history.push(link);
    };

    return (
        <NavigationMenuContainer>
            <NavigationMenuButton onClick={switchOpen}>
                {<MenuOutlinedButton />}
            </NavigationMenuButton>
            <Form onMouseLeave={open ? switchOpen : undefined}>
                <div
                    className={classNames(
                        'navigation-card',
                        {
                            'show-card': open,
                        },
                        { 'banner-visible': authReducer.showBanner },
                    )}
                >
                    <NavigationMenuClose onClick={switchOpen} />
                    <Row className="links-container">
                        <Space size={24} align="start" split={<Divider type="vertical" />}>
                            {navigationGroups.map((group) => (
                                <Col className="group" key={group.title}>
                                    <Space direction="vertical">
                                        <Image
                                            src={`src/assets/images/${group.icon}`}
                                            preview={false}
                                            alt="icon"
                                            height={24}
                                        />
                                        <Typography.Text className="group-title">
                                            {group.title}
                                        </Typography.Text>
                                        {group.items.map((item) => (
                                            <Typography.Link
                                                key={item.name}
                                                onClick={() => goTo(item.link)}
                                            >
                                                {item.name}
                                            </Typography.Link>
                                        ))}
                                    </Space>
                                </Col>
                            ))}
                        </Space>
                    </Row>
                </div>
            </Form>
        </NavigationMenuContainer>
    );
};

export default NavigationMenu;
