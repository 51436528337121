import styled from 'styled-components';
import { blue01, blueDark01, blueLink, grayDark05, grayDark06 } from '../../styles/colors';
import { Modal } from 'antd';

export const HelpModalContainer = styled(Modal)`
    width: 680px !important;
    height: 740px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
        min-height: 400px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    .ant-form-item {
        margin-bottom: 0px;
    }

    .ant-col {
        text-align: left;
        width: 80px;
        margin-right: 16px;
    }

    .ant-form-horizontal .ant-form-item-control {
        margin-right: 0;
    }

    .ant-form-item-label > label {
        height: auto !important;
    }
`;

export const InfoBLock = styled('div')`
    width: 220px;
    h5 {
        font-family: 'DINNextLTPro';
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: ${blue01};
        text-transform: uppercase;
    }

    a {
        color: ${blueLink};
    }
`;

export const InputBlock = styled('div')`
    width: 340px;
    .ant-form-item-explain-error {
        bottom: -20px;
    }
`;

export const ModalContainer = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 40px;
`;

export const InputContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: 20px;
    color: ${blueDark01};
    font-family: 'Proxima Nova';

    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            justify-content: flex-start !important;
            font-weight: 400 !important;
            margin-right: 0;
            margin-bottom: 4px;
            width: 242px !important;

            &:last-child {
                margin-bottom: 0;
            }

            span:not(.ant-checkbox-inner) {
                align-self: center;
            }

            span.ant-checkbox {
                line-height: 16px;
                top: 0;
            }

            .ant-checkbox + span {
                text-align: left;
                width: 218px;
                padding: 0;
                margin-left: 8px;
            }
        }
    }

    textarea.ant-input {
        height: 47px;
    }

    .ant-input {
        border-radius: 3px;
        border-color: ${grayDark06};
    }

    .ant-input:hover {
        border-color: ${grayDark05};
        border-radius: 3px;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: ${blue01};
        border-radius: 3px;
        box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
    }

    .ant-checkbox-wrapper:hover > .ant-checkbox > .ant-checkbox-inner,
    .ant-checkbox:hover > .ant-checkbox-inner {
        border-color: ${grayDark05};
    }

    .ant-checkbox > .ant-checkbox-inner {
        border: 1px solid ${grayDark06};
        border-radius: 3px;
    }

    .ant-checkbox-checked > .ant-checkbox-inner {
        background-color: ${blue01};
        border-color: ${blue01};
        border-radius: 3px;
    }

    .ant-checkbox:hover::after,
    .ant-checkbox-wrapper:hover .ant-checkbox::after {
        border: none;
    }

    #input-container:not(:last-child) {
        margin-bottom: 16px;
    }

    #input-container:nth-child(3) label {
        &::after {
            top: 8px;
        }
    }

    #input-container label,
    #text-align-right label {
        width: 100%;
        height: 20px;
        text-align: right;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px;
        line-height: 16px;
        color: ${blueDark01};
        justify-content: flex-end;

        &::after {
            margin: 0;
        }
    }

    span:not(.ant-checkbox-inner) {
        font-family: 'Proxima Nova';
        font-weight: normal;
    }
`;

const ItemsParagraph = styled('p')`
    margin-bottom: 0;
`;

export const InfoContent = styled(ItemsParagraph)`
    font-weight: 400;
    font-size: 13px;
    color: ${blueDark01};
`;
