import styled from 'styled-components';
import { Spin } from 'antd';

export const EC3TabContainer = styled('div')`
    min-height: 400px;
`;

export const EC3TabSpin = styled(Spin)`
    top: 0 !important;
`;
