import React from 'react';
import { Space } from 'antd';
import { useModalState } from '../../hooks/modalState';
import { MergedFavoriteProduct } from '../favoritProducts/FavoriteProducts.types';
import { FavoriteType } from '../../models/product/enums';
import { FavoriteProductsActionImage, SearchProductButton } from './FavoriteProductActions..styles';
import ExportFavoriteToCSV from './exportFavoriteToCSV/ExportFavoriteToCSV';
import { ExternalProduct } from '../../models/product/types';
import { usePermissions } from '../../hooks/permissions';
import { AccountPermissionTypes } from '../../models/permission/enums';

interface Props {
    showActions?: boolean;
    projectId?: number;
    selectedProducts: Array<MergedFavoriteProduct>;
    favoriteType: FavoriteType;
    products: Array<ExternalProduct>;
}

const FavoriteProductsAction: React.FC<Props> = ({ showActions, favoriteType, products }) => {
    const { getAccountRole } = usePermissions();
    const exportModal = useModalState();

    const goToProducts = () => {
        const type = favoriteType === FavoriteType.MyFavorite ? 'my-favorites' : 'favorites';
        window.location.href = `${process.env.REACT_APP_TC_URL}/search?project=${type}`;
    };

    return (
        <Space>
            {showActions && (
                <FavoriteProductsActionImage
                    src="src/assets/images/file-export.svg"
                    preview={false}
                    onClick={exportModal.onOpen}
                    title="Export"
                />
            )}
            {(favoriteType === FavoriteType.MyFavorite ||
                getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN) && (
                <>
                    <SearchProductButton onClick={goToProducts}>
                        Search for products
                    </SearchProductButton>
                </>
            )}
            {exportModal.isOpen && (
                <ExportFavoriteToCSV
                    isOpen={exportModal.isOpen}
                    closeModal={exportModal.onClose}
                    products={products}
                    favoriteType={favoriteType}
                />
            )}
        </Space>
    );
};

export default FavoriteProductsAction;
