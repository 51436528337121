import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import ModalTitle from '../../../common/modalTitle/ModalTitle';
import { ProjectTableFields } from '../../../../models/project/types';
import { CopyProjectData } from '../ProjectActions.types';
import { blueDark01 } from '../../../../styles/colors';

import ModalFooter from '../../../common/modalFooter/ModalFooter';
import { Text, CloseIcon } from '../../../../styles/common.styles';
import { GetProjectInformationResponse } from '../../../../redux/actions/projectActions/types';
import CustomInput from '../../../common/inputs/CustomInput';
import { alertService } from '../../../../services/alert.service';
import { CopyProjectModal, CopyProjectSpace } from '../copyProject/CopyProject.styles';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'createFromTemplate' }) => void;
    reload: (modal: string, data: CopyProjectData) => void;
    project: ProjectTableFields | GetProjectInformationResponse;
}

const CreateFromTemplate: React.FC<Props> = ({ isOpen, closeModal, reload, project }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ copy: `${project.name}` });
    }, []);

    const handleCancel = () => {
        closeModal({ key: 'createFromTemplate' });
    };

    const onSubmit = (data: { copy: string }) => {
        if (!data.copy.trim()) {
            alertService.warn('project name cannot contain only spaces');
        }
        if (data.copy.trim()) {
            reload('createFromTemplate', { name: data.copy });
        }
    };
    return (
        <CopyProjectModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <ModalTitle
                title="Create project from manufacturer template"
                icon="ic-modal-title-copy.svg"
            />
            <div>
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    Create project as:
                </Text>
            </div>
            <Form onFinish={onSubmit} form={form}>
                <CopyProjectSpace>
                    <CustomInput
                        Component={Input}
                        label="Project name"
                        name="copy"
                        block
                        required
                        rules={[{ required: true, message: '' }]}
                    />
                </CopyProjectSpace>
                <ModalFooter submit={() => {}} cancel={handleCancel} action="Create project" />
            </Form>
        </CopyProjectModal>
    );
};

export default CreateFromTemplate;
