import React from 'react';
import { getAddress } from '../../../helpers/project';
import { MFRProject } from '../../../models/project/types';
import { blueDark02, grayDark03 } from '../../../styles/colors';
import {
    MFRHeaderSpace,
    MFRHeaderRow,
    MFRHeaderItem,
    MFRTH,
    MFRHeaderTitle,
    MFRHeaderValue,
} from './MFRPageHeader.styles';

interface Props {
    project: MFRProject | null;
}

const MFRPageHeader: React.FC<Props> = ({ project }) => {
    const getBuildingType = () => {
        let buildingType = 'None';
        if (project) {
            buildingType =
                project?.buildingType.name === 'Other' && project.otherBuildingType
                    ? project.otherBuildingType
                    : project?.buildingType.name;
        }
        return buildingType;
    };

    return (
        <MFRHeaderSpace>
            <tbody>
                <MFRHeaderRow>
                    <MFRHeaderItem>
                        <MFRTH className="th">
                            <div>
                                <MFRHeaderTitle
                                    weight={400}
                                    height={12}
                                    size={10}
                                    color={grayDark03}
                                >
                                    PROJECT NAME
                                </MFRHeaderTitle>
                                <MFRHeaderValue
                                    weight={400}
                                    height={16}
                                    size={13}
                                    color={blueDark02}
                                >
                                    {project?.name}
                                </MFRHeaderValue>
                            </div>
                        </MFRTH>
                    </MFRHeaderItem>
                    <MFRHeaderItem>
                        <MFRTH className="th">
                            <div>
                                <MFRHeaderTitle
                                    weight={400}
                                    height={12}
                                    size={10}
                                    color={grayDark03}
                                >
                                    Project / building type
                                </MFRHeaderTitle>
                                <MFRHeaderValue
                                    weight={400}
                                    height={16}
                                    size={13}
                                    color={blueDark02}
                                >
                                    {getBuildingType()}
                                </MFRHeaderValue>
                            </div>
                        </MFRTH>
                    </MFRHeaderItem>
                    <MFRHeaderItem>
                        <MFRTH className="th">
                            <div>
                                <MFRHeaderTitle
                                    weight={400}
                                    height={12}
                                    size={10}
                                    color={grayDark03}
                                >
                                    CONTRACT TYPE
                                </MFRHeaderTitle>
                                <MFRHeaderValue
                                    weight={400}
                                    height={16}
                                    size={13}
                                    color={blueDark02}
                                >
                                    {project?.contractTypeName || 'None'}
                                </MFRHeaderValue>
                            </div>
                        </MFRTH>
                    </MFRHeaderItem>
                    <MFRHeaderItem>
                        <MFRTH>
                            <div>
                                <MFRHeaderTitle
                                    weight={400}
                                    height={12}
                                    size={10}
                                    color={grayDark03}
                                >
                                    LOCATION
                                </MFRHeaderTitle>
                                <MFRHeaderValue
                                    weight={400}
                                    height={16}
                                    size={13}
                                    color={blueDark02}
                                >
                                    {getAddress()}
                                </MFRHeaderValue>
                            </div>
                        </MFRTH>
                    </MFRHeaderItem>
                </MFRHeaderRow>
            </tbody>
        </MFRHeaderSpace>
    );
};

export default MFRPageHeader;
