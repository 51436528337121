import React from 'react';
import { Container, FooterSpace, FooterSpaceDivider } from './Footer.styles';

const Footer: React.FC = () => {
    return (
        <Container>
            <FooterSpace direction="horizontal" split={<FooterSpaceDivider type="vertical" />}>
                <a href="http://www.sustainableminds.com" target="_blank">
                    <span className="blue-dark"> ©{new Date().getFullYear()}</span> Sustainable
                    Minds® <span className="blue-dark">All rights reserved </span>
                </a>
                <a href="https://transparencycatalog.com/privacy-policy" target="_blank">
                    Privacy policy
                </a>
                <a href="https://transparencycatalog.zendesk.com/hc/en-us" target="_blank">
                    Help
                </a>
            </FooterSpace>
        </Container>
    );
};

export default Footer;
