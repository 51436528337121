import { createSlice } from '@reduxjs/toolkit';
import {
    addToFavoriteAction,
    getMyFavoritesAction,
    getOfficeFavoritesAction,
    removeFromFavoriteAction,
} from '../../actions/favoriteProductsActions';

export interface FavoriteProductsType {
    pending: boolean;
}

const initialState: FavoriteProductsType = {
    pending: false,
};

export const favoriteProductsSlice = createSlice({
    name: 'favoriteProducts',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addToFavoriteAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(addToFavoriteAction.rejected, (state) => {
                state.pending = false;
            })
            .addCase(addToFavoriteAction.fulfilled, (state) => {
                state.pending = false;
            })
            .addCase(removeFromFavoriteAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(removeFromFavoriteAction.rejected, (state) => {
                state.pending = false;
            })
            .addCase(removeFromFavoriteAction.fulfilled, (state) => {
                state.pending = false;
            })
            .addCase(getMyFavoritesAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(getMyFavoritesAction.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getMyFavoritesAction.fulfilled, (state) => {
                state.pending = false;
            })
            .addCase(getOfficeFavoritesAction.pending, (state) => {
                state.pending = true;
            })
            .addCase(getOfficeFavoritesAction.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getOfficeFavoritesAction.fulfilled, (state) => {
                state.pending = false;
            });
    },
});

export default favoriteProductsSlice.reducer;
