import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
    CurrentNumberOfPage,
    CurrentPageInput,
    PageArrowContainer,
    Container,
} from './CustomPagination.styles';

interface Props {
    page: number;
    total: number;
    handleCurrentPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changePageNumber: (pageNumber: number) => void;
    pageSize: number;
    paginationObjectsName?: string;
    maxPages?: number;
}

const CustomPagination: React.FC<Props> = ({
    page,
    total,
    handleCurrentPage,
    changePageNumber,
    pageSize,
    paginationObjectsName,
    maxPages,
}) => {
    const nexPage = () => {
        if (page < total / pageSize) {
            changePageNumber(page + 1);
        }
    };

    const previousPage = () => {
        if (page > 1) {
            changePageNumber(page - 1);
        }
    };

    const getObjectsName = (): string => {
        return paginationObjectsName ? paginationObjectsName : 'pages';
    }

    return (
        <Container aria-orientation="horizontal">
            <PageArrowContainer className={`${page > 1 ? '' : 'disabled'}`} onClick={previousPage}>
                <LeftOutlined />
            </PageArrowContainer>
            <CurrentPageInput
                min={0}
                max={maxPages ?? 99}
                value={page}
                onChange={handleCurrentPage}
                type="number"
            />
            <CurrentNumberOfPage>{`of ${Math.ceil(total / pageSize)} ${getObjectsName()}`}</CurrentNumberOfPage>
            <PageArrowContainer
                className={`${page < total / pageSize ? '' : 'disabled'}`}
                onClick={nexPage}
            >
                <RightOutlined />
            </PageArrowContainer>
        </Container>
    );
};

export default CustomPagination;
