import { createSlice } from '@reduxjs/toolkit';
import {
    getBannersAction,
    updateBannerAction,
    changeOrderAction,
    uploadFileAction,
} from '../../actions/bannerActions';

export interface ProjectReducerType {
    pending: boolean;
}

const initialState: ProjectReducerType = {
    pending: false,
};

export const bannerSlice = createSlice({
    name: 'banners',
    initialState: initialState,
    reducers: {
        clearBannerSlice(state) {
            state = initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBannersAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getBannersAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getBannersAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(updateBannerAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(updateBannerAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(updateBannerAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(changeOrderAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(changeOrderAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(changeOrderAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(uploadFileAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(uploadFileAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(uploadFileAction.rejected, (state) => {
            state.pending = false;
        });
    },
});

export const { clearBannerSlice } = bannerSlice.actions;

export default bannerSlice.reducer;
