import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import ReactHtmlParser from 'react-html-parser';
import ModalTitle from '../common/modalTitle/ModalTitle';
import {
    HelpModalContainer,
    InfoBLock,
    InputBlock,
    ModalContainer,
    InputContainer,
} from './HelpModal.styles';
import { CloseIcon, TopSpin, Text } from '../../styles/common.styles';

import CustomInput from '../common/inputs/CustomInput';
import HelpModalFooter from './HelpModalFooter/HelpModalFooter';
import { blue01 } from '../../styles/colors';
import { SelectData, SubmitHelp } from '../../models/common/types';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getHelpItemsAction, submitHelpAction } from '../../redux/actions/projectActions';
import { ActionResponse } from '../../models/actionResponse/types';
import { BrandCompany } from '../../models/product/types';
import { getBrandByIdAction } from '../../redux/actions/transparencyCatalogActions';
import { EC3LoginSpin } from '../projects/projectActions/ec3Login/EC3Login.styles';
import { alertService } from '../../services';
import { MFRProject } from '../../models/project/types';
import {
    GetProjectInformationResponse,
    SubmitHelpRequest,
} from '../../redux/actions/projectActions/types';
import { getAddress } from '../../helpers/project';

const { TextArea } = Input;
interface Props {
    project?: MFRProject | GetProjectInformationResponse | null;
    isOpen: boolean;
    companyId: number;
    handleCancel: () => void;
}

const HelpModal: React.FC<Props> = ({ project, isOpen, companyId, handleCancel }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { productReducer, authReducer } = selector;
    const [form] = Form.useForm();

    const [brandInformation, setBrandInformation] = useState<BrandCompany | undefined>(undefined);
    const [options, setOptions] = useState(Array<{ label: string; value: string }>());
    const [verifyCaptcha, setVerifyCaptcha] = useState(false);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await getCompanyData();
        await getHelpItems();
        setPending(false);
    };

    const getCompanyData = async () => {
        if (companyId) {
            const { payload } = (await dispatch(getBrandByIdAction([companyId]))) as ActionResponse<
                Array<BrandCompany>
            >;
            if (payload.data && payload.data[0]) {
                setBrandInformation(payload.data[0]);
                setInitialValues();
            }
        }
    };

    const getHelpItems = async () => {
        const { payload } = (await dispatch(getHelpItemsAction())) as ActionResponse<
            Array<SelectData>
        >;
        if (payload.data) {
            setOptions(payload.data.map((item) => ({ label: item.name, value: item.id })));
        }
    };

    const setInitialValues = () => {
        const projectAddress = getAddress(project as any);
        const initialValues = {
            projectName: project?.name,
            location: projectAddress === '-' ? '' : projectAddress,
            email: authReducer.user?.email,
            phone: authReducer.user?.phoneNumber,
        };
        form.setFieldsValue(initialValues);
    };

    const onSubmit = async (data: SubmitHelp) => {
        if (project && brandInformation) {
            const manufacturerEmail =
                process.env.REACT_APP_ENVIRONMENT === 'production'
                    ? brandInformation.contactEmail
                    : (authReducer.user?.email).replace('@', '+mfr@');
            const helpRequest: SubmitHelpRequest = {
                projectId: project.id,
                manufacturerEmail: manufacturerEmail || '',
                manufacturerName: brandInformation.company || '',
                productsOfInterest: data.products,
                location: data.location,
                note: data.note,
                helpItems: data.helpItems,
            };
            const { payload } = await dispatch(submitHelpAction(helpRequest));
            if (payload) {
                handleCancel();
                alertService.success('Request was sent', { fixed: true });
            }
        }
    };

    const reCaptchaChange = (data: any) => {
        setVerifyCaptcha(!!data);
    };

    return (
        <HelpModalContainer
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <TopSpin spinning={pending}>
                {!pending && (
                    <>
                        <Form.Item>
                            <ModalTitle
                                title="Get sample, spec, quote & submittal help"
                                icon="ic-modal-title-get-help.svg"
                            />
                        </Form.Item>
                        <Form
                            onFinish={onSubmit}
                            initialValues={{ projectLibraryVisibilityId: 1 }}
                            form={form}
                        >
                            <ModalContainer>
                                <EC3LoginSpin spinning={productReducer.pending}>
                                    <InfoBLock>
                                        {ReactHtmlParser(brandInformation?.contactInfo || '')}
                                    </InfoBLock>
                                </EC3LoginSpin>
                                <InputBlock>
                                    <Text
                                        weight={500}
                                        size={13}
                                        height={16}
                                        color={blue01}
                                        font="DINNextLTPro"
                                    >
                                        HAVE US CONTACT YOU
                                    </Text>
                                    <InputContainer>
                                        <CustomInput
                                            Component={Input}
                                            label="Your email"
                                            name="email"
                                            required
                                            rules={[{ required: true, message: '' }]}
                                        />
                                        <CustomInput
                                            Component={Input}
                                            label="Your phone"
                                            name="phone"
                                        />
                                        <CustomInput
                                            Component={Checkbox.Group}
                                            label="I need help with"
                                            name="helpItems"
                                            required
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'select at least one option',
                                                },
                                            ]}
                                            props={{
                                                options: options,
                                            }}
                                        />
                                        <CustomInput
                                            Component={Input}
                                            label="Products of interest"
                                            name="products"
                                        />
                                        <CustomInput
                                            Component={Input}
                                            label="Project name"
                                            name="projectName"
                                        />
                                        <CustomInput
                                            Component={Input}
                                            label="Location"
                                            name="location"
                                        />
                                        <CustomInput
                                            Component={TextArea}
                                            label="Add a note"
                                            name="note"
                                            props={{
                                                rows: 3,
                                            }}
                                            rules={[
                                                {
                                                    max: 3000,
                                                    message:
                                                        'Value should be less than 3000 characters',
                                                },
                                            ]}
                                        />
                                    </InputContainer>
                                </InputBlock>
                            </ModalContainer>
                            <HelpModalFooter
                                submit={() => {}}
                                action="Contact"
                                cancel={handleCancel}
                                changeCAPTCHA={reCaptchaChange}
                                primaryProps={{ disabled: !verifyCaptcha }}
                            />
                        </Form>
                    </>
                )}
            </TopSpin>
        </HelpModalContainer>
    );
};

export default HelpModal;
