import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import SMAdminUsersDetails from '../adminPanel/pages/userDetails/SMAdminUserDetails';
import UsersForSMAdmin from '../adminPanel/pages/users/SMAdminUsersPage';
import { NavigationKey } from '../components/appNavigationBar/AppNavigation.types';
import { useAppDispatch } from '../hooks/redux';
import { addPage, removePage } from '../redux/reducers/navigationReducer/NavigationSlice';
import { RouteName } from './Routes.types';

const SMUsersRoute: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(addPage({ key: NavigationKey.USERS, value: 'Users' }));
        return () => {
            dispatch(removePage());
        };
    }, []);
    return (
        <Switch>
            <Route exact path="/">
                <UsersForSMAdmin userType={'user'} />
            </Route>
            <Route exact path={RouteName.USERS}>
                <UsersForSMAdmin userType={'user'} />
            </Route>
            <Route exact path={RouteName.ADMIN_USER_DETAIL} component={SMAdminUsersDetails} />
        </Switch>
    );
};

export default SMUsersRoute;
