import { blueDark01, blueDark02, green, white } from '../../../styles/colors';
import { BannerStyleProps } from './BannerStyleProps';

export const TextBanner: BannerStyleProps = {
    background: 'transparent',
    titleColor: `${blueDark02}`,
    contentColor: `${blueDark01}`,
};

export const GreenBanner: BannerStyleProps = {
    background: `${green}`,
    titleColor: `${white}`,
    contentColor: `${white}`,
};
