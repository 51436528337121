import React from 'react';

import MhcTip from './c2c/mhcTip/MhcTip';
import V3_1Tip from './c2c/v3.1Tip/V3_1Tip';
import DeclareTip from './declareTip/DeclareTip';
import HpdTip from './hpdTip/HpdTip';
import MfrInvTip from './mfrInvTip/MfrInvTip';
import EpProgramTip from '../epProgramTip/EpProgramTip';
import CmfImpactAreasTip from '../cmfImpactAreasTip/CmfImpactAreasTip';
import DtImpactAreasTip from '../dtImpactAreasTip/DtImpactAreasTip';
import Ec3Tip from '../ec3Tip/EC3Tip';

const getTipByMaterialProgram = (materialProgram: string, productsTab?: boolean, dataInput: Array<any>) => {
    const regex = new RegExp(/(HPD)\sv\d(?:\.\d)*|C2C\s(V3\.1|MHC)|(Declare)|(EpProgram)|(Ec3)|(cmfImpactAreas)|(dtImpactAreas)|(Mfr\sInv)/gm);
    const groups = regex.exec(materialProgram);
    const program = groups?.filter((element) => {
        return element !== undefined;
    })[1];
    switch (program) {
        case 'HPD':
            return <HpdTip />;
        case 'C2C V3.1':
            return <V3_1Tip />;
        case 'C2C MHC':
            return <MhcTip />;
        case 'MHC':
            return <MhcTip />;
        case 'Declare':
            return <DeclareTip />;
        case 'EpProgram':
            return <EpProgramTip productsTab={productsTab} />;
        case 'cmfImpactAreas':
            return <CmfImpactAreasTip cmfImpactAreas={dataInput} />;
        case 'dtImpactAreas':
            return <DtImpactAreasTip dtImpactAreas={dataInput} />;
        case 'Mfr Inv':
            return <MfrInvTip />;
        case 'V3.1':
            return <V3_1Tip />;
        case 'Ec3':
            return <Ec3Tip />;
    }

    return <></>;
};

interface Props {
    materialProgram: string;
}

const ProgramTipsController: React.FC<Props> = ({ materialProgram, productsTab, dataInput }) => {
    return <>{getTipByMaterialProgram(materialProgram, productsTab, dataInput)}</>;
};

export default ProgramTipsController;
