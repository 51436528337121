import { Spin } from 'antd';
import styled from 'styled-components';
import { blueDark02, blueLink, grayDark03, grayDark06, grayLight03 } from '../../../styles/colors';

export const UsersTableContainer = styled('div')`
    [type='number'] {
        -moz-appearance: textfield;
    }
    .ant-table-cell {
        padding: 10px;
        &::before {
            content: unset !important;
        }
    }
    thead {
        .ant-table-cell {
            background: unset;
            font-weight: normal;
            font-family: 'Proxima N W15 Bold';
            font-size: 10px;
            line-height: 12px;
            color: ${blueDark02};
            text-transform: uppercase;
            border-bottom: 1px solid ${grayDark06};
            padding-top: 8px;
            padding-bottom: 9px;
        }
    }
    tbody {
        .ant-table-cell {
            border: none;
            cursor: pointer;
            font-size: 13px;
            line-height: 16px;
            border: none;
            border-bottom: 1px solid ${grayLight03};
        }
    }

    .ant-table-tbody > tr:not(:last-child) > td {
        border-bottom: 1px solid ${grayLight03};
    }
`;

export const UsersTableLastNameContainer = styled('div')`
    .ant-image::before {
        content: ' ';
        white-space: pre;
    }
`;

export const UsersTableSpinner = styled(Spin)`
    top: 0 !important;
`;

export const UsersTableEmail = styled('div')`
    font-weight: 400;
    color: ${blueLink};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const UsersTableGraySpan = styled('span')`
    color: ${grayDark03};
`;
