import React from 'react';
import * as Tooltip from '../../Tooltip';
import { TooltipStyles } from '../../Tooltip.styles';
import * as MaterialProgramTip from '../MaterialProgramTip';

const MfrInvTip: React.FC = () => {
    return (
        <MaterialProgramTip.Container>
            <Tooltip.Container>
                <TooltipStyles className="mfr shadow">
                    <div className="tooltip">
                        <h1>Manufacturer Inventory (Mfr inv)</h1>
                        <h2>Scope</h2>
                        <p>
                            <b>Threshold concentration</b> above which substances in the material
                            are itemized by the manufacturer:
                        </p>
                        <ul>
                            <li>100 ppm</li>
                            <li>1000 ppm</li>
                            <li>GHS SDS</li>
                            <li>MSDS</li>
                            <li>Other</li>
                        </ul>
                        <h2>Results</h2>
                        <p>
                            Highest concern GreenScreen Benchmark (BM) or List Translator (LT) Score
                            of all substances
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>BM1</b>
                            </span>
                            <span className="rollover_text">Avoid – chemical of high concern</span>
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>LT-1</b>
                            </span>
                            <span className="rollover_text">Likely Benchmark 1</span>
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>LT-P1</b>
                            </span>
                            <span className="rollover_text">Possible Benchmark 1</span>
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>LT-UNK</b>
                            </span>
                            <span className="rollover_text">Benchmark unknown</span>
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>UNK</b>
                            </span>
                            <span className="rollover_text">
                                Unknown, chemical not identified on lists
                            </span>
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>BM-U</b>
                            </span>
                            <span className="rollover_text">
                                Unspecified, insufficient data to benchmark
                            </span>
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>BM2</b>
                            </span>
                            <span className="rollover_text">
                                Use but search for safer substitutes
                            </span>
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>BM3</b>
                            </span>
                            <span className="rollover_text">
                                Use but still opportunity for improvement
                            </span>
                        </p>
                        <p className="rollover_wrap">
                            <span className="rollover_label">
                                <b>BM4</b>
                            </span>
                            <span className="rollover_text">Prefer – safer chemical</span>
                        </p>
                        <p>&nbsp;</p>
                        <i>
                            <p>
                                For more information read the{' '}
                                <a
                                    href="http://www.greenscreenchemicals.org/practice/leed"
                                    target="_blank"
                                >
                                    How to Use GreenScreen® for LEED v4{' '}
                                </a>
                            </p>
                        </i>
                    </div>
                </TooltipStyles>
            </Tooltip.Container>
        </MaterialProgramTip.Container>
    );
};

export default MfrInvTip;
