import React from 'react';
import {
    Activity,
    ActivityAuthor,
    GetProjectInformationResponse,
} from '../../../../../redux/actions/projectActions/types';
import ProjectActivityItem from '../projectActivityItem/ProjectActivityItem';

interface Props {
    activityAuthor: ActivityAuthor;
    projectInfo: GetProjectInformationResponse | null;
    reload: () => void;
}

const ProjectAuthorNotifications: React.FC<Props> = ({ activityAuthor, reload, projectInfo }) => {
    const activityValue = Array<Array<Activity>>();
    const groupedMap = activityAuthor.activities.reduce(
        (entryMap, e) =>
            entryMap.set(e.projectProductId, [...(entryMap.get(e.projectProductId) || []), e]),
        new Map<number | undefined, Array<Activity>>(),
    );
    for (const [_key, value] of groupedMap.entries()) {
        activityValue.push(value);
    }
    return (
        <>
            {activityValue.map((v, i) => (
                <ProjectActivityItem
                    activities={v.sort(
                        (a, b) =>
                            new Date(b.modifiedOn).getTime() - new Date(a.modifiedOn).getTime(),
                    )}
                    key={i}
                    reload={reload}
                    projectInfo={projectInfo}
                />
            ))}
        </>
    );
};

export default ProjectAuthorNotifications;
