import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CookiesType {
    show: boolean;
}

const initialState: CookiesType = {
    show: true,
};

export const cookiesSlice = createSlice({
    name: 'Cookies',
    initialState: initialState,
    reducers: {
        setShowCookiesModal(state, action: PayloadAction<boolean>) {
            state.show = action.payload;
        },
    },
});

export const { setShowCookiesModal } = cookiesSlice.actions;

export default cookiesSlice.reducer;
