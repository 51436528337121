import React from 'react';
import { blueDark01 } from '../../../styles/colors';
import { PrimaryButton, Text } from '../../../styles/common.styles';
import {
    InformationCardButtonContainer,
    InformationCardContainer,
    InformationCardTitle,
} from './informationCard..styles';

interface Props {
    title: string;
    content: string | JSX.Element;
    buttonText: string;
    showButton?: boolean;
    onClick: () => void;
}

const InformationCard: React.FC<Props> = React.memo(
    ({ title, content, buttonText, showButton, onClick }) => {
        if (title.toLowerCase().includes('trial')) {
            title = title.replace('FreeTrial', '') + '(Trial)';
        }
        return (
            <InformationCardContainer>
                <InformationCardTitle level={3}>{title}</InformationCardTitle>
                <Text weight={400} height={18} size={14} color={blueDark01}>
                    {content}
                </Text>
                {showButton && (
                    <InformationCardButtonContainer>
                        <PrimaryButton onClick={onClick}>{buttonText}</PrimaryButton>
                    </InformationCardButtonContainer>
                )}
            </InformationCardContainer>
        );
    },
    (prev, next) => {
        return prev.buttonText === next.buttonText;
    },
);

export default InformationCard;
