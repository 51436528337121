import React from "react";
import CustomInputUploadFileForm from "../../../common/inputs/CustomInputUploadFileForm";
import TextArea from "antd/lib/input/TextArea";

interface Props {
    name: string;
    label: string;
    placeholder: string;
    suffix: string;
}

const DescriptionInput: React.FC<Props> = ({
    label,
    name,
    placeholder,
    suffix,
}) => {
    return (
        <CustomInputUploadFileForm
            Component={TextArea}
            label={label}
            name={name}
            suffix={suffix}
            block
            props={{
                placeholder: placeholder,
                rows: 3,
            }}
            rules={[
                {
                    max: 3000,
                    message: 'Value should be less than 3000 characters',
                },
            ]}
        />
    );
}

export default DescriptionInput;