import { clearAuthSlice } from '../redux/reducers/authReducer/AuthSlice';
import { clearBannerSlice } from '../redux/reducers/bannerReducer/bannerSlice';
import { clearEC3Slice } from '../redux/reducers/ec3Reducer/EC3Slice';
import { clearProductSlice } from '../redux/reducers/productReducer/ProductSlice';
import { useAppDispatch } from './redux';

export const useManageStore = () => {
    const dispatch = useAppDispatch();
    const clearStore = () => {
        dispatch(clearAuthSlice());
        dispatch(clearBannerSlice());
        dispatch(clearEC3Slice());
        dispatch(clearProductSlice());
    };

    return { clearStore };
};
