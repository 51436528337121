import styled from 'styled-components';
import { blueDark02, orange, orange01, orange02, teal01, white } from '../../styles/colors';
import { Space, Typography } from 'antd';
import { PrimaryButton } from '../../styles/common.styles';

export const NavigationBarContainer = styled('div')`
    background-color: ${blueDark02};
    padding: 5px 100px;
    display: flex;
    align-items: center;
    min-width: 1224px;
    &.ant-layout-header {
        height: unset;
    }
`;

export const NavigationBarSpace = styled(Space)`
    width: 100%;
    justify-content: space-between;
`;

export const NavigationPathContainer = styled(Space)`
    cursor: pointer;
    gap: 10px !important;
    .ant-space-item:nth-child(n + 2):last-child {
        .ant-typography {
            color: #ffff !important;
            cursor: auto !important;
        }
    }
`;

export const NavigationBarPath = styled('div')`
    display: flex;
    align-items: center;
`;

export const CreateProjectButton = styled(PrimaryButton)`
    cursor: pointer;
    background-color: ${orange01};
    color: #ffff;
    border-radius: 3px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    height: 28px;
    font-family: 'DINNextLTPro';
    padding: 5px 10px;
    min-width: 112px;
    &:hover {
        background-color: ${orange02};
        color: #ffffff;
    }
    &:focus {
        background-color: ${orange};
        color: #ffff;
    }
`;

export const NavigationItem = styled(Typography.Text)`
    color: ${teal01};
    transition: 1s;
    font-size: 13px;
    line-height: 28px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    max-width: 450px;
    &:hover {
        color: ${white};
    }
`;
