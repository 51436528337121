import { Input, Modal } from 'antd';
import styled from 'styled-components';
import { grayDark05, blue01, blueDark01 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common.styles';

export const SaveTemplateModal = styled(Modal)`
    width: 680px !important;
    height: 409px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    .ant-input {
        padding: 7px 10px 7px 10px;
        border-radius: 3px;
        font-size: 13px;
        line-height: 16px;
        width: 400px;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form-item-label > label {
        height: 16px;
        vertical-align: unset;
        margin-bottom: 4px;
        line-height: 16px !important;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        color: ${blueDark01} !important;

        &::before {
            margin: 0 !important;
        }

        &::after {
            position: unset;
            top: 0;
            margin: 0;
        }
    }

    .ant-input:hover {
        border-color: ${grayDark05};
        border-radius: 3px;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: ${blue01};
        border-radius: 3px;
        box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
    }

    .ant-divider-horizontal {
        margin-top: 29px;
    }
`;

export const SaveTemplateText = styled(Text)`
    margin-bottom: 24px;
`;

export const SaveTemplateInput = styled(Input)`
    width: 50%;
`;
