import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import {
    ForgotPasswordContainer,
    ForgotPasswordTitle,
    ForgotPasswordWrapper,
} from './ForgotPassword.styles';
import { useAppDispatch } from '../../hooks/redux';
import { forgotPasswordAction } from '../../redux/actions/authActions';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, SpaceCenter, SpaceStart, TypographyText } from '../../styles/common.styles';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';

const ForgotPassword: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [state, setState] = useState({
        email: '',
        isRequestSuccess: false,
    });

    useEffect(() => {
        dispatch(addPages([{ key: NavigationKey.HOME, value: 'SM Transparency Catalog' }]));
    }, []);

    const onSubmit = async (data: { email: string }) => {
        const { payload } = (await dispatch(forgotPasswordAction(data.email))) as any;
        if (payload.message) {
            setState({ email: data.email, isRequestSuccess: true });
        }
    };

    const goToLogin = () => {
        history.push('/login');
    };
    return (
        <ForgotPasswordWrapper>
            {!state.isRequestSuccess ? (
                <ForgotPasswordContainer>
                    <ForgotPasswordTitle level={2}>Forgot password?</ForgotPasswordTitle>
                    <Form.Item>
                        <TypographyText>
                            Enter your email and we will send you a recovery link.
                        </TypographyText>
                    </Form.Item>
                    <Form autoComplete="off" onFinish={onSubmit}>
                        <Form.Item
                            name="email"
                            validateTrigger="onBlur"
                            rules={[
                                { required: true, message: 'Enter your email address to request password reset.' },
                                {
                                    pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                                    message: 'Invalid email',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <SpaceStart>
                            <PrimaryButton htmlType="submit">Request password reset</PrimaryButton>
                        </SpaceStart>
                    </Form>
                </ForgotPasswordContainer>
            ) : (
                <ForgotPasswordContainer className="back">
                    <div>
                        <div>
                            <TypographyText>
                                An email with password reset instructions has been sent to
                            </TypographyText>
                        </div>
                        <Form.Item>
                            <SpaceCenter>
                                <strong>{state.email}</strong>
                            </SpaceCenter>
                        </Form.Item>
                        <SpaceCenter>
                            <PrimaryButton onClick={goToLogin}>Back to login screen</PrimaryButton>
                        </SpaceCenter>
                    </div>
                </ForgotPasswordContainer>
            )}
        </ForgotPasswordWrapper>
    );
};

export default ForgotPassword;
