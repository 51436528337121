import React, { useEffect, useState } from 'react';
import { Image, Space, Dropdown } from 'antd';
import {
    DocumentTypesTitle,
    DocumentTypeCount,
    TableDataContainer,
    DoneButton,
} from './DocumentTypesGrid.styles';
import { DtGroup, DtCmfContainer, DtCmfImage, DtBlock, DtRow, DtName, DtLink, FilterInput, FilterLabel } from './DocumentTypesGrid.styles';
import { Text } from '../../../../styles/common.styles';
import { grayDark03 } from '../../../../styles/colors';0
import CustomPagination from '../../../common/pagination/CustomPagination';

import {
    GetProjectInformationResponse,
} from '../../../../redux/actions/projectActions/types';
interface Props {
    totalCount: number;
    totalWithFilterCount: number;
    documentTypes: Array<any>;
    getDocumentTypes: (page: number, searchString: string) => void;
    reload: (page: number, searchString: string) => Promise<void>;
}

const DocumentTypesGrid: React.FC<Props> = ({
    totalCount,
    totalWithFilterCount,
    documentTypes,
    getDocumentTypes,
    reload,
}) => {
    const [state, setState] = useState({ searchString: '', curPage: 1 });

    useEffect(() => {
        if (totalCount < 10) {
            setState({ ...state, curPage: 1 });
        }
    }, [totalCount]);

    const changePage = (page: number) => {
        setState({ ...state, curPage: page });
        getDocumentTypes(page, state.searchString);
    };

    const handleCurrentPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentCount = event.currentTarget.value;
        if (/^-?\d*\.?\d*$/.test(currentCount) || currentCount === '') {
            setState({ ...state, curPage: +event.currentTarget.value });
        }
        if (+currentCount > Math.ceil(totalWithFilterCount / 10)) {
            setTimeout(() => {
                setState({
                    ...state,
                    curPage: Math.ceil(totalWithFilterCount / 10),
                });
            }, 1000);
        }
        if (
            +event.currentTarget.value > 0 &&
            +event.currentTarget.value <= Math.ceil(totalWithFilterCount / 10)
        ) {
            getDocumentTypes(+event.currentTarget.value, state.searchString);
        }
        if (+currentCount === 0) {
            getDocumentTypes(1, state.searchString);
            setTimeout(() => {
                setState({
                    ...state,
                    curPage: 1,
                });
            }, 1000);
        }
    };

    const handleFilterPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        getDocumentTypes(state.curPage, event.target.value);
        setState({ ...state, searchString: event.target.value });
    };

    const getInspectAreaImage = (impactAreaName : string) => {
       switch(impactAreaName) {
          case 'HUMAN HEALTH':
              return 'src/assets/images/health_red_notext.png';
          case 'CLIMATE HEALTH':
              return 'src/assets/images/climate_blue_notext.png';
          case 'CIRCULARITY':
              return 'src/assets/images/circularity_blue_notext.png';
          case 'SOCIAL HEALTH & EQUITY':
              return 'src/assets/images/social_purple_notext.png';
          case 'ECOSYSTEM HEALTH':
              return 'src/assets/images/ecosystem_green_notext.png';
       }
    };

    return (
        <>
            <TableDataContainer>
                <Space>
                    <DocumentTypesTitle>Document types</DocumentTypesTitle>
                    <DocumentTypeCount>({totalCount})</DocumentTypeCount>
                </Space>
                <Space>
                    <FilterLabel weight={400} size={12} height={12} color={grayDark03}>
                        Filter
                    </FilterLabel>
                    <FilterInput height={28} onChange={handleFilterPage} placeholder='Document type' />
                </Space>
                <Space>
                    <CustomPagination
                        page={state.curPage}
                        total={totalWithFilterCount}
                        pageSize={10}
                        changePageNumber={changePage}
                        handleCurrentPage={handleCurrentPage}
                    />
                </Space>
            </TableDataContainer>

         {documentTypes.length && documentTypes.length > 0 ? (
          <DtGroup>
              {documentTypes?.map((dt, i) => (
                <DtBlock>
                   <DtRow height={dt.name.length < 69 ? 32 : 52}>
                     <DtName>
                         <DtLink href={dt.url} target="_blank">
                             {dt.name}
                         </DtLink>
                     </DtName>
                     <DtCmfContainer>
                       {dt.impactAreas && dt.impactAreas.length > 0 && dt.impactAreas[0].impactAreaId > 0 && (
                        <>
                        {dt?.impactAreas?.map((impactArea, i) => (
                          <DtCmfImage>
                             <Image
                                preview={false}
                                src={getInspectAreaImage(impactArea.name?.toUpperCase())}
                                style={{ cursor: 'pointer' }}
                                title={impactArea.name}
                                width={14} height={14}
                             />
                          </DtCmfImage>
                        ))}
                        </>
                      )}
                     </DtCmfContainer>
                   </DtRow> 
                </DtBlock>
              ))}
          </DtGroup>
          ) : (<></>)
        }

        </>
    );
};

export default DocumentTypesGrid;
