import styled from 'styled-components';
import { blue01, white } from '../../../styles/colors';

export const EmptyDataContainer = styled('div')`
    min-height: 100px;
    background-color: ${white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    line-height: 27px;
    color: ${blue01};
    font-family: 'DIN Next W01 Medium';
`;
