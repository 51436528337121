import styled from 'styled-components';

export const DivFlexWithWrap = styled('div')`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 390px;
    margin: 14px 0 14px 0;
`;

export const DivImpactArea = styled('div')`
    display: flex;
    width: 183px;
`;

export const DivImpactAreaImage = styled('div')`
    display: inline;
`;

export const DivImpactAreaNameAndBuckets = styled('div')`
    display: block;
        font-family: 'Proxima N W15 Bold' !important;
        font-weight: normal !important;
        font-size: 10px !important;
        line-height: 12px !important;

    p {
    }
`;

export const DivImpactAreaBuckets = styled('div')`
    display: block;
        font-family: 'Proxima Nova' !important;
        font-weight: 400 !important;
        font-size: 10px !important;
        line-height: 12px !important;

    p {
    }
`;

