import { Divider, Modal, Space, Tabs, Typography } from 'antd';
import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    blueDark02,
    blueLink,
    grayDark06,
    grayLight02,
    white,
} from '../../../../styles/colors';
import { PrimaryButton } from '../../../../styles/common.styles';

export const ShareProjectTabs = styled(Tabs)`
    margin-top: 20px;
`;

export const CollaborationSpace = styled(Space)`
    margin-top: 10px;
    width: 100%;
    align-items: center;
    .ant-space-item:first-child {
        width: 60%;
        .ant-form-item-control {
            max-width: 100%;
            margin-right: 20px;
        }
    }
    .ant-space-item:nth-last-of-type(2) {
        width: 40%;
    }
    .ant-form-item-control {
        max-width: 100%;
    }
`;

export const ShareProjectModal = styled(Modal)`
    .ant-spin-nested-loading > div > .ant-spin {
        top: 0;
    }
    .ant-modal-content {
        width: 600px;
        border-radius: 6px;
    }
    .invite-form {
        #select-role {
            position: relative;
            display: flex;
            #input-container {
                width: 100%;
            }
            img {
                position: absolute;
                top: -10px;
                left: -100px;
            }
        }
        .text-area-container {
            .ant-space-item {
                width: 100%;
                .ant-form-item-control {
                    min-width: 100%;
                    margin-right: 20px;
                }
            }
            .ant-form-item-label {
                margin-left: 5px;
            }
        }
    }
`;

export const AccessTabTitle = styled(Typography.Text)`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 14px;
    line-height: 18px;
    color: ${blue01};
    margin-left: 12px;
`;

export const AccessTabLinkContainer = styled('div')`
    margin: 10px 0 20px 0;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    padding: 5px 0px;
`;

export const CopyToClipboardContainer = styled('div')`
    cursor: pointer;
`;

export const CollaboratorsCount = styled('div')`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${blue01};
    margin: 0 5px 0 -5px;
`;

export const ShareTableDataContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
`;

export const AccessTabMarkContainer = styled('div')`
    img {
        width: 16px;
    }
`;

export const ShareTabTableItem = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    .ant-btn-default {
        display: flex;
        justify-content: space-between;
        width: 135px;
    }
    .collaborator-email {
        margin-left: 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: ${blueLink};
        &.gray {
            color: gray;
        }
    }
    .ant-form-item-control-input {
        width: 120px;
    }
    .ant-form-item {
        margin: 0;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding: 0;
    }
    #bold {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
    }
`;

export const InviteTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    .access-tip {
        display: none;
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -70px;
            right: 40px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 400px;
        }
        .access-tip {
            display: block;
            position: absolute;
            top: 30px;
            right: -90px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 202px;
        }
    }
`;

export const RoleTip = styled('div')``;

export const ShareModalDivider = styled(Divider)`
    border-top: 1px solid ${grayLight02};
    margin-top: 5px;
    margin: 20px 0;
`;

export const AccessActionsButton = styled(PrimaryButton)`
    align-items: center;
    background-color: ${white};
    color: ${blueDark02};
    border: 1px solid ${grayDark06};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${white};
        color: ${blueDark01};
        border: 1px solid ${blueDark01};
    }
    &:disabled {
        background-color: ${white};
        color: ${blueDark02};
        border: 1px solid ${blueDark02};
        &:hover {
            color: ${blueDark02};
            background-color: ${white};
            border: 1px solid ${blueDark02};
        }
    }
`;

export const InviteText = styled('div')`
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 14px;
    font-weight: normal;
    border-bottom: 1px solid ${grayDark06};
`;

export const CollaboratorEmail = styled('div')`
    display: flex;
    gap: 3px;
    align-items: center;
    cursor: pointer;
`;
