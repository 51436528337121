import { Spin } from 'antd';
import styled from 'styled-components';
import { grayLight04, teal05 } from '../../styles/colors';

export const SettingsPageContainer = styled('div')`
    max-width: 2000px;
    padding: 40px;

    .setting-group-container {
        display: flex;
    }

    .manage-group-action {
        color: #90908e;
    }

    .group-item {
        display: grid;
        grid-template-columns: auto 50px;
        padding: 12px 10px;
        * {
            white-space: nowrap;
        }
        &:nth-child(odd) {
            background-color: ${grayLight04};
        }
        &:hover {
            background-color: ${teal05};
        }
    }
`;
export const SettingPageSpin = styled(Spin)`
    top: 0 !important;
`;
