import React from 'react';
import { Checkbox } from 'antd';
import {
    GreenRect,
    TipContainer,
    ProductHeaderTable,
    ProductHeaderTBody,
    ProductHeaderTHead,
    GridHead,
    ProductTable,
    ProductTableTHead,
    ProductTableBody,
    MasterFormatRow,
    ProductBodyRow,
    ProgramRow,
    MaterialTD,
    IngredientsTD,
    EC3TipContainer,
    ColorCircle,
    ProductHeaderLink,
    CheckboxContainer,
} from './ProductItemHeader.styles';
import { InvisibleButton } from '../../../../styles/common.styles';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Product } from '../../../../models/product/types';
import { MergedFavoriteProduct } from '../../FavoriteProducts.types';
import { useModalState } from '../../../../hooks/modalState';
import HelpModal from '../../../helpModal/HelpModal';
import { MFRProject } from '../../../../models/project/types';
import {
    formatRegulationText,
    getColorCircleForResult,
    getListingHeaderBackgroundColor,
    getMasterFormatBackgroundColor,
    getNameOfListingType,
    getPercentileColor,
} from './ProductItemHeaderHelper';
import {
    DeclareResults,
    EC3EmbodiedCarbon,
    MasterFormatBackground,
} from '../../../../models/product/enums';
import ProgramTip from './tips/epProgramTip/EpProgramTip';
import { removeCentury } from '../../../../helpers/dateHelper';
import ProgramTipsController from './tips/materialPrograms/ProgramTipsController';
import EC3Tip from './tips/ec3Tip/EC3Tip';
import ProductOutsideTCItemHeader from './ProductOutsideTCItemHeader';

interface Props {
    product: Product | MergedFavoriteProduct;
    productsCount: number;
    showCheckbox: boolean;
    showHelp: boolean;
    showHeader: boolean;
    productSelected?: boolean;
    projectInfo?: MFRProject;
    handleProductSelection?: (data: CheckboxChangeEvent) => void;
}

const ProductItemHeader: React.FC<Props> = ({
    product,
    productsCount,
    showCheckbox,
    showHelp,
    showHeader,
    productSelected,
    projectInfo,
    handleProductSelection,
}) => {
    const { onClose, isOpen } = useModalState();

    if (product.product.storage === 'OutsideTC') {
        return (
            <ProductOutsideTCItemHeader
                product={product}
                productSelected={productSelected}
                showCheckbox={showCheckbox}
                handleProductSelection={handleProductSelection}
            />
        );
    }

    const openGetSpecModal = async () => {
        if (product.specUrl) {
            const a = document.createElement('a');
            a.target = '_blank';
            a.href = product.specUrl;
            a.click();
        }
    };

    const getGridHead = () => {
        if (!showHeader) {
            return <></>;
        }

        return (
            <GridHead>
                <ProductHeaderTable className="tabs-table tabs-header-table new">
                    <ProductHeaderTHead>
                        <tr>
                            <th></th>
                            <td colSpan={4} className="env_header">
                                <div className="div">ENVIRONMENTAL PERFORMANCE</div>
                            </td>
                            <td colSpan={4} className="mat_header">
                                <div className="div">MATERIAL INGREDIENTS</div>
                            </td>
                        </tr>
                    </ProductHeaderTHead>
                    <ProductHeaderTBody>
                        <tr>
                            <th className="brand">
                                <div className="div">Brand | Product</div>
                            </th>
                            <td className="type">
                                <div className="div">
                                    Program
                                    <TipContainer>
                                        <ProgramTip />
                                    </TipContainer>
                                </div>
                            </td>
                            <td colSpan={2} className="scope">
                                <div className="div">Scope, Region, CO2E, Ind Avg</div>
                            </td>
                            <td className="env expires">
                                <div className="div">Expires</div>
                            </td>
                            <td className="program">
                                <div className="div">Program</div>
                            </td>
                            <td colSpan={2} className="results">
                                <div className="div">Scope / Results</div>
                            </td>
                            <td className="mat expires">
                                <div className="div">Expires</div>
                            </td>
                        </tr>
                    </ProductHeaderTBody>
                </ProductHeaderTable>
            </GridHead>
        );
    }

    return (
        <>
            {getGridHead()}
            <ProductTable>
                {showCheckbox && (
                    <ProductTableTHead
                        backgroundColor={getListingHeaderBackgroundColor(
                            product.companyLevel as MasterFormatBackground,
                        )}
                    >
                        <tr>
                            <td colSpan={2} className="company">
                                <a target="_blank" href={product.listingUrl}>
                                    <div>{product.company}</div>
                                </a>
                            </td>
                            <td colSpan={3} className="featured">
                                <div>
                                    {getNameOfListingType(
                                        product.companyLevel as MasterFormatBackground,
                                    )}
                                </div>
                            </td>
                        </tr>
                    </ProductTableTHead>
                )}
                <ProductTableBody>
                    <MasterFormatRow
                        backgroundColor={getMasterFormatBackgroundColor(
                            product.companyLevel as MasterFormatBackground,
                        )}
                    >
                        <td colSpan={2} className="format">
                            <div>
                                {product.mfSection.number} {product.mfSection.label} (
                                {productsCount})
                            </div>
                        </td>
                        <td colSpan={3} className="help">
                            {showHelp && product.specUrl && (
                                <div>
                                    <InvisibleButton onClick={openGetSpecModal}>
                                        Get the spec
                                    </InvisibleButton>
                                </div>
                            )}
                        </td>
                    </MasterFormatRow>
                    <ProductBodyRow>
                        <th>
                            <CheckboxContainer>
                                {showCheckbox && (
                                    <Checkbox
                                        checked={productSelected}
                                        onChange={handleProductSelection}
                                        data-company={product.company}
                                        data-product={product.productName}
                                    />
                                )}
                                <span>
                                    <ProductHeaderLink
                                        className={product.productUrl ? '' : 'black'}
                                        href={product.productUrl}
                                        target="_blank"
                                    >
                                        {product.productName}
                                    </ProductHeaderLink>
                                </span>
                            </CheckboxContainer>
                        </th>
                        <MaterialTD>
                            <ProgramRow>
                                <div className="type">
                                    <div className="div">
                                        {' '}
                                        {product.epdProgram && (
                                            <a href={product.epdUrl} target="_blank">
                                                {product.epdProgram}
                                            </a>
                                        )}
                                    </div>
                                </div>
                                <div className="scope">
                                    <div className="div">{product.scopeRegion}</div>
                                </div>
                                <div className="expires">
                                    <div>{removeCentury(product.epdExpiresDate)}</div>
                                </div>
                            </ProgramRow>
                            {product.ec3Url && (
                                <ProgramRow className="ec3">
                                    <div className="type">
                                        <div className="div">
                                            {product.ec3Url && (
                                                <EC3TipContainer>
                                                    <a href={product.ec3Url} target="_blank">
                                                        {'CO2e | EC3'}
                                                    </a>
                                                    <TipContainer className="co2">
                                                        <EC3Tip />
                                                    </TipContainer>
                                                </EC3TipContainer>
                                            )}
                                        </div>
                                    </div>
                                    <div className="scope">
                                        <div className="div">
                                            <GreenRect
                                                className="greenRect"
                                                color={getPercentileColor(
                                                    product.ec3EmbodiedCarbon as EC3EmbodiedCarbon,
                                                )}
                                            />
                                            {product.ec3EmbodiedCarbon}
                                        </div>
                                    </div>
                                    <div className="expires"></div>
                                </ProgramRow>
                            )}
                        </MaterialTD>
                        <IngredientsTD>
                            {product.declare && (
                                <ProgramRow>
                                    <div className="program">
                                        {' '}
                                        {product.declare && (
                                            <EC3TipContainer>
                                                <a href={product.declareUrl}>
                                                    {product.declare}
                                                </a>
                                                <TipContainer className="ec3">
                                                    <ProgramTipsController
                                                        materialProgram={product.declare}
                                                    />
                                                </TipContainer>
                                            </EC3TipContainer>
                                        )}
                                    </div>
                                    <div className="results circle">
                                        {
                                            <ColorCircle
                                                color={getColorCircleForResult(
                                                    product.declareResults as DeclareResults,
                                                )}
                                            />
                                        }
                                        {product.declareResults}
                                    </div>
                                    <div className="expires-epd">
                                        <div>{removeCentury(product.declareExpiresDate)}</div>
                                    </div>
                                </ProgramRow>
                            )}
                            {product.lpc && 
                              (
                                <ProgramRow>
                                    <div className="program">
                                        {' '}
                                        {product.lpc && (
                                            <EC3TipContainer>
                                                <a href={product.lpcUrl}>
                                                    {product.lpc}
                                                </a>
                                                <TipContainer className="ec3">
                                                    <ProgramTipsController
                                                        materialProgram={product.lpc}
                                                    />
                                                </TipContainer>
                                            </EC3TipContainer>
                                        )}
                                    </div>
                                    <div className="results circle">
                                        {
                                            <ColorCircle
                                                color={getColorCircleForResult(
                                                    product.lpcResults as DeclareResults,
                                                )}
                                            /> 
                                        }
                                        {product.lpcResults}
                                    </div>
                                    <div className="expires-epd">
                                        <div>{removeCentury(product.lpcExpiresDate)}</div>
                                    </div>
                                </ProgramRow>
                            )}
                            {product.c2cMHC && (
                                <ProgramRow className={product.hpd || product.declare ? 'ec3' : ''}>
                                    <div className="program">
                                        {' '}
                                        {product.c2cMHC && (
                                            <EC3TipContainer>
                                                <a href={product.c2cMHCUrl}>
                                                    {product.c2cMHC}
                                                </a>
                                                <TipContainer className="ec3">
                                                    <ProgramTipsController
                                                        materialProgram={product.c2cMHC}
                                                    />
                                                </TipContainer>
                                            </EC3TipContainer>
                                        )}
                                    </div>
                                    <div className="results">{product.c2cMHCResults}</div>
                                    <div className="expires-epd">
                                        <div>{removeCentury(product.c2cMHCExpiresDate)}</div>
                                    </div>
                                </ProgramRow>
                            )}

                            {((product.hpdScopeResults || product.hpdUrl ) && (
                                    <ProgramRow
                                        className={
                                            product.hpd || product.declare || product.c2cMHC
                                                ? 'ec3'
                                                : ''
                                        }
                                    >
                                        <div className="program">
                                            {' '}
                                            {product.hpd && (
                                                <EC3TipContainer>
                                                    <a href={product.hpdUrl}>
                                                        {product.hpd}
                                                    </a>
                                                    <TipContainer className="ec3">
                                                        <ProgramTipsController
                                                            materialProgram={product.hpd}
                                                        />
                                                    </TipContainer>
                                                </EC3TipContainer>
                                            )}
                                        </div>
                                        <div className="results">
                                            <div>
                                                {product.hpdScopeResults}
                                                {product.regulations && (
                                                    <div>
                                                        {product.regulations.map((r) => (
                                                            <div>{formatRegulationText(r)}</div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="expires-epd">
                                            <div>{removeCentury(product.hpdExpiresDate)}</div>
                                        </div>
                                    </ProgramRow>
                                ))}
                            {((product.c2cCertified ) && (
                                    <ProgramRow
                                        className={
                                            product.hpd || product.declare || product.c2cMHC
                                                ? 'ec3'
                                                : ''
                                        }
                                    >
                                        <div className="program">
                                            {' '}
                                            {product.c2cCertified && (
                                                <EC3TipContainer>
                                                    <a href={product.c2cUrl}>
                                                        {product.c2cCertified}
                                                    </a>
                                                    <TipContainer className="ec3">
                                                        <ProgramTipsController
                                                            materialProgram={product.c2cCertified}
                                                        />
                                                    </TipContainer>
                                                </EC3TipContainer>
                                            )}
                                        </div>
                                        <div className="results">
                                            <div>
                                                {product.c2cResults}
                                                {product.regulations && (
                                                    <div>
                                                        {product.regulations.map((r) => (
                                                            <div>{formatRegulationText(r)}</div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="expires-epd">
                                            <div>{removeCentury(product.c2cExpiresDate)}</div>
                                        </div>
                                    </ProgramRow>
                                ))}
                            {product.mfrInv && (
                                <ProgramRow
                                    className={
                                        product.hpd || product.c2cCertified || product.c2cMHC
                                            ? 'ec3'
                                            : ''
                                    }
                                >
                                    <div className="program">
                                        {' '}
                                        {product.mfrInv && (
                                            <EC3TipContainer>
                                                <a href={product.mfrInvUrl}>
                                                    {product.mfrInv}
                                                </a>
                                                <TipContainer className="ec3">
                                                    <ProgramTipsController
                                                        materialProgram={product.mfrInv}
                                                    />
                                                </TipContainer>
                                            </EC3TipContainer>
                                        )}
                                    </div>
                                    <div className="results">{product.mfrInvResults}</div>
                                    <div className="expires-epd"></div>
                                </ProgramRow>
                            )}
                        </IngredientsTD>
                    </ProductBodyRow>
                </ProductTableBody>
            </ProductTable>
            {isOpen && (
                <HelpModal
                    isOpen={isOpen}
                    handleCancel={onClose}
                    companyId={product.companyID}
                    project={projectInfo}
                />
            )}
        </>
    );
};

export default ProductItemHeader;
