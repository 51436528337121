import React from 'react';
import { TooltipStyles } from '../../Tooltip.styles';
import * as MaterialProgramTip from '../MaterialProgramTip';

const HpdTip: React.FC = () => {
    return (
        <MaterialProgramTip.Container>
            <TooltipStyles className="shadow">
                <div className="tooltip tooltip-hpd">
                    <h1>Health Product Declaration</h1>
                    <h2>Program</h2>
                    <p>
                        The HPD Open Standard provides a consistent, and transparent format to
                        accurately disclose the material contents and associated hazard
                        classifications for a building product.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>HPD Open Standard Version followed to create the HPD:</strong> v1.0,
                        2.0, 2.1, 2.1.1, 2.2, 2.3
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>HPDs are self-declared, verification is optional.</strong>
                    </p>
                    <p>
                        <img src="src/assets/images/verified.png" alt="" />{' '}
                        <strong>Indicates HPD has been 3rd party verified.</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>HPD 3rd party verifiers:</strong> GreenCircle, SCS, Vertima, WAP
                        Sustainability
                    </p>
                    <h2>Scope</h2>
                    <p>
                        <strong>Threshold concentration</strong> above which substances in the
                        material are itemized by the manufacturer:
                    </p>
                    <div className="tooltip-content">
                        <ul className="list-bulleted">
                            <li>100 ppm</li>
                            <li>1000 ppm</li>
                            <li>GHS SDS</li>
                            <li>MSDS</li>
                            <li>Other</li>
                        </ul>
                    </div>
                    <p>
                        <b>Degree of disclosure</b> of data for all substances in the product’s
                        content inventory:
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>C</b>
                        </span>
                        <span className="rollover_text">
                            <b>Characterized</b>: % weight & role provided
                        </span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>S</b>
                        </span>
                        <span className="rollover_text">
                            <b>Screened</b>: Screened using Priority Hazard Lists w/ results
                            disclosed
                        </span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>I</b>
                        </span>
                        <span className="rollover_text">
                            <b>Identified</b>: Disclosed by name and identifier
                        </span>
                    </p>
                    <h2>Results</h2>
                    <p>
                        Highest concern GreenScreen Benchmark (BM) or List Translator (LT) Score of
                        all substances:
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>BM1</b>
                        </span>
                        <span className="rollover_text">Avoid – chemical of high concern</span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>LT-1</b>
                        </span>
                        <span className="rollover_text">Likely Benchmark 1</span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>LT-P1</b>
                        </span>
                        <span className="rollover_text">Possible Benchmark 1</span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>LT-UNK</b>
                        </span>
                        <span className="rollover_text">Benchmark unknown</span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>UNK</b>
                        </span>
                        <span className="rollover_text">
                            Unknown, chemical not identified on lists
                        </span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>BM-U</b>
                        </span>
                        <span className="rollover_text">
                            Unspecified, insufficient data to benchmark
                        </span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>BM2</b>
                        </span>
                        <span className="rollover_text">Use but search for safer substitutes</span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>BM3</b>
                        </span>
                        <span className="rollover_text">
                            Use but still opportunity for improvement
                        </span>
                    </p>
                    <p className="rollover_wrap">
                        <span className="rollover_label">
                            <b>BM4</b>
                        </span>
                        <span className="rollover_text">Prefer – safer chemical</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <a
                            href="https://www.hpd-collaborative.org/hpd-open-standard-all-versions/"
                            target="_blank"
                        >
                            Visit the HPD Collaborative website to learn more.
                        </a>
                    </p>
                </div>
            </TooltipStyles>
        </MaterialProgramTip.Container>
    );
};

export default HpdTip;
