import React, { useState, useEffect } from 'react';
import { useModalState } from '../../../hooks/modalState';
import CopyProject from '../../../components/projects/projectActions/copyProject/CopyProject';
import DeleteModal from '../../../components/common/deleteModal/DeleteModal';
import ShareProject from '../../../components/projects/projectActions/shareProject/ShareProject';
import { GetProjectInformationResponse } from '../../../redux/actions/projectActions/types';
import { CopyProjectData } from '../../../components/projects/projectActions/ProjectActions.types';
import { useAppDispatch } from '../../../hooks/redux';
import {
    copyProjectAction,
    deleteProjectAction,
    switchProjectStatusAction,
} from '../../../redux/actions/projectActions';
import { useHistory, useLocation } from 'react-router-dom';
import { alertService } from '../../../services';
import { ProjectPermissionTypes } from '../../../models/permission/enums';
import { AlertMessages } from '../../../components/alert/AlertMessages';
import { usePermissions } from '../../../hooks/permissions';
import ExportProject from '../../../components/projects/projectActions/exportProject/ExportProject';
import { ExportProjectType, ProjectAction } from '../../../models/project/enums';
import { MFRProject, ProjectReferences } from '../../../models/project/types';
import EC3Login from '../../../components/projects/projectActions/ec3Login/EC3Login';
import EC3SuccessExport from '../../../components/projects/projectActions/ec3Success/EC3SuccessExport';
import { setEc3ProjectBegan } from '../../../redux/reducers/ec3Reducer/EC3Slice';
import {
    ActionButton,
    OpenActionsMenu,
    ProjectActionDropdown,
    ProjectActionSpace,
    ProjectDotsAction,
} from './ProjectInfoActions..styles';
import { getProjectActionItems } from './ProjectInfoActions..helper';
import SaveTemplate from '../../../components/projects/projectActions/saveTemplates/SaveTemplate';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { InternalProduct } from '../../../models/product/types';
import { getProjectProductsAction } from '../../../redux/actions/productActions';
import { ActionResponse } from '../../../models/actionResponse/types';

interface Props {
    project: GetProjectInformationResponse | MFRProject | null;
    projectReferences: ProjectReferences | null;
    activeTab?: string;
    setPersistActiveTab?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ProjectInfoActions: React.FC<Props> = ({
    project,
    projectReferences,
    activeTab,
    setPersistActiveTab,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const { getProjectPermission } = usePermissions();

    const [open, setOpen] = useState({
        share: false,
        copy: false,
        template: false,
        delete: false,
        csv: false,
        ec3: false,
        completed: false,
    });

    const [productIds, setProductIds] = useState<Array<InternalProduct>>([]);

    const { getAccountSubscription } = usePermissions()

    const dropdownMenu = useModalState();
    const ec3Modal = useModalState();

    useEffect(() => {
        getProductIds();
        const code = params.get('code');
        if (code && project) {
            setOpen({ ...open, ec3: true });
            window.history.replaceState(null, '', `/project-info/${project?.id}/0/${activeTab}`);
            history.push(`/project-info/${project?.id}/0/${activeTab}`);
        }
        if (open.completed) {
            switchProjectStatus();
        }
    }, [project, open]);

    const getProductIds = async () => {
        if (!project) {
            return;
        }

        const { payload } = (await dispatch(
            getProjectProductsAction(project.id),
        )) as ActionResponse<Array<InternalProduct>>;

        if (payload?.data) {
            setProductIds(payload.data);
        }
    };

    const handleOpen = (action: string) => {
        if (project?.userRole === ProjectPermissionTypes.COMMENTER) {
            return;
        }
        setOpen({ ...open, [action]: true });
    };

    const closeModal = (data: { key: string }) => {
        setOpen({ ...open, [data.key]: false });
    };

    const reload = (key: ProjectAction, data?: any) => {
        switch (key) {
            case ProjectAction.DELETE:
                deleteProject();
                break;
            case ProjectAction.COPY:
                createCopy(data);
                break;
            case ProjectAction.COMPLETED:
                switchProjectStatus();
                break;
            case ProjectAction.TEMPLATE:
                createTemplate(data);
                break;
            case ProjectAction.EC3:
                setOpen({ ...open, ec3: false });
                if (activeTab !== '5') {
                    ec3Modal.onOpen();
                } else {
                    dispatch(setEc3ProjectBegan());
                }
                break;
            default:
                break;
        }
    };

    const deleteProject = async () => {
        if (project) {
            const { payload } = await dispatch(deleteProjectAction(+project.id) as any);
            if (payload.message) {
                history.push('/project-library');
                alertService.success(AlertMessages.PROJECT_DELETED);
            }
        }
    };

    const switchProjectStatus = async () => {
        if (project) {
            const { payload } = (await dispatch(switchProjectStatusAction(+project.id))) as any;
            if (payload.message) {
                project.isCompleted = !project.isCompleted;
                setOpen({ ...open, completed: false });
            }
        }
    };

    const createTemplate = async (data: CopyProjectData | undefined) => {
        if (project && data) {
            const { payload } = await dispatch(
                copyProjectAction({ ...data, projectId: +project?.id, isTemplate: true }),
            );
            if (payload) {
                setOpen({ ...open, template: false });
                alertService.success(AlertMessages.TEMPLATE_CREATED);
            }
        }
    };

    const createCopy = async (data: CopyProjectData | undefined) => {
        if (data && project) {
            const { payload } = await dispatch(
                copyProjectAction({ ...data, projectId: +project.id }),
            );
            if (payload) {
                setOpen({ ...open, copy: false });
                alertService.success(
                    data.isTemplate ? AlertMessages.TEMPLATE_CREATED : AlertMessages.PROJECT_COPIED,
                );
            }
        }
    };

    const handleMenuSelect = (data: { key: string }) => {
        if (data.key === 'ec3' && !productIds?.length) {
            console.log('There are no products to export');
            alertService.warn('There are no products to export');
            return;
        }
        
        setOpen({ ...open, [data.key]: true });
    };

    return (
        <ProjectActionSpace>
            {project && !project?.isTemplate && (
                <>
                    {getProjectPermission(
                        [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                        project.userRole,
                    ) && <ActionButton onClick={() => handleOpen('share')}>Share</ActionButton>}
                </>
            )}
            {project && getAccountSubscription() !== SubscriptionTypes.FREE && (
                <ProjectActionDropdown
                    menu={{
                        items: getProjectActionItems(project, getProjectPermission),
                        onClick: handleMenuSelect,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                    onOpenChange={dropdownMenu.onToggle}
                >
                    <div>
                        <OpenActionsMenu>
                            <ProjectDotsAction />
                        </OpenActionsMenu>
                    </div>
                </ProjectActionDropdown>
            )}
            {open.copy && project && (
                <CopyProject
                    isOpen={open.copy}
                    closeModal={closeModal}
                    reload={reload}
                    project={project}
                />
            )}
            {open.share && project && !project.isTemplate && (
                    <ShareProject
                    isOpen={open.share}
                    closeModal={closeModal}
                    reload={reload}
                    project={project}
                />
            )}
            {open.delete && project && (
                <DeleteModal
                    isOpen={open.delete}
                    title="project"
                    text={project.name}
                    closeModal={closeModal}
                    reload={reload}
                    icon="building-circle-xmark.svg"
                />
            )}
            {open.csv && project && (
                <ExportProject
                    isOpen={open.csv}
                    closeModal={closeModal}
                    reload={reload}
                    project={project}
                    projectReferences={projectReferences}
                    options={[
                        {
                            id: ExportProjectType.ALL_DIVISIONS_ALL_PRODUCTS.toString(),
                            name: 'All divisions, all products',
                        },
                        {
                            id: ExportProjectType.ALL_DIVISIONS_BASIS_DESIGN.toString(),
                            name: 'All divisions, basis of design only',
                        },
                        {
                            id: ExportProjectType.INSTALLED_PRODUCTS_ALL_DIVISIONS_ALL_PRODUCTS.toString(),
                            name: 'Installed products - all divisions, all products',
                        },
                        {
                            id: ExportProjectType.INSTALLED_PRODUCTS_ALL_DIVISIONS_BASIS_DESIGN.toString(),
                            name: 'Installed products - all divisions, basis of design only',
                        },
                    ]}
                />
            )}
            {open.ec3 && project && productIds?.length && (
                <EC3Login
                    closeModal={closeModal}
                    isOpen={open.ec3}
                    project={project}
                    productIds={productIds}
                    reload={reload}
                    activeTab={activeTab}
                />
            )}
            {open.template && project && (
                <SaveTemplate
                    isOpen={open.template}
                    reload={reload}
                    closeModal={closeModal}
                    project={project}
                />
            )}
            {ec3Modal.isOpen && project && (
                <EC3SuccessExport
                    isOpen={ec3Modal.isOpen}
                    closeModal={ec3Modal.onClose}
                    projectId={project.id}
                    setPersistActiveTab={setPersistActiveTab}
                />
            )}
        </ProjectActionSpace>
    );
};

export default ProjectInfoActions;
