import { Typography } from 'antd';
import React from 'react';
import { blueDark01, blueLink } from '../../styles/colors';
import { Text } from '../../styles/common.styles';

const Version = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <Typography.Title style={{ color: `${blueLink}` }}>Version:</Typography.Title>
            <br />
            <Text weight={600} height={24} color={blueDark01} size={24}>
                666
            </Text>
            <Typography.Title style={{ color: `${blueLink}` }}>Date:</Typography.Title>
            <br />
            <Text weight={600} height={24} color={blueDark01} size={24}>
                06/27/2023
            </Text>
        </div>
    );
};

export default Version;
