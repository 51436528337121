import React from 'react';
import Switcher from '../../../../../components/common/switcher/Switcher';
import { ProductSwitcherContent } from '../../../../../styles/common.styles';
import {
    NotificationItemContainer,
    NotificationItemWrapper,
    NotificationTitleContainer,
} from './NotificationDateSection.styles';
import { GetProjectInformationResponse } from '../../../../../redux/actions/projectActions/types';
import ProjectDateNotifications from '../projectDateNotifications/ProjectDateNotifications';
import { NotificationSection } from '../../../../../models/projectNotification/types';
import NotificationTitle from './notificationTitle/NotificationTitle';

interface Props {
    notification: NotificationSection;
    projectInfo: GetProjectInformationResponse | null;
    handleClicked: (id: string) => void;
    handleReload: () => Promise<void>;
}
const NotificationDateSection: React.FC<Props> = ({
    notification,
    projectInfo,
    handleClicked,
    handleReload,
}) => {
    const { id, key, clicked, activities } = notification;

    return (
        <NotificationItemWrapper className={clicked ? 'clicked' : ''}>
            <NotificationItemContainer>
                <NotificationTitleContainer
                    onClick={() => {
                        handleClicked(id);
                    }}
                >
                    <Switcher
                        id={id}
                        clicked={clicked}
                        handleSwitch={handleClicked}
                        width={24}
                        height={24}
                    />
                    <NotificationTitle notification={notification} />
                </NotificationTitleContainer>
            </NotificationItemContainer>
            <ProductSwitcherContent className={clicked ? 'clicked' : ''}>
                <ProjectDateNotifications
                    projectActivity={activities}
                    reload={handleReload}
                    projectInfo={projectInfo}
                    key={key}
                />
            </ProductSwitcherContent>
        </NotificationItemWrapper>
    );
};

export default NotificationDateSection;
