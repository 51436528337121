import Dragger from 'antd/lib/upload/Dragger';
import styled from 'styled-components';
import { DraggerText } from '../../../../pages/projectInfo/tabs/projectInformation/ProjectInformation.styles';
import { blueDark01, blueDark02, blueLink, error, grayLight05, white } from '../../../../styles/colors';
import { PrimaryButton, Text } from '../../../../styles/common.styles';

export const UploadFormSubText = styled(Text)`
    margin-top: 8px;
    margin-bottom: 20px;
`;

export const DatePickerContainer = styled('div')`
    .ant-picker {
        width: 100%;
    }
`;

export const SaveUploadsButton = styled(PrimaryButton)`
    width: 100px;
    height: 32px;
    border: 1px solid ${blueDark02};
    border-radius: 3px;
    margin-top: 20px;
    background-color: inherit;
    color: ${blueDark02};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${grayLight05};
        border: 1px solid ${blueDark02};
        color: ${blueDark02};
    }
    &.pictureSaveBtn {
        margin-top: 28px;
    }
`;

export const UploadDraggerText = styled(DraggerText)`
    max-width: 350px;
    margin-top: 0;
    @media screen and (max-width: 760px) {
        max-width: unset;
    }
`;

export const UploadChooseFile = styled(DraggerText)`
    max-width: 350px;
    margin-top: 4px;
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    @media screen and (max-width: 760px) {
        max-width: unset;
    }
`;

export const UploadFileInputFlexRow = styled('div')`
    display: flex;
    flex-direction: row;
    padding: 8px 0 2px;
    background-color: ${grayLight05};
    div:after {
        content: ' ';
        white-space: pre;
    }

    div:last-child:after {
        content: '';
    }
`;

export const UploadFileDragger = styled(Dragger)`
    height: 112px !important;
    max-height: 112px !important;
    border: none !important;
    .ant-upload {
        background-color: #eff9fc;
        border: 1px dashed #c1c1c1;
        border-radius: 3px;
    }
    .ant-upload,
    .ant-upload-drag-container {
        transition: 0s 0.05s;
    }
    &.ant-upload-drag-hover .ant-upload {
        border-color: #4b97e8;
        background-color: #e3e9f7;
    }
    &.ant-upload-drag-hover .ant-upload-drag-container {
        opacity: 0.3;
    }
    &.error {
        .ant-upload {
            border: 1px dashed ${error} !important;
        }
    }
`;

export const AlternativeTextContainer = styled('div')`
    gap: -10px;
//    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    height: 88px;
    color: ${blueDark01};
`;

export const AlternativeTextTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    width: 13px;
    top: -83px;
    left: 160px;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: 10px;
            left: -140px;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 200;
            width: 250px;
            white-space: normal;
            a {
                color: ${blueLink};
                &:hover {
                    color: ${blueLink};
                }
            }
        }
    }
`;
