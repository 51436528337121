import styled from 'styled-components';
import { Form } from 'antd';
import { error } from '../../../styles/colors';
import { grayLight02 } from '../../../styles/colors';

export const CustomInputFormItem = styled(Form.Item)`
    .ant-input {
        height: 32px;
        padding: 8px 10px;
    }
    textarea.ant-input {
        height: auto;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        border-color: ${error};
    }
    .ant-form-item-explain-error {
        color: ${error};
    }
    #basic_city {
        pointer-events: none;
        background-color: ${grayLight02};
    }
    .normal-weight-text {
        font-weight: normal;
        font-family: "Proxima Nova";
        display: inline-block;
        margin-left: 3px;
    }
`;
