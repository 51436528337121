import React, { useEffect, useState } from 'react';
import { formatTwoDigits } from '../../../helpers/dateHelper';

interface Props {
    expireMilliseconds: number;
    terminateSession: () => void;
    text: string;
}

const TrialSectionTimer: React.FC<Props> = ({ text, expireMilliseconds, terminateSession }) => {
    const oneDay = 1000 * 60 * 60 * 24;
    const oneHour = 1000 * 60 * 60;
    const oneMinute = 1000 * 60;

    const [minutes, setMinutes] = useState(
        Math.floor(((expireMilliseconds % oneDay) % oneHour) / oneMinute),
    );
    const [hours, setHours] = useState(Math.floor(expireMilliseconds / oneHour));
    const [days, setDays] = useState(Math.floor(expireMilliseconds / oneDay));

    useEffect(() => {
        const myInterval = setInterval(() => {
            if (days < 1 && hours === 0 && minutes < 2) {
                terminateSession();
            }
            if (minutes > 0) {
                setMinutes(minutes - 1);
            }
            if (minutes === 0) {
                if (hours === 0 && days < 1) {
                    clearInterval(myInterval);
                } else {
                    setHours(hours - 1);
                    setMinutes(59);
                    if (hours % 24 === 0) {
                        setDays(days - 1);
                    }
                }
            }
        }, 60000);
        return () => {
            clearInterval(myInterval);
        };
    });

    const validDate = expireMilliseconds !== -1;
    const expireText = validDate ? `${days} ${days === 1 ? 'day' : 'days'}` : '--:--';

    return (
        <span>
            {days < 1 && validDate
                ? text + `${formatTwoDigits(hours)}:${formatTwoDigits(minutes)}`
                : text + expireText}
        </span>
    );
};

export default TrialSectionTimer;
