import React, { useEffect, useState } from 'react';
import { alertService } from '../../services';
import CustomTabs from '../../components/common/tabs/CustomTabs';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { addPages, removePage } from '../../redux/reducers/navigationReducer/NavigationSlice';
import {
    changePasswordAction,
    getAccountSettingsAction,
    getUserByIdAction,
    updateUserAction,
} from '../../redux/actions/userActions';
import {
    ChangePasswordRequest,
    CreateUserRequest,
    GetAccountSettingsResponse,
    UserByIdResponse,
} from '../../redux/actions/userActions/types';
import { useHistory, useParams } from 'react-router-dom';
import DeleteModal from '../../components/common/deleteModal/DeleteModal';
import InformationCard from '../../components/common/informationCard/informationCard';
import NotificationsEmail from '../../components/notificationsEmail/NotificationsEmail';
import { clearState } from '../../redux/persistedState';
import { riseStoredUser, setStoredUser } from '../../redux/reducers/authReducer/AuthSlice';
import { extractGetByIdUser } from '../../helpers/extract';
import PageHeader from '../../components/common/pageHeader/PageHeader';

import { AccountPageSpin, DownInformationCard } from './Account.styles';
import { PrimaryButton, SpaceEnd } from '../../styles/common.styles';
import { AccountPermissionTypes } from '../../models/permission/enums';
import { SubscriptionTypes } from '../../models/subscription/enums';
import { usePermissions } from '../../hooks/permissions';
import { useModalState } from '../../hooks/modalState';
import { getCustomerPortalLinkAction } from '../../redux/actions/checkoutActions';
import { AlertMessages } from '../../components/alert/AlertMessages';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { setSubscriptionUpdating } from '../../redux/reducers/subscriptionReducer/SubscriptionSlice';
import ChangeUsersModal from '../../components/ChangeUsersModal/ChangeUsersModal';
import ErrorModal from '../../components/common/errorModal/ErrorModal';
import BasicInformationTab from '../editUser/BasicInformationTab';
import { ActionResponse } from '../../models/actionResponse/types';
import { getInformationCardButtonText, getInformationCardContent } from './Account.helper';

interface Params {
    tab: string;
}
const Account: React.FC = () => {
    const selector = useAppSelector((state) => state);
    const { authReducer } = selector;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const params = useParams<Params>();

    const [user, setUser] = useState<CreateUserRequest | undefined>(undefined);
    const [userSubscriptionType, setUserSubscriptionType] = useState('');
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const { getAccountRole, getAccountSubscription } = usePermissions();
    const { isOpen, onOpen, onClose } = useModalState();
    const [subscriptionCanceled, setSubscriptionCanceled] = useState(false);
    const errorModal = useModalState();

    useEffect(() => {
        dispatch(
            addPages([
                {
                    key: NavigationKey.DASHBOARD,
                    value: `${authReducer.user?.firstName}’s Dashboard`,
                },
                {
                    key: NavigationKey.ACCOUNT,
                    value: `Account`,
                },
            ]),
        );
        getData();
        return () => {
            dispatch(removePage());
        };
    }, []);

    const getData = async () => {
        await getUser();
        if (getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN) {
            await getAccountSettings();
        }
    };

    const getAccountSettings = async () => {
        const { payload } = (await dispatch(
            getAccountSettingsAction(),
        )) as GetAccountSettingsResponse;
        if (payload.data) {
            setSubscriptionCanceled(payload.data.subscription?.cancelAtPeriodEnd ? true : false);
        }
    };

    const getUser = async () => {
        if (authReducer.user?.id) {
            const { payload } = (await dispatch(
                getUserByIdAction(authReducer.user.id.toString()),
            )) as ActionResponse<Array<UserByIdResponse>>;
            if (payload?.data[0]) {
                setUserSubscriptionType(payload.data[0].subscription.planName);
                const currentUser = extractGetByIdUser(payload?.data[0]);
                setUser(currentUser);
            } else {
                history.push('/users');
            }
        }
    };

    const onSubmit = async (data: CreateUserRequest) => {
        Object.keys(data).forEach((key) => {
            if (!data[key]) {
                data[key] = null;
            }
        }, data);
        if (authReducer.user?.id) {
            const { payload } = (await dispatch(
                updateUserAction({
                    user: {
                        ...data,
                        userName: data.email,
                        accountId: user?.parentAccountId || user?.accountId,
                    },
                    id: authReducer.user.id.toString(),
                }),
            )) as any;
            if (payload?.data) {
                if (payload?.data[0].userName !== user?.userName) {
                    clearState();
                    history.push('/login');
                    dispatch(riseStoredUser());
                    alertService.info('Please login with a new email', {
                        autoClose: false,
                    });
                    return;
                } else {
                    dispatch(setStoredUser(payload?.data[0]));
                }
                window.scroll(0, 0);
                alertService.success(AlertMessages.UPDATE_USER);
            } else {
                errorModal.onOpen();
            }
        }
    };

    const submitContainer = (callback: () => void) => {
        return (
            <SpaceEnd>
                <PrimaryButton htmlType="submit" onClick={callback}>
                    Save
                </PrimaryButton>
            </SpaceEnd>
        );
    };

    const submitPassword = async (data: ChangePasswordRequest) => {
        const { payload } = await dispatch(changePasswordAction(data));
        if (payload.message) {
            window.scroll(0, 0);
            alertService.success(AlertMessages.CHANGE_PASSWORD);
        }
    };

    const goToSubscriptions = async () => {
        if (
            getAccountSubscription() === SubscriptionTypes.FREE ||
            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_INDIVIDUAL ||
            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE
        ) {
            history.push('/plan');
            return;
        }
        if (authReducer.user?.accountId) {
            const { payload } = (await dispatch(getCustomerPortalLinkAction())) as any;
            if (payload) {
                dispatch(setSubscriptionUpdating(true));
                window.location.href = payload.data;
            }
        }
    };

    const upgradeAccount = () => {
        if (getAccountSubscription() === SubscriptionTypes.OFFICE) {
            onOpen();
        } else {
            goToSubscriptions();
        }
    };

    const showLowerInformationCard = () => {
        if (
            getAccountSubscription() === SubscriptionTypes.OFFICE &&
            !authReducer.user?.parentAccountId
        ) {
            return true;
        }
        return false;
    };

    const informationCards = (isSubscriptionCanceled: boolean) => () =>
        (
            <div>
                <InformationCard
                    title={`Subscription type: ${authReducer.user?.subscriptionPlanName}`}
                    content={getInformationCardContent(getAccountSubscription())}
                    buttonText={getInformationCardButtonText(getAccountSubscription())}
                    onClick={upgradeAccount}
                    showButton={getAccountSubscription() !== SubscriptionTypes.OFFICE}
                />
                <>
                    {showLowerInformationCard() && (
                        <DownInformationCard>
                            <InformationCard
                                title="Payment"
                                content={
                                    'Visit your Stripe account to update payment information or cancel your subscription.'
                                }
                                buttonText={
                                    isSubscriptionCanceled ? 'Renew subscription' : 'Visit Stripe'
                                }
                                onClick={goToSubscriptions}
                                showButton
                            />
                        </DownInformationCard>
                    )}
                </>
            </div>
        );

    const generateBasicInformation = (isSubscriptionCanceled: boolean) => {
        return (
            <>
                <BasicInformationTab
                    user={user}
                    onSubmit={onSubmit}
                    submitContainer={submitContainer}
                    submitPassword={submitPassword}
                    isOwner={true}
                    informationCards={informationCards(isSubscriptionCanceled)}
                    userSubscriptionType={userSubscriptionType}
                />
                {isOpen && (
                    <ChangeUsersModal
                        isOpen={isOpen}
                        handleCancel={onClose}
                        currentUsersCount={user?.maxUsersCount}
                        subscriptionName="Office"
                    />
                )}
            </>
        );
    };

    const tabs = (isSubscriptionCanceled: boolean) => {
        return [
            {
                name: 'Basic information',
                tab: generateBasicInformation(isSubscriptionCanceled),
                key: '1',
            },
            getAccountSubscription() !== SubscriptionTypes.FREE && ({
                name: 'Notifications & email',
                tab: (
                    <NotificationsEmail
                        id={authReducer.user?.id}
                        userNotifications={user?.notificationSettings || []}
                    />
                ),
                key: '2',
            }),
        ];
    };

    return (
        <>
            <PageHeader
                title={`${authReducer.user?.firstName} ${authReducer.user?.lastName}’s account`}
            />
            <AccountPageSpin size="large" spinning={authReducer.pending}>
                <div>
                    <CustomTabs tabs={tabs(subscriptionCanceled)} defaultKey={params?.tab || '1'} />
                </div>
            </AccountPageSpin>
            <DeleteModal
                isOpen={isOpenDeleteModal}
                closeModal={() => {
                    setIsOpenDeleteModal(false);
                }}
                reload={() => {}}
                text={`${user?.firstName} ${user?.lastName}`}
                title="account"
                icon="trash-can-list.svg"
            />
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </>
    );
};

export default Account;
