import { Modal } from 'antd';
import styled from 'styled-components';
import { blueLink } from '../../../styles/colors';

export const CreateInBulkModal = styled(Modal)`
    width: 680px !important;
    height: 476px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    a {
        color: ${blueLink};
    }

    .ant-form-item {
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .ant-divider-horizontal {
        margin-top: 20px;
        margin-bottom: 9px;
    }

    .ant-spin-nested-loading > div > .ant-spin {
        top: 0;
    }
`;
