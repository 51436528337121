import { NotificationItem } from '../../models/user/user';

export const ACCEPTS_SHARING = 'Someone accepts my sharing invitation';
export const ADDS_PRODUCTS = 'Someone adds, edits, or deletes a product in my projects';
export const UPLOAD_DOCUMENT = 'Someone uploads a new document for a product';
export const LEAVES_COMMENT = 'Someone leaves a comment on one of my projects';
export const SEND_COPY =
    'I contact a manufacturer for spec & quote help: send me a copy of my message';

export const defaultState: { [key: string]: NotificationItem } = {
    [ACCEPTS_SHARING]: {
        id: 1,
        name: ACCEPTS_SHARING,
        sendEmailDigest: false,
        sendEmailMeEachTime: false,
        notificationSettingId: 1,
    },
    [ADDS_PRODUCTS]: {
        id: 1,
        name: ADDS_PRODUCTS,
        sendEmailDigest: false,
        sendEmailMeEachTime: false,
        notificationSettingId: 1,
    },
    [UPLOAD_DOCUMENT]: {
        id: 1,
        name: UPLOAD_DOCUMENT,
        sendEmailDigest: false,
        sendEmailMeEachTime: false,
        notificationSettingId: 1,
    },
    [LEAVES_COMMENT]: {
        id: 1,
        name: LEAVES_COMMENT,
        sendEmailDigest: false,
        sendEmailMeEachTime: false,
        notificationSettingId: 1,
    },
    [SEND_COPY]: {
        id: 1,
        name: SEND_COPY,
        sendEmailDigest: false,
        sendEmailMeEachTime: false,
        notificationSettingId: 1,
    },
};
