import { Col, Row } from 'antd';
import styled from 'styled-components';
import { blueDark01, blueLink } from '../../../styles/colors';

export const ManageSubscriptionContent = styled('div')`
    margin-left: 6rem;
`;

export const SettingLabelText = styled(Col)`
    width: 15rem;
    text-align: end;
    div {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px;
        line-height: 16px;
        color: ${blueDark01};
    }
`;

export const SettingLabelValue = styled(Col)`
    div {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: ${blueDark01};
    }
`;

export const SettingActionLink = styled(Col)`
    display: flex;
    align-items: center;
`;

export const SettingPropertyRow = styled(Row)`
    margin-bottom: ${(props: { marginBottom?: string }) => props.marginBottom}px;
`;

export const AddMoreUsersContainer = styled('div')`
    position: relative;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    a {
        color: ${blueLink};
    }
`;

export const OpenModalText = styled('div')`
    cursor: pointer;
    color: ${blueLink};
`;
