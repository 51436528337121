import { SubscriptionPlan, SubscriptionTier } from '../redux/actions/subscriptionActions/types';

export default class PriceHelper {
    subscriptions: SubscriptionPlan[];

    constructor(inputSubscription: SubscriptionPlan[]) {
        this.subscriptions = inputSubscription;
    }

    //For individual subscriptions
    getSubscriptionPrice(name: string) {
        return (this.subscriptions.find((x) => x.name === name)?.unitAmount ?? 0) / 100;
    }

    //For multiuser subscriptions
    getMultiUserSubscriptionPrice(name: string, usersQuantity: number) {
        const selectedSubscription = this.subscriptions.find((x) => x.name === name);
        let resultPrice = 0;

        //for method to work tier list should go from less user quantity required to the biggest until the null value
        if (selectedSubscription?.tiers) {
            if (selectedSubscription?.tiers[0]?.up_to < usersQuantity) {
                for (let i = 1; i < selectedSubscription?.tiers.length; i++) {
                    if (
                        selectedSubscription?.tiers[i]?.up_to === null ||
                        selectedSubscription?.tiers[i]?.up_to >= usersQuantity
                    ) {
                        //here is the last operable tier. We find number of users above required minnimum and calculate price for them
                        const currentQuantity = Math.abs(
                            usersQuantity - selectedSubscription?.tiers[i - 1]?.up_to,
                        );
                        resultPrice += this.getTierPrice(
                            selectedSubscription?.tiers[i],
                            currentQuantity,
                        );
                        break;
                    } else {
                        //total price = tiers prices for users + required tier price
                        const currentQuantity =
                            selectedSubscription?.tiers[i]?.up_to -
                            selectedSubscription?.tiers[i - 1]?.up_to;
                        resultPrice += this.getTierPrice(
                            selectedSubscription?.tiers[i],
                            currentQuantity,
                        );
                    }
                }
            }

            //in every user quantity the lowest tier is required
            resultPrice += this.getTierPrice(selectedSubscription?.tiers[0], usersQuantity);
        }
        return resultPrice;
    }

    getTierPrice(tier: SubscriptionTier | undefined, usersQuantity: number) {
        return tier?.flat_amount !== null
            ? (tier?.flat_amount ?? 0) / 100
            : ((tier?.unit_amount ?? 0) * usersQuantity) / 100;
    }
}
