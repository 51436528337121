import { UploadsTableFields } from '../../components/productInfo/uploads/Uploads.types';
import { SelectData } from '../common/types';
import { ProjectPermissionTypes } from '../permission/enums';
import { InternalProduct } from '../product/types';
import { TableData } from '../tableData/types';

export interface Project {
    id: number;
    name: string;
    type: string;
    status: string;
    lastModified: string;
    created: string;
    owner: string;
    office: string;
    group: string;
    isCompleted: boolean;
}

export interface ProjectTableFields {
    id: number;
    name: string;
    externalProjectId: string;
    buildingTypeName: string;
    projectStatusName: string;
    modifiedOn: string;
    createdOn: string;
    officeName: string;
    projectGroupName: string;
    key: string;
    isCompleted: boolean;
    isConfidential: boolean;
    isTemplate?: boolean;
    projectLibraryVisibilityId: number;
    imageFilePath: string;
    userRole: ProjectPermissionTypes;
    clientOwner: string;
    createdById: number;
}

export interface RecentlyProjectsTableFields {
    activityName: string;
    authorName: string;
    emailList: string;
    id: number;
    modifiedOn: string;
    projectId: number;
    projectImageFilePath: any;
    projectIsDeleted: boolean;
    projectName: string;
    projectProduct?: InternalProduct;
    projectProductCommentId?: number;
    projectProductUpload?: UploadsTableFields;
}

export interface ProjectLibrary {
    projects: Array<Project>;
    tableData: TableData;
    totalCount: number;
}

export interface ProjectReferences {
    allBasisOfDesigns: Array<SelectData>;
    allBudgets: Array<SelectData>;
    allBuildingTypes: Array<SelectData>;
    allConstructionTypes: Array<SelectData>;
    allContractTypes: Array<SelectData>;
    allDocumentTypes: Array<SelectData>;
    allGreenBuildingRatingSystems: Array<SelectData>;
    allGrossBuildingAreas: Array<SelectData>;
    allProductPricingTypes: Array<SelectData>;
    allProductSubstitutions: Array<SelectData>;
    allProjectAccesses: Array<SelectData>;
    allProjectGroups: Array<SelectData>;
    allProjectRoles: Array<SelectData>;
    allProjectStatuses: Array<SelectData>;
    allProjectVisibilities: Array<SelectData>;
}

export class ProjectUserData implements ProjectTableFields {
    clientOwner = '';
    projectLibraryVisibilityId = 1;
    isConfidential = false;
    isCompleted = false;
    isTemplate = false;
    id = 0;
    name = '';
    externalProjectId = '';
    buildingTypeName = '';
    projectStatusName = '';
    modifiedOn = '';
    createdOn = '';
    owner = '';
    officeName = '';
    projectGroupName = '';
    key = '';
    imageFilePath = '';
    userRole = '' as ProjectPermissionTypes;
    manufacturer = '';
    createdById = 0;
}

export class ProjectData implements Project {
    id = 0;
    name = '';
    type = '';
    status = '';
    lastModified = '';
    created = '';
    owner = '';
    office = '';
    group = '';
    isCompleted = false;
}

export interface CreateProjectInitialValue {
    name: string | undefined;
    projectStatusId: number | undefined;
    projectGroupId: number | undefined;
    buildingTypeId: number | undefined;
    projectLibraryVisibilityId: number | undefined;
    projectTemplateId?: number | undefined;
    isConfidential?: boolean;
    description?: string | undefined;
    id?: number;
}

export interface AvailableProject {
    id: number;
    name: string;
    projectStatusId: number;
    projectLibraryVisibilityId: number;
    buildingTypeId: number;
    isConfidential: boolean;
    isTemplate: boolean;
    description: string;
    label: string;
    value: string;
}

export interface MFRProject {
    id: number;
    name: string;
    buildingType: SelectData;
    contractTypeName: string;
    otherBuildingType: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    userRole: ProjectPermissionTypes;
    isCompleted: boolean;
    isTemplate: boolean;
}
