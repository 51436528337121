export const toShortDate = (timestamp: any): string => {
    if (!timestamp) {
        return '';
    }
    const [, month, dayOfMonth, year] = new Date(timestamp).toDateString().split(' ');
    return `${month} ${dayOfMonth}, ${year}`;
};

export const toShortLocalDate = (timestamp: any): string => {
    if (!timestamp) {
        return '';
    }
    const localDate = convertUTCDateToLocalDate(new Date(timestamp));
    const [, month, dayOfMonth, year] = localDate.toDateString().split(' ');
    return `${month} ${dayOfMonth}, ${year}`;
};

export const toShortTime = (timestamp: any): string => {
    if (!timestamp) {
        return '';
    }
    const [time, half] = new Date(timestamp).toLocaleTimeString().split(' ');
    const zone = getTimeZone(new Date(timestamp));
    return `${time.substring(0, time.length - 3)} ${half.toLowerCase()} ${zone}`;
};

export const toShortLocalTime = (timestamp: any): string => {
    if (!timestamp) {
        return '';
    }
    const localDate = convertUTCDateToLocalDate(new Date(timestamp));
    const [time, half] = localDate.toLocaleTimeString().split(' ');
    const zone = getTimeZone(localDate);
    return `${time.substring(0, time.length - 3)} ${half.toLowerCase()} ${zone}`;
};

function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}

export const toLocalShortDateTime = (timestamp: any): string => {
    if (!timestamp) {
        return '';
    }
    const localDate = convertUTCDateToLocalDate(new Date(timestamp));
    const [, month, dayOfMonth, year] = localDate.toDateString().split(' ');
    const [time, half] = localDate.toLocaleTimeString().split(' ');
    const zone = getTimeZone(localDate);
    return `${month} ${dayOfMonth}, ${year} ${time.substring(0, time.length - 3)} ${half.toLowerCase()} ${zone}`;
};

function getTimeZone(localDate) {
    return localDate
            .toString()
            .replace(/^.*GMT.*\(/, '')
            .replace(/\)$/, '')
            .split(' ')
            .map((w) => w[0])
            .join('');
}

export const dayLeft = (expiredOn: string | undefined): number => {
    if (!expiredOn) {
        return 0;
    }
    const oneDay = 1000 * 60 * 60 * 24;
    const expiredDayDate = new Date(expiredOn) as any;
    const today = new Date();
    const offset = today.getTimezoneOffset() * 60000;
    const start =
        Date.UTC(
            expiredDayDate.getFullYear(),
            expiredDayDate.getMonth(),
            expiredDayDate.getDate(),
        ) + offset;
    const end = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    return (start - end) / oneDay;
};

export const getExpireDifferenceInMilliseconds = (expiredOn: string | undefined): number => {
    if (!expiredOn) {
        return -1;
    }

    const expiredDayDate = new Date(expiredOn) as any;
    const today = new Date() as any;
    const offset = today.getTimezoneOffset() * 60000;
    const difference = expiredDayDate - today - offset;
    if (!difference || difference < 0) {
        return -1;
    }

    return difference;
};

export const formatTwoDigits = (digit: number): string => {
    return digit < 10 ? `0${digit}` : digit.toString();
};

export const formatTimeEST = (dateStr: string | null | undefined) => {
    if (dateStr) {
        const date = new Date(dateStr + 'Z');
        const options: any = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        const zone = new Date()
            .toString()
            .replace(/^.*GMT.*\(/, '')
            .replace(/\)$/, '')
            .split(' ')
            .map((w) => w[0])
            .join('');
        return (
            date
                .toLocaleDateString('en-US', options)
                .replace('at', '–')
                .replace(' AM', 'am')
                .replace(' PM', 'pm') + ` ${zone}`
        );
    }
    return '';
};

export const estimatedInMinutes = (date: string, completed: number, all: number) => {
    if (!date) {
        return '';
    }
    const today: any = new Date(new Date()).getTime(); // Current date now.
    const start: any = new Date(date as any).getTime();
    const offset = new Date().getTimezoneOffset() * 60000;
    const diff = today + offset - start; // Difference in milliseconds.
    const takeForOne = diff / completed;
    const timeLeft = (all - completed) * takeForOne;
    return Math.floor(timeLeft / 60000);
};

export const removeCentury = (date?: string) => {
    if (!date) {
        return '';
    }
    const actualDate = date.split('/');
    actualDate.splice(2, 1, actualDate[actualDate.length - 1].substring(2));
    return actualDate.join('/');
};
