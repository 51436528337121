import { MenuItem } from '../../../../models/common/types';
import { ActivitiesFilter } from '../../../../models/projectActivity/enums';

export const actionMenu: Array<MenuItem> = [
    {
        key: 'all',
        label: 'All notifications',
    },
    {
        key: 'sharing',
        label: 'Sharing',
    },
    {
        key: 'project',
        label: 'Project',
    },
    {
        key: 'file',
        label: 'Files',
    },
    {
        key: 'comment',
        label: 'Comments',
    },
    {
        key: 'product',
        label: 'Products',
    },
];

export const activitiesGroup = {
    sharing: [
        ActivitiesFilter.ProjectShared,
        ActivitiesFilter.ProjectShareRemoved,
        ActivitiesFilter.AccessToUserRemoved,
        ActivitiesFilter.InvitedUserToProject,
        ActivitiesFilter.InvitationAccepted,
    ],
    project: [
        ActivitiesFilter.ProjectCompleted,
        ActivitiesFilter.ProjectPropertiesUpdated,
        ActivitiesFilter.ProjectDeleted,
        ActivitiesFilter.NewProjectAddedToProjectLibrary,
    ],
    file: [
        ActivitiesFilter.DeletedFile,
        ActivitiesFilter.UpdatedFile,
        ActivitiesFilter.UploadedFile,
    ],
    image: [
        ActivitiesFilter.DeletedImage,
        ActivitiesFilter.UpdatedImage,
        ActivitiesFilter.UploadedImage,
    ],
    comment: [ActivitiesFilter.CommentDeleted, ActivitiesFilter.CommentAdded],
    product: [
        ActivitiesFilter.PricingUpdated,
        ActivitiesFilter.ProductMarkedAsInstalled,
        ActivitiesFilter.ProductMarkedAsUninstalled,
        ActivitiesFilter.ProductUpdated,
        ActivitiesFilter.ProductDeleted,
        ActivitiesFilter.ProductDeleted,
        ActivitiesFilter.ProductAdded,
    ],
};
