import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { blueDark02, blueLink, grayLight01, grayLight05, white } from '../../../styles/colors';
import { PrimaryButton, BoldText } from '../../../styles/common.styles';

export const ProductPricingContainer = styled('div')`
    background-color: ${grayLight05};
    padding: 4px 12px;
    border-top: 1px solid ${grayLight01};
`;

export const ProductPricingSwitcherContainer = styled('div')`
    display: flex;
    align-items: center;
`;

export const PricingSwitcherTitle = styled(BoldText)`
    margin-left: -20px;
`;

export const PricingContent = styled('div')`
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 200ms ease, opacity 0ms ease;
    background-color: ${grayLight05};
    &.clicked {
        max-height: 400px;
        opacity: 1;
        transition: max-height 200ms ease, opacity 0ms ease;
        background-color: ${grayLight05};
        padding: 17px 23px 40px 0;
    }
    display: flex;
    gap: 32px;
`;

export const PricingInfoInput = styled(TextArea)`
    height: 132px !important;
`;

export const PricingContainer = styled('div')`
    width: 75%;
`;

export const PricingSaveButton = styled(PrimaryButton)`
    width: 100px;
    height: 32px;
    border: 1px solid ${blueDark02};
    border-radius: 3px;
    margin-top: 20px;
    background-color: ${grayLight05};
    color: ${blueDark02};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${grayLight05};
        border: 1px solid ${blueDark02};
        color: ${blueDark02};
    }
`;
