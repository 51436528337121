import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { blue01, blueDark01, blueLink } from '../../../styles/colors';
import { Form } from 'antd';

export const ForgotPasswordLink = styled(NavLink)`
    color: ${blueLink};
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    &:hover {
        color: ${blueLink};
    }
`;

export const LoginTitle = styled('h2')`
    font-family: 'DINNextLTPro';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: ${blue01};
    margin-bottom: 16px;
`;

export const LoginSubTitle = styled('div')`
    min-width: 470px;
    color: ${blueDark01};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 28px;
`;

export const LoginFormStyle = styled(Form)`
    max-width: 470px;
    .ant-input-affix-wrapper {
        padding: 0;
    }
    .ant-input-affix-wrapper > .ant-input:not(textarea) {
        padding: 9px 10px;
    }
    .anticon svg {
        margin-right: 10px;
    }
    .ant-form-item-label > label {
        font-size: 13px;
        &::after {
            margin: 0 8px 0 2px;
        }
    }
    .ant-form-item {
        margin-bottom: 16px;
    }
    .ant-form-item-row {
        gap: unset;
    }
    .ant-col-6 {
        flex: 0 0 23%;
    }
    .ant-col-offset-6 {
        margin-left: 23%;
    }
    #input-container {
        label {
            color: ${blueDark01};
        }
    }
`;

export const ButtonEmptySpace = styled('div')`
    flex: 0 0 23%;
`;

export const LoginButtonContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ButtonFormItem = styled('div')`
    display: flex;
    min-width: 0;
`;
