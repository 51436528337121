import styled from 'styled-components';
import { Link } from '../../../../../common/modalFooter/ModalFooter.styles';
import { blue01 } from '../../../../../../styles/colors';

export const DivFlexWithWrap = styled('div')`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 333px;
    margin: 8px 0 0 0;
`;

export const DivImpactArea = styled('div')`
    display: flex;
`;

export const DivImpactAreaImage = styled('div')`
    display: inline;
`;

export const DivImpactAreaName = styled('div')`
    display: block;
    font-family: 'Proxima Nova' !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    padding: 6px 0 0 6px;
`;

export const DtGroup = styled('div')`
   width: 334px;
   gap: 8px;
   padding: 0 0 8px 0;
`;

export const DtOtherGroup = styled('div')`
   width: 334px;
   gap: 8px;
   padding: 8px 0 16px 0;
`;

export const DtBlock = styled('div')`
`;

export const DtRow = styled('div')`
    display: inline-flex;
    width: 100%;
    margin-top: 6px;
`;

export const DtName = styled('div')`
    min-width: 200px;
    width: 200px;
    padding-top: 0;
    a {
        font-family: Proxima Nova;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        text-decoration: none;
    }
    a:link { 
      text-decoration: none; 
    } 
    a:visited { 
      text-decoration: none; 
    } 
    a:hover { 
      text-decoration: none; 
    } 
    a:active { 
      text-decoration: none; 
    }
`;

export const DtCmfContainer = styled('div')`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: start;
`;

export const DtCmfImage = styled('div')`
`;

export const DtLink = styled(Link)`
    font-family: Proxima Nova;
    color: #00237B;
    font-weight: 400;
    margin-bottom: 0px;
    text-decoration: none !important; 
`;

export const IaLegend = styled('div')`
   width: 333px;
   heigth: 66px;
   gap: 2px;
   border: 1px 0px 0px 0px;
   opacity: 0px;
   border-top: 1px solid #D3D3D3;
`;
