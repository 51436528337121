import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Modal } from 'antd';
import { CloseIcon } from '../../styles/common.styles';
import ModalFooter from '../common/modalFooter/ModalFooter';
import ModalTitle from '../common/modalTitle/ModalTitle';
import { Text } from '../../styles/common.styles';
import { blueDark01 } from '../../styles/colors';

interface Props {
    closeModal: () => void;
    isOpen: boolean;
}
const SuggestUpgradeAccountModal: React.FC<Props> = ({ isOpen, closeModal }) => {
    const history = useHistory();
    const goToSelectPlan = () => {
        history.push('/plan');
    };
    return (
        <Modal
            open={isOpen}
            onCancel={closeModal}
            centered
            footer={null}
            className="delete-modal-container"
            closeIcon={<CloseIcon />}
        >
            <Form onFinish={goToSelectPlan}>
                <ModalTitle title={`Please update your account`} />
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    You cannot create projects in the current subscription
                </Text>
                <ModalFooter
                    cancel={closeModal}
                    cancelText="Close"
                    submit={() => {}}
                    actionButtonWidth={150}
                    action="Upgrade Account"
                />
            </Form>
        </Modal>
    );
};

export default SuggestUpgradeAccountModal;
