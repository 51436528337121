import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { EC3ExportStatus } from '../../../../models/ec3/enums';
import { EC3Export } from '../../../../models/ec3/types';
import { getExportDataAction } from '../../../../redux/actions/ec3Actions';
import { ExportToEC3Response } from '../../../../redux/actions/ec3Actions/types';
import { GetProjectInformationResponse } from '../../../../redux/actions/projectActions/types';
import { setEc3Project } from '../../../../redux/reducers/ec3Reducer/EC3Slice';
import { PageContainer } from '../../../../styles/common.styles';
import { EC3TabContainer, EC3TabSpin } from './EC3ExportTab.styles';
import ExportData from './exportData/ExportData';
import ExportHeader from './exportHeader/ExportHeader';

interface Props {
    projectInfo: GetProjectInformationResponse | null;
    getProjectInfo: () => Promise<void>;
    setPersistActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const EC3ExportTab: React.FC<Props> = ({ projectInfo, getProjectInfo }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const exportIdRef = useRef(0);
    const { authReducer, ec3Reducer, productReducer, projectReducer } = selector;
    const [competedExportCount, setCompetedExportCount] = useState(0);
    const [state, setState] = useState<EC3Export>({
        pBProject: {},
        eC3Project: { link: '', token: '', name: '' },
        export: {
            exportID: 0,
            status: '',
            completedCallCount: 0,
            allCallCount: 0,
            completenessPercentage: 0,
            createdOn: '',
            errorMessage: '',
        },
    });

    useEffect(() => {
        exportIdRef.current = 0;
    }, [ec3Reducer.exportBegan]);
    useEffect(() => {
        const url = new URL(window.location.href);
        const projectId = url.searchParams.get('projectId');
        if (!projectInfo && projectId) {
           projectInfo = {id: projectId};
        }
        if (projectInfo) {
            getExportData();
            const interval = setInterval(() => {
                getExportData(interval);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [ec3Reducer.exportedProject, ec3Reducer.exportBegan]);

    useEffect(() => {
        if (state.export.allCallCount !== 0) {
            if (state.export.allCallCount === state.export.completedCallCount) {
                setTimeout(() => {
                    const currentExportedCount = state.export.allCallCount + 1;
                    setCompetedExportCount(currentExportedCount);
                }, 1000);
            }
        }
    }, [state.export.completedCallCount]);

    const getExportData = async (interval?: NodeJS.Timer) => {
        if (projectInfo && authReducer.user) {
            const exportBy = { projectId: projectInfo.id };
            const { payload } = (await dispatch(
                getExportDataAction({ ...exportBy, userId: authReducer.user.id }),
            )) as ExportToEC3Response;
            if (payload) {
                let completedCallCount = payload.export.completedCallCount;
                if (payload.export.status === EC3ExportStatus.Done) {
                    dispatch(
                        setEc3Project({ projectId: projectInfo.id, link: payload.eC3Project.link }),
                    );
                    clearInterval(interval);
                    if (!exportIdRef.current) {
                        payload.export.completedCallCount = payload.export.allCallCount + 1;
                        setState(payload);
                        getProjectInfo();
                        return;
                    }
                    completedCallCount = payload.export.allCallCount;
                }
                if (payload.export.status === EC3ExportStatus.Cancelled) {
                    clearInterval(interval);
                }
                payload.export.completedCallCount = completedCallCount;
                setState(payload);
                getProjectInfo();
                exportIdRef.current = payload.export.exportID;
            } else {
                clearInterval(interval);
                const initialState = state;
                initialState.export.status = EC3ExportStatus.NotExported;
                setState(() => initialState);
                setCompetedExportCount(1);
            }
        }
    };

    return (
        <PageContainer>
            <EC3TabSpin
                spinning={productReducer.pending || ec3Reducer.pending || projectReducer.pending}
            >
                <ExportHeader projectInfo={projectInfo} ec3ExportData={state} />
                {state.export.status ? (
                    <ExportData ec3ExportData={state} competedExportCount={competedExportCount} />
                ) : (
                    <EC3TabContainer />
                )}
            </EC3TabSpin>
        </PageContainer>
    );
};

export default EC3ExportTab;
