import { Menu } from 'antd';
import styled from 'styled-components';
import { blueDark02, blueLink, grayDark03, grayDark05 } from '../../../styles/colors';
import { ClickableRow } from '../../../styles/common.styles';

export const ProjectLibraryTable = styled('div')`
    .ant-table-cell {
        padding: 8px 8px 8px 0;

        &::before {
            content: unset !important;
        }
    }

    td.ant-table-cell {
        vertical-align: top;
    }

    thead {
        .ant-table-cell {
            background: unset;
            border-bottom-width: 2px;
            font-family: 'Proxima N W15 Bold';
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: ${blueDark02};
        }
    }

    tbody {
        .ant-table-cell {
            padding: 2px 8px 2px 0;
            cursor: pointer;
        }
    }

    .project-name {
        display: flex;
        gap: 8px;
        align-items: center;
    }
    .ant-table-cell-row-hover {
        .menu-button {
            background-color: #eff9fc;
        }
    }
    .ant-table-row:hover {
        .menu-button {
            background-color: #eff9fc;
        }
    }
    #table-search-input {
        width: 172px;
        height: 20px;
        padding: 0;
        &::placeholder {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: ${grayDark05};
        }
    }
    .ant-table-column-title {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: ${blueDark02};
    }
    .ant-spin-nested-loading > div > .ant-spin {
        top: 0;
    }
`;

export const DotsAction = styled('div')`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${grayDark05};
    box-shadow: 0px 8px 0px ${grayDark05}, 0px 16px 0px ${grayDark05};
    position: absolute;
    top: 13px;
`;

export const OpenMenuButton = styled('button')`
    border: none;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    padding: 20px;
`;

export const TableImageContainer = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 54px;
    &.default-image {
        .ant-image:first-child {
            position: absolute;
            z-index: 1;
        }
    }
    .ant-image-img {
        object-fit: cover;
    }
`;

export const ProjectTableNameRow = styled('div')`
    display: flex;
    align-items: center;
`;

export const ProjectNameContainer = styled('div')`
    display: block;
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 12px;
    line-height: 16px;
    color: ${blueLink};
`;

export const ProjectLibraryTableCellContainer = styled('div')`
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: ${grayDark03};
    div {
        padding: 11px 0 0 0px;
    }
`;

export const ProjectLibraryContainer = styled('div')`
    max-width: 2000px;
    padding: 0 100px 40px;

    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const ProjectActionMenuItem = styled('span')`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${grayDark03};
    white-space: nowrap;
`;

export const ProjectActionMenu = styled(Menu)`
    .disabled {
        &:hover {
            background-color: transparent;
        }
        opacity: 0.3;
    }
`;

export const MFRTemplateOwner = styled('div')`
    padding: 13px 0 0 12px;
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 11px;
    line-height: 13px;
    color: ${grayDark03};
`;

export const ProjectTableClickableRow = styled(ClickableRow)`
    font-size: 12px;
    text-decoration: none;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 30rem;
`;

export const ConfidentialTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -30px;
            left: 24px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 280px;
        }
    }
`;
