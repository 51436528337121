export const CSVHeader = [
    { label: 'LAST NAME', key: 'lastName' },
    { label: 'FIRST NAME', key: 'firstName' },
    { label: 'EMAIL', key: 'email' },
    { label: 'TITLE', key: 'titleRoleName' },
    { label: 'PHONE', key: 'phoneNumber' },
    { label: 'Ext', key: 'phoneNumberExt' },
    { label: 'isManufacturer', key: 'isManufacturer' },
    { label: 'SUBSCRIPTION', key: 'subscriptionPlanName' },
    { label: 'STATUS', key: 'subscriptionStatus' },
    { label: 'ORG NAME', key: 'organizationName' },
    { label: 'ORG SIZE', key: 'organizationSizeShortName' },
    { label: 'SERVICES', key: 'services' },
    { label: 'ACCOUNT TYPE', key: 'roleName' },
    { label: 'CREATED', key: 'createdOn' },
    { label: 'LAST LOGIN', key: 'lastLoginTime' },
];
