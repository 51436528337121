import styled from 'styled-components';
import { Space, Button } from 'antd';
import { blueDark01, blueDark02, blueDark03, grayLight01, white, blueLink } from '../../../styles/colors';
import { PrimaryButton, SpaceEnd } from '../../../styles/common.styles';

export const CreateAccountButton = styled(Button)`
    border-radius: 3px;
    background-color: ${blueDark02};
    color: ${grayLight01};
    cursor: pointer;
    border-color: ${grayLight01};
    border-radius: 3px 0 0 3px;
    font-family: 'DINNextLTPro';
    border-right: 0px solid ${blueDark02};
    &:focus {
        background-color: ${blueDark03};
        color: ${grayLight01};
        border-color: ${grayLight01};
    }
`;

export const CreateAccountMenu = styled(Button)`
    border-radius: 0px 3px 3px 0;
    padding: 0 10px;
    background-color: ${blueDark02};
    font-family: 'DINNextLTPro';
    &:focus {
        background-color: ${blueDark01};
        color: #ffff;
        border-color: #ffff;
    }
`;

export const CreateAccountButtonContainer = styled('div')`
    &:hover {
        button:first-child {
            background-color: ${blueDark03};
            border-color: #ffff;
            color: #ffff;
        }
        button:last-child {
            background-color: ${blueDark03};
            border-color: #ffff;
            color: #ffff;
        }
    }
`;

export const UsersActionSpace = styled(Space)`
    gap: 16px !important;
    height: 32px;
`;

export const UsersTableActions = styled(SpaceEnd)``;

export const UsersPageActionsButton = styled(PrimaryButton)`
    align-items: center;
    background-color: ${white};
    color: ${blueDark02};
    border: 1px solid ${blueDark02};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${white};
        color: ${blueDark01};
        border: 1px solid ${blueDark01};
    }
    &:disabled {
        background-color: ${white};
        color: ${blueDark02};
        border: 1px solid ${blueDark02};
        &:hover {
            color: ${blueDark02};
            background-color: ${white};
            border: 1px solid ${blueDark02};
        }
    }
`;
