import React from 'react';
import { Image } from 'antd';
import * as MaterialProgramTip from '../materialPrograms/MaterialProgramTip';
import * as Tooltip from '../Tooltip';
import { TooltipStyles } from '../Tooltip.styles';
import { DivFlexWithWrap, DivImpactArea, DivImpactAreaImage, DivImpactAreaName, DtGroup, DtOtherGroup, DtCmfContainer, DtCmfImage, DtBlock, DtRow, DtName, DtLink, IaLegend } from './DtImpactAreasTip.styles';

const DtImpactAreasTip: React.FC = ( data: any, dtImpactAreas: Array<any> ) => {
    const dts = dtImpactAreas.length && dtImpactAreas.length > 0 ? dtImpactAreas : data.dtImpactAreas;
    const dtsSustainability = dts.filter((dt) => dt.groupName==='Sustainability');
    const dtsOther = dts.filter((dt) => dt.groupName==='Other');

    const getInspectAreaImage = (impactAreaName : string) => {
       switch(impactAreaName) {
          case 'HUMAN HEALTH':
              return 'src/assets/images/health_red_notext.png';
          case 'CLIMATE HEALTH':
              return 'src/assets/images/climate_blue_notext.png';
          case 'CIRCULARITY':
              return 'src/assets/images/circularity_blue_notext.png';
          case 'SOCIAL HEALTH & EQUITY':
              return 'src/assets/images/social_purple_notext.png';
          case 'ECOSYSTEM HEALTH':
              return 'src/assets/images/ecosystem_green_notext.png';
       }
    };

    const allImpactAreas = ['Human Health','Climate Health','Circularity','Social Health & Equity','Ecosystem Health'];

    return (
        <MaterialProgramTip.Container spanset={true}>
            <Tooltip.Container spanset={true}>
                <TooltipStyles className='program-cmfdt shadow'>
                    <div className='tooltip-cmf tooltip-cmfdt'>

         {dtsSustainability.length && dtsSustainability.length > 0 && (
          <DtGroup>
            <p><b>Sustainability documents</b></p>
              {dtsSustainability?.map((dt, i) => (
                <DtBlock>
                   <DtRow height={dt.name.length > 40 ? 28 : 14}>
                     <DtName>
                         <DtLink href={dt.url}>
                             {dt.name}
                         </DtLink>
                     </DtName>

                     <DtCmfContainer>
                        {dt?.impactAreas?.map((impactArea, i) => (
                          <>
                            {impactArea.name && (
                              <DtCmfImage>
                                 <Image
                                    preview={false}
                                    src={getInspectAreaImage(impactArea.name?.toUpperCase())}
                                    style={{ cursor: 'pointer' }}
                                    title={impactArea.name}
                                    width={14} height={14}
                                 />
                              </DtCmfImage>
                            )}
                          </>
                        ))}
                     </DtCmfContainer>
                   </DtRow> 
                </DtBlock>
              ))}
          </DtGroup>
        )}

        {dtsSustainability.length && dtsSustainability.length > 0 && (
          <DtOtherGroup>
            <p><b>Other documents</b></p>
              {dtsOther?.map((dt, i) => (
                <DtBlock>
                   <DtRow>
                     <DtName>
                        <DtLink href={dt.url}>
                           {dt.name}
                        </DtLink>
                     </DtName>
                   </DtRow> 
                </DtBlock>
              ))}

          </DtOtherGroup>
        )}

          <IaLegend>
              <DivFlexWithWrap>
                {allImpactAreas?.map((impactArea, i) => (
                  <DivImpactArea>
                    <DivImpactAreaImage>
                       <Image
                         preview={false}
                         src={getInspectAreaImage(impactArea?.toUpperCase())}
                         style={{ cursor: 'none' }}
                         width={22} height={22}
                       />
                    </DivImpactAreaImage>
                    <DivImpactAreaName>
                       {impactArea}
                    </DivImpactAreaName>
                  </DivImpactArea>
                ))}
              </DivFlexWithWrap>
          </IaLegend>

                    </div>
                </TooltipStyles>
            </Tooltip.Container>
        </MaterialProgramTip.Container>
    );
};

export default DtImpactAreasTip;
