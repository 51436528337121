export enum ActivitiesName {
    ProductAdded = 'added product',
    ProductDeleted = 'deleted product',
    ProductUpdated = 'updated product',
    ProductMarkedAsInstalled = 'marked product as installed',
    ProductMarkedAsUninstalled = 'marked product as uninstalled',
    CommentAdded = 'added comment',
    CommentDeleted = 'deleted comment',
    UploadedFile = 'uploaded file',
    UpdatedFile = 'updated file',
    DeletedFile = 'deleted file',
    UploadedImage = 'uploaded image',
    UpdatedImage = 'updated image',
    DeletedImage = 'deleted image',
    PricingUpdated = 'updated pricing',
    ProjectShared = 'shared project to',
    ProjectShareRemoved = 'removed share',
    InvitedUserToProject = 'invite to project',
    InvitationAccepted = 'accepted the invitation',
    AccessToUserRemoved = 'removed access',
    NewProjectAddedToProjectLibrary = 'created project',
    ProjectDeleted = 'deleted project',
    ProjectPropertiesUpdated = 'updated project information',
    ProjectCompleted = 'completed project',
}

export enum ActivitiesFilter {
    ProductAdded = 'ProductAdded',
    ProductDeleted = 'ProductDeleted',
    ProductUpdated = 'ProductUpdated',
    ProductMarkedAsInstalled = 'ProductMarkedAsInstalled',
    ProductMarkedAsUninstalled = 'ProductMarkedAsUninstalled',
    CommentAdded = 'CommentAdded',
    CommentDeleted = 'CommentDeleted',
    UploadedFile = 'UploadedFile',
    UpdatedFile = 'UpdatedFile',
    DeletedFile = 'DeletedFile',
    UploadedImage = 'UploadedImage',
    UpdatedImage = 'UpdatedImage',
    DeletedImage = 'DeletedImage',
    PricingUpdated = 'PricingUpdated',
    ProjectShared = 'ProjectShared',
    ProjectShareRemoved = 'ProjectShareRemoved',
    InvitedUserToProject = 'InvitedUserToProject',
    AccessToUserRemoved = 'AccessToUserRemoved',
    NewProjectAddedToProjectLibrary = 'NewProjectAddedToProjectLibrary',
    ProjectDeleted = 'ProjectDeleted',
    ProjectPropertiesUpdated = 'ProjectPropertiesUpdated',
    ProjectCompleted = 'ProjectCompleted',
    InvitationAccepted = 'InvitationAccepted',
}
