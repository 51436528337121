import { Divider, Space } from 'antd';
import styled from 'styled-components';
import { blue, blue01, blue02, grayDark02, grayLight02, white } from '../../../styles/colors';
import { PrimaryButton } from '../../../styles/common.styles';

export const ModalFooterDivider = styled(Divider)`
    border-top: 1px solid ${grayLight02};
    margin-top: 5px;
    margin-bottom: 20px;
`;

export const ModalButtonContainer = styled(Space)`
    width: 100%;
    justify-content: flex-end;
    gap: 12px !important;
    &:focus {
        color: ${white};
    }
`;

export const CaptchaBlock = styled('div')`
    margin-top: 16px;
`;

export const CaptchaText = styled('p')`
    font-weight: 400;
    font-size: 11px;
    color: ${grayDark02};
    margin-bottom: 0px;
`;

export const Link = styled('a')`
    color: ${blue01};
    margin-bottom: 0px;
`;

export const OuterContainer = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const SubmitModalButton = styled(PrimaryButton)`
    min-width: 100px;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${blue01};
    color: #ffffff;
    border: none;
    font-family: 'DINNextLTPro';
    padding: 7px 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 14px;
    &:hover {
        background-color: ${blue02};
        color: #ffff;
    }
    &:focus {
        background-color: ${blue01};
        color: #ffffff;
    }
    &:disabled {
        opacity: 0.3;
        background-color: ${blue01};
        color: #ffffff;
        &:hover {
            background-color: ${blue01};
            color: #ffffff;
        }
    }
    &:active {
        background-color: ${blue};
    }
`;
