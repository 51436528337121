import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { SelectData } from '../../models/common/types';
import { getSubscriptionAction } from '../../redux/actions/subscriptionActions';
import { SubscriptionPlan } from '../../redux/actions/subscriptionActions/types';
import ModalFooter from '../common/modalFooter/ModalFooter';
import ModalTitle from '../common/modalTitle/ModalTitle';
import CustomSelect from '../common/select/CustomSelect';
import {
    ChangeNumberOfUsersSubtitle,
    ChangeUsersCountForm,
    HighLightText,
    ChangeNumberOfUsersCol,
    ChangeNumberOfUsersRow,
    ChangeNumberOfUsersColPrice,
    PriceSpin,
    ChangeNumberOfUsersModal,
    ContactMessage,
    ContactMessageWrapper,
} from './ChangeUsersModal.styles';
import { ActionResponse, CommonResponse } from '../../models/actionResponse/types';
import PriceHelper from '../../helpers/priceHelper';
import { SubscriptionTypes } from '../../models/subscription/enums';
import CustomInput from '../common/inputs/CustomInput';
import { CloseIcon, Text } from '../../styles/common.styles';
import { buyMoreUsersAction } from '../../redux/actions/checkoutActions';
import { alertService } from '../../services';
import { blueDark01 } from '../../styles/colors';

interface Props {
    isOpen: boolean;
    handleCancel: () => void;
    currentUsersCount?: number;
    subscriptionName: string;
    updateSubscription?: (planName: SubscriptionTypes, usersCount: number) => Promise<void>;
}

interface State {
    isNewPrice: boolean;
    count: number;
    price: number;
}

const ChangeUsersModal: React.FC<Props> = ({
    isOpen,
    handleCancel,
    updateSubscription,
    subscriptionName,
    currentUsersCount = 1,
}) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((store) => store);
    const { authReducer } = selector;
    const [priceHelper, setPriceHelper] = useState<PriceHelper | null>(null);
    const [state, setState] = useState<State>({
        isNewPrice: false,
        count: 1,
        price: 0,
    });
    const [isCurrentUsersCountMoreThanTen, setIsCurrentUsersCountMoreThanTen] = useState<boolean>(
        currentUsersCount >= 10,
    );
    const [form] = Form.useForm();
    if (subscriptionName.toLowerCase().includes('trial')) {
        subscriptionName = subscriptionName.replace('FreeTrial', '') + '(Trial)';
    }

    const handleUsersCountSelect = (value: string) => {
        const price = priceHelper?.getMultiUserSubscriptionPrice(SubscriptionTypes.OFFICE, +value);
        setState({ price: price || 0, count: +value, isNewPrice: true });
        if (+value > 10) {
            setIsCurrentUsersCountMoreThanTen(true);
        } else {
            setIsCurrentUsersCountMoreThanTen(false);
        }
    };

    useEffect(() => {
        getSubscriptionPrices();
    }, [currentUsersCount]);

    const getSubscriptionPrices = async () => {
        const { payload } = (await dispatch(getSubscriptionAction())) as ActionResponse<
            Array<SubscriptionPlan>
        >;
        if (payload.data) {
            const helper = new PriceHelper(payload.data);
            setPriceHelper(helper);
            let price = 0;
            switch (authReducer.user?.subscriptionPlanName) {
                case SubscriptionTypes.OFFICE:
                    price = helper?.getMultiUserSubscriptionPrice(
                        SubscriptionTypes.OFFICE,
                        currentUsersCount,
                    );
                    break;
                case SubscriptionTypes.INDIVIDUAL:
                    price =
                        payload.data.find((plan) => plan.name === SubscriptionTypes.INDIVIDUAL)
                            ?.price || 0;
                    break;
                default:
                    break;
            }
            setState({ ...state, price: price || 0, isNewPrice: isCurrentUsersCountMoreThanTen });
            if (isCurrentUsersCountMoreThanTen) {
                form.setFieldValue('count', '11');
            }
        }
    };
    const usersCountOptions = () => {
        const resultOptions = Array<SelectData>();
        for (let i = currentUsersCount + 1; i < 11; i++) {
            const element = { id: i.toString(), name: i.toString() };
            resultOptions.push(element);
        }
        resultOptions.push({ id: '11', name: '11 or more' });
        return resultOptions;
    };

    const onSubmit = async (data: { count: string }) => {
        const newUserCount = data && data.count ? parseInt(data.count) : parseInt(currentUsersCount);
        if (updateSubscription) {
            updateSubscription(SubscriptionTypes.OFFICE, newUserCount);
            return;
        }
        const { payload } = (await dispatch(
            buyMoreUsersAction({ usersCount: newUserCount }),
        )) as CommonResponse;
        if (payload.message) {
            handleCancel();
            alertService.success(
                'Congratulations, you have successfully upgraded your subscription. Soon you will be able to use the new functionality. It usually takes 5-10 minutes',
                { autoClose: false },
            );
        }
    };

    const actualPrice = () => {
        if (isCurrentUsersCountMoreThanTen) {
            return (
                <ContactMessageWrapper>
                    <ContactMessage>
                        Contact
                        <a href={`mailto:support@transparencycatalog.com`}>
                            {` support@transparencycatalog.com `}
                        </a>
                        for custom pricing.
                    </ContactMessage>
                </ContactMessageWrapper>
            );
        }
        return (
            <PriceSpin spinning={selector.subscriptionReducer.pending} size="small">
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    {`${!selector.subscriptionReducer.pending ? '$' + state.price : ''} per year`}
                </Text>
            </PriceSpin>
        );
    };

    return (
        <ChangeNumberOfUsersModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <ModalTitle
                title="Change number of Admin / Member accounts"
                icon="ic-modal-title-change-number-of-users.svg"
            />
            <ChangeNumberOfUsersSubtitle weight={400} size={14} height={18} color={blueDark01}>
                Your <HighLightText> {subscriptionName}</HighLightText> subscription includes{' '}
                <HighLightText>{currentUsersCount}</HighLightText> users.{' '}
                <>
                    Need 11 or more user accounts?{' '}
                    <a href={`mailto:support@transparencycatalog.com`}>Contact us</a> for custom
                    pricing
                </>
            </ChangeNumberOfUsersSubtitle>
            <ChangeUsersCountForm onFinish={onSubmit} form={form}>
                <ChangeNumberOfUsersRow>
                    <ChangeNumberOfUsersCol>
                        <CustomSelect
                            id="ModalSelect"
                            label="Need more users?"
                            name="count"
                            options={usersCountOptions()}
                            placeholder="Add more accounts"
                            rules={[{ required: updateSubscription === undefined  ? true : false, message: '' }]}
                            block
                            props={{ onChange: handleUsersCountSelect }}
                        />
                    </ChangeNumberOfUsersCol>
                    {state.isNewPrice && (
                        <ChangeNumberOfUsersColPrice>
                            <CustomInput
                                Component={actualPrice}
                                label={'Price'}
                                name="price"
                                block
                            />
                        </ChangeNumberOfUsersColPrice>
                    )}
                </ChangeNumberOfUsersRow>
                <ModalFooter
                    action="Save"
                    cancel={handleCancel}
                    submit={() => {}}
                    primaryProps={{ disabled: isCurrentUsersCountMoreThanTen }}
                />
            </ChangeUsersCountForm>
        </ChangeNumberOfUsersModal>
    );
};

export default ChangeUsersModal;
