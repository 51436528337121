import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Form, Input, Spin } from 'antd';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    InputLikePassword,
    ListContainer,
    ServiceItemContainer,
    ServiceName,
    ServicesContainer,
    ServiceText,
    ServiceText1,
    SignUpCol,
    SignUpImage,
    SignUpPageContainer,
    SignUpPageDivider,
    SignUpPageLink,
    SignUpPageWrapper,
    SignUpRow,
    SignUpSubscribeContainer,
    SignUpTipText,
    SignUpTitle,
    PasswordFormItem,
} from './SignUp.styles';
import { blueDark01, blueLink } from '../../styles/colors';
import CustomSelect from '../../components/common/select/CustomSelect';
import { NavLink, useHistory } from 'react-router-dom';
import CustomInput from '../../components/common/inputs/CustomInput';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { SelectData } from '../../models/common/types';
import { SubscriptionTypes } from '../../models/subscription/enums';
import { StoredUser } from '../../models/user/user';
import { getRegisterReferencesAction, registerAction } from '../../redux/actions/authActions';
import { SignUpRequest } from '../../redux/actions/authActions/types';
import {
    getCheckoutLinkAction,
    createTrialSubscriptionAction,
    createFreeSubscriptionAction,
} from '../../redux/actions/checkoutActions';
import { alertService } from '../../services';
import {
    Margin,
    PrimaryButton,
    SecondaryButton,
    SpaceBetween,
    SpaceEnd,
    Text,
} from '../../styles/common.styles';
import { AlertMessages } from '../../components/alert/AlertMessages';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { SubscriptionPlan } from '../../redux/actions/subscriptionActions/types';
import { getSubscriptionAction } from '../../redux/actions/subscriptionActions';
import { ActionResponse } from '../../models/actionResponse/types';
import { createSalesforceLead } from '../../helpers/analytics';
import { getAccessToken } from '../../redux/persistedState';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';

interface SubscriptionState {
    availableSubscriptions: Array<SubscriptionPlan>;
    selectedSubscription: SubscriptionPlan | null;
}
interface Params {
    subscriptionId: string;
    usersCount?: string;
}
const SignUpPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const params = useParams<Params>();
    const selector = useAppSelector((state) => state);
    const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionState>({
        availableSubscriptions: Array<SubscriptionPlan>(),
        selectedSubscription: null,
    });

    useEffect(() => {
        dispatch(addPages([{ key: NavigationKey.HOME, value: 'SM Transparency Catalog' }]));
    }, []);

    const { subscriptionReducer, authReducer } = selector;
    const authToken = getAccessToken();
    if (authToken) {
        history.push('/dashboard');
    }
    const [form] = Form.useForm();
    const watch = Form.useWatch('officeCountryId', form);

    const [references, setReferences] = useState({
        allOrganizationSizes: Array<SelectData>(),
        allServices: Array<SelectData>(),
        allCountriesAndStates: Array<any>(),
        allStates: Array<any>(),
    });

    const [services, setServices] = useState({ current: Array<{ id: number; text?: string }>() });

    const [error, setError] = useState(false);

    const [visible, setVisible] = useState({ password: false, confirm: false });
    const handleVisible = (field: string) => {
        setVisible({ ...visible, [field]: !visible[field] });
    };

    useEffect(() => {
        getReferences();
        getSubscriptions();
    }, []);

    useEffect(() => {
        if (references.allCountriesAndStates.length) {
            form.setFieldsValue({
                officeCountryId: references.allCountriesAndStates[0]?.id,
                officeStateId: references.allCountriesAndStates[0]?.states[0]?.id,
            });
        }
    }, [references.allCountriesAndStates]);

    useEffect(() => {
        if (watch) {
            const currentCountry = references.allCountriesAndStates.find(
                (country) => country.id === watch,
            );
            setReferences({ ...references, allStates: currentCountry?.states || [] });
            form.setFieldValue('officeStateId', currentCountry?.states[0]?.id);
        }
    }, [watch]);

    const getSubscriptions = async () => {
        const { payload } = (await dispatch(getSubscriptionAction())) as ActionResponse<
            Array<SubscriptionPlan>
        >;
        if (payload.data) {
            const selectedSubscription = payload.data.find(
                (ss) => ss.id.toString() === params.subscriptionId,
            );
            if (selectedSubscription) {
                setSubscriptionPlans({
                    ...subscriptionPlans,
                    availableSubscriptions: payload.data,
                    selectedSubscription,
                });
            }
        } else {
            alertService.error('Sorry, plans is not available now');
        }
    };

    const getReferences = async () => {
        const { payload } = (await dispatch(getRegisterReferencesAction())) as any;
        if (payload.data) {
            setReferences({
                allOrganizationSizes: payload?.data?.allOrganizationSizes || [],
                allServices: payload?.data?.allServices || [],
                allCountriesAndStates: payload?.data?.allCountriesAndStates || [],
                allStates: payload?.data?.allCountriesAndStates[0].states || [],
            });
        }
    };

    const handleServicesSelect = (event: any) => {
        const service = +event.target.name;
        const currentServices = services.current;
        const index = currentServices.findIndex((item) => item.id === service);
        if (index === -1) {
            currentServices.push({ id: service });
        } else {
            currentServices.splice(index, 1);
        }
        if (currentServices.length && error) {
            setError(false);
        }
        setServices({ ...services, current: currentServices });
    };

    const onSubmit = async (data: SignUpRequest) => {
        if (subscriptionPlans.selectedSubscription) {
            const { payload } = (await dispatch(
                registerAction({
                    ...data,
                    subscriptionPlanId: subscriptionPlans.selectedSubscription?.id,
                    userName: data.email,
                    titleRoleName: data.titleRoleID,
                    services: services.current,
                }),
            )) as any;
            if (payload.data.length) {
                createSalesforceLead(data);
                if (subscriptionPlans?.selectedSubscription?.name === SubscriptionTypes.FREE) {
                    createFreeSubscription(payload.data[0]);
                    return;
                }
                if (subscriptionPlans.selectedSubscription.name.toLowerCase().includes('trial')) {
                    createTrialSubscription(payload.data[0]);
                } else {
                    getSubscriptionLink(payload.data[0]);
                }
            } else {
                window.scroll(0, 0);
                alertService.error('Something went wrong');
            }
        } else {
            alertService.error('Something went wrong');
        }
    };

    const getSubscriptionLink = async (data: StoredUser) => {
        if (subscriptionPlans.selectedSubscription) {
            const { payload } = (await dispatch(
                getCheckoutLinkAction({
                    planName: subscriptionPlans.selectedSubscription?.name,
                    customerEmail: data.email,
                    usersCount:
                        subscriptionPlans.selectedSubscription?.name === SubscriptionTypes.OFFICE
                            ? parseInt(params.usersCount || '0')
                            : 1,
                    accountId: data.accountId,
                }),
            )) as any;
            window.location.href = payload.url;
        }
    };

    const createTrialSubscription = async (user: StoredUser) => {
        if (subscriptionPlans.selectedSubscription) {
            const { payload } = (await dispatch(
                createTrialSubscriptionAction({
                    planName: subscriptionPlans.selectedSubscription.name.replace('FreeTrial', ''),
                    planId: subscriptionPlans.selectedSubscription.id,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    usersCount:
                        subscriptionPlans.selectedSubscription.name === SubscriptionTypes.OFFICE
                            ? 2
                            : 1,
                    accountId: user.accountId,
                }),
            )) as any;
            if (payload) {
                history.push('/login');
                window.scrollTo(0, 0);
                alertService.success(AlertMessages.SENT_CONFIRMATION_LINK);
            }
        }
    };

    const createFreeSubscription = async (user: StoredUser) => {
        const { payload } = (await dispatch(
            createFreeSubscriptionAction({ accountId: user.accountId, email: user.email }),
        )) as any;
        if (payload) {
            history.push('/login');
            window.scrollTo(0, 0);
            alertService.success(AlertMessages.SENT_CONFIRMATION_LINK);
        }
    };

    const goToChooseSubscription = () => {
        history.push('/subscriptions');
    };

    const onFailed = (errorsInfo: ValidateErrorEntity<SignUpRequest>) => {
        if (errorsInfo?.errorFields.length && errorsInfo.errorFields[0].name) {
            document
                .getElementById(`${errorsInfo.errorFields[0].name[0].toString()}`)
                ?.scrollIntoView();
        }
        if (!services.current.length) {
            setError(true);
            return;
        }
    };

    const showPhoneFields = () => {
        if (
            (subscriptionPlans?.selectedSubscription?.name === SubscriptionTypes.INDIVIDUAL ||
                subscriptionPlans?.selectedSubscription?.name === SubscriptionTypes.OFFICE) &&
            !subscriptionPlans?.selectedSubscription.name.toLowerCase().includes('trial')
        ) {
            return true;
        }
        return false;
    };

    return (
        <>
            <Spin size="large" spinning={subscriptionReducer.pending || authReducer.pending}>
                <PageHeader
                    title="Create a Transparency Catalog account – it’s free."
                    showMenu={false}
                ></PageHeader>
                <SignUpPageWrapper>
                    <SignUpImage src="src/assets/images/frame.png" preview={false} />
                    <SignUpPageContainer>
                        <SignUpPageLink>
                            <NavLink to="/login">
                                <Text weight={400} size={14} height={18} color={blueLink}>
                                    Already have an account? Log in instead.
                                </Text>
                            </NavLink>
                        </SignUpPageLink>
                        <ListContainer>
                            <li>
                                See the extensive and ever-increasing list of building product
                                manufacturers and industry organizations investing in product
                                transparency{' '}
                            </li>
                            <li>
                                Export products to grow your internal digital specification & BoD
                                libraries
                            </li>
                            <li>
                                Get news, product updates and invites to our hugely popular webinar
                                series, ‘Transparency is the New Green in Product Selection,
                                Specification and Procurement.’
                            </li>
                        </ListContainer>
                        <SignUpTitle> About you</SignUpTitle>
                        <Form
                            onFinish={onSubmit}
                            onFinishFailed={onFailed}
                            autoComplete={'false'}
                            form={form}
                        >
                            <SignUpRow>
                                <SignUpCol id="firstName">
                                    <CustomInput
                                        Component={Input}
                                        label="First name"
                                        name="firstName"
                                        required
                                        block
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please add your first name',
                                            },
                                        ]}
                                        removeExtraSpaces={true}
                                    />
                                </SignUpCol>
                                <SignUpCol id="lastName">
                                    <CustomInput
                                        Component={Input}
                                        label="Last name"
                                        name="lastName"
                                        required
                                        block
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please add your last name',
                                            },
                                        ]}
                                        removeExtraSpaces={true}
                                    />
                                </SignUpCol>
                            </SignUpRow>
                            <SignUpRow>
                                <SignUpCol id="email">
                                    <CustomInput
                                        Component={Input}
                                        label="Email"
                                        name="email"
                                        required
                                        block
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please add your email',
                                            },
                                            {
                                                pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                                                message: 'Invalid email',
                                            },
                                        ]}
                                        forbidWhitespace={true}
                                    />
                                </SignUpCol>
                                <SignUpCol id="titleRoleID">
                                    <CustomInput
                                        Component={Input}
                                        label="Title/role"
                                        name="titleRoleID"
                                        block
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please add your title/role',
                                            },
                                        ]}
                                    />
                                </SignUpCol>
                            </SignUpRow>
                            <SignUpRow>
                                <SignUpCol id="phoneNumber">
                                    <CustomInput
                                        Component={Input}
                                        label="Phone"
                                        name="phoneNumber"
                                        block
                                        required={showPhoneFields()}
                                        rules={[
                                            {
                                                required: showPhoneFields(),
                                                message: 'Please add your phone',
                                            },
                                            {
                                                pattern:
                                                    /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                                                message: 'Invalid phone',
                                            },
                                        ]}
                                    />
                                </SignUpCol>
                                <SignUpCol id="phoneNumberExt">
                                    <CustomInput
                                        Component={Input}
                                        label="Ext."
                                        name="phoneNumberExt"
                                        block
                                        rules={[
                                            {
                                                pattern: /^(\+?\d{1,3}|\d{1,4})$/,
                                                message: 'Invalid phone extension',
                                            },
                                        ]}
                                    />
                                </SignUpCol>
                            </SignUpRow>
                            <SignUpTitle> About your organization</SignUpTitle>
                            <SignUpRow>
                                <SignUpCol id="organizationName">
                                    <CustomInput
                                        Component={Input}
                                        label="Organization name"
                                        name="organizationName"
                                        required
                                        block
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please add your organization name',
                                            },
                                        ]}
                                    />
                                </SignUpCol>
                                <SignUpCol id="organizationSizeId">
                                    <CustomSelect
                                        label="Organization size"
                                        name="organizationSizeId"
                                        options={references.allOrganizationSizes}
                                        placeholder="Select organization size"
                                        required
                                        block
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select your organization size',
                                            },
                                        ]}
                                    />
                                </SignUpCol>
                            </SignUpRow>
                            <SignUpTitle> About your office</SignUpTitle>
                            <ServiceText1 weight={400} height={18} size={14} color={blueDark01}>
                                {' '}
                                Tell us about <strong> the office you’re associated with </strong>at
                                your organization.
                            </ServiceText1>
                            <SignUpRow>
                                <SignUpCol>
                                    <CustomSelect
                                        label="Office country"
                                        name="officeCountryId"
                                        options={references.allCountriesAndStates}
                                        placeholder="Select country"
                                        props={{
                                            filterOption: (input: string, option: any) =>
                                                (option?.children.toLowerCase() ?? '').includes(
                                                    input.toLowerCase(),
                                                ),
                                            showSearch: true,
                                        }}
                                        block
                                    />
                                </SignUpCol>
                                <SignUpCol>
                                    {references.allStates.length > 0 ? (
                                        <CustomSelect
                                            label="Office state/province"
                                            name="officeStateId"
                                            options={references.allStates}
                                            placeholder="Select state or province"
                                            props={{
                                                filterOption: (input: string, option: any) =>
                                                    (option?.children.toLowerCase() ?? '').includes(
                                                        input.toLowerCase(),
                                                    ),
                                                showSearch: true,
                                            }}
                                            block
                                        />
                                    ) : (
                                        <CustomInput
                                            Component={Input}
                                            label="Office state/province"
                                            name="officeStateName"
                                            block
                                        />
                                    )}
                                </SignUpCol>
                                <SignUpCol>
                                    <CustomInput
                                        Component={Input}
                                        label="Office city"
                                        name="officeCity"
                                        block
                                    />
                                </SignUpCol>
                            </SignUpRow>
                            <ServiceText weight={400} height={16} size={13} color={blueDark01}>
                                {' '}
                                Services & activities{' '}
                                <SignUpTipText> Select all that apply </SignUpTipText>
                            </ServiceText>

                            <ServicesContainer>
                                <SignUpRow>
                                    <SignUpCol>
                                        {references.allServices.map((service, i) => {
                                            if (references.allServices.length / 2 > i) {
                                                return (
                                                    <ServiceItemContainer key={service.id}>
                                                        <Checkbox
                                                            name={service.id}
                                                            onChange={handleServicesSelect}
                                                            checked={
                                                                services.current.findIndex(
                                                                    (item) =>
                                                                        item.id.toString() ===
                                                                        service.id.toString(),
                                                                ) !== -1
                                                            }
                                                        />
                                                        <ServiceName> {service.name}</ServiceName>
                                                    </ServiceItemContainer>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </SignUpCol>
                                    <SignUpCol>
                                        {references.allServices.map((service, i) => {
                                            if (references.allServices.length / 2 <= i) {
                                                return (
                                                    <ServiceItemContainer key={service.id}>
                                                        <Checkbox
                                                            name={service.id}
                                                            onChange={handleServicesSelect}
                                                            checked={
                                                                services.current.findIndex(
                                                                    (item) =>
                                                                        item.id.toString() ===
                                                                        service.id.toString(),
                                                                ) !== -1
                                                            }
                                                        />
                                                        <ServiceName> {service.name}</ServiceName>
                                                    </ServiceItemContainer>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </SignUpCol>
                                </SignUpRow>
                            </ServicesContainer>
                            <SignUpTitle style={{ marginTop: '40px' }}>Password</SignUpTitle>
                            <SignUpRow>
                                <SignUpCol>
                                    <div className="create-user-input-container">
                                        <div
                                            id="confirm-container"
                                            className={'required input-block'}
                                        >
                                            <PasswordFormItem
                                                label="Password *"
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please add your password',
                                                    },
                                                    {
                                                        pattern:
                                                            /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$/,
                                                        message:
                                                            'Should be at least 6 characters, numbers and letters only',
                                                    },
                                                ]}
                                            >
                                                <InputLikePassword
                                                    autoComplete="off"
                                                    suffix={
                                                        visible.password ? (
                                                            <EyeOutlined
                                                                onClick={() =>
                                                                    handleVisible('password')
                                                                }
                                                            />
                                                        ) : (
                                                            <EyeInvisibleOutlined
                                                                onClick={() =>
                                                                    handleVisible('password')
                                                                }
                                                            />
                                                        )
                                                    }
                                                    className={visible.password ? '' : 'key'}
                                                />
                                            </PasswordFormItem>
                                        </div>
                                    </div>
                                </SignUpCol>
                                <SignUpCol>
                                    <div className="create-user-input-container">
                                        <div
                                            id="confirm-container"
                                            className={'required input-block'}
                                        >
                                            <PasswordFormItem
                                                name="confirm"
                                                label="Confirm Password *"
                                                dependencies={['password']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please confirm your password!',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (
                                                                !value ||
                                                                getFieldValue('password') === value
                                                            ) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error(
                                                                    'The two passwords that you entered do not match!',
                                                                ),
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <InputLikePassword
                                                    autoComplete="off"
                                                    suffix={
                                                        visible.confirm ? (
                                                            <EyeOutlined
                                                                onClick={() =>
                                                                    handleVisible('confirm')
                                                                }
                                                            />
                                                        ) : (
                                                            <EyeInvisibleOutlined
                                                                name="confirm"
                                                                onClick={() =>
                                                                    handleVisible('confirm')
                                                                }
                                                            />
                                                        )
                                                    }
                                                    className={visible.confirm ? '' : 'key'}
                                                />
                                            </PasswordFormItem>
                                        </div>
                                    </div>
                                </SignUpCol>
                            </SignUpRow>
                            <SignUpSubscribeContainer>
                                <Checkbox />
                                <Text weight={400} height={18} size={14} color={blueDark01}>
                                    Subscribe me to Transparency Catalog emails
                                </Text>
                            </SignUpSubscribeContainer>
                            <SignUpPageDivider type="horizontal" />
                            <Text weight={400} height={16} size={13} color={'#888688;'}>
                                Sustainable Minds will never sell, loan, rent, trade, exchange, or
                                share your name or other personal information with anyone. Any
                                information we collect is solely for the purpose of understanding
                                Transparency Catalog usage patterns and informing functionality
                                development.
                            </Text>
                            <Margin value="0 0 40px 0" />
                            <SpaceEnd>
                                {' '}
                                <SpaceBetween>
                                    <SecondaryButton onClick={goToChooseSubscription}>
                                        Cancel
                                    </SecondaryButton>
                                    <PrimaryButton htmlType="submit">Create account</PrimaryButton>
                                </SpaceBetween>
                            </SpaceEnd>
                            <Margin value="0 0 40px 0" />
                        </Form>
                    </SignUpPageContainer>
                    {/* <a id="test">test</a> */}
                </SignUpPageWrapper>
            </Spin>
        </>
    );
};

export default SignUpPage;
