import { Form } from 'antd';
import React from 'react';
import ModalTitle from '../modalTitle/ModalTitle';
import { Text, CloseIcon } from '../../../styles/common.styles';
import { blueDark01 } from '../../../styles/colors';
import ModalFooter from '../modalFooter/ModalFooter';
import { UpdateModal } from './UpdateAccountNumberModal.styles';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'update' }) => void;
    reload: (modal: string) => void;
    text: string;
    title: string;
    icon?: string;
}
var parse = require('html-react-parser');
const updateAccountNumberModal: React.FC<Props> = ({ isOpen, closeModal, reload, text, title, icon }) => {
    const handleCancel = () => {
        closeModal({ key: 'update' });
    };

    const onUpdate = () => {
        reload('updateAccountNumber');
    };

    return (
        <UpdateModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            className="delete-modal-container"
            closeIcon={<CloseIcon />}
        >
            <Form onFinish={onUpdate}>
                <ModalTitle title={`${title}`} icon={icon} />
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    {parse(text)}
                </Text>
                <ModalFooter cancel={handleCancel} submit={() => {}} action="Update" />
            </Form>
        </UpdateModal>
    );
};

export default updateAccountNumberModal;
