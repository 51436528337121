import styled from 'styled-components';
import { Spin } from 'antd';
import { blueLink } from '../../styles/colors';

export const AccountChangePasswordButton = styled('div')`
    font-style: normal;
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 13px;
    line-height: 16px;
    color: ${blueLink} !important;
    border: none;
    background-color: transparent;
    cursor: pointer;
`;

export const AccountPageSpin = styled(Spin)`
    top: 0 !important;
`;

export const DownInformationCard = styled('div')`
    margin-top: 40px;
`;
