import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthorizedPage } from '../components/authorizedPage/AuthorizedPage';
import CookiesModal from '../components/modals/cookiesModal/CookiesModal';
import EC3Redirect from '../components/projects/projectActions/ec3Login/EC3Redirect';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import Account from '../pages/account/Account';
import DashboardPage from '../pages/dashboard/Dashboard';
import MFRPage from '../pages/mfrPage/MFRPage';
import MyFavoriteProducts from '../pages/myFavoriteProducts/MyFavoriteProducts';
import NotFoundPage from '../pages/notFound/NotFoundPage';
import OrganizationFavoriteProducts from '../pages/OrganizationFavoriteProducts/OrganizationFavoriteProducts';
import PlanSelection from '../pages/planSelection/PlanSelection';
import ProjectInfo from '../pages/projectInfo/ProjectInfo';
import ProjectLibrary from '../pages/projectLibrary/ProjectLibrary';
import SettingsPage from '../pages/settings/SettingsPage';
import { setShowCookiesModal } from '../redux/reducers/cookiesReducer/CookiesSlice';
import UsersRoute from './UsersRote';
import { RouteName } from './Routes.types';

const CustomerRoutes: React.FC = () => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { cookiesReducer } = selector;
    const closeCookiesModal = () => {
        dispatch(setShowCookiesModal(false));
    };
    return (
        <AuthorizedPage>
            <Switch>
                <Route exact path={RouteName.PROJECT_LIBRARY} component={ProjectLibrary} />
                <Route exact path={RouteName.PROJECT_INFO} component={ProjectInfo} />
                <Route exact path={RouteName.DASHBOARD} component={DashboardPage} />
                <Route exact path={RouteName.HOME} component={DashboardPage} />
                <Route path={RouteName.USERS} component={UsersRoute} />
                <Route exact path={RouteName.ACCOUNT} component={Account} />
                <Route exact path={RouteName.SETTINGS} component={SettingsPage} />
                <Route exact path={RouteName.PRODUCT_INFO} component={MFRPage} />
                <Route
                    exact
                    path={RouteName.OFFICE_FAVORITE}
                    component={OrganizationFavoriteProducts}
                />
                <Route exact path={RouteName.SELECT_PLAN} component={PlanSelection} />
                <Route exact path={RouteName.MY_FAVORITE} component={MyFavoriteProducts} />
                <Route path={RouteName.EC3} component={EC3Redirect} />
                <Route exact path={RouteName.EMPTY_PATH} component={DashboardPage} />
                <Route path={RouteName.ANY_PATH} component={NotFoundPage} />
            </Switch>
            <CookiesModal isOpen={cookiesReducer.show} closeModal={closeCookiesModal} />
        </AuthorizedPage>
    );
};

export default CustomerRoutes;
