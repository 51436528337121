import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SubscriptionTypes } from '../../../../models/subscription/enums';
import { black } from '../../../../styles/colors';
import { BoldText } from '../../../../styles/common.styles';
import { SMAdminUserByIdResponse } from '../../../../redux/actions/smAdminActions/types';
import {
    SubscriptionDetailsSectionWrapper,
    OfficeSubscriptionDetails,
    AddMoreUsersContainer,
    UpdateTrialPeriodContainer,
    UpdateTrialDateContainer,
    AddUsersButton,
    UpdateTrialButton,
    UploadExpiresContainer,
    UploadsDatePickerContainer,
    EditExpirationDateInput,
    CalendarActionContainer,
    EditDatePicker,
    CustomNormalText,
    CustomNormalTextBig,
    CustomBoldText,
    CustomBoldTextBig,
    TrialEndText,
} from './SubscriptionDetailsSection.styles';
import CustomSelect from '../../../../components/common/select/CustomSelect';
import CustomInput from '../../../../components/common/inputs/CustomInput';
import { Input, DatePicker } from 'antd';
import { SelectData } from '../../../../models/common/types';
import { useAppDispatch } from '../../../../hooks/redux';
import { CommonResponse } from '../../../../models/actionResponse/types';
import { alertService } from '../../../../services';
import { buyMoreUsersBySMAdminAction } from '../../../../redux/actions/smAdminActions';
import { buyMoreAssociateUsersBySMAdminAction } from '../../../../redux/actions/smAdminActions';
import { updateTrialPeriodBySMAdminAction } from '../../../../redux/actions/smAdminActions';
import UpdateAccountNumberModal from '../../../../components/common/updateAccountNumberModal/UpdateAccountNumberModal';
import { useModalState } from '../../../../hooks/modalState';
import ErrorModal from '../../../../components/common/errorModal/ErrorModal';
import { dayLeft, toShortLocalDate } from '../../../../helpers/dateHelper';

interface Props {
    user?: SMAdminUserByIdResponse;
    userSubscriptionName: string;
}

const SubscriptionDetailsSection: React.FC<Props> = ({ user, userSubscriptionName }) => {
    const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
    const [isOpenUpdateAssociateModal, setIsOpenUpdateAssociateModal] = useState(false);
    const { isOpen, onOpen, onClose } = useModalState();

    const dispatch = useAppDispatch();

    const [state, setState] = useState<number>(0);
    const [stateAssociate, setStateAssociate] = useState<number>(0);
    const [maxUserCount, setMaxUserCount] = useState<number>(0);
    const [maxAssociateUserCount, setMaxAssociateUserCount] = useState<number>(0);
    const [subscriptionPeriodValue, setSubscriptionPeriodValue] = useState<number>(0);
    const [subscriptionExpiredOn, setSubscriptionExpiredOn] = useState<string>(null);

    const officeTrialSubscriptionType = 'Office(Trial)';

    const isUsingAccountShown = () => {
        return (user?.isMainUser && 
           (userSubscriptionName === SubscriptionTypes.OFFICE || 
            userSubscriptionName === officeTrialSubscriptionType ||
            userSubscriptionName === SubscriptionTypes.FREE_TRIAL_INDIVIDUAL ||
            userSubscriptionName === SubscriptionTypes.FREE_TRIAL_OFFICE
           )
         );
    };

    const isSubscriptionPeriodShown = () => {
        return (user?.isMainUser && 
           (userSubscriptionName === SubscriptionTypes.OFFICE
           )
         );
    };

    const isTrialStartShown = () => {
        return (user?.isMainUser && 
           (userSubscriptionName === officeTrialSubscriptionType ||
            userSubscriptionName === SubscriptionTypes.FREE_TRIAL_INDIVIDUAL ||
            userSubscriptionName === SubscriptionTypes.FREE_TRIAL_OFFICE
           )
         );
    };

    const isTrialEndShown = () => {
        return (user?.isMainUser && 
           (userSubscriptionName === officeTrialSubscriptionType ||
            userSubscriptionName === SubscriptionTypes.FREE_TRIAL_INDIVIDUAL ||
            userSubscriptionName === SubscriptionTypes.FREE_TRIAL_OFFICE
           )
         );
    };

    const isTotalNumberOfAccountsShown = () => {
        return (user?.isMainUser && 
           (userSubscriptionName === SubscriptionTypes.OFFICE || 
            userSubscriptionName === officeTrialSubscriptionType ||
            userSubscriptionName === SubscriptionTypes.FREE_TRIAL_INDIVIDUAL ||
            userSubscriptionName === SubscriptionTypes.FREE_TRIAL_OFFICE
           )
         );
    };

    const isSimpleSubscriptionNameShown = () => {
        return (user?.isMainUser === false &&
           (userSubscriptionName !== SubscriptionTypes.OFFICE &&
            userSubscriptionName !== officeTrialSubscriptionType &&
            userSubscriptionName !== SubscriptionTypes.FREE_TRIAL_INDIVIDUAL &&
            userSubscriptionName !== SubscriptionTypes.FREE_TRIAL_OFFICE
           )
         );
    };

    const convertDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
    };

    const handleMaxAccountNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.target.value);
    };

    const handleMaxAssociateAccountNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateAssociate(event.target.value);
    };

    const handleInputDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubscriptionExpiredOn(event._d);
    };

    const submit = async () => {
        if (state && +state !== user.maxUserCount && user?.id) {
            if (state < 2) {
                alertService.error('Office subscriptions require at least 2 accounts.', { autoClose: true });
                return;
            }
            if (state > 1000) {
                alertService.error('Office subscription may not include more than 1,000 accounts.', { autoClose: true });
                return;
            }
            setIsOpenUpdateModal(true);
        }
        if (subscriptionExpiredOn !== null) {
            updateTrialPeriod();
        }
    };

    const submitAssociate = async () => {
        if (stateAssociate && +stateAssociate !== user.maxAssociateUserCount && user?.id) {
            if (stateAssociate > 1000) {
                alertService.error('Office subscription may not include more than 1,000 accounts.', { autoClose: true });
                return;
            }
            setIsOpenUpdateAssociateModal(true);
        }
        if (subscriptionExpiredOn !== null) {
            updateTrialPeriod();
        }
    };

    const updateTrialPeriod = async () => {
            const { payload } = (await dispatch(
                updateTrialPeriodBySMAdminAction({ subscriptionPeriodValue: subscriptionPeriodValue, subscriptionExpiredOn: subscriptionExpiredOn, subscriptionId: +user?.subscriptionId }),
            )) as CommonResponse;
            if (payload.message) {
                alertService.success('Subscription has been updated', { autoClose: false });
            }

    };

    const updateAccountNumber = async () => {
            const { payload } = (await dispatch(
                buyMoreUsersBySMAdminAction({ usersCount: +state, userId: +user?.id, subscriptionId: +user?.subscriptionId }),
            )) as CommonResponse;
            if (payload.message) {
                alertService.success('Subscription has been updated', { autoClose: false });
            }
            setIsOpenUpdateModal(false);
            user.maxUserCount = state;
            setMaxUserCount(user?.maxUserCount || 0);
            setState(user?.maxUserCount);
    };

    const updateAccountNumberAssociate = async () => {
            const { payload } = (await dispatch(
                buyMoreAssociateUsersBySMAdminAction({ usersCount: +stateAssociate, userId: +user?.id, subscriptionId: +user?.subscriptionId }),
            )) as CommonResponse;
            if (payload.message) {
                alertService.success('Account has been updated', { autoClose: false });
            }
            setIsOpenUpdateAssociateModal(false);
            user.maxAssociateUserCount = stateAssociate;
            setMaxAssociateUserCount(user?.maxAssociateUserCount || 0);
            setStateAssociate(user?.maxAssociateUserCount);
    };

    return (
        <SubscriptionDetailsSectionWrapper>
            { isUsingAccountShown() && userSubscriptionName === SubscriptionTypes.OFFICE && (
                <OfficeSubscriptionDetails>
                    <div>
                      <CustomBoldTextBig>
                        <strong>{userSubscriptionName.replace('(', ' (')}:</strong>
                      </CustomBoldTextBig>
                      <CustomNormalTextBig>
                        {` using `}
                        <strong>{user.paidUserInUse}</strong>
                        {` of `}
                        <strong>{maxUserCount && maxUserCount > 0 ? maxUserCount : user.maxUserCount}</strong>
                        {` Admin / Member accounts and `}
                        <strong>{user.freeUserInUse}</strong>
                        {` of `}
                        <strong>{maxAssociateUserCount && maxAssociateUserCount > 0 ? maxAssociateUserCount : (user.maxAssociateUserCount ? user.maxAssociateUserCount : '0' ) }</strong>
                        {` Associate account(s) `}
                      </CustomNormalTextBig>
                    </div>
                </OfficeSubscriptionDetails>
            )}

            { isUsingAccountShown() && userSubscriptionName !== SubscriptionTypes.OFFICE && (
                <OfficeSubscriptionDetails>
                    <div>
                      <CustomBoldText>
                        <strong>{userSubscriptionName.replace('(', ' (')}:</strong>
                      </CustomBoldText>
                      <CustomNormalText>
                        {` using `}
                        <strong>{user.paidUserInUse}</strong>
                        {` of `}
                        <strong>{maxUserCount && maxUserCount > 0 ? maxUserCount : user.maxUserCount}</strong>
                        {` paid accounts and `}
                        <strong>{user.freeUserInUse}</strong>
                        {` free account(s) `}
                      </CustomNormalText>
                    </div>
                </OfficeSubscriptionDetails>
            )}

            { isSubscriptionPeriodShown() && (
                <OfficeSubscriptionDetails>
                    <div>
                        <CustomBoldTextBig>
                            <strong>{'Subscription period'}: </strong>
                        </CustomBoldTextBig>
                        <CustomNormalTextBig>
                            {convertDate(user?.createdOn)} - {convertDate(user?.expiredOn)}
                        </CustomNormalTextBig>
                    </div>
                </OfficeSubscriptionDetails>
            )}


            { isTrialStartShown() && (
                <OfficeSubscriptionDetails>
                  <div>
                    <CustomBoldText>
                        <strong>{'Trial start'}: </strong>
                    </CustomBoldText>
                    <CustomNormalText>
                        {convertDate(user?.createdOn)}
                    </CustomNormalText>
                  </div>
                </OfficeSubscriptionDetails>
            )}

            { isTrialEndShown() && (
              <OfficeSubscriptionDetails>
               <UpdateTrialDateContainer>
                <TrialEndText size={13} height={16} color={black}>
                    Trial end
                </TrialEndText>
                <UploadExpiresContainer>
                     <UploadsDatePickerContainer className={state.editMode ? 'edit' : ''}>
                            <EditExpirationDateInput
                                value={subscriptionExpiredOn ? convertDate(subscriptionExpiredOn) : convertDate(user?.expiredOn)}
                                onChange={handleInputDate}
//                                className={errors.expirationDate ? 'error' : ''}
                            />
                        <CalendarActionContainer>
                                    <EditDatePicker
                                        onChange={handleInputDate}
                                        allowClear={false}
                                        disabledDate={(current: any) => {
                                            const customDate = moment().format('YYYY-MM-DD');
                                            return (
                                                current &&
                                                current < moment(customDate, 'YYYY-MM-DD')
                                            );
                                        }}
					value={state && state.file && state.file.expiredOn ? moment(state.file.expiredOn) :  null}
//                                        suffixIcon={
//                                            <>
//                                                <Image
//                                                    src="src/assets/images/calendar-day.svg"
//                                                    preview={false}
//                                                    alt="calendar"
//                                                />
//                                            </>
//                                        }
                                    />
                        </CalendarActionContainer>
                    </UploadsDatePickerContainer>
                 </UploadExpiresContainer>
               </UpdateTrialDateContainer>
              </OfficeSubscriptionDetails>
            )}

            { isTotalNumberOfAccountsShown() && (
                <OfficeSubscriptionDetails>
                    <AddMoreUsersContainer>
                         <CustomInput
                             Component={Input}
                             label={'Total Admin / Member accounts'}
                             name="maxUserCount"
                             props={{ onChange: handleMaxAccountNumber }}
                             rules={[
                                    {   required: true, message: 'Value required' },
                                    {
                                        pattern: /^(?:\d*)$/,
                                        message: "Value should contain just number",
                                    },
                                ]}
                             required
                             block
                         />
                        <AddUsersButton onClick={submit}>Update</AddUsersButton>
                    </AddMoreUsersContainer>
                </OfficeSubscriptionDetails>
            )}

            { isTotalNumberOfAccountsShown() && (
                <OfficeSubscriptionDetails>
                    <AddMoreUsersContainer>
                         <CustomInput
                             Component={Input}
                             label={'Total Associate accounts'}
                             name="maxAssociateUserCount"
                             props={{ onChange: handleMaxAssociateAccountNumber }}
                             rules={[
                                    {   required: true, message: 'Value required' },
                                    {
                                        pattern: /^(?:\d*)$/,
                                        message: "Value should contain just number",
                                    },
                                ]}
                             required
                             block
                         />
                        <AddUsersButton onClick={submitAssociate}>Update</AddUsersButton>
                    </AddMoreUsersContainer>
                </OfficeSubscriptionDetails>
            )}

            { isSimpleSubscriptionNameShown() && (
                <OfficeSubscriptionDetails>
                    <BoldText weight={400} size={13} height={16} color={black} font={'Proxima Nova'}>
                        {userSubscriptionName}
                    </BoldText>
                </OfficeSubscriptionDetails>
            )}

           <UpdateAccountNumberModal
                isOpen={isOpenUpdateModal}
                closeModal={() => {
                    setIsOpenUpdateModal(false);
                }}
                reload={updateAccountNumber}
                text={`You are changing this subscription from <b>${user?.maxUserCount}</b> Admin / Member accounts to <b>${state}</b> Admin / Member accounts. If you are decreasing the number of Admin / Member accounts, first delete the accounts who will no longer have paid access to the subscription.`}
                title="Change number of Admin / Member accounts"
                icon="users-gear.svg"
           />

           <UpdateAccountNumberModal
                isOpen={isOpenUpdateAssociateModal}
                closeModal={() => {
                    setIsOpenUpdateAssociateModal(false);
                }}
                reload={updateAccountNumberAssociate}
                text={`You are changing this subscription from <b>${user?.maxAssociateUserCount}</b> Associate accounts to <b>${stateAssociate}</b> Associate accounts. If you are decreasing the number of Associate accounts, first delete the accounts who will no longer have paid access to the subscription.`}
                title="Change number of Associate accounts"
                icon="users-gear.svg"
           />

        </SubscriptionDetailsSectionWrapper>
    );
};

export default SubscriptionDetailsSection;