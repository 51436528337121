import React, { useEffect, useState } from 'react';
import { Banner } from '../../../models/common/types';
import {
    DashboardButton,
    BannerWrapper,
    ContentWrapper,
    PromoWrapper,
    TitleText,
} from './BannerBlock.styles';
import { PromoType } from '../bannerTypes/PromoType.enum';
import { BannerStyleProps } from '../bannerTypes/BannerStyleProps';
import { GreenBanner, TextBanner } from '../bannerTypes/BannerTemplates';
import ReactHtmlParser from 'react-html-parser';

interface Props {
    banner: Banner;
    promoType: PromoType;
}

const BannerBlock: React.FC<Props> = ({ banner, promoType }) => {
    const [theme, setTheme] = useState<BannerStyleProps>();
    const [bannerTextInfo, setBannerTextInfo] = useState<string>('');

    useEffect(() => {
        if (banner.text) {
            setBannerTextInfo(banner.text);
        }
    }, [banner]);

    useEffect(() => {
        switch (promoType) {
            case PromoType.Text:
                setTheme(TextBanner);
                break;
            case PromoType.GreenBlock:
                setTheme(GreenBanner);
                break;
            default:
                setTheme(TextBanner);
        }
    }, [banner]);

    const buttonAction = () => {
        if (!banner.buttonLink) {
            return;
        }

        if (banner.openLinksInTextInNewTab) {
            window.open(banner.buttonLink, '_blank');
        } else {
            window.location.href = banner.buttonLink;
        }
    };

    return (
        <PromoWrapper
            background={`${theme?.background}`}
            titleColor={`${theme?.titleColor}`}
            contentColor={`${theme?.contentColor}`}
            className="promoWrapper"
        >
            <BannerWrapper
                className={`${
                    promoType === PromoType.GreenBlock ? 'green' : 'withoutPadding'
                } banner-wrapper`}
            >
                <TitleText className="banner-title">
                    <a href={banner.headLineLink} target={banner.openLinksInTextInNewTab ? '_blank' : '_self'}>
                        {banner.headLine}
                    </a>
                </TitleText>
                <ContentWrapper className="banner-content-wrapper">
                    {ReactHtmlParser(bannerTextInfo)}
                </ContentWrapper>
                {banner.buttonText && (
                    <DashboardButton onClick={buttonAction}>{banner.buttonText}</DashboardButton>
                )}
            </BannerWrapper>
        </PromoWrapper>
    );
};

export default BannerBlock;
