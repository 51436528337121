import { createAsyncThunk } from '@reduxjs/toolkit';
import { http, httpMultipart } from '../../../helpers/request';
import { UpdateBannerRequest, GetBannersResponse, UploadImageRequest } from './types';
import { ActionResponse } from '../../../models/actionResponse/types';
import { createMultipartData } from '../../../helpers/multipartData';
import { SelectData } from '../../../models/common/types';

const baseUrl = `/banners`;

export const getBannersAction = createAsyncThunk<
    ActionResponse<GetBannersResponse> | undefined,
    undefined
>('banners/getBanners', async () => {
    const result = await http<ActionResponse<GetBannersResponse>, undefined>({
        path: `${baseUrl}`,
        method: 'get',
    });

    console.log('Banners:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateBannerAction = createAsyncThunk<any | undefined, UpdateBannerRequest>(
    'banners/updateBanner',
    async (banner: UpdateBannerRequest) => {
        const result = await http<any, UpdateBannerRequest>({
            path: `${baseUrl}`,
            method: 'put',
            body: banner,
        });
        console.log('updateBanner:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const changeOrderAction = createAsyncThunk<any | undefined, Array<number>>(
    'projects/changeOrder',
    async (data: Array<number>) => {
        const result = await http<any, Array<number>>({
            path: `${baseUrl}/change-order`,
            method: 'post',
            body: data,
        });
        console.log('Changed order result:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const uploadFileAction = createAsyncThunk<any | undefined, UploadImageRequest>(
    'projects/updateProjectInformation',
    async (image: UploadImageRequest) => {
        const data = createMultipartData(image);
        const result = await httpMultipart({
            path: `${baseUrl}/upload-image`,
            method: 'post',
            body: data,
        });

        console.log('Uploaded Image:');
        console.log(result.data);
        return result.data;
    },
);

export const getPromoTypesAction = createAsyncThunk<
    ActionResponse<SelectData> | undefined,
    undefined
>('banners/getPromoTypes', async () => {
    const result = await http<ActionResponse<SelectData>, undefined>({
        path: `${baseUrl}/promo-types`,
        method: 'get',
    });

    console.log('Promo Types:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
