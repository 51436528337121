import styled from 'styled-components';
import { Layout, Typography } from 'antd';

export const ForgotPasswordContainer = styled('div')`
    margin: auto;
    max-width: 405px;
    width: 100%;
    &.back {
        display: flex;
        justify-content: center;
    }
`;

export const ForgotPasswordWrapper = styled(Layout)`
    background-color: transparent;
    height: calc(100vh - 180px);
    overflow-y: auto;
    align-items: center;
    display: flex;
    flex-direction: inherit;
`;

export const ForgotPasswordTitle = styled(Typography.Title)`
    color: rgb(0, 153, 255) !important;
    text-align: left;
    font-family: 'DINNextLTPro';
    font-size: 20px !important;
`;
