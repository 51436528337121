import styled, { css } from 'styled-components';

export const WarningBannerContainer = styled.div<{ FullWidth?: boolean; Clicked?: boolean }>`
    background-color: #f8f2c6;
    display: flex;
    margin: 14px 0 0 20px;
    ${(props: any) =>
        !props.FullWidth &&
        css`
            width: 52%;
            padding: 10px;
        `}
    ${(props: any) =>
        props.FullWidth &&
        css`
            width: 100%;
            padding: 20px 80px;
            margin-top: -11px;
        `}
`;

export const WarningBannerIcon = styled('div')``;

export const WarningBannerText = styled('div')`
    margin: 0 7px;
`;

export const WarningBannerBoldText = styled('span')`
    font-family: 'Proxima N W15 Bold';
    font-weight: normal;
    margin-right: 4px;
`;
