import { Image } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, SpaceStart, Text } from '../../styles/common.styles';

export const MFRPageContainer = styled(PageContainer)`
    padding-top: 0;
    min-width: 1224px;
`;

export const CompanyImageContainer = styled(SpaceStart)`
    img {
        min-width: 80px;
    }
    margin: 30px 0;
    max-width: 225px;
    width: auto;
`;

export const MFRPageContent = styled('div')``;

export const ProductsCount = styled(Text)`
    font-family: 'DINNextLTPro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 40px 0 24px 0;
`;

export const BrandImage = styled(Image)`
`;

export const ReturnToProductTabContainer = styled('div')`
    display: block;
`;

export const ReturnToProductTabLink = styled(NavLink)`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 30px;
`;
