import { Form, Input, Space, Image } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { getAvailableProjectRoles } from '../../../../helpers/projectAccess';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { SelectData } from '../../../../models/common/types';
import { ProjectPermissionTypes } from '../../../../models/permission/enums';
import { MFRProject, ProjectTableFields } from '../../../../models/project/types';
import { sendProjectInvitationAction } from '../../../../redux/actions/projectActions';
import {
    Collaborator,
    GetProjectInformationResponse,
} from '../../../../redux/actions/projectActions/types';
import { alertService } from '../../../../services';
import { PrimaryButton, SpaceEnd } from '../../../../styles/common.styles';
import CustomInput from '../../../common/inputs/CustomInput';
import CustomSelect from '../../../common/select/CustomSelect';
import { CollaborationSpace, InviteTipContainer, RoleTip } from './ShareProject.styles';

interface Props {
    handleCancel: () => void;
    projectRoles: Array<SelectData> | undefined;
    project: ProjectTableFields | GetProjectInformationResponse | MFRProject;
    selectedUser: Collaborator | null;
    collaborators: Array<Collaborator>;
}

const InviteTab: React.FC<Props> = ({
    handleCancel,
    projectRoles,
    project,
    selectedUser,
    collaborators,
}) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { authReducer } = selector;
    const [state, setState] = useState({
        currentRoles: Array<SelectData>(),
        selectedRole: 0,
    });
    useEffect(() => {
        if (selectedUser) {
            form.setFieldsValue({ email: selectedUser.email, projectRoleID: selectedUser.roleId });
            setAvailableRoles(selectedUser.email, selectedUser.roleId);
        }
    }, [selectedUser]);

    useEffect(() => {
        if (projectRoles?.length) {
            const currentRoles = projectRoles;
            setState({
                ...state,
                currentRoles: currentRoles,
            });
        }
    }, [projectRoles]);

    const setAvailableRoles = (email: string, role?: number) => {
        const collaboratorDomainSplit = email.split('@');
        const ownerDomainSplit = authReducer.user?.email.split('@');
        const collaboratorDomain = collaboratorDomainSplit[collaboratorDomainSplit.length - 1];
        if (ownerDomainSplit && projectRoles) {
            const ownerDomain = ownerDomainSplit[ownerDomainSplit?.length - 1];
            const currentCollaborator = collaborators.find(
                (collaborator) => collaborator.email === email,
            );
            const availableRoles = getAvailableProjectRoles(
                ownerDomain,
                collaboratorDomain,
                currentCollaborator,
            );
            const currentRoles = projectRoles.filter((projectRole) =>
                availableRoles.some((availableRole) => projectRole.name === availableRole),
            );
            const selectedRole = role || state.selectedRole;
            if (!currentRoles.find((currentRole) => currentRole.id.toString() === selectedRole.toString())) {
                form.setFieldValue('projectRoleID', undefined);
            }
            if (
                currentRoles.length === 1 &&
                currentRoles[0].name === ProjectPermissionTypes.PROJECT_ADMIN
            ) {
                form.setFieldValue('projectRoleID', currentRoles[0].id);
            }
            setState({
                ...state,
                currentRoles: currentRoles || [],
            });
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (selectedUser) {
            form.setFieldValue('projectRoleID', undefined);
        }
        setAvailableRoles(event.target.value);
    };
    const handleSelectChange = (roleId: number) => {
        setState({ ...state, selectedRole: roleId });
    };

    const onSubmit = async (data: { email: string; projectRoleID: string; note: string }) => {
        if (!data.email || !data.projectRoleID) {
            return;
        }
        const { payload } = await dispatch(
            sendProjectInvitationAction({
                email: data.email,
                roleId: +data.projectRoleID,
                note: data.note,
                projectId: project.id,
            }),
        );
        if (payload) {
            alertService.success('Invitation was sent successfully');
            handleCancel();
        }
    };

    return (
        <Form
            name="basic"
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            onFinish={onSubmit}
            className="invite-form"
            form={form}
        >
            <CollaborationSpace>
                <CustomInput
                    Component={Input}
                    label="Collaborator email"
                    name="email"
                    required
                    block
                    props={{ onChange: handleInputChange }}
                    rules={[
                        { required: true, message: '' },
                        {
                            pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                            message: 'Invalid email',
                        },
                    ]}
                />
                <div id="select-role">
                    <CustomSelect
                        label="Role"
                        name="projectRoleID"
                        options={
                            state.currentRoles?.map((role) => ({
                                ...role,
                                name: role.name.replace('Project', ''),
                            })) || []
                        }
                        placeholder="Select role"
                        props={{ onChange: handleSelectChange }}
                        rules={[{ required: true, message: '' }]}
                        block
                    />
                </div>
                <InviteTipContainer>
                    <Image preview={false} src="src/assets/images/circle-question.svg" />
                    <RoleTip className="tip">
                        <div>
                            <strong>Types of collaborator</strong>
                        </div>
                        <div>
                            Not every role below will be available for every person you invite to <br />{' '}
                            the project.
                        </div>
                        <div>
                            <strong>Commenters</strong> can view or comment on your project. Anyone
                            may have <br /> this role.
                        </div>
                        <div>
                            <strong>Editors</strong> can view, edit, comment on, upload to, export,
                            and copy this
                            <br />
                            project. Editors must be members of your organization with paid <br />{' '}
                            accounts.
                        </div>
                        <div>
                            <strong>Owners</strong> can do everything Editors do, but also control
                            project access <br /> including being able to invite others to the
                            project, delete it, or assign <br />
                            its ownership to another person. Owners must be members of your <br />
                            organization with paid accounts.
                        </div>
                        <div>
                            <strong>Admins</strong> are administrators of your Office subscription.
                            They can do <br /> everything Owners can do, but should still be invited
                            to the project if <br /> you have work for them to do.
                        </div>
                    </RoleTip>
                </InviteTipContainer>
            </CollaborationSpace>
            <Space style={{ width: '100%' }} className="text-area-container">
                <CustomInput
                    Component={TextArea}
                    label="Add a note"
                    name="note"
                    block
                    props={{
                        placeholder: '',
                        rows: 3,
                    }}
                />
            </Space>
            <SpaceEnd>
                <PrimaryButton
                    htmlType="submit"
                    width={127}
                    height={32}
                    disabled={!state.currentRoles.length}
                >
                    Send invitation
                </PrimaryButton>
            </SpaceEnd>
        </Form>
    );
};

export default InviteTab;
