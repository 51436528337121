import styled from 'styled-components';
import { blueDark01, grayLight01, blueLink } from '../../../../../styles/colors';
import { ProductSwitcherContent } from '../../../../../styles/common.styles';

export const SectionItemContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4px;
`;

export const SectionItemDivider = styled('div')`
    border-top: 1px solid ${grayLight01};
    margin: 0 0 0 40px !important;
`;

export const SectionItemTitleContainer = styled('div')`
    display: flex;
    align-items: center;
    &:hover {
        .hover {
            color: ${blueLink};
            cursor: pointer;
        }
        .switch-button {
            background-color: ${blueLink} !important;
            &:after {
                background: white;
            }
            &:before {
                background: white;
            }
        }
    }
`;

export const SectionItemWrapper = styled('div')`
    padding: 16.5px 0;
`;

export const SectionTitle = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 16px;
    line-height: 20px;
    color: ${blueDark01};
    margin-right: 5px;
    &.section-id {
        margin-left: -13.5px;
    }
    &.mfr {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 14px !important;
        line-height: 18px !important;
    }
`;

export const ProductsContainer = styled(ProductSwitcherContent)`
    .product-item-container:first-child {
        border-top: 1px solid ${grayLight01};
    }
    &.clicked:last-child {
        margin-bottom: 40px;
    }
`;

export const MFRTitleContainer = styled('div')`
    display: flex;
    margin: 14px 55px;
    &.sec {
        padding-top: 16.5px;
    }
`;

export const ProductScrollableContainer = styled('div')`
    overflow: visible !important;
`;
