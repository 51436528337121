export interface ProjectInfo {
    projectName: string;
    products: string[];
    installedProducts: string[];
    projectStatus: string;
    projectGroup: string;
    client: string;
    projectLibraryVisibility: string;
    confidentiality: boolean;
    projectId: string;
    buildingType: string;
    grossBuildingArea: string;
    ec3url: string;
}

export enum ProjectStatus {
    Active = 'Active',
    Completed = 'Completed',
    Template = 'Template',
    ManufacturerTemplate = 'Manufacturer Template',
}
