import { RecentlyProjectsTableFields } from '../../models/project/types';
import { ActivitiesName } from '../../models/projectActivity/enums';

export const showEmails = (data: RecentlyProjectsTableFields) => {
    if (ActivitiesName[data.activityName] === ActivitiesName.InvitationAccepted) {
        return false;
    }
    if (data.emailList) {
        return true;
    }
    return false;
};

export const handleActivityName = (data: RecentlyProjectsTableFields) => {
    if (ActivitiesName[data.activityName] === ActivitiesName.ProductAdded) {
        return `added product `;
    }
    if (ActivitiesName[data.activityName] === ActivitiesName.UploadedFile) {
        return 'uploaded file to';
    }
    if (ActivitiesName[data.activityName] === ActivitiesName.UploadedImage) {
        return 'uploaded image to';
    }
    return ActivitiesName[data.activityName];
};

export const attachProductToProject = (
    data: Array<RecentlyProjectsTableFields>,
    products: Array<{ productId: number | undefined; name: string | undefined }>,
) => {
    return data.reduce((agg, item) => {
        if (item.projectProduct) {
            item.projectProduct.name = products.find(
                (p) => p.productId == item.projectProduct?.productId,
            )?.name;
        }
        agg.push(item);
        return agg;
    }, Array<RecentlyProjectsTableFields>());
};
