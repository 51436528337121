import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Image, Input, Radio, RadioChangeEvent, Checkbox, Tooltip } from 'antd';
import { ProductReferences } from '../../../../../redux/actions/productActions/types';
import { blueDark01, blueLink, grayDark05 } from '../../../../../styles/colors';
import { PrimaryButton, BoldText, InvisibleButton, Margin, Text } from '../../../../../styles/common.styles';
import WarningBanner from '../../../../../components/warningBanner/WarningBanner';
import {
    ActionCloseContainer,
    ActionContainer,
    ActionItem,
    ActionsSection,
    BrandSection,
    DateSection,
    ProductItemPropertiesSection,
    ProductItemPropertiesLeftSection,
    PricingError,
    FinishScheduleIdError,
    PricingInputContainer,
    FinishScheduleIdInputContainer,
    ProductItemPropertiesRightSection,
    ProductContainer,
    ProductPropertiesColumnsContainer,
    ProductItemDivider,
    ProductItemLabel,
    ProductItemLabelNoTopMargin,
    ProductNameSection,
    ProductRadioButtonContainer,
    ProductDatesContainer,
    DetailItemsSection,
    ProductDetailsWrapper,
    QuintileInformation,
    EC3Url,
    DescriptionInputContainer,
    FavoriteButton,
    DiscussAndUploadAndCounts,
    TextCounts,
    TextCountsItem,
    ProgramRow,
    TipContainer,
    TipContainerImpactAreas,
    ColorCircle,
    ResultTextAfterCircle,
    PercentageText,
} from './ProductItem.styles';
import ProgramTipsController from '../../../../../components/favoritProducts/favoriteProductItem/productItemHeader/tips/materialPrograms/ProgramTipsController';
import {
    formatRegulationText,
    getColorCircleForResult,
    getListingHeaderBackgroundColor,
    getMasterFormatBackgroundColor,
    getNameOfListingType,
    getPercentileColor,
} from '../../../../../components/favoritProducts/favoriteProductItem/productItemHeader/ProductItemHeaderHelper';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { BrandCompany, FavoriteProduct, Product } from '../../../../../models/product/types';
import {
    changeBasisOfDesignAction,
    changeFinishScheduleIdAction,
    changePricingTypeAction,
    changeSubstitutionAction,
    deleteProductAction,
    switchProductInstalledAction,
    updateDescriptionAction,
    updatePricingAction,
} from '../../../../../redux/actions/productActions';
import {
    addToFavoriteAction,
    removeFromFavoriteAction,
} from '../../../../../redux/actions/favoriteProductsActions';
import { toShortLocalDate } from '../../../../../helpers/dateHelper';
import { removeCentury } from '../../../../../helpers/dateHelper';
import { alertService } from '../../../../../services';
import { useModalState } from '../../../../../hooks/modalState';
import DeleteModal from '../../../../../components/common/deleteModal/DeleteModal';
import { ActionResponse, CommonResponse } from '../../../../../models/actionResponse/types';
import { EC3EmbodiedCarbon, FavoriteType, MasterFormatBackground } from '../../../../../models/product/enums';
import HelpModal from '../../../../../components/helpModal/HelpModal';
import { usePermissions } from '../../../../../hooks/permissions';
import { SubscriptionTypes } from '../../../../../models/subscription/enums';
import { AccountPermissionTypes } from '../../../../../models/permission/enums';
import { GetProjectInformationResponse } from '../../../../../redux/actions/projectActions/types';
import {
    GreenRect,
} from '../../../../../components/favoritProducts/favoriteProductItem/productItemHeader/ProductItemHeader.styles';
import EC3Tip from '../../../../../components/favoritProducts/favoriteProductItem/productItemHeader/tips/ec3Tip/EC3Tip';
import ProductEPDSection from './productEPDSection/ProductEPDSection';
import ActiveOfficeFavoriteIcon from '../../../../../components/common/activeOfficeFavoriteIcon/ActiveOfficeFavoriteIcon';
import OfficePromptModal from '../../../../../components/common/officeFavoritePromptModal/OfficePromptModal';
import MyPromptModal from '../../../../../components/common/myFavoritePromptModal/MyPromptModal';
import BrandInnerData from './BrandInnerData';

const { Option } = Select;

interface Props {
    product: Product;
    references: ProductReferences | null;
    projectInfo: GetProjectInformationResponse | null;
    previousProduct?: Product;
    installedTab?: boolean;
    editable: boolean;
    divider: boolean;
    brandInfo?: BrandCompany;
    lastProduct: boolean;
    updateDivisions: (product: Product, isInstalled?: boolean) => void;
    handleDeleteProduct: (product: Product) => void;
}

const ProductItem: React.FC<Props> = ({
    product,
    projectInfo,
    references,
    previousProduct,
    installedTab,
    editable,
    divider,
    brandInfo,
    lastProduct,
    updateDivisions,
    handleDeleteProduct,
}) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { productReducer } = selector;
    const history = useHistory();

    const [state, setState] = useState({ current: product });
    const [errors, setErrors] = useState({
        pricing: false,
        finishScheduleId: false,
    });
    const { onOpen, onClose, isOpen } = useModalState();
    const { onOpen: openHelp, onClose: closeHelp, isOpen: isHelpOpen } = useModalState();
    const { getAccountRole, getAccountSubscription } = usePermissions();
    const promptModal = useModalState();
    const myPromptModal = useModalState();

    useEffect(() => {
        if (product) {
            setState({ ...state, current: product });
        }
    }, [product]);

    const handleMyFavorite = async () => {
        if (productReducer.pending || getAccountSubscription() === SubscriptionTypes.FREE) {
            return;
        }
        let result;
        if (!product.isMyFavorite) {
            const { payload } = (await dispatch(
                addToFavoriteAction({
                    productId: product.productId,
                    projectId: product.projectId,
                    favoriteType: FavoriteType.MyFavorite,
                }),
            )) as ActionResponse<FavoriteProduct>;
            if (payload.data) {
                product.myFavoriteId = payload.data.id;
                myPromptModal.onToggle();
            }
            result = payload;
        } else {
            if (product.myFavoriteId) {
                const { payload } = (await dispatch(
                    removeFromFavoriteAction({
                        favoriteType: FavoriteType.MyFavorite,
                        productFavoriteId: product.myFavoriteId,
                    }),
                )) as CommonResponse;
                result = payload;
            }
        }
        if (result) {
            updateDivisions({ ...product, isMyFavorite: !product.isMyFavorite });
        }
    };
    const handleOfficeFavorite = async () => {
        if (productReducer.pending) {
            return;
        }
        let result;
        if (!product.isOfficeFavorite) {
            const { payload } = (await dispatch(
                addToFavoriteAction({
                    productId: product.productId,
                    projectId: product.projectId,
                    favoriteType: FavoriteType.OfficeFavorite,
                }),
            )) as ActionResponse<FavoriteProduct>;
            if (payload.data) {
                product.officeFavoriteId = payload.data.id;
                promptModal.onToggle();
            }
            result = payload;
        } else {
            if (product.officeFavoriteId) {
                const { payload } = (await dispatch(
                    removeFromFavoriteAction({
                        favoriteType: FavoriteType.OfficeFavorite,
                        productFavoriteId: product.officeFavoriteId,
                    }),
                )) as CommonResponse;
                result = payload;
            }
        }
        if (result) {
            updateDivisions({ ...product, isOfficeFavorite: !product.isOfficeFavorite });
        }
    };

    const handleBasisOfDesign = async (basisOfDesignId: number) => {
        const currentProduct = state.current;
        currentProduct.basisOfDesignId = basisOfDesignId;
        setState({ ...state, current: currentProduct });
        await dispatch(
            changeBasisOfDesignAction({
                basisOfDesignId,
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
        updateDivisions(currentProduct);
    };

    const handleFinishScheduleId = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const finishScheduleId = event.target.value;
        if (finishScheduleId.length > 50) {
            setErrors({ ...errors, finishScheduleId: true });
            setTimeout(() => {
                setErrors({ ...errors, finishScheduleId: false });
            }, 5000);
            return;
        }
        const currentProduct = state.current;
        currentProduct.finishScheduleId = finishScheduleId;
        setState({ ...state, current: currentProduct });
    };

    const handleSubstitution = async (substitutionTypeId: number) => {
        const currentProduct = state.current;
        currentProduct.substitutionTypeId = substitutionTypeId;
        setState({ ...state, current: currentProduct });
        await dispatch(
            changeSubstitutionAction({
                substitutionsId: substitutionTypeId,
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
        updateDivisions(currentProduct);
    };

    const handleInstalledProduct = async () => {
        const currentProduct = state.current;
        currentProduct.isInstalled = !currentProduct.isInstalled;
        setState({ ...state, current: currentProduct });
        await dispatch(
            switchProductInstalledAction({
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
        updateDivisions(currentProduct, true);
    };

    const handlePricingType = async (event: RadioChangeEvent) => {
        const pricingTypeId = event.target.value || ' ';
        if (pricingTypeId) {
            const currentProduct = state.current;
            currentProduct.pricingTypeId = pricingTypeId;
            setState({ ...state, current: currentProduct });
            await dispatch(
                changePricingTypeAction({
                    pricingTypeId,
                    productId: product.productId,
                    projectId: product.projectId,
                    projectProductId: product.projectProductId,
                }),
            );
            updateDivisions(currentProduct);
        } else {
            alertService.error('Something went wrong');
        }
    };

    const handlePricing = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const pricing = event.target.value;
        if (pricing.length > 75) {
            setErrors({ ...errors, pricing: true });
            setTimeout(() => {
                setErrors({ ...errors, pricing: false });
            }, 5000);
            return;
        }
        const currentProduct = state.current;
        currentProduct.pricing = pricing;
        setState({ ...state, current: currentProduct });
    };

    const savePricing = async () => {
        const currentProduct = state.current;
        await dispatch(
            updatePricingAction({
                pricing: currentProduct.pricing || '',
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
        updateDivisions(currentProduct);
    };

    const saveFinishScheduleId = async () => {
        const currentProduct = state.current;
        await dispatch(
            changeFinishScheduleIdAction({
                finishScheduleId: currentProduct.finishScheduleId || '',
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
        updateDivisions(currentProduct);
    };

    const handleDescription = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const description = event.target.value;
        const currentProduct = state.current;
        currentProduct.description = description;
        setState({ ...state, current: currentProduct });
    };

    const handleDescriptionBlur = (event: any) => {
        event.target.scrollLeft = 0;
        saveDescription();
    }

    const saveDescription = async () => {
        const currentProduct = state.current;
        await dispatch(
            updateDescriptionAction({
                description: currentProduct.description || '',
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
        updateDivisions(currentProduct);
    };

    const deleteProduct = async () => {
        const { payload } = await dispatch(
            deleteProductAction({ productId: product.productId, projectId: product.projectId, projectProductId: product.projectProductId }),
        );
        if (payload) {
            handleDeleteProduct(product);
        }
    };

    const goToMFRPage = async (mode: string) => {
        window.history.replaceState(
            null,
            '',
            `/project-info/${product.product.projectId}/0/${installedTab ? '3' : '2'}?productId=${product.productId
            }`,
        );
        const redir = `/product-info/${product.product.projectId}/${product.productId}/` + `-1/` + (product.product.id ? `${product.product.id}` : `-1`) + (mode ? `/${mode}` : ``) ;
        history.push(redir);
    };

    const goToMFRPageDefault = async () => {
        goToMFRPage('');
    };

    const goToMFRPageComments = async () => {
        goToMFRPage('comments');
    };

    const goToMFRPageDocuments = async () => {
        goToMFRPage('documents');
    };

    const goToMFRPageImages = async () => {
        goToMFRPage('images');
    };

    const getFreeListingClassName = () => {
        if (!brandInfo) {
            return '';
        }

        return brandInfo.level === MasterFormatBackground.FREE_LISTING ? 'freeListing' : '';
    }

    let sameBrand = product.companyID === previousProduct?.companyID;
    if (product.companyID === undefined || product.companyID === null) {
        sameBrand = product.company === previousProduct?.company;
    }

    const productIsNotIncludedWarningText = "Product no longer included in the MFR's Transparency Catalog listing.";

    const notAllowedClassName = 'not-allowed';

//    product.hpdScopeResults = "123";
//    product.hpd = "456";
//    product.perkinsWillPrecautionaryListFree = true;
//    product.livingBuildingChallengeRedListFree = true;
//    product.c2cRestrictedListFree = true;

    return (
        <ProductContainer
            className="product-item-container"
            id={product.product.productId.toString()}
        >
            <BrandSection className={`product-item${product.product.storage === 'OutsideTC' ? '-gray' : ''} ${divider ? 'withBorder' : ''} ${getFreeListingClassName()}`}>
                {!sameBrand && (
                    <BrandInnerData 
                        user={selector.authReducer.user}
                        product={product}
                        productStorage={product.product.storage}
                        brandInfo={brandInfo}
                        sameBrand={sameBrand}
                        openHelp={openHelp}
                    />
                )}
            </BrandSection>
            <ProductDetailsWrapper>
                {product.status !== 'live' && (
                    <WarningBanner Text={productIsNotIncludedWarningText}></WarningBanner>
                )}
                <DetailItemsSection>
                    <ProductNameSection
                        className={`product-item ${lastProduct ? 'withoutBorder' : ''}`}
                    >
                        {product.status === 'live' && product.productUrl && product.productUrl.trim() ? (
                            <a href={product.productUrl} target="_blank">
                                <BoldText weight={400} size={14} height={18} color={blueLink}>
                                    {product.productName}
                                </BoldText>
                            </a>
                        ) : (
                            <BoldText weight={400} size={14} height={18} color={blueDark01}>
                                {product.productName}
                            </BoldText>
                        )}
                      {product.product.storage === 'TC' && (
                        <ProductEPDSection product={product} epdProgram={'IA ' + product.epdProgram} hpd={product.hpd} />
                      )}
                      {product.product.storage === 'TC' && (
                        <QuintileInformation>
                            {' '}

                            {product.epdProgram && (
                                <ProgramRow>
                                <div className="program">
                                        {' '}
                                        {product.epdProgram && (
                                          <>
                                            <a href={product.epdUrl} target="_blank">
                                                {(product.epdType.toLowerCase() === 'industry average' ? 'IA ' : '') + product.epdProgram}
                                            </a>
                                            <TipContainer className="ec3">
                                                <ProgramTipsController
                                                    materialProgram='EpProgram'
                                                    productsTab={true}
                                                />
                                            </TipContainer>
                                          </>
                                        )}
                                </div>
                                </ProgramRow>
                            )}

                            {product.ec3EmbodiedCarbon && (
                                <ProgramRow>
                                    <div className="program">
                                        <>
                                            <GreenRect
                                                className="greenRect"
                                                color={getPercentileColor(
                                                    product.ec3EmbodiedCarbon as EC3EmbodiedCarbon,
                                                )}
                                            />
                                            <PercentageText>{product.ec3EmbodiedCarbon}</PercentageText>
                                            <TipContainer className="ec3">
                                                <ProgramTipsController
                                                    materialProgram='Ec3'
                                                />
                                            </TipContainer>
                                        </>
                                    </div>
                                </ProgramRow>
                            )}

                            {product.declare && (
                                <ProgramRow>
                                    <div className="program">
                                        {' '}
                                        {product.declare && (
                                            <>
                                                <a href={product.declareUrl}>
                                                    {product.declare}
                                                </a>
                                                <TipContainer className="ec3">
                                                    <ProgramTipsController
                                                        materialProgram={product.declare}
                                                    />
                                                </TipContainer>
                                            </>
                                        )}
                                    </div>
                                    <div className="results circle">
                                        {
                                            <ColorCircle
                                                color={getColorCircleForResult(
                                                    product.declareResults as DeclareResults,
                                                )}
                                            />
                                        }
                                        <ResultTextAfterCircle>{product.declareResults}</ResultTextAfterCircle>
                                    </div>
                                </ProgramRow>
                            )}

                            {product.lpc && 
                              (
                                <ProgramRow>
                                    <div className="program">
                                        {' '}
                                        {product.lpc && (
                                            <>
                                                <a href={product.lpcUrl}>
                                                    {product.lpc}
                                                </a>
                                                <TipContainer className="ec3">
                                                    <ProgramTipsController
                                                        materialProgram={product.lpc}
                                                    />
                                                </TipContainer>
                                            </>
                                        )}
                                    </div>
                                    <div className="results circle">
                                        {
                                            <ColorCircle
                                                color={getColorCircleForResult(
                                                    product.lpcResults as DeclareResults,
                                                )}
                                            /> 
                                        }
                                        <ResultTextAfterCircle>{product.lpcResults}</ResultTextAfterCircle>
                                    </div>
                                </ProgramRow>
                            )}
                            {product.c2cMHC && (
                                <ProgramRow className={product.hpd || product.declare ? 'ec3' : ''}>
                                    <div className="program">
                                        {' '}
                                        {product.c2cMHC && (
                                            <>
                                                <a href={product.c2cMHCUrl}>
                                                    {product.c2cMHC}
                                                </a>
                                                <TipContainer className="ec3">
                                                    <ProgramTipsController
                                                        materialProgram={product.c2cMHC}
                                                    />
                                                </TipContainer>
                                            </>
                                        )}
                                    </div>
                                    <div className="results circle">
                                        {
                                            <ColorCircle
                                                color={getColorCircleForResult(
                                                    product.c2cMHCResults as DeclareResults,
                                                )}
                                            />
                                        }
                                       <ResultTextAfterCircle>{product.c2cMHCResults}</ResultTextAfterCircle>
                                    </div>
                                </ProgramRow>
                            )}

                            {((product.hpdScopeResults || product.hpdUrl ) && (
                                  <>
                                    <ProgramRow
                                        className={
                                            product.hpd || product.declare || product.c2cMHC
                                                ? 'ec3'
                                                : ''
                                        }
                                    >
                                        <div className="program">
                                            {' '}
                                            {product.hpd && (
                                                <>
                                                    <a href={product.hpdUrl}>
                                                        {product.hpd}
                                                    </a>
                                                    <TipContainer className="ec3">
                                                        <ProgramTipsController
                                                            materialProgram={product.hpd}
                                                        />
                                                    </TipContainer>
                                                </>
                                            )}
                                        </div>
                                        <div className={`${product.regulations ? 'results' : ''} `}>
                                            {product.regulations && (
                                                    <span>
                                                        {product.regulations.filter((p) => p.indexOf('Perkins&Will') > -1).map((r) => (
                                                            <div>{formatRegulationText(r)}</div>
                                                        ))}
                                                    </span>
                                                )}
                                        </div>
                                    </ProgramRow>

                                    <ProgramRow
                                        className={
                                            product.hpd || product.declare || product.c2cMHC
                                                ? 'ec3'
                                                : ''
                                        }
                                    >
                                            {product.perkinsWillPrecautionaryListFree && (
                                                            <span class='results' style={{marginLeft: 0 + 'px'}}>Perkins&Will PL Free</span>
                                            )}
                                    </ProgramRow>

                                    <ProgramRow
                                        className={
                                            product.hpd || product.declare || product.c2cMHC
                                                ? 'ec3'
                                                : ''
                                        }
                                    >
                                            {product.livingBuildingChallengeRedListFree && (
                                                            <span class='results' style={{marginLeft: 0 + 'px'}}>LBC Red List Free</span>
                                            )}
                                    </ProgramRow>

                                    <ProgramRow
                                        className={
                                            product.hpd || product.declare || product.c2cMHC
                                                ? 'ec3'
                                                : ''
                                        }
                                    >
                                            {product.c2cRestrictedListFree && (
                                                            <span class='results' style={{marginLeft: 0 + 'px'}}>C2C Restricted List Free</span>
                                            )}
                                    </ProgramRow>
       
                                   </>
                                ))}

                            {((product.c2cCertified ) && (
                                    <ProgramRow
                                        className={
                                            product.hpd || product.declare || product.c2cMHC
                                                ? 'ec3'
                                                : ''
                                        }
                                    >
                                        <div className="program">
                                            {' '}
                                            {product.c2cCertified && (
                                                <>
                                                    <a href={product.c2cUrl}>
                                                        {product.c2cCertified}
                                                    </a>
                                                    <TipContainer className="ec3">
                                                        <ProgramTipsController
                                                            materialProgram={product.c2cCertified}
                                                        />
                                                    </TipContainer>
                                                </>
                                            )}
                                        </div>
                                        <div className="results circle">
                                            {
                                                <ColorCircle
                                                    color={getColorCircleForResult(
                                                        product.c2cResults as DeclareResults,
                                                    )}
                                                />
                                            }
                                            <ResultTextAfterCircle>{product.c2cResults}</ResultTextAfterCircle>
                                            {product.regulations && (
                                                <div>
                                                    {product.regulations.map((r) => (
                                                        <div>{formatRegulationText(r)}</div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </ProgramRow>
                                ))}
                            {product.mfrInv && (
                                <ProgramRow
                                    className={
                                        product.hpd || product.c2cCertified || product.c2cMHC
                                            ? 'ec3'
                                            : ''
                                    }
                                >
                                    <div className="program">
                                        {' '}
                                        {product.mfrInv && (
                                            <>
                                                <a href={product.mfrInvUrl}>
                                                    {product.mfrInv}
                                                </a>
                                                <TipContainer className="ec3">
                                                    <ProgramTipsController
                                                        materialProgram={product.mfrInv}
                                                    />
                                                </TipContainer>
                                            </>
                                        )}
                                    </div>
                                    <div className={`${product.mfrInvResults ? 'results' : ''} `}>
                                        {product.mfrInvResults}
                                    </div>
                                </ProgramRow>
                            )}




                        </QuintileInformation>
                      )}
                        <ProductItemLabel weight={400} size={9} height={11} color={grayDark05}>
                            DESCRIPTION
                        </ProductItemLabel>
                        <DescriptionInputContainer>
                            <Tooltip title={product.description}>
                                <Input
                                    disabled={!editable}
                                    value={product.description}
                                    placeholder="Ex: SKU, color, use, etc."
                                    onChange={handleDescription}
                                    onBlur={handleDescriptionBlur}
                                    width={187}
                                />
                            </Tooltip>
                        </DescriptionInputContainer>

                        <DiscussAndUploadAndCounts>
                            <PrimaryButton htmlType="submit" onClick={goToMFRPageDefault} width={105} height={24} style={{marginTop: 16}}>
                                <Text weight={400} size={12} height={11} style={{marginTop: 2}}>
                                    Discuss & upload
                                </Text>
                            </PrimaryButton>
                            <TextCounts weight={400} style={{marginTop: 23}}>
                                {product.commentsCount > 0 && (
                                    <TextCountsItem onClick={goToMFRPageComments} style={{marginLeft: 0}}>
                                        {product.commentsCount +  ' comment' + (product.commentsCount > 1 ? `s` : ``)}
                                    </TextCountsItem>
                                )}
                                {product.documentsCount > 0 && product.documentTypes.filter((dt) => dt.groupName==='Sustainability').length > 0 && (
                                  <>
                                    <TextCountsItem onClick={goToMFRPageDocuments}>
                                        {product.documentsCount +  ' document'  + (product.documentsCount > 1 ? `s` : ``)}
                                    </TextCountsItem>
                                    <TipContainerImpactAreas className="ec3">&nbsp;&nbsp;&nbsp;&nbsp;
                                        <ProgramTipsController
                                            materialProgram='dtImpactAreas'
                                            dataInput={product.documentTypes}
                                        />
                                    </TipContainerImpactAreas>
                                  </>
                                )}
                                {product.imagesCount > 0 && (
                                    <TextCountsItem onClick={goToMFRPageImages}>
                                        {product.imagesCount +  ` image` + (product.imagesCount > 1 ? `s` : ``)}
                                    </TextCountsItem>
                                )}

                            </TextCounts>
                        </DiscussAndUploadAndCounts>

                    </ProductNameSection>

                    <ProductItemPropertiesSection className={`product-item ${lastProduct ? 'withoutBorder' : ''}`}>

                        <ProductDatesContainer>
                          <div>
                            <ProductItemLabelNoTopMargin weight={400} size={9} height={11} color={grayDark05}>
                                DATE ADDED
                            </ProductItemLabelNoTopMargin>
                            <Text weight={400} size={12} height={14} color={blueDark01}>
                                {toShortLocalDate(product.dateAdded)}
                            </Text>
                          </div>
                          <div>
                            <ProductItemLabelNoTopMargin weight={400} size={9} height={11} color={grayDark05}>
                                LAST UPDATED
                            </ProductItemLabelNoTopMargin>
                            <Text weight={400} size={12} height={14} color={blueDark01}>
                                {toShortLocalDate(product.lastUpdated)}
                            </Text>
                          </div>
                        </ProductDatesContainer>

                        <ProductPropertiesColumnsContainer
                           className="product-item-container"
                        >

                          <ProductItemPropertiesLeftSection className={`product-item without-after ${lastProduct ? 'withoutBorder' : 'withoutBorder'}`}>

                            <ProductItemLabel weight={400} size={9} height={11} color={grayDark05}>
                                BASIS OF DESIGN
                            </ProductItemLabel>
                            <Select
                                disabled={!editable}
                                aria-required
                                onChange={handleBasisOfDesign}
                                value={state?.current?.basisOfDesignId}
                                suffixIcon={
                                    <Image
                                        src="src/assets/images/ic-dropdown-arrow.svg"
                                        preview={false}
                                    />
                                }
                            >
                                {references?.allBasisOfDesignType
                                    ? references.allBasisOfDesignType.map((item) => (
                                        <Option style={{fontSize: 12}} value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))
                                    : null}
                            </Select>

                            <Margin value="12px 0 0 0" />

                            <ProductItemLabel weight={400} size={9} height={11} color={grayDark05}>
                                PRICING
                            </ProductItemLabel>
                            <PricingInputContainer>
                                <Input
                                    disabled={!editable}
                                    placeholder="ex: $5-9/sq. ft."
                                    value={product.pricing}
                                    onChange={handlePricing}
                                    onBlur={savePricing}
                                />
                                {errors.pricing && <PricingError>max size 75 characters</PricingError>}
                            </PricingInputContainer>
                            <ProductRadioButtonContainer>
                                <Radio.Group
                                    value={state?.current?.pricingTypeId}
                                    onChange={handlePricingType}
                                    disabled={!editable}
                                >
                                {references?.allPricingType.map((pricingType) => (
                                        <Radio value={pricingType.id} key={pricingType.id}>
                                            {pricingType.name}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </ProductRadioButtonContainer>

                          </ProductItemPropertiesLeftSection>

                          <ProductItemPropertiesRightSection className={`product-item without-after ${lastProduct ? 'withoutBorder' : 'withoutBorder'}`}>

                            <ProductItemLabel weight={400} size={9} height={11} color={grayDark05}>
                                SUBSTITUTIONS
                            </ProductItemLabel>
                            <Select
                                disabled={!editable}
                                aria-required
                                value={state.current.substitutionTypeId}
                                onChange={handleSubstitution}
                                suffixIcon={
                                    <Image
                                        src="src/assets/images/ic-dropdown-arrow.svg"
                                        preview={false}
                                    />
                                }
                            >
                                {references?.allSubstitutionTypes
                                    ? references.allSubstitutionTypes.map((item) => (
                                        <Option style={{fontSize: 12}} value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))
                                    : null}
                            </Select>

                            <ProductItemLabel weight={400} size={9} height={11} color={grayDark05}>
                                FINISH SCHEDULE ID
                            </ProductItemLabel>
                            <FinishScheduleIdInputContainer>
                                <Input
                                    disabled={!editable}
                                    placeholder=""
                                    value={product.finishScheduleId}
                                    onChange={handleFinishScheduleId}
                                    onBlur={saveFinishScheduleId}
                                />
                                {errors.finishScheduleId && <FinishScheduleIdError>max size 50 characters</FinishScheduleIdError>}
                            </FinishScheduleIdInputContainer>

                          </ProductItemPropertiesRightSection>

                        </ProductPropertiesColumnsContainer>

                    </ProductItemPropertiesSection>

                    <ActionsSection
                        className={`product-item ${lastProduct ? 'withoutBorder' : ''}`}
                    >
                        <ActionContainer>
                            <ActionItem>
                                {product.isMyFavorite ? (
                                    <Image
                                        src="src/assets/images/star.svg"
                                        preview={false}
                                        width={16}
                                        style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                                        onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleMyFavorite}
                                    />
                                ) : (
                                    <Image
                                        src="src/assets/images/star-empty.svg"
                                        preview={false}
                                        width={16}
                                        style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                                        onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleMyFavorite}
                                    />
                                )}
                               <FavoriteButton onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleMyFavorite} className="favoriteButtonText"
                                   style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                               >
                                <Text weight={400} size={12} height={14} color={blueLink}>
                                    My Library
                                </Text>
                               </FavoriteButton>
                            </ActionItem>
                            {(getAccountSubscription() === SubscriptionTypes.OFFICE ||
                                getAccountSubscription() ===
                                SubscriptionTypes.FREE_TRIAL_OFFICE) && (
                                    <ActionItem>
                                        {product.isOfficeFavorite ? (
                                            <ActiveOfficeFavoriteIcon
                                                handleOfficeFavorite={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleOfficeFavorite}
                                            />
                                        ) : (
                                            <>
                                                <Image
                                                    src="src/assets/images/star-empty.svg"
                                                    preview={false}
                                                    width={16}
                                                    style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                                                    onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleOfficeFavorite}
                                                />
                                               <FavoriteButton onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleOfficeFavorite} 
                                                 className="favoriteButtonText"
                                                 style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                                               >
                                                <Text
                                                    weight={400}
                                                    size={12}
                                                    height={14}
                                                    color={blueLink}
                                                >
                                                   Office Library
                                                </Text>
                                               </FavoriteButton>
                                            </>
                                        )}
                                    </ActionItem>
                                )}
                            <ActionItem>
                                <Checkbox
                                    name={'installed'}
                                    checked={product.isInstalled}
                                    onChange={handleInstalledProduct}
                                    disabled={!editable}
                                />
                                <Text weight={400} size={12} height={14} color={blueLink}>
                                    Installed
                                </Text>
                            </ActionItem>
                        </ActionContainer>
                        {editable && (
                            <ActionCloseContainer>
                                <InvisibleButton onClick={onOpen}>
                                    <Image
                                        src="src/assets/images/circle-xmark.svg"
                                        preview={false}
                                    />
                                </InvisibleButton>
                            </ActionCloseContainer>
                        )}
                    </ActionsSection>
                    <DeleteModal
                        isOpen={isOpen}
                        closeModal={onClose}
                        icon="building-circle-xmark.svg"
                        title="product"
                        text={product.productName}
                        reload={deleteProduct}
                    />
                    {isHelpOpen && (
                        <HelpModal
                            isOpen={isHelpOpen}
                            handleCancel={closeHelp}
                            project={projectInfo}
                            companyId={product.companyID}
                        />
                    )}
                </DetailItemsSection>
                <OfficePromptModal
                    isOpen={promptModal.isOpen}
                    closeModal={promptModal.onClose}
                    productId={product.officeFavoriteId}
                />
                <MyPromptModal
                    isOpen={myPromptModal.isOpen}
                    closeModal={myPromptModal.onClose}
                    productId={product.myFavoriteId}
                />
            </ProductDetailsWrapper>
        </ProductContainer>
    );
};

export default ProductItem;
