import { PromoType } from '../components/bannerDisplay/bannerTypes/PromoType.enum';
import { SelectData } from '../models/common/types';

export const getCorrectPromoType = (promoTypeId: string, promoTypes: Array<SelectData>) => {
    const promoType = promoTypes.find(
        (type: SelectData) => type.id.toString() === promoTypeId?.toString(),
    );

    switch (promoType?.name) {
        case 'Text (with or without images)':
            return PromoType.Text;
        case 'Green block':
            return PromoType.GreenBlock;
        default:
            return PromoType.Text;
    }
};
