import styled from 'styled-components';
import { Space, Divider } from 'antd';
import { blueDark01, teal01, white } from '../../styles/colors';

export const Container = styled('div')`
    display: flex;
    width: 100%;
    background: ${teal01};
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: auto;
    min-width: 1224px;
    height: 55px;
`;

export const FooterSpace = styled(Space)`
    width: 100%;
    justify-content: start;
    gap: 0 !important;
    padding: 21px 0 24px 100px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: ${white};
    .blue-dark {
        color: ${blueDark01};
    }
    .ant-space-item-split {
        line-height: 16px;
    }
    a {
        color: inherit;
    }
`;

export const FooterSpaceDivider = styled(Divider)`
    border-left: 1px solid ${blueDark01};
`;
