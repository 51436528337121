import React from 'react';
import { ModalButtonContainer, ModalFooterDivider, SubmitModalButton } from './ModalFooter.styles';
import { SecondaryButton } from '../../../styles/common.styles';

interface Props {
    submit: (data?: any) => void;
    cancel: () => void;
    action: string;
    primaryProps?: any;
    cancelProps?: any;
    cancelText?: string | undefined;
    cancelButtonWidth?: number;
    actionButtonWidth?: number;
}

const ModalFooter: React.FC<Props> = ({
    submit,
    cancel,
    action,
    primaryProps,
    cancelProps,
    cancelText,
    cancelButtonWidth = 100,
    actionButtonWidth = 100,
}) => {
    return (
        <>
            <ModalFooterDivider />
            <ModalButtonContainer>
                <SecondaryButton
                    onClick={cancel}
                    width={cancelButtonWidth}
                    height={32}
                    {...cancelProps}
                >
                    {cancelText || 'Cancel'}
                </SecondaryButton>
                {actionButtonWidth > 0 && (
                    <SubmitModalButton
                        htmlType="submit"
                        onClick={submit}
                        {...primaryProps}
                        height={32}
                    >
                        {action}
                    </SubmitModalButton>
                )}
            </ModalButtonContainer>
        </>
    );
};

export default ModalFooter;
