import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    blueDark02,
    blueLink,
    grayDark06,
    grayLight01,
    white,
} from '../../../../styles/colors';
import { PrimaryButton, Text } from '../../../../styles/common.styles';

export const ProjectTeamTabTitle = styled('h2')`
    font-family: 'DINNextLTPro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${blue01};
    margin-bottom: 28px;
`;

export const ProjectTeamText = styled(Text)`
    display: flex;
    gap: 3px;
    margin-bottom: 16px;
`;

export const ProjectTeamMembersList = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
`;

export const ProjectTeamMembersUpdateButtonWrapper = styled.div`
    width: 600px;
    display: flex;
    justify-content: end;
    margin-top: 10px;
`;

export const ProjectTeamMembersListItem = styled.div`
    display: flex;
    gap: 3px;
    padding: 10px 0 ;
    border-bottom: 1px solid ${grayLight01};
    align-items: center;
`;

export const ProjectTeamMembersListItemUsername = styled.a`
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: ${blueLink};
    cursor: pointer;

    &.gray {
        color: gray;
    }

    &:hover {
        color: ${blueLink};
    }
`;

export const ProjectTeamMembersListItemRole = styled.div`
    margin-left: auto;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    width: 125px;
    cursor: pointer;
`;


export const ProjectTeamMembersListItemDropdownButton = styled(PrimaryButton)`
    align-items: center;
    justify-content: space-between;
    background-color: ${white};
    color: ${blueDark02};
    border: 1px solid ${grayDark06};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${white};
        color: ${blueDark01};
        border: 1px solid ${blueDark01};
    }
    &:disabled {
        background-color: ${white};
        color: ${blueDark02};
        border: 1px solid ${blueDark02};
        &:hover {
            color: ${blueDark02};
            background-color: ${white};
            border: 1px solid ${blueDark02};
        }
    }
`;