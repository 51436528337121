import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { changeSubscription, riseStoredUser } from '../../redux/reducers/authReducer/AuthSlice';
import { logOutAction } from '../../redux/actions/authActions';
import { usePermissions } from '../../hooks/permissions';
import { clearState, getAccessToken } from '../../redux/persistedState';
import { SubscriptionTypes } from '../../models/subscription/enums';
import { UserRoleTypes } from '../../models/permission/enums';
import {
    HeaderLayout,
    HeaderLogo,
    HeaderSpace,
    HeaderSpaceDivider,
    HelpLink,
    NavItem,
    NavItemRole,
    TextLink,
} from './Header.styles';
import TrialSection from './trialSection/TrialSection';
import { getCurrentSubscriptionAction } from '../../redux/actions/subscriptionActions';
import { ActionResponse } from '../../models/actionResponse/types';
import { GetCurrentSubscriptionResponse } from '../../redux/actions/subscriptionActions/types';
import { setSubscriptionUpdating } from '../../redux/reducers/subscriptionReducer/SubscriptionSlice';
import { InvisibleButton } from '../../styles/common.styles';
import { teal01 } from '../../styles/colors';
import { Image } from 'antd';
import { setProjectPackageId } from '../../redux/reducers/projectsReducer/ProjectSlice';

const Header: React.FC = () => {
    const selector = useAppSelector((state) => state);
    const { authReducer, subscriptionReducer } = selector;
    const authToken = getAccessToken();
    const dispatch = useAppDispatch();
    const { getUserRole } = usePermissions();
    const [myInterval, setMyInterval] = useState<NodeJS.Timer | undefined>(undefined);

    useEffect(() => {
        if (subscriptionReducer.isSubscriptionUpdating) {
            const interval = setInterval(async () => {
                if (
                    subscriptionReducer.isSubscriptionUpdating &&
                    !authReducer.user?.parentAccountId
                ) {
                    const { payload } = (await dispatch(
                        getCurrentSubscriptionAction(),
                    )) as ActionResponse<GetCurrentSubscriptionResponse>;
                    if (
                        payload.data &&
                        payload.data.subscriptionPlan.name !==
                            authReducer.user?.subscriptionPlanName
                    ) {
                        dispatch(setSubscriptionUpdating(false));
                        dispatch(
                            changeSubscription({
                                subscriptionPlanName: payload.data.subscriptionPlan
                                    .name as SubscriptionTypes,
                                subscriptionPlanId: payload.data.subscriptionPlan.id,
                            }),
                        );
                    }
                }
            }, 15000);
            setMyInterval(interval);
        }
        if (!authReducer.user) {
            clearInterval(myInterval);
            dispatch(setSubscriptionUpdating(false));
        }
    }, [subscriptionReducer.isSubscriptionUpdating, authReducer.user]);

    const logOut = async () => {
        await dispatch(logOutAction());
        clearState();
        dispatch(riseStoredUser());
        dispatch(setProjectPackageId(undefined));
        window.location.href = '/login';
    };

    return (
        <>
            {authToken && getUserRole() === UserRoleTypes.SM_USER && <TrialSection />}
            <HeaderLayout>
                <a href="https://transparencycatalog.com" target="_blank">
                    <HeaderLogo
                        src={'src/assets/images/tc-logo.svg'}
                        preview={false}
                        width={196}
                        height={42}
                    />
                </a>
                {authToken ? (
                    <HeaderSpace
                        direction="horizontal"
                        split={<HeaderSpaceDivider type="vertical" />}
                    >
                        <NavItem>
                            <NavItemRole>
                                {getUserRole() === UserRoleTypes.SM_ADMIN
                                    ? 'SM Admin'
                                    : authReducer.user?.accountRole?.replace('Account', '')}
                            </NavItemRole>
                        </NavItem>
                        {getUserRole() === UserRoleTypes.SM_ADMIN && (
                            <a href="https://dashboard.stripe.com/">
                                Stripe{' '}
                                <Image
                                    preview={false}
                                    src="src/assets/images/arrow-up-right-from-square-teal01.svg"
                                    height={14}
                                    alt="stripe"
                                />
                            </a>
                        )}
                        <NavLink exact to="/account">
                            Account
                        </NavLink>
                        <HelpLink
                            target="_blank"
                            href="https://transparencycatalog.zendesk.com/hc/en-us"
                        >
                            Help
                        </HelpLink>
                        <InvisibleButton onClick={logOut}>
                            <TextLink weight={400} height={16} size={13} color={teal01}>
                                Log out
                            </TextLink>
                        </InvisibleButton>
                    </HeaderSpace>
                ) : (
                    <HeaderSpace direction="horizontal">
                        <h1>
                            <NavLink exact to="/login">
                                Log in
                            </NavLink>
                        </h1>
                    </HeaderSpace>
                )}
            </HeaderLayout>
        </>
    );
};

export default Header;
