import { Divider, Modal, Space, Tabs, Typography } from 'antd';
import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    blueDark02,
    blueLink,
    grayDark06,
    grayLight02,
    white,
} from '../../../../styles/colors';
import { PrimaryButton } from '../../../../styles/common.styles';

export const DocumentTypesModal = styled(Modal)`
    .ant-spin-nested-loading > div > .ant-spin {
        top: 0;
    }
    .ant-modal-content {
        width: 650px;
        border-radius: 6px;
    }
    .invite-form {
        #select-role {
            position: relative;
            display: flex;
            #input-container {
                width: 100%;
            }
            img {
                position: absolute;
                top: -10px;
                left: -100px;
            }
        }
        .text-area-container {
            .ant-space-item {
                width: 100%;
                .ant-form-item-control {
                    min-width: 100%;
                    margin-right: 20px;
                }
            }
            .ant-form-item-label {
                margin-left: 5px;
            }
        }
    }
`;

export const DocumentTypesTableDataContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
`;

export const AccessTabMarkContainer = styled('div')`
    img {
        width: 16px;
    }
`;

export const DocumentTypesTabTableItem = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    .ant-btn-default {
        display: flex;
        justify-content: space-between;
        width: 135px;
    }
    .collaborator-email {
        margin-left: 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: ${blueLink};
        &.gray {
            color: gray;
        }
    }
    .ant-form-item-control-input {
        width: 120px;
    }
    .ant-form-item {
        margin: 0;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding: 0;
    }
    #bold {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
    }
`;

export const InviteTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    .access-tip {
        display: none;
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -70px;
            right: 40px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 400px;
        }
        .access-tip {
            display: block;
            position: absolute;
            top: 30px;
            right: -90px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 202px;
        }
    }
`;

export const RoleTip = styled('div')``;

export const DocumentTypesModalDivider = styled(Divider)`
    border-top: 1px solid ${grayLight02};
    margin-top: 5px;
    margin: 20px 0;
`;

export const AccessActionsButton = styled(PrimaryButton)`
    align-items: center;
    background-color: ${white};
    color: ${blueDark02};
    border: 1px solid ${grayDark06};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${white};
        color: ${blueDark01};
        border: 1px solid ${blueDark01};
    }
    &:disabled {
        background-color: ${white};
        color: ${blueDark02};
        border: 1px solid ${blueDark02};
        &:hover {
            color: ${blueDark02};
            background-color: ${white};
            border: 1px solid ${blueDark02};
        }
    }
`;

export const InviteText = styled('div')`
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 14px;
    font-weight: normal;
    border-bottom: 1px solid ${grayDark06};
`;

export const CollaboratorEmail = styled('div')`
    display: flex;
    gap: 3px;
    align-items: center;
    cursor: pointer;
`;



export const DocumentTypeTextBlock = styled('div')`
    margin: 0 0 8px 0;
    // max-width: 616px;
    width: 100%;
    font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    b {
        font-family: Proxima N W15 Bold;
        font-size: 14px;
        font-weight: normal;
        line-height: 18px;
        text-align: left;
    }
    a {
        font-family: Proxima Nova;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #134FBD;
    }
`;
export const DocumentTypeButtonLinkBlock = styled('div')`
    margin: 0 0 25px 0;
    display: inline-flex;
`;
export const DocumentTypeLinkBlock = styled('div')`
    a {
        font-family: Proxima Nova;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: #134FBD;
    }
`;

export const IaLegend = styled('div')`
   width: 500px;
   heigth: 77px;
   gap: 4px;
   border: 0px 0px 0px 0px;
   opacity: 0px;
   margin: 14px 0 13px 0;
`;

export const DivFlexWithWrap = styled('div')`
    display: flex;
    flex-wrap: wrap;
`;

export const DivImpactArea = styled('div')`
    display: flex;
`;

export const DivImpactAreaImage = styled('div')`
    display: inline;
`;
