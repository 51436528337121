import styled from 'styled-components';
import { Space } from 'antd';
import { grayLight02 } from '../../styles/colors';

export const MailboxContainer = styled('div')`
    .mapboxgl-canvas-container {
        height: 352px;
        min-width: 354px !important;
        @media screen and (max-width: 1400px) {
            width: 352px;
        }
    }
    .mapboxgl-control-container {
        display: none;
    }
    circle {
        fill: rgb(176, 24, 26);
    }
`;

export const CustomSpace = styled(Space)`
    justify-content: space-between;
    width: 100%;
    .ant-select-selector {
        min-width: unset;
    }
    .ant-input {
        min-width: 120px;
    }
    .ant-form-item-label {
        min-width: 36px !important;
    }
    #stateLabel .ant-form-item-label {
        display: none;
    }
    #basic_zip {
        pointer-events: none;
        background-color: ${grayLight02};
    }
    #stateLabel .ant-select {
        pointer-events: none;
    }
    #stateLabel .ant-select-selection-search {
        background-color: ${grayLight02};
        left: 0px;
        right: 0px;
    }
`;
