import React, { useEffect } from 'react';
import LoginForm from '../../components/login/loginForm/LoginForm';
import { LoginCol, LoginPageContainer, LoginRow } from './Login.styles';
import { useHistory } from 'react-router-dom';
import { clearState, getAccessToken } from '../../redux/persistedState';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useManageStore } from '../../hooks/clearStore';

const LoginPage: React.FC = () => {
    const authToken = getAccessToken();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { clearStore } = useManageStore();
    const selector = useAppSelector((combinedState) => combinedState);
    const { authReducer } = selector;

    useEffect(() => {
        if (authToken && authReducer.user) {
            history.push('/dashboard');
        } else {
            dispatch(addPages([{ key: NavigationKey.HOME, value: 'SM Transparency Catalog' }]));
            clearState();
            clearStore();
        }
    }, []);

    return (
        <LoginPageContainer>
            <LoginRow justify="start">
                <LoginCol span={11}>
                    <LoginForm />
                </LoginCol>
            </LoginRow>
        </LoginPageContainer>
    );
};

export default LoginPage;
