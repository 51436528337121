import styled from 'styled-components';
import { InvisibleButton } from '../../../styles/common.styles';

export const OfficeFavoriteTipContainer = styled('div')`
    img {
        opacity: 0.3;
    }
    position: relative;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: 30px;
            right: -150px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 450px;
        }
    }
`;

export const FavoriteButton = styled(InvisibleButton)`
    align-items: flex-start;
    margin-top: 0px;
    padding-bottom: 0px;
    &.favoriteButtonText {
        padding-top: 8px;
    }
`;
