import styled from 'styled-components';
import { Divider, Image, Layout, Space } from 'antd';
import { blueDark01, blueDark02, teal01 } from '../../styles/colors';
import { Text } from '../../styles/common.styles';

const { Header } = Layout;

export const HeaderLayout = styled(Header)`
    display: flex;
    align-items: center;
    padding: 23px 100px 24px 100px;
    height: 82px;
    line-height: 18px;
    background-color: ${blueDark01};
    min-width: 1224px;
    a {
        color: ${teal01};
        &:hover {
            color: #ffffff;
        }
    }
`;

export const HeaderLogo = styled(Image)`
    width: auto;
`;

export const HeaderSpace = styled(Space)`
    width: 100%;
    justify-content: end;
    gap: 0 !important;

    .ant-space-item:not(:first-child) {
        font-size: 13px;
        line-height: 16px;
    }
    h1 {
        font-size: 14px;
        line-height: 16px;
    }

    .ant-space-item-split {
        line-height: 16px;
    }
`;

export const HeaderSpaceDivider = styled(Divider)`
    border-left: 1px solid ${teal01};
`;

export const NavItem = styled('div')`
    color: ${teal01};
`;

export const NavItemRole = styled('span')`
    display: block;
    background-color: ${blueDark02};
    color: ${teal01};
    padding: 3px 5px;
    margin-right: 6px;
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
    border-radius: 1px;
`;

export const TextLink = styled(Text)`
    &:hover {
        color: #ffffff;
    }
`;

export const HelpLink = styled('a')``;
