import styled from 'styled-components';
import { Form } from 'antd';
import { error } from '../../../styles/colors';

export const CustomSelectFormItem = styled(Form.Item)`
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 10px;
        display: flex;
        align-items: center;
    }
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
            .ant-select-customize-input
        ):not(.ant-pagination-size-changer)
        .ant-select-selector {
        border-color: ${error} !important;
    }
    .ant-form-item-explain-error {
        color: ${error} !important;
    }
    #document_type {
        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            margin-left: -4px !important;
        }
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            margin-left: -4px !important;
    }
`;
