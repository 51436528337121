import { Modal, Space, Spin } from 'antd';
import styled from 'styled-components';

export const ExportProjectModal = styled(Modal)`
    width: 680px !important;
    height: 347px !important;

    .ant-modal-body {
        padding: 32px 40px 41px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }
    #csv-link {
        opacity: 0;
        position: absolute;
    }
`;

export const ExportProjectSpace = styled(Space)`
    margin-top: 28px;
    align-items: end;
    width: 100%;
    .ant-space-item:first-child {
        width: 60%;
    }
`;

export const ExportSpin = styled(Spin)`
    top: 0 !important;
`;
