import styled from 'styled-components';
import { blue01, white } from '../../../../styles/colors';

export const FreeListingContainer = styled('div')``;

export const BrandName = styled('h2')`
    font-family: 'DIN Next W01 Medium';
    font-size: 20px;
    line-height: 25px;
    font-weight: 400px;
    margin-top: 60px;
    a {
        color: #3f99f8;
        &:hover {
            opacity: 0.5;
        }
    }
`;

export const FreeListingDescription = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 15px;
    font-family: 'Proxima Nova';
    line-height: 18.75px;
    strong {
        font-family: Proxima N W15 Bold, Arial, Helvetica Neue, Helvetica, sans-serif;
        font-weight: normal;
    }
    a {
        &:hover {
            opacity: 0.5;
        }
    }
`;

export const FreeListingText = styled('div')``;

export const FreeListingContact = styled('div')`
    padding: 8px 11px 0;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.16);
    background: ${blue01};
    color: ${white};
    font-family: 'Proxima N W15 Bold';
    font-weight: 400;
    min-width: 238px;
    height: 100px;
    .title {
        line-height: 22px;
        font-size: 17px;
    }
    .text {
        line-height: 21px;
        font-size: 16px;
    }
    a {
        text-decoration: underline;
        font-family: 'Proxima Nova';
        color: ${white};
        &:hover {
            opacity: 0.5;
        }
    }
    strong {
        font-weight: normal;
    }
`;
