import React from 'react';
import Switcher from '../../../../../components/common/switcher/Switcher';
import { Division } from '../../../../../models/productDivision/types';
import { BrandCompany, Product } from '../../../../../models/product/types';
import { ProductReferences } from '../../../../../redux/actions/productActions/types';
import { blueDark01, blueLink } from '../../../../../styles/colors';
import { ProductSwitcherContent, Text, InvisibleButton } from '../../../../../styles/common.styles';
import SectionItem from '../sectionItem/SectionItem';
import {
    DivisionItemContainer,
    DivisionItemWrapper,
    DivisionTitle,
    DivisionTitleContainer,
    ExportDivisionImage,
    OpenCloseDivisionButton,
} from './DivisionItem.styles';
import { useModalState } from '../../../../../hooks/modalState';
import ExportProject from '../../../../../components/projects/projectActions/exportProject/ExportProject';
import { GetProjectInformationResponse } from '../../../../../redux/actions/projectActions/types';
import { ExportProjectType } from '../../../../../models/project/enums';
import { ProjectReferences } from '../../../../../models/project/types';

interface Props {
    division: Division<Product>;
    references: ProductReferences | null;
    projectReferences: ProjectReferences | null;
    editable: boolean;
    projectInfo: GetProjectInformationResponse | null;
    brandsInfo: Array<BrandCompany>;
    installedTab?: boolean;
    handleClicked: (id: string) => void;
    handleSectionOpen: (divisionId: string, sectionId: string) => void;
    updateDivisions: (product: Product, isInstalled?: boolean) => void;
    handleDeleteProduct: (product: Product) => void;
}
const DivisionItem: React.FC<Props> = ({
    division,
    references,
    projectReferences,
    editable,
    installedTab,
    projectInfo,
    brandsInfo,
    handleClicked,
    handleSectionOpen,
    updateDivisions,
    handleDeleteProduct,
}) => {
    const { isOpen, onToggle } = useModalState();
    const handleDivisionClicked = () => {
        handleClicked(division.id);
    };
    const handleSectionClicked = (sectionId: string) => {
        handleSectionOpen(division.id, sectionId);
    };

    const openCloseDivision = async () => {
        const action = checkWhatActionNeeded();
        openCloseDivisionProcess(action);

    };
    const checkWhatActionNeeded = () => {
        if (!division.clicked) {
            return true;
        }
        for (let j = 0; j < division.sections.length; j++) {
            const section = division.sections[j];
            if (!section.clicked) {
                return true;
            }
        }
        return false;
    }

    const openCloseDivisionProcess = (action: boolean) => {
        if (!division.sections || !division.sections.length) {
            return;
        }
        handleDivisionClicked();
        division.clicked = action;

        for (let j = 0; j < division.sections.length; j++) {
            const section = division.sections[j];
            handleSectionClicked(section.id);
            section.clicked = action;
        }

    }

    return (
        <DivisionItemWrapper className={division.clicked ? 'clicked' : ''}>
            <DivisionItemContainer>
                <DivisionTitleContainer
                >
                    <Switcher
                        id={division.id}
                        clicked={division.clicked}
                        handleSwitch={handleClicked}
                        width={24}
                        height={24}
                        onClick={() => {
                            handleClicked(division.id);
                        }}
                    />
                    <DivisionTitle className="division-id hover"
                        onClick={() => {
                            handleClicked(division.id);
                        }}
                    >
                        {division.id[0] + division.id[1]}
                    </DivisionTitle>
                    <DivisionTitle className="hover"
                        onClick={() => {
                            handleClicked(division.id);
                        }}
                    >{division.name}</DivisionTitle>
                    <Text weight={400} size={20} height={24} color={blueDark01} className="hover"
                        onClick={() => {
                            handleClicked(division.id);
                        }}
                    >
                        (
                        {division.sections.reduce((agg, item) => {
                            agg += item.products.length;
                            return agg;
                        }, 0)}
                        )
                    </Text>
                    <InvisibleButton>
                        <OpenCloseDivisionButton onClick={openCloseDivision} className="closeOpenDivision">
                            <Text weight={400} size={12} height={14} color={blueLink}>
                                Open / close all
                            </Text>
                        </OpenCloseDivisionButton>
                    </InvisibleButton>
                </DivisionTitleContainer>
                {!installedTab && !projectInfo?.isTemplate && (
                    <ExportDivisionImage
                        src="src/assets/images/file-division-export.svg"
                        title="Export division"
                        preview={false}
                        onClick={onToggle}
                    />
                )}
            </DivisionItemContainer>
            <ProductSwitcherContent className={division.clicked ? 'clicked' : ''}>
                {division.sections.map((section, i) => (
                    <SectionItem
                        section={section}
                        key={section.id}
                        editable={editable}
                        references={references}
                        projectInfo={projectInfo}
                        handleClicked={handleSectionClicked}
                        updateDivisions={updateDivisions}
                        handleDeleteProduct={handleDeleteProduct}
                        lastSection={i + 1 === division.sections.length}
                        brandsInfo={brandsInfo}
                        installedTab={installedTab}
                    />
                ))}
            </ProductSwitcherContent>
            {isOpen && (
                <ExportProject
                    isOpen={isOpen}
                    closeModal={onToggle}
                    project={projectInfo}
                    division={division}
                    projectReferences={projectReferences}
                    wrapLinesInDropdown={true}
                    options={[
                        {
                            id: ExportProjectType.DIVISION_ALL_PRODUCTS.toString(),
                            name: `${division.name}, all products`,
                        },
                        {
                            id: ExportProjectType.DIVISION_BASIS_DESIGN.toString(),
                            name: `${division.name}, basis of design only`,
                        },
                        {
                            id: ExportProjectType.INSTALLED_PRODUCTS_DIVISION_ALL_PRODUCTS.toString(),
                            name: `Installed products - ${division.name}, all products`,
                        },
                        {
                            id: ExportProjectType.INSTALLED_PRODUCTS_DIVISION_BASIS_DESIGN.toString(),
                            name: `Installed products - ${division.name}, basis of design only`,
                        },
                    ]}
                    reload={() => {}}
                />
            )}
        </DivisionItemWrapper>
    );
};

export default DivisionItem;
