import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { addPage, removePage } from '../../../redux/reducers/navigationReducer/NavigationSlice';
import { useParams } from 'react-router-dom';
import PageHeader from '../../../components/common/pageHeader/PageHeader';

import { AccountPageSpin } from './Account.styles';
import CustomTabs from '../../../components/common/tabs/CustomTabs';
import { BasicInformation } from '../../components/common/basicInformation/BasicInformation';
import { NavigationKey } from '../../../components/appNavigationBar/AppNavigation.types';
import { UserByIdResponse } from '../../../redux/actions/userActions/types';
import { getUserByIdAction } from '../../../redux/actions/userActions';
import { ActionResponse } from '../../../models/actionResponse/types';

interface Params {
    tab: string;
}

const Account: React.FC = () => {
    const selector = useAppSelector((state) => state);
    const { authReducer } = selector;
    const dispatch = useAppDispatch();
    const params = useParams<Params>();
    const [user, setUser] = useState<UserByIdResponse | undefined>(undefined);

    useEffect(() => {
        getUser();
        dispatch(
            addPage({
                key: NavigationKey.ACCOUNT,
                value: `Account`,
            }),
        );
        return () => {
            dispatch(removePage());
        };
    }, []);

    const getUser = async () => {
        const { payload } = (await dispatch(
            getUserByIdAction(authReducer.user!.id.toString()),
        )) as ActionResponse<UserByIdResponse>;
        if (payload?.data[0]) {
            setUser(payload?.data[0]);
        }
    };

    const tabs = [
        {
            name: 'Basic information',
            tab: <BasicInformation user={user} />,
            key: '1',
        },
    ];

    return (
        <>
            <PageHeader
                title={`${authReducer.user?.firstName} ${authReducer.user?.lastName}’s account`}
            />
            <AccountPageSpin size="large" spinning={authReducer.pending}>
                <div>
                    <CustomTabs tabs={tabs} defaultKey={params?.tab || '1'} />
                </div>
            </AccountPageSpin>
        </>
    );
};

export default Account;
