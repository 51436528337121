import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMultipartData } from '../../../helpers/multipartData';
import { http, httpMultipart } from '../../../helpers/request';
import { ProjectTemplate } from '../../../models/template/types';
import {
    CopyProjectRequest,
    CreateProjectRequest,
    GetProjectLibraryRequest,
    GetProjectInformationResponse,
    SendProjectInviteRequest,
    GetCollaboratorsAction,
    ChangeCollaboratorsRole,
    UpdateProjectRequest,
    ProjectActivitiesResponse,
    RemoveProjectAccessRequest,
    RemoveProjectAccessResponse,
    GetCollaboratorsResponse,
    GetProjectNotificationsRequest,
    SubmitHelpRequest,
    GetCollaboratorsTeamAction,
    UpdateCollaboratorsTeamAction,
} from './types';
import { ActionResponse } from '../../../models/actionResponse/types';
import { SelectData } from '../../../models/common/types';
import {
    AvailableProject,
    MFRProject,
    Project,
    ProjectReferences,
    RecentlyProjectsTableFields,
} from '../../../models/project/types';

const baseUrl = `/projects`;
const referencesUrl = `/references`;

export const getProjectReferencesAction = createAsyncThunk<
    ActionResponse<ProjectReferences> | undefined,
    undefined
>('projects/getProjectReferences', async () => {
    const result = await http<ActionResponse<ProjectReferences>, undefined>(
        {
            path: `${referencesUrl}/projects`,
            method: 'get',
        },
        false,
    );

    console.log('Projects/References:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getProjectTemplatesAction = createAsyncThunk<
    ActionResponse<Array<ProjectTemplate>> | undefined,
    undefined
>('projects/getProjectReferences', async () => {
    const result = await http<ActionResponse<Array<ProjectTemplate>>, undefined>({
        path: `${baseUrl}/all-templates`,
        method: 'get',
    });

    console.log('GetTemplates:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const createProjectAction = createAsyncThunk<
    ActionResponse<Project> | undefined,
    CreateProjectRequest
>('projects/createProject', async (data: CreateProjectRequest) => {
    const result = await http<ActionResponse<Project>, CreateProjectRequest>({
        path: `${baseUrl}`,
        method: 'post',
        body: data,
    });

    console.log('CreateProject:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getProjectLibraryAction = createAsyncThunk<any | undefined, GetProjectLibraryRequest>(
    'projects/getProjectLibrary',
    async (data: GetProjectLibraryRequest) => {
        const result = await http<any, GetProjectLibraryRequest>({
            path: `${baseUrl}/project-list`,
            method: 'post',
            body: data,
        });
        console.log('GetProjectLibrary:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const deleteProjectAction = createAsyncThunk<any | undefined, number>(
    'projects/deleteProject',
    async (id: number) => {
        const result = await http<any, number>({
            path: `${baseUrl}/${id}`,
            method: 'delete',
        });
        console.log('DeleteProject:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const switchProjectStatusAction = createAsyncThunk<any | undefined, number>(
    'projects/switchProjectStatus',
    async (id: number) => {
        const result = await http<any, number>({
            path: `${baseUrl}/switch-status/${id}`,
            method: 'put',
        });
        console.log('ChangeProjectStatus:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const copyProjectAction = createAsyncThunk<
    ActionResponse<GetProjectInformationResponse> | undefined,
    CopyProjectRequest
>('projects/copyProject', async (data: CopyProjectRequest) => {
    const result = await http<ActionResponse<GetProjectInformationResponse>, CopyProjectRequest>(
        {
            path: `${baseUrl}/copy`,
            method: 'post',
            body: data,
        },
        true,
        false,
    );
    console.log('CopyProject:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

const getProjectInformation = async (id: string, copyTokensFromCookiesIfAuthFails: boolean = false) => {
    const result = await http<any, string>({
        path: `/projects/${id}`,
        method: 'get',
    }, true, true, false, copyTokensFromCookiesIfAuthFails);
    console.log('GetProjectInformation1:');
    console.log(result.parsedBody);
    return result.parsedBody;
}

export const getProjectInformationWithCopyTokensOptionAction = createAsyncThunk<
    GetProjectInformationResponse | undefined,
    string
>('projects/getProjectInformation', async (id: string) => {
    const result = await getProjectInformation(id, true);
    return result;
});

export const getProjectInformationAction = createAsyncThunk<
    GetProjectInformationResponse | undefined,
    string
>('projects/getProjectInformation', async (id: string) => {
    const result = await getProjectInformation(id);
    return result;
});

export const getProjectRolesAction = createAsyncThunk<
    ActionResponse<Array<SelectData>> | undefined,
    undefined
>('projects/getProjectRoles', async () => {
    const result = await http<ActionResponse<Array<SelectData>>, string>({
        path: `${baseUrl}/roles`,
        method: 'get',
    });
    console.log('getProjectRoles:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const sendProjectInvitationAction = createAsyncThunk<
    any | undefined,
    SendProjectInviteRequest
>('projects/sendProjectInvitation', async (data: SendProjectInviteRequest) => {
    const result = await http<any, SendProjectInviteRequest>({
        path: `${baseUrl}/share`,
        method: 'post',
        body: data,
    });
    console.log('SendProjectInvitation:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const acceptProjectInvitation = createAsyncThunk<
    any | undefined,
    { email: string; key: string }
>('projects/acceptProjectInvitation', async (data: { email: string; key: string }) => {
    const result = await http<any, { email: string; key: string }>({
        path: `${baseUrl}/share`,
        method: 'post',
        body: data,
    });
    console.log('AcceptProjectInvitation:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getCollaboratorsAction = createAsyncThunk<
    ActionResponse<GetCollaboratorsResponse> | undefined,
    GetCollaboratorsAction
>('projects/getCollaborators', async (data: GetCollaboratorsAction) => {
    const result = await http<ActionResponse<GetCollaboratorsResponse>, GetCollaboratorsAction>({
        path: `${baseUrl}/collaborators`,
        method: 'post',
        body: data,
    });
    console.log('GetCollaborators:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getTeamAction = createAsyncThunk<
    ActionResponse<GetCollaboratorsResponse> | undefined,
    GetCollaboratorsTeamAction
>('projects/getTeam', async (data: GetCollaboratorsTeamAction) => {
    const result = await http<ActionResponse<GetCollaboratorsResponse>, GetCollaboratorsTeamAction>({
        path: `${baseUrl}/team`,
        method: 'post',
        body: data,
    });
    console.log('GetTeamMembers:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateTeamAction = createAsyncThunk<
    ActionResponse<GetCollaboratorsResponse> | undefined,
    UpdateCollaboratorsTeamAction[]
>('projects/getTeam', async (data: UpdateCollaboratorsTeamAction[]) => {
    const result = await http<any, UpdateCollaboratorsTeamAction[]>({
        path: `${baseUrl}/change-team`,
        method: 'post',
        body: data,
    });
    console.log('UpdateTeamMembers:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeCollaboratorsRoleAction = createAsyncThunk<
    any | undefined,
    ChangeCollaboratorsRole
>('projects/changeCollaboratorsRole', async (data: ChangeCollaboratorsRole) => {
    const result = await http<any, ChangeCollaboratorsRole>({
        path: `${baseUrl}/change-collaborators`,
        method: 'post',
        body: data,
    });
    console.log('ChangeCollaboratorsRole:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getRecentlyProjectsAction = createAsyncThunk<
    ActionResponse<Array<RecentlyProjectsTableFields>> | undefined,
    undefined
>('projects/getRecentlyProjects', async () => {
    const result = await http<ActionResponse<Array<RecentlyProjectsTableFields>>, undefined>({
        path: `${baseUrl}/recently-activities`,
        method: 'get',
    });
    console.log('GetRecentlyProjects:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateProjectInformationAction = createAsyncThunk<
    any | undefined,
    UpdateProjectRequest
>('projects/updateProjectInformation', async (project: UpdateProjectRequest) => {
    const data = createMultipartData(project.data);
    const result = await httpMultipart({
        path: `${baseUrl}/${project.projectId}`,
        method: 'put',
        body: data,
    });
    console.log('GetProjectInformation2:');
    console.log(result.data);
    return result.data;
});

export const getProjectActivitiesAction = createAsyncThunk<
    ProjectActivitiesResponse | undefined,
    GetProjectNotificationsRequest
>('projects/getProjectActivities', async (data: GetProjectNotificationsRequest) => {
    const result = await http<ProjectActivitiesResponse, GetProjectNotificationsRequest>({
        path: `${baseUrl}/notifications`,
        method: 'post',
        body: data,
    });
    console.log('GetProjectActivities:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const removeProjectAccessAction = createAsyncThunk<
    RemoveProjectAccessResponse | undefined,
    RemoveProjectAccessRequest
>('projects/removeProjectAccess', async (data: RemoveProjectAccessRequest) => {
    const result = await http<RemoveProjectAccessResponse, RemoveProjectAccessRequest>({
        path: `${baseUrl}/remove-access`,
        method: 'post',
        body: data,
    });
    console.log('RemoveProjectAccess:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getAvailableProjectsAction = createAsyncThunk<
    ActionResponse<Array<AvailableProject>> | undefined,
    undefined
>('projects/getAvailableProjects', async () => {
    const result = await http<ActionResponse<Array<AvailableProject>>, undefined>({
        path: `${baseUrl}/available-projects`,
        method: 'get',
    });
    console.log('GetAvailableProjects:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getProjectForMFRPageAction = createAsyncThunk<
    ActionResponse<MFRProject> | undefined,
    number
>('projects/getProjectForMFRPage', async (id: number) => {
    const result = await http<ActionResponse<MFRProject>, undefined>({
        path: `${baseUrl}/mfr-project/${id}`,
        method: 'get',
    });
    console.log('GetMFRProject:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getHelpItemsAction = createAsyncThunk<ActionResponse<any> | undefined, undefined>(
    'projects/getHelpItems',
    async () => {
        const result = await http<ActionResponse<any>, undefined>({
            path: `${referencesUrl}/help-items`,
            method: 'get',
        });
        console.log('GetHelpItems:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const submitHelpAction = createAsyncThunk<
    ActionResponse<any> | undefined,
    SubmitHelpRequest
>('projects/submitHelp', async (data: SubmitHelpRequest) => {
    const result = await http<ActionResponse<any>, SubmitHelpRequest>({
        path: `${baseUrl}/get-help`,
        method: 'post',
        body: data,
    });
    console.log('SubmitHelp:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
