import { MFRProject } from '../models/project/types';

export const getAddress = (project?: MFRProject) => {
    if (!project) {
        return '-';
    }

    const address = project?.address ?? '';
    const city = project?.city ?? '';
    const state = project?.state === 'NO' ? '' : project?.state;
    const zip = project?.zip ?? '';

    if (!address && !city && !state && !zip) {
        return '-';
    }

    const comma = (address || city) && (state || zip) ? ', ' : '';
    const space1 = address && city ? ' ' : '';
    const space2 = state && zip ? ' ' : '';
    return `${address}` + space1 + `${city}` + comma + `${state}` + space2 + `${zip}`;
};
