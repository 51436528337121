import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import {
    blueDark02,
    blueLink,
    grayDark05,
    grayDark06,
    grayLight01,
    grayLight04,
    grayLight05,
    white,
} from '../../../styles/colors';
import { PrimaryButton, ProductSwitcherContent, BoldText } from '../../../styles/common.styles';

export const ProductCommentsContainer = styled('div')`
    background-color: ${grayLight05};
    padding: 4px 12px;
    border-top: 1px solid ${grayLight01};
`;

export const ProductCommentsSwitcherContainer = styled('div')`
    display: flex;
    align-items: center;
`;

export const CommentsSwitcherTitle = styled(BoldText)`
    margin-left: -20px;
`;

export const CommentsContainer = styled(ProductSwitcherContent)`
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 200ms ease, opacity 0ms ease;
    background-color: ${grayLight05};
    display: none;
    &.clicked {
        padding: 0 35px 48px 12px;
        max-height: 600px;
        opacity: 1;
        transition: max-height 200ms ease, opacity 0ms ease;
        background-color: ${grayLight05};
        display: flex;
    }
    gap: 36px;
    @media screen and (max-width: 650px) {
        display: block;
    }
`;

export const ViewComments = styled('div')`
    width: 75%;
    @media screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const AddComment = styled('div')`
    flex: 1;
    @media screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const DeleteComments = styled('div')`
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
`;

export const CommentsSection = styled('div')`
    height: 318px;
    border: 1px solid ${grayDark06};
    border-radius: 3px;
    background-color: ${white};
    max-height: 265px !important;
    overflow: hidden scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 14px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px 6px ${grayLight04};
        border: solid 4px transparent;
        border-radius: 14px;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 14px 14px ${grayDark05};
        border: solid 4px transparent;
        border-radius: 14px;
    }
    &::-webkit-scrollbar-button {
        display: none;
    }
`;

export const CommentInput = styled(TextArea)`
    margin-top: 32px;
    height: 213px !important;
    border-radius: 3px;
    border: 1px solid ${grayDark06};
`;
export const SaveCommentButton = styled(PrimaryButton)`
    width: 100px;
    height: 32px;
    border: 1px solid ${blueDark02};
    border-radius: 3px;
    margin-top: 20px;
    background-color: ${grayLight05};
    color: ${blueDark02};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${grayLight05};
        border: 1px solid ${blueDark02};
        color: ${blueDark02};
    }
`;
