import { Col, Image, Row, Divider, Input, Form } from 'antd';
import styled from 'styled-components';
import { blueDark01, grayLight01, error } from '../../styles/colors';
import { BlueTitle, BoldText } from '../../styles/common.styles';
import { Text } from '../../styles/common.styles';

export const SignUpPageWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media screen and (max-width: 750px) {
        display: block;
    }
`;

export const SignUpPageContainer = styled('div')`
    padding: 20px 20% 0 20px;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 60%;
    @media screen and (max-width: 1200px) {
        margin: 0;
    }
    @media screen and (max-width: 750px) {
        width: 100%;
    }
`;

export const SignUpImage = styled(Image)`
    position: sticky;
    top: 0;
    @media screen and (max-width: 750px) {
        display: none;
    }
`;

export const SignUpPageLink = styled('div')``;

export const ListContainer = styled('ul')`
    margin-top: 20px;
    li {
        margin: 5px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: ${blueDark01};
    }
`;

export const SignUpRow = styled(Row)`
    .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin: 0;
    }
    width: 100%;
    justify-content: space-between;

    label {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px !important;
        line-height: 16px !important;
        color: ${blueDark01} !important;
        white-space: nowrap;
    }
`;

export const SignUpTitle = styled(BlueTitle)`
    margin-bottom: -2px;
    margin-top: 10px;
`;

export const SignUpCol = styled(Col)`
    width: 48%;
    @media screen and (max-width: 425px) {
        width: 100%;
    }
`;

export const SignUpTipText = styled('span')`
    font-size: 12px;
    font-weight: 400;
    color: ${grayLight01};
    margin-bottom: 10px;
`;

export const ServicesContainer = styled('div')`
    display: flex;
`;

export const ServiceItemContainer = styled('div')`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const ServiceText = styled(BoldText)`
    margin-bottom: 10px;
`;

export const ServiceText1 = styled(Text)`
    margin: 5px 0 10px 0;
`;

export const ServiceName = styled('span')`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
`;

export const SignUpSubscribeContainer = styled('div')`
    margin-top: 15px;
    display: flex;
    gap: 5px;
    align-items: center;
`;

export const SignUpPageDivider = styled(Divider)`
    border-left: 1px solid black;
`;

export const InputLikePassword = styled(Input)`
    &.key {
        font-family: 'password';
    }
`;

export const PasswordFormItem = styled(Form.Item)`
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper:hover {
        border-color: ${error};
    }

    .ant-form-item-explain-error {
        color: ${error};
    }
`;
