import React from 'react';
import { NotificationTitleLabel } from './NotificationTitle.styles';
import { NotificationSection } from '../../../../../../models/projectNotification/types';
import { Activity } from '../../../../../../redux/actions/projectActions/types';
import { ActivitiesName } from '../../../../../../models/projectActivity/enums';

interface Props {
    notification: NotificationSection;
}

const GroupedNotifications = [
    ActivitiesName.CommentAdded,
    ActivitiesName.UploadedFile,
    ActivitiesName.UploadedImage,
    ActivitiesName.ProjectShared,
];
const NotificationTitle: React.FC<Props> = ({ notification }) => {
    const { name, activities } = notification;

    const getNotificationQuantity = () => {
        const totalNumber = activities.authors.reduce((agg, item) => {
            const groupedMap = item.activities.reduce(
                (entryMap, e) =>
                    entryMap.set(e.projectProductId, [
                        ...(entryMap.get(e.projectProductId) || []),
                        e,
                    ]),
                new Map<number | undefined, Array<Activity>>(),
            );
            for (const [_key, value] of groupedMap.entries()) {
                agg += GroupedNotifications.find((n) => n === ActivitiesName[value[0].activityName])
                    ? value.length
                    : 1;
            }
            return agg;
        }, 0);

        return totalNumber;
    };

    return (
        <NotificationTitleLabel className="hover">{`${name} (${getNotificationQuantity()})`}</NotificationTitleLabel>
    );
};

export default NotificationTitle;
