import { Modal } from 'antd';
import styled from 'styled-components';

export const ShowProductsModal = styled(Modal)`
    width: 80vw !important;
    height: 90vh !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
        height: 90%;
    }

    .ant-modal-content {
        border-radius: 6px;
        height: 90%;
    }
`;
