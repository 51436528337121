import { Image, Space } from 'antd';
import React from 'react';
import {
    UsersPageActions,
    UsersFindInput,
    UsersTableCount,
    SearchUsersSpace,
    UnselectedText,
} from './UsersPage.styles';
import { TableData } from '../../models/tableData/types';
import { InvisibleButton } from '../../styles/common.styles';
import { blueLink } from '../../styles/colors';
import { useAppDispatch } from '../../hooks/redux';
import { clearSelectedRows } from '../../redux/reducers/usersReducer/UserSlice';

interface Props {
    tableData: TableData;
    totalCount: number;
    onSearch: () => void;
    handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clearSearchInput: () => void;
}

export const UsersPageSearch: React.FC<Props> = ({
    tableData,
    totalCount,
    onSearch,
    handleSearch,
    clearSearchInput,
}) => {
    const dispatch = useAppDispatch();
    const removeSelection = () => {
        dispatch(clearSelectedRows());
    };
    return (
        <UsersPageActions direction="horizontal">
            <SearchUsersSpace>
                <UsersFindInput
                    placeholder="Find a user by email or name"
                    onChange={handleSearch}
                    onPressEnter={onSearch}
                    value={tableData.searchString}
                    prefix={
                        <>
                            {!tableData.searchString && (
                                <Image
                                    src="src/assets/images/magnifying-glass.svg"
                                    preview={false}
                                />
                            )}
                        </>
                    }
                    suffix={
                        <>
                            {tableData.searchString && (
                                <Image
                                    preview={false}
                                    src="src/assets/images/close-circle-icon.svg"
                                    onClick={clearSearchInput}
                                />
                            )}
                        </>
                    }
                ></UsersFindInput>
                <UsersTableCount>{`${totalCount} Users`}</UsersTableCount>
                <InvisibleButton onClick={removeSelection}>
                    <Space>
                        <UnselectedText weight={400} height={16} size={13} color={blueLink}>
                            Unselect all
                        </UnselectedText>
                    </Space>
                </InvisibleButton>
            </SearchUsersSpace>
        </UsersPageActions>
    );
};
