import styled from 'styled-components';
import { Input, Space } from 'antd';

export const UsersPageActions = styled(Space)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const SearchUsersSpace = styled(Space)`
    gap: 20px !important;
`;

export const UsersFindInput = styled(Input)`
    max-width: 233px;
    height: 32px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    width: 18rem;
    padding: 1px 5px;
`;

export const UsersTableCount = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 14px;
    line-height: 18px;
    color: #000000;
`;
