import React from "react";
import { Text } from '../../../../styles/common.styles';
import { UploadedImageData } from "../UploadsInfo";
import { DownloadButton, LabelContainer, TextContainer } from "./ImageModalReadyOnly.styles";
import { blueDark01 } from "../../../../styles/colors";
import { ButtonsContainer, ButtonsRowContainer } from "./ImageModal.styles";

interface Props {
    image: UploadedImageData;
    onDownloadButtonClicked: () => void;
}

const ImageModalReadOnlyForm: React.FC<Props> = ({ image, onDownloadButtonClicked }) => {
        const getDescription = () => {
        if (!image.description?.length) {
            return <></>;
        }

        return (
            <TextContainer>
                <LabelContainer>
                    <Text weight={400} size={13} height={16} color={blueDark01}>
                        Description
                    </Text>
                </LabelContainer>
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    {image.description}
                </Text>
            </TextContainer>
        );
    }

    return (
        <>
            <TextContainer>
                <LabelContainer>
                    <Text weight={400} size={13} height={16} color={blueDark01}>
                        Image name*
                    </Text>
                </LabelContainer>
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    {image.name}
                </Text>
            </TextContainer>
            {getDescription()}
            <ButtonsRowContainer>
                <ButtonsContainer>
                    <DownloadButton onClick={onDownloadButtonClicked}>Download</DownloadButton>
                </ButtonsContainer>
            </ButtonsRowContainer>
        </>
    );
};

export default ImageModalReadOnlyForm;