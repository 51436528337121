import React from 'react';
import { Comment } from '../../../../models/comment/types';
import { Image, Space } from 'antd';
import {
    CommentContainer,
    CommentContent,
    CommentContentText,
    CommentDivider,
    CommentInformation,
} from './CommentItem.styles';
import { BoldText, InvisibleButton, Text } from '../../../../styles/common.styles';
import { blueDark02, grayDark02, grayDark05 } from '../../../../styles/colors';
import { toShortDate, toShortTime } from '../../../../helpers/dateHelper';
import { usePermissions } from '../../../../hooks/permissions';
import { MFRProject } from '../../../../models/project/types';
import { FavoriteType } from '../../../../models/product/enums';
import { AccountPermissionTypes } from '../../../../models/permission/enums';
import DeleteModal from '../../../common/deleteModal/DeleteModal';
import { useModalState } from '../../../../hooks/modalState';
import InitialAvatar from '../../../common/InitialAvatar/InitialAvatar';

interface Props {
    comment: Comment;
    deleteComment: (commentId: number) => void;
    projectData?: MFRProject;
    favoriteType?: FavoriteType;
    lastItem: boolean;
}

const CommentItem: React.FC<Props> = ({ comment, deleteComment, favoriteType, lastItem }) => {
    const { getAccountRole } = usePermissions();
    const { onOpen, onClose, isOpen } = useModalState();

    const handleCommentDelete = () => {
        deleteComment(comment.id);
    };

    return (
        <>
            <CommentContainer id={comment.id.toString()}>
                <CommentInformation>
                    <Space>
                    <InitialAvatar style={{ paddingTop: 1 }} initial={comment.author[0]} outlined={!(comment.isAccountAdmin || comment.isAccountMember || comment.isAccountAssociate)} />
                        <BoldText weight={400} size={13} height={16} color={blueDark02}>
                            {comment.author}
                        </BoldText>
                        <Text weight={400} size={12} height={14} color={grayDark05}>{`${toShortDate(
                            comment.createdOn,
                        )} | ${toShortTime(comment.createdOn)}`}</Text>
                    </Space>
                    {(favoriteType === FavoriteType.MyFavorite ||
                        getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN) && (
                        <InvisibleButton onClick={onOpen}>
                            <Image
                                src="src/assets/images/trash-grey.svg"
                                preview={false}
                                style={{ cursor: 'pointer' }}
                                alt="delete"
                            />
                        </InvisibleButton>
                    )}
                </CommentInformation>
                <CommentContent>
                    <CommentContentText weight={400} size={13} height={16} color={grayDark02}>
                        {comment.comment}
                    </CommentContentText>
                </CommentContent>
                {isOpen && (
                    <DeleteModal
                        isOpen={isOpen}
                        closeModal={onClose}
                        reload={handleCommentDelete}
                        title="comment"
                        text={'this comment'}
                        icon=""
                    />
                )}
            </CommentContainer>
            <CommentDivider className={lastItem ? 'hide' : ''}></CommentDivider>
        </>
    );
};

export default CommentItem;
