import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SMAdminUserTableFields } from '../../../models/user/smAdminUser';

export const getSMAdminUsersTableActions = (user: SMAdminUserTableFields): Array<ItemType> => {
    return [
        {
            key: 'changeRole',
            label: 'Change account type',
        },
        !user.isManufacturer
            ? {
                  key: 'makePublisher',
                  label: 'Make Publisher',
              }
            : {
                  key: 'removePublisher',
                  label: 'Remove Publisher',
              },
        {
            type: 'divider',
        },
        {
            key: 'delete',
            label: 'Delete user',
        },
    ];
};

export const getSMAdminTableActions = (): Array<ItemType> => {
    return [
        {
            key: 'delete',
            label: 'Delete user',
        },
    ];
};

export const getUserSortExpressionIdByString = (sortExpression: string): number | undefined => {
    const sortExpressionMap: Map<string, number> = new Map([
        ['lastName', 0],
        ['firstName', 1],
        ['email', 2],
        ['roleName', 3],
        ['createdOn', 4],
    ]);
    return sortExpressionMap.get(sortExpression);
};

export const getAdminSortExpressionIdByString = (sortExpression: string): number | undefined => {
    const sortExpressionMap: Map<string, number> = new Map([
        ['lastName', 0],
        ['firstName', 1],
        ['email', 2],
        ['titleRoleName', 3],
        ['subscriptionPlan', 4],
        ['subscriptionStatus', 5],
        ['organizationName', 6],
        ['organizationSizeShortName', 7],
        ['services', 8],
        ['roleName', 9],
        ['createdOn', 10],
        ['lastLoginTime', 11],
    ]);
    return sortExpressionMap.get(sortExpression);
};
