import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { blueDark01 } from '../../../styles/colors';
import { CloseIcon } from '../../../styles/common.styles';
import ModalFooter from '../../common/modalFooter/ModalFooter';
import ModalTitle from '../../common/modalTitle/ModalTitle';
import { FavoriteCSVHeader } from '../../projects/projectActions/exportProject/ExportProject.mockData';
import {
    ExportFavoriteToCSVForm,
    ExportProjectModal,
    ExportSpin,
} from './ExportFavoriteToCSV.styles';
import { Text } from '../../../styles/common.styles';
import { ExternalProduct } from '../../../models/product/types';
import { FavoriteType } from '../../../models/product/enums';
import { useAppSelector } from '../../../hooks/redux';

interface Props {
    isOpen: boolean;
    products: Array<ExternalProduct>;
    favoriteType: FavoriteType;
    closeModal: () => void;
}

interface State {
    exportData: Array<any>;
    fileName: string;
    pending: boolean;
}

const ExportFavoriteToCSV: React.FC<Props> = ({ isOpen, products, favoriteType, closeModal }) => {
    const selector = useAppSelector((combinedState) => combinedState);
    const { authReducer } = selector;

    const [state, setState] = useState<State>({
        exportData: Array<any>(),
        fileName: 'export products',
        pending: false,
    });

    const onSubmit = () => {
        setState({ ...state, pending: true });
        setTimeout(() => {
            const currentProducts = products.map((p) => ({
                ...p,
                masterFormat: `${p.mfSection.number} ${p.mfSection.label}`,
            }));
            setState({ ...state, exportData: currentProducts });
            const link = document.getElementById('csv-link') as any;
            link.click();
            closeModal();
        }, 500);
    };

    return (
        <ExportProjectModal
            open={isOpen}
            onCancel={closeModal}
            closeIcon={<CloseIcon />}
            centered
            footer={null}
        >
            <ExportSpin spinning={state.pending}>
                <ModalTitle title="Export to CSV" icon="ic-modal-title-export.svg" />
                <div>
                    <Text weight={400} size={15} height={20} color={blueDark01}>
                        Export to CSV includes a spreadsheet of product name, manufacturer name, 
                        MasterFormat number, URL to their Transparency Catalog listing page, 
                        optionally added attributes (pricing, BOD, substitutions), and the types 
                        of transparency documentation for that product.
                    </Text>
                    <CSVLink
                        id="csv-link"
                        filename={
                            favoriteType === FavoriteType.MyFavorite
                                ? `${authReducer.user?.firstName} ${authReducer.user?.lastName}'s favorite products`
                                : `${authReducer.user?.office.name}' favorite products`
                        }
                        data={state.exportData}
                        headers={FavoriteCSVHeader}
                    >
                        Download me
                    </CSVLink>
                </div>
                <ExportFavoriteToCSVForm onFinish={onSubmit}>
                    <ModalFooter action="Export" cancel={closeModal} submit={() => {}} />
                </ExportFavoriteToCSVForm>
            </ExportSpin>
        </ExportProjectModal>
    );
};

export default ExportFavoriteToCSV;
