import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/redux';
import { acceptProjectInvitation } from '../../redux/actions/projectActions';
import { alertService } from '../../services';
import { ConfirmationContainer } from '../confirmationEmail/ConfirmationEmail.styles';

const ConfirmInvitation: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    useEffect(() => {
        const email = params.get('email');
        const key = params.get('key');
        acceptInvitation(email, key);
    }, [params]);

    const acceptInvitation = async (email: string | null, key: string | null) => {
        if (!email || !key) {
            history.push('/login');
            alertService.error('Invitation link was corrupted');
            return;
        }
        const { payload } = (await dispatch(acceptProjectInvitation({ email, key }))) as any;
        history.push('/login');
        if (payload) {
            alertService.success('Invitation was accepted');
        } else {
            alertService.error('Invitation link was corrupted or expired');
        }
    };
    return (
        <ConfirmationContainer>
            {' '}
            <Spin size="large" spinning={true}></Spin>
        </ConfirmationContainer>
    );
};

export default ConfirmInvitation;
