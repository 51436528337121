import { Form } from 'antd';
import styled from 'styled-components';
import { grayDark06 } from '../../../styles/colors';
import { Text } from '../../../styles/common.styles';

export const OrganizationInfoContainer = styled('div')`
    margin-top: 30px;
    border-top: 1px solid ${grayDark06};
    padding-top: 30px;
`;
export const OrganizationInfoForm = styled(Form)`
    width: 50%;
    .button-container {
        label {
            display: none;
        }
    }
    .ant-form-item-label {
        min-width: 128px !important;
        label {
        }
    }
    .ant-form-item {
        min-width: 220px !important;
        label {
        }
    }
`;

export const ServiceText = styled(Text)`
    margin: -10px 0 10px 0;
`;

export const ServicesContainer = styled('div')`
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin: 5px 0;
`;
