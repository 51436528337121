import React, { useEffect, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FavoriteProductItemContainer } from './FavoriteProductItem.styles';
import { ProductReferences } from '../../../redux/actions/productActions/types';
import { FavoriteType } from '../../../models/product/enums';
import ProductComments from '../../productInfo/comments/ProductComments';
import ProductPricing from '../../productInfo/pricing/PricingInfo';
import ProductUploads, { UploadType } from '../../productInfo/uploads/UploadsInfo';
import { MergedFavoriteProduct } from '../FavoriteProducts.types';
import FavoriteNoteSection from './favoriteNoteSection/FavoriteNoteSection';
import ProductItemHeader from './productItemHeader/ProductItemHeader';
import { useAppDispatch } from '../../../hooks/redux';
import { getBrandByIdAction } from '../../../redux/actions/transparencyCatalogActions';
import { ActionResponse } from '../../../models/actionResponse/types';
import { BrandCompany } from '../../../models/product/types';
import { isFunctionalityEnabled } from '../../../../config/functionalityAvailability';
import { UPLOAD_PHOTOS } from '../../../../config/paramsConfig';

interface Props {
    product: MergedFavoriteProduct;
    references: ProductReferences | null;
    favoriteType: FavoriteType;
    showProductItemHeader: boolean;
    selectProduct: (product: MergedFavoriteProduct, checked: boolean) => void;
    productsCount: number;
}
const FavoriteProductItem: React.FC<Props> = ({
    product,
    references,
    favoriteType,
    showProductItemHeader,
    selectProduct,
    productsCount,
}) => {
    const dispatch = useAppDispatch();
    const [productWithSpecUrl, setProductWithSpecUrl] = useState({...product, specUrl: undefined});

    useEffect(() => {
        addSpecUrlToProduct();
    }, []);

    const handleProductSelection = (data: CheckboxChangeEvent) => {
        selectProduct(product, data.target.checked);
    };

    const addSpecUrlToProduct = async () => {
        const companiesResponse = (await dispatch(getBrandByIdAction([product.companyID])
        )) as ActionResponse<Array<BrandCompany>>;

        if (!companiesResponse?.payload?.data?.length) {
            return;
        }

        const productCompany = companiesResponse?.payload.data[0];
        if (!productCompany || !productCompany.specs || !product.mfSection) {
            return;
        }

        const section = productCompany.specs.find((spec) => spec.mfSectionNumber === product.mfSection.number);
        if (!section) {
            return;
        }

        setProductWithSpecUrl({
            ...product,
            specUrl: section.specUrl
        });
    }

    return (
        <FavoriteProductItemContainer id={product.product.productId.toString()}>
            <ProductItemHeader
                product={productWithSpecUrl}
                showCheckbox={true}
                productSelected={product.selected}
                handleProductSelection={handleProductSelection}
                productsCount={productsCount}
                showHelp
                showHeader={showProductItemHeader}
            />
            <FavoriteNoteSection
                references={references}
                product={product}
                favoriteType={favoriteType}
            />
            <ProductComments product={product} favoriteType={favoriteType} />
            <ProductPricing product={product} favoriteType={favoriteType} />
            <ProductUploads
                allDocumentTypes={references?.allDocumentTypes}
                product={product}
                favoriteType={favoriteType}
                uploadType={UploadType.DOCUMENT}
            />
            {
                isFunctionalityEnabled(UPLOAD_PHOTOS) &&
                <ProductUploads
                    allDocumentTypes={references?.allDocumentTypes}
                    product={product}
                    favoriteType={favoriteType}
                    uploadType={UploadType.PICTURE}
                />
            }
        </FavoriteProductItemContainer>
    );
};

export default FavoriteProductItem;
