/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import * as Tooltip from '../../../Tooltip';
import * as MaterialProgramTip from '../../MaterialProgramTip';
import { TooltipStyles } from '../../../Tooltip.styles';

const MhcTip: React.FC = () => {
    return (
        <MaterialProgramTip.Container>
            <Tooltip.Container>
                <TooltipStyles className="shadow">
                    <div className="tooltip tooltip-c2c">
                        <h1>Cradle to Cradle Certified Material Health Certificate&trade;</h1>
                        <h2>Program</h2>
                        <p>
                            C2C Certified Material Health Certificate™ is issued to products and
                            materials that have been assessed, optimized and verified for material
                            health in accordance with the C2C Certified Material Health category
                            requirements.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <img src="src/assets/images/verified.png" alt="" />{' '}
                            <b>
                                All C2C Certified Material Health Certificates are third-party
                                verified.
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Companies must work with an{' '}
                            <a
                                href="https://www.c2ccertified.org/get-certified/find-an-assessor"
                                target="_blank"
                            >
                                independent assessment body
                            </a>{' '}
                            to assess and verify a product’s Material Health performance.
                        </p>
                        <h2>Results</h2>
                        <div className="tooltip-content">
                            <div className="tooltip-row">
                                <p>
                                    C2C Certified Material Health Certificates are awarded based
                                    upon four ascending levels of achievement. The certificate must
                                    be renewed every two years with measurable improvement required
                                    at each issuance.
                                </p>
                            </div>
                            <div className="tooltip-row">
                                <div className="circle platinum"></div>
                                <p>
                                    <strong>Platinum</strong>
                                </p>
                            </div>
                            <div className="tooltip-row">
                                <div className="circle gold"></div>
                                <p>
                                    <strong>Gold</strong>
                                </p>
                            </div>
                            <div className="tooltip-row">
                                <div className="circle silver"></div>
                                <p>
                                    <strong>Silver</strong>
                                </p>
                            </div>
                            <div className="tooltip-row">
                                <div className="circle bronze"></div>
                                <p>
                                    <strong>Bronze</strong>
                                </p>
                            </div>
                            <div className="tooltip-row">
                                <p>
                                    Products with active certificates are listed in the publicly
                                    available{' '}
                                    <a
                                        href="https://www.c2ccertified.org/products/mhcregistry"
                                        target="_blank"
                                    />
                                    Material Health Certificate Registry.
                                </p>
                            </div>
                            <div className="tooltip-row">
                                <p>
                                    <a href="https://www.c2ccertified.org/" target="_blank">
                                        Visit the Cradle to Cradle Products Innovation Institute
                                        website to learn more.
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </TooltipStyles>
            </Tooltip.Container>
        </MaterialProgramTip.Container>
    );
};

export default MhcTip;
