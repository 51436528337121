export enum RouteName {
    ACCOUNT = '/account/:tab?',
    ADMINS = '/admins',
    ADMIN_DETAIL = '/admins/edit/:id',
    ADMIN_USER_DETAIL = '/users/edit/:id',
    ANY_PATH = '*',
    CONFIRM_EMAIL = '/ConfirmRegistration',
    CONFIRM_INVITATION = '/InviteToProject',
    CREATE_NEW_PASSWORD = '/create-password',
    DASHBOARD = '/dashboard',
    EC3 = '/ec3',
    EMPTY_PATH = '/',
    FORGOT_PASSWORD = '/forgot-password',
    SUBSCRIPTION = '/subscriptions',
    RESTORE_PASSWORD = '/RestorePassword',
    LOGIN = '/login',
    NOT_FOUND_PAGE = '/404',
    SUCCESS_PAYMENT = '/success',
    SIGN_UP = '/register/:subscriptionId/:usersCount?',
    VERSION = '/version',
    SEARCH_PRODUCT = '/search',
    PROJECT_LIBRARY = '/project-library/:packageId?/:projectId?',
    PROJECT_INFO = '/project-info/:id/:packageId?/:tab?/:share?',
    HOME = '/home',
    USERS = '/users',
    SETTINGS = '/settings',
    PRODUCT_INFO = '/product-info/:projectId/:productId/:commentId?/:projectProductId?/:mode?',
    OFFICE_FAVORITE = '/favorites/:projectId?/:productId?',
    SELECT_PLAN = '/plan',
    MY_FAVORITE = '/my-favorites/:projectId?/:productId?',
    PROMO_BLOCKS = '/promo',
}
