import styled from 'styled-components';
import { Image } from 'antd';
import { blueDark01, blueDark03 } from '../../styles/colors';
import { PrimaryButton } from '../../styles/common.styles';

export const FavoriteProductsActionImage = styled(Image)`
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
    &:hover {
        background-color: ${blueDark03};
    }
    &:focus {
        background-color: ${blueDark01};
    }
    &.disabled {
        opacity: 0.3;
        &:hover {
            background-color: transparent;
        }
    }
`;

export const SearchProductButton = styled(PrimaryButton)``;
