import React from 'react';
import { blueDark01 } from '../../../styles/colors';
import { Text, CloseIcon } from '../../../styles/common.styles';
import ModalFooter from '../modalFooter/ModalFooter';
import ModalTitle from '../modalTitle/ModalTitle';
import { ErrorWarnAlertModalContent, ErrorWarnAlertModalWrapper, ExistingWarnAlertModalListContainer } from './ErrorWarnAlertModal.styles';

interface Props {
    isOpen: boolean;
    type: string;
    title: string;
    topLine: string;
    topLine2: string;
    listStrings: string[];
    listStrings2: string[];
    bottomLine: string;
    bottomLine2: string;
    closeModal: () => void;
}

const ErrorDoubleWarnAlertModal: React.FC<Props> = ({ isOpen, type, title, topLine, topLine2, listStrings, listStrings2, bottomLine, bottomLine2, closeModal }) => {
// type can be: 'error', 'warning'

    if (!listStrings) {
        listStrings = [];
    }

    if (!listStrings2) {
        listStrings2 = [];
    }

    return (
        <ErrorWarnAlertModalWrapper
            open={isOpen}
            onCancel={closeModal}
            centered
            footer={null}
            className="delete-modal-container"
            closeIcon={<CloseIcon />}
        >
            <ModalTitle title={title} icon={type+'.svg'} />
            <ErrorWarnAlertModalContent>
                <Text weight={400} height={18} size={14} color={blueDark01}>
                    {topLine}
                </Text>
                <ExistingWarnAlertModalListContainer>
                    {listStrings.map((listString) => (
                        <li key={listString}>{listString}</li>
                    ))}
                </ExistingWarnAlertModalListContainer>
                <Text weight={400} height={18} size={14} color={blueDark01}>
                    {bottomLine}
                </Text>
                <Text weight={400} height={18} size={14} color={blueDark01}>
                    {topLine2}
                </Text>
                <ExistingWarnAlertModalListContainer>
                    {listStrings2.map((listString) => (
                        <li key={listString}>{listString}</li>
                    ))}
                </ExistingWarnAlertModalListContainer>
                <Text weight={400} height={18} size={14} color={blueDark01}>
                    {bottomLine2}
                </Text>
            </ErrorWarnAlertModalContent>
            <ModalFooter
                submit={closeModal}
                action={'Close'}
                cancel={closeModal}
                cancelProps={{
                    style: { display: 'none' },
                }}
            />
        </ErrorWarnAlertModalWrapper>
    );
};

export default ErrorDoubleWarnAlertModal;
