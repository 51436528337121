import styled from 'styled-components';
import { grayLight04 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common.styles';

export const CommentContainer = styled('div')`
    padding: 12px 12px 12px 7.55px;
`;

export const CommentInformation = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const CommentContent = styled('div')`
    margin-left: 28px;
`;

export const CommentContentText = styled(Text)`
    max-width: 80%;
    word-break: break-word;
    white-space: pre-wrap;
`;

export const CommentDivider = styled('div')`
    border-top: 1px solid ${grayLight04};
    margin-left: 36px;
    &.hide {
        display: none;
    }
`;
