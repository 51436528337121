import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './persistedState';
import userReducer from './reducers/usersReducer/UserSlice';
import smAdminReducer from './reducers/smAdminUsersReducer/SMAdminUserSlice';
import authReducer from './reducers/authReducer/AuthSlice';
import navigationReducer from './reducers/navigationReducer/NavigationSlice';
import subscriptionReducer from './reducers/subscriptionReducer/SubscriptionSlice';
import projectReducer from './reducers/projectsReducer/ProjectSlice';
import productReducer from './reducers/productReducer/ProductSlice';
import favoriteReducer from './reducers/favoriteProductsReducer/FavoriteProductsSlice';
import ec3Reducer from './reducers/ec3Reducer/EC3Slice';
import bannerReducer from './reducers/ec3Reducer/EC3Slice';
import cookiesReducer from './reducers/cookiesReducer/CookiesSlice';

const rootReducer = combineReducers({
    userReducer,
    smAdminReducer,
    authReducer,
    navigationReducer,
    subscriptionReducer,
    projectReducer,
    productReducer,
    favoriteReducer,
    ec3Reducer,
    bannerReducer,
    cookiesReducer,
});

export const setupStore = () => {
    const persistedState = loadState();
    const store = configureStore({
        reducer: rootReducer,
        preloadedState: persistedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
        devTools: process.env.NODE_ENV !== 'production',
    });
    store.subscribe(() => {
        saveState(store.getState());
    });
    return store;
};

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
