import React from 'react';
import {
    Activity,
    GetProjectInformationResponse,
} from '../../../../../redux/actions/projectActions/types';
import { Image } from 'antd';
import { blueDark02, blueLink } from '../../../../../styles/colors';
import {
    ActivityEmailText,
    ActivityEvent,
    AdditionalDataItem,
    AdditionalDataLink,
    AdditionalDataSection,
    AuthorNameText,
    DownloadDocument,
    DownloadDocumentLink,
} from './ProjectActivityItem.styles';
import GenerateActivityText from './GenerateActivityText';
import { ActivitiesName } from '../../../../../models/projectActivity/enums';
import { uploadDocument } from '../../../../../helpers/DOM';
import {
    ResendInvitationSection,
    ResendInvitationTextBox,
    ResendInvitationText,
    ResendInvitationButton,
    ActivityEventItem,
} from '../ProjectNotifications.styles';
import { useAppDispatch } from '../../../../../hooks/redux';
import { sendProjectInvitationAction } from '../../../../../redux/actions/projectActions';
import { alertService } from '../../../../../services';
import { AlertMessages } from '../../../../../components/alert/AlertMessages';
import { usePermissions } from '../../../../../hooks/permissions';
import { ProjectPermissionTypes } from '../../../../../models/permission/enums';
interface Props {
    activities: Array<Activity>;
    projectInfo: GetProjectInformationResponse | null;
    reload: () => void;
}

const ProjectActivityItem: React.FC<Props> = ({ activities, reload, projectInfo }) => {
    const dispatch = useAppDispatch();
    const { getProjectPermission } = usePermissions();

    const resendInvitation = async (email: string, roleId: number, projectId: number) => {
        const { payload } = await dispatch(
            sendProjectInvitationAction({
                projectId,
                email,
                roleId,
                note: '',
            }),
        );
        if (payload) {
            reload();
            alertService.success(AlertMessages.RESEND_INVITATION, { fixed: true });
        }
    };

    let additionalData = null;
    if (ActivitiesName[activities[0].activityName] === ActivitiesName.CommentAdded) {
        additionalData = (
            <AdditionalDataSection>
                {activities.map((a) => (
                    <AdditionalDataItem className="item" key={a.id}>
                        <Image src="src/assets/images/message-lines.svg" preview={false} />
                        <AdditionalDataLink
                            to={`/product-info/${a.projectId}/${a.projectProduct?.productId}/${a.projectProductCommentId}` + (a.projectProduct?.id ? `${a.projectProduct?.id}` : `-1`) }
                        >
                            {a.commentText}
                        </AdditionalDataLink>
                    </AdditionalDataItem>
                ))}
            </AdditionalDataSection>
        );
    }
    if (ActivitiesName[activities[0].activityName] === ActivitiesName.UploadedFile) {
        additionalData = (
            <AdditionalDataSection>
                {activities.map((a) => (
                    <AdditionalDataItem className="item" key={a.id}>
                        <Image src="src/assets/images/file-lines-small.svg" preview={false} />
                        {a.projectProduct.isDeleted ? (
                            <DownloadDocument>
                                {a.projectProductUpload?.documentName}
                            </DownloadDocument>
                        ) : (
                            <DownloadDocumentLink
                                onClick={() => uploadDocument(a.projectProductUpload)}
                            >
                                {a.projectProductUpload?.documentName}
                            </DownloadDocumentLink>
                        )}
                    </AdditionalDataItem>
                ))}
            </AdditionalDataSection>
        );
    }
    if (ActivitiesName[activities[0].activityName] === ActivitiesName.UploadedImage) {
        additionalData = (
            <AdditionalDataSection>
                {activities.map((a) => (
                    <AdditionalDataItem className="item" key={a.id}>
                        <Image src="src/assets/images/file-lines-small.svg" preview={false} />
                        {a.projectProduct.isDeleted ? (
                            <DownloadDocument>
                                {a.projectProductUpload?.documentName}
                            </DownloadDocument>
                        ) : (
                            <DownloadDocumentLink
                                onClick={() => uploadDocument(a.projectProductUpload)}
                            >
                                {a.projectProductUpload?.documentName}
                            </DownloadDocumentLink>
                        )}
                    </AdditionalDataItem>
                ))}
            </AdditionalDataSection>
        );
    }
    if (ActivitiesName[activities[0].activityName] === ActivitiesName.ProjectShared) {
        if (activities.length > 1) {
            additionalData = (
                <AdditionalDataSection>
                    {activities.map((a) => (
                        <>
                            <AdditionalDataItem className="item">
                                <Image
                                    src="src/assets/images/icon-mail.svg"
                                    preview={false}
                                    width={16}
                                    height={12}
                                />
                                <ActivityEmailText>
                                    <a href={`mailto:${a.emailList}`}>
                                        <ActivityEventItem
                                            weight={400}
                                            size={13}
                                            height={16}
                                            color={blueLink}
                                        >
                                            {a.emailList}
                                        </ActivityEventItem>
                                    </a>
                                </ActivityEmailText>
                            </AdditionalDataItem>
                            {a?.expiredInvitation &&
                                getProjectPermission(
                                    [
                                        ProjectPermissionTypes.OWNER,
                                        ProjectPermissionTypes.PROJECT_ADMIN,
                                    ],
                                    projectInfo?.userRole,
                                ) && (
                                    <ResendInvitationSection>
                                        <ResendInvitationTextBox>
                                            <Image
                                                src="src/assets/images/ic-alert.svg"
                                                preview={false}
                                            />
                                            <ResendInvitationText>
                                                This invitation was sent over 7 days ago and has not
                                                yet been accepted.
                                            </ResendInvitationText>
                                        </ResendInvitationTextBox>
                                        <ResendInvitationButton
                                            onClick={() =>
                                                resendInvitation(
                                                    a?.emailList,
                                                    a?.resendInvitationRoleId,
                                                    a?.projectId,
                                                )
                                            }
                                        >
                                            Resend invitation
                                        </ResendInvitationButton>
                                    </ResendInvitationSection>
                                )}
                        </>
                    ))}
                </AdditionalDataSection>
            );
        }
    }
    return (
        <>
            <ActivityEvent>
                <AuthorNameText weight={400} size={14} height={18} color={blueDark02}>
                    {activities[0].authorName}
                </AuthorNameText>
                <GenerateActivityText data={activities} />
            </ActivityEvent>
            {additionalData}
            {activities.length === 1 &&
                activities[0]?.expiredInvitation &&
                getProjectPermission(
                    [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                    projectInfo?.userRole,
                ) && (
                    <ResendInvitationSection className="single">
                        <ResendInvitationTextBox>
                            <Image src="src/assets/images/ic-alert.svg" preview={false} />
                            <ResendInvitationText>
                                This invitation was sent over 7 days ago and has not yet been
                                accepted.
                            </ResendInvitationText>
                        </ResendInvitationTextBox>
                        <ResendInvitationButton
                            onClick={() =>
                                resendInvitation(
                                    activities[0]?.emailList,
                                    activities[0]?.resendInvitationRoleId,
                                    activities[0]?.projectId,
                                )
                            }
                        >
                            Resend invitation
                        </ResendInvitationButton>
                    </ResendInvitationSection>
                )}
        </>
    );
};

export default ProjectActivityItem;
