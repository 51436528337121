export enum ProductFavoriteType {
    MyFavorite = 'myFavorite',
    OfficeFavorite = 'officeFavorite',
}

export enum FavoriteProductAction {
    Favorite = 'favorite',
    Add = 'add',
    Remove = 'remove',
}
