import React, { useEffect, useState } from 'react';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { usePermissions } from '../../../hooks/permissions';
import { getExpireDifferenceInMilliseconds } from '../../../helpers/dateHelper';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { logOutAction } from '../../../redux/actions/authActions';
import { riseStoredUser } from '../../../redux/reducers/authReducer/AuthSlice';
import { clearState } from '../../../redux/persistedState';
import TrialSectionTimer from './TrialSectionTimer';

import { closeBanner } from '../../../redux/reducers/authReducer/AuthSlice';
import { CloseTrialSection, TrialContainer, TrialSpace } from './TrialSection.styles';
import { Space } from 'antd';
import { SecondaryButton } from '../../../styles/common.styles';
import { useHistory } from 'react-router-dom';

const TrialSection: React.FC = () => {
    const { getAccountSubscription } = usePermissions();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const selector = useAppSelector((state) => state);
    const [showTimer, setShowTimer] = useState<boolean>(true);

    const { authReducer } = selector;

    const [trialSection, setTrialSection] = useState({
        text: '',
    });

    const logout = () => {
        dispatch(logOutAction());
        window.location.href = '/login';
        dispatch(riseStoredUser());
        clearState();
    };

    useEffect(() => {
        handleTrialSection();
    }, [authReducer.showBanner, authReducer.user?.subscriptionPlanName]);

    const handleTrialSection = () => {
        let value = trialSection;
        switch (getAccountSubscription()) {
            case SubscriptionTypes.FREE:
                value = { text: 'Upgrade to create Projects and save products' };
                setShowTimer(false);
                break;
            case SubscriptionTypes.FREE_TRIAL_INDIVIDUAL:
            case SubscriptionTypes.FREE_TRIAL_OFFICE:
                value = {
                    text: `Your product trial period will expire in `,
                };
                break;
            default:
                break;
        }
        setTrialSection({ ...trialSection, text: value.text });
    };

    const closeTrialSection = () => {
        dispatch(closeBanner());
    };

    const goToPlanSelection = () => {
        history.push('/plan');
    };

    return (
        <>
            {authReducer.showBanner && (
                <TrialContainer>
                    <TrialSpace>
                        <div></div>
                        <Space>
                            {showTimer ? (
                                <TrialSectionTimer
                                    text={trialSection.text}
                                    expireMilliseconds={getExpireDifferenceInMilliseconds(
                                        authReducer.user?.subscriptionExpired,
                                    )}
                                    terminateSession={logout}
                                />
                            ) : (
                                trialSection.text
                            )}
                            {!authReducer.user?.parentAccountId && (
                                <SecondaryButton onClick={goToPlanSelection}>
                                    Upgrade now
                                </SecondaryButton>
                            )}
                        </Space>
                        <CloseTrialSection onClick={closeTrialSection}>x</CloseTrialSection>
                    </TrialSpace>
                </TrialContainer>
            )}
        </>
    );
};

export default TrialSection;
