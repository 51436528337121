import { SelectData } from '../common/types';
import { AccountPermissionTypes } from '../permission/enums';
import { SubscriptionTypes } from '../subscription/enums';

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    userRoles: Array<UserRole>;
    permission: Array<PermissionItem>;
    accountId: number;
    subscriptionPlanName: SubscriptionTypes;
    subscriptionPlanId: number;
    accountUser: Array<AccountUser>;
    isMemberOfOtherAccount: boolean;
}

export interface StoredUser {
    id: number;
    accountId: number;
    firstName: string;
    lastName: string;
    email: string;
    accountRole: AccountPermissionTypes;
    appRole: string;
    subscriptionPlanId: number | null;
    subscriptionPlanName: SubscriptionTypes | undefined;
    permissions: Array<PermissionItem>;
    parentAccountId: number;
    subscriptionExpired: string;
    organization: SelectData;
    office: SelectData;
    phoneNumber: string;
    usedTrialSubscription: boolean;
}

export interface PermissionItem {
    object: string;
    action: string;
    isAllowed: boolean;
}

interface UserRole {
    role: Role;
}

interface Role {
    name: string;
}

interface AccountUser {
    accountId: number;
    accountUserId: number;
    role: AccountRole;
    subscription: SubscriptionItem;
}

interface AccountRole {
    id: number;
    name: AccountPermissionTypes;
}

interface SubscriptionItem {
    id: number;
    name: SubscriptionTypes;
}

export interface BaseUserTableFields {
    lastName: string;
    firstName: string;
    email: string;
    key: string;
    id: number;
}

export interface UserTableFields extends BaseUserTableFields {
    projectBuilderRoleName: string;
    accountCreated: string;
    isMemberOfOtherAccount: boolean;
    accountRole: string;
}

export interface TokenModel {
    accessToken: string;
    refreshToken: string;
}

export interface NotificationItem {
    id: number;
    name: string;
    notificationSettingId: number;
    sendEmailDigest: boolean;
    sendEmailMeEachTime: boolean;
}

export interface SalesforceLead {
    email: string;
    firstName: string;
    lastName: string;
    organizationName: string;
}

export class TableUserData implements UserTableFields {
    isMemberOfOtherAccount = false;
    accountRole = '';
    lastName = '';
    firstName = '';
    email = '';
    projectBuilderRoleName = '';
    accountCreated = '';
    lastLoginTime = null;
    key = '';
    id = 0;
}
