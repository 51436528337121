import React, { FC, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { usePermissions } from '../../hooks/permissions';
import { AccountPermissionTypes } from '../../models/permission/enums';
import { SubscriptionTypes } from '../../models/subscription/enums';

export const OfficeSubscriptionRoute: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { getAccountRole, getAccountSubscription, getUserRole } = usePermissions();

    if (
        ((getAccountSubscription() === SubscriptionTypes.OFFICE ||
            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE) &&
            getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN) ||
        getUserRole() === 'SMAdmin'
    ) {
        return <Fragment>{children}</Fragment>;
    } else {
        return <Redirect to={{ pathname: '/404' }} />;
    }
};
