export enum AccountPermissionTypes {
    ACCOUNT_ADMIN = 'AccountAdmin',
    ACCOUNT_USER = 'AccountMember',
    ACCOUNT_ASSOCIATE = 'AccountAssociate',
//    ACCOUNT_FREE = 'AccountAssociate',
}

export enum ProjectPermissionTypes {
    OWNER = 'Owner',
    COMMENTER = 'Commenter',
    EDITOR = 'Editor',
    MANUFACTURER = 'Manufacturer',
    PROJECT_ADMIN = 'ProjectAdmin',
}

export enum UserRoleTypes {
    SM_USER = 'SMUser',
    SM_ADMIN = 'SMAdmin',
}
