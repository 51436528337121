import styled from 'styled-components';
import { blueDark01 } from '../../../../../styles/colors';
import { Text } from '../../../../../styles/common.styles';

export const ExportContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    gap: 20px;
`;

export const ExportRowName = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 14px;
    line-height: 16px;
    color: ${blueDark01};
    margin-bottom: 14px;
`;

export const ExportRowValue = styled('div')`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${blueDark01};
    margin-bottom: 14px;
`;

export const ExportColumn = styled('div')``;

export const ExportColumnName = styled('div')`
    text-align: right;
`;

export const ExportErrorText = styled(Text)`
    span {
        color: ${blueDark01};
    }
`;
