import { Divider } from 'antd';
import styled from 'styled-components';
import { ProductHeaderSpaceProps } from '../../../../models/styles/types';
import { blue01, blueDark01, black, blueLink, white, blueDark02 } from '../../../../styles/colors';
import { BaseLink, Text } from '../../../../styles/common.styles';
import '../../../../assets/images/icon-tooltip-darker.svg';

export const GridHead = styled('div')``;

export const GreenRect = styled('span')`
    width: 15px;
    height: 8px;
    background-color: ${(props: { color: string }) => props.color};
    margin-right: 5px;
    color: ${blueDark01};
    display: inline-block;
`;

export const ColorCircle = styled('span')`
    border-radius: 5px;
    font-weight: 400;
    background-color: ${(props: { color: string }) => props.color};
    display: inline-block;
    height: 9px;
    margin-right: 0.4em;
    margin-top: 1.5px;
    padding: 0;
    width: 9px;
`;

export const ScopeResultContainer = styled('div')`
    width: 100%;
`;

export const ScopeResultDivider = styled(Divider)`
    margin: 0;
`;

export const ProgramDivider = styled(Divider)`
    margin: 0;
`;

export const TipContainer = styled('span')`
    text-transform: initial;
    white-space: normal;
    background: url(src/assets/images/icon-tooltip_darker.svg) no-repeat 0 0;
    background-size: 10px 10px;
    float: right;
    height: 14px;
    margin: -1px 2px 0 0;
    width: 14px;
    cursor: pointer;
    .ant-image {
        display: flex;
    }
    img {
        cursor: pointer;
    }
    .program-tip {
        display: none;
        &:hover {
            display: block;
        }
    }
    .tooltip-ec3 {
        display: none;
        &:hover {
            display: block;
        }
    }
    &:hover {
        .program-tip,
        .tooltip-ec3 {
            z-index: 1;
            display: block;
            margin-left: 9px;
            margin-top: -20px;
            position: absolute;
            border-radius: 3px;
            background: #ffffb8;
            color: ${black};
            &.products-tab {
                margin-left: 5px;
            }
        }

        .tooltip-ec3 {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
        .shadow {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
    }
    &.ec3 {
        position: absolute;
        top: 2px;
        left: 75px;
    }
    &.co2 {
        position: absolute;
        top: 1px;
        left: 58px;
    }
    &.product-tab {
        margin-top: 0;
    }
`;

export const TipDivider = styled(Divider)`
    border-top: 1px solid black;
    margin: 5px 0;
`;

export const ProductHeaderSpace = styled('div')`
    width: 100%;
    display: flex;
    padding: 3px;
    gap: 2px;
    height: ${(props: ProductHeaderSpaceProps) => props.height}%;
    justify-content: ${(props: ProductHeaderSpaceProps) => props.justifyContent};
    align-items: ${(props: ProductHeaderSpaceProps) => props.alignItems};
    padding: 6px 2px 6px 8px;
    &.expires {
        padding-right: 6px;
    }
`;

export const TextAndTipContainer = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const PropertyDivider = styled('div')`
    padding-bottom: 3px;
`;

export const ProductPropertyContainer = styled('div')`
    div:not(:first-child) {
    }
`;

export const MasterFormat = styled(Text)`
    letter-spacing: 0.9px !important;
`;

export const ProductHeaderTable = styled('table')`
    font-family: Proxima N W15 Bold, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 11px;
    margin-bottom: 1.5px;
    text-transform: uppercase;
    border-collapse: collapse;
    width: 755px;
    border-spacing: 0;
    vertical-align: top;
    div {
        vertical-align: baseline;
    }
`;

export const ProductHeaderTHead = styled('thead')`
    vertical-align: top;
    td {
        color: #fff;
        text-align: left;
        vertical-align: middle;
        line-height: 13.75px;
        padding: 0;
        .div {
            font-size: 10.5px;
            letter-spacing: 0.1em;
            padding: 6px 0 5px 6px;
            width: 275px;
            line-height: 13.125px;
        }
        &.env_header {
            background-color: #81c17b;
            border-right: 2px solid #fff;
        }
        &.mat_header {
            background-color: #7eb6d1;
        }
    }
`;

export const ProductHeaderTBody = styled('tbody')`
    vertical-align: top;
    background-color: #e3e3e3;
    color: #90908e;
    font-size: 8.5px;
    tr {
        text-align: left;
        vertical-align: middle;
        th {
            font-weight: 400;
            padding: 0;
            line-height: 10.625px;
            &.brand {
                border-right: 2px solid #fff;
                width: 183px;
                .div {
                    padding: 5px 0 4px 12px;
                }
            }
        }
        td {
            font-weight: 400;
            padding: 0;
            line-height: 10.625px;
            .div {
                padding: 5px 0 4px 6px;
                text-align: left;
            }
            &.type {
                width: 79px;
                border-right: 2px solid #fff;
            }
            &.scope {
                border-right: 2px solid #fff;
                width: 87px;
            }
            &.expires {
                background-color: #8c8c8a;
                border-right: 2px solid #fff;
                color: #e6e6e5;
                width: 56px;
                &.env {
                    width: 50px;
                }
                &.mat {
                    border: 0;
                }
            }
            &.program {
                border-right: 2px solid #fff;
                width: 102px;
                line-height: 13.75px;
            }
            &.results {
                width: 131px;
                border-right: 2px solid #fff;
            }
        }
    }
`;

export const ProductTable = styled('table')`
    font-weight: 400;
    width: 755px;
    vertical-align: top;
`;

export const ProductTableTHead = styled('thead')`
    tr {
        background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};
        color: #fff;
        td {
            font-family: Proxima N W15 Bold, Arial, Helvetica Neue, Helvetica, sans-serif;
            font-weight: 400;
            color: #fff;
            text-align: left;
            padding: 0;
            &.company {
                font-size: 16px;
                padding: 3px 0 3px 12px;
                line-height: 20px;
                text-decoration: underline;
                a {
                    height: 20px;
                    color: ${white};
                }
            }
            &.featured {
                font-size: 8.5px;
                text-align: right;
                text-transform: uppercase;
                vertical-align: middle;
                text-align: right;
                padding-right: 7px;
                padding-top: 2px;
                line-height: 10.625px;
            }
        }
    }
`;

export const ProductTableBody = styled('tbody')``;

export const MasterFormatRow = styled('tr')`
    border-top: 1px solid #a6b6c4 !important;
    border-bottom: 1.4px solid #a6b6c4 !important;
    background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};
    td {
        font-family: Proxima N W15 Bold, Arial, Helvetica Neue, Helvetica, sans-serif;
        font-weight: 400;
        color: #fff;
        font-size: 9px;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        background-clip: padding-box;
        &.help {
            text-align: right;
            vertical-align: middle;
            text-align: right;
            padding-right: 7px;
            padding-top: 2px;
            line-height: 10.625px;
            text-decoration: underline;
            div {
                display: flex;
                align-items: center;
                justify-content: end;
            }
            button {
                font-family: Proxima N W15 Smbd, Arial, Helvetica Neue, Helvetica, sans-serif !important;
                height: 14px;
                font-size: 10.5px;
                line-height: 13.125px;
            }
        }
        &.format {
            padding: 4px 0 2px 12px;
            font-size: 10.5px;
            letter-spacing: 0.9px;
            text-transform: uppercase;
            div {
                line-height: 13.125px;
            }
        }
    }
`;

export const ProductHeaderLink = styled(BaseLink)`
    &:hover {
        opacity: 0.5;
    }
    &.black {
        color: #000000;
        cursor: unset;
        &:hover {
            opacity: 1;
        }
    }
`;

export const CheckboxContainer = styled('div')`
    padding: 6px 6px 6px 12px;
    max-width: 183px;
    position: relative;
    
    & > .ant-checkbox-wrapper {
        position: absolute;
        transform: translate(calc(-6px - 14px - 100%), 0px);
        left: 12px;
    }

    .ant-checkbox-wrapper:hover > .ant-checkbox > .ant-checkbox-inner,
    .ant-checkbox:hover > .ant-checkbox-inner {
        border-color: ${blueLink};
    }

    .ant-checkbox > .ant-checkbox-inner {
        border-color: ${blueDark02};
        border-radius: 3px;
    }

    .ant-checkbox-checked > .ant-checkbox-inner {
        background-color: ${blue01};
        border-color: ${blue01};
        border-radius: 3px;
    }

    .ant-checkbox:hover::after,
    .ant-checkbox-wrapper:hover .ant-checkbox::after {
        border-color: ${blueLink};
    }
`;

export const ProductBodyRow = styled('tr')`
    border-bottom: 2px solid #94cad9;
    th {
        background-color: #eff9fc;
        border-right: 1px solid #d5e9e1;
        font-family: Proxima N W15 Smbd, Arial, Helvetica Neue, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 11.5px;
        line-height: 1.25em;
        text-align: left;
        width: 183px;
        vertical-align: top;
        color: ${blueLink};
    }
`;
export const MaterialTD = styled('td')`
    background: transparent url(src/assets/images/bck_env_program_row_odd.png) repeat-y;
    border-right: 2px solid #fff;
    padding: 0;
    vertical-align: top;
    width: 279px;
`;
export const ProgramRow = styled('div')`
    display: flex;
    width: 100%;
    position: relative;
    font-weight: 400;
    font-size: 10.5px;
    line-height: 13.125px;
    .type {
        width: 79px;
        .div {
            padding: 6px 0 7px 7px;
            a {
                font-family: Proxima N W15 Smbd, Arial, Helvetica Neue, Helvetica, sans-serif;
                color: ${blueLink};
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
    .scope {
        width: 148px;
        flex-grow: 1;
        .div {
            padding: 6px 0 7px 6px;
            width: 148px;
            color: #000000;
        }
    }
    .expires {
        width: 47px;
        font-family: 'Proxima Nova';
        div {
            padding: 6px 0 7px 2px;
            color: #000000;
            line-height: 13.125px;
        }
    }
    .program {
        font-size: 11px;
        padding: 6px 0 6px 8px;
        width: 99px;
        line-height: 13.75px;
        a {
            font-family: Proxima N W15 Smbd, Arial, Helvetica Neue, Helvetica, sans-serif;
            color: ${blueLink};
            &:hover {
                opacity: 0.5;
            }
        }
    }
    .results {
        font-size: 11px;
        padding: 6px 0 6px 9px;
        width: 135px;
        display: flex;
        align-items: center;
        font-family: 'Proxima Nova';
        line-height: 13.75px;
        &.circle {
            display: unset;
        }
    }
    .expires-epd {
        margin-left: 2px;
        padding: 7px 0 6px 9px;
        text-align: left;
        width: 55px;
        font-size: 10.5px;
        font-family: 'Proxima Nova';
        line-height: 12.5px;
        div {
            line-height: 13.125px;
        }
    }
    &.ec3 {
        border-top: 1px solid #c7ded7;
    }
`;

export const IngredientsTD = styled('td')`
    background: #e8f4f6 url(src/assets/images/ezgif.com-gif-maker.gif) repeat-y 100% 0;
    vertical-align: top;
    width: 292px;
    padding: 0;
`;

export const EC3TipContainer = styled('div')`
    position: relative;
`;
