import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../../helpers/request';
import { ActionResponse } from '../../../models/actionResponse/types';
import {
    CreateSubscriptionRequest,
    GetCurrentSubscriptionResponse,
    GetSubscriptionsResponse,
    SubscriptionPlan,
} from './types';

const baseUrl = `/subscriptions`;
const referencesUrl = `/references`;

export const getSubscriptionAction = createAsyncThunk<
    ActionResponse<Array<SubscriptionPlan>> | undefined,
    undefined
>('subscriptions/getSubscription', async () => {
    const result = await http<ActionResponse<Array<SubscriptionPlan>>, undefined>({
        path: `${referencesUrl}/getAllSubscriptionPlans`,
        method: 'get',
    });
    console.log('References/GetAllSubscriptionPlans:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const submitSubscriptionPlanAction = createAsyncThunk<
    GetSubscriptionsResponse | undefined,
    CreateSubscriptionRequest
>('subscriptions/submitSubscription', async (data: CreateSubscriptionRequest) => {
    const result = await http<GetSubscriptionsResponse, CreateSubscriptionRequest>({
        path: `${baseUrl}/signUp`,
        method: 'post',
        body: data,
    });

    console.log('SignUp Subscription:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getCurrentSubscriptionAction = createAsyncThunk<
    ActionResponse<GetCurrentSubscriptionResponse> | undefined,
    undefined
>('subscriptions/getCurrentSubscription', async () => {
    const result = await http<ActionResponse<GetCurrentSubscriptionResponse>, undefined>({
        path: `${baseUrl}/current-subscription`,
        method: 'get',
    });

    console.log('GetCurrentSubscription:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
