import styled from 'styled-components';
import { Space, Typography } from 'antd';

export const InformationCardContainer = styled('div')`
    width: 250px;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 44%) 5px 6px 5px 2px;
`;

export const InformationCardButtonContainer = styled(Space)`
    margin-top: 20px;
    width: 100%;
    justify-content: flex-start;
`;

export const InformationCardTitle = styled(Typography.Title)`
    color: #0099ff !important;
    font-size: 20px !important;
`;
