export const template = [
    { column: 'Last name', key: 'lastName', required: true },
    { column: 'First name', key: 'firstName', required: true },
    { column: 'Email', key: 'email', required: true },
    { column: 'Email', key: 'userName', required: false },
    { column: 'Phone', key: 'phoneNumber', required: false },
    { column: 'Phone ext.', key: 'phoneNumberExt', required: false },
    { column: 'Title/role', key: 'titleRoleID', required: true },
    { column: 'Project Builder account type', key: 'accountRoleID', required: true },
];
