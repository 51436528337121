import styled from 'styled-components';
import { blueDark01, blueDark02, blueLink, grayLight01, white } from '../../../styles/colors';
import { ProductSwitcherContent } from '../../../styles/common.styles';
import { SectionTitle } from '../../../pages/projectInfo/tabs/productsTab/sectionItem/SectionItem.styles';

export const FavoriteSectionItemContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4px;
`;

export const FavoriteSectionItemDivider = styled('div')`
    border-top: 1px solid ${grayLight01};
    margin: 0 0 0 40px !important;
`;

export const FavoriteSectionItemTitleContainer = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        .hover {
            color: ${blueLink};
        }
        .switch-button {
            background-color: ${blueLink} !important;
            &:after {
                background: white;
            }
            &:before {
                background: white;
            }
        }
    }
`;

export const FavoriteSectionItemWrapper = styled('div')`
    padding: 16.5px 0;
`;

export const FavoriteSectionTitle = styled(SectionTitle)``;

export const FavoriteProductsContainer = styled(ProductSwitcherContent)`
    .product-item-container:first-child {
        border-top: 1px solid ${grayLight01};
    }
`;

export const FavoriteProductActionsButtonContainer = styled('div')`
    color: ${blueDark01};
    &:hover {
        color: ${blueLink};
        button:first-child {
            background-color: ${white};
            color: ${blueLink};
            border-color: ${blueLink};
            border-right: 0px solid white;
        }
        button:last-child {
            background-color: ${white};
            color: #ffff;
            border-color: ${blueLink};
            border-left: 1px solid ${blueLink};
        }
    }
`;

export const FavoriteCheckboxContainer = styled(ProductSwitcherContent)`
    display: none;
    &.show {
        display: flex;
        margin-left: 18px;
        align-items: center;
        gap: 8px;
    }
    .ant-checkbox-inner {
        border-radius: 3px;
        border-color: ${blueDark02};
    }
    .ant-checkbox-wrapper:hover > .ant-checkbox > .ant-checkbox-inner,
    .ant-checkbox:hover > .ant-checkbox-inner {
        border-color: ${blueLink};
    }
`;

export const ProductScrollableContainer = styled('div')`
    overflow: visible !important;
    &.extraPadding {
        padding-top: 16px;
    }
`;

export const MFRTitleContainer = styled('div')`
    display: flex;
    margin: 14px 55px;
`;
