import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import SMAdminDetails from '../adminPanel/pages/adminsDetails/SMAdminDetails';
import UsersForSMAdmin from '../adminPanel/pages/users/SMAdminUsersPage';
import { NavigationKey } from '../components/appNavigationBar/AppNavigation.types';
import { useAppDispatch } from '../hooks/redux';
import { addPage, removePage } from '../redux/reducers/navigationReducer/NavigationSlice';
import { RouteName } from './Routes.types';

const SMAdmins: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(addPage({ key: NavigationKey.ADMINS, value: 'Admins' }));
        return () => {
            dispatch(removePage());
        };
    }, []);
    return (
        <Switch>
            <Route exact path={RouteName.ADMINS}>
                <UsersForSMAdmin userType={'admin'} />
            </Route>
            <Route exact path={RouteName.ADMIN_DETAIL} component={SMAdminDetails} />
        </Switch>
    );
};

export default SMAdmins;
