import React, { useEffect } from 'react';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { useAppDispatch } from '../../hooks/redux';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { NotFoundPageList, NotFoundPageText } from './NotFoundPage.styles';
import { BlueTitle, PageContainer } from '../../styles/common.styles';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import { NavLink } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(addPages([{ key: NavigationKey.HOME, value: 'SM Transparency Catalog' }]));
    }, []);

    return (
        <>
            <PageHeader title="404: file not found"></PageHeader>
            <PageContainer>
                <BlueTitle>Hmmm ... we can’t seem to find the page you’re looking for.</BlueTitle>
                <NotFoundPageText>Here are some helpful links instead:</NotFoundPageText>
                <NotFoundPageList>
                    <li>
                        <NavLink to={'/'}>Your dashboard </NavLink>
                    </li>
                    <li>
                        <NavLink to={'/project-library'}>Your Project Library </NavLink>
                    </li>
                    <li>
                        <NavLink to={'/my-favorites'}>Your Favorite Products Library </NavLink>
                    </li>
                </NotFoundPageList>
            </PageContainer>
        </>
    );
};

export default NotFoundPage;
