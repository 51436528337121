import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addFavoriteCommentAction } from '../../actions/favoriteProductsActions';
import {
    deleteCommentAction,
    deleteProductAction,
    getProjectProductsAction,
} from '../../actions/productActions';
import { getBrandByIdAction } from '../../actions/transparencyCatalogActions';

export interface ProductReducerType {
    pending: boolean;
    packageId?: string;
    filePath?: string;
}

const initialState: ProductReducerType = {
    pending: false,
    packageId: undefined,
    filePath: undefined,
};

export const productSlice = createSlice({
    name: 'product',
    initialState: initialState,
    reducers: {
        setPackageId(state, action: PayloadAction<string | undefined>) {
            state.packageId = action.payload;
        },
        setFilePath(state, action: PayloadAction<string | undefined>) {
            state.filePath = action.payload;
        },
        clearProductSlice(state) {
            state.pending = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectProductsAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getProjectProductsAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getProjectProductsAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(deleteProductAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(deleteProductAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(deleteProductAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(addFavoriteCommentAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(addFavoriteCommentAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(addFavoriteCommentAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(deleteCommentAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(deleteCommentAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(deleteCommentAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getBrandByIdAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getBrandByIdAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getBrandByIdAction.rejected, (state) => {
            state.pending = false;
        });
    },
});

export const { setPackageId, setFilePath, clearProductSlice } = productSlice.actions;

export default productSlice.reducer;
