import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../../helpers/request';
import { ActionResponse, CommonResponse } from '../../../models/actionResponse/types';
import { SelectData } from '../../../models/common/types';
import { TableData } from '../../../models/tableData/types';
import {
    ChangeUserProjectBuilderRoleRequest,
    ChangeUserProjectGroupRequest,
    CreateUserInBulkRequest,
    CreateUserRequest,
    CreateUserResponse,
    GerUsersResponse,
    GetUserReferencesResponse,
    UpdateUserRequest,
    UserByIdResponse,
    AddProjectGroupResponse,
    AddProjectGroupRequest,
    RemoveProjectGroupRequest,
    ChangePasswordRequest,
    GetAccountSettingsResponse,
    UpdateAccountSettingsRequest,
    ChangeUserAccountRoleRequest,
    GetAccountAdminsRequest,
} from './types';

const baseUrl = `/users`;
const referencesUrl = `/references`;

export const getUsersAction = createAsyncThunk<GerUsersResponse | undefined, TableData>(
    'getUsers/users',
    async (data: TableData) => {
        const result = await http<GerUsersResponse, TableData>({
            path: `${baseUrl}/search`,
            method: 'post',
            body: data,
        });
        console.log('Search:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const getUserByIdAction = createAsyncThunk<
    ActionResponse<Array<UserByIdResponse>> | undefined,
    string
>('getUserById/users', async (id: string) => {
    const result = await http<ActionResponse<Array<UserByIdResponse>>, string>({
        path: `${baseUrl}/${id}`,
        method: 'get',
    });
    console.log('GetById:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const createUserAction = createAsyncThunk<CreateUserResponse | undefined, CreateUserRequest>(
    'createUser/users',
    async (data: CreateUserRequest) => {
        console.log(data);

        const result = await http<CreateUserResponse, CreateUserRequest>({
            path: `${baseUrl}/create`,
            method: 'post',
            body: data,
        });
        console.log('Create:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const createBulkUsersAction = createAsyncThunk<boolean | undefined, CreateUserInBulkRequest>(
    'createBulkUsers',
    async (data: CreateUserInBulkRequest) => {
        const result = await http<boolean, CreateUserInBulkRequest>({
            path: `${baseUrl}/createBulk`,
            method: 'post',
            body: data,
        });
        console.log('CreateInBulk:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const getUserReferencesAction = createAsyncThunk<
    ActionResponse<GetUserReferencesResponse> | undefined,
    undefined
>('getUsersReferences/users', async () => {
    const result = await http<ActionResponse<GetUserReferencesResponse> | undefined, undefined>({
        path: `${referencesUrl}/user`,
        method: 'get',
    });
    console.log('User/References:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateUserAction = createAsyncThunk<
    { data: string; message: string } | undefined,
    UpdateUserRequest
>('updateUser/users', async (data: UpdateUserRequest) => {
    const result = await http<{ data: string; message: string }, CreateUserRequest>({
        path: `${baseUrl}/${data.id}`,
        method: 'put',
        body: data.user,
    });
    console.log('Update:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const deleteUserAction = createAsyncThunk<CommonResponse | undefined, string>(
    'deleteUser/users',
    async (id: string) => {
        const result = await http<CommonResponse, { id: string }>({
            path: `${baseUrl}/delete`,
            method: 'post',
            body: { id: `${id}` },
        });
        console.log('Delete:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const changeUserProjectBuilderRoleAction = createAsyncThunk<
    CommonResponse | undefined,
    ChangeUserProjectBuilderRoleRequest
>('changeUserProjectBuilderRole/users', async (data: ChangeUserProjectBuilderRoleRequest) => {
    const result = await http<CommonResponse, ChangeUserProjectBuilderRoleRequest>({
        path: `${baseUrl}/changeUserProjectBuilderRole`,
        method: 'post',
        body: data,
    });
    console.log('ChangeUserProjectBuilderRole:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeUserAccountRoleAction = createAsyncThunk<
    CommonResponse | undefined,
    ChangeUserAccountRoleRequest
>('changeUserAccountRole/users', async (data: ChangeUserAccountRoleRequest) => {
    const result = await http<CommonResponse, ChangeUserAccountRoleRequest>({
        path: `${baseUrl}/changeUserAccountRole`,
        method: 'post',
        body: data,
    });
    console.log('ChangeUserAccountRole:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const changeProjectGroupAction = createAsyncThunk<
    CommonResponse | undefined,
    ChangeUserProjectGroupRequest
>('changeProjectGroup/users', async (data: ChangeUserProjectGroupRequest) => {
    const result = await http<CommonResponse, ChangeUserProjectGroupRequest>({
        path: `${baseUrl}/changeProjectGroup`,
        method: 'post',
        body: data,
    });
    console.log('ChangeProjectGroup:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const deleteBulkUsersAction = createAsyncThunk<CommonResponse | undefined, Array<string>>(
    'deleteBulkUsers/users',
    async (data: Array<string>) => {
        const result = await http<CommonResponse, Array<string>>({
            path: `${baseUrl}/deleteBulk`,
            method: 'post',
            body: data,
        });
        console.log('DeleteBulk:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const getNotificationsAction = createAsyncThunk<any | undefined, number>(
    'getNotifications/user',
    async (userId: number) => {
        const result = await http<any, any>({
            path: `/Notifications/GetUserNotificationSettingsByUserId`,
            method: 'post',
            body: userId,
        });
        console.log('getNotifications by User:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const changeNotificationsAction = createAsyncThunk<any | undefined, any>(
    'changeNotifications/user',
    async (data: any) => {
        const result = await http<any, any>({
            path: `/notifications/updateUserNotificationSettings`,
            method: 'post',
            body: data,
        });
        console.log('ChangeNotification By User:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const addProjectGroupAction = createAsyncThunk<
    AddProjectGroupResponse | undefined,
    AddProjectGroupRequest
>('addProjectGroup/settings', async (data: AddProjectGroupRequest) => {
    const result = await http<AddProjectGroupResponse, AddProjectGroupRequest>({
        path: `/projectgroups`,
        method: 'post',
        body: data,
    });
    console.log('Add project group:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const removeProjectGroupAction = createAsyncThunk<
    CommonResponse | undefined,
    RemoveProjectGroupRequest
>('removeProjectGroup/settings', async (data: RemoveProjectGroupRequest) => {
    const result = await http<CommonResponse, RemoveProjectGroupRequest>({
        path: `/projectgroups/${data.id}`,
        method: 'delete',
    });
    console.log('Remove project group:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getProjectGroups = createAsyncThunk<Array<SelectData> | undefined, void>(
    'getProjectGroups/settings',
    async () => {
        const result = await http<Array<SelectData>, void>({
            path: `/projectgroups`,
            method: 'get',
        });
        console.log('Project groups:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const changePasswordAction = createAsyncThunk<any | undefined, ChangePasswordRequest>(
    'users/changePassword',
    async (data: ChangePasswordRequest) => {
        const result = await http<any, ChangePasswordRequest>({
            path: `${baseUrl}/change-password`,
            method: 'post',
            body: data,
        });
        console.log('Change password: ');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const manualConfirmEmailAction = createAsyncThunk<any | undefined, string>(
    'settings/manualConfirmEmail',
    async (data: string) => {
        console.log(data);

        const result = await http<any, { email: string }>(
            {
                path: `${baseUrl}/manual`,
                method: 'post',
                body: { email: data },
            },
            false,
        );
        console.log('Manual Confirm:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const resetUserPasswordAction = createAsyncThunk<CommonResponse | undefined, string>(
    'users/resetUserPassword',
    async (id: string) => {
        const result = await http<CommonResponse, { userId: string }>({
            path: `${baseUrl}/reset-user-password`,
            method: 'post',
            body: { userId: `${id}` },
        });
        console.log('Delete:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const getAccountSettingsAction = createAsyncThunk<
    GetAccountSettingsResponse | undefined,
    undefined
>('users/getAccountSettings', async () => {
    const result = await http<GetAccountSettingsResponse, { userId: string }>({
        path: `${baseUrl}/account-settings`,
        method: 'get',
    });
    console.log('GetAccountSettings:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const updateAccountSettingsAction = createAsyncThunk<
    GetAccountSettingsResponse | undefined,
    UpdateAccountSettingsRequest
>('users/updateAccountSettings', async (data: UpdateAccountSettingsRequest) => {
    const result = await http<GetAccountSettingsResponse, UpdateAccountSettingsRequest>({
        path: `${baseUrl}/update-account-settings`,
        method: 'post',
        body: data,
    });
    console.log('UpdateAccountSettings:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const getAccountAdminsAction = createAsyncThunk<
    ActionResponse<GetAccountAdminsRequest> | undefined,
    undefined
>('users/getAccountAdmins', async () => {
    const result = await http<ActionResponse<GetAccountAdminsRequest>, undefined>({
        path: `${baseUrl}/account-info`,
        method: 'get',
    });
    console.log('GetAccountAdmins:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
