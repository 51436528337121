import styled from 'styled-components';
import { SwitcherProps } from '../../../models/styles/types';
import { blueDark02 } from '../../../styles/colors';

export const SwitcherContainer = styled('div')`
    width: 50px;
    .switch-container {
        display: flex;
        justify-content: flex-start;
        margin-left: 12.5px;
        align-items: center;
        &.small {
            margin-left: 9px;
        }
    }

    .switch-button {
        position: absolute;
        width: ${(props: SwitcherProps) => props.width}px;
        height: ${(props: SwitcherProps) => props.height}px;
        background: white;
        border: 1px solid ${blueDark02};
        top: 50%;
        left: 20%;
        transform: translate(-50%, -50%) rotate(0deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.4s cubic-bezier(0.2, 0.6, 0.3, 1.1);
    }
    .switch-button.clicked:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 50%;
        background: white;
    }

    .switch-button.clicked:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50%;
        width: 2px;
        background: white;
    }

    .switch-button:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 50%;
        background: ${blueDark02};
    }

    .switch-button:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50%;
        width: 2px;
        background: ${blueDark02};
    }

    .switch-button.clicked {
        transform: translate(-50%, -50%) rotate(360deg);
        background: ${blueDark02};
    }

    .switch-button.clicked:before {
        width: 0;
    }
`;
