import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NavigationKey } from '../components/appNavigationBar/AppNavigation.types';
import { OfficeSubscriptionRoute } from '../components/authorizedPage/OfficeSubscriptionRotes';
import { useAppDispatch } from '../hooks/redux';
import EditAccountPage from '../pages/editUser/EditAccount';
import UsersPage from '../pages/users/UsersPage';
import { addPage, removePage } from '../redux/reducers/navigationReducer/NavigationSlice';

const UsersRoute: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(addPage({ key: NavigationKey.USERS, value: 'Users' }));
        return () => {
            dispatch(removePage());
        };
    }, []);
    return (
        <Switch>
            <OfficeSubscriptionRoute>
                <Route exact path="/users/:from?" component={UsersPage} />
                <Route path="/users/edit/:id" component={EditAccountPage} />
            </OfficeSubscriptionRoute>
        </Switch>
    );
};

export default UsersRoute;
