import { Col, Form, Modal, Row, Spin } from 'antd';
import styled from 'styled-components';
import { blue01, blueContact, blueDark01, grayDark05, blueLink } from '../../styles/colors';
import { Text } from '../../styles/common.styles';

export const ChangeNumberOfUsersModal = styled(Modal)`
    width: 680px !important;
    height: 566px !important;

    .ant-modal-body {
        padding: 32px 40px 37px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }
`;

export const ChangeNumberOfUsersSubtitle = styled('div')`
    a {
        color: ${blueLink};
    }
`;

export const HighLightText = styled('strong')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
`;

export const ChangeUsersCountForm = styled(Form)`
    margin-top: 28px;
`;

export const ChangeNumberOfUsersRow = styled(Row)`
    height: 90px;
    .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin: 0;
    }
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .ant-select-selector:hover {
        border-color: ${grayDark05} !important;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: ${blue01} !important;
        box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
    }

    label {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px !important;
        line-height: 16px !important;
        color: ${blueDark01} !important;
        white-space: nowrap;
    }
    .ant-form-item-row {
        height: 52px;
    }
`;

export const ChangeNumberOfUsersCol = styled(Col)`
    width: 280px;
`;
export const ChangeNumberOfUsersColPrice = styled(Col)`
    width: 280px;
    .ant-form-item-control-input {
        align-items: center;
    }

    .ant-form-item-label > label {
        height: 16px;
        vertical-align: top;
    }
`;

export const PriceSpin = styled(Spin)`
    span {
        left: 10px !important;
        top: 0 !important;
    }
`;
export const ContactMessageWrapper = styled('div')`
    height: 44px;
    position: relative;
`;
export const ContactMessage = styled('div')`
    position: absolute;
    bottom: 3px;
    line-height: 18px;
    font-weight: 400;
    font-size: 14px;
    a {
        color: ${blueLink};
    }
`;
