export enum AddProductAction {
    Search = 'search',
    YourFavorites = 'yourFavorites',
    OfficeFavorite = 'favorites',
    AnotherSite = 'another',
    ProjectLibraryImport = 'import',
    SearchNotInTc = 'searchNotInTc',
}

export enum FavoriteType {
    MyFavorite,
    OfficeFavorite,
}

export enum UploadsFieldKey {
    Id,
    DocumentName,
    ExpiredOn,
    CreatedOn,
    Size,
}

export enum DeclareType {
    LBCCompliant = 'LBC compliant',
}

export enum DeclareResults {
    Approved = 'LBC Red List Approved',
    Free = 'LBC Red List Free',
    Bronze = 'Bronze',
    Declared = 'LBC Declared',
    Platinum = 'Platinum',
    Gold = 'Gold',
    Silver = 'Silver',
}

export enum C2CResult {
    Bronze = 'Bronze',
    Platinum = 'Platinum',
    Gold = 'Gold',
    Silver = 'Silver',
}

export enum EC3EmbodiedCarbon {
    Twenty = '20th percentile',
    Fourty = '40th percentile',
    Sixty = '60th percentile',
    SixtyPlus = '60th+ percentile',
    Eighty = '80th percentile',
    EightyPlus = '80th+ percentile',
}

export enum MasterFormatBackground {
    FREE_LISTING = 'level-1',
    STANDARD = 'level-2',
    FEATURED = 'level-3',
}

export enum RegulationText {
    PERKINS_AND_WILL = 'Perkins&Will Precautionary List Free',
}
