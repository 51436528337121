import { Divider, Form, Image, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import Dragger from 'antd/lib/upload/Dragger';
import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    blueLink,
    error,
    orange,
    orange01,
    orange02,
    white,
} from '../../../../styles/colors';
import { BoldText, PrimaryButton, SpaceBetween, Text } from '../../../../styles/common.styles';
import { HiddenInputProps } from './ProjectInformation.types';
import { InvisibleButton } from '../../../../styles/common.styles';
import CustomSelect from '../../../../components/common/select/CustomSelect';
import CustomInput from '../../../../components/common/inputs/CustomInput';

export const ProjectInfoPageTitle = styled('h2')`
    font-family: 'DINNextLTPro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${blue01};
    margin-bottom: 28px;
`;

export const ProjectInformationSpace = styled(SpaceBetween)`
    .ant-space-item {
        width: 50%;
    }
    align-items: baseline;
    gap: 60px !important;
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 36px;
    }
    .ant-input {
        height: 36px;
        padding: 8px 10px;
        min-width: 354px;
    }
    .ant-select-selector {
        min-width: 354px;
        .ant-select-arrow {
            right: -50px;
        }
    }
    .ant-form-item {
        margin-bottom: 16px;
    }
`;

export const BaseInformationContainer = styled('div')`
    padding: 0 10% 0 0px;
    @media screen and (max-width: 1400px) {
        padding: 0;
    }
`;

export const BaseInformationForm = styled(Form)`
    .ant-form-item-label {
        flex: 0 0 22%;
        max-width: 25%;
        min-width: 106px !important;
        label {
            max-width: 124px;
            height: 34px;
            color: ${blueDark01} !important;
        }
    }
    .ant-spin-nested-loading > div > .ant-spin {
        top: 0;
    }
`;

export const ProjectInformationPageDivider = styled(Divider)`
    height: 100%;
    border-left: 1px solid black;
    margin: 16px 0 16px;
    &.image {
        margin-top: 40px;
    }
`;

export const ProjectInformationDragger = styled(Dragger)`
    height: 112px !important;
    max-height: 112px !important;
    border: none !important;
    .ant-upload {
        background-color: #eff9fc;
        border: 1px dashed #c1c1c1;
        border-radius: 3px;
        min-width: 354px;
    }
    .ant-upload,
    .ant-upload-drag-container {
        transition: 0s 0.05s;
    }
    &.ant-upload-drag-hover .ant-upload {
        border-color: #4b97e8;
        background-color: #e3e9f7;
    }
    &.ant-upload-drag-hover .ant-upload-drag-container {
        opacity: 0.3;
    }
    &.error {
        .ant-upload {
            border: 1px dashed ${error} !important;
        }
    }
`;

export const ProjectInformationImageDragger = styled(Dragger)`
    height: 195px !important;
    border: none !important;
    .ant-upload {
        background-color: #eff9fc;
        border: 1px dashed #c1c1c1;
        border-radius: 3px;
    }
    .ant-upload,
    .ant-upload-drag-container {
        transition: 0s 0.05s;
    }
    &.ant-upload-drag-hover .ant-upload {
        border-color: #4b97e8;
        background-color: #e3e9f7;
    }
    &.ant-upload-drag-hover .ant-upload-drag-container {
        opacity: 0.3;
    }
    &.preview {
        .ant-image {
            width: 100%;
            height: 100%;
            .ant-image-img {
                height: 100%;
                max-height: 194px;
                min-height: 193px;
            }
            img {
                object-fit: fill;
            }
        }
        span {
            padding: 0 !important;
        }
    }
`;

export const DraggerText = styled(Text)`
    margin-top: 10px;
    max-height: 50px;
    text-overflow: ellipsis;
    overflow: hidden auto;
    &.up {
        margin-top: 0;
    }
`;

export const ChooseFileText = styled(BoldText)`
    margin-top: 4px;
    max-height: 50px;
    text-overflow: ellipsis;
    overflow: hidden auto;
    &.up {
        margin-top: 0;
    }
`;
export const MailboxContainer = styled('div')`
    margin-left: 25%;
    height: 300px;
    .mapboxgl-control-container {
        display: none;
    }
`;

export const SaveProjectInfo = styled(PrimaryButton)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: 'DINNextLTPro';
    background-color: ${orange01};
    color: #ffffff;
    padding: 9px 18px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    width: 120px;
    justify-content: center;
    border-color: transparent;
    margin-top: 3px;
    cursor: pointer;
    &:hover {
        background-color: ${orange02};
        color: #ffffff;
        border-color: transparent;
    }
    &:focus {
        border-color: transparent;
        color: #ffffff;
        background-color: ${orange};
    }
    &:disabled {
        background-color: ${orange01};
        opacity: 0.3;
        color: ${white};
        &:hover {
            background-color: ${orange01};
            opacity: 0.3;
            color: ${white};
        }
    }
    span {
        margin-top: 3.5px;
    }
`;

export const SaveProjectInfoTop = styled(SaveProjectInfo)`
    margin-top: -16px !important;
    margin-bottom: 40px !important;
    width: 112px !important;
    min-width: 112px !important;
    height: 28px !important;
`;


export const GrossBuildingInput = styled('div')`
    .ant-form-item-label {
        label {
            max-width: 90px;
            width: 78px;
            text-align: right !important;
        }
    }

    @-moz-document url-prefix() {
        .ant-form-item-label {
            label {
                height: 43px;
            }
        }
    }
`;

export const ConstructionTypeInput = styled('div')`
    .ant-form-item-label {
        label {
            max-width: 90px;
            width: 78px;
            text-align: right !important;
        }
    }

    @-moz-document url-prefix() {
        .ant-form-item-label {
            label {
                height: 43px;
            }
        }
    }
`;

export const DocumentNameInput = styled('div')`
    .ant-form-item-label {
        label {
            text-align: right !important;
            max-width: 100%;
        }
    }
`;

export const PreviewImage = styled(Image)`
    object-fit: cover;
`;

export const CustomSpace = styled(Space)`
    justify-content: space-between;
    width: 100%;
`;

export const HiddenInputWrapper = styled('div')`
    .ant-input {
        width: 75%;
        float: right;
    }

    display: ${(props: HiddenInputProps) => (props.displayed ? 'block' : 'none')};
`;

export const CustomButton = styled(InvisibleButton)`
    float: right;
    margin-top: -47px;
`;

export const ProjectLibraryVisibilityInput = styled(CustomSelect)`
    .ant-form-item-label {
        label {
            min-width: 124px;
        }
    }
`;

export const RightLabelInputAlign = styled(CustomInput)`
    label {
        text-align: right;
    }
`;

export const RightLabelOwnersContainer = styled(CustomInput)`
    label {
        text-align: right;
    }
`;

export const TemplateContainer = styled(CustomInput)`
    label {
        text-align: right;
    }
`;

export const RightLabelSelectAlign = styled(CustomSelect)`
    label {
        text-align: right;
    }
`;

export const LinkToManufacturerDashboard = styled(NavLink)`
    color: ${blueLink};
    &:hover {
        color: ${blueLink};
    }
`;

export const ConfidentialTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -30px;
            left: 24px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 280px;
        }
    }
`;

export const DraggerTextAction = styled(Dragger)`
    background-color: ${white} !important;
    border: none !important;
    margin-bottom: -10px !important;
    width: 90% !important;
    &.ant-upload.ant-upload-drag .ant-upload {
        padding: 0 !important;
    }
`;

export const ProjectFileInputFlexRow = styled('div')`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 2px;
    div:after {
        content: ' ';
        white-space: pre;
    }

    div:last-child:after {
        content: '';
    }
`;

export const EC3ExportButton = styled(InvisibleButton)`
    &:focus {
        color: ${blueLink} !important;
    }
`;

export const EC3LinkContainer = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const ProjectBuildingTypeInputContainer = styled('div')`
    label {
        text-align: right !important;
        &:after {
            top: 7px;
        }
    }
`;

export const ProjectVisibilityTextContainer = styled('div')`
    gap: -10px;
//    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    height: 58px;
    color: ${blueDark01};
`;
