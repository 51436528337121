import { Select, Image } from 'antd';
import React from 'react';
import { SelectData } from '../../../models/common/types';
import { grayDark03 } from '../../../styles/colors';
import { Text } from '../../../styles/common.styles';
import { ChangePageSizeOption, PaginationSpace } from './CustomPagination.styles';

const pageSizeOptions: Array<SelectData> = [
    { id: '10', name: '10' },
    { id: '25', name: '25' },
    { id: '50', name: '50' },
    { id: '100', name: '100' },
];

interface Props {
    changePageSize: (pageSize: number) => void;
    pageSize: number;
}

const ChangePageSize: React.FC<Props> = ({ changePageSize, pageSize }) => {
    const handleChangePageSize = (value: string) => {
        changePageSize(+value);
    };
    return (
        <PaginationSpace>
            <Select
                value={pageSize.toString()}
                onChange={handleChangePageSize}
                suffixIcon={
                    <Image
                        src="src/assets/images/caret-down.svg"
                        preview={false}
                        alt="caret-down"
                    />
                }
            >
                {pageSizeOptions.map((option) => (
                    <ChangePageSizeOption value={option.id} key={option.id}>
                        {option.name}
                    </ChangePageSizeOption>
                ))}
            </Select>
            <Text weight={400} size={13} height={16} color={grayDark03}>
                per page
            </Text>
        </PaginationSpace>
    );
};

export default ChangePageSize;
