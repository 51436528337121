import styled from 'styled-components';
import { Modal } from 'antd';

export const ErrorWarnAlertModalWrapper = styled(Modal)`
    width: 680px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    .ant-divider-horizontal {
        margin-top: 28px;
        margin-bottom: 21px;
    }
`;

export const ErrorWarnAlertModalContent = styled('div')``;

export const ExistingWarnAlertModalListContainer = styled('ul')`
    margin-top: 16px;
`;
