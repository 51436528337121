import React, { useEffect, useState, useRef } from 'react';
import { Image } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../hooks/redux';
import { ActionResponse } from '../../models/actionResponse/types';
import { ExternalProduct, FavoriteProduct, Product } from '../../models/product/types';
import { Division, Section } from '../../models/productDivision/types';
import { InvisibleButton, Text, SpaceEnd } from '../../styles/common.styles';
import { ProductsTabHeader } from '../../pages/projectInfo/tabs/productsTab/ProductsTab.styles';
import { blueLink } from '../../styles/colors';
import FavoriteDivisionItem from './favoriteDivisionItem/FavoriteDivisionItem';
import {
    FavoritePageContainer,
    FavoriteProductsHeader,
    FavoriteSpin,
    FavoriteTabContent,
    NoProductsTitleContainer,
    NoProductsTitleContainerFiltered,
    OpenCloseAllButton,
    FoundResultsCount,
} from './FavoriteProducts.styles';
import { MergedFavoriteProduct } from './FavoriteProducts.types';
import {
    GetProductIdsByPackageIdResponse,
    ProductReferences,
} from '../../redux/actions/productActions/types';
import {
    getProductIdsByPackageIdAction,
    getProductReferencesAction,
} from '../../redux/actions/productActions';
import { FavoriteType } from '../../models/product/enums';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../redux/actions/transparencyCatalogActions/types';
import { addFavoriteInBulkAction } from '../../redux/actions/favoriteProductsActions';
import { AddFavoriteInBulkResponse } from '../../redux/actions/favoriteProductsActions/types';
import ExistingProductsModal from '../existingProductsModal/ExistingProductsModal';
import {
    getCompaniesOfProducts,
    getExternalProductsAction,
} from '../../redux/actions/transparencyCatalogActions';
import FavoriteProductsAction from '../common/favoriteProductsAction/FavoriteProductsAction';

import ProductsSearch from '../products/productsTable/ProductsSearch';

interface Props {
    favoriteType: FavoriteType;
    removedProducts: Array<MergedFavoriteProduct>;
    selectedProducts: Array<MergedFavoriteProduct>;
    productId?: string;
    setSelectedProducts: (selectedProducts: Array<MergedFavoriteProduct>) => void;
    handleSelectProduct: (product: MergedFavoriteProduct, checked: boolean) => void;
    setFavoriteProducts: React.Dispatch<React.SetStateAction<ExternalProduct[]>>;
    removeFavorites: () => void;
    addProductsToProject: () => void;
    addProductsToFavorite: () => void;
    action: () => AsyncThunkAction<
        ActionResponse<Array<FavoriteProduct>> | undefined,
        undefined,
        {}
    >;
    isFilterMode?: boolean;
    isFilteringInProgress?: boolean;
    filteredDivisions: Array<Division<MergedFavoriteProduct>>;
    setFilteredDivisions: (divisions: Array<Division<MergedFavoriteProduct>>) => Array<Division<MergedFavoriteProduct>>;
    setFilteredMode: (filterMode: boolean) => boolean;
    setFilteringInProgress: (filterInProgress: boolean) => boolean;
}

const FavoriteProducts: React.FC<Props> = ({
    removedProducts,
    favoriteType,
    selectedProducts,
    productId,
    setSelectedProducts,
    handleSelectProduct,
    setFavoriteProducts,
    removeFavorites,
    addProductsToFavorite,
    addProductsToProject,
    action,
    isFilterMode,
    isFilteringInProgress,
    filteredDivisions,
    setFilteredDivisions,
    setFilteredMode,
    setFilteringInProgress
}) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const existingProductIds = useRef(Array<number>());
    const [existingProducts, setExistingProducts] = useState({
        products: Array<{ id: number; name: string }>(),
        productIds: Array<number>(),
        isModalOpen: false,
    });

    const [state, setState] = useState({
        divisions: Array<Division<MergedFavoriteProduct>>(),
        filteredDivisions: Array<Division<MergedFavoriteProduct>>(),
        pending: true,
        searchString: null,
    });

    const [references, setReferences] = useState<ProductReferences | null>(null);

    const packageId = queryParams.get('packageId');
    useEffect(() => {
        window.scroll(0, 0);
        if (!state.searchString) {
            getData();
        }
    }, []);

    const getData = async () => {
        await getProductIdsByPackageId();
        await getFavorites();
        await getProductReferences();
    };

    useEffect(() => {
        if (removedProducts && removedProducts.length) {
            removeProducts();
        }
    }, [removedProducts]);

    const changeSearch = (searchString: string) => {
        setState({
            ...state,
            searchString: searchString,
        });
    };

    const submitSearch = () => {
        setFilteringInProgress(true);
        setFilteredMode(state.searchString);
        if (!state.searchString) {
            setFilteredDivisions([]);
            setFilteringInProgress(false);
            return;
        }
        getData();
    };

    const cleanFilter = () => {
        setFilteringInProgress(true);
        changeSearch('');
        setFilteredMode(false);
        setFilteredDivisions([]);
        setFilteringInProgress(false);
    };

    const getProductReferences = async () => {
        const { payload } = (await dispatch(
            getProductReferencesAction(),
        )) as ActionResponse<ProductReferences>;
        setReferences(payload.data);
    };
    const getProductIdsByPackageId = async () => {
        if (packageId) {
            const { payload } = (await dispatch(
                getProductIdsByPackageIdAction({
                    productPackageID: packageId,
                }),
            )) as GetProductIdsByPackageIdResponse;
            if (payload.result && payload.result.length) {
                await addFavoriteInBulk(payload.result.map((p) => p.productSelectedOnTcId));
            }
        }
    };

    const addFavoriteInBulk = async (productFavoriteIds: Array<number>) => {
        const { payload } = (await dispatch(
            addFavoriteInBulkAction({ productFavoriteIds, favoriteType }),
        )) as ActionResponse<AddFavoriteInBulkResponse>;
        if (payload.data && payload.data.existedProductIds.length) {
            existingProductIds.current = payload.data.existedProductIds;
        }
    };

    const getFavorites = async () => {
        const { payload } = (await dispatch(action())) as ActionResponse<Array<FavoriteProduct>>;
        if (payload.data) {
            getProductsFromTransparencyCatalog(payload.data);
        }
    };

    const getCompanyData = async (externalProducts: ExternalProduct[]) => {
        const companies = await getCompaniesOfProducts(externalProducts, dispatch);
        return externalProducts.map((ep) => ({
            ...ep,
            companyLevel: companies.find((c) => c.id === ep.companyID)?.level,
            listingUrl: companies.find((c) => c.id === ep.companyID)?.listingUrl,
        }));
    };

    const getProductsFromTransparencyCatalog = async (favoriteProducts: Array<FavoriteProduct>) => {
        const productIds = favoriteProducts.map((favorite) => favorite.product.productId);
        if (!productIds || !productIds.length) {
            setState({ ...state, pending: false });
            return;
        }
        const { payload } = (await dispatch(
            getExternalProductsAction({ productIds }),
        )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;

        if (payload.data) {
            let externalProducts = Object.values(payload.data.mfSection).reduce(
                (agg, item: any) => agg.concat(item),
                Array<ExternalProduct>(),
            );
            externalProducts = await getCompanyData(externalProducts);
            setFavoriteProducts(externalProducts);
            const products = mergeProducts(externalProducts, favoriteProducts);
            const divisions = new Map();
            products.forEach((product) => {
                const key = parseInt(product.mfDivision.number[0] + product.mfDivision.number[1]);
                const highestSection = product.mfSectionLevel1 || product.mfSection;
                if (divisions.has(key)) {
                    if (divisions.get(key).sections.has(highestSection.number.slice(0, 5))) {
                        divisions
                            .get(key)
                            .sections.get(highestSection.number.slice(0, 5))
                            .products.push(product);
                    } else {
                        divisions.get(key).sections.set(highestSection.number.slice(0, 5), {
                            id: highestSection.number.slice(0, 5),
                            key: parseInt(highestSection.number[3] + highestSection.number[4]),
                            name: highestSection.label,
                            clicked: false,
                            products: new Array(product),
                        });
                    }
                } else {
                    divisions.set(key, {
                        id: product.mfDivision.number,
                        name: product.mfDivision.label,
                        key: key,
                        sections: new Map([
                            [
                                highestSection.number.slice(0, 5),
                                {
                                    id: highestSection.number.slice(0, 5),
                                    key: parseInt(
                                        highestSection.number[3] + highestSection.number[4],
                                    ),
                                    name: highestSection.label,
                                    clicked: false,
                                    products: new Array(product),
                                },
                            ],
                        ]),
                        clicked: false,
                    });
                }
            });
            for (const division of divisions.values()) {
                division.sections = [...division.sections.values()].sort(
                    (a: Section<FavoriteProduct>, b: Section<FavoriteProduct>) => a.key - b.key,
                );
            }
            const uniqueDivisions = [...divisions.values()];
            const selectedProduct = externalProducts.find(
                (product) => product.id.toString() === productId,
            );
            if (existingProductIds.current && existingProductIds.current.length) {
                setExistingProducts({
                    ...existingProducts,
                    products: products
                        .filter((cp) =>
                            existingProductIds.current.some((id) => id === cp.product.productId),
                        )
                        .map((product) => ({ name: product.productName, id: product.id })),
                    isModalOpen: true,
                });
            }
            if (selectedProduct) {
                showSelectedProduct(selectedProduct, uniqueDivisions);
            }
            if (packageId) {
                window.history.replaceState(
                    null,
                    '',
                    `${favoriteType === FavoriteType.MyFavorite ? 'my-favorites' : 'favorites'}`,
                );
            }
            setState({
                ...state,
                divisions: uniqueDivisions.sort((a, b) => a.key - b.key),
                pending: false,
            });
            if (state.searchString) {
                const uniqueFilteredDivisions = filterProducts(uniqueDivisions);
                setFilteredDivisions([...uniqueFilteredDivisions.sort((a, b) => a.key - b.key)]);
                setFilteredMode(true);
            }
            setFilteringInProgress(false);
        }
    };

    const showSelectedProduct = (
        product: ExternalProduct,
        divisions: Array<Division<MergedFavoriteProduct>>,
    ) => {
        const divisionIndex = divisions.findIndex((d) => d.id === product.mfDivision.number);
        const highestSection = product.mfSectionLevel1 || product.mfSection;
        if (divisionIndex !== -1) {
            divisions[divisionIndex].clicked = true;
            const sectionIndex = divisions[divisionIndex].sections.findIndex(
                (s) => s.id === highestSection.number.slice(0, 5),
            );
            if (sectionIndex !== -1) {
                divisions[divisionIndex].sections[sectionIndex].clicked = true;
                setTimeout(() => {
                    document.getElementById(`${product.id}`)?.scrollIntoView({
                        behavior: 'smooth',
                    });
                }, 500);
            }
        }
    };
    const mergeProducts = (
        externalProducts: Array<ExternalProduct>,
        favoriteProducts: Array<FavoriteProduct>,
    ): Array<MergedFavoriteProduct> => {
        return favoriteProducts.reduce((agg, value) => {
            const currentProduct = externalProducts.find(
                (product) => product.id === value.product.productId,
            );
            if (currentProduct) {
                agg.push({
                    ...currentProduct,
                    ...value,
                    selected: false,
                });
            }
            return agg;
        }, Array<MergedFavoriteProduct>());
    };

    const handleDivisionOpen = (id: string) => {
        const index = state.divisions.findIndex((division) => division.id === id);
        if (index !== -1) {
            const currentDivisions = state.divisions;
            currentDivisions[index].clicked = !currentDivisions[index].clicked;
            setState({ ...state, divisions: currentDivisions });
        }
    };

    const handleFilteredDivisionOpen = (id: string) => {
        const index = filteredDivisions.findIndex((division) => division.id === id);
        if (index !== -1) {
            const currentDivisions = filteredDivisions;
            currentDivisions[index].clicked = !currentDivisions[index].clicked;
            setFilteredDivisions(currentDivisions);
        }
    };

    const handleSectionOpen = (divisionId: string, sectionId: string) => {
        const divisionIndex = state.divisions.findIndex((division) => division.id === divisionId);
        const sectionIndex = state.divisions[divisionIndex].sections.findIndex(
            (section) => section.id === sectionId,
        );
        if (divisionIndex !== -1 && sectionIndex !== -1) {
            const currentDivisions = state.divisions;
            currentDivisions[divisionIndex].sections[sectionIndex].clicked =
                !currentDivisions[divisionIndex].sections[sectionIndex].clicked;
            setState({ ...state, divisions: currentDivisions });
        }
    };

    const handleFilteredSectionOpen = (divisionId: string, sectionId: string) => {
        const divisionIndex = filteredDivisions.findIndex(
            (division) => division.id === divisionId,
        );
        const sectionIndex = filteredDivisions[divisionIndex].sections.findIndex(
            (section) => section.id === sectionId,
        );
        if (divisionIndex !== -1 && sectionIndex !== -1) {
            const currentDivisions = filteredDivisions;
            currentDivisions[divisionIndex].sections[sectionIndex].clicked =
                !currentDivisions[divisionIndex].sections[sectionIndex].clicked;
            setFilteredDivisions(currentDivisions);
        }
    };

    const selectAllProducts = (divisionId: string, sectionId: string, checked: boolean) => {
        const divisionIndex = !isFilterMode ? state.divisions.findIndex((division) => division.id === divisionId) :
                              filteredDivisions.findIndex((division) => division.id === divisionId);
        const sectionIndex = !isFilterMode ? 
            state.divisions[divisionIndex].sections.findIndex(
                (section) => section.id === sectionId,
            ) :
            filteredDivisions[divisionIndex].sections.findIndex(
                (section) => section.id === sectionId,
            );
        if (divisionIndex !== -1 && sectionIndex !== -1) {
            const currentDivisions = !isFilterMode ? state.divisions : filteredDivisions;
            currentDivisions[divisionIndex].sections[sectionIndex].products = currentDivisions[
                divisionIndex
            ].sections[sectionIndex].products.map((product) => ({ ...product, selected: checked }));
            let currentSelectedProducts = selectedProducts;
            currentDivisions[divisionIndex].sections[sectionIndex].products.forEach((product) => {
                if (
                    product.selected &&
                    !currentSelectedProducts.find((item) => item.id === product.id)
                ) {
                    currentSelectedProducts.push(product);
                }
                if (!product.selected) {
                    currentSelectedProducts = currentSelectedProducts.filter(
                        (currentProduct) => currentProduct.id !== product.id,
                    );
                }
            });
            setSelectedProducts(currentSelectedProducts);
            if (!isFilterMode) {
                setState({ ...state, divisions: currentDivisions });
            } else {
                setState({ ...state, filteredDivisions: currentDivisions });
            }
        }
    };

    const selectProduct = (product: MergedFavoriteProduct, checked: boolean) => {
        const highestSection = product.mfSectionLevel1 || product.mfSection;
        const divisionIndex = !isFilterMode ? state.divisions.findIndex(
            (division) => division.id === product.mfDivision.number,
        ) :
            filteredDivisions.findIndex(
                (division) => division.id === product.mfDivision.number,
            );
        if (divisionIndex === -1) {
            return;
        }
        const sectionIndex = !isFilterMode ? state.divisions[divisionIndex].sections.findIndex(
            (section) => section.id === highestSection.number.slice(0, 5),
        ) : 
            filteredDivisions[divisionIndex].sections.findIndex(
                (section) => section.id === highestSection.number.slice(0, 5),
        );
        if (sectionIndex === -1) {
            return;
        }
        const productIndex = !isFilterMode ? state.divisions[divisionIndex].sections[
            sectionIndex
        ].products.findIndex((item) => product.id === item.id) : 
            filteredDivisions[divisionIndex].sections[
                sectionIndex].products.findIndex((item) => product.id === item.id);

        if (productIndex === -1) {
            return;
        }

        const currentDivisions = !isFilterMode ? state.divisions : filteredDivisions;
        currentDivisions[divisionIndex].sections[sectionIndex].products[productIndex].selected =
            checked;
        if (!isFilterMode) {
            setState({ ...state, divisions: currentDivisions });
        } else {
            setState({ ...state, filteredDivisions: currentDivisions });
        }
        handleSelectProduct(product, checked);
    };

    const removeProducts = () => {
        removedProducts.forEach((product) => {
            const highestSection = product.mfSectionLevel1 || product.mfSection;
            let divisionIndex = state.divisions.findIndex(
                (division) => division.id === product.mfDivision.number,
            );
            let sectionIndex = state.divisions[divisionIndex].sections.findIndex(
                (section) => section.id === highestSection.number.slice(0, 5),
            );

            let productIndex = state.divisions[divisionIndex].sections[
                sectionIndex
            ].products.findIndex((item) => item.id === product.id);
            let currentDivisions = state.divisions;
            currentDivisions[divisionIndex].sections[sectionIndex].products.splice(productIndex, 1);
            if (!currentDivisions[divisionIndex].sections[sectionIndex].products.length) {
                currentDivisions[divisionIndex].sections.splice(sectionIndex, 1);
                if (!currentDivisions[divisionIndex].sections.length) {
                    currentDivisions.splice(divisionIndex, 1);
                }
            }
            setState({ ...state, divisions: currentDivisions });

            if (isFilterMode) {
                divisionIndex = filteredDivisions.findIndex(
                    (division) => division.id === product.mfDivision.number,
                );
                sectionIndex = filteredDivisions[divisionIndex].sections.findIndex(
                    (section) => section.id === highestSection.number.slice(0, 5),
                );

                productIndex = filteredDivisions[divisionIndex].sections[
                    sectionIndex
                ].products.findIndex((item) => item.id === product.id);
                currentDivisions = filteredDivisions;
                currentDivisions[divisionIndex].sections[sectionIndex].products.splice(productIndex, 1);
                if (!currentDivisions[divisionIndex].sections[sectionIndex].products.length) {
                    currentDivisions[divisionIndex].sections.splice(sectionIndex, 1);
                    if (!currentDivisions[divisionIndex].sections.length) {
                        currentDivisions.splice(divisionIndex, 1);
                    }
                }

                setState({ ...state, filteredDivisions: currentDivisions });
            }
        });
    };

    const closeWarningModal = () => {
        setExistingProducts({ ...existingProducts, isModalOpen: false });
    };

    const openCloseAll = async () => {
        let action;

        if (!isFilterMode) {
            action = checkWhatActionNeeded(state.divisions);
            openCloseAllProcess(state.divisions, action);
        } else if (isFilterMode) {
            action = checkWhatActionNeeded(filteredDivisions);
            openCloseAllProcess(filteredDivisions, action);
        }

        if (favoriteType === 1) {
            history.push(`/favorites`);
        }
        if (favoriteType === 0) {
            history.push(`/my-favorites`);
        }
    };

    const openCloseAllProcess = async (divisions: Array<Division<Product>>, isActionNeeded: boolean) => {
        if (!divisions || !divisions.length) {
            return;
        }
        for (let i = 0; i < divisions.length; i++) {
            const division = divisions[i];
            division.clicked = isActionNeeded;
            if (!division.sections || !division.sections.length) {
                return;
            }
            for (let j = 0; j < division.sections.length; j++) {
                const section = division.sections[j];
                section.clicked = isActionNeeded;
            }
        }
    }

    const checkWhatActionNeeded = (divisions: Array<Division<Product>>) => {
        for (let i = 0; i < divisions.length; i++) {
            const division = divisions[i];
            if (!division.clicked) {
                return true;
            }
            for (let j = 0; j < division.sections.length; j++) {
                const section = division.sections[j];
                if (!section.clicked) {
                    return true;
                }
            }
        }
        return false;
    }

    const filterProducts = (
        divs: Array<Division<MergedFavoriteProduct>>
    ) => {
        const filteredDs = new Array<Division<MergedFavoriteProduct>>();
        const strSearch = state.searchString ? state.searchString.toLowerCase() : state.searchString;
        for (const div of divs.values()) {
            const filteredSections = [];
            for (const sect of div.sections.values()) {
               const filteredProducts = sect.products.filter(
                   (product) => (product.productName && product.productName.toLowerCase().indexOf(strSearch) > -1) || 
                                (product.company && product.company.toLowerCase().indexOf(strSearch) > -1) );
               if (filteredProducts && filteredProducts.length > 0) {
                   const filteredSection =  { ...sect };
                   filteredSection.clicked = true;
                   filteredSection.products = filteredProducts;
                   filteredSections.push(filteredSection);
               }
            }
               if (filteredSections && filteredSections.length > 0) {
                   const filteredD = { ...div };
                   filteredD.clicked = true;
                   filteredD.sections = filteredSections;
                   filteredDs.push(filteredD);
               }
        }
        return filteredDs;
    };

    const getProductCount = (divisions: Array<Division<Product>>) => {
        let coun = 0;
        for (let i = 0; i < divisions.length; i++) {
            const division = divisions[i];
            for (let j = 0; j < division.sections.length; j++) {
                coun += division.sections[j].products.length;
            }
        }
        return coun;       
    }

    return (
        <FavoritePageContainer>
            <ProductsTabHeader>
                    <ProductsSearch
                         searchString={state.searchString}
                         cleanFilter={cleanFilter}
                         changeSearch={changeSearch}
                         handleSearchSubmit={submitSearch}
                    />
                    <SpaceEnd>
                        <FavoriteProductsAction
                            favoriteType={favoriteType}
                            selectedProducts={selectedProducts}
                            addProductsToFavorite={addProductsToFavorite}
                            addProductsToProject={addProductsToProject}
                            removeFavorites={removeFavorites}
                        />
                    </SpaceEnd>
            </ProductsTabHeader>

            {isFilterMode && !isFilteringInProgress && (
                <FoundResultsCount weight={400} size={14} height={18} color={blueLink}>
                    {!filteredDivisions.length || !getProductCount(filteredDivisions) === 0 ? 'No results found' : getProductCount(filteredDivisions) + ' result' + (getProductCount(filteredDivisions) > 1 ? 's' : '') + ' found' } 
                </FoundResultsCount>
            )}

            {(!isFilterMode && state.divisions.length) || (isFilterMode && filteredDivisions.length) ? (
                <InvisibleButton>
                        <OpenCloseAllButton onClick={openCloseAll} className="closeOpenAll">
                            <Text weight={400} size={12} height={14} color={blueLink}>
                                Open / close all
                            </Text>
                        </OpenCloseAllButton>
                </InvisibleButton>
                   ) : (''
            )}
            {state.pending || isFilteringInProgress ? (
                <FavoriteSpin size="large" spinning={state.pending || isFilteringInProgress}>
                    <FavoriteTabContent></FavoriteTabContent>
                </FavoriteSpin>
            ) : (
                <>
                  {!isFilterMode && (
                   <>
                    {state.divisions.length > 0 ? (
                        <FavoriteTabContent>
                            {state.divisions.map((division) => (
                                <FavoriteDivisionItem
                                    key={division.id}
                                    division={division}
                                    references={references}
                                    favoriteType={favoriteType}
                                    handleClicked={handleDivisionOpen}
                                    handleSectionOpen={handleSectionOpen}
                                    selectAllProducts={selectAllProducts}
                                    selectProduct={selectProduct}
                                />

//                                                key={division.key}
//                                                projectReferences={projectReferences}
//                                                projectInfo={projectInfo}
//                                                handleDeleteProduct={handleDeleteProduct}
//                                                updateDivisions={updateDivisions}
//                                                brandsInfo={brandsInformation}

                            ))}
                        </FavoriteTabContent>
                    ) : (
                        <FavoriteTabContent>
                            <NoProductsTitleContainer>
                                <h2>There are no products here yet</h2>
                            </NoProductsTitleContainer>
                        </FavoriteTabContent>
                    )}
                   </>
                  )}

                  {isFilterMode && (
                   <>
                    {filteredDivisions.length > 0 ? (
                        <FavoriteTabContent>
                            {filteredDivisions.map((division) => (
                                <FavoriteDivisionItem
                                    key={division.id}
                                    division={division}
                                    references={references}
                                    favoriteType={favoriteType}
                                    handleClicked={handleFilteredDivisionOpen}
                                    handleSectionOpen={handleFilteredSectionOpen}
                                    selectAllProducts={selectAllProducts}
                                    selectProduct={selectProduct}
                                />
                            ))}
                        </FavoriteTabContent>
                    ) : (
                        <FavoriteTabContent>
                            <NoProductsTitleContainerFiltered>
                                <center>
                                    <Image
                                        src="src/assets/images/empty-graphic 1.svg"
                                        preview={false}
                                        alt="no results found"
                                    />
                                    <h2><center>Clear search field to<br/>display products and try<br/>your search again.</center></h2>
                                </center>
                            </NoProductsTitleContainerFiltered>

                        </FavoriteTabContent>
                    )}
                   </>
                  )}

                </>
            )}
            {existingProducts.isModalOpen && (
                <ExistingProductsModal
                    isOpen={existingProducts.isModalOpen}
                    closeModal={closeWarningModal}
                    existingProducts={existingProducts.products}
                    addedTo={`${
                        favoriteType === FavoriteType.MyFavorite
                            ? 'Favorite Products Library'
                            : "office's Favorite Products Library"
                    }`}
                    addedFrom={'Transparency Catalog'}
                    currentPage={`${
                        favoriteType === FavoriteType.MyFavorite ? 'my-favorites' : 'favorites'
                    }`}
                />
            )}
        </FavoritePageContainer>
    );
};

export default FavoriteProducts;
