import styled from 'styled-components';
import { blue01, blueDark01, error, grayDark05, grayDark06, blueLink } from '../../../styles/colors';
import { Space, Modal, Row, Spin } from 'antd';

export const StartProjectModal = styled(Modal)`
    width: 680px !important;

    .ant-modal-body {
        padding: 32px 40px 40px 40px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }

    .ant-col {
        text-align: left;
    }

    .ant-input {
        width: 280px;
        border-radius: 3px;
        border-color: ${grayDark06};
        padding: 7px 10px 7px 10px;
        line-height: 16px;
        color: ${blueDark01};
        &::placeholder {
            color: ${grayDark05};
        }
    }

    .ant-form-item {
        margin-bottom: 0;

        .ant-select-selector {
            width: 280px;
            border-color: ${grayDark06};
            color: ${grayDark05};
        }

        .ant-select-single .ant-select-selector .ant-select-selection-item {
            color: ${blueDark01};
        }

        .ant-select-selector:hover {
            border-color: ${grayDark05};
            border-radius: 3px;
        }

        .ant-select-selector:focus,
        .ant-select-selector-focused {
            border-color: ${blue01};
            border-radius: 3px;
            box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
        }

        .ant-select-selector-status-error:not(.ant-select-selector-disabled):not(
                .ant-select-selector-borderless
            ).ant-select-selector,
        .ant-select-selector-status-error:not(.ant-select-selector-disabled):not(
                .ant-select-selector-borderless
            ).ant-select-selector:hover {
            border-color: ${error};
        }
    }

    .ant-form-horizontal .ant-form-item-label {
        line-height: 14px;
    }

    .ant-form-item-label > label {
        height: 16px;
        vertical-align: unset;
        margin-bottom: 4px;
        line-height: 16px !important;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        color: ${blueDark01} !important;

        &::before {
            display: none !important;
        }

        &::after {
            position: unset;
            top: 0;
            margin: 0;
        }
    }

    .ant-input:hover {
        border-color: ${grayDark05};
        border-radius: 3px;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: ${blue01};
        border-radius: 3px;
        box-shadow: 0 0 0 2px rgba(63, 153, 248, 0.3);
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        border-color: ${error};
    }

    .ant-checkbox-wrapper:hover > .ant-checkbox > .ant-checkbox-inner,
    .ant-checkbox:hover > .ant-checkbox-inner {
        border-color: ${grayDark05};
    }

    .ant-checkbox > .ant-checkbox-inner {
        border: 1px solid ${grayDark06};
        border-radius: 3px;
    }

    .ant-checkbox-checked > .ant-checkbox-inner {
        background-color: ${blue01};
        border-color: ${blue01};
        border-radius: 3px;
    }

    .ant-checkbox:hover::after,
    .ant-checkbox-wrapper:hover .ant-checkbox::after {
        border: none;
    }

    textarea.ant-input {
        width: 100%;
        height: 76px;
        padding: 7px 8px 7px 8px;
    }

    .ant-divider-horizontal {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const StartProjectSpace = styled(Space)`
    .ant-space-item {
        width: 100%;
    }
`;

export const StartProjectRow = styled(Row)`
    .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin: 0;
    }
    width: 100%;
    gap: 40px;
    margin-bottom: 20px;

    label {
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px !important;
        line-height: 16px !important;
        color: ${blueDark01} !important;
        white-space: nowrap;
    }
`;

export const ConfidentialContainer = styled('div')`
    gap: 10px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark01};

    .ant-checkbox {
        top: 0;
    }

    .ant-image {
        height: 18px;

        img {
            height: 18px;
        }
    }
`;

export const ConfidentialText = styled('div')`
    display: flex;
    gap: 4px;
    margin-top: 1px;
    align-items: baseline;
    img {
        margin-bottom: 2px;
    }
`;

export const StartNewProjectTitle = styled('div')`
    margin: 20px 0;
`;

export const StartProjectSpin = styled(Spin)`
    top: 0 !important;
`;

export const StartProjectTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -30px;
            left: 24px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 280px;
        }
    }
`;

export const ProjectVisibilityTextContainer = styled('div')`
    gap: -10px;
//    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    height: 58px;
    color: ${blueDark01};
`;
