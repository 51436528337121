import styled from 'styled-components';

export const PromoBlockWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;
    .rdw-image-modal-upload-option-label {
        display: none;
    }
`;

export const PromoBlockInnerWrapper = styled('div')`
    width: 550px;
    margin-top: 20px;
    margin-left: 115px;
    .rdw-image-imagewrapper {
        img {
            max-width: 321px;
            max-height: 121px;
        }
    }
`;

export const ListItemWrapper = styled('div')`
    display: flex;
    margin-bottom: 30px;
`;

export const ImageWrapper = styled('div')`
    margin-right: 15px;
`;

export const PageContainer = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const PreviewContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0px auto;
    height: 100%;
`;
