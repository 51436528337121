import { AvailabilityConfig, FunctionalityParams, FUNCTIONALITIES_PARAMS } from "./paramsConfig";

const functionalityAvailabilityMap = new Map<string, AvailabilityConfig>();

const setFunctionalityAvailabilityMap = (functionalityParamsArray: FunctionalityParams[]) => {
    functionalityParamsArray.forEach((params) => {
        functionalityAvailabilityMap.set(params.functionalityName, params.availabilityConfig);
    });
}

setFunctionalityAvailabilityMap(FUNCTIONALITIES_PARAMS);

export const isFunctionalityEnabled = (functionalityName: string): boolean => {
    const functionalityAvailability = functionalityAvailabilityMap.get(functionalityName.toLowerCase());

    if (!functionalityAvailability) {
        return false;
    }

    if (process.env.REACT_APP_URL?.toLowerCase().includes('staging')) {
        return functionalityAvailability.staging;
    }

    const env = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();

    return env?.length ? Boolean(functionalityAvailability[env]) : false;
}
