import React from 'react';
import { blueDark01 } from '../../../styles/colors';
import { Text, CloseIcon } from '../../../styles/common.styles';
import ModalFooter from '../modalFooter/ModalFooter';
import ModalTitle from '../modalTitle/ModalTitle';
import { ErrorModalContent, ErrorModalWrapper } from './ErrorModal.styles';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}

const ErrorModal: React.FC<Props> = ({ isOpen, closeModal }) => {
    return (
        <ErrorModalWrapper
            open={isOpen}
            onCancel={closeModal}
            centered
            footer={null}
            className="delete-modal-container"
            closeIcon={<CloseIcon />}
        >
            <ModalTitle title={`Something's wrong`} icon={'error.svg'} />
            <ErrorModalContent>
                <Text weight={400} height={18} size={14} color={blueDark01}>
                    The database is not available. Please try again later.
                </Text>
            </ErrorModalContent>
            <ModalFooter
                submit={closeModal}
                action={'Close'}
                cancel={closeModal}
                cancelProps={{
                    style: { display: 'none' },
                }}
            />
        </ErrorModalWrapper>
    );
};

export default ErrorModal;
