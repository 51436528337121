import React, { useEffect, useState } from 'react';
import { UploadsTableFields } from '../Uploads.types';
import {
    UploadsTableContainer,
    DownloadAllButton,
    DownloadButtonsContainer,
    PaginationContainer,
    DocumentTypeTextBlock,
    DocumentTypeButtonLinkBlock,
    DocumentTypeLinkBlock,
    DocumentTypeButtonBlock,
} from './UploadTable.styles';
import { useAppDispatch } from '../../../../hooks/redux';
import { FavoriteType, UploadsFieldKey } from '../../../../models/product/enums';
import { MergedFavoriteProduct } from '../../../favoritProducts/FavoriteProducts.types';
import { usePermissions } from '../../../../hooks/permissions';
import { SubscriptionTypes } from '../../../../models/subscription/enums';
import { AccountPermissionTypes } from '../../../../models/permission/enums';
import { getFavoriteFilesAction } from '../../../../redux/actions/favoriteProductsActions';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { SelectData } from '../../../../models/common/types';
import { Product } from '../../../../models/product/types';
import { getProductFilesAction, retrieveUploadFileAction, getSustainabilityDocumentTypesAction } from '../../../../redux/actions/productActions';
import { MFRProject } from '../../../../models/project/types';
import { UploadType, UploadedImageData, getPictureDocumentTypeId } from '../UploadsInfo';
import { capitalizeFirstLetter } from '../../../../helpers/textFormatter';
import DocumentsTable from './DocumentsTable';
import ImagesTable from './ImagesTable';
import CustomPagination from '../../../common/pagination/CustomPagination';
import { PrimaryButton, Text } from '../../../../styles/common.styles';

import {
    GetDocumentTypesResponse,
} from '../../../../redux/actions/productActions/types';


import FindDocuments from './FindDocuments';

interface Props {
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    project?: MFRProject;
    newFile: UploadsTableFields | null;
    allDocumentTypes: Array<SelectData> | undefined;
    uploadType: UploadType;
    setImagesCount: (count: number) => void;
}

export const getCSSClassNameByUploadType = (uploadType: UploadType, nestedClassName: string = ''): string => {
    return uploadType === UploadType.PICTURE ? `pictureTable${capitalizeFirstLetter(nestedClassName)}` : nestedClassName;
}

export const downloadFile = async (file: UploadsTableFields) => {
    if (!file) {
        return;
    }

    const blob = await retrieveUploadFileAction({ fileName: file.filePath });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${file.documentName}`;
    link.click();
}

const imagesPerPage = 8;

const UploadTable: React.FC<Props> = ({
    favoriteType,
    product,
    project,
    newFile,
    allDocumentTypes,
    uploadType,
    setImagesCount
}) => {
    const dispatch = useAppDispatch();
    const { getAccountRole, getAccountSubscription } = usePermissions();

    const [state, setState] = useState({
        sorting: 0,
        field: UploadsFieldKey.Id,
        files: Array<UploadsTableFields>(),
        pending: true,
    });

    const [open, setOpen] = useState({
        findDocuments: false,
    });

    const [images, setImages] = useState<Array<UploadedImageData>>([]);
    const [filteredImages, setFilteredImages] = useState<Array<UploadedImageData>>([]);
    const [imagesStartIndex, setImagesStartIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterInput, setFilterInput] = useState('');
    const [sustainabilityDocumentTypesInfo, setSustainabilityDocumentTypesInfo] = useState([]);

    useEffect(() => {
        filterImages();
    }, [filterInput]);

    useEffect(() => {
        filterImages();
    }, [images]);

    useEffect(() => {
        return () => {
            images.forEach(image => URL.revokeObjectURL(image.url));
        };
    }, []);

    useEffect(() => {
        getDocumentTypes(1, '');
    }, []);

    useEffect(() => {
        getUploadFiles(0, UploadsFieldKey.Id);
    }, []);

    useEffect(() => {
        getUploadFiles(state.sorting, state.field);
    }, [newFile]);

    const getDocumentTypes = async (page: number, searchString: string) => {
        const { payload } = (await dispatch(
            getSustainabilityDocumentTypesAction({
                curPage: page,
                searchString
            }),
        )) as ActionResponse<GetDocumentTypesResponse>;
        if (payload.data) {
            setSustainabilityDocumentTypesInfo(payload.data);
        }
    };

    const getUploadFiles = async (sortDirection: number, sortExpression: UploadsFieldKey) => {
        let result = Array<UploadsTableFields>();
        if (favoriteType !== undefined) {
            const { payload } = (await dispatch(
                getFavoriteFilesAction({
                    sortExpression,
                    sortDirection: sortDirection === 2 ? 'desc' : 'asc',
                    productFavoriteId: product.id,
                    favoriteType: favoriteType,
                }),
            )) as ActionResponse<Array<UploadsTableFields>>;
            if (payload.data) {
                result = payload.data;
            }
        } else {
            const { payload } = (await dispatch(
                getProductFilesAction({
                    uploadType: uploadType,
                    sortExpression,
                    sortDirection: sortDirection === 2 ? 'desc' : 'asc',
                    productId: product.product.productId,
                    projectId: product.product.projectId,
                    projectProductId: product.product.id,
                    productName: product.product.name?.length ? product.product.name : '',
                }),
            )) as ActionResponse<Array<UploadsTableFields>>;
            if (payload && payload.data) {
                result = payload.data;
            }
        }
        if (result) {
            result = result.filter((file) => file.filePath);
            const filteredFiles = filterFilesByDocumentType(result);
            setState({ ...state, files: filteredFiles, field: sortExpression, sorting: sortDirection });
            await getImagesWithUrl(filteredFiles);
        }
    };

    const filterFilesByDocumentType = (files: UploadsTableFields[]): UploadsTableFields[] => {
        const pictureDocumentTypeId = getPictureDocumentTypeId(allDocumentTypes);

        if (!allDocumentTypes || !pictureDocumentTypeId) {
            return [];
        }

        if (uploadType === UploadType.PICTURE) {
            return files.filter((file) => file.documentTypeId === pictureDocumentTypeId);
        }

        return files.filter((file) => file.documentTypeId !== pictureDocumentTypeId);
    }

    const getImageUrl = async (filePath: string) => {
        const blob = await retrieveUploadFileAction({ fileName: filePath });
        return URL.createObjectURL(blob);
    }

    const getImagesWithUrl = async (files: UploadsTableFields[]) => {
        if (uploadType !== UploadType.PICTURE) {
            return;
        }

        const promises = files.map(async (file, fileIndex) => ({
            file: file,
            name: file.documentName,
            url: await getImageUrl(file.filePath),
            altText: file.alternativeText,
            description: file.description,
            id: fileIndex,
        }))

        const imagesWithUrl = await Promise.all(promises);
        setImages(imagesWithUrl);
        setFilteredImages(imagesWithUrl);
    }

    const checkPermission = (): boolean => {
        if (favoriteType === FavoriteType.OfficeFavorite) {
            if (
                (getAccountSubscription() === SubscriptionTypes.OFFICE ||
                    getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE) &&
                getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN
            ) {
                return true;
            }
            return false;
        }
        return true;
    };

    const handleSort = (field: UploadsFieldKey) => {
        let sortDirection = 0;
        if (state.field !== field) {
            sortDirection = 1;
        } else {
            sortDirection = state.sorting === 2 ? 0 : state.sorting + 1;
        }
        setState({ ...state, field: field, sorting: sortDirection });
        getUploadFiles(sortDirection, field);
    };

    const removeUploadDocument = (document: UploadsTableFields) => {
        setState({ ...state, files: state.files.filter((file) => file.id !== document.id) });
    };

    const downloadAll = () => {
        downloadDocuments();
    };

    const downloadDocuments = async () => {
        for (let i = 0; i <= state.files.length; i++) {
            const file = state.files[i];
            if (file) {
                await downloadFile(file);
            }
        }
    };

    const changePageNumber = (pageNumber: number) => {
        setImagesStartIndex((pageNumber - 1) * imagesPerPage);
        setCurrentPage(pageNumber);
    }

    const handleCurrentPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentPage = Math.ceil(+event.currentTarget.value);
        if (currentPage === 0 || (currentPage === 1 && !filteredImages.length)) {
            setCurrentPage(currentPage);
        } else if (currentPage > 0 && currentPage <= filteredImages.length) {
            changePageNumber(currentPage);
        }
    };

    const getFilteredImages = () => {
        if (!filterInput.length) {
            return images;
        }
        return images.filter(image => image.name.toLowerCase().includes(filterInput.toLowerCase()));
    }

    const filterImages = () => {
        const sortedFilteredImages = getFilteredImages().sort((firstImage, secondImage) => {
            return firstImage.name.toLowerCase().localeCompare(secondImage.name.toLowerCase());
        });
        setFilteredImages(sortedFilteredImages);
    }

    const onFilterInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterInput(event.target.value);
    };

    const updateImage = (image: UploadedImageData) => {
        const updatedImages: UploadedImageData[] = [...images];
        const imageIndex = updatedImages.findIndex(updatedImage => updatedImage.id === image.id);
        updatedImages[imageIndex] = image;
        setImages(updatedImages);
    }

    const removeImage = (imageId: number) => {
        setImagesCount(images.length - 1);
        setImages(images.filter(image => image.id !== imageId));
    }

    const getContentTable = () => {
        if (uploadType === UploadType.DOCUMENT) {
            return (
                <DocumentsTable 
                    uploadType={uploadType}
                    state={state}
                    favoriteType={favoriteType}
                    product={product}
                    project={project}
                    allDocumentTypes={allDocumentTypes}
                    handleSort={handleSort}
                    checkPermission={checkPermission}
                    removeUploadDocument={removeUploadDocument}
                />
            );
        }

        return (
            <ImagesTable
                images={filteredImages.slice(imagesStartIndex, imagesStartIndex + imagesPerPage)}
                favoriteType={favoriteType}
                product={product}
                onFilterInputChanged={onFilterInputChanged}
                updateImage={updateImage}
                removeImage={removeImage}
                userRole={project?.userRole}
            />
        );
    }

    const getTotalPagesCount = () => {
        return filteredImages.length ? filteredImages.length : imagesPerPage;
    }

    const getImagesPagination = () => {
        return uploadType === UploadType.PICTURE ? (
            <PaginationContainer>
                <CustomPagination
                    page={currentPage}
                    total={getTotalPagesCount()}
                    pageSize={imagesPerPage}
                    changePageNumber={changePageNumber}
                    handleCurrentPage={handleCurrentPage}
                    maxPages={Math.ceil(filteredImages.length / imagesPerPage)}
                />
            </PaginationContainer>
        ) : (<></>);
    }

    const openFindDocuments = async () => {
        const action = 'findDocuments';
        setOpen({ ...open, [action]: true });
    };

    const closeModal = (data: { key: string }) => {
        setOpen({ ...open, [data.key]: false });
    };

    const reload = (key: ProjectAction, data?: any) => {
        switch (key) {
            case 'findDocuments':
                break;
            default:
                break;
        }
    };

    return (
        <UploadsTableContainer>
            {uploadType === UploadType.DOCUMENT && (
              <>
                <DocumentTypeTextBlock>
                    <b>Need more sustainability documents?</b> Visit the manufacturer's website, <a href="https://www.mindfulmaterials.com/" target="_blank">mindful MATERIALS</a> or any of the certification and disclosure providers' websites to download.
                </DocumentTypeTextBlock>
                <DocumentTypeButtonLinkBlock>
                    <DocumentTypeButtonBlock>
                        <PrimaryButton htmlType="submit" onClick={openFindDocuments} width={99} height={24} style={{marginTop: 0}}>
                            <Text weight={400} size={12} height={11} style={{marginTop: 2}}>
                                Find documents
                            </Text>
                        </PrimaryButton>
                    </DocumentTypeButtonBlock>
                    <DocumentTypeLinkBlock>
                        <a href="https://www.mindfulmaterials.com/a-common-language" target="_blank">Learn about the Common Materials Framework (CMF)</a>
                    </DocumentTypeLinkBlock>
                </DocumentTypeButtonLinkBlock>
              </>
            )}
            {getContentTable()}
            <DownloadButtonsContainer>
                <DownloadAllButton onClick={downloadAll} disabled={state.files.length === 0}>
                    Download all {uploadType.replace('picture', 'image')}s
                </DownloadAllButton>
                { getImagesPagination() }
            </DownloadButtonsContainer>
            {open.findDocuments && (
                <FindDocuments
                    isOpen={open.findDocuments}
                    documentTypesInfo={sustainabilityDocumentTypesInfo}
                    closeModal={closeModal}
                    reload={reload}
                />
            )}
        </UploadsTableContainer>
    );
};

export default UploadTable;
