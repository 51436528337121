import React from 'react';
import Switcher from '../../../components/common/switcher/Switcher';
import { Product } from '../../../models/product/types';
import { Section } from '../../../models/productDivision/types';
import { blueDark01 } from '../../../styles/colors';
import {
    MFRSectionItemDivider,
    MFRSectionItemWrapper,
    MFRSectionItemContainer,
    MFRSectionItemTitleContainer,
    MFRSectionTitle,
    MFRProductsContainer,
} from './MFRSection.styles';
import { Text } from '../../../styles/common.styles';
import MFRPProductItem from '../mfrProduct/MFRProduct';
import { ProductReferences } from '../../../redux/actions/productActions/types';
import { MFRProject } from '../../../models/project/types';

interface Props {
    section: Section<Product>;
    projectData: MFRProject;
    references: ProductReferences | null;
    editable: boolean;
    commentId?: string;
    selectedProductId?: string;
    openingMode?: string;
    showHelp: boolean;
    handleClicked: (sectionId: string) => void;
}

const MFRSectionItem: React.FC<Props> = ({
    section,
    references,
    editable,
    projectData,
    commentId,
    selectedProductId,
    openingMode,
    showHelp,
    handleClicked,
}) => {
    return (
        <>
            <MFRSectionItemDivider></MFRSectionItemDivider>
            <MFRSectionItemWrapper>
                <MFRSectionItemContainer>
                    <MFRSectionItemTitleContainer
                        onClick={() => {
                            handleClicked(section.id);
                        }}
                    >
                        <Switcher
                            id={section.id}
                            clicked={section.clicked}
                            handleSwitch={() => handleClicked(section.id)}
                            width={20}
                            height={20}
                            small
                        />
                        <MFRSectionTitle className="section-id hover">{section.id}</MFRSectionTitle>
                        <MFRSectionTitle className="hover">{section.name}</MFRSectionTitle>
                        <Text
                            weight={400}
                            size={16}
                            height={20}
                            color={blueDark01}
                            className="hover"
                        >
                            ({section.products.length})
                        </Text>
                    </MFRSectionItemTitleContainer>
                </MFRSectionItemContainer>
            </MFRSectionItemWrapper>
            <MFRProductsContainer className={section.clicked ? 'clicked' : ''}>
                {section.products.map((product: Product, productIdx: number) => (
                    <MFRPProductItem
                        references={references}
                        product={product}
                        productsCount={
                            section.products.filter(
                                (p) => p.mfSection.number === product.mfSection.number,
                            ).length
                        }
                        key={product.id}
                        editable={editable}
                        projectData={projectData}
                        commentId={commentId}
                        selectedProductId={selectedProductId}
                        openingMode={openingMode}
                        showHelp={showHelp}
                        showProductItemHeader={productIdx === 0}
                    />
                ))}
            </MFRProductsContainer>
        </>
    );
};

export default MFRSectionItem;
