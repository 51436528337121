import React from 'react';
import { RecentlyProjectsTableFields } from '../../models/project/types';
import { ActivitiesName } from '../../models/projectActivity/enums';
import { retrieveUploadFileAction } from '../../redux/actions/productActions';
import { UploadsTableFields } from '../productInfo/uploads/Uploads.types';
import {
    FileNameLink,
    NotificationLink,
    ProductLink,
    ProductNameText,
} from './GenerateProjectNotificationLink.styles';

interface Props {
    data: RecentlyProjectsTableFields;
}
const GenerateProjectNotificationLink: React.FC<Props> = ({ data }) => {
    const projectProductLink = data.projectProduct?.isDeleted ? (
        <ProductNameText>{data.projectProduct?.name}</ProductNameText>
    ) : (
        <ProductLink to={`/product-info/${data.projectId}/${data.projectProduct?.productId}`+ (data.projectProduct?.id ? `${data.projectProduct?.id}` : `-1`) }>
            {data.projectProduct?.name}
        </ProductLink>
    );

    const uploadDocument = async (file: UploadsTableFields | undefined) => {
        if (file) {
            const blob = await retrieveUploadFileAction({ fileName: file.filePath });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${file.documentName}`;
            link.click();
        }
    };
    const constructLink = () => {
        if (ActivitiesName[data.activityName] === ActivitiesName.ProductAdded) {
            return <span>added product {projectProductLink}</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProductDeleted) {
            return <span>deleted product {projectProductLink}</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProductUpdated) {
            return <span>updated product {projectProductLink}</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProductMarkedAsInstalled) {
            return <span>marked {projectProductLink} as installed</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProductMarkedAsUninstalled) {
            return <span>marked {projectProductLink} as uninstalled</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.CommentAdded) {
            if (data.projectProduct?.isDeleted) {
                return (
                    <span>
                        added comment to{' '}
                        <ProductNameText>{data.projectProduct?.name}</ProductNameText>
                    </span>
                );
            } else {
                return (
                    <span>
                        added comment to{' '}
                        <ProductLink
                            to={`/product-info/${data.projectId}/${data.projectProduct?.productId}/${data.projectProductCommentId}`+ (data.projectProduct?.id ? `${data.projectProduct?.id}` : `-1`)}
                        >
                            {data.projectProduct?.name}
                        </ProductLink>{' '}
                    </span>
                );
            }
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.CommentDeleted) {
            if (data.projectProduct?.isDeleted) {
                return (
                    <span>
                        deleted comment from{' '}
                        <ProductNameText>{data.projectProduct?.name}</ProductNameText>
                    </span>
                );
            } else {
                return (
                    <span>
                        deleted comment from{' '}
                        <ProductLink
                            to={`/product-info/${data.projectId}/${data.projectProduct?.productId}/${data.projectProductCommentId}` + (data.projectProduct?.id ? `${data.projectProduct?.id}` : `-1`)}
                        >
                            {data.projectProduct?.name}
                        </ProductLink>{' '}
                    </span>
                );
            }
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.UploadedFile) {
            if (data.projectProduct?.isDeleted) {
                return (
                    <span>
                        uploaded
                        {`in `} {projectProductLink}
                    </span>
                );
            } else {
                return (
                    <span>
                        uploaded{' '}
                        <FileNameLink onClick={() => uploadDocument(data.projectProductUpload)}>
                            {data.projectProductUpload?.documentName}
                        </FileNameLink>{' '}
                        {`in `} {projectProductLink}
                    </span>
                );
            }
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.DeletedFile) {
            return (
                <span>
                    deleted{' '}
                    <ProductNameText>{data.projectProductUpload?.documentName}</ProductNameText>{' '}
                    {`from `} {projectProductLink}
                </span>
            );
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.UpdatedFile) {
            if (data.projectProduct?.isDeleted) {
                return (
                    <span>
                        updated file info <span>{data.projectProductUpload?.documentName}</span>
                        {`in `} {projectProductLink}
                    </span>
                );
            } else {
                return (
                    <span>
                        updated file info{' '}
                        <FileNameLink onClick={() => uploadDocument(data.projectProductUpload)}>
                            {data.projectProductUpload?.documentName}
                        </FileNameLink>{' '}
                        {`in `} {projectProductLink}
                    </span>
                );
            }
        }

        if (ActivitiesName[data.activityName] === ActivitiesName.UploadedImage) {
            if (data.projectProduct?.isDeleted) {
                return (
                    <span>
                        uploaded
                        {`in `} {projectProductLink}
                    </span>
                );
            } else {
                return (
                    <span>
                        uploaded{' '}
                        <FileNameLink onClick={() => uploadDocument(data.projectProductUpload)}>
                            {data.projectProductUpload?.documentName}
                        </FileNameLink>{' '}
                        {`in `} {projectProductLink}
                    </span>
                );
            }
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.DeletedImage) {
            return (
                <span>
                    deleted{' '}
                    <ProductNameText>{data.projectProductUpload?.documentName}</ProductNameText>{' '}
                    {`from `} {projectProductLink}
                </span>
            );
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.UpdatedImage) {
            if (data.projectProduct?.isDeleted) {
                return (
                    <span>
                        updated file info <span>{data.projectProductUpload?.documentName}</span>
                        {`in `} {projectProductLink}
                    </span>
                );
            } else {
                return (
                    <span>
                        updated file info{' '}
                        <FileNameLink onClick={() => uploadDocument(data.projectProductUpload)}>
                            {data.projectProductUpload?.documentName}
                        </FileNameLink>{' '}
                        {`in `} {projectProductLink}
                    </span>
                );
            }
        }


        if (ActivitiesName[data.activityName] === ActivitiesName.PricingUpdated) {
            if (data.projectProduct?.isDeleted) {
                return (
                    <span>
                        updated pricing{' '}
                        <ProductNameText>{data.projectProduct?.name}</ProductNameText>
                    </span>
                );
            } else {
                return (
                    <span>
                        updated pricing{' '}
                        <ProductLink
                            to={`/product-info/${data.projectId}/${data.projectProduct?.productId}/0` + (data.projectProduct?.id ? `${data.projectProduct?.id}` : `-1`)}
                        >
                            {data.projectProduct?.name}
                        </ProductLink>{' '}
                    </span>
                );
            }
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.NewProjectAddedToProjectLibrary) {
            return <span>created project</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProjectShared) {
            return <span>shared project to {data.emailList}</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProjectShareRemoved) {
            return <span>{`access to project for ${data.emailList} was removed`}</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProjectDeleted) {
            return <span>{`project deleted`}</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProjectPropertiesUpdated) {
            return <span>{`updated project`}</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.ProjectCompleted) {
            return <span>{`project was completed`}</span>;
        }
        if (ActivitiesName[data.activityName] === ActivitiesName.InvitationAccepted) {
            return <span>{`accepted invitation to this project. `}</span>;
        }
        return <span>{`updated project`}</span>;
    };
    return <NotificationLink>{constructLink()}</NotificationLink>;
};

export default GenerateProjectNotificationLink;
