import React from 'react';
import { SubscriptionPlan } from '../../redux/actions/subscriptionActions/types';
import { style } from './Subscription.styles';

export interface PlanCardModel {
    titleContainerStyle: React.CSSProperties;
    title: string;
    subtitle: string;
    content: Array<string>;
    buttonValue: string;
    titleStyle: React.CSSProperties;
    plan?: SubscriptionPlan;
}

export const getCards = (plans: Array<SubscriptionPlan>): Array<PlanCardModel> => [
    {
        title: 'Free',
        titleContainerStyle: style.mainHeader,
        subtitle: `$${plans.find((plan) => plan.name === 'Free')?.price}`,
        content: ['Single users only, limited features'],
        buttonValue: 'Free',
        titleStyle: style.mainTitle,
    },
    {
        title: 'Individual',
        titleContainerStyle: style.mainHeader,
        subtitle: `$${plans.find((plan) => plan.name === 'Individual')?.price}`,
        content: ['Single users, many more features'],
        buttonValue: 'Individual',
        titleStyle: style.mainTitle,
    },
    {
        title: 'Office',
        titleContainerStyle: style.mainHeader,
        subtitle: `$${plans.find((plan) => plan.name === 'Office')?.price}`,
        content: ['Single offices who need team features'],
        buttonValue: 'Office',
        titleStyle: style.mainTitle,
    },
    {
        title: 'header',
        titleContainerStyle: style.header,
        subtitle: '',
        content: [
            'One project',
            'One user',
            'Project library to sort & track projects',
            '25 favorite products',
        ],
        buttonValue: '',
        titleStyle: style.titleHide,
    },
    {
        title: 'Core features',
        titleContainerStyle: style.header,
        subtitle: '',
        content: [
            'Unlimited projects',
            'One user',
            'Project library to sort & track projects',
            'Unlimited favorite products',
        ],
        buttonValue: '',
        titleStyle: style.secondaryTitle,
    },
    {
        title: 'header',
        titleContainerStyle: style.header,
        subtitle: '',
        content: [
            'Unlimited projects',
            'Unlimited users in a single office location',
            'Project library to sort & track projects',
            'Unlimited favorite products',
        ],
        buttonValue: '',
        titleStyle: style.titleHide,
    },
    {
        title: 'header',
        titleContainerStyle: style.header,
        subtitle: '',
        content: ['Export project specifications for bid or submittal'],
        buttonValue: '',
        titleStyle: style.titleHide,
    },
    {
        title: 'Project export',
        titleContainerStyle: style.header,
        subtitle: '',
        content: ['Export project specifications for bid or submittal'],
        buttonValue: '',
        titleStyle: style.secondaryTitle,
    },
    {
        title: 'header',
        titleContainerStyle: style.header,
        subtitle: '',
        content: ['Export project specifications for bid or submittal'],
        buttonValue: '',
        titleStyle: style.titleHide,
    },
    {
        title: 'header',
        titleContainerStyle: style.header,
        subtitle: '',
        content: [
            'Share projects with clients, contractors and colleagues for reviews & feedback (view only)',
        ],
        buttonValue: '',
        titleStyle: style.titleHide,
    },
    {
        title: 'Project management',
        titleContainerStyle: style.header,
        subtitle: '',
        content: [
            'Share projects with clients,  contractors and colleagues for reviews & feedback  (view & comment only)',
            'Upload spec & credit documentation',
        ],
        buttonValue: '',
        titleStyle: style.secondaryTitle,
    },
    {
        title: 'header',
        titleContainerStyle: style.header,
        subtitle: '',
        content: [
            'Share projects with clients, contractors and colleagues for reviews & feedback  (view, comment, edit)',
            'Upload spec & credit documentation',
            'Create project templates for easy replication',
        ],
        buttonValue: '',
        titleStyle: style.titleHide,
    },
    {
        title: 'header',
        titleContainerStyle: style.header,
        subtitle: '',
        content: ['None'],
        buttonValue: 'Free',
        titleStyle: style.titleHide,
    },
    {
        title: 'Team features',
        titleContainerStyle: style.header,
        subtitle: '',
        content: ['None'],
        buttonValue: 'Individual',
        titleStyle: style.secondaryTitle,
    },
    {
        title: 'header',
        titleContainerStyle: style.header,
        subtitle: '',
        content: ['Import projects into other projects'],
        buttonValue: 'Office',
        titleStyle: style.titleHide,
    },
];
