import React from 'react';
import * as MaterialProgramTip from '../../MaterialProgramTip';
import { TooltipStyles } from '../../../Tooltip.styles';

const V3_1Tip: React.FC = () => {
    return (
        <MaterialProgramTip.Container>
            <TooltipStyles className="shadow">
                <div className="tooltip tooltip-c2c">
                    <h1>Cradle to Cradle Certified®</h1>
                    <h2>Program</h2>
                    <p>
                        Cradle to Cradle (C2C) Certified® is a global standard for products that are
                        safe, circular and responsibly made. It assesses the safety, circularity and
                        responsibility of materials and products across five categories of
                        sustainability performance: Material Health, Product Circularity, Clean Air
                        & Climate Protection, Water & Soil Stewardship, Social Fairness.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <img src="src/assets/images/verified.png" alt="" />{' '}
                        <b>All C2C Certifications are third-party verified.</b>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Companies must work with an{' '}
                        <a
                            href="https://www.c2ccertified.org/get-certified/find-an-assessor"
                            target="_blank"
                        >
                            independent assessment body
                        </a>{' '}
                        to assess and verify a product’s performance across each of the C2C
                        Certified performance categories.
                    </p>
                    <h2>Results</h2>
                    <div className="tooltip-content">
                        <div className="tooltip-row">
                            <p>
                                C2C certification is awarded based upon four ascending levels of
                                achievement. Each performance category (Material Health, Product
                                Circularity, Clean Air & Climate Protection, Water & Soil
                                Stewardship, Social Fairness) is awarded an achievement level and
                                the product’s lowest category achievement level determines its
                                overall certification level. Certification must be renewed every two
                                years with measurable improvement required at each recertification.
                            </p>
                        </div>
                        <div className="tooltip-row">
                            <div className="circle platinum"></div>
                            <p>
                                <strong>Platinum</strong>
                            </p>
                        </div>
                        <div className="tooltip-row">
                            <div className="circle gold"></div>
                            <p>
                                <strong>Gold</strong>
                            </p>
                        </div>
                        <div className="tooltip-row">
                            <div className="circle silver"></div>
                            <p>
                                <strong>Silver</strong>
                            </p>
                        </div>
                        <div className="tooltip-row">
                            <div className="circle bronze"></div>
                            <p>
                                <strong>Bronze</strong>
                            </p>
                        </div>
                        <div className="tooltip-row">
                            <p>
                                Products with active certification are listed in the publicly
                                available{' '}
                                <a
                                    href="https://www.c2ccertified.org/products/registry"
                                    target="_blank"
                                >
                                    C2C Certified Product Registry
                                </a>
                                .
                            </p>
                        </div>
                        <div className="tooltip-row">
                            <p>
                                <a href="https://www.c2ccertified.org/" target="_blank">
                                    Visit the Cradle to Cradle Products Innovation Institute website
                                    to learn more.
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </TooltipStyles>
        </MaterialProgramTip.Container>
    );
};

export default V3_1Tip;
