import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Form, Input, Image, Col } from 'antd';
import CustomSelect from '../../common/select/CustomSelect';
import CustomInput from '../../common/inputs/CustomInput';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import WhoCanSeeThisProject from '../../tips/WhoCanSeeThisProject';
import {
    createProjectAction,
    getProjectReferencesAction,
    getProjectTemplatesAction,
} from '../../../redux/actions/projectActions';
import {
    CreateProjectRequest,
    GetProjectReferencesResponse,
} from '../../../redux/actions/projectActions/types';
import { setAddNewProjectMark } from '../../../redux/reducers/projectsReducer/ProjectSlice';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ModalTitle from '../../common/modalTitle/ModalTitle';
import ProductsModal from '../../productsModal/ProductsModal';
import { useModalState } from '../../../hooks/modalState';
import { usePermissions } from '../../../hooks/permissions';
import {
    ConfidentialContainer,
    ConfidentialText,
    StartProjectModal,
    StartProjectRow,
    StartProjectSpace,
    StartProjectSpin,
    StartProjectTipContainer,
    ProjectVisibilityTextContainer,
} from './StartProject.styles';
import {
    addProductsToProjectAction,
    getProductIdsByPackageIdAction,
} from '../../../redux/actions/productActions';
import { GetProductIdsByPackageIdResponse } from '../../../redux/actions/productActions/types';
import { SubscriptionTypes } from '../../../models/subscription/enums';

import { CloseIcon } from '../../../styles/common.styles';
import ModalFooter from '../../common/modalFooter/ModalFooter';
import { AvailableProject, Project } from '../../../models/project/types';
import { ActionResponse } from '../../../models/actionResponse/types';
import ChooseExistingProject from './chooseExistingProject/ChooseExistingProject';
import { ProjectTemplate } from '../../../models/template/types';
import ErrorModal from '../../common/errorModal/ErrorModal';
const { TextArea } = Input;

interface Props {
    isOpen: boolean;
    showProjects?: boolean;
    fromFavorite?: boolean;
    handleCancel: () => void;
    addProductsToProject?: (projectId: number) => void;
}

const StartProject: React.FC<Props> = ({
    isOpen,
    showProjects,
    fromFavorite,
    handleCancel,
    addProductsToProject,
}) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { authReducer, projectReducer } = selector;
    const [form] = Form.useForm();
    const selectProductButton = useRef(null);
    const isSelectProductClicked = useRef(false);
    const productsModal = useModalState();
    const errorModal = useModalState();
    const [references, setReferences] = useState<GetProjectReferencesResponse>({
        allBuildingTypes: [],
        allProjectStatuses: [],
        allTemplates: [],
        allProjectGroups: [],
        allProjectVisibilities: [],
    });
    const [state, setState] = useState({
        isConfidential: false,
    });
    const [productsPackageId, setProductsPackageId] = React.useState('');
    const setPPId = (pPId: string) => {
        setProductsPackageId(pPId);
    };
    const [selectedProject, setSelectedProject] = useState<AvailableProject | null>(null);

    const { getAccountSubscription } = usePermissions()

    useEffect(() => {
        getReferences();
        dispatch(setAddNewProjectMark(false));
        const selectProductRef = selectProductButton?.current as any;
        selectProductRef?.addEventListener('click', selectProductClick, false);
        () => {
            return selectProductRef?.removeEventListener('click', selectProductClick, false);
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue(selectedProject);
        if (selectedProject) {
            setState({ ...state, isConfidential: selectedProject.isConfidential });
        } else {
            setState({ ...state, isConfidential: false });
            form.resetFields();
        }
    }, [selectedProject]);

    useEffect(() => {
        if (projectReducer.viewingProject?.isTemplate) {
            form.setFieldsValue(projectReducer.viewingProject);
            setState({
                ...state,
                isConfidential: projectReducer.viewingProject.isConfidential || false,
            });
        }
    }, []);
    const selectProductClick = () => {
        isSelectProductClicked.current = true;
    };

    const getReferences = async () => {
        const { payload } = (await dispatch(getProjectReferencesAction())) as any;
        const templates = await getTemplates();
        setReferences({ ...payload.data, allTemplates: templates || [] });

        references.allProjectVisibilities = payload.data.allProjectVisibilities;
        references.allTemplates = templates;

        if (projectReducer.viewingProject?.isTemplate) {
            selectTemplate(projectReducer.viewingProject.id, true);
        }
    };

    const getTemplates = async () => {
        if (authReducer.user?.accountId) {
            const { payload } = (await dispatch(getProjectTemplatesAction())) as ActionResponse<
                Array<ProjectTemplate>
            >;
            if (payload?.data) {
                return payload.data;
            } else {
                return [];
            }
        }
        return [];
    };

    const handleCheckbox = (event: CheckboxChangeEvent) => {
        setState({ ...state, isConfidential: event.target.checked });
    };

    const confidentialityCheckbox = () => {
        return (
            <ConfidentialContainer>
                <Checkbox
                    name="isConfidential"
                    onChange={handleCheckbox}
                    checked={state.isConfidential}
                    disabled={!!selectedProject}
                />
                <ConfidentialText>
                    This is a confidential client project.{' '}
                    <StartProjectTipContainer>
                        <Image
                            width={13}
                            preview={false}
                            src="src/assets/images/circle-question.svg"
                            alt="tooltip"
                        />
                        <div className="tip">
                            A <strong>Confidential</strong> Project excludes Project name<br/> and location from manufacturer analytics.
                        </div>
                    </StartProjectTipContainer>
                </ConfidentialText>
            </ConfidentialContainer>
        );
    };

    const onSubmit = async (data: CreateProjectRequest) => {
        if (selectedProject) {
            addProductsAfterProjectCreation(selectedProject.id);
            return;
        }
        if (authReducer.user?.accountId) {
            const { payload } = (await dispatch(
                createProjectAction({
                    ...data,
                    accountId: authReducer.user?.accountId,
                    isConfidential: state.isConfidential,
                }),
            )) as ActionResponse<Project>;
            if (payload?.data) {
                if (showProjects) {
                    addProductsAfterProjectCreation(payload.data.id);
                    return;
                }
                if (productsPackageId.length > 30) {
                    await getProductIdsForProject(payload.data.id);
                } else {
                    handleCancel();
                }
                if (isSelectProductClicked.current) {
                    window.location.href = `${process.env.REACT_APP_TC_URL}/search?project=${payload.data?.id}`;
                } else {
                    window.location.href = `/project-info/${payload.data?.id}`;
                }
            }
        }
    };

    const getProductIdsForProject = async (projectId: number) => {
        if (productsPackageId) {
            const { payload } = (await dispatch(
                getProductIdsByPackageIdAction({
                    productPackageID: productsPackageId,
                }),
            )) as GetProductIdsByPackageIdResponse;
            if (payload.result) {
                const products = payload.result.map((r) => r.productSelectedOnTcId);
                await dispatch(addProductsToProjectAction({ products, projectId }));
            }
        }
    };

    const addProductsAfterProjectCreation = (projectId: number) => {
        if (fromFavorite && addProductsToProject) {
            addProductsToProject(projectId);
            handleCancel();
        }
    };

    const onFailed = () => {
        isSelectProductClicked.current = false;
    };

    const selectTemplate = (templateId: number, startFromTemplate: boolean) => {
        if (templateId === 0) {
            form.resetFields();
            setReferences({
                ...references,
                allTemplates: references.allTemplates.filter((t) => t.id !== templateId),
            });
            setState({ ...state, isConfidential: false });
            return;
        }
        const selectedTemplate = {
            ...references.allTemplates.find(
                (template) => template.id.toString() === templateId.toString(),
            ),
        };

        const projectName = form.getFieldValue('name');
        if (selectedTemplate) {
            selectedTemplate.name = projectName ? projectName : selectedTemplate.name + ' copy';
        }
        if (startFromTemplate === true) {
            selectedTemplate.name = projectName + ' copy';
            selectedTemplate.projectTemplateId = projectReducer.viewingProject?.id; 
        }
        form.setFieldsValue({
            ...selectedTemplate,
            projectLibraryVisibilityId:
                selectedTemplate?.projectLibraryVisibilityId ||
                references.allProjectVisibilities[0].id,
        });
        const currentTemplates = references.allTemplates as any;
        if (!references.allTemplates.find((t) => t.id === 0)) {
            currentTemplates.unshift({ name: 'None', id: 0 });
        }
        setReferences({ ...references, allTemplates: currentTemplates });
        setState({ ...state, isConfidential: !!selectedTemplate?.isConfidential });
    };

    return (
        <StartProjectModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <StartProjectSpin spinning={projectReducer.pending}>
                <Form.Item>
                    <ModalTitle
                        title={showProjects ? 'Start/add to project' : 'Start a project'}
                        icon="ic-modal-title-start-add-project.svg"
                    />
                </Form.Item>
                {showProjects && <ChooseExistingProject setSelectedProject={setSelectedProject} />}
                <Form
                    onFinish={onSubmit}
                    onFinishFailed={onFailed}
                    initialValues={{ projectLibraryVisibilityId: 1 }}
                    form={form}
                >
                    <StartProjectRow>
                        <Col>
                            <CustomInput
                                Component={Input}
                                label="Project name"
                                name="name"
                                required
                                rules={[
                                    { required: true, message: '' },
                                    {
                                        max: 200,
                                        message: 'Value should be less than 200 characters',
                                    },
                                ]}
                                props={{ disabled: !!selectedProject }}
                                block
                            />
                        </Col>
                        <Col>
                            <CustomSelect
                                label="Template"
                                name="projectTemplateId"
                                options={references.allTemplates}
                                placeholder="Select template"
                                props={{
                                    onChange: selectTemplate,
                                    disabled: !!selectedProject || projectReducer.viewingProject?.isTemplate,
                                    showSearch: true,
                                }}
                                block
                            />
                        </Col>
                    </StartProjectRow>
                    <StartProjectRow>
                        <Col>
                            <CustomInput
                                Component={Input}
                                label="Project ID"
                                name="externalProjectId"
                                rules={[
                                    {
                                        max: 250,
                                        message: 'Value should be less than 250 characters',
                                    },
                                ]}
                                props={{ disabled: !!selectedProject || getAccountSubscription() === SubscriptionTypes.FREE }}
                                block
                            />
                        </Col>
                        <Col>
                            <CustomSelect
                                label="Project / building type*"
                                name="buildingTypeId"
                                options={references.allBuildingTypes}
                                placeholder="Select building type"
                                rules={[{ required: true, message: '' }]}
                                props={{ disabled: !!selectedProject }}
                                block
                            />
                        </Col>
                    </StartProjectRow>
                    <StartProjectRow>
                        <Col style={{ width: '280' + `px` }}>
                            <CustomInput
                                label="Confidentiality"
                                name="isConfidential"
                                Component={confidentialityCheckbox}
                                block
                            />
                        </Col>
                        <Col>
                            <CustomSelect
                                label="Project status*"
                                name="projectStatusId"
                                options={references.allProjectStatuses}
                                placeholder="Select project status"
                                rules={[{ required: true, message: '' }]}
                                props={{ disabled: !!selectedProject }}
                                block
                            />
                        </Col>
                    </StartProjectRow>
                    <StartProjectRow>
                        <Col style={{ width: '280' + `px` }}>
                        </Col>
                        <Col>
                          <ProjectVisibilityTextContainer className='altTextContainer'>
                            <CustomSelect
                                label="Who can see this Project*"
                                name="projectLibraryVisibilityId"
                                options={references.allProjectVisibilities}
                                placeholder="Select project status"
                                rules={[{ required: true, message: '' }]}
                                props={{ disabled: !!selectedProject }}
                                block
                            />
                            <WhoCanSeeThisProject page="StartProject" />
                          </ProjectVisibilityTextContainer>
                        </Col>
                    </StartProjectRow>
                    <StartProjectSpace style={{ width: '100%' }} className="text-area-container">
                        <CustomInput
                            Component={TextArea}
                            label="Description"
                            name="description"
                            block
                            props={{
                                placeholder:
                                    'Can include project background info, summarized design brief and efficiency strategies to be explored. Limited to 3000 characters.',
                                rows: 3,
                                disabled: !!selectedProject,
                            }}
                            rules={[
                                {
                                    max: 3000,
                                    message: 'Value should be less than 3000 characters',
                                },
                            ]}
                        />
                    </StartProjectSpace>
                    <ModalFooter
                        submit={() => {}}
                        action={selectedProject ? 'Add products' : 'Save project'}
                        actionButtonWidth={106}
                        cancel={() => {}}
                        cancelText="Select products"
                        cancelButtonWidth={123}
                        cancelProps={{
                            htmlType: 'submit',
                            ref: selectProductButton,
                            style: showProjects ? { display: 'none' } : {},
                        }}
                    />
                </Form>
            </StartProjectSpin>
            {productsModal.isOpen && (
                <ProductsModal
                    isOpen={productsModal.isOpen}
                    closeModal={productsModal.onClose}
                    setProductsPackageId={setPPId}
                ></ProductsModal>
            )}
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </StartProjectModal>
    );
};

export default StartProject;
