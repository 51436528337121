import React from 'react';
import { Card, Typography } from 'antd';
import { PlanCardModel } from './Subscription.mockData';

import { ChoosePlanButton, style, TrialButton } from './Subscription.styles';
import '../../styles/overlapStyles/subscription.scss';
interface Props {
    card: PlanCardModel;
    choosePlan: (planName: string) => void;
    handleUsersChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    usersValue: number;
}

const SubscriptionCard: React.FC<Props> = ({
    card,
    choosePlan,
    handleUsersChange,
    usersValue = 0,
}) => {
    const submitPlan = (event: React.MouseEvent<HTMLButtonElement>) => {
        choosePlan(event.currentTarget.value);
    };
    const submitTrialPlan = (event: React.MouseEvent<HTMLButtonElement>) => {
        choosePlan('FreeTrial' + event.currentTarget.value);
    };
    return (
        <Card style={style.card} className="choose-plan-card">
            <div style={card.titleContainerStyle}>
                {' '}
                <Typography.Title level={4} style={card.titleStyle}>
                    {card.title}
                </Typography.Title>
            </div>
            <div style={style.cardBody}>
                <Typography.Title level={4}>{card.subtitle}</Typography.Title>
                {card.content.map((item, index) => (
                    <div key={index} style={style.contentItem} className="choose-plan-content-item">
                        {item}
                    </div>
                ))}
                <div
                    style={
                        card.buttonValue === 'Office' ? style.usersInputContainer : style.titleHide
                    }
                >
                    <label htmlFor="users">Users number</label>
                    <input
                        style={style.usersInput}
                        onChange={handleUsersChange}
                        name="users"
                        type="number"
                        value={usersValue}
                        min={0}
                        max={100}
                    />
                </div>
                <div style={style.buttonContainer}>
                    {card.buttonValue && (
                        <>
                            <ChoosePlanButton value={card.buttonValue} onClick={submitPlan}>
                                Sign up now
                            </ChoosePlanButton>
                            {card.buttonValue !== 'Free' && (
                                <TrialButton
                                    style={style.C}
                                    value={card.buttonValue}
                                    onClick={submitTrialPlan}
                                >
                                    {' '}
                                    Try It 30 days for Free
                                </TrialButton>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default SubscriptionCard;
