import React, { useState, useRef, useEffect } from 'react';
import {
    PromoBlockWrapper,
    PromoBlockInnerWrapper,
    ListItemWrapper,
    ImageWrapper,
    PageContainer,
    PreviewContainer,
} from './PromoBlockConstructor.styles';
import PromoBlockItem from '../../components/promoBlocks/PromoBlockItem/PromoBlockItem';
import { Image } from 'antd';
import {
    changeOrderAction,
    getBannersAction,
    getPromoTypesAction,
} from '../../../redux/actions/bannerActions';
import { useAppDispatch } from '../../../hooks/redux';
import { useModalState } from '../../../hooks/modalState';
import { Banner } from '../../../models/common/types';
import { SelectData } from '../../../models/common/types';
import BannerBlock from '../../../components/bannerDisplay/banner/BannerBlock';
import ErrorModal from '../../../components/common/errorModal/ErrorModal';
import { getCorrectPromoType } from '../../../helpers/bannerHelper';
import PageHeader from '../../../components/common/pageHeader/PageHeader';

interface Props {}

const PromoBlockConstructor: React.FC<Props> = ({}) => {
    const dispatch = useAppDispatch();
    const [bannerList, setBannerList] = useState<Array<Banner>>([]);
    const [promoTypes, setPromoTypes] = useState<Array<SelectData>>([]);
    const errorModal = useModalState();
    const dragItem = useRef<number>();
    const dragOverItem = useRef<number>();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setPromoTypes(await getPromoTypes());
        setBannerList(await getBanners());
    };

    const getBanners = async () => {
        const { payload } = (await dispatch(getBannersAction())) as any;
        if (payload?.data) {
            return payload?.data;
        }
        return [];
    };

    const getPromoTypes = async () => {
        const { payload } = (await dispatch(getPromoTypesAction())) as any;
        if (payload?.data) {
            return payload?.data;
        }
        return [];
    };

    const updateBannersOrder = async () => {
        await dispatch(changeOrderAction(bannerList.map((x) => x.id)));
    };

    const dragStart = (position: number) => {
        dragItem.current = position;
    };

    const dragEnter = (position: number) => {
        dragOverItem.current = position;
    };

    const drop = () => {
        const dragIndex = dragItem.current ?? 0;
        const dragOverIndex = dragOverItem.current ?? 0;
        const copyListItems = [...bannerList];
        const dragItemContent = copyListItems[dragIndex];
        copyListItems.splice(dragIndex, 1);
        copyListItems.splice(dragOverIndex, 0, dragItemContent);
        dragItem.current = undefined;
        dragOverItem.current = undefined;
        setBannerList(copyListItems);
    };

    useEffect(() => {
        if (bannerList.length > 0) {
            updateBannersOrder();
        }
    }, [drop]);

    const updateBanners = (newBanner: Banner) => {
        const listCopy = [...bannerList];
        const index = listCopy.findIndex((copy) => copy.id === newBanner.id);
        listCopy[index] = newBanner;
        setBannerList(listCopy);
    };

    return (
        <>
            <PageHeader title={'Promo blocks'} showMenu />
            <PageContainer>
                <PromoBlockWrapper>
                    <PromoBlockInnerWrapper>
                        {bannerList &&
                            bannerList.map((item, index) => (
                                <div
                                    onDragStart={() => dragStart(index)}
                                    onDragEnter={() => dragEnter(index)}
                                    onDragEnd={drop}
                                    key={index}
                                >
                                    <ListItemWrapper>
                                        <ImageWrapper draggable>
                                            <Image
                                                src="src/assets/images/four-direction-arrow.svg"
                                                preview={false}
                                                width={20}
                                            />
                                        </ImageWrapper>
                                        <PromoBlockItem
                                            bannerInfo={item}
                                            promoTypes={promoTypes}
                                            updateBanners={updateBanners}
                                        />
                                    </ListItemWrapper>
                                </div>
                            ))}
                    </PromoBlockInnerWrapper>
                </PromoBlockWrapper>
                <PreviewContainer>
                    {bannerList.map((banner) => {
                        return (
                            <BannerBlock
                                banner={banner}
                                promoType={getCorrectPromoType(
                                    banner?.promoTypeId?.toString() ?? '',
                                    promoTypes,
                                )}
                                key={banner.id}
                            />
                        );
                    })}
                </PreviewContainer>
                {errorModal.isOpen && (
                    <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
                )}
            </PageContainer>
        </>
    );
};

export default PromoBlockConstructor;
