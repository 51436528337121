import React from "react";
import { Image } from 'antd';
import { FavoriteType, UploadsFieldKey } from "../../../../models/product/enums";
import { blueDark01 } from "../../../../styles/colors";
import { BoldText } from "../../../../styles/common.styles";
import { DocumentsState, UploadType, filterDocumentTypesByUploadType } from "../UploadsInfo";
import { getCSSClassNameByUploadType } from "./UploadTable";
import { UploadTableHead, TableHeadName, TableHeadGeneral, TableHeadGeneral12, UploadTableBody } from "./UploadTable.styles";
import UploadsRow from "./uploadsRow/UploadsRow";
import { MergedFavoriteProduct } from "../../../favoritProducts/FavoriteProducts.types";
import { Product } from "../../../../models/product/types";
import { MFRProject } from "../../../../models/project/types";
import { SelectData } from "../../../../models/common/types";
import { UploadsTableFields } from "../Uploads.types";

interface Props {
    uploadType: UploadType;
    state: DocumentsState;
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    project?: MFRProject;
    allDocumentTypes: Array<SelectData> | undefined;
    handleSort: (field: UploadsFieldKey) => void;
    checkPermission: () => boolean;
    removeUploadDocument: (document: UploadsTableFields) => void;
};

const DocumentsTable: React.FC<Props> = ({
    uploadType,
    state,
    favoriteType,
    product,
    project,
    allDocumentTypes,
    handleSort,
    checkPermission,
    removeUploadDocument
}) => {
    return (
        <>
            <UploadTableHead>
                <TableHeadName className={getCSSClassNameByUploadType(uploadType)} onClick={() => handleSort(UploadsFieldKey.DocumentName)}>
                    <BoldText weight={400} size={10} height={12} color={blueDark01}>
                        NAME
                    </BoldText>
                    {state.sorting === 0 || state.field !== UploadsFieldKey.DocumentName ? (
                        <Image src="src/assets/images/caret-down.svg" preview={false} alt="sort" />
                    ) : (
                        <Image
                            src="src/assets/images/caret-down-.75.svg"
                            preview={false}
                            className={state.sorting === 1 ? 'rotate' : ''}
                            alt="sort"
                        />
                    )}
                </TableHeadName>

                <TableHeadGeneral12
                    className={getCSSClassNameByUploadType(uploadType, 'cmfImpactAreas')}
                >
                    <BoldText weight={400} size={10} height={12} color={blueDark01}>
                        CMF IMPACT AREA
                    </BoldText>
                </TableHeadGeneral12>

                <TableHeadGeneral
                    onClick={() => handleSort(UploadsFieldKey.ExpiredOn)}
                    className={getCSSClassNameByUploadType(uploadType, 'expires')}
                >
                    <BoldText weight={400} size={10} height={12} color={blueDark01}>
                        EXPIRES
                    </BoldText>
                    {state.sorting === 0 || state.field !== UploadsFieldKey.ExpiredOn ? (
                        <Image src="src/assets/images/caret-down.svg" preview={false} alt="sort" />
                    ) : (
                        <Image
                            src="src/assets/images/caret-down-.75.svg"
                            preview={false}
                            className={state.sorting === 1 ? 'rotate' : ''}
                            alt="sort"
                        />
                    )}
                </TableHeadGeneral>
                <TableHeadGeneral className={getCSSClassNameByUploadType(uploadType, 'date')} onClick={() => handleSort(UploadsFieldKey.CreatedOn)}>
                    <BoldText weight={400} size={10} height={12} color={blueDark01}>
                        DATE ADDED
                    </BoldText>
                    {state.sorting === 0 || state.field !== UploadsFieldKey.CreatedOn ? (
                        <Image src="src/assets/images/caret-down.svg" preview={false} alt="sort" />
                    ) : (
                        <Image
                            src="src/assets/images/caret-down-.75.svg"
                            preview={false}
                            className={state.sorting === 1 ? 'rotate' : ''}
                            alt="sort"
                        />
                    )}
                </TableHeadGeneral>
                <TableHeadGeneral className={getCSSClassNameByUploadType(uploadType, 'size')} onClick={() => handleSort(UploadsFieldKey.Size)}>
                    <BoldText weight={400} size={10} height={12} color={blueDark01}>
                        SIZE
                    </BoldText>
                    {state.sorting === 0 || state.field !== UploadsFieldKey.Size ? (
                        <Image src="src/assets/images/caret-down.svg" preview={false} />
                    ) : (
                        <Image
                            src="src/assets/images/caret-down-.75.svg"
                            preview={false}
                            className={state.sorting === 1 ? 'rotate' : ''}
                            alt="sort"
                        />
                    )}
                </TableHeadGeneral>
                {checkPermission() && <TableHeadGeneral className="action"></TableHeadGeneral>}
            </UploadTableHead>
            <UploadTableBody id="upload-list">
                {state.files.map((file, index) => (
                    <UploadsRow
                        file={file}
                        key={file.id}
                        isOdd={(index + 1) % 2 === 0}
                        favoriteType={favoriteType}
                        product={product}
                        project={project}
                        allDocumentTypes={filterDocumentTypesByUploadType(allDocumentTypes, uploadType)}
                        uploadType={uploadType}
                        removeFile={removeUploadDocument}
                    />
                ))}
            </UploadTableBody>
        </>
    );
}

export default DocumentsTable;