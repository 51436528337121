import styled from 'styled-components';
import { grayLight01, grayDark05, blueDark01, blueLink } from '../../../styles/colors';

export const MFRDivisionItemContainer = styled('div')`
    display: flex;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;
`;

export const MFRDivisionTitleContainer = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        .hover {
            color: ${blueLink};
        }
        .switch-button {
            background-color: ${blueLink} !important;
            &:after {
                background: white;
            }
            &:before {
                background: white;
            }
        }
    }
`;

export const MFRDivisionTitle = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 20px;
    line-height: 24px;
    color: ${blueDark01};
    margin-right: 5px;
    &.division-id {
        margin-left: -10.5px;
    }
`;

export const MFRDivisionItemWrapper = styled('div')`
    border-bottom: 0.5px solid ${grayLight01};
    border-top: 0.5px solid ${grayLight01};
    &.clicked {
        border-bottom: 0.5px solid ${grayDark05};
        border-top: 0.5px solid ${grayDark05};
    }
`;
