import styled from 'styled-components';
import { Form, Layout, Typography } from 'antd';

export const CreatePasswordContainer = styled('div')`
    margin: auto;
    max-width: 405px;
    width: 100%;
    .ant-input {
        height: unset;
    }
`;

export const CreatePasswordWrapper = styled(Layout)`
    background-color: transparent;
    height: calc(100vh - 180px);
    overflow-y: auto;
    align-items: center;
    display: flex;
    flex-direction: inherit;
`;

export const CreatePasswordTitleForm = styled(Form.Item)`
    color: rgb(0, 153, 255) !important;
    text-align: center;
`;

export const CreatePasswordTitle = styled(Typography.Title)`
    color: rgb(0, 153, 255) !important;
    text-align: center;
    font-size: 20px !important;
`;

export const SubmitButtonContainer = styled('div')`
    label {
        opacity: 0;
    }
`;
