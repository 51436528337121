import React, { useEffect } from "react";
import AlternativeTextInput from "../uploadFileForm/AlternativeTextInput";
import DescriptionInput from "../uploadFileForm/DescriptionInput";
import DocumentNameInput from "../uploadFileForm/DocumentNameInput";
import {
    DoubleTextInputsContainer,
    DoubleInputContainer,
    DescriptionContainer,
    ButtonsRowContainer,
    ButtonsContainer,
    DeleteButton,
    DownloadButton,
    SaveButton
} from "./ImageModal.styles";
import { UploadedImageData } from "../UploadsInfo";
import { Form } from "antd";
import { UploadFileData } from "../Uploads.types";
import { FavoriteType } from "../../../../models/product/enums";
import { MergedFavoriteProduct } from "../../../favoritProducts/FavoriteProducts.types";
import { Product } from "../../../../models/product/types";
import { useAppDispatch } from "../../../../hooks/redux";
import { updateFile } from "../uploadTable/uploadsRow/UploadsRow";

interface Props {
    image: UploadedImageData;
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    onDownloadButtonClicked: () => void;
    onDeleteButtonClicked: () => void;
    closeImageModal: () => void;
    updateImage: (data: UploadFileData) => void;
}

const imageNameFieldName = 'documentName';
const altTextFieldName = 'alternativeText';
const descriptionFieldName = 'description';

const ImageModalForm: React.FC<Props> = ({ 
    image,
    favoriteType,
    product,
    onDownloadButtonClicked,
    onDeleteButtonClicked,
    closeImageModal,
    updateImage,
}) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        if (image) {
            form.setFieldValue(imageNameFieldName, image.name);
            form.setFieldValue(altTextFieldName, image.altText);
            form.setFieldValue(descriptionFieldName, image.description);
        }
    }, [image]);

    const onSubmit = async (data: UploadFileData) => {
        updateFile(favoriteType, {
            ...image.file,
            documentName: data[imageNameFieldName],
            alternativeText: data[altTextFieldName] ?? '',
            description: data[descriptionFieldName] ?? ''

        }, product, dispatch);
        updateImage(data);
        closeImageModal();
    };

    return (
        <Form onFinish={onSubmit} form={form}>
            <DoubleTextInputsContainer>
                <DoubleInputContainer>
                    <DocumentNameInput
                        name={imageNameFieldName}
                        label='Image name'
                        htmlFor='image_name'
                    />
                </DoubleInputContainer>
                <DoubleInputContainer>
                    <AlternativeTextInput
                        name={altTextFieldName}
                        label='Alternative text'
                        suffix='(optional)'
                        placeholder='Alternative text'
                    />
                </DoubleInputContainer>
            </DoubleTextInputsContainer>
            <DescriptionContainer>
                <DescriptionInput
                    name={descriptionFieldName}
                    label='Description'
                    suffix='(optional)'
                    placeholder='Add description'
                />
            </DescriptionContainer>
            <ButtonsRowContainer>
                <ButtonsContainer>
                    <DeleteButton type='link' onClick={onDeleteButtonClicked}>Delete</DeleteButton>
                    <DownloadButton onClick={onDownloadButtonClicked}>Download</DownloadButton>
                    <SaveButton htmlType='submit'>Save</SaveButton>
                </ButtonsContainer>
            </ButtonsRowContainer>
        </Form>
    );
}

export default ImageModalForm;