import styled from 'styled-components';
import { grayDark05, checkGreen } from '../../../../../../styles/colors';

export const EPDSection = styled('div')`
    margin: 4px 0 4px 0;
    display: flex;
    gap: 10px;
`;

const EPDItem = styled('div')`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${grayDark05};
    display: flex;
    gap: 2px;
`;

export const EPD = styled(EPDItem)`
    &.check {
        color: ${checkGreen};
        font-family: 'Proxima N W15 Bold';
        font-weight: normal;
    }
`;

export const MaterialDisclosures = styled(EPDItem)`
    &.check {
        color: #007388;
        font-family: 'Proxima N W15 Bold';
        font-weight: normal;
    }
`;
