import styled from 'styled-components';
import { Divider, Row, Col } from 'antd';
import { PageContainer } from '../../styles/common.styles';

export const LoginPageDivider = styled(Divider)`
    height: 100%;
    border-left: 1px solid black;
`;

export const LoginRow = styled(Row)``;

export const LoginCol = styled(Col)``;

export const LoginPageContainer = styled(PageContainer)`
    min-width: 1224px;
`;
