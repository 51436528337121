import { Image, Select } from 'antd';
import React from 'react';
import { SelectData } from '../../../models/common/types';
import { CreateProjectInitialValue } from '../../../models/project/types';
import { CustomSelectFormItem } from './CustomSelectUploadFileForm.styles';

const { Option } = Select;

interface Props {
    options: Array<SelectData | CreateProjectInitialValue>;
    name?: string;
    label?: string | React.ReactNode;
    placeholder?: string;
    required?: boolean;
    rules?: any;
    props?: any;
    id?: string;
    block?: boolean;
    value?: any;
}
const CustomSelectUploadFileFormSearch: React.FC<Props> = ({
    id = 'input-container',
    label,
    name,
    required,
    options,
    placeholder,
    rules,
    props,
    block,
    value,
}) => {
    const availableOptions = options.map((option, i) => (
        <Option value={option.id} key={option.id} className={`${name}-${i.toString()}`}>
            {option.name}
        </Option>
    ));
    return (
        <div id={id} className={`${required ? 'required' : ''}  ${block ? 'input-block' : ''}`}>
            <CustomSelectFormItem name={name} label={<span className='customSelectFormItemLabel'>{label}</span>} rules={rules}>
                <Select
                    showSearch={true}
                    optionFilterProp="children"
                    filterOption={(inputValue, option) =>
                        option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                    }
                    aria-required
                    placeholder={placeholder}
                    value={value}
                    {...props}
                    suffixIcon={
                        <Image
                            src="src/assets/images/ic-dropdown-arrow.svg"
                            preview={false}
                            alt="caret-down"
                        />
                    }
                >
                    1{availableOptions}2
                </Select>
            </CustomSelectFormItem>
        </div>
    );
};

export default CustomSelectUploadFileFormSearch;
