import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { blueLink } from '../../styles/colors';

export const NotificationLink = styled('span')`
    line-height: 16px;
    font-weight: normal;
    font-family: 'Proxima Nova';
    font-size: 12px;
    margin-left: 5px;
    color: ${blueLink};
    @media screen and (max-width: 860px) {
        white-space: normal;
    }
`;

export const ProductLink = styled(Link)`
    cursor: pointer;
    color: ${blueLink};
    &:hover {
        text-decoration: underline;
        color: ${blueLink};
    }
`;

export const ProductNameText = styled('span')`
    color: ${blueLink};
    font-family: 'Proxima Nova';
`;

export const FileNameLink = styled('span')`
    line-height: 16px;
    font-weight: normal;
    font-size: 12px;
    margin-left: 5px;
    color: ${blueLink};
    font-family: 'Proxima Nova';
    &:hover {
        text-decoration: underline;
        color: ${blueLink};
    }
    @media screen and (max-width: 860px) {
        white-space: normal;
    }
`;
