import styled from 'styled-components';
import { Divider, Form } from 'antd';
import { blueDark01 } from '../../../../styles/colors';
import { PrimaryButton } from '../../../../styles/common.styles';

export const BasicInfoForm = styled(Form)`
    margin-bottom: 40px;

    &:nth-child(2) {
        .ant-form-item-row {
            .ant-form-item-control-input-content {
                width: 354px;
                max-width: unset;
                flex: unset;

                & > span {
                    height: 36px;

                    & > input {
                        height: auto !important;
                    }
                }
            }
        }
    }
`;

export const BasicInfoContainer = styled('div')`
    margin: 37px 100px 46px 100px;
    display: flex;
    flex-direction: column;
`;

export const BasicInfoColumn = styled('div')`
    display: flex;
    flex-direction: column;
`;

export const BasicInfoSection = styled('div')`
    display: flex;
    flex-direction: column;

    .input-block,
    & > div > div {
        & > div:first-child {
            margin-bottom: 16px;
        }
        .ant-form-item-row {
            display: flex !important;
            gap: 16px;

            .ant-form-item-label {
                width: 125px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                label {
                    font-weight: normal;
                    font-family: 'Proxima N W15 Bold';
                    font-size: 13px;
                    line-height: 16px !important;
                    height: auto;
                    color: ${blueDark01} !important;
                    &::after {
                        margin-right: 0;
                    }
                }
            }

            .ant-form-item-control {
                input,
                .ant-select-selector {
                    width: 354px;
                    height: 36px;
                }

                .ant-form-item-explain-error {
                    top: 33px;
                }
            }
        }
    }
`;

export const BasicInfoPhoneInputContainer = styled('div')`
    display: flex;
    flex-direction: row;

    & > div {
        :nth-child(1) {
            .ant-form-item-row {
                .ant-form-item-control {
                    input {
                        width: 203px;
                    }
                }
            }
        }
        :nth-child(2) {
            .ant-form-item-row {
                .ant-form-item-label {
                    width: 48px;
                }
                .ant-form-item-control {
                    input {
                        width: 87px;
                    }
                }
            }
        }
    }
`;

export const BasicInfoPasswordContainer = styled('div')`
    & > div:last-child {
        .ant-form-item {
            margin-bottom: 0;
            .ant-form-item-label {
                height: auto;
            }
        }
    }
`;

export const BasicInfoDivider = styled(Divider)`
    margin: 0px 0px 40px 0px;
`;

export const BasicInfoSaveButton = styled(PrimaryButton)`
    align-self: end;
`;

export const BasicInfoResetPassword = styled('div')`
    button {
        height: 16px;
    }
`;
