import React, { useEffect } from 'react';
import { Form } from 'antd';
import { MFRProject, ProjectTableFields } from '../../../../models/project/types';
import { GetProjectInformationResponse } from '../../../../redux/actions/projectActions/types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { ExternalProduct, InternalProduct } from '../../../../models/product/types';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../../../redux/actions/transparencyCatalogActions/types';
import { exportToEC3Action, refreshEC3AuthTokens } from '../../../../redux/actions/ec3Actions';
import { alertService } from '../../../../services';
import {
    clearEC3Slice,
    setEC3AuthToken,
    setExportedProject,
    setPageFromRequest,
} from '../../../../redux/reducers/ec3Reducer/EC3Slice';
import { EC3ErrorStatus } from '../../../../models/ec3/enums';
import { getExternalProductsAction } from '../../../../redux/actions/transparencyCatalogActions';

interface Props {
    isOpen: boolean;
    project: ProjectTableFields | GetProjectInformationResponse | MFRProject | null;
    productIds: Array<InternalProduct>;
    activeTab?: string;
    closeModal: (data: { key: 'ec3' }) => void;
    reload: (key: string) => void;
}
const EC3Login: React.FC<Props> = ({ project, productIds, activeTab, reload, closeModal }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { authReducer, ec3Reducer } = selector;

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldValue('email', undefined);
        if (ec3Reducer.token) {
            onSubmit();
        } else {
            const isTabInUrl = window.location.pathname.split('/').length > 4;
            const pageUrl = isTabInUrl
                ? window.location.pathname
                : window.location.pathname + `/0/${activeTab || 4}`;
            dispatch(setPageFromRequest({ page: pageUrl }));
            window.location.href = `https://buildingtransparency.org/oauth2/authorize?client_id=${process.env.REACT_APP_EC3_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}/ec3&response_type=code&scope=read%20write`;
        }
    }, []);

    const handleCancel = () => {
        closeModal({ key: 'ec3' });
    };
    const onSubmit = async (accessToken?: string) => {
        if (productIds?.length) {
            const products = await getProducts(productIds);

            if (products.length && project && authReducer.user) {
                const productEPDs = products.map((p) => p.ec3Url).filter((p) => p);

                if (productEPDs.length) {
                    const { payload } = (await dispatch(
                        exportToEC3Action({
                            token: accessToken || ec3Reducer.token,
                            projectId: project.id,
                            userId: authReducer.user.id,
                            projectName: project.name,
                            epdKeys: productEPDs,
                        }),
                    )) as any;
                    if (payload && payload === EC3ErrorStatus.TokenExpired) {
                        refreshToken();
                        return;
                    }
                    if (payload && payload.eC3Project?.token) {
                        reload('ec3');
                        dispatch(setExportedProject(project.id));
                    } else {
                        handleCancel();
                    }
                } else {
                    console.log('There are no products with EPDs in Public EC3 product categories.');
                    alertService.warn('There are no products with EPDs in Public EC3 product categories.');
                }
            }
        }
    };

    const refreshToken = async () => {
        const refreshResult = await refreshEC3AuthTokens({
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_EC3_CLIENT_ID || '',
            refresh_token: ec3Reducer.refreshToken,
        });
        if (refreshResult) {
            dispatch(
                setEC3AuthToken({
                    token: refreshResult.data.access_token,
                    refreshToken: refreshResult.data.refresh_token,
                }),
            );
            onSubmit(refreshResult.data.access_token);
        } else {
            const isTabInUrl = window.location.pathname.split('/').length > 4;
            const pageUrl = isTabInUrl
                ? window.location.pathname
                : window.location.pathname + `/0/${activeTab}`;
            dispatch(setPageFromRequest({ page: pageUrl }));
            dispatch(clearEC3Slice());
            window.location.href = `https://buildingtransparency.org/oauth2/authorize?client_id=${process.env.REACT_APP_EC3_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}/ec3&response_type=code&scope=read%20write`;
        }
    };

    const getProducts = async (internalProducts: Array<InternalProduct>) => {
        const productIds = internalProducts.map((p) => p.productId);
        const { payload } = (await dispatch(
            getExternalProductsAction({ productIds }),
        )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;

        if (payload.data) {
            const availableProducts = Object.values(payload.data.mfSection).reduce(
                (agg, item: any) => agg.concat(item),
                Array<ExternalProduct>(),
            );

            const currentProducts = internalProducts.reduce((agg, value) => {
                const availableProduct = availableProducts.find(
                    (product) => product.id === value.productId,
                );
                if (availableProduct) {
                    agg.push({
                        ...availableProduct,
                    });
                }
                return agg;
            }, Array<ExternalProduct>());
            return currentProducts;
        }
        return [];
    };
    return <></>;
};

export default EC3Login;
