import React, { useState } from 'react';
import { Image, Space, Dropdown } from 'antd';
import { NavLink } from 'react-router-dom';
import { ClickableRow } from '../../../styles/common.styles';
import {
    DotsAction,
    MFRTemplateOwner,
    OpenMenuButton,
    ProjectLibraryTableCellContainer,
    ProjectNameContainer,
    TableImageContainer,
} from './ProjectsTable.styles';
import { toShortLocalDate } from '../../../helpers/dateHelper';
import { ProjectTableFields } from '../../../models/project/types';
import { CustomColumnType } from '../../common/table/CustomTable.types';
import { getMFRTemplateActions } from './ProjectTable.helper';
import { LibraryType } from '../../../models/project/enums';

export const manufacturerTemplateColumns = (
    handleActions: (action: string, project: ProjectTableFields) => void,
    _libraryType: LibraryType,
    projectId?: string,
): CustomColumnType<ProjectTableFields>[] => [
    {
        title: 'MANUFACTURER',
        dataIndex: 'manufacturer',
        sorter: true,
        clickable: true,
        render: (value: any) => (
            <MFRTemplateOwner>
                <div>{value}</div>
            </MFRTemplateOwner>
        ),
    },
    {
        title: 'TEMPLATE',
        sorter: true,
        dataIndex: 'name',
        clickable: true,
        render: (value: any, data: any) => (
            <div className="project-name">
                <TableImageContainer>
                    {!data.imageFilePath ? (
                        <>
                            <Image
                                src="src/assets/images/image.svg"
                                width={24}
                                height={21}
                                preview={false}
                            />
                            <Image
                                src="src/assets/images/table-image.png"
                                width={96}
                                height={54}
                                preview={false}
                            />
                        </>
                    ) : (
                        <Image
                            src={`${process.env.REACT_APP_API_URL}/static/${data.imageFilePath}`}
                            width={96}
                            height={54}
                            preview={false}
                        />
                    )}
                </TableImageContainer>
                <ProjectNameContainer>
                    <div>
                        <NavLink to={`/project-info/${data.id}`}>
                            <ClickableRow>{value}</ClickableRow>
                        </NavLink>
                    </div>
                    <Space>
                        {data.projectLibraryVisibilityId === 2 && !data.isTemplate && (
                            <Image
                                src="src/assets/images/eye-slash.svg"
                                width={13}
                                height={12}
                                preview={false}
                            />
                        )}
                        {data.isConfidential && !data.isTemplate && (
                            <Image
                                src="src/assets/images/lock-keyhole.svg"
                                width={11}
                                height={12}
                                preview={false}
                            />
                        )}
                    </Space>
                </ProjectNameContainer>
            </div>
        ),
    },
    {
        title: 'Project / building type',
        dataIndex: 'buildingTypeName',
        sorter: true,
        clickable: true,
        render: (value: any) => (
            <ProjectLibraryTableCellContainer>
                <div>{value}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'Last modified',
        dataIndex: 'modifiedOn',
        sorter: true,
        clickable: true,
        render: (value) => (
            <ProjectLibraryTableCellContainer>
                <div>{toShortLocalDate(value)}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'Created',
        dataIndex: 'createdOn',
        sorter: true,
        clickable: true,
        render: (value: string) => (
            <ProjectLibraryTableCellContainer>
                <div>{toShortLocalDate(value)}</div>
            </ProjectLibraryTableCellContainer>
        ),
    },
    {
        title: 'ACTIONS',
        sorter: false,
        render: (data: ProjectTableFields) => (
            <MFRTemplateActions
                project={data}
                handleActions={handleActions}
                projectId={projectId}
            />
        ),

        width: 20,
    },
];

interface Props {
    project: ProjectTableFields;
    handleActions: (action: string, project: ProjectTableFields) => void;
    projectId?: string;
}

const MFRTemplateActions: React.FC<Props> = ({ project, projectId, handleActions }) => {
    const [open, setOpen] = useState(false);

    const submitAction = (data: { key: string }) => {
        handleActions(data.key, project);
    };

    const handleMenuOpen = () => {
        setOpen(!open);
    };
    return (
        <Dropdown
            menu={{
                items: getMFRTemplateActions(project, projectId),
                onClick: submitAction,
            }}
            placement="bottom"
            trigger={['click']}
            onOpenChange={handleMenuOpen}
        >
            <div>
                <OpenMenuButton>
                    <DotsAction></DotsAction>
                </OpenMenuButton>
            </div>
        </Dropdown>
    );
};
