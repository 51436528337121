import React from 'react';
import Switcher from '../../common/switcher/Switcher';
import { MergedFavoriteProduct } from '../FavoriteProducts.types';
import { Division } from '../../../models/productDivision/types';
import { blueDark01, blueLink } from '../../../styles/colors';
import {
    FavoriteDivisionItemContainer,
    FavoriteDivisionItemWrapper,
    FavoriteDivisionTitle,
    FavoriteDivisionTitleContainer,
    OpenCloseDivisionButton,
} from './FavoriteDivisionItem.styles';
import { ProductSwitcherContent, Text, InvisibleButton } from '../../../styles/common.styles';
import FavoriteSectionItem from '../favoriteSectionItem/FavoriteSectionItem';
import { ProductReferences } from '../../../redux/actions/productActions/types';
import { FavoriteType } from '../../../models/product/enums';

interface Props {
    division: Division<MergedFavoriteProduct>;
    references: ProductReferences | null;
    favoriteType: FavoriteType;
    handleClicked: (id: string) => void;
    handleSectionOpen: (divisionId: string, sectionId: string) => void;
    selectAllProducts: (divisionId: string, sectionId: string, checked: boolean) => void;
    selectProduct: (product: MergedFavoriteProduct, checked: boolean) => void;
}

const FavoriteDivisionItem: React.FC<Props> = ({
    division,
    references,
    favoriteType,
    handleClicked,
    handleSectionOpen,
    selectAllProducts,
    selectProduct,
}) => {
    const handleSectionClicked = (sectionId: string) => {
        handleSectionOpen(division.id, sectionId);
    };

    const selectSectionProducts = (sectionId: string, checked: boolean) => {
        selectAllProducts(division.id, sectionId, checked);
    };

    const handleDivisionClicked = () => {
        handleClicked(division.id);
    };

    const openCloseDivision = async () => {
        const action = checkWhatActionNeeded();
        openCloseDivisionProcess(action);

    };
    const checkWhatActionNeeded = () => {
        if (!division.clicked) {
            return true;
        }
        for (let j = 0; j < division.sections.length; j++) {
            const section = division.sections[j];
            if (!section.clicked) {
                return true;
            }
        }
        return false;
    }

    const openCloseDivisionProcess = (action: boolean) => {
        if (!division.sections || !division.sections.length) {
            return;
        }
        handleDivisionClicked();
        division.clicked = action;
        for (let j = 0; j < division.sections.length; j++) {
            const section = division.sections[j];
            handleSectionClicked(section.id);
            section.clicked = action;
        }

    }

    return (
        <FavoriteDivisionItemWrapper>
            <FavoriteDivisionItemContainer>
                <FavoriteDivisionTitleContainer
                >
                    <Switcher
                        id={division.id}
                        clicked={division.clicked}
                        handleSwitch={handleClicked}
                        width={24}
                        height={24}
                    />
                    <FavoriteDivisionTitle className="division-id hover"
                        onClick={() => {
                            handleClicked(division.id);
                        }}
                    >
                        {division.id[0] + division.id[1]}
                    </FavoriteDivisionTitle>
                    <FavoriteDivisionTitle className="hover"
                        onClick={() => {
                            handleClicked(division.id);
                        }}
                    >
                        {division.name}
                    </FavoriteDivisionTitle>
                    <Text weight={400} size={20} height={24} color={blueDark01} className="hover">
                        (
                        {division.sections.reduce((agg, item) => {
                            agg += item.products.length;
                            return agg;
                        }, 0)}
                        )
                    </Text>
                    <InvisibleButton>
                        <OpenCloseDivisionButton onClick={openCloseDivision} className="closeOpenDivision">
                            <Text weight={400} size={12} height={14} color={blueLink}>
                                Open / close all
                            </Text>
                        </OpenCloseDivisionButton>
                    </InvisibleButton>
                </FavoriteDivisionTitleContainer>
            </FavoriteDivisionItemContainer>
            <ProductSwitcherContent className={division.clicked ? 'clicked' : ''}>
                {division.sections.map((section) => (
                    <FavoriteSectionItem
                        section={section}
                        key={section.id}
                        references={references}
                        favoriteType={favoriteType}
                        handleClicked={handleSectionClicked}
                        selectProduct={selectProduct}
                        selectAllProducts={selectSectionProducts}
                    />
                ))}
            </ProductSwitcherContent>
        </FavoriteDivisionItemWrapper>
    );
};

export default FavoriteDivisionItem;
