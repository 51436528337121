export const formatSubscription = (plan?: string): string => {
    if (!plan) {
        return 'Free';
    }
    if (plan.toLowerCase().includes('trial')) {
        plan = plan.replace('FreeTrial', '') + '(Trial)';
    }
    return plan;
};

export const capitalizeFirstLetter = (str: string | undefined): string => {
    return str?.length ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}
