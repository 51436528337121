import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input } from 'antd';
import ModalTitle from '../../../common/modalTitle/ModalTitle';
import { MFRProject, ProjectTableFields } from '../../../../models/project/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CopyProjectData } from '../ProjectActions.types';
import { blueDark01 } from '../../../../styles/colors';

import ModalFooter from '../../../common/modalFooter/ModalFooter';
import { Text, CloseIcon } from '../../../../styles/common.styles';
import { CopyCheckboxSpace, CopyProjectModal, CopyProjectSpace } from './CopyProject.styles';
import { GetProjectInformationResponse } from '../../../../redux/actions/projectActions/types';
import CustomInput from '../../../common/inputs/CustomInput';
import { alertService } from '../../../../services/alert.service';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'copy' }) => void;
    reload: (modal: string, data: CopyProjectData) => void;
    project: ProjectTableFields | GetProjectInformationResponse | MFRProject;
}

const CopyProject: React.FC<Props> = ({ isOpen, closeModal, reload, project }) => {
    const [state, setState] = useState<CopyProjectData>({
        name: `${project.name} copy`,
        isTemplate: false,
    });

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ copy: `${project.name} copy` });
    }, []);

    const handleCancel = () => {
        closeModal({ key: 'copy' });
    };

    const handleCheckbox = (event: CheckboxChangeEvent) => {
        setState({ ...state, isTemplate: event.target.checked });
    };

    const onSubmit = (data: { copy: string }) => {
        if (!data.copy.trim()) {
            alertService.warn('project name cannot contain only spaces');
        }
        if (data.copy.trim()) {
            reload('copy', { name: data.copy, isTemplate: state.isTemplate });
        }
    };
    return (
        <CopyProjectModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <ModalTitle title="Copy project" icon="ic-modal-title-copy.svg" />
            <div>
                <Text weight={400} size={14} height={18} color={blueDark01}>
                    Save a copy of this project as:
                </Text>
            </div>
            <Form onFinish={onSubmit} form={form}>
                <CopyProjectSpace>
                    <CustomInput
                        Component={Input}
                        label="Project name"
                        name="copy"
                        block
                        required
                        rules={[{ required: true, message: '' }]}
                    />
                    <CopyCheckboxSpace>
                        <Checkbox
                            checked={state.isTemplate}
                            name="isTemplate"
                            onChange={handleCheckbox}
                        />
                        <Text weight={400} size={14} height={18} width={141} color={blueDark01}>
                            Make this a template
                        </Text>
                    </CopyCheckboxSpace>
                </CopyProjectSpace>
                <ModalFooter
                    submit={() => {}}
                    cancel={handleCancel}
                    action="Save a copy"
                    actionButtonWidth={89}
                />
            </Form>
        </CopyProjectModal>
    );
};

export default CopyProject;
