import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useModalState } from '../../hooks/modalState';
import {
    changePasswordAction,
    deleteUserAction,
    getUserByIdAction,
    getUserReferencesAction,
    resetUserPasswordAction,
    updateUserAction,
} from '../../redux/actions/userActions';
import {
    ChangePasswordRequest,
    CreateUserRequest,
    UserByIdResponse,
} from '../../redux/actions/userActions/types';
import { alertService } from '../../services';
import { addPage, removePage } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { clearState } from '../../redux/persistedState';
import { riseStoredUser, setStoredUser } from '../../redux/reducers/authReducer/AuthSlice';
import { extractGetByIdUser } from '../../helpers/extract';
import { SelectData } from '../../models/common/types';
import { PrimaryButton, SpaceEnd } from '../../styles/common.styles';
import BasicInformationTab from './BasicInformationTab';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import CustomTabs from '../../components/common/tabs/CustomTabs';
import DeleteModal from '../../components/common/deleteModal/DeleteModal';
import { AccountChangePasswordButton } from '../account/Account.styles';
import ResetPasswordModal from '../../components/common/resetModal/ResetModal';
import { AlertMessages } from '../../components/alert/AlertMessages';
import ErrorModal from '../../components/common/errorModal/ErrorModal';
import { ActionResponse } from '../../models/actionResponse/types';

interface EditParams {
    id: string;
}

const EditAccountPage: React.FC = () => {
    const { id } = useParams<EditParams>();
    const history = useHistory();
    const selector = useAppSelector((state) => state.authReducer);
    const dispatch = useAppDispatch();
    const [user, setUser] = useState<CreateUserRequest | undefined>(undefined);
    const [userSubscriptionType, setUserSubscriptionType] = useState('');
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const { onOpen, onClose, isOpen } = useModalState();
    const errorModal = useModalState();

    useEffect(() => {
        getData();
        return () => {
            dispatch(removePage());
        };
    }, []);

    const getData = async () => {
        await getUser();
        await getDataForSelect();
    };

    const [selectData, setSelectedData] = useState({
        roleName: [],
        projectGroup: [{ id: '', name: ' ' }],
        allTitleRoles: [],
        allAccountRoles: [],
        allOrganizationSizes: [],
    });

    const getDataForSelect = async () => {
        const { payload } = (await dispatch(getUserReferencesAction())) as any;
        if (payload?.data) {
            setSelectedData({
                roleName: payload.data.allRoles || [],
                projectGroup: selectData.projectGroup.concat(payload.data.allProjectGroups),
                allTitleRoles: payload.data.allTitleRoles || [],
                allAccountRoles:
                    payload.data.allAccountRoles.map((role: SelectData) => ({
                        ...role,
                        name: role.name.replace('Account', ''),
                    })) || [],
                allOrganizationSizes: payload.data.allOrganizationSizes || [],
            });
        } else {
            errorModal.onOpen();
        }
    };

    const getUser = async () => {
        const { payload } = (await dispatch(getUserByIdAction(id))) as ActionResponse<
            Array<UserByIdResponse>
        >;
        if (payload?.data[0] && selector.user?.id) {
            setUserSubscriptionType(payload.data[0].subscription.planName);
            const currentUser = extractGetByIdUser(payload?.data[0]);
            Object.keys(currentUser).forEach((key) => {
                if (!currentUser[key]) {
                    delete currentUser[key];
                }
            }, currentUser);
            setUser(currentUser);
            dispatch(
                addPage({
                    key: `/users/edit/${payload.data[0]?.id}`,
                    value: `${payload.data[0]?.firstName} ${payload.data[0]?.lastName}`,
                }),
            );
        } else {
            history.push('/users');
        }
    };
    const onSubmit = async (data: CreateUserRequest) => {
        Object.keys(data).forEach((key) => {
            if (!data[key]) {
                data[key] = null;
            }
        }, data);
        const { payload } = (await dispatch(
            updateUserAction({
                user: {
                    ...data,
                    userName: data.email,
                    accountId: user?.parentAccountId || user?.accountId,
                },
                id,
            }),
        )) as any;
        if (payload?.data) {
            if (
                payload?.data[0].id === selector.user?.id &&
                payload?.data[0].userName !== user?.userName
            ) {
                clearState();
                history.push('/login');
                dispatch(riseStoredUser());
                alertService.info('Please login with a new email', {
                    autoClose: false,
                });
                return;
            }
            if (payload.data[0].id === selector.user?.id) {
                dispatch(setStoredUser(payload?.data[0]));
            }
            alertService.success(AlertMessages.UPDATE_USER);
            setTimeout(() => {
                history.push('/users/edit');
            }, 500);
        } else {
            errorModal.onOpen();
        }
    };

    const openDeleteModal = () => {
        setIsOpenDeleteModal(true);
    };

    const deleteUser = async () => {
        const { payload } = (await dispatch(deleteUserAction(id))) as any;
        if (payload?.message) {
            history.push('/users/edit');
        }
    };

    const submitContainer = (callback: () => void) => {
        return (
            <SpaceEnd>
                <PrimaryButton htmlType="submit" onClick={callback}>
                    Save
                </PrimaryButton>
                {id !== selector?.user?.id.toString() && (
                    <AccountChangePasswordButton onClick={openDeleteModal}>
                        Delete account
                    </AccountChangePasswordButton>
                )}
            </SpaceEnd>
        );
    };

    const submitPassword = async (data: ChangePasswordRequest) => {
        const { payload } = await dispatch(changePasswordAction(data));
        if (payload.message) {
            alertService.success(AlertMessages.CHANGE_PASSWORD);
        }
    };

    const tabs = [
        {
            name: 'Basic information',
            tab: (
                <BasicInformationTab
                    user={user}
                    onSubmit={onSubmit}
                    submitContainer={submitContainer}
                    resetPassword={onOpen}
                    submitPassword={submitPassword}
                    isOwner={id === selector?.user?.id.toString()}
                    userSubscriptionType={userSubscriptionType}
                />
            ),
            key: '1',
        },
    ];

    const resetPassword = async () => {
        const { payload } = (await dispatch(resetUserPasswordAction(id))) as any;
        if (payload.message) {
            window.scroll(0, 0);
            alertService.success(AlertMessages.RESET_PASSWORD);
            onClose();
        }
    };

    return (
        <>
            <PageHeader title={`${user?.firstName} ${user?.lastName}’s account`} />
            <Spin size="large" spinning={selector.pending}>
                <CustomTabs tabs={tabs} defaultKey="1" />
            </Spin>
            <DeleteModal
                isOpen={isOpenDeleteModal}
                closeModal={() => {
                    setIsOpenDeleteModal(false);
                }}
                reload={deleteUser}
                text={`${user?.firstName} ${user?.lastName}`}
                title="user"
                icon="trash-can-list.svg"
            />
            <ResetPasswordModal
                isOpen={isOpen}
                text={`${user?.firstName} ${user?.lastName}`}
                closeModal={onClose}
                reload={resetPassword}
            />
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </>
    );
};

export default EditAccountPage;
