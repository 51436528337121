import jwt_decode from 'jwt-decode';
import { alertService } from '../services';
import { clearState, copyTokensFromCookiesToLocalStorage, getAccessToken, getRefreshToken } from '../redux/persistedState';
import { encode as _base64_encode } from 'base-64';
import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios';
import { LoginResponse } from '../redux/actions/authActions/types';

export interface HttpRequest<REQB> {
    path: string;
    method?: string;
    body?: REQB;
}
export interface HttpResponse<RESB> extends Response {
    parsedBody?: RESB;
}

const applicationJSONContentType = 'application/json';

export const http = <RESB, REQB>(
    config: HttpRequest<REQB>,
    accessToken: boolean = true,
    redirectError: boolean = true,
    ec3Authorization: boolean = false,
    copyTokensFromCookiesIfAuthFails: boolean = false,
): Promise<HttpResponse<RESB>> => {
    return new Promise((resolve, reject) => {
        const request = new Request(`${process.env.REACT_APP_API_URL}${config.path}`, {
            method: config.method || 'get',
            headers: {
                'Content-Type': applicationJSONContentType,
            },
            body: config.body ? JSON.stringify(config.body) : undefined,
        });
        if (accessToken) {
            const accessTokenString = getAccessToken();
            request.headers.set('authorization', `bearer ${accessTokenString}`);
        }
        let response: HttpResponse<RESB>;
        fetch(request)
            .then((res) => {
                response = res;
                if (res.status === 204) {
                    return null;
                }
                return res.json();
            })
            .then(async (body) => {
                if (!body) {
                    resolve(response);
                    return;
                }
                if (response.status === 401) {
                    if (ec3Authorization) {
                        reject(response);
                        return;
                    }
                    try {
                        if (await tryRefreshSession()) {
                            resolve(http(config, true, redirectError));
                            return;
                        }
                    } catch(tryRefreshResponse) {
                        if (copyTokensFromCookiesIfAuthFails) {
                            console.log('Tried to copy tokens from cookies to local storage');
                            copyTokensFromCookiesToLocalStorage();
                        } else {
                            clearState();
                            window.location.href = '/login';
                        }

                        reject(tryRefreshResponse);
                        return;
                    }
                }
                if (body.errors || body.error) {
                    window.scrollTo(0, 0);
                    alertService.error(body.title || body.error);
                    reject(response);
                    return;
                }
                if (response.ok) {
                    response.parsedBody = body;
                    resolve(response);
                } else {
                    if (
                        (redirectError && response.status === 404) ||
                        (redirectError && response.status === 403)
                    ) {
                        window.location.href = '/404';
                        reject(response);
                        return;
                    }
                    try {
                        JSON.parse(body.message);
                    } catch (error) {
                        alertService.error(body.message);
                    }
                    window.scrollTo(0, 0);
                    reject(body.message);
                }
            });
    });
};

export const tryRefreshSession = () => {
    return new Promise((resolve, reject) => {
        const accessToken = getAccessToken();
        const request = new Request(`${process.env.REACT_APP_API_URL}/users/refresh-session`, {
            method: 'post',
            headers: {
                'Content-Type': applicationJSONContentType,
            },
            body: JSON.stringify({ accessToken }),
        });
        const refreshToken = getRefreshToken();
        request.headers.set('authorization', `bearer ${refreshToken}`);
        let response: HttpResponse<LoginResponse>;
        fetch(request)
            .then((res) => {
                response = res;
                return res.json();
            })
            .then((body) => {
                if (response.ok) {
                    response.parsedBody = body;
                    if (response?.parsedBody?.message === 'Success') {
                        const parsedToken = jwt_decode(
                            response.parsedBody.tokens.accessToken,
                        ) as any;
                        localStorage.setItem('Expired', parsedToken?.exp);
                        localStorage.setItem('SmSession', response.parsedBody.tokens.accessToken);
                        localStorage.setItem(
                            'SmRefreshSession',
                            response.parsedBody.tokens.refreshToken,
                        );
                        resolve(true);
                    }
                } else {
                    reject(response);
                }
            });
    });
};

export const httpMultipart = async (config: {
    path: string;
    method: string;
    body: FormData;
}): Promise<any> => {
    try {
        const accessToken = getAccessToken();
        return await axios(`${process.env.REACT_APP_API_URL}${config.path}`, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=<calculated when request is sent>`,
                Authorization: `Bearer ${accessToken}`,
            },
            data: config.body,
            method: config.method,
        });
    } catch (error) {
        window.scroll(0, 0);
        alertService.error(
            error?.response?.data?.errors?.name[0] || error?.response?.data?.message,
        );
    }
};

export const httpBuffer = async (
    path: string,
    responseType: ResponseType,
    method: Method,
    data?: any,
): Promise<any> => {
    try {
        const accessToken = getAccessToken();
        const options: AxiosRequestConfig = {
            responseType: responseType,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            method: method,
            data: data,
        };
        const response = await axios(`${process.env.REACT_APP_API_URL}${path}`, options);
        return response.data;
    } catch (error) {
        window.scroll(0, 0);
        alertService.error(error?.response?.data?.errors?.name[0]);
    }
};

export const httpExternal = async (config: HttpRequest<any>): Promise<HttpResponse<any>> => {
    const options: AxiosRequestConfig = {
        headers: {
            'Content-Type': applicationJSONContentType,
        },
        method: config.method,
        data: config.body,
    };
    const result = await axios(`${config.path}`, options);
    return result.data;
};

export const httpTest = <RESB, REQB>(
    config: HttpRequest<REQB>,
    accessToken?: string,
    redirectError: boolean = true,
): Promise<HttpResponse<RESB>> => {
    return new Promise((resolve, reject) => {
        const request = new Request(`${config.path}`, {
            method: config.method || 'get',
            headers: {
                'Content-Type': applicationJSONContentType,
            },
            body: config.body ? JSON.stringify(config.body) : undefined,
        });
        if (accessToken) {
            request.headers.set('authorization', `bearer ${accessToken}`);
        }
        let response: HttpResponse<RESB>;
        fetch(request)
            .then((res) => {
                response = res;
                return res.json();
            })
            .then(async (body) => {
                if (response.status === 401) {
                    if (await tryRefreshSession()) {
                        resolve(http(config, true, redirectError));
                        return;
                    } else {
                        window.location.href = '/login';
                        clearState();
                        reject(response);
                        return;
                    }
                }
                if (body.errors || body.error) {
                    window.scrollTo(0, 0);
                    alertService.error(body.title || body.error);
                    reject(response);
                    return;
                }
                if (response.ok) {
                    response.parsedBody = body;
                    resolve(response);
                } else {
                    if (
                        (redirectError && response.status === 404) ||
                        (redirectError && response.status === 403)
                    ) {
                        window.location.href = '/404';
                        reject(response);
                        return;
                    }
                    alertService.error(body.message);
                    window.scrollTo(0, 0);
                    reject(response);
                }
            });
    });
};
