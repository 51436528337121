import { Image } from 'antd';
import styled from 'styled-components';
import { blueDark01, blueLink, grayDark05, grayLight01 } from '../../../../../styles/colors';
import { InvisibleButton } from '../../../../../styles/common.styles';

export const DivisionItemContainer = styled('div')`
    display: flex;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;
`;

export const DivisionTitleContainer = styled('div')`
    display: flex;
    align-items: center;
    &:hover {
        .hover {
            color: ${blueLink};
            cursor: pointer;
        }
        .switch-button {
            background-color: ${blueLink} !important;
            &:after {
                background: white;
            }
            &:before {
                background: white;
            }
        }
    }
`;

export const DivisionTitle = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 20px;
    line-height: 24px;
    color: ${blueDark01};
    margin-right: 5px;
    &.division-id {
        margin-left: -10.5px;
    }
`;

export const DivisionItemWrapper = styled('div')`
    border-bottom: 0.5px solid ${grayLight01};
    border-top: 0.5px solid ${grayLight01};
    &.clicked {
        border-bottom: 0.5px solid ${grayDark05};
        border-top: 0.5px solid ${grayDark05};
    }
`;

export const ExportDivisionImage = styled(Image)`
    cursor: pointer;
`;

export const OpenCloseDivisionButton = styled(InvisibleButton)`
    align-items: flex-start;
    margin-top: 2px;
    &.closeOpenDivision {
        margin-top: 20px;
        margin-left: 8px;
    }
`;
