import React from 'react';
import { Image } from 'antd';
import { estimatedInMinutes, formatTimeEST } from '../../../../../helpers/dateHelper';
import { EC3ExportStatus } from '../../../../../models/ec3/enums';
import { EC3Error, EC3Export } from '../../../../../models/ec3/types';
import { blueLink, error } from '../../../../../styles/colors';
import { EC3Link } from '../../productsTab/ProductsTab.styles';
import { Text } from '../../../../../styles/common.styles';
import {
    ExportColumn,
    ExportColumnName,
    ExportContainer,
    ExportErrorText,
    ExportRowName,
    ExportRowValue,
} from './ExportData.styles';
import ProgressBar from '../../../../../components/common/progressBar/ProgressBar';

interface Props {
    ec3ExportData: EC3Export;
    competedExportCount: number;
}

const ExportData: React.FC<Props> = ({ ec3ExportData, competedExportCount }) => {
    const getLinkOrProgressBar = () => {
        if (ec3ExportData.export.status === EC3ExportStatus.Cancelled) {
            return <></>;
        }
        if (ec3ExportData.export.status === EC3ExportStatus.Done) {
            return (
                <EC3Link>
                    <Image src="src/assets/images/arrow-up-right-from-square.svg" preview={false} />
                    <a href={ec3ExportData.eC3Project.link} target="_blank">
                        <Text weight={400} size={14} height={18} color={blueLink}>
                            View project in EC3 tool
                        </Text>
                    </a>
                </EC3Link>
            );
        }
        if (ec3ExportData.export.status === EC3ExportStatus.NotExported) {
            return <></>;
        }
        if (
            (ec3ExportData.export.completedCallCount <= ec3ExportData.export.allCallCount &&
                competedExportCount <= ec3ExportData.export.allCallCount) ||
            ec3ExportData.export.status === EC3ExportStatus.InProgress ||
            ec3ExportData.export.status === EC3ExportStatus.CollectionsCteated ||
            ec3ExportData.export.status === EC3ExportStatus.ProjectCreated ||
            ec3ExportData.export.status === EC3ExportStatus.CreateProjectView
        ) {
            return (
                <ProgressBar
                    total={ec3ExportData.export.allCallCount}
                    completed={ec3ExportData.export.completedCallCount}
                    percent={ec3ExportData.export.completenessPercentage}
                />
            );
        }
        return <></>;
    };

    const createErrorMsgSpan = (errorMessage: string, ec3Error: any) => {
        const nonFieldErrors = ec3Error.validation_errors?.non_field_errors;
        const nonFieldError = nonFieldErrors?.length ? nonFieldErrors[0] : undefined;
        const standardDeviation = ec3Error.validation_errors?.standard_deviation;
        const standardDeviationErrors = standardDeviation?.length ? standardDeviation.join('. ') : undefined;

        return (
            <span>
            {
                errorMessage || standardDeviationErrors || nonFieldError ||
                ec3Error.validation_errors?.selected_material_id || ec3Error?.detail || ''
            }
            </span>
        );
    }

    const getExportStatus = () => {
        if (ec3ExportData.export.status === EC3ExportStatus.Cancelled) {
            let ec3Error: any = {};
            let errorMessage = '';
            if (typeof ec3ExportData.export.errorMessage === 'string') {
                try {
                    ec3Error = JSON.parse(ec3ExportData.export.errorMessage) as EC3Error;
                } catch (exception) {
                    errorMessage = ec3ExportData.export.errorMessage;
                }
            } else {
                errorMessage = ec3ExportData.export.errorMessage;
            }
            return (
                <ExportErrorText weight={400} height={16} size={14} color={error}>
                    {`Error: `}{' '}
                    {createErrorMsgSpan(errorMessage, ec3Error)}
                </ExportErrorText>
            );
        }
        if (ec3ExportData.export.status === EC3ExportStatus.Done) {
            return <>Completed</>;
        }
        if (ec3ExportData.export.status === EC3ExportStatus.NotExported) {
            return <>No export started</>;
        }
        if (
            (ec3ExportData.export.completedCallCount <= ec3ExportData.export.allCallCount &&
                competedExportCount <= ec3ExportData.export.allCallCount) ||
            ec3ExportData.export.status === EC3ExportStatus.InProgress ||
            ec3ExportData.export.status === EC3ExportStatus.CollectionsCteated ||
            ec3ExportData.export.status === EC3ExportStatus.ProjectCreated ||
            ec3ExportData.export.status === EC3ExportStatus.CreateProjectView
        ) {
            const estimatedTime = estimatedInMinutes(
                ec3ExportData.export.createdOn,
                ec3ExportData.export.completedCallCount,
                ec3ExportData.export.allCallCount,
            );

            return (
                <span>
                    {parseInt(
                        (
                            (ec3ExportData.export.completedCallCount /
                                ec3ExportData.export.allCallCount) *
                            100
                        ).toString(),
                    )}
                    {`% `}{' '}
                    {`- estimated ${
                        estimatedTime > 0 ? estimatedTime : 'less than 1'
                    } minutes remaining`}
                </span>
            );
        }
        return <></>;
    };
    const getTimeRemaining = () => {
        if (ec3ExportData.export.status === EC3ExportStatus.NotExported) {
            return <>No export started</>;
        }
        return <>{formatTimeEST(ec3ExportData.export.createdOn).split(',').slice(-2).join(',')}</>;
    };

    return (
        <ExportContainer>
            <ExportColumnName>
                <ExportRowName>Time started</ExportRowName>
                <ExportRowName>Export status</ExportRowName>
            </ExportColumnName>
            <ExportColumn>
                <ExportRowValue>{getTimeRemaining()} </ExportRowValue>
                <ExportRowValue>{getExportStatus()}</ExportRowValue>
                {((ec3ExportData.export.completedCallCount <= ec3ExportData.export.allCallCount &&
                    competedExportCount <= ec3ExportData.export.allCallCount) ||
                    ec3ExportData.export.status === EC3ExportStatus.Done ||
                    ec3ExportData.export.status === EC3ExportStatus.InProgress ||
                    ec3ExportData.export.status === EC3ExportStatus.CollectionsCteated ||
                    ec3ExportData.export.status === EC3ExportStatus.ProjectCreated ||
                    ec3ExportData.export.status === EC3ExportStatus.CreateProjectView) && (
                    <ExportRowValue>{getLinkOrProgressBar()}</ExportRowValue>
                )}
                {ec3ExportData.export.status === EC3ExportStatus.Cancelled && (
                    <ExportErrorText weight={400} height={16} size={14} color={error}>
                        <span>
                            This error message is from the EC3 API. It recommends trying your export
                            later.
                        </span>
                    </ExportErrorText>
                )}
            </ExportColumn>
        </ExportContainer>
    );
};

export default ExportData;
