import styled from 'styled-components';
import { Space, Dropdown } from 'antd';
import { blueDark01, blueDark02, blueDark03, grayLight01 } from '../../../../styles/colors';
import { PrimaryButton } from '../../../../styles/common.styles';

export const ActionButton = styled(PrimaryButton)`
    border: 1px solid ${grayLight01};
    border-radius: 3px;
    padding: 0 14px;
    background-color: ${blueDark02};
    color: ${grayLight01};
    width: 64px;
    &:hover {
        background-color: ${blueDark03};
        border: 1px solid ${grayLight01};
        color: ${grayLight01};
    }
    &:focus {
        border: 1px solid ${grayLight01};
        color: ${grayLight01};
        background-color: ${blueDark01};
    }
`;

export const UsersDotsAction = styled('div')`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${grayLight01};
    box-shadow: 0px 8px 0px ${grayLight01}, 0px 16px 0px ${grayLight01};
    position: absolute;
    top: 6px;
    left: 13px;
`;

export const OpenActionsMenu = styled('button')`
    border: 1px solid ${grayLight01};
    border-radius: 3px;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    width: 32px;
    height: 32px;
    &:hover {
        background-color: ${blueDark03};
        border: 1px solid ${grayLight01};
        color: ${grayLight01};
    }
`;

export const UsersActionSpace = styled(Space)`
    gap: 16px !important;
    height: 32px;
`;

export const UsersActionDropdown = styled(Dropdown)`
    display: flex;
`;
