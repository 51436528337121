import React from 'react';
export const style: { [key: string]: React.CSSProperties } = {
    title: {
        margin: '0',
    },
    divider: {
        borderTop: '3px solid black',
        margin: '5px 0',
    },
    planContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: '40px 10%',
        padding: '0 5%',
    },
    gridItem: {
        padding: '0',
    },
    card: {
        height: '100%',
        textAlign: 'center',
        padding: '0px',
        borderRadius: '0',
        border: '0.5px solid #f0f0f0',
    },
    mainHeader: {
        backgroundColor: '#8fbac5',
        padding: '8px 0',
    },
    header: {
        backgroundColor: '#f2f2f2',
        color: '#0a0603',
        padding: '1px 0',
    },
    titleHide: {
        backgroundColor: '#f2f2f2',
        opacity: '0',
    },
    mainTitle: {
        color: '#ffff',
    },
    secondaryTitle: {
        color: '#0a0603',
        whiteSpace: 'nowrap',
    },
    cardBody: {
        padding: '20px',
        height: '100%',
        position: 'relative',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    usersInput: {
        marginLeft: '10px',
        width: '60px',
    },
    usersInputContainer: {
        margin: '20px 0',
    },
};

import styled from 'styled-components';
import { Button } from 'antd';
import { blueDark01, blueDark02, blueDark03 } from '../../styles/colors';

export const ChoosePlanButton = styled(Button)`
    border-radius: 3px;
    background-color: ${blueDark02};
    color: #ffffff;
    cursor: pointer;
    border: none;
    position: absolute;
    bottom: 100px;
    padding: 17px 17px;
    display: flex;
    align-items: center;
    &:hover {
        background-color: ${blueDark03};
        color: #ffff;
    }
    &:focus {
        background-color: ${blueDark01};
        color: #ffff;
    }
`;

export const TrialButton = styled(Button)`
    border-radius: 3px;
    background-color: ${blueDark02};
    color: #ffffff;
    cursor: pointer;
    border: none;
    position: absolute;
    bottom: 60px;
    padding: 17px 17px;
    display: flex;
    align-items: center;
    &:hover {
        background-color: ${blueDark03};
        color: #ffff;
    }
    &:focus {
        background-color: ${blueDark01};
        color: #ffff;
    }
`;
