import { ExternalProduct } from '../../models/product/types';

export const mockProducts: Array<ExternalProduct> = [
    {
        type: 'product',
        id: 9376,
        status: 'live',
        dateCreated: '03/11/2020',
        dateUpdated: '07/09/2022',
        productName: 'AFT Carbon Smart™ Loose-Fill Cellulose Insulation',
        company: 'Advanced Fiber Technology',
        companyID: 23075,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 23',
        mfSectionLabel: 'Loose-Fill Insulation',
    },
    {
        type: 'product',
        id: 9194,
        status: 'live',
        dateCreated: '02/19/2020',
        dateUpdated: '08/16/2022',
        productName: '10 psi, Type I Neopor® Plus GPS',
        company: 'BASF | Neopor',
        companyID: 9194,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 13',
        mfSectionLabel: 'Board Insulation',
    },
    {
        type: 'product',
        id: 9190,
        status: 'live',
        dateCreated: '02/19/2020',
        dateUpdated: '08/16/2022',
        productName: '15 psi, Type II Neopor® Plus GPS',
        company: 'BASF | Neopor',
        companyID: 15195,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 13',
        mfSectionLabel: 'Board Insulation',
    },
    {
        type: 'product',
        id: 9191,
        status: 'live',
        dateCreated: '02/19/2020',
        dateUpdated: '08/16/2022',
        productName: '25 psi, Type IX Neopor® Plus GPS',
        company: 'BASF | Neopor',
        companyID: 15195,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 13',
        mfSectionLabel: 'Board Insulation',
    },
    {
        type: 'product',
        id: 25758,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '06/22/2022',
        productName: 'Gold Bond® eXP® Fire-Shield® Sheathing',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '06 00 00',
        mfDivisionLabel: 'Wood, Plastics, and Composites',
        mfSectionNumber: '06 16 00',
        mfSectionLabel: 'Sheathing',
    },
    {
        type: 'product',
        id: 25760,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '06/22/2022',
        productName: 'DEXcell® Glass Mat Roof Board',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 53 23',
        mfSectionLabel: 'Ethylene-Propylene-Diene-Monomer Roofing',
    },
    {
        type: 'product',
        id: 25746,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '06/22/2022',
        productName: 'Gold Bond® Kal-Kore® Fire-Shield C™ 5/8” Plaster Base',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 22 39',
        mfSectionLabel: 'Veneer Plaster Base',
    },
    {
        type: 'product',
        id: 25759,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '09/10/2021',
        productName: 'Gold Bond® eXP® Interior Extreme® AR Gypsum Panel',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25753,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '06/22/2022',
        productName: 'ProForm® All Purpose Joint Compound with Dust-Tech®',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25752,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '09/10/2021',
        productName: 'ProForm® Brand Lite-Blue Joint Compound',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25754,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '09/10/2021',
        productName: 'ProForm® Lite Blue™ Joint Compound with Dust-Tech®',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25750,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '09/10/2021',
        productName: 'ProForm® Multi-Use Joint Compound',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25757,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '09/01/2022',
        productName: 'ProForm® Quick Set Lite™ Setting Compound',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25756,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '09/01/2022',
        productName: 'ProForm® Quick Set™ Setting Compound',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25751,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '06/22/2022',
        productName: 'ProForm® Ultra Lite® All Purpose Joint Compound',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25755,
        status: 'live',
        dateCreated: '03/05/2020',
        dateUpdated: '06/22/2022',
        productName: 'ProForm™ Paper Joint Tape',
        company: 'National Gypsum',
        companyID: 5172,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 29 00',
        mfSectionLabel: 'Gypsum Board',
    },
    {
        type: 'product',
        id: 25530,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Metro Slab™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 76 00',
        mfSectionLabel: 'Roof Pavers',
    },
    {
        type: 'product',
        id: 25583,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Skyline™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 76 00',
        mfSectionLabel: 'Roof Pavers',
    },
    {
        type: 'product',
        id: 25644,
        status: 'live',
        dateCreated: '02/20/2020',
        dateUpdated: '06/22/2022',
        productName: 'Skyline™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 76 00',
        mfSectionLabel: 'Roof Pavers',
    },
    {
        type: 'product',
        id: 25504,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Ada Paver',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25505,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Anchorlock™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25506,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Antara®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25641,
        status: 'live',
        dateCreated: '02/20/2020',
        dateUpdated: '06/22/2022',
        productName: 'Arcana™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25572,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Artline™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25573,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Avante Ashlar™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25561,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Beacon Hill™ Flagstone',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25562,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Beacon Hill™ Smooth',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25563,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Boulevard™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25588,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Bristol Valley®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25507,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Bronte® Street Paver',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25508,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Brussels Block®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25564,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Brussels Premier™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25513,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'City Park Paver™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25574,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'City Park Paver™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25585,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'City Park Paver™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25599,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'City Park Paver™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25612,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Copthorne®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25613,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Courtstone®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25517,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Double Holland™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25527,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Europaver®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25558,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Europaver®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25565,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Granito™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25566,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Holland Premier™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25528,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Hollandstone™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25567,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Il Campo®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25568,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Mattoni™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25643,
        status: 'live',
        dateCreated: '02/20/2020',
        dateUpdated: '06/22/2022',
        productName: 'Metro Slab™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25532,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Optiloc®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25559,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Optiloc®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25601,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Optiloc®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25534,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Promenade™ Plank Paver',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25560,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Promenade™ Plank Paver',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25586,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Promenade™ Plank Paver',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25600,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Promenade™ Plank Paver',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25614,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Richcliff®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25589,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Senzo™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25602,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Series™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25596,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Skyline™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25642,
        status: 'live',
        dateCreated: '02/20/2020',
        dateUpdated: '06/22/2022',
        productName: 'Skyline™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25581,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Thornbury™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25645,
        status: 'live',
        dateCreated: '02/20/2020',
        dateUpdated: '06/22/2022',
        productName: 'Town Hall®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25570,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Transition™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25542,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Treo® Classic',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25571,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Treo® Premier',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25590,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Umbriano®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25546,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Westport™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25587,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Westport™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25547,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Windermere®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25548,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Windermere® Smooth',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 00',
        mfSectionLabel: 'Unit Paving',
    },
    {
        type: 'product',
        id: 25514,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'City Park Paver™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25557,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'City Park Paver™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25591,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'City Park Paver™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25603,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'City Park Paver™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25521,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Dura-Mat™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25518,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'DuraFlow®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25575,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'DuraFlow®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25522,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Line®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25576,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Line®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25592,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Line®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25604,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Line®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25523,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Optiloc™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25577,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Optiloc™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25607,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Optiloc™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25524,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Priora™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25578,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Priora™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25593,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Priora™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25605,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Priora™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25525,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Promenade®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25579,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Promenade®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25594,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Promenade®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25606,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Eco-Promenade®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25569,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Thornbury™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25616,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Town Hall®',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25608,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Tribeca Cobble™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25543,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Turfstone™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25545,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Uni Eco-Stone™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
        type: 'product',
        id: 25580,
        status: 'live',
        dateCreated: '02/18/2020',
        dateUpdated: '06/22/2022',
        productName: 'Uni Eco-Stone™',
        company: 'Unilock',
        companyID: 23172,
        mfDivisionNumber: '32 00 00',
        mfDivisionLabel: 'Exterior Improvements',
        mfSectionNumber: '32 14 43',
        mfSectionLabel: 'Porous Unit Paving',
    },
    {
       "type": "product",
       "id": 31050,
       "status": "live",
       "dateCreated": "09/15/2020",
       "dateUpdated": "09/19/2022",
       "productName": "EchoPanel, Mura and Aire",
       "company": "Woven Image",
       "companyID": 31019,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 78 00',
        mfSectionLabel: 'Interior Wall Paneling',
    },
    {
       "type": "product",
       "id": 62969,
       "status": "live",
       "dateCreated": "09/15/2020",
       "dateUpdated": "09/19/2022",
       "productName": "Basalt Dimension Stone",
       "company": "Natural Stone Institute",
       "companyID": 44220,
        mfDivisionNumber: '04 00 00',
        mfDivisionLabel: 'Masonry',
        mfSectionNumber: '04 41 00',
        mfSectionLabel: 'Dry-Placed Stone',
    },
    {
       "type": "product",
       "id": 9378,
       "status": "live",
       "dateCreated": "09/15/2020",
       "dateUpdated": "09/19/2022",
       "productName": "Ruberoid® MOP Granule",
       "company": "GAF",
       "companyID": 5257,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 13 52',
        mfSectionLabel: 'Modified Bituminous Sheet Waterproofing',
    },
    {
       "type": "product",
       "id": 9194,
       "status": "live",
       "dateCreated": "09/15/2020",
       "dateUpdated": "09/19/2022",
       "productName": "EnergyGuard™ NH HD Plus Polyiso Cover Board",
       "company": "GAF",
       "companyID": 5257,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 00',
        mfSectionLabel: 'Thermal Insulation',
    },
    {
       "type": "product",
       "id": 13954,
       "status": "live",
       "dateCreated": "09/15/2020",
       "dateUpdated": "09/19/2022",
       "productName": "Formica® Compact",
       "company": "Formica Corporation",
       "companyID": 5436,
        mfDivisionNumber: '06 00 00',
        mfDivisionLabel: 'Wood, Plastics, and Composites',
        mfSectionNumber: '06 20 23',
        mfSectionLabel: 'Interior Finish Carpentry',
    },
    {
       "type": "product",
       "id": 9102,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "EcoBatt® and EcoRoll® Foil Faced",
       "company": "Knauf Insulation",
       "companyID": 5258,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 00',
        mfSectionLabel: 'Thermal Insulation',
    },
    {
       "type": "product",
       "id": 7432,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "RUBEROID® Torch Smooth Membrane",
       "company": "GAF",
       "companyID": 5257,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 52 00',
        mfSectionLabel: 'Modified Bituminous Membrane Roofing',
    },
    {
       "type": "product",
       "id": 16078,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "K-13: Spray-Applied Thermal & Acoustical Finish System",
       "company": "International Cellulose Corporation",
       "companyID": 5505,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 29',
        mfSectionLabel: 'Sprayed Insulation',
        mfSectionNumber1: '07 20 00',
        mfSectionLabel1: 'Thermal Protection',
        mfSectionNumber2: '07 21 00',
        mfSectionLabel2: 'Thermal Insulation',
        mfSectionNumber3: '07 21 29',
        mfSectionLabel3: 'Sprayed Insulation',
    },
    {
       "type": "product",
       "id": 43443,
       "status": "live",
       "dateCreated": "09/15/2023",
        "productName": "Forbo Sustain 100",
        "company": "Forbo Flooring Systems",
        "companyID": 5020,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 65 43',
        mfSectionLabel: 'Linoleum Flooring',
        mfSectionNumber1: '09 60 00',
        mfSectionLabel1: 'Flooring',
        mfSectionNumber2: '09 65 00',
        mfSectionLabel2: 'Resilient Flooring',
        mfSectionNumber3: '09 65 43',
        mfSectionLabel3: 'Linoleum Flooring',
    },
    {
       "type": "product",
       "id": 9376,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "EnergyGuard™ NH Barrier Polyiso Insulation",
       "company": "GAF",
       "companyID": 5257,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 00',
        mfSectionLabel: 'Thermal Insulation',
        mfSectionNumber1: '07 20 00',
        mfSectionLabel1: 'Thermal Protection',
        mfSectionNumber2: '07 21 00',
        mfSectionLabel2: 'Thermal Insulation',
    },
    {
       "type": "product",
       "id": 45434,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "Bio Xorel Fabric",
       "company": "Carnegie Fabrics",
       "companyID": 5147,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 72 00',
        mfSectionLabel: 'Wall Coverings',
        mfSectionNumber1: '09 70 00',
        mfSectionLabel1: 'Wall Finishes',
        mfSectionNumber2: '09 72 00',
        mfSectionLabel2: 'Wall Coverings',
    },
    {
       "type": "product",
       "id": 25630,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "10 psi, Type I Neopor® Plus GPS",
       "company": "BASF | Neopor",
       "companyID": 15195,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 21 13',
        mfSectionLabel: 'Board Insulation',
        mfSectionNumber1: '07 20 00',
        mfSectionLabel1: 'Thermal Protection',
        mfSectionNumber2: '07 21 00',
        mfSectionLabel2: 'Thermal Insulation',
        mfSectionNumber3: '07 21 13',
        mfSectionLabel3: 'Board Insulation',
    },
    {
       "type": "product",
       "id": 9209,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": '1 1/2\" Type B Deck, B-36',
       "company": "ASC Steel Deck",
       "companyID": 5784,
        mfDivisionNumber: '05 00 00',
        mfDivisionLabel: 'Metals',
        mfSectionNumber: '05 31 00',
        mfSectionLabel: 'Steel Decking',
        mfSectionNumber1: '05 30 00',
        mfSectionLabel1: 'Metal Decking',
        mfSectionNumber2: '05 31 00',
        mfSectionLabel2: 'Steel Decking'
    },
    {
       "type": "product",
       "id":34517,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": 'Sikalastic-641 Lo-VOC RoofPro Fleece System',
       "company": "Sika Corporation",
        "companyID": 5304,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 56 00',
        mfSectionLabel: 'Fluid-Applied Roofing',
        mfSectionNumber1: '07 50 00',
        mfSectionLabel1: 'Membrane Roofing',
        mfSectionNumber2: '07 56 00',
        mfSectionLabel2: 'Fluid-Applied Roofing'
    },
    {
       "type": "product",
       "id":16036,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "GSB-78",
       "company": "Asphalt Systems",
       "companyID": 4992,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 51 13',
        mfSectionLabel: 'Built-Up Asphalt Roofing',
        mfSectionNumber1: '07 50 00',
        mfSectionLabel1: 'Membrane Roofing',
        mfSectionNumber2: '07 51 00',
        mfSectionLabel2: 'Built-up Bituminous Roofing',
        mfSectionNumber3: '07 51 13',
        mfSectionLabel3: 'Built-Up Asphalt Roofing'
    },
    {
       "type": "product",
       "id":34102,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "EverGuard® Extreme TPO Roofing Membrane",
       "company": "BMI Group",
       "companyID": 33885,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 55 00',
        mfSectionLabel: 'Protected Membrane Roofing',
        mfSectionNumber1: '07 50 00',
        mfSectionLabel1: 'Membrane Roofing',
        mfSectionNumber2: '07 55 00',
        mfSectionLabel2: 'Protected Membrane Roofing'
    },
    {
       "type": "product",
       "id":38202,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "SBS-Modified Commercial Roofing: Self-Adhered, Hot Asphalt or Cold Adhesive & Torch Applied",
       "company": "CertainTeed",
       "companyID": 5993,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 54 00',
        mfSectionLabel: 'Thermoplastic Membrane Roofing',
        mfSectionNumber1: '07 50 00',
        mfSectionLabel1: 'Membrane Roofing',
        mfSectionNumber2: '07 54 00',
        mfSectionLabel2: 'Thermoplastic Membrane Roofing'
    },
    {
       "type": "product",
       "id":37154,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "BUR Non-Modified Asphalt Commercial Roofing: Hot Asphalt Applied",
       "company": "CertainTeed",
       "companyID": 5993,
        mfDivisionNumber: '07 00 00',
        mfDivisionLabel: 'Thermal and Moisture Protection',
        mfSectionNumber: '07 55 56.13',
        mfSectionLabel: 'Hot-Applied Rubberized Asphalt Protected Membrane Roofing',
        mfSectionNumber1: '07 50 00',
        mfSectionLabel1: 'Membrane Roofing',
        mfSectionNumber2: '07 55 00',
        mfSectionLabel2: 'Protected Membrane Roofing',
        mfSectionNumber3: '07 55 56',
        mfSectionLabel3: 'Fluid-Applied Protected Membrane Roofing'
    },
    {
       "type": "product",
       "id":201548,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "CQUEST BioX Modular Flooring Tile (North America)",
       "company": "Interface",
       "companyID": 5032,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 68 00',
        mfSectionLabel: 'Carpeting',
        mfSectionNumber1: '09 60 00',
        mfSectionLabel1: 'Flooring',
        mfSectionNumber2: '09 68 00',
        mfSectionLabel2: 'Carpeting'
    },
    {
       "type": "product",
       "id":22919,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "Mars™ High-NRC Acoustical Panels 80-35",
       "company": "USG",
       "companyID": 5185,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 51 00',
        mfSectionLabel: 'Acoustical Ceilings',
        mfSectionNumber1: '09 50 00',
        mfSectionLabel1: 'Ceilings',
        mfSectionNumber2: '09 51 00',
        mfSectionLabel2: 'Acoustical Ceilings'
    },
    {
       "type": "product",
       "id":127091,
       "status": "live",
       "dateCreated": "09/15/2023",
       "dateUpdated": "09/19/2022",
       "productName": "Arborisa",
       "company": "Arktura",
       "companyID": 16579,
        mfDivisionNumber: '09 00 00',
        mfDivisionLabel: 'Finishes',
        mfSectionNumber: '09 50 00',
        mfSectionLabel: 'Ceilings',
        mfSectionNumber1: '09 50 00',
        mfSectionLabel1: 'Ceilings',
    },
    {
        "type": "product",
        "id": 415398,
        "status": "live",
        "dateCreated": "09/15/2023",
        "dateUpdated": "09/19/2023",
        "productName": "TestProductName",
        "company": "TestManufacturer",
        "companyID": null,
         mfDivisionNumber: '08 00 00',
         mfDivisionLabel: 'Openings',
         mfSectionNumber: '08 30 00',
         mfSectionLabel: 'Conservation Treatment for Period Openings',
         mfSectionNumber1: '08 30 00',
         mfSectionLabel1: 'Conservation Treatment for Period Openings',
     },
     {
        "type": "product",
        "id": 415400,
        "status": "live",
        "dateCreated": "09/15/2023",
        "dateUpdated": "09/19/2023",
        "productName": "TestProductName2",
        "company": "TestManufacturer",
        "companyID": null,
         mfDivisionNumber: '08 00 00',
         mfDivisionLabel: 'Openings',
         mfSectionNumber: '08 30 00',
         mfSectionLabel: 'Conservation Treatment for Period Openings',
         mfSectionNumber1: '08 30 00',
         mfSectionLabel1: 'Conservation Treatment for Period Openings',
     },
     {
        "type": "product",
        "id": 415408,
        "status": "live",
        "dateCreated": "09/15/2023",
        "dateUpdated": "09/19/2023",
        "productName": "TestProductName3",
        "company": "TestManufacturer2",
        "companyID": null,
         mfDivisionNumber: '08 00 00',
         mfDivisionLabel: 'Openings',
         mfSectionNumber: '08 30 00',
         mfSectionLabel: 'Conservation Treatment for Period Openings',
         mfSectionNumber1: '08 30 00',
         mfSectionLabel1: 'Conservation Treatment for Period Openings',
     },
     {
        "type": "product",
        "id": 242157,
        "status": "live",
        "dateCreated": "09/15/2023",
        "dateUpdated": "09/19/2022",
        "productName": "Upholstery Fabric Pelle",
        "company": "Wolf-Gordon",
        "companyID": 5527,
         mfDivisionNumber: '12 00 00',
         mfDivisionLabel: 'Furnishings',
         mfSectionNumber: '12 05 13',
         mfSectionLabel: 'Fabrics',
         mfSectionNumber1: '12 05 00',
         mfSectionLabel1: 'Common Work Results for Furnishings',
         mfSectionNumber2: '12 05 13',
         mfSectionLabel3: 'Fabrics'
      },
      {
        "type": "product",
        "id": 227553,
        "status": "live",
        "dateCreated": "09/15/2023",
        "dateUpdated": "09/19/2022",
        "productName": "Upholstery Pattern Lerida",
        "company": "Wolf-Gordon",
        "companyID": 5527,
         mfDivisionNumber: '12 00 00',
         mfDivisionLabel: 'Furnishings',
         mfSectionNumber: '12 05 13',
         mfSectionLabel: 'Fabrics',
         mfSectionNumber1: '12 05 00',
         mfSectionLabel1: 'Common Work Results for Furnishings',
         mfSectionNumber2: '12 05 13',
         mfSectionLabel3: 'Fabrics'
      },
      {
        "type": "product",
        "id": 415462,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Test PN 343423",
        "company": "Test MN32232",
        "companyID": 415460,
         mfDivisionNumber: '08 00 00',
         mfDivisionLabel: 'Openings',
         mfSectionNumber: '08 50 00',
         mfSectionLabel: 'Windows',
         mfSectionNumber1: '08 50 00',
         mfSectionLabel1: 'Windows',
      },
      {
        "type": "product",
        "id": 62987,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Bluestone Dimension Stone",
        "company": "Natural Stone Institute",
        "companyID": 44220,
         mfDivisionNumber: '04 00 00',
         mfDivisionLabel: 'Masonry',
         mfSectionNumber: '04 41 00',
         mfSectionLabel: 'Dry-Placed Stone',
         mfSectionNumber1: '04 40 00',
         mfSectionLabel1: 'Stone Assemblies',
         mfSectionNumber2: '04 41 00',
         mfSectionLabel2: 'Dry-Placed Stone',
      },
      {
        "type": "product",
        "id": 98266,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Alluvia Direct Mount",
        "company": "Arktura",
        "companyID": 16579,
         mfDivisionNumber: '09 00 00',
         mfDivisionLabel: 'Finishes',
         mfSectionNumber: '09 50 00',
         mfSectionLabel: 'Ceilings',
         mfSectionNumber1: '09 50 00',
         mfSectionLabel1: 'Ceilings',
      },
      {
        "type": "product",
        "id": 415466,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Product outside TC",
        "company": "New Company Out",
        "companyID": 415464,
         mfDivisionNumber: '12 00 00',
         mfDivisionLabel: 'Furnishings',
         mfSectionNumber: '12 05 00',
         mfSectionLabel: 'Common Work Results for Furnishings',
         mfSectionNumber1: '12 05 00',
         mfSectionLabel1: 'Common Work Results for Furnishings',
      },
      {
        "type": "product",
        "id": 37924,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Polyisocyanurate Wall Insulation Boards",
        "company": "GAF",
        "companyID": 5257,
         mfDivisionNumber: '07 00 00',
         mfDivisionLabel: 'Thermal and Moisture Protection',
         mfSectionNumber: '07 21 00',
         mfSectionLabel: 'Thermal Insulation',
         mfSectionNumber1: '07 20 00',
         mfSectionLabel1: 'Thermal Protection',
         mfSectionNumber2: '07 21 00',
         mfSectionLabel12: 'Thermal Insulation',
      },
      {
        "type": "product",
        "id": 29282,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "MasterSeal 350",
        "company": "Master Builders Solutions",
        "companyID": 29233,
         mfDivisionNumber: '07 00 00',
         mfDivisionLabel: 'Thermal and Moisture Protection',
         mfSectionNumber: '07 18 00',
         mfSectionLabel: 'Traffic Coatings',
         mfSectionNumber1: '07 10 00',
         mfSectionLabel1: 'Dampproofing and Waterproofing',
         mfSectionNumber2: '07 18 00',
         mfSectionLabel2: 'Traffic Coatings',
      },
      {
        "type": "product",
        "id": 31208,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Light and Heavy Density Mineral Wool Board",
        "company": "Aislantes Minerales",
        "companyID": 253466,
         mfDivisionNumber: '07 00 00',
         mfDivisionLabel: 'Thermal and Moisture Protection',
         mfSectionNumber: '07 21 13.19',
         mfSectionLabel: 'Mineral Board Insulation',
         mfSectionNumber1: '07 20 00',
         mfSectionLabel1: 'Thermal Protection',
         mfSectionNumber2: '07 21 00',
         mfSectionLabel2: 'Thermal Insulation',
         mfSectionNumber3: '07 21 13',
         mfSectionLabel3: 'Board Insulation',
      },
      {
        "type": "product",
        "id": 9085,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "MetalWorks™ 3D",
        "company": "Armstrong Ceiling & Wall Solutions",
        "companyID": 5314,
         mfDivisionNumber: '09 00 00',
         mfDivisionLabel: 'Finishes',
         mfSectionNumber: '09 54 00',
         mfSectionLabel: 'Specialty Ceilings',
         mfSectionNumber1: '09 50 00',
         mfSectionLabel1: 'Ceilings',
         mfSectionNumber2: '09 54 00',
         mfSectionLabel2: 'Specialty Ceilings',
      },
      {
        "type": "product",
        "id": 29791,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Calla®",
        "company": "Armstrong Ceiling & Wall Solutions",
        "companyID": 5314,
         mfDivisionNumber: '09 00 00',
         mfDivisionLabel: 'Finishes',
         mfSectionNumber: '09 51 00',
         mfSectionLabel: 'Acoustical Ceilings',
         mfSectionNumber1: '09 50 00',
         mfSectionLabel1: 'Ceilings',
         mfSectionNumber2: '09 51 00',
         mfSectionLabel2: 'Acoustical Ceilings',
      },
      {
        "type": "product",
        "id": 16078,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "K-13: Spray-Applied Thermal & Acoustical Finish System",
        "company": "International Cellulose Corporation",
        "companyID": 5505,
         mfDivisionNumber: '07 00 00',
         mfDivisionLabel: 'Thermal and Moisture Protection',
         mfSectionNumber: '07 21 29',
         mfSectionLabel: 'Sprayed Insulation',
         mfSectionNumber1: '07 20 00',
         mfSectionLabel1: 'Thermal Protection',
         mfSectionNumber2: '07 21 00',
         mfSectionLabel2: 'Thermal Insulation',
         mfSectionNumber3: '07 21 29',
         mfSectionLabel3: 'Sprayed Insulation',
      },
      {
        "type": "product",
        "id": 43443,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Forbo Sustain 100",
        "company": "Forbo Flooring Systems",
        "companyID": 5020,
         mfDivisionNumber: '09 00 00',
         mfDivisionLabel: 'Finishes',
         mfSectionNumber: '09 65 43',
         mfSectionLabel: 'Linoleum Flooring',
         mfSectionNumber1: '09 60 00',
         mfSectionLabel1: 'Flooring',
         mfSectionNumber2: '09 65 00',
         mfSectionLabel2: 'Resilient Flooring',
         mfSectionNumber3: '09 65 43',
         mfSectionLabel3: 'Linoleum Flooring',
      },
      {
        "type": "product",
        "id": 9104,
        "status": "live",
        "dateCreated": "09/15/2024",
        "dateUpdated": "09/19/2022",
        "productName": "Acoustical Smooth Board",
        "company": "Knauf Insulation",
        "companyID": 5258,
         mfDivisionNumber: '07 00 00',
         mfDivisionLabel: 'Thermal and Moisture Protection',
         mfSectionNumber: '07 21 13',
         mfSectionLabel: 'Board Insulation',
         mfSectionNumber1: '07 20 00',
         mfSectionLabel1: 'Thermal Protection',
         mfSectionNumber2: '07 21 00',
         mfSectionLabel2: 'Thermal Insulation',
         mfSectionNumber3: '07 21 13',
         mfSectionLabel3: 'Board Insulation',
      },
      {
        "type": "product",
        "id": 42323,
        "status": "live",
        "dateCreated": "09/15/2021",
        "dateUpdated": "09/19/2022",
        "productName": "SpecSeal Composite Sheet",
        "company": "Specified Technologies",
        "companyID": 16180,
         mfDivisionNumber: '07 00 00',
         mfDivisionLabel: 'Thermal and Moisture Protection',
         mfSectionNumber: '07 84 13.16',
         mfSectionLabel: 'Penetration Firestopping Devices',
         mfSectionNumber1: '07 80 00',
         mfSectionLabel1: 'Fire and Smoke Protection',
         mfSectionNumber2: '07 84 00',
         mfSectionLabel2: 'Firestopping',
         mfSectionNumber3: '07 84 13',
         mfSectionLabel3: 'Penetration Firestopping',
      },
      {
        "type": "product",
        "id": 589842,
        "status": "live",
        "dateCreated": "09/15/2021",
        "dateUpdated": "09/19/2022",
        "productName": "AAA - FAKE Product - EcoBatt® Kraft Faced - test purpose",
        "company": "Knauf Insulation",
        "companyID": 5258,
         mfDivisionNumber: '07 00 00',
         mfDivisionLabel: 'Thermal and Moisture Protection',
         mfSectionNumber: '07 21 00',
         mfSectionLabel: 'Thermal Insulation',
         mfSectionNumber1: '07 20 00',
         mfSectionLabel1: 'Thermal Protection',
         mfSectionNumber2: '07 21 00',
         mfSectionLabel2: 'Thermal Insulation',
      },

];
