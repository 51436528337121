import React, { useCallback, useEffect, useState } from 'react';
import PageHeader from '../../../components/common/pageHeader/PageHeader';
import { Dropdown } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setSMAdminUsersTableData } from '../../../redux/reducers/smAdminUsersReducer/SMAdminUserSlice';
import { clearSelectedRows, setUsersTableData } from '../../../redux/reducers/usersReducer/UserSlice';
import { PageContainer } from '../../../styles/common.styles';
import {
    CreateAccountButton,
    CreateAccountButtonContainer,
    CreateAccountMenu,
    UsersActionSpace,
    UsersTableActions,
    UsersPageActionsButton,
} from './SMAdminUsersPage.styles';
import { extractFields } from '../../../helpers/extract';
import { getSMAdminsAction, getSMAdminUsersAction, deleteSMAdminUsersAction } from '../../../redux/actions/smAdminActions';
import { TableData } from '../../../models/tableData/types';
import {
    TableSMAdminUserData,
    SMAdminUserTableFields,
    SMAdminTableFields,
} from '../../models/user/smAdminUser';
import { actionMenuTop, createAccountMenu } from './SMAdminUsersPage.mockData';
import UsersTable from '../../components/users/usersTable/SMAdminUsersTable';
import { UsersPageSearch } from '../../components/common/smAdminUsersPageSearch/SMAdminUsersPageSearch';
import { getUserReferencesAction } from '../../../redux/actions/userActions';
import { SelectData } from '../../../models/common/types';
import SMAdminCreateOneAccountModal from '../../components/users/smAdminCreateOneAccountModal/SMAdminCreateOneAccountModal';
import CreateOneSMAdminModal from '../../components/admins/createOneSMAdminModal/CreateOneSMAdminModal';
import { SubscriptionPlan } from '../../../redux/actions/userActions/types';
import { getSubscriptionAction } from '../../../redux/actions/subscriptionActions';
import { ActionResponse } from '../../../models/actionResponse/types';
import { alertService } from '../../../services';
import UsersActions from './usersActions/UsersActions';
import DeleteModal from '../../components/common/deleteModal/DeleteModal';
import ExportUsersToCsv from '../../components/admins/exportUsersToCsv/ExportUsersToCsv';
interface UsersParams {
    from?: string;
    userType: UserType;
}

export type UserType = 'user' | 'admin';

const UsersForSMAdmin: React.FC<UsersParams> = ({ userType }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { userReducer, authReducer } = selector;

    const [openCreateMenu, setOpenCreateMenu] = useState(false);
    const [openActionsMenu, setOpenActionsMenu] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenExportModal, setIsOpenExportModal] = useState(false);

    const [tableData, setTableData] = useState<TableData>({
        curPage: 1,
        pageSize: 10,
        searchString: '',
        sortDirection: 'asc',
        sortExpression: null,
    });

    const [state, setState] = useState({
        users: Array<SMAdminUserTableFields | SMAdminTableFields>(),
        total: 0,
        newUsers: Array<SMAdminUserTableFields | SMAdminTableFields>(),
    });

    const [subscriptionPlans, setSubscriptionPlans] = useState<Array<SubscriptionPlan>>([]);

    const [openModals, setOpenModals] = useState({
        one: false,
        admin: false,
    });

    useEffect(() => {
        getData();
        dispatch(clearSelectedRows());
    }, []);

    const getData = async () => {
        if (userType === 'user') {
            await getDataForSelect();
            history.replaceState(null, '', '/users');
        } else {
            history.replaceState(null, '', '/admins');
        }
        await getSubscriptions();
        await getUsers(tableData);
    };

    const getSubscriptions = async () => {
        const { payload } = (await dispatch(getSubscriptionAction())) as ActionResponse<
            Array<SubscriptionPlan>
        >;
        if (payload.data) {
            setSubscriptionPlans(payload.data);
        } else {
            alertService.error('Sorry, plans is not available now');
        }
    };

    const getDataForSelect = async () => {
        const { payload } = (await dispatch(getUserReferencesAction())) as any;
        if (payload.data) {
            const availableRoles = new Map<number, string>(
                payload.data.allAccountRoles.map((item: SelectData) => {
                    return [item.id, item.name];
                }),
            );
            return availableRoles;
        }
        return undefined;
    };

    const getUsers = async (
        currentTableData: TableData,
        newUsers?: Array<SMAdminUserTableFields | SMAdminTableFields>,
    ) => {
        const additionalUsers = Array.isArray(newUsers) ? newUsers : [];
        const { payload } =
            userType === 'user'
                ? ((await dispatch(getSMAdminUsersAction(currentTableData))) as any)
                : ((await dispatch(getSMAdminsAction(currentTableData))) as any);
        const result = extractFields<SMAdminUserTableFields | SMAdminTableFields>(
            Object.keys(new TableSMAdminUserData()),
            payload.data.items.concat(additionalUsers),
        );
        const users = [
            ...new Map(
                result.map((item: SMAdminUserTableFields | SMAdminTableFields) => [item.key, item]),
            ).values(),
        ] as Array<SMAdminUserTableFields | SMAdminTableFields>;

        if (userType === 'user') {
            const roles = await getDataForSelect();
            if (roles) {
                users.forEach((item: SMAdminUserTableFields) => {
                    item.roleName = roles.get(item.roleId);
                });
            }
        }
        dispatch(setSMAdminUsersTableData(currentTableData));
        setTableData(currentTableData);
        setState({
            users: users,
            total: payload.data.currentCount,
            newUsers: additionalUsers,
        });
    };

    const handleCreateMenu = () => {
        setOpenCreateMenu(!openCreateMenu);
    };

    const handleMenuSelect = (data: { key: string }) => {
        if (data.key === 'deletebulk') {
            const selectedUsersIds = selector.userReducer.selectedRows.map((x) => String(x.key));
            const currentUsersId = String(authReducer.user?.id);
            if (selectedUsersIds.includes(currentUsersId)) {
                alertService.error("You can't delete your own account with this command");
                return;
            }
            openDeleteModal();
        }
        if (data.key === 'csv') {
            openExportModal();
        }

        setOpenCreateMenu(false);
        setOpenModals({ ...openModals, [data.key]: !openModals[data.key] });
    };

    const openDeleteModal = () => {
        setIsOpenDeleteModal(true);
    };

    const openExportModal = () => {
        setIsOpenExportModal(true);
    };

    const deleteUser = async () => {
        const res = await dispatch(deleteSMAdminUsersAction(selector.userReducer.selectedRows.map((x) => (x.key))) as any);
        if (res.payload?.message) {
              reloadAfterCloseModal(tableData);
        }
        setIsOpenDeleteModal(false);
    };

    const exportUser = async () => {
        setIsOpenExportModal(false);
    };

    const handleActionsMenu = () => {
        setOpenActionsMenu(!openActionsMenu);
    };

    const handleSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.value && tableData.searchString) {
                getUsers({ ...tableData, searchString: '' });
            }
            setTableData({ ...tableData, searchString: event.target.value });
        },
        [tableData.searchString],
    );

    const onSearch = () => {
        getUsers({ ...tableData, curPage: 1 });
    };

    const clearSearchInput = () => {
        getUsers({ ...tableData, searchString: '' });
    };

    const reloadAfterCloseModal = async (
        modal: string,
        users?: Array<SMAdminUserTableFields | SMAdminTableFields>,
    ) => {
        setOpenModals({ ...openModals, [modal]: false });
        await getUsers(tableData, users);
        dispatch(clearSelectedRows());
    };

    const createButton = (
        <div>
        <UsersActionSpace>
            <Dropdown
                menu={{
                    items: createAccountMenu,
                    onClick: handleMenuSelect,
                    style: { top: -36 },
                }}
                placement="bottom"
                trigger={['click']}
                onOpenChange={handleCreateMenu}
            >
                <CreateAccountButtonContainer>
                    <CreateAccountButton id="create-user-button">
                        Create account
                    </CreateAccountButton>
                    <CreateAccountMenu id="create-user-menu">
                        {openCreateMenu ? (
                            <CaretUpOutlined style={{ color: 'white' }} />
                        ) : (
                            <CaretDownOutlined style={{ color: 'white' }} />
                        )}
                    </CreateAccountMenu>
                </CreateAccountButtonContainer>
            </Dropdown>
            <div style={{display:'none'}}><UsersActions
            /></div> 
        </UsersActionSpace>
        </div>
    );

    return (
        <>
            <PageHeader
                title={userType === 'user' ? 'Users' : 'SM admins'}
                children={createButton}
            />
            <PageContainer>
                <div>
                    <UsersTableActions>
                        <Dropdown
                            menu={{
                                items: actionMenuTop,
                                onClick: handleMenuSelect,
                                style: { top: 0 },
                            }}
                            disabled={!selector.userReducer.selectedRows?.length}
                            placement="bottom"
                            trigger={['click']}
                            onOpenChange={handleActionsMenu}
                        >
                            <UsersPageActionsButton>
                                Actions{' '}
                                {openActionsMenu ? <CaretUpOutlined /> : <CaretDownOutlined />}
                            </UsersPageActionsButton>
                        </Dropdown>
                    </UsersTableActions>
                    <UsersTable
                        getUsers={getUsers}
                        tableData={tableData}
                        users={state.users}
                        totalCount={state.total}
                        isPending={userReducer.pending}
                        addiTionalItems={state.newUsers}
                        subscriptions={subscriptionPlans}
                        searchComponent={
                            <UsersPageSearch
                                tableData={tableData}
                                totalCount={state.total}
                                onSearch={onSearch}
                                handleSearch={handleSearch}
                                clearSearchInput={clearSearchInput}
                            />
                        }
                        setTableData={setTableData}
                        userType={userType}
                    />
                </div>
                {openModals.one && (
                    <SMAdminCreateOneAccountModal
                        isOpen={openModals.one}
                        closeModal={handleMenuSelect}
                        reload={reloadAfterCloseModal}
                    />
                )}
                {openModals.admin && (
                    <CreateOneSMAdminModal
                        isOpen={openModals.admin}
                        closeModal={handleMenuSelect}
                        reload={reloadAfterCloseModal}
                    />
                )}
            </PageContainer>
            <DeleteModal
                isOpen={isOpenDeleteModal}
                closeModal={() => {
                    setIsOpenDeleteModal(false);
                }}
                reload={deleteUser}
                text={`selected users`}
                title="user"
                icon="trash-can-list.svg"
            />

            <ExportUsersToCsv
                isOpen={isOpenExportModal}
                closeModal={() => {
                    setIsOpenExportModal(false);
                }}
                users = {selector.userReducer.selectedRows}
                userIds = {selector.userReducer.selectedRows.map((x) => (x.key))}
                reload={exportUser}
                options={[]}
            />        


        </>
    );
};

export default UsersForSMAdmin;
