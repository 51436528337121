import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import ModalTitleLeft8 from '../../../common/modalTitle/ModalTitleLeft8';
import DocumentTypesGrid from './DocumentTypesGrid';

import { DocumentTypesModalDivider, 
    DocumentTypesModal,
    DocumentTypeTextBlock,
    DocumentTypeButtonLinkBlock,
    DocumentTypeLinkBlock,
    IaLegend,
    DivFlexWithWrap,
    DivImpactArea,
    DivImpactAreaImage,
} from './FindDocuments.styles';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
    getSustainabilityDocumentTypesAction
} from '../../../../redux/actions/productActions';
import {
    GetDocumentTypesResponse,
} from '../../../../redux/actions/productActions/types';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { Spin } from 'antd';
import { CloseIcon, PrimaryButton, SpaceEnd } from '../../../../styles/common.styles';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'findDocuments' }) => void;
    documentTypesInfo: any;
    reload: (modal: string) => void;
}

const FindDocuments: React.FC<Props> = ({ isOpen, closeModal, documentTypesInfo }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { projectReducer } = selector;

    const [state, setState] = useState({
        curPage: 1,
        searchString: '',
        documentTypes: !documentTypesInfo ? Array<any>() : documentTypesInfo.documentTypes ,
        currentDocumentTypes: !documentTypesInfo ? Array<any>() : documentTypesInfo.documentTypes.slice(0, 10) ,
        total: !documentTypesInfo ? 0 : documentTypesInfo.allExistingSustainabilityDocumentTypesCount ,
        totalWithFilter: !documentTypesInfo ? 0 : documentTypesInfo.allExistingSustainabilityDocumentTypesCount ,
    });

//    useEffect(() => {
//        getData();
//    }, []);

//    const getData = async () => {
//        await getDocumentTypes(1, '');
//    };

    const handleCancel = () => {
        closeModal({ key: 'findDocuments' });
    };

    const getDocumentTypes = async (page: number, searchString: string) => {
        const { payload } = (await dispatch(
            getSustainabilityDocumentTypesAction({
                curPage: page,
                searchString
            }),
        )) as ActionResponse<GetDocumentTypesResponse>;
        if (payload.data) {
            setState({
                ...state,
                curPage: page,
                searchString,
                documentTypes: payload.data.documentTypes,
                currentDocumentTypes: payload.data.documentTypes.slice(page - 1, page - 1 + 10),
                total: payload.data.allExistingSustainabilityDocumentTypesCount,
                totalWithFilter: payload.data.allExistingSustainabilityDocumentTypesCount,
            });
        }
    };

    const getCurrentDocumentTypes = (page: number, searchString: string) => {
        let currentDocumentTypes = state.documentTypes;
        if (searchString) {
            currentDocumentTypes = currentDocumentTypes
                .filter((dt) =>
                    dt.name.toLowerCase().includes(searchString.toLowerCase()),
                );
        }
        let start = page === 1 ? 0 : (page - 1) * 10;
        let totalCount = state.documentTypes.length;
        if (searchString) {
            totalCount = state.documentTypes
                .filter((dt) =>
                    dt.name.toLowerCase().includes(searchString.toLowerCase()),
                ).length;
            if (totalCount < 10) {
                start = 0;
            }
        }
        const docTwithFilter = currentDocumentTypes.slice(start, start + 10);
        setState({
            ...state,
            currentDocumentTypes: totalCount > 0 ? docTwithFilter : [],
            totalWithFilter: totalCount,
            // total: totalCount,
        });
    };

    const allImpactAreas = ['Human Health','Climate Health','Circularity','Social Health & Equity','Ecosystem Health'];

    const getInspectAreaImage = (impactAreaName : string) => {
       switch(impactAreaName) {
          case 'HUMAN HEALTH':
              return 'src/assets/images/health_red.png';
          case 'CLIMATE HEALTH':
              return 'src/assets/images/climate_blue.png';
          case 'CIRCULARITY':
              return 'src/assets/images/circularity_blue.png';
          case 'SOCIAL HEALTH & EQUITY':
              return 'src/assets/images/social_purple.png';
          case 'ECOSYSTEM HEALTH':
              return 'src/assets/images/ecosystem_green.png';
       }
    };

    return (
        <>
        <DocumentTypesModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            closeIcon={<CloseIcon />}
        >
            <Spin spinning={projectReducer.pending}>
                <ModalTitleLeft8 title="Find sustainability documents" icon="ic-modal-title-share.svg" />
                <DocumentTypeTextBlock>
                    <b>Need more sustainability documents?</b> Visit the manufacturer's website, <a href="https://www.mindfulmaterials.com/" target="_blank">mindful MATERIALS</a> or any of the certification and disclosure providers' websites to download.
                </DocumentTypeTextBlock>

                <IaLegend>
                    <DivFlexWithWrap>
                      {allImpactAreas?.map((impactArea, i) => (
                        <DivImpactArea>
                          <DivImpactAreaImage>
                             <Image
                               preview={false}
                               src={getInspectAreaImage(impactArea?.toUpperCase())}
                               style={{ cursor: 'none' }}
                               width={76} height={76}
                             />
                          </DivImpactAreaImage>
                        </DivImpactArea>
                      ))}
                    </DivFlexWithWrap>
                </IaLegend>

                <DocumentTypeButtonLinkBlock>
                    <DocumentTypeLinkBlock>
                        <a href="https://www.mindfulmaterials.com/a-common-language" target="_blank">Learn about the Common Materials Framework</a>
                    </DocumentTypeLinkBlock>
                </DocumentTypeButtonLinkBlock>

                <DocumentTypesGrid
                    totalCount={state.total}
                    totalWithFilterCount={state.totalWithFilter}
                    documentTypes={state.currentDocumentTypes}
                    getDocumentTypes={getCurrentDocumentTypes}
                    reload={getDocumentTypes}
                />

                <SpaceEnd style={{ marginTop: '12' + `px` ,  marginBottom: '16' + `px` }}>
                    <PrimaryButton
                        htmlType="submit"
                        width={127}
                        height={32}
                        onClick={handleCancel}
                    >
                        Done
                    </PrimaryButton>
                </SpaceEnd>

            </Spin>
        </DocumentTypesModal>
        </>
    );
};

export default FindDocuments;

