import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { blueDark01, blueLink, grayLight03 } from '../../../../../styles/colors';
import { BoldText, SpaceStart } from '../../../../../styles/common.styles';

export const ActivityEvent = styled(SpaceStart)`
    padding: 10px 0;
    align-items: baseline;
`;

export const ActivityText = styled('span')`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark01};
`;

export const ProductNameText = styled('span')``;

export const ProductLink = styled(Link)`
    color: ${blueLink};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    &:hover {
        text-decoration: underline;
        color: ${blueLink};
    }
`;

export const ActivityEmailText = styled('span')`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark01};
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
`;

export const AdditionalDataSection = styled('div')`
    border-top: 1px solid ${grayLight03};
    padding-left: 28px;
    margin-right: 5em;
    .item:first-child {
        margin-top: 12px;
    }
`;

export const AdditionalDataItem = styled(SpaceStart)`
    align-items: baseline;
    padding-bottom: 16px;
`;

export const AdditionalDataLink = styled(Link)`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${blueLink};
    &:hover {
        text-decoration: underline;
        color: ${blueLink};
    }
`;

export const DownloadDocumentLink = styled('div')`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${blueLink};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: ${blueLink};
    }
`;

export const DownloadDocument = styled('div')`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${blueLink};
`;

export const Container = styled('div')`
    width: 70%;
    margin-left: 20%;
`;

export const AuthorNameText = styled(BoldText)`
    white-space: nowrap;
`;

export const PricingInformationText = styled(ActivityText)`
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
