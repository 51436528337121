import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { ActionResponse } from '../../models/actionResponse/types';
import { SubscriptionTypes } from '../../models/subscription/enums';
import {
    confirmPaymentAction,
    confirmUpdateSubscriptionPaymentAction,
} from '../../redux/actions/checkoutActions';
import { getCurrentSubscriptionAction } from '../../redux/actions/subscriptionActions';
import { GetCurrentSubscriptionResponse } from '../../redux/actions/subscriptionActions/types';
import { changeSubscription } from '../../redux/reducers/authReducer/AuthSlice';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { setSubscriptionUpdating } from '../../redux/reducers/subscriptionReducer/SubscriptionSlice';
import { alertService } from '../../services';
import { AlertMessages } from '../alert/AlertMessages';
import { NavigationKey } from '../appNavigationBar/AppNavigation.types';
import { ConfirmationContainer } from './Success.style';

const Success: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const [state, setState] = useState({ pending: true });
    const selector = useAppSelector((combinedState) => combinedState);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const sessionId = params.get('session_id');
        dispatch(addPages([{ key: NavigationKey.HOME, value: 'SM Transparency Catalog' }]));
        if (sessionId) {
            confirmPayment(sessionId);
        } else {
            alertService.error('Something went wrong');
        }
    }, []);

    const confirmPayment = async (sessionId: string) => {
        if (selector.authReducer.user) {
            const { payload } = await dispatch(
                confirmUpdateSubscriptionPaymentAction({ sessionId }),
            );
            if (payload.data.payment_status === 'paid') {
                setState({ ...state, pending: false });
                getCurrentSubscription();
            }
            return;
        } else {
            const { payload } = await dispatch(confirmPaymentAction({ sessionId }));
            if (payload.data.payment_status === 'paid') {
                setState({ ...state, pending: false });
                history.push('/login');
                alertService.success(AlertMessages.SENT_CONFIRMATION_LINK);
            }
        }
    };

    const getCurrentSubscription = async () => {
        const { payload } = (await dispatch(
            getCurrentSubscriptionAction(),
        )) as ActionResponse<GetCurrentSubscriptionResponse>;
        if (
            payload.data &&
            payload.data.subscriptionPlan.name !== selector.authReducer.user?.subscriptionPlanName
        ) {
            dispatch(setSubscriptionUpdating(false));
            dispatch(
                changeSubscription({
                    subscriptionPlanName: payload.data.subscriptionPlan.name as SubscriptionTypes,
                    subscriptionPlanId: payload.data.subscriptionPlan.id,
                }),
            );
            history.push('/dashboard');
            alertService.success(
                'Congratulations you have updated your subscription, enjoy the new features.',
            );
        }
    };
    return (
        <ConfirmationContainer>
            <Spin size="large" spinning={true}></Spin>
        </ConfirmationContainer>
    );
};

export default Success;
