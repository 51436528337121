import styled from 'styled-components';
import { blueDark01 } from '../../../../../../styles/colors';

export const NotificationTitleLabel = styled('div')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 20px;
    line-height: 24px;
    color: ${blueDark01};
    margin-right: 5px;
    &.division-id {
        margin-left: -10.5px;
    }
`;
