import { createAsyncThunk } from '@reduxjs/toolkit';
import { HttpResponse, httpExternal } from '../../../helpers/request';
import { GetProductsFromTransparencyCatalogAPI } from './types';
import { BrandCompany, ExternalProduct } from '../../../models/product/types';
import { MasterFormatBackground } from '../../../models/product/enums';
import { ActionResponse } from '../../../models/actionResponse/types';

const transparencyCatalogAPI = `${process.env.REACT_APP_TC_URL}/api/v1`;

export const getExternalProductsAction = createAsyncThunk<
    HttpResponse<any>,
    GetProductsFromTransparencyCatalogAPI
>(
    'transparencyCatalogActions/getExternalProducts',
    async (data: GetProductsFromTransparencyCatalogAPI) => {
        console.log(data);
        console.log(data.productIds.join(','));
        console.log('TC API URL:')
        console.log(`${transparencyCatalogAPI}/products?ids=${data.productIds.join(',')}`);

        const result = await httpExternal({
            path: `${transparencyCatalogAPI}/products?ids=${data.productIds.join(',')}`,
            method: 'get',
        });
        console.log('GetProductDetails:');
        console.log(result);
        return result;
    },
);

export const getBrandByIdAction = createAsyncThunk<HttpResponse<any>, number[]>(
    'transparencyCatalogActions/getBrandById',
    async (companyIds: Array<number>) => {
        const result = await httpExternal({
            path: `${transparencyCatalogAPI}/companies?ids=${companyIds.join(',')}`,
            method: 'get',
        });
        console.log('GetCompanyDetails:');
        console.log(result);
        return result;
    },
);

export const getCompaniesOfProducts = async (products: ExternalProduct[], dispatch: any): Promise<BrandCompany[]> => {
    const productsWithInvalidCompanyId = products.filter(product => !Boolean(product.companyID));
    const fakeCompanies: BrandCompany[] = productsWithInvalidCompanyId.map((product) => ({
        id: -1,
        company: product.company,
        companyUrl: '',
        description: '',
        logo: {url: '', height: undefined, width: undefined},
        featuredProductImage: '',
        listingUrl: '',
        contactInfo: '',
        contactEmail: '',
        websiteUrl: '',
        level: MasterFormatBackground.STANDARD,
    }));

    const productsWithValidCompanyId = products.filter(product => Boolean(product.companyID));
    if (!productsWithValidCompanyId.length) {
        return fakeCompanies;
    }

    const validCompanyIds = productsWithValidCompanyId.map(product => product.companyID);
    const uniqueCompanyIds = [...new Set(validCompanyIds)];
    const companiesPayloadData = (await dispatch(getBrandByIdAction(uniqueCompanyIds))) as ActionResponse<Array<BrandCompany>>;
    const payload = companiesPayloadData.payload;

    if (!payload?.data || payload.error?.length) {
        return fakeCompanies;
    }
    
    return [
        ...payload.data,
        ...fakeCompanies
    ];
}