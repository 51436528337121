import styled from "styled-components";
import { blueDark01, blueLink, grayDark08, grayDark09, grayLight06, green01 } from "../../../../styles/colors";
import { CheckboxContainer } from "./ProductItemHeader.styles";

export const HeaderContainer = styled('div')`
    width: 755px;
`;

export const SectionNameContainer = styled('div')`
    background-color: ${grayDark08};
    color: white;
    height: 18px;
    font-family: 'Proxima N W15 Bold';
    font-size: 10.5px;
    line-height: 13.13px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    padding: 3px 13px 0px 13px;
    border-bottom: 1px solid ${grayDark09};
`;

export const InfoContainer = styled('div')`
    height: 55px;
    background-color: ${grayLight06};
    border-bottom: 2px solid ${green01};
    padding: 5px 13px 0px 13px;
`;

export const TextContainer = styled('div')`
    height: 15px;
    display: flex;
    align-items: center;
    font-size: 11.5px;
    line-height: 14.38px;
`;

export const ProductNameContainer = styled(TextContainer)`
    font-family: Proxima N W15 Smbd;
    color: ${blueLink};
`;

export const ActionCheckboxContainer = styled(CheckboxContainer)`
    padding: 0;
    & > .ant-checkbox-wrapper {
        left: 0px;
    }
`;

export const NotesContainer = styled(TextContainer)`
    margin-top: 4px;
    color: ${blueDark01};
    .bold {
        font-family: 'Proxima N W15 Bold';
    }
`;