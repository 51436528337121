import React from 'react';
import { blueDark01 } from '../../../styles/colors';
import { CloseIcon } from '../../../styles/common.styles';
import ModalFooter from '../../common/modalFooter/ModalFooter';
import ModalTitle from '../../common/modalTitle/ModalTitle';
import {
    CookiesModalContainer,
    CookiesModalContent,
    CookiesModalText,
    CookiesModalWrapper,
} from './CookiesModal.styles';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}

const CookiesModal: React.FC<Props> = ({ isOpen, closeModal }) => {
    return (
        <CookiesModalWrapper
            open={isOpen}
            onCancel={closeModal}
            centered
            footer={null}
            className="delete-modal-container"
            closeIcon={<CloseIcon />}
        >
            <CookiesModalContainer>
                <ModalTitle title={`Cookies`} icon={'cookie.svg'} />
            </CookiesModalContainer>
            <CookiesModalContent>
                <CookiesModalText weight={400} height={18} size={14} color={blueDark01}>
                    The Transparency Catalog uses cookies to collect basic usage data (page views,
                    search types, downloads), which is aggregated. Project Builder & Library
                    requires functional cookies to keep you logged in and to select products for
                    Projects and Favorite Product Libraries. No other cookies are set.
                </CookiesModalText>
                <a href="https://transparencycatalog.com/privacy-policy" target="_blank">
                    Learn more in our privacy policy.
                </a>
            </CookiesModalContent>
            <ModalFooter
                submit={closeModal}
                action={'Allow cookies'}
                cancel={closeModal}
                cancelProps={{
                    style: { display: 'none' },
                }}
            />
        </CookiesModalWrapper>
    );
};

export default CookiesModal;
