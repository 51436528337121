import styled from 'styled-components';
import { grayLight02 } from '../../../../styles/colors';

export const ExistingProjectContainer = styled('div')`
    border-bottom: 1px solid ${grayLight02};
`;

export const StartProjectField = styled('div')`
    margin-top: 20px;
    .ant-form-item .ant-select-selector {
        width: unset;
    }
    label {
        margin-right: 16px;
        margin-top: 8px;
    }
    .ant-form-item-control-input-content {
        margin-bottom: 20px;
    }
`;
