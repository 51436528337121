import styled from 'styled-components';
import { Typography } from 'antd';
import { blueDark01, grayDark03, grayDark06, grayLight03 } from '../../styles/colors';

export const NotificationsEmailText = styled(Typography.Text)`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${grayDark03};
    padding-left: 10px;
`;

export const NotificationsEmailTable = styled('table')`
    margin-top: 30px;
    width: 70%;
    padding-left: 10px;
    tbody {
        tr {
            border-bottom: 1px solid ${grayLight03};
        }
    }
    thead {
        border-bottom: 1px solid ${grayDark06};
    }
`;

export const NotificationsTableTitle = styled('span')`
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark01};
    display: flex;
    position: relative;
    padding: 15px;
`;

export const NotificationTip = styled('div')`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    white-space: nowrap;
    top: -5px;
    opacity: 0.5;
    font-family: 'Proxima Nova';
`;

export const CheckBoxContainer = styled('div')`
    display: flex;
    justify-content: center;
    padding: 10px 0;
`;
