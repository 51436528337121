/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import { blueDark01, blueLink } from '../../styles/colors';
import ModalFooter from '../common/modalFooter/ModalFooter';
import ModalTitle from '../common/modalTitle/ModalTitle';
import { Text, CloseIcon, BoldText } from '../../styles/common.styles';
import { AdminItem, AdminsContainer, ContactAdminModal } from './ContactAccountAdmins.styles';
import { useAppDispatch } from '../../hooks/redux';
import { getAccountAdminsAction } from '../../redux/actions/userActions';
import { ActionResponse } from '../../models/actionResponse/types';
import { GetAccountAdminsRequest } from '../../redux/actions/userActions/types';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}

const ContactAccountAdmins: React.FC<Props> = ({ isOpen, closeModal }) => {
    const dispatch = useAppDispatch();

    const [state, setState] = useState(Array<GetAccountAdminsRequest>());

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const { payload } = (await dispatch(getAccountAdminsAction())) as ActionResponse<
            Array<GetAccountAdminsRequest>
        >;
        if (payload.data) {
            setState(payload.data);
        }
    };

    return (
        <ContactAdminModal
            open={isOpen}
            onCancel={closeModal}
            footer={null}
            className="delete-modal-container"
            closeIcon={<CloseIcon />}
        >
            <ModalTitle title={`Contact account administrators`} icon={'contact.svg'} />
            <Text weight={400} size={14} height={18} color={blueDark01}>
                If you need help using or managing your account, please contact your account
                admin(s):
            </Text>
            <AdminsContainer>
                {state.map((account) => (
                    <AdminItem key={account.email}>
                        <BoldText
                            weight={400}
                            height={18}
                            size={14}
                            color={blueDark01}
                        >{`${account.firstName} ${account.lastName}`}</BoldText>
                        <a href={`mailto:${account.email}`}>
                            <Text weight={400} height={18} size={14} color={blueLink}>
                                {account.email}
                            </Text>
                        </a>
                        <Text weight={400} height={18} size={14} color={blueDark01}>{`${
                            account.phoneNumber || ''
                        }`}</Text>
                    </AdminItem>
                ))}
            </AdminsContainer>
            <ModalFooter
                cancel={closeModal}
                submit={closeModal}
                cancelProps={{
                    style: { display: 'none' },
                }}
                action="Close"
            />
        </ContactAdminModal>
    );
};

export default ContactAccountAdmins;
