import { Divider, Modal, Space, Tabs, Typography, Input } from 'antd';
import { Link } from '../../../common/modalFooter/ModalFooter.styles';
import { Text } from '../../../../styles/common.styles';
import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    blueDark02,
    blueLink,
    grayDark06,
    grayLight02,
    white,
} from '../../../../styles/colors';
import { PrimaryButton } from '../../../../styles/common.styles';

export const DocumentTypesTitle = styled(Typography.Text)`
    font-weight: 700;
    font-family: 'Proxima Nova';
    font-size: 14px;
    line-height: 18px;
    color: ${blue01};
    margin-left: 0px;
`;

export const DocumentTypeCount = styled('div')`
    font-weight: 700;
    font-family: 'Proxima Nova';
    font-size: 14px;
    line-height: 18px;
    color: ${blue01};
    margin: 0 5px 0 -5px;
`;

export const TableDataContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
`;

export const DtGroup = styled('div')`
   width: 600px;
   gap: 8px;
   padding: 0 0 8px 0;
   border-top: 1px solid ${grayLight02};
`;

export const DtBlock = styled('div')`
`;

export const DtRow = styled('div')`
    display: inline-flex;
    width: 100%;
//    height: 32px;
    border-bottom: 1px solid ${grayLight02};
`;

export const DtName = styled('div')`
    min-width: 470px;
    width: 470px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    a {
        font-family: Proxima Nova;
        color: #134FBD;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        text-decoration: none;
    }
    a:link { 
      text-decoration: none; 
    } 
    a:visited { 
      text-decoration: none; 
    } 
    a:hover { 
      text-decoration: none; 
    } 
    a:active { 
      text-decoration: none; 
    }
`;

export const DtCmfContainer = styled('div')`
//    width: 100%;
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: start;
`;

export const DtCmfImage = styled('div')`
`;

export const DtLink = styled(Link)`
    font-family: Proxima Nova;
    color: #00237B;
    font-weight: 400;
    margin-bottom: 0px;
    text-decoration: none !important; 
`;

export const FilterInput = styled(Input)`
    height: 28px !important;
    font-size: 14px;
    line-height: 18px;
`;

export const FilterLabel = styled(Text)`
    font-size: 12px;
    line-height: 14px;
`;
