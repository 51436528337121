import React from 'react';
import { Image } from 'antd';

import { ModalTitleSpace, ModalTitleTypography } from './ModalTitle.styles';

interface Props {
    title: string;
    icon?: string;
}

const ModalTitleLeft8: React.FC<Props> = ({ title, icon }) => {
    return (
        <ModalTitleSpace className="title-container" style={{ marginLeft: '-8' + `px` }} >
            {icon && <Image src={`src/assets/images/${icon}`} preview={false} />}
            <ModalTitleTypography>
                <strong>{title}</strong>
            </ModalTitleTypography>
        </ModalTitleSpace>
    );
};

export default ModalTitleLeft8;
