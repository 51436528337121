import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { alertService } from '../../services';
import { useAppDispatch } from '../../hooks/redux';
import { confirmEmailAction } from '../../redux/actions/authActions';
import { useHistory, useLocation } from 'react-router-dom';
import { ConfirmationContainer } from './ConfirmationEmail.styles';
import { AlertMessages } from '../../components/alert/AlertMessages';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';

const ConfirmationEmail: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    useEffect(() => {
        dispatch(addPages([{ key: NavigationKey.HOME, value: 'SM Transparency Catalog' }]));
        const userId = params.get('id');
        const key = params.get('key');
        confirmEmail(userId, key);
    }, [params]);

    const confirmEmail = async (userId: string | null, key: string | null) => {
        if (!userId || !key) {
            history.push('/login');
            alertService.error('Confirmation link was corrupted');
            return;
        }
        const { payload } = (await dispatch(confirmEmailAction({ userId, key }))) as any;
        history.push('/login');
        if (payload) {
            alertService.success(AlertMessages.CONFIRM_EMAIL);
        } else {
            alertService.error(AlertMessages.CORRUPTED_LINK);
        }
    };

    return (
        <ConfirmationContainer>
            {' '}
            <Spin size="large" spinning={true}></Spin>
        </ConfirmationContainer>
    );
};

export default ConfirmationEmail;
