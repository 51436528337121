import { DatePicker, Image, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import {
    black,
    blue01,
    blueDark01,
    blueDark02,
    blueLink,
    error,
    grayDark03,
    grayDark06,
    grayLight05,
    white,
} from '../../../../../styles/colors';
import { BoldText, ErrorMessage, PrimaryButton } from '../../../../../styles/common.styles';

export const UploadsRowContainer = styled('div')`
    padding: 17px 0px;
    display: flex;
    .row-container {
        image {
            margin-top: -9px;
        }
    }
`;

export const UploadDocumentNameContainer = styled('div')`
    width: 40%;
    min-width: 260px;
    &.pictureTable {
        width: 25%;
        min-width: 230px;
    }
`;

export const UploadDocumentPreviewContainer = styled('div')`
    width: 30%;
    padding-right: 30px;
`;

export const UploadsNameContainer = styled('div')`
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
    .ant-image {
        display: flex;
        padding-left: 10px;
    }
    .file-image {
        margin-top: -9px;
    }
`;

export const UploadsDocumentType = styled('div')`
    margin-left: 32px;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${grayDark03};
    display: flex;
//    flex-wrap: wrap;
`;

export const UploadsDescription = styled('div')`
    display: flex;
    align-items: center;
    margin-top: 7px;
    margin-left: 32px;
    gap: 2px;
    cursor: pointer;
    .rotate {
        transform: rotate(180deg);
    }
`;

export const UploadDescriptionContent = styled('div')`
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 200ms ease, opacity 0ms ease;
    &.clicked {
        max-height: 200px;
        opacity: 1;
        transition: max-height 200ms ease, opacity 0ms ease;
        padding-top: 7px;
        margin-top: -20px;
        padding-bottom: 16px;
    }
    padding-left: 32px;
`;

export const UploadDescriptionInput = styled(TextArea)`
    width: 70%;
    &.error {
        border-color: ${error};
    }
    &.ant-input {
        color: ${blueDark01};
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }
    &.ant-input[disabled] {
        color: ${blueDark01};
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }
`;

export const UploadDescriptionInputContainer = styled('div')`
    position: relative;
`;

export const UploadDescriptionInputError = styled(ErrorMessage)`
    left: 0;
    bottom: -15px;
`;

export const UploadExpiresContainer = styled('div')`
    align-items: center;
    width: 20%;
    &.pictureTable {
        width: 20%;
    }
`;

export const UploadsDatePickerContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    &.edit {
        border-radius: 3px;
        padding: 9px 0px 0px 0px;
        border: 1px solid ${grayDark06};
        margin-top: -9px;
        width: 90%;
        background-color: ${white};
    }
`;

export const UploadsActions = styled('div')`
    width: 10%;
    display: flex;
    justify-content: end;
    padding-right: 16px;
    gap: 16px;
`;

export const UpdateUploadsButton = styled(PrimaryButton)`
    width: 100px;
    height: 32px;
    border: 1px solid ${blueDark02};
    border-radius: 3px;
    margin-top: 16px;
    background-color: inherit;
    color: ${blueDark02};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${grayLight05};
        border: 1px solid ${blueDark02};
        color: ${blueDark02};
    }
`;

export const EditFileNameInput = styled(Input)`
    margin-top: -9px;
    border: 1px solid ${blue01};
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: ${blueDark01};
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 12px;
    line-height: 14px;
    padding: 8px 11px;
    &.error {
        border-color: red;
    }
`;

export const EditExpirationDateInput = styled(Input)`
    margin-top: -9px;
    height: 32px;
    border: none;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: ${blueDark01};
    font-weight: normal;
    font-family: 'Proxima N W15 Bold';
    font-size: 12px;
    line-height: 14px;
    padding: 0px 0px 0px 8px;
    &.edit {
        background-color: ${white};
        color: ${black};
        border: 1px solid ${white};
    }
    &:hover {
        background-color: ${white};
        color: ${black};
        border: 1px solid ${white};
    }
    &:focus {
        background-color: ${white};
        color: ${black};
        border: 1px solid ${white};
    }
    &.error {
        border-color: red;
    }
`;

export const EditDatePicker = styled(DatePicker)`
    height: 32px;
    margin-top: -5px;
    background-color: ${white};
    &.ant-picker {
        border: none;
        background-color: ${white};
        padding: 0px 11px 4px 0;
        cursor: pointer;
    }
    input {
        display: none;
    }
`;

export const UploadColumnContainer = styled('div')`
    width: 15%;
    display: flex;
    &.pictureTable {
        width: 10%;
    }
`;

export const UploadCmfContainer = styled('div')`
    width: 15%;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: start;
`;

export const UploadCmfImage = styled('div')`
`;

export const FileNameText = styled(BoldText)`
    cursor: pointer;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(80%);
    white-space: nowrap;
`;

export const UploadsRowTipContainer = styled('div')`
    cursor: pointer;
    position: relative;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: nowrap;
            z-index: 2;
            width: 205px;
        }
    }
`;

export const CalendarActionContainer = styled('div')`
    margin-top: -4px;
    display: flex;
`;

export const ClearExpireImage = styled(Image)`
    cursor: pointer;
`;


export const TipContainer = styled('span')`
    text-transform: initial;
    white-space: normal;
    background: url(src/assets/images/mfr-question.svg) no-repeat 0 0;
    background-size: 10px 10px;
    float: inherit;
    margin: 0 0 0 20px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    .ant-image {
        display: flex;
    }
    img {
        cursor: pointer;
    }
    .program-tip {
        display: none;
        &:hover {
            display: block;
        }
    }
    .tooltip-ec3 {
        display: none;
        &:hover {
            display: block;
        }
    }
    .tooltip-epdpt {
        display: none;
        &:hover {
            display: block;
        }
    }
    &:hover {
        .program-tip,
        .tooltip-ec3 {
            z-index: 1;
            display: block;
            margin-left: 9px;
            margin-top: -20px;
            position: absolute;
            border-radius: 3px;
            background: #ffffb8;
            color: ${black};
            &.products-tab {
                margin-left: 5px;
            }
        }

        .tooltip-ec3 {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }

        .tooltip-epdpt {
            z-index: 1;
            display: block;
            margin-left: 9px;
            margin-top: -20px;
            position: absolute;
            border-radius: 3px;
            background: #ffffb8;
            color: ${black};
            &.products-tab {
                margin-left: 5px;
            }
        }

        .tooltip-epdpt {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }

        .shadow {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
    }
    &.ec3 {
//        display: flex;
//        flex-wrap: wrap;
        margin-top: 0px;
        margin-left: 6px;
//border: 0px solid #000;
    }
`;
